import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import Text from '../../../../../components/atoms/Text';
interface CategorySelectorProps {
  categories?: string[];
  isMobile?: boolean;
  categoryMobile?: string;
  categoryDesktop?: string;
  handleChangeMobile?: (event: SelectChangeEvent<string>) => void;
  handleChangeDesktop?: (item: string) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, isMobile, categoryMobile, categoryDesktop, handleChangeMobile, handleChangeDesktop }) => {

  return (
    <>
      {isMobile ? (
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">¿Qué servicios buscas?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={categoryMobile}
              label="¿Qué servicios buscas?"
              onChange={handleChangeMobile}
            >
              {categories?.map((item, index) => (
                <MenuItem value={item} key={`service-category-${index}`}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <div className='flex flex-row mt-10 mb-8'>
          {categories?.map((item, index) => (
            <div
              key={`category-${index}`}
              className='mr-6'>
              <button onClick={() => handleChangeDesktop?.(item)}>
                <Text size='l' color={categoryDesktop === item ? 'primary' : 'dark'} weight={categoryDesktop === item ? 'bold' : 'medium'}>{item}</Text>
              </button>
              {categoryDesktop === item && <div className='h-0.5 bg-h-primary w-full'></div>}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(CategorySelector);