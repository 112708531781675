import React, { useMemo } from 'react';
import Text from '../../../components/atoms/Text';

interface Props {
	status: 'positive' | 'negative' | 'neutral' | 'paid';
}
const FiscalResult: React.FC<Props> = ({ status }) => {
	const label = useMemo(() => {
		switch (status) {
			case 'positive':
				return 'A favor';

			case 'negative':
				return 'Por pagar';

			case 'paid':
				return 'Pagado';
			default:
				return 'Saldo en 0';
		}
	}, [status]);

	const color = useMemo(() => {
		switch (status) {
			case 'positive':
			case 'paid':
				return 'green';

			case 'negative':
				return 'red';

			default:
				return 'medium';
		}
	}, [status]);

	return (
		<Text
			size='caption'
			weight='light'
			color={color}
			sx='text-center w-full table whitespace-nowrap'
		>
			{label}
		</Text>
	);
};

export default React.memo(FiscalResult);
