import React from 'react';
import images from '../../../../shared/constants/images';
import Text from '../../../../components/atoms/Text';
import {
	getBrandBg,
	getBrandMockCards,
} from '../../../../shared/utils/payments';

interface Props {
	brand: string;
	lastFour: string;
	expires: string;
	isHalf?: boolean;
	isDefault?: boolean;
}

const CardMock: React.FC<Props> = ({
	brand,
	lastFour,
	expires,
	isHalf = false,
	isDefault = true,
}) => {
	return (
		<>
			{isHalf ? (
				<div className='relative md:min-w-[275px] h-[85px] w-full'>
					<img
						src={getBrandBg(brand)}
						alt='brand'
						className='absolute inset-0 w-full h-full object-cover rounded-t-[12px]'
					/>
					<div
						className={`absolute inset-0 p-5 flex flex-col justify-end hover:bg-[linear-gradient(45deg,transparent_25%,rgba(61,59,64,1)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_0%] bg-[position:-100%_0,0_0] rounded-t-[12px] shadow-2xl hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]`}
					>
						<div className='relative'>
							<div className='absolute -top-12 left-0'>
								<img
									src={getBrandMockCards(brand)}
									alt='brand'
									className='w-[40px] h-[40px]'
								/>
							</div>
							<div className='absolute -top-6 right-0'>
								<img
									src={images.chip}
									alt='brand'
									className='w-[50px] h-[50px]'
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div
					className={`relative ${isDefault ? 'md:w-[340px]' : 'md:min-w-[275px]'} w-full h-44`}
				>
					<img
						src={getBrandBg(brand)}
						alt='brand'
						className={`absolute inset-0 w-full h-full object-cover ${isDefault ? 'rounded-[12px]' : isHalf ? '' : 'rounded-[12px]'} `}
					/>
					<div
						className={`absolute inset-0 p-5 flex flex-col justify-end hover:bg-[linear-gradient(45deg,transparent_25%,rgba(61,59,64,1)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_0%] bg-[position:-100%_0,0_0] ${isDefault ? 'rounded-[12px]' : isHalf ? 'rounded-t-[12px]' : 'rounded-[12px]'} shadow-2xl hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]`}
					>
						<div className='relative'>
							<div className='absolute -top-20 left-0'>
								<img
									src={getBrandMockCards(brand)}
									alt='brand'
									className='w-[40px] h-[40px]'
								/>
							</div>
							<div className='absolute -top-12 right-0'>
								<img
									src={images.chip}
									alt='brand'
									className='w-[50px] h-[50px]'
								/>
							</div>
						</div>
						<div className='flex items-center'>
							<Text
								//@ts-expect-error
								className='mb-2 font-medium tracking-tight text-neutral-100'
								color='white'
								weight='light'
								size='m'
							>
								• • • • &nbsp;&nbsp; • • • • &nbsp;&nbsp; • • • • &nbsp;&nbsp;{' '}
								{lastFour}
							</Text>
						</div>
						<div className='flex justify-end mt-2'>
							<Text
								color='white'
								weight='light'
								size='m'
							>
								{expires}
							</Text>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(CardMock);
