import { useSelector } from 'react-redux';
import clevertap from 'clevertap-web-sdk';
import { RootState } from '../redux/store';

const useCleverTapEvent = () => {
	const isFromApp = useSelector((state: RootState) => state.profile.isFromApp);
	const platformOS = useSelector(
		(state: RootState) => state.profile.platformOS,
	);
	const logCleverTapEvent = (
		eventName: string,
		properties: Record<string, string | number | boolean | any[]> = {},
	) => {
		const sourceChannel = isFromApp ? platformOS ?? 'app_embedded' : 'web_app';
		clevertap.event.push(eventName, {
			...properties,
			timestamp: new Date(),
			source_channel: sourceChannel,
		});
	};

	return logCleverTapEvent;
};

export default useCleverTapEvent;
