import HERU_API from "../shared/constants/heruApi";
import { post } from "../shared/utils/FetchSSL.util";

const acceptZubaleTC = async (body: object, tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.COMPLIANCE_LOG,
			body,
			{
				isNewGateway: true,
				tokenSession,
				token: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {  acceptZubaleTC };