import axios from 'axios';
import { Receiver } from '../@types/Receiver.type';
import { Regime } from '../@types/Regime.type';
import HERU_API from '../shared/constants/heruApi';
import { get, getCustom, post, del } from '../shared/utils/FetchSSL.util';
import {
	DigitalStampStatus,
	FiscalValidator,
} from '../@types/DigitalStampStatus.type';
import {
	Concept,
	InvoiceDetail,
	Issuer,
	SummaryInvoice,
} from '../@types/Invoicing.type';
import { UserIssuedInvoice } from '../@types/UserIssuedInvoice.type';

const fetchFinancialsMovements = async (params: any, tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_FINANCIALS_MOVEMENTS.concat(`?${params}`),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getListOfRegimes = async (tokenSession?: string) => {
	try {
		const response = await get<{ regimes: Regime[] }>(
			HERU_API.INVOICE_REGIMES,
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getListOfReceivers = async (tokenSession?: string) => {
	try {
		const response = await get<{ receivers: Receiver[] }>(
			HERU_API.INVOICE_RECEIVERS,
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getUserDigitalStampStatus = async (
	userId: number,
	tokenSession?: string,
) => {
	try {
		const response = await get<DigitalStampStatus>(
			HERU_API.INVOICE_DIGITAL_STATUS(userId),
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getInvoicingCatalog = async () => {
	try {
		const response = await axios.get(HERU_API.INVOICE_CATALOGS);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getUserSavedProducts = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.INVOICE_USER_PRODCUTS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response.products;
	} catch (error) {
		throw error;
	}
};

type ValidationPropsFields = {
	rfc: string;
	name: string;
	postal_code: string;
	tax_regime: string;
	cfdiUse: string;
};
const getUserValidationFields = async (
	props: ValidationPropsFields,
	tokenSession?: string,
) => {
	try {
		const response = await getCustom(
			HERU_API.INVOICE_VALIDATION_FIELDS(props),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response.resource as FiscalValidator;
	} catch (error) {
		throw error;
	}
};

export type SaveReceiverProps = {
	name: string;
	rfc: string;
	tax_regime: string;
	postal_code: string;
	usocfdi: string;
	commercial_name: string;
};
const saveReceiver = async (
	values: SaveReceiverProps,
	tokenSession?: string,
) => {
	try {
		await post(HERU_API.INVOICE_SAVE_RECEIVER, values, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
	} catch (error) {}
};

const sendDataSummary = async (body: any, tokenSession?: string) => {
	try {
		const response = await post<SummaryInvoice>(
			HERU_API.INVOICE_SUMMARY,
			body,
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

type GenerateProps = {
	email: {
		to: string[];
	};
	data: {
		date: string;
		invoice_type: string;
		payment_form: string;
		payment_method: string;
		place_of_expedition: string;
		issuer: Issuer;
		receiver: Partial<Receiver>;
		concepts: Concept[];
	};
};
const sendGenerateInvoice = async (
	body: GenerateProps,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.INVOICE_GENERATE, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const getHasUserInvoice = async (id: number, tokenSession?: string) => {
	try {
		const response = await get<{ exists: boolean }>(
			HERU_API.INVOICE_USER_HAS(id),
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response.exists;
	} catch (error) {
		throw error;
	}
};

const getUserIssuedInvoice = async (id: number, tokenSession?: string) => {
	try {
		const response = await get<{ [key: string]: UserIssuedInvoice[] }>(
			HERU_API.INVOICE_USER_ISSUED(id),
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getInvoiceDetail = async (id: string, tokenSession?: string) => {
	try {
		const response = await get<InvoiceDetail>(HERU_API.INVOICE_DETAIL(id), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const sendDetailEmail = async (
	id: string,
	body: { to: string[]; user_id: number },
	tokenSession?: string,
) => {
	try {
		await post(HERU_API.INVOICE_DETAIL_SEND_EMAIL(id), body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
	} catch (error) {
		throw error;
	}
};

const sendCancelInvoice = async (
	id: string,
	motive: number,
	tokenSession?: string,
) => {
	try {
		const response = await del(HERU_API.INVOICE_CANCEL(id, motive), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response?.data?.resource as InvoiceDetail;
	} catch (error) {
		throw error;
	}
};

const onSendCertificates = async (body: any, tokenSession?: string) => {
	try {
		const response = await post(HERU_API.INVOICE_CERTIFICATES, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchCheckUserRappi = async (userId: number, tokenSession?: string) => {
	try {
		const taxpayer_response = await get(HERU_API.HERU_API_FINANCIAL_TAX_PAYERS(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		const rfc = taxpayer_response?.code
		let response;
		if (rfc) {
			response = await get(
				`${HERU_API.HERU_API_CERTIFICATES}?user_id=${userId}&rfc=${rfc}`,
				{
					token: true,
					isNewGateway: true,
					tokenSession,
				},
			);
		} else {
			response = await get(
				`${HERU_API.HERU_API_CERTIFICATES}?user_id=${userId}`,
				{
					token: true,
					isNewGateway: true,
					tokenSession,
				},
			);
		}

		return response;
	} catch (error) {
		throw error;
	}
};

export {
	saveReceiver,
	sendDataSummary,
	sendDetailEmail,
	getInvoiceDetail,
	getListOfRegimes,
	getHasUserInvoice,
	sendCancelInvoice,
	getListOfReceivers,
	onSendCertificates,
	getInvoicingCatalog,
	sendGenerateInvoice,
	getUserIssuedInvoice,
	getUserSavedProducts,
	getUserValidationFields,
	fetchFinancialsMovements,
	getUserDigitalStampStatus,
	fetchCheckUserRappi,
};
