import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
	CaretDown,
	CaretUp,
	CheckCircle,
	Pencil,
	ShoppingCart,
	WarningCircle,
} from '@phosphor-icons/react';
import { Radio } from '@mui/material';
import Text from '../../../../components/atoms/Text';
import Heading from '../../../../components/atoms/Heading';
import Button from '../../../../components/atoms/Button';
import Calendar from '../../components/organisms/Calendar';
import CalendarOtherRegimes from '../../components/organisms/CalendarOtherRegimes';
import {
	CategoryName,
	ListProducts,
	Product,
} from '../../../../@types/Products.type';
import { getUserFiscalStatus } from '../../../../services/fiscal.service';
import {
	FiscalDeclaration,
	PeriodsSelectedType,
} from '../../../../@types/Calendar.type';
import {
	regularizationBenefits,
	regularizationRegimes,
	rentalRevenues,
} from '../../data/ecommerceData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
	addTaxPayer,
	startLoader,
} from '../../../../redux/slices/taxProfile.slice';
import { fetchTaxPayer } from '../../../../services/taxes.service';
import { REGIMES_NAME } from '../../../../@types/Regime.type';
import hotSaleImg from '../../../../assets/img/svg/hot_sale.svg';
import { APP_CONFIGURATIONS } from '../../../../shared/constants/configurations';
import { getElasticyPrice } from '../../../../shared/utils/products.utils';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../../shared/utils/growthbook.util';
interface RegularizationProps {
	productName?: string;
	description?: string;
	products: ListProducts[];
	isMobile: boolean;
	handleAddToCart: (
		id: number,
		periods: PeriodsSelectedType[],
		regime: REGIMES_NAME,
	) => void;
	isLoadingAddProduct: boolean;
}

const reguProdSupported = [
	'reg_act_emp',
	'reg_arr_mes',
	'reg_arr_tri',
	'reg_rif',
	'reg_resico_plus',
	'reg_pt_full',
];

const messagesBusiness =
	'La presentación de declaraciones atrasadas debe ser en orden de enero a diciembre, ya que los ingresos se acumulan mes a mes. Asegúrese de seleccionar correctamente los meses que desea contratar.';

const Regularization: React.FC<RegularizationProps> = ({
	productName,
	description,
	products,
	isMobile,
	handleAddToCart,
	isLoadingAddProduct,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const lowerPrice = useMemo(() => {
		const lower =
			products
				.find((cat) => cat.name === CategoryName.DeclaraciónAtrasada)
				?.products.filter((p) => reguProdSupported.includes(p.slug))
				.sort((a, b) => a.price - b.price)?.[0]?.price ?? 0;

		return APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(lower / 100, elasticityPrice.group_experiment)
			: lower / 100;
	}, [products]);

	const plansAndPricesDeclaration = products[1]?.products ?? [];
	const [selectedRegime, setSelectedRegime] = useState<REGIMES_NAME>();
	const [valuePeriods, setValuePeriods] = useState<PeriodsSelectedType[]>([]);
	const [openBenefits, setOpenBenefits] = useState(false);
	const [value, setValue] = useState<string>();
	const [viewArriendos, setViewArriendos] = useState<boolean>(false);
	const [viewRif, setViewRif] = useState<boolean>(false);
	const [viewResico, setViewResico] = useState<boolean>(false);
	const [viewActivity, setViewActivity] = useState<boolean>(false);
	const [product, setProduct] = useState<number>(0);
	const [price, setPrice] = useState<number>(lowerPrice);
	const [count, setCount] = useState<number>(1);
	const [pricePeriods, setPricePeriods] = useState<number>(0);

	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();

	const [fiscalDeclarationStatus, setFiscalDeclarationStatus] =
		useState<FiscalDeclaration>();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedRegime(event.target.value as REGIMES_NAME);
	};

	const handledPeriods = (e: PeriodsSelectedType[]) => {
		setValuePeriods(e);
	};

	const handledArrendOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const getStatus = useCallback(async () => {
		try {
			const response = await getUserFiscalStatus(tokenSession);
			if (!response) return;

			setFiscalDeclarationStatus(response);
			if (response.regime_covered) {
				setSelectedRegime(REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS);
			}
		} catch (error) {}
	}, []);

	useEffect(() => {
		if (selectedRegime === 'Actividades Empresariales') {
			setProduct(0);
			setCount(1);
			setValuePeriods([]);
			setViewActivity(true);
			const product = plansAndPricesDeclaration.find(
				(plan: Product) => plan.slug === 'reg_act_emp',
			);
			if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
				const _priceElasticity = getElasticyPrice(
					Math.round((product?.price ?? 0) / 100),
					elasticityPrice.group_experiment,
				);
				setPrice(_priceElasticity);
			} else {
				setPrice((product?.price ?? 0) / 100);
			}
			setProduct(product?.default_product_version_id ?? 0);
		} else setViewActivity(false);

		if (selectedRegime === 'Arrendamiento') {
			setProduct(0);
			setCount(1);
			setValuePeriods([]);
			setViewArriendos(true);
			if (value === 'GanaMás') {
				setProduct(0);
				setPrice(0);
				setCount(1);
				setValuePeriods([]);
				const product = plansAndPricesDeclaration.find(
					(plan) => plan.slug === 'reg_arr_mes',
				);
				if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
					const _priceElasticity = getElasticyPrice(
						Math.round((product?.price ?? 0) / 100),
						elasticityPrice.group_experiment,
					);
					setPrice(_priceElasticity);
				} else {
					setPrice((product?.price ?? 0) / 100);
				}
				setProduct(product?.default_product_version_id ?? 0);
			} else if (value === 'GanaMenos') {
				setProduct(0);
				setCount(1);
				setValuePeriods([]);
				const product = plansAndPricesDeclaration.find(
					(plan) => plan.slug === 'reg_arr_tri',
				);
				if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
					const _priceElasticity = getElasticyPrice(
						Math.round((product?.price ?? 0) / 100),
						elasticityPrice.group_experiment,
					);
					setPrice(_priceElasticity);
				} else {
					setPrice((product?.price ?? 0) / 100);
				}
				setProduct(product?.default_product_version_id ?? 0);
			}
		} else setViewArriendos(false);

		if (selectedRegime === 'RIF') {
			setProduct(0);
			setCount(1);
			setValuePeriods([]);
			setViewRif(true);
			const product = plansAndPricesDeclaration.find(
				(plan) => plan.slug === 'reg_rif',
			);
			if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
				const _priceElasticity = getElasticyPrice(
					Math.round((product?.price ?? 0) / 100),
					elasticityPrice.group_experiment,
				);
				setPrice(_priceElasticity);
			} else {
				setPrice((product?.price ?? 0) / 100);
			}
			setProduct(product?.default_product_version_id ?? 0);
		} else setViewRif(false);

		if (selectedRegime === 'RESICO') {
			setProduct(0);
			setCount(1);
			setValuePeriods([]);
			setViewResico(true);
			const product = plansAndPricesDeclaration.find(
				(plan) => plan.slug === 'reg_resico_plus',
			);
			if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
				const _priceElasticity = getElasticyPrice(
					Math.round((product?.price ?? 0) / 100),
					elasticityPrice.group_experiment,
				);
				setPrice(_priceElasticity);
			} else {
				setPrice((product?.price ?? 0) / 100);
			}
			setProduct(product?.default_product_version_id ?? 0);
		} else setViewResico(false);

		if (selectedRegime === 'Plataformas tecnológicas') {
			setProduct(0);
			setCount(1);
			setValuePeriods([]);
			const product = plansAndPricesDeclaration.find(
				(plan) => plan.slug === 'reg_pt_full',
			);
			if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
				const _priceElasticity = getElasticyPrice(
					Math.round((product?.price ?? 0) / 100),
					elasticityPrice.group_experiment,
				);
				setPrice(_priceElasticity);
			} else {
				setPrice((product?.price ?? 0) / 100);
			}
			setProduct(product?.default_product_version_id ?? 0);
		}

		if (!selectedRegime) {
			setPrice(lowerPrice);
		}
	}, [selectedRegime, value, plansAndPricesDeclaration]);

	const regimesUser = useMemo(
		() => (regimenCode: string) => {
			if (taxPayer?.status?.tax_regimes?.length === 0) return false;
			const regimes = taxPayer?.status?.tax_regimes?.map((r) => r.code);
			return regimes?.includes(regimenCode);
		},
		[taxPayer?.status?.tax_regimes],
	);

	const getTaxPayer = async () => {
		try {
			if (!userProfile?.id) return;
			dispatch(startLoader(true));
			const resource = await fetchTaxPayer(userProfile?.id, tokenSession);
			await dispatch(addTaxPayer(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	useEffect(() => {
		if (taxPayer) return;
		getTaxPayer();
	}, [taxPayer, userProfile?.id]);

	useEffect(() => {
		if (valuePeriods?.length >= 0) {
			setCount(valuePeriods?.length);
			setPricePeriods(count * price);
		} else {
			setCount(1);
		}
	}, [valuePeriods, count, price]);

	useEffect(() => {
		getStatus();
	}, []);

	const textPeriodsSelected = useMemo(() => {
		if (valuePeriods.length === 0) {
			return 'Por periodo';
		}
		if (valuePeriods.length === 1) {
			return 'Por 1 periodo';
		}
		return `Por ${valuePeriods.length} periodos`;
	}, [valuePeriods]);

	return (
		<>
			{isMobile ? (
				<div className='flex flex-col rounded-md border mb-56'>
					<div className='sticky top-0 z-10 bg-white'>
						<div
							className={`${
								isHotSale ? 'bg-h-hot-sale' : 'bg-h-dark'
							} relative p-4 rounded-t-md flex flex-col items-center`}
						>
							{isHotSale && (
								<div className='mb-12'>
									<div className='absolute left-0 -top-16 flex items-center justify-center'>
										<img
											src={hotSaleImg}
											alt='hot sale heru'
											className='w-56 h-56'
										/>
									</div>
								</div>
							)}

							<Text
								size='xl'
								color='white'
								weight='bold'
							>
								Declaraciones atrasadas
							</Text>

							<div
								className={`border ${
									isHotSale ? 'border-white' : 'border-blue-200'
								} my-4 mx-4 w-full`}
							/>
							<Text
								size='s'
								color='white'
								sx='text-center'
								weight='regular'
							>
								Ponte al día declarando tus periodos pendientes, evita multas,
								sanciones o recargos del SAT.
							</Text>
						</div>

						{isHotSale ? (
							<div className='flex items-center justify-end w-full my-4'>
								<div className='flex items-center mr-4'>
									<Text
										color='gray'
										size={'s'}
										sx='max-w-10 mr-3 text-center'
									>
										Desde
									</Text>
									<Text
										color='gray'
										size={'label'}
										weight='regular'
										sx='line-through'
									>
										${`${valuePeriods.length > 0 ? pricePeriods : price}`}
									</Text>
								</div>
								<div className='flex items-center ml-2'>
									<Text
										color='darkMenu'
										weight='bold'
										size={'s'}
										sx='max-w-10 text-center mr-5'
									>
										HOT HERU
									</Text>
									<Heading
										color='darkMenu'
										size={'xs'}
										weight='medium'
									>
										$
									</Heading>
									<Heading
										color='darkMenu'
										size={'m'}
										weight='medium'
									>
										{`${
											valuePeriods.length > 0
												? (pricePeriods * 0.6).toFixed(0)
												: (price * 0.6).toFixed(0)
										}`}
									</Heading>
									<Text
										color='darkMenu'
										size={'s'}
										sx='mt-5 ml-1'
										weight='bold'
									>
										{textPeriodsSelected}
									</Text>
								</div>
							</div>
						) : (
							<div className='flex flex-row justify-end mr-4 mt-2'>
								<Text
									size='xl'
									weight='bold'
									sx='mt-5 mr-2 text-neutral-400'
								>
									Desde
								</Text>
								<Heading
									size='xs'
									weight='bold'
									sx='mt-3 mr-2'
								>
									$
								</Heading>
								<Heading
									size='l'
									weight='bold'
								>
									{`${valuePeriods.length > 0 ? pricePeriods : price}`}
								</Heading>
								<Text
									size='xl'
									weight='bold'
									sx='mt-1 ml-2 mr-6 w-[60px] text-center leading-[18px]'
								>
									{textPeriodsSelected}
								</Text>
							</div>
						)}
					</div>

					<div className='flex flex-col p-4'>
						{!selectedRegime ? (
							<div className='border-b pb-4'>
								<Text
									size='l'
									color='dark'
									weight='bold'
								>
									Selecciona el régimen a declarar
								</Text>
								<div className='flex flex-col mt-3'>
									{regularizationRegimes.map((item, index) => (
										<div
											className='flex flex-row, mb-2 w-full'
											key={`regularization-regime-${index}`}
										>
											<Radio
												checked={selectedRegime === item.value}
												onChange={handleChange}
												value={item.value}
												name='radio-buttons'
												inputProps={{ 'aria-label': 'A' }}
											/>

											<div className='flex flex-col w-full'>
												<Text size='m'>{item.label}</Text>
												<Text
													size='s'
													color='medium'
												>
													{item.description}
												</Text>
											</div>
											{regimesUser(item.code) && (
												<div className='whitespace-nowrap h-6 px-1.5 py-1 bg-green-500 rounded justify-center items-center inline-flex '>
													<div className='px-1.5 justify-center items-center flex'>
														<Text
															color='white'
															size='xxs'
														>
															Tu régimen
														</Text>
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							</div>
						) : (
							<div className='flex flex-row justify-between items-start'>
								<div className='w-[85%] '>
									<Text
										color='dark'
										size='m'
									>
										{selectedRegime}
									</Text>
									<Text
										size='s'
										color='medium'
									>
										{
											regularizationRegimes.find(
												(item) => item.value === selectedRegime,
											)?.description
										}
									</Text>
								</div>
								<button onClick={() => setSelectedRegime(undefined)}>
									<Pencil
										size={24}
										color='var(--h-blue-60)'
									/>
								</button>
							</div>
						)}

						{selectedRegime === REGIMES_NAME.ARRENDAMIENTO && (
							<>
								{!value ? (
									<div className='flex flex-col py-4 w-full mt-10'>
										<Text
											color='dark'
											weight='bold'
											size='xl'
										>
											¿Cuáles son tus ingresos mensuales aproximados?
										</Text>

										<div className='flex flex-col justify-between border-b py-8'>
											{rentalRevenues.map((item, index) => (
												<div
													className='flex flex-row, mb-2'
													key={`rental-revenues-${index}`}
												>
													<Radio
														checked={value === item.value}
														onChange={(e) => setValue(e.target.value)}
														value={item.value}
														name='radio-buttons'
														inputProps={{ 'aria-label': 'A' }}
													/>

													<div className='flex flex-col'>
														<Text size='m'>{item.label}</Text>
														<Text
															size='s'
															color='medium'
														>
															{item.description}
														</Text>
													</div>
												</div>
											))}
										</div>
									</div>
								) : (
									<div className='flex flex-row py-4 w-full justify-between items-start'>
										<div className='w-[85%]'>
											<Text
												color='dark'
												size='m'
											>
												{
													rentalRevenues.find((item) => item.value === value)
														?.label
												}
											</Text>
											<Text
												size='s'
												color='medium'
											>
												{
													rentalRevenues.find((item) => item.value === value)
														?.description
												}
											</Text>
										</div>
										<button onClick={() => setValue(undefined)}>
											<Pencil
												size={24}
												color='var(--h-blue-60)'
											/>
										</button>
									</div>
								)}
							</>
						)}

						<div className='border-b pb-4 mt-5'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								Selecciona los años que deseas declarar
							</Text>
							<div className='flex flex-col gap-4 mt-2 items-center'>
								{selectedRegime === REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS ? (
									<>
										<Calendar
											fiscalDeclarationStatus={fiscalDeclarationStatus}
											styles={styles}
											periods={handledPeriods}
										/>
										{fiscalDeclarationStatus && (
											<div className='flex flex-col gap-2 w-full max-w-[400px]'>
												<div className='h-[18px] justify-start items-center gap-2 inline-flex mb-2'>
													<div className='w-4 h-4 bg-red-600 rounded-full' />
													<Text size='s'>Mes con declaración atrasada</Text>
												</div>
												<div className='h-[18px] justify-start items-center gap-2 inline-flex'>
													<div className='w-4 h-4 bg-green-500 rounded-full' />
													<Text size='s'>
														Mes con declaraciones presentadas
													</Text>
												</div>
											</div>
										)}
									</>
								) : selectedRegime === REGIMES_NAME.ACTIVIDAD_EMPRESARIAL ? (
									<>
										<CalendarOtherRegimes
											periods={handledPeriods}
											viewActivity={viewActivity}
										/>
										<div className='flex flex-row p-3 bg-neutral-200 rounded-md gap-x-4'>
											<WarningCircle
												size='80px'
												weight='bold'
												color='var(--h-gray-50)'
											/>
											<Text
												size='xs'
												sx='text-neutral-400'
											>
												{messagesBusiness}
											</Text>
										</div>
									</>
								) : (
									<CalendarOtherRegimes
										periods={handledPeriods}
										value={value}
										viewArriendos={viewArriendos}
										viewRif={viewRif}
										viewResico={viewResico}
									/>
								)}
							</div>
						</div>

						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{regularizationBenefits.map((benefit, index) => (
										<div
											key={`regularization-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div
						className={`fixed bottom-12 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg`}
					>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={
										valuePeriods.length === 0 ||
										product === 0 ||
										!selectedRegime
											? 'white'
											: isHotSale
												? 'var(--h-primary)'
												: 'white'
									}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							disabled={
								valuePeriods.length === 0 || product === 0 || !selectedRegime
							}
							onClick={() => {
								handleAddToCart(product, valuePeriods, selectedRegime!);
							}}
							className={`${
								valuePeriods.length === 0 || product === 0 || !selectedRegime
									? 'bg-gray-400'
									: isHotSale
										? 'bg-h-blue-20'
										: 'bg-h-primary'
							} rounded-lg ${
								valuePeriods.length === 0 || product === 0 || !selectedRegime
									? 'text-white'
									: isHotSale
										? 'text-h-primary'
										: 'text-white'
							} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			) : (
				<div className='flex flex-col h-full'>
					<div
						className={`${
							isHotSale ? 'bg-h-hot-sale' : 'bg-h-blue-60'
						} relative p-4 rounded-t-xl flex flex-col items-center`}
					>
						{isHotSale && (
							<div className='mb-12'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}
						<Text
							size='l'
							color='white'
							sx='text-center'
							weight='regular'
						>
							{description}
						</Text>

						<div
							className={`border ${
								isHotSale ? 'border-white' : 'border-blue-200'
							} my-4 mx-4 w-full`}
						/>
						<Heading
							size='m'
							color='white'
							weight='bold'
						>
							{productName}
						</Heading>
					</div>

					{isHotSale ? (
						<div className='flex items-center justify-end w-full my-4'>
							<div className='flex items-center mr-4'>
								<Text
									color='gray'
									size={'label'}
									sx='max-w-10 mr-5 md:mr-8 text-center'
								>
									Desde
								</Text>
								<Text
									color='gray'
									size={'body-1'}
									weight='regular'
									sx='line-through'
								>
									${`${valuePeriods.length > 0 ? pricePeriods : price}`}
								</Text>
							</div>
							<div className='flex items-center ml-2'>
								<Text
									color='darkMenu'
									weight='bold'
									size={'label'}
									sx='max-w-10 text-center mr-5'
								>
									HOT HERU
								</Text>
								<Heading
									color='darkMenu'
									size={'s'}
									weight='medium'
								>
									$
								</Heading>
								<Heading
									color='darkMenu'
									size={'l'}
									weight='medium'
								>
									{`${
										valuePeriods.length > 0
											? (pricePeriods * 0.6).toFixed(0)
											: (price * 0.6).toFixed(0)
									}`}
								</Heading>
								<Text
									color='darkMenu'
									size={'label'}
									sx='mt-5 ml-1'
									weight='bold'
								>
									{textPeriodsSelected}
								</Text>
							</div>
						</div>
					) : (
						<div className='flex flex-row justify-end mr-4 mb-4'>
							{valuePeriods.length === 0 && (
								<Text
									size='xl'
									weight='bold'
									sx='mt-5 mr-2 text-neutral-400'
								>
									Desde
								</Text>
							)}
							<Heading
								size='xs'
								weight='bold'
								sx='mt-3 mr-2'
							>
								$
							</Heading>
							<Heading
								size='l'
								weight='bold'
							>
								{`${valuePeriods.length > 0 ? pricePeriods : price}`}
							</Heading>
							<Text
								size='xl'
								weight='bold'
								sx='mt-1 ml-2 mr-6 w-[60px] text-center leading-[18px]'
							>
								{textPeriodsSelected}
							</Text>
						</div>
					)}

					<div className='flex flex-col overflow-y-auto mb-16'>
						{!selectedRegime ? (
							<div className='flex flex-col p-4 w-full'>
								<div className='mb-2'>
									<Text
										color='dark'
										weight='bold'
										size='xl'
									>
										Selecciona un regimen
									</Text>
								</div>

								<div className='flex flex-col justify-between border-b py-8'>
									{regularizationRegimes.map((item, index) => (
										<div
											className='flex flex-row items-center mb-2 w-full'
											key={`regularization-regime-${index}`}
										>
											<Radio
												checked={selectedRegime === item.value}
												onChange={handleChange}
												value={item.value}
												name='radio-buttons'
												inputProps={{ 'aria-label': 'A' }}
											/>

											<div className='flex flex-col w-full'>
												<Text size='m'>{item.label}</Text>
												<Text
													size='s'
													color='medium'
												>
													{item.description}
												</Text>
											</div>
											{regimesUser(item.code) && (
												<div className='whitespace-nowrap h-6 px-1.5 py-1 bg-green-500 rounded justify-center items-center inline-flex '>
													<div className='px-1.5 justify-center items-center flex'>
														<Text
															color='white'
															size='xxs'
														>
															Tu régimen
														</Text>
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							</div>
						) : (
							<div className='flex flex-row p-4 w-full justify-between items-center'>
								<div>
									<Text
										color='dark'
										size='m'
									>
										{selectedRegime}
									</Text>
									<Text
										size='s'
										color='medium'
									>
										{
											regularizationRegimes.find(
												(item) => item.value === selectedRegime,
											)?.description
										}
									</Text>
								</div>
								<button onClick={() => setSelectedRegime(undefined)}>
									<Pencil
										size={24}
										color='var(--h-blue-60)'
									/>
								</button>
							</div>
						)}

						{selectedRegime === REGIMES_NAME.ARRENDAMIENTO && (
							<>
								{!value ? (
									<div className='flex flex-col p-4 w-full mt-2'>
										<Text
											color='dark'
											weight='bold'
											size='xl'
										>
											¿Cuáles son tus ingresos mensuales aproximados?
										</Text>

										<div className='flex flex-col justify-between pt-6 pb-0'>
											{rentalRevenues.map((item, index) => (
												<div
													className='flex flex-row mb-2'
													key={`rental-revenues-${index}`}
												>
													<Radio
														checked={value === item.value}
														onChange={handledArrendOptions}
														value={item.value}
														name='radio-buttons'
														inputProps={{ 'aria-label': 'A' }}
													/>

													<div className='flex flex-col'>
														<Text size='m'>{item.label}</Text>
														<Text
															size='s'
															color='medium'
														>
															{item.description}
														</Text>
													</div>
												</div>
											))}
										</div>
									</div>
								) : (
									<div className='flex flex-row p-4 w-full justify-between items-center'>
										<div>
											<Text
												color='dark'
												size='m'
											>
												{
													rentalRevenues.find((item) => item.value === value)
														?.label
												}
											</Text>
											<Text
												size='s'
												color='medium'
											>
												{
													rentalRevenues.find((item) => item.value === value)
														?.description
												}
											</Text>
										</div>
										<button onClick={() => setValue(undefined)}>
											<Pencil
												size={24}
												color='var(--h-blue-60)'
											/>
										</button>
									</div>
								)}
							</>
						)}

						<div className='flex flex-col w-full p-4 '>
							<hr className='mb-6' />
							<Text
								color='dark'
								weight='bold'
								size='xl'
							>
								Selecciona los periodos a declarar
							</Text>

							<div className='flex flex-col items-center mt-10 mb-2 border border-h-gray-20 p-6'>
								{selectedRegime === REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS ? (
									<>
										<Calendar
											fiscalDeclarationStatus={fiscalDeclarationStatus}
											styles={styles}
											periods={handledPeriods}
										/>
										{fiscalDeclarationStatus && (
											<div className='flex flex-col gap-2 w-full max-w-[400px]'>
												<div className='h-[18px] justify-start items-center gap-2 inline-flex mb-2'>
													<div className='w-4 h-4 bg-red-600 rounded-full' />
													<Text size='s'>Mes con declaración atrasada</Text>
												</div>
												<div className='h-[18px] justify-start items-center gap-2 inline-flex'>
													<div className='w-4 h-4 bg-green-500 rounded-full' />
													<Text size='s'>
														Mes con declaraciones presentadas
													</Text>
												</div>
											</div>
										)}
									</>
								) : selectedRegime === REGIMES_NAME.ACTIVIDAD_EMPRESARIAL ? (
									<>
										<CalendarOtherRegimes
											periods={handledPeriods}
											viewActivity={viewActivity}
										/>
										<div className='flex flex-row p-3 bg-neutral-200 rounded-md gap-x-4'>
											<WarningCircle
												size='70px'
												weight='bold'
												color='var(--h-gray-50)'
											/>
											<Text
												size='s'
												sx='text-neutral-400'
											>
												{messagesBusiness}
											</Text>
										</div>
									</>
								) : (
									<CalendarOtherRegimes
										periods={handledPeriods}
										value={value}
										viewArriendos={viewArriendos}
										viewRif={viewRif}
										viewResico={viewResico}
									/>
								)}
							</div>

							<div className='flex flex-col border-b'>
								<div className='flex flex-row justify-between py-8'>
									<Text
										color='dark'
										weight='bold'
										size='xl'
									>
										Beneficios
									</Text>
									<button onClick={() => setOpenBenefits(!openBenefits)}>
										{openBenefits ? (
											<CaretUp
												size={24}
												weight='light'
											/>
										) : (
											<CaretDown
												size={24}
												weight='light'
											/>
										)}
									</button>
								</div>

								{openBenefits && (
									<div className='flex flex-col'>
										{regularizationBenefits.map((benefit, index) => (
											<div
												key={`regularization-benefit-${index}`}
												className='flex items-center gap-2.5 mb-4'
											>
												<CheckCircle
													size={24}
													weight='light'
													color='var(--h-primary)'
												/>
												<div className='w-full'>
													<Text
														size='s'
														color='dark'
													>
														{benefit}
													</Text>
												</div>
											</div>
										))}
									</div>
								)}
							</div>

							<div className='fixed bottom-0 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg'>
								<Button
									beforeIcon={
										<ShoppingCart
											size={24}
											weight='fill'
											color={
												valuePeriods.length === 0 ||
												product === 0 ||
												!selectedRegime
													? 'white'
													: isHotSale
														? 'var(--h-primary)'
														: 'white'
											}
											className='mr-2'
										/>
									}
									loading={isLoadingAddProduct}
									label='Agregar al carrito'
									disabled={
										valuePeriods.length === 0 ||
										product === 0 ||
										!selectedRegime
									}
									onClick={() => {
										handleAddToCart(product, valuePeriods, selectedRegime!);
									}}
									className={`${
										valuePeriods.length === 0 ||
										product === 0 ||
										!selectedRegime
											? 'bg-gray-400'
											: isHotSale
												? 'bg-h-blue-20'
												: 'bg-h-primary'
									} rounded-lg ${
										valuePeriods.length === 0 ||
										product === 0 ||
										!selectedRegime
											? 'text-white'
											: isHotSale
												? 'text-h-primary'
												: 'text-white'
									} font-medium w-full flex items-center justify-center py-3`}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const styles = {
	box: { display: 'flex', direction: 'row', fontSize: '14px' },
	textName: { fontSize: '14px', fontWeight: 500 },
	text: { marginRight: '1rem', color: 'var(--font-200)', fontSize: '13px' },
	text2: { textAlign: 'center', marginBottom: '1rem' },
	icon: {
		width: '24px',
		marginRight: '1rem',
		color: 'var(--font-200)',
		fontSize: '13px',
	},
	title: {
		color: '#0A2B34',
		fontSize: '15px',
	},
	div: { textAlign: 'end', marginTop: '1rem' },
	disable: {
		opacity: 0.5,
	},
};

export default Regularization;
