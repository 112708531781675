import { FieldsStatus } from '../../@types/ServiceStatus.type';
import LITERALS from './literals';
import { CONFIG_WHATSAPP_SUPPORT } from './whatsappNumbers';

export enum ACTION_CURRENT_SLUG {
	STAGE_REQUESTED_IDENTIFICATION_FORM = 'stage_requested_identification_form',
	STAGE_REQUESTED_CALL_WITH_USER = 'stage_requested_call_with_user',
	STAGE_REQUESTED_RFC_TAX_VERIFICATION_ASAT = 'stage_requested_rfc_tax_verification_asat',
	STAGE_REQUESTED_E_SIGNATURE_TAX_VERIFICATION_ASAT = 'stage_requested_e_signature_tax_verification_asat',
	STAGE_REQUESTED_STAMPS_TAX_VERIFICATION_ASAT = 'stage_requested_stamps_tax_verification_asat',
	STAGE_REQUESTED_RFC_PROCESSING_SERVICE_ASAT = 'stage_requested_rfc_processing_service_asat',
	STAGE_REQUESTED_E_SIGNATURE_PROCESSING_SERVICE_ASAT = 'stage_requested_e_signature_processing_service_asat',
	STAGE_REQUESTED_STAMPS_PROCESSING_SERVICE_ASAT = 'stage_requested_stamps_processing_service_asat',
	STAGE_REQUESTED_RFC_TAX_VERIFICATION_DECLARATION = 'stage_requested_rfc_tax_verification_declaration',
	STAGE_REQUESTED_VALID_ACTIVITY_PROCESSING_DECLARATION = 'stage_requested_valid_activity_processing_declaration',
	STAGE_REQUESTED_RFC_TAX_VERIFICATION_ANNUAL_DECLARATION = 'stage_requested_rfc_tax_verification_annual_declaration',
	STAGE_REQUESTED_CSF_TAX_VERIFICATION_DECLARATION = 'stage_requested_csf_tax_verification_declaration',
	STAGE_REQUESTED_VALID_ACTIVITY_PROCESSING_ANNUAL_DECLARATION = 'stage_requested_valid_activity_processing_annual_declaration',
	STAGE_SUBSCRIPTION_BLOCKED_FOR_PAYMENT_DECLARATION = 'stage_subscription_blocked_for_payment_declaration',
	STAGE_ABANDONED_STEP_IDENTIFICATION_FORM = 'stage_abandoned_step_identification_form',
	STAGE_ABANDONED_TAX_VERIFICATION_ASAT = 'stage_abandoned_tax_verification_asat',
	STAGE_ABANDONED_PROCESSING_SERVICE_ASAT = 'stage_abandoned_processing_service_asat',
	STAGE_COMPLETED_DECLARATION = 'stage_completed_declaration',
	STAGE_COMPLETED_ANNUAL_DECLARATION = 'stage_completed_annual_declaration',
	STAGE_IN_PROGRESS_VALIDATE_STATUS_DECLARATION = 'stage_in_progress_validate_status_declaration',
	STAGE_IN_PROGRESS_VALIDATE_STATUS_MONTHLY_DECLARATION_IN_ANNUAL_DECLARATION = 'stage_in_progress_validate_status_monthly_declaration_in_annual_declaration',
	STAGE_IN_PROGRESS_ON_HOLD_DECLARATION = 'stage_in_progress_on_hold_declaration',
	STAGE_COMPLETED_CONFIRM_APPOINTMENT_ASAT_TAX_VERIFICATION_ASAT = 'stage_completed_confirm_appointment_asat_tax_verification_asat',
	STAGE_COMPLETED_ASAT = 'stage_completed_asat',
}

export enum VARIANT_STATUS {
	IN_PROGRESS = 'in_progress',
	PENDING = 'pending',
	COMPLETED = 'completed',
	BLOCKED = 'blocked',
	ABANDONED = 'abandoned',
}

export enum GLOBAL_STATUS {
	ACTIVE = 'active',
	CLOSED = 'closed',
}

export const PURCHASED_DATE = '2024-04-22';

export const renderAnnualValidateStatus = (status: FieldsStatus) => {
	if (
		status?.regulations_pending_for_present === 'pending' &&
		status.declarations_pending_for_pay_in_sat === 'pending'
	) {
		return 'Estamos validando el estado de tus declaraciones mensuales';
	} else if (
		status?.regulations_pending_for_present === 'pending' &&
		status.declarations_pending_for_pay_in_sat === 'completed'
	) {
		return 'Tienes declaraciones pendientes por pagar ante el SAT';
	} else if (
		status.regulations_pending_for_present === 'completed' &&
		status.declarations_pending_for_pay_in_sat === 'pending'
	) {
		return (
			<>
				Tienes meses pendientes por regularizar, si ya los compraste pronto te
				avisaremos cuando los hayamos presentado, sino para adquirirlos{' '}
				<a
					className='text-h-primary underline'
					target='_blank'
					rel='noreferrer'
					href={`${CONFIG_WHATSAPP_SUPPORT}?text=${LITERALS.WHATSSAP_REGULARIZATIONS}`}
				>
					haz clic aquí
				</a>
			</>
		);
	} else if (
		status.regulations_pending_for_present === 'completed' &&
		status.declarations_pending_for_pay_in_sat === 'completed'
	) {
		return 'Tienes declaraciones pendientes por pagar ante el SAT y meses pendientes por regularizar';
	}
};
