import { Minus, Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import Text from '../../../components/atoms/Text';

interface Props {
	period: 'monthly' | 'yearly';
}

const HelpDropdown: React.FC<Props> = ({ period }) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	return (
		<div className=' bg-h-neutral-90/20 border border-h-neutral-80/40 py-2 sm:py-3 px-2 sm:px-4 rounded-lg mt-3'>
			<div
				className='flex justify-between items-center cursor-pointer'
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}
			>
				<Text
					size='caption'
					weight='medium'
					color='medium'
				>
					{period === 'monthly'
						? '¿Cómo funcionan las declaraciones mensuales?'
						: '¿Cómo presento mi declaración anual?'}
				</Text>{' '}
				{isDropdownOpen ? <Minus /> : <Plus />}
			</div>

			{isDropdownOpen && (
				<div className='mt-2'>
					<hr className='pt-2' />
					<Text
						size='caption'
						weight='light'
						sx='flex-col'
						color='medium'
					>
						{period === 'monthly' ? (
							<>
								<span>
									Las declaraciones que presentas cada mes se categorizan según
									los regímenes que estás obligado a presentar en ese período
									(plataformas tecnológicas, arrendamientos, RIF, etc){' '}
								</span>
								<span className='mt-2'>
									Además recuerda que por cada régimen puedes haber presentado
									más de una declaración. Si al presentar tu declaración todo ha
									estado correcto al primer intento, genial. Si no, has debido
									presentar una declaración complementaria que corregirá y
									completará tu declaración inicial.{' '}
								</span>
							</>
						) : (
							<>
								<span>
									Para facilitar la presentación de tus declaración anual de
									impuestos, recuerda que debes cumplir con las declaraciones
									mensuales del año anterior.
								</span>
								<span>
									Cada mes, registra ingresos y deducciones, mantén recibos y
									comprobantes organizados. Al final del año, la acumulación
									mensual simplificará el proceso, proporcionando una visión
									integral de tus finanzas.
								</span>
								<span>
									Utiliza esta información para completar fácilmente tu
									declaración anual, asegurándote de cumplir con precisión y
									eficiencia tus obligaciones fiscales.
								</span>
							</>
						)}
					</Text>
				</div>
			)}
		</div>
	);
};

export default HelpDropdown;
