import React, { Fragment } from 'react';

import { CheckFat, ClockCountdown, Lock, SignOut } from '@phosphor-icons/react';

import { useMediaQuery } from '@mui/material';

//? Components
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import HomeCard from '../../molecules/HomeCard';
import { heruWpFailedUnlockPeriod } from '../../../shared/constants/links';
import { ServiceStatusElement } from '../../../@types/ServiceStatus.type';
import {
	ACTION_CURRENT_SLUG,
	VARIANT_STATUS,
} from '../../../shared/constants/serviceStatus';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';

interface BlockedMockProps {
	failedStripeSub: ServiceStatusElement[] | any;
}
interface ActionButtonProps {
	label: string;
	variant:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'outline'
		| 'text'
		| 'danger'
		| 'danger-secondary';
	onClick?: () => void;
	isLoading?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
	label,
	variant,
	onClick,
	isLoading,
}) => {
	return (
		<div className='w-full'>
			<Button
				loading={isLoading}
				onClick={onClick}
				label={label}
				variant={variant}
			/>
		</div>
	);
};

const BlockedMock: React.FC<BlockedMockProps> = ({ failedStripeSub }) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width: 768px)');
	const logClevertapEvent = useCleverTapEvent();

	const onPressUnlock = () => {
		window.open(`${heruWpFailedUnlockPeriod}`, '_blank');
	};

	const getColor = (isCurrentStep: boolean, isCompleted: boolean) => {
		const iconType =
			failedStripeSub?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return 'bg-h-error-50';
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return 'bg-h-neutral-50';
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return 'bg-h-success-50';
			case isCurrentStep:
				return 'bg-h-information-50';
			case !isCurrentStep && isCompleted:
				return 'bg-h-success-50';
			default:
				return 'bg-h-neutral-80';
		}
	};

	const getColorText = (isCurrentStep: boolean, isCompleted: boolean) => {
		const iconType =
			failedStripeSub?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return 'red';
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return 'gray';
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return 'greenSuccess';
			case isCurrentStep:
				return 'blueInformation';
			case !isCurrentStep && isCompleted:
				return 'greenSuccess';
			default:
				return 'gray';
		}
	};

	const getIcon = (isCurrentStep: boolean) => {
		const iconType =
			failedStripeSub?.current_service_has_step?.stage?.icon_type;

		switch (true) {
			case isCurrentStep && iconType === VARIANT_STATUS.BLOCKED:
				return (
					<Lock
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			case isCurrentStep && iconType === VARIANT_STATUS.ABANDONED:
				return (
					<SignOut
						size={18}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);

			case isCurrentStep && iconType === VARIANT_STATUS.IN_PROGRESS:
				return (
					<ClockCountdown
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			case isCurrentStep && iconType === VARIANT_STATUS.COMPLETED:
				return (
					<CheckFat
						size={14}
						weight='fill'
						color='var(--h-text-white)'
					/>
				);
			default:
				return <></>;
		}
	};

	const getStepName = (isCurrentStep: any, name: string) => {
		if (isCurrentStep) {
			if (
				failedStripeSub?.current_service_has_step?.stage?.icon_type ===
				VARIANT_STATUS.BLOCKED
			) {
				return 'Proceso bloqueado';
			} else if (
				failedStripeSub?.current_service_has_step?.stage?.icon_type ===
				VARIANT_STATUS.COMPLETED
			) {
				if (
					failedStripeSub?.service_type_has_product?.product_slug.includes(
						'asat',
					)
				) {
					return 'Completado';
				} else {
					return 'Presentada';
				}
			} else {
				return 'En proceso';
			}
		} else {
			if (
				failedStripeSub?.service_type_has_product?.product_slug.includes('asat')
			) {
				return 'Completado';
			} else {
				return name;
			}
		}
	};

	const goToDetail = () => {
		navigate(`${ROUTES.SERVICE_STATUS}/${failedStripeSub?.id}`, {
			state: { origin: 'home' },
		});
	};

	const renderActionBySlug = (slug: string) => {
		let content = null;

		switch (slug) {
			case ACTION_CURRENT_SLUG.STAGE_SUBSCRIPTION_BLOCKED_FOR_PAYMENT_DECLARATION:
				content = (
					<ActionButton
						onClick={goToDetail}
						label='Desbloquear periodo'
						variant={'primary'}
					/>
				);
				break;
		}
		return (
			<div
				onClick={() => {
					logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
						event_type: EVENT_TYPES.HOME_SERVICE_STATUS_CTA,
					});
				}}
			>
				{content}
			</div>
		);
	};

	const renderDescription = () => {
		return (
			<Text
				size='caption'
				color='gray'
				weight='regular'
				sx='mb-2'
			>
				{failedStripeSub?.current_service_has_step?.stage?.description}
			</Text>
		);
	};

	return (
		<>
			<HomeCard title={'Estado de tu suscripción'}>
				<div className='bg-h-neutral-94 flex flex-col p-4 rounded-lg shadow-md'>
					<div className='flex items-center justify-between'>
						<Text
							size='mini-1'
							color='gray'
							weight='light'
							sx='mb-2'
						>
							Producto
						</Text>
						{failedStripeSub?.product?.regime?.length > 0 && (
							<Text
								size='mini-1'
								color='gray'
								weight='light'
								sx='mb-2'
							>
								Regimen
							</Text>
						)}
					</div>

					<div className='flex items-center justify-between'>
						<Text
							size={isMobile ? 'caption' : 'body-2'}
							color='dark'
							weight='medium'
						>
							{failedStripeSub?.product?.name}
						</Text>

						{failedStripeSub?.product?.regime && (
							<div className='flex flex-col'>
								<div className='bg-h-neutral-94 rounded-lg shadow-md p-2'>
									<Text
										size={isMobile ? 'mini-2' : 'mini-1'}
										color='gray'
										weight='regular'
										sx='text-right'
									>
										{failedStripeSub?.product?.regime?.length > 1
											? `Contratado para ${failedStripeSub?.product?.regime?.length} regímenes`
											: failedStripeSub?.product?.regime?.[0]}
									</Text>
								</div>
							</div>
						)}
					</div>

					<div
						onClick={onPressUnlock}
						className='flex items-center w-full mt-8 mb-6 justify-between px-0 md:px-2 lg:px-4 cursor-pointer'
					>
						{failedStripeSub?.service_overview?.service_has_step_overview.map(
							(step: any, index: any) => (
								<Fragment key={`step-${index}`}>
									<div className='flex flex-col items-center'>
										<div
											className={`${getColor(
												!!step?.current_service_has_step_id,
												step?.completed,
											)} h-8 w-8 rounded-full flex items-center justify-center`}
										>
											{step?.current_service_has_step_id ? (
												<>
													<div className='bg-white w-6 h-6 rounded-full flex items-center justify-center'>
														<div
															className={`${getColor(
																!!step?.current_service_has_step_id,
																step?.completed,
															)} rounded-full w-5 h-5 flex items-center justify-center`}
														>
															{getIcon(!!step?.current_service_has_step_id)}
														</div>
													</div>
												</>
											) : (
												<>
													{index ===
													failedStripeSub?.service_overview
														?.service_has_step_overview.length -
														1 ? (
														<CheckFat
															size={14}
															weight='fill'
															color='var(--h-text-white)'
														/>
													) : (
														getIcon(!!step?.current_service_has_step_id)
													)}
												</>
											)}
										</div>

										<Text
											size='mini-1'
											color={getColorText(
												!!step?.current_service_has_step_id,
												step?.completed,
											)}
											weight='light'
											sx='mt-2 text-center'
										>
											{getStepName(
												step?.current_service_has_step_id,
												step?.name,
											)}
										</Text>
									</div>
									{index <
									failedStripeSub?.service_overview?.service_has_step_overview
										.length -
										1 ? (
										<div
											className={`${getColor(
												!!step.current_service_has_step_id,
												step.completed,
											)} h-[2.5px] w-6 sm:w-28 md:w-40 lg:w-48 rounded-lg mb-5`}
										></div>
									) : null}
								</Fragment>
							),
						)}
					</div>
					<div className='mb-4'>{renderDescription()}</div>
					<div>
						{renderActionBySlug(
							failedStripeSub?.current_service_has_step?.stage?.slug,
						)}
					</div>
				</div>
			</HomeCard>
		</>
	);
};

export default React.memo(BlockedMock);
