import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CaretCircleLeft, CheckCircle, X } from '@phosphor-icons/react';

//? Types
import { Profile } from '../../../../../@types/Profile.type';
import { Organizations } from '../../../../../@types/Organizations.type';

//? Components
import Text from '../../../../../components/atoms/Text';
import Button from '../../../../../components/atoms/Button';
import Modal from '../../../../../components/molecules/Modal/Modal';
import { ALERT_TYPE } from '../../../../../components/molecules/Alert';

//? Services
import {
	fetchAllOrganizations,
	getUberLogin,
	postAssignOrganization,
} from '../../../../../services/organizations.service';
import { getUserProfile } from '../../../../../services/profile.service';

//? Utils
import {
	getBgDesktopByOrg,
	getBgMobileByOrg,
	getBorderColorByOrg,
	isLocalStorageEnabled,
} from '../../../../../shared/utils/general.util';
import { ORGANIZATIONS } from '../../../../../shared/constants/organizations';

//? Redux
import { RootState } from '../../../../../redux/store';
import { showAlert } from '../../../../../redux/slices/alert.slice';
import { setProfile } from '../../../../../redux/slices/profile.slice';
interface Props {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<any>>;
}

export type AssignOrgType = {
	org_code: string;
};

const schema = yup
	.object({
		org_code: yup
			.string()
			.required('Campo requerido')
			.email('Ingrese formato de correo electrónico válido'),
	})
	.required('Campo requerido');

const ModalOrganizations: React.FC<Props> = ({ showModal, setShowModal }) => {
	const dispatch = useDispatch();

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const [step, setStep] = useState(0);
	const [url, setUrl] = useState('');
	const [formValid, setFormValid] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState('');
	const [organizations, setOrganizations] = useState<Organizations[]>([]);
	const [isLoadingAssignOrg, setIsLoadingAssignOrg] = useState(false);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const getOrganizations = async () => {
		try {
			const resource: Organizations[] =
				await fetchAllOrganizations(tokenSession);
			setOrganizations(resource);
		} catch (error: any) {}
	};

	const fetchUrlUber = async () => {
		const ub = await getUberLogin();
		setUrl(ub);
	};

	const getUpdatedProfile = async () => {
		try {
			const userInfo: Profile = await getUserProfile(tokenSession);
			dispatch(setProfile(userInfo));
		} catch (error) {}
	};

	const userOrgs = userProfile?.organizations?.map((org) => org.name);

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm<AssignOrgType>({
		resolver: yupResolver(schema),
		defaultValues: {
			org_code: '',
		},
	});

	const watchValidate = watch();

	const onSubmit = async (body: AssignOrgType) => {
		setIsLoadingAssignOrg(true);
		try {
			const response = await postAssignOrganization(
				organizations.find((org) => org?.name === selectedOrg)?.id ?? 0,
				body,
				tokenSession,
			);

			if (response) {
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: `Vinculado correctamente a ${selectedOrg}`,
				};
				dispatch(showAlert(alert));
				getUpdatedProfile();
				setTimeout(() => {
					setShowModal(false);
					setStep(0);
					reset();
				}, 1000);
			} else {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: `No se ha podido vincular a ${selectedOrg}`,
				};
				dispatch(showAlert(alert));
			}
		} catch (error) {
		} finally {
			setIsLoadingAssignOrg(false);
		}
	};

	useEffect(() => {
		getOrganizations();
		fetchUrlUber();
		getUpdatedProfile();
	}, []);

	useEffect(() => {
		const values = [watchValidate.org_code];
		setFormValid(!values.includes(''));
	}, [watchValidate]);

	const userInactiveOrgs = organizations?.filter(
		(org) =>
			[
				ORGANIZATIONS.RAPPI,
				ORGANIZATIONS.DIDI,
				ORGANIZATIONS.AIRBNB,
				ORGANIZATIONS.UBER,
				ORGANIZATIONS.CORNERSHOP,
				ORGANIZATIONS.MERCADO_ENVIOS,
				ORGANIZATIONS.UBER_EATS,
			].some((keyword) => org?.name?.includes(keyword)) &&
			!userOrgs?.includes(org?.name),
	);

	return (
		<Modal
			open={showModal}
			onClose={handleCloseModal}
			sx='w-[94%] !max-w-[662px]'
			showCloseButton={false}
		>
			{step === 0 && (
				<>
					<div className='absolute top-3 right-3'>
						<button
							onClick={() => setShowModal(false)}
							className='bg-h-primary rounded-full w-5 h-5 flex justify-center items-center'
						>
							<X
								size={12}
								weight='bold'
								className='text-white '
							/>
						</button>
					</div>

					{/* Desktop */}
					<div className='hidden lg:flex lg:flex-col p-4 bg-white'>
						<div className='flex flex-col items-center'>
							<Text
								size='body-1'
								weight='bold'
								color='medium'
								sx='text-center mb-6'
							>
								Selecciona una plataforma
							</Text>
							<Text
								size='body-3'
								weight='regular'
								color='medium'
								sx='mb-6 text-center'
							>
								Selecciona y conecta la plataforma aliada para conocer los
								beneficios que tienes disponibles.
							</Text>
							<div className='flex items-center'>
								{userInactiveOrgs?.map((org) => (
									<button
										key={org.id}
										onClick={() => setSelectedOrg(org.name)}
										className={`mr-4 p-1 rounded-lg ${
											selectedOrg === org.name
												? `border-2 ${getBorderColorByOrg(org.name)}`
												: 'border-none'
										}`}
									>
										<img
											src={org.extra_fields?.icon_url_png}
											alt={org.name}
											width={60}
											height={60}
										/>
									</button>
								))}
							</div>
						</div>

						<div className='flex w-full justify-end mt-6'>
							<div className='w-32'>
								<Button
									onClick={() => setStep(1)}
									disabled={!selectedOrg}
									label='Continuar'
								/>
							</div>
						</div>
					</div>

					{/* Mobile */}
					<div className='flex flex-col lg:hidden p-3 bg-white'>
						<div className='flex flex-col items-center'>
							<Text
								size='body-2'
								weight='bold'
								color='medium'
								sx='text-center mb-6'
							>
								Selecciona una plataforma
							</Text>
							<Text
								size='caption'
								weight='regular'
								color='medium'
								sx='mb-6 text-center'
							>
								Selecciona y conecta la plataforma aliada para conocer los
								beneficios que tienes disponibles.
							</Text>
							<div className='flex flex-wrap justify-center'>
								{userInactiveOrgs?.map((org) => (
									<button
										key={org.id}
										onClick={() => setSelectedOrg(org.name)}
										className={`m-2 p-1 rounded-md ${
											selectedOrg === org.name
												? `border-2 ${getBorderColorByOrg(org.name)}`
												: 'border-none'
										}`}
									>
										<img
											src={org.extra_fields?.icon_url_png}
											alt={org.name}
											width={45}
											height={45}
										/>
									</button>
								))}
							</div>
							<div className='mt-6 w-full'>
								<Button
									onClick={() => setStep(1)}
									disabled={!selectedOrg}
									label='Continuar'
								/>
							</div>
						</div>
					</div>
				</>
			)}
			{step === 1 && (
				<>
					{/* Desktop */}
					<div className='hidden lg:flex'>
						<div className=' w-2/5'>
							<img
								className='w-full h-full object-cover rounded-md'
								src={getBgDesktopByOrg(selectedOrg)}
								alt={selectedOrg}
							/>
							<img
								className='absolute bottom-7 left-7 w-8 h-8'
								src={
									organizations.find((org) => org.name === selectedOrg)
										?.extra_fields?.icon_url_png
								}
								alt={selectedOrg}
							/>
						</div>
						<div className='flex flex-col px-3 w-2/3'>
							<div className='flex items-center justify-between'>
								<button onClick={() => setStep(0)}>
									<CaretCircleLeft
										size={25}
										weight='fill'
										className='mr-3'
										color='var(--h-primary)'
									/>
								</button>
								<button
									onClick={() => setShowModal(false)}
									className='bg-h-primary rounded-full w-5 h-5 flex justify-center items-center'
								>
									<X
										size={12}
										weight='bold'
										className='text-white '
									/>
								</button>
							</div>
							<div className='flex flex-col p-2'>
								<Text
									size='body-1'
									weight='bold'
									color='medium'
									sx='text-center mt-2'
								>
									Sigue los pasos indicados y comienza a disfrutar de los
									beneficios
								</Text>

								<div className='flex flex-col mt-8'>
									{organizations
										.find((org) => org.name === selectedOrg)
										?.extra_fields?.screen.steps.map((step, index) => (
											<div
												className='flex items-center mb-4'
												key={index}
											>
												<div className='flex items-center justify-center rounded-full mr-2.5'>
													<CheckCircle
														size={24}
														weight='light'
														color={'var(--h-primary)'}
													/>
												</div>
												<Text
													size='caption'
													weight='regular'
													color='medium'
												>
													{step}
												</Text>
											</div>
										))}
								</div>

								{!selectedOrg.includes('Uber') && (
									<div className='mt-2 mb-4'>
										<Controller
											name='org_code'
											control={control}
											rules={{
												required: true,
											}}
											render={({ field: { value, onChange } }) => (
												<input
													type='text'
													className={`border border-gray-300 rounded-md w-full px-4 py-2 focus:outline-none bg-white
		flex-1`}
													placeholder={`Ingresa tu correo de ${selectedOrg}`}
													id='emailOrg'
													value={value}
													onChange={onChange}
												/>
											)}
										/>

										{errors?.org_code && (
											<Text
												size='caption'
												weight='regular'
												color='red'
												sx='mt-2'
											>
												{errors?.org_code?.message}
											</Text>
										)}
									</div>
								)}

								{selectedOrg.includes('Uber') ? (
									<div className='flex w-full justify-end mt-4'>
										<div className='w-auto'>
											<Button
												loading={isLoadingAssignOrg}
												onClick={() => {
													window.location.href = url;
													if (isLocalStorageEnabled()) {
														localStorage.setItem('selectedOrg', selectedOrg);
													}
												}}
												label={`Conectar mi cuenta de ${selectedOrg}`}
											/>
										</div>
									</div>
								) : (
									<div className='flex w-full justify-end mt-4'>
										<div className='w-auto'>
											<Button
												disabled={!formValid}
												loading={isLoadingAssignOrg}
												onClick={handleSubmit(onSubmit)}
												label={`Conectar mi cuenta de ${selectedOrg}`}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Mobile */}
					<div className='flex flex-col lg:hidden'>
						<div className=' w-full h-full'>
							<img
								className='w-full h-full object-cover rounded-md'
								src={getBgMobileByOrg(selectedOrg)}
								alt={selectedOrg}
							/>
							<img
								className='absolute top-7 left-7 w-8 h-8'
								src={
									organizations.find((org) => org.name === selectedOrg)
										?.extra_fields?.icon_url_png
								}
								alt={selectedOrg}
							/>
						</div>
						<div className='flex flex-col p-2 w-full'>
							<div className='flex items-center justify-between'>
								<button onClick={() => setStep(0)}>
									<CaretCircleLeft
										size={25}
										weight='fill'
										className='mr-3'
										color='var(--h-primary)'
									/>
								</button>
								<button
									onClick={() => setShowModal(false)}
									className='bg-h-primary rounded-full w-5 h-5 flex justify-center items-center'
								>
									<X
										size={12}
										weight='bold'
										className='text-white '
									/>
								</button>
							</div>

							<div className='flex flex-col p-2 items-center'>
								<Text
									size='body-3'
									weight='bold'
									color='medium'
									sx='text-center mt-2'
								>
									Sigue los pasos indicados y comienza a disfrutar de los
									beneficios
								</Text>

								<div className='flex flex-col mt-8'>
									{organizations
										.find((org) => org.name === selectedOrg)
										?.extra_fields?.screen.steps.map((step, index) => (
											<div
												className='flex items-center mb-4'
												key={index}
											>
												<div className='flex items-center justify-center rounded-full mr-2.5'>
													<CheckCircle
														size={24}
														weight='light'
														color={'var(--h-primary)'}
													/>
												</div>
												<Text
													size='caption'
													weight='regular'
													color='medium'
												>
													{step}
												</Text>
											</div>
										))}
								</div>

								{!selectedOrg.includes('Uber') && (
									<div className='mt-2 mb-4 w-full'>
										<Controller
											name='org_code'
											control={control}
											rules={{
												required: true,
											}}
											render={({ field: { value, onChange } }) => (
												<input
													type='text'
													className={`border border-gray-300 rounded-md w-full px-4 py-2 focus:outline-none bg-white text-sm
		flex-1`}
													placeholder={`Ingresa tu correo de ${selectedOrg}`}
													id='emailOrg'
													value={value}
													onChange={onChange}
												/>
											)}
										/>

										{errors?.org_code && (
											<Text
												size='caption'
												weight='regular'
												color='red'
												sx='mt-2'
											>
												{errors?.org_code?.message}
											</Text>
										)}
									</div>
								)}

								{selectedOrg.includes('Uber') ? (
									<div className='flex w-full justify-end mt-4'>
										<div className='w-full'>
											<Button
												loading={isLoadingAssignOrg}
												onClick={() => {
													window.location.href = url;
													if (isLocalStorageEnabled()) {
														localStorage.setItem('selectedOrg', selectedOrg);
													}
												}}
												label={`Conectar mi cuenta de ${selectedOrg}`}
											/>
										</div>
									</div>
								) : (
									<div className='flex w-full mt-2'>
										<div className='w-full'>
											<Button
												disabled={!formValid}
												loading={isLoadingAssignOrg}
												onClick={handleSubmit(onSubmit)}
												className='rounded-lg border flex gap-2 items-center justify-center px-3 py-3 shadow-sm font-medium transition active:translate-y-[2px] text-sm
												disabled:text-h-light disabled:bg-h-gray-20 disabled:border-none disabled:cursor-not-allowed w-full'
												label={`Conectar mi cuenta de ${selectedOrg}`}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</Modal>
	);
};

export default ModalOrganizations;
