export const TAXES_TYPE = [
	{
	  iva: 0.16,
	  name: "IVA 16%",
	},
	{
	  iva: 0.08,
	  name: "IVA 8%",
	},
	{
	  iva: 0.0,
	  name: "IVA 0%",
	},
	{
	  iva: "E",
	  name: "IVA Exento",
	},
  ];
  