import { Sparkle } from '@phosphor-icons/react';
import Text from '../../components/atoms/Text';
import TypingText from '../../components/molecules/TypingText';
import ProductCard from '../../components/organisms/ProductCard';
import { RecommendedProducts } from '../../@types/Onboarding.type';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DATES, getLastYears } from '../../shared/utils/dates';
import { ListProducts, ProductsCategory } from '../../@types/Products.type';
import { RegimesEnum } from '../../@types/Regime.type';
import {
	MONTHLY_DECLARATION_PRODUCTS_SLUG,
	PRODUCTS_SLUG,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG,
} from '../../shared/constants/SlugsProducts';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';
import Modal from '../../components/molecules/Modal/Modal';
import { Checkbox, useMediaQuery } from '@mui/material';
import Button from '../../components/atoms/Button';
import { formatPrice } from '../../shared/utils/general.util';

enum ProductType {
	SUBSCRIPTION = 'subscription',
	REGULARIZATION = 'regularization',
}

interface Props {
	setStep: React.Dispatch<React.SetStateAction<number>>;

	AIWelcomeMessage: string;
	handleAddProduct: (
		product_id?: number,
		regime?: string,
		period?: number,
	) => void;
	products: ListProducts[];
	isLoadingAddToCart: boolean;
	setRecommendedProduct: React.Dispatch<
		React.SetStateAction<{
			id: number;
			regime: string;
			period: number;
		}>
	>;
}

const regimesOptions = [
	{
		value: RegimesEnum.PLATFORMS,
		label: 'Plataformas tecnológicas',
		description: 'Ganas dinero por aplicaciones o internet',
	},
	{
		value: RegimesEnum.RESICO,
		label: 'RESICO',
		description: 'Ingresos menores a 3.5 mdp/año y sin salario',
	},
	{
		value: RegimesEnum.ENTERPRISE,
		label: 'Actividades Empresariales',
		description: 'Soy independiente o tengo un pequeño negocio',
	},
	{
		value: RegimesEnum.RIF,
		label: 'RIF',
		description: 'Eres emprendedor',
	},
	{
		value: RegimesEnum.LEASE,
		label: 'Arrendamiento',
		description: 'Tus ingresos son de rentas de propiedades',
	},
];

const Recommendations: React.FC<Props> = ({
	AIWelcomeMessage,
	handleAddProduct,
	products,
	isLoadingAddToCart,
	setRecommendedProduct,
}) => {
	const [showModal, setShowModal] = useState({
		subscription: false,
	});

	const [regimeSelected, setRegimeSelected] = useState<string>(
		RegimesEnum.PLATFORMS,
	);

	const [modalType, setModalType] = useState<
		ProductType.REGULARIZATION | ProductType.SUBSCRIPTION
	>(ProductType.SUBSCRIPTION);

	let AIParams = {
		type: 'platforms',
		activity: '',
	};

	const navigate = useNavigate();

	const options = useMemo(() => {
		const numYears = 5;
		return getLastYears(numYears, DATES.currentYear - 1).map((year) => ({
			value: year,
			label: year,
		}));
	}, []);

	const { control, watch } = useForm({
		defaultValues: { value: options?.[0]?.value ?? '' },
	});

	const getProductBySlug = (slug: string) => {
		const allProducts = products.flatMap((item) => item.products);
		return allProducts.find((item) => item.slug === slug);
	};

	const slugByYearAnnual =
		watch('value') === DATES.currentYear - 1
			? PRODUCTS_SLUG.ANNUAL_DECLARATION
			: PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD;

	const subscriptionSlugByRegime: Record<string, string> = {
		[RegimesEnum.PLATFORMS]: PRODUCTS_SLUG.DECLARATION,
		[RegimesEnum.RESICO]: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_RESICO_PLUS,
		[RegimesEnum.ENTERPRISE]: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_ACT_EMP,
		[RegimesEnum.LEASE]: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_ARR_MES,
		[RegimesEnum.RIF]: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_RIF,
	};

	const regularizationSlugByRegime: Record<string, string> = {
		[RegimesEnum.PLATFORMS]: PRODUCTS_SLUG.REGULARIZATION,
		[RegimesEnum.RESICO]:
			REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
		[RegimesEnum.ENTERPRISE]:
			REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
		[RegimesEnum.LEASE]: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
		[RegimesEnum.RIF]: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
	};

	const handleClickSubscription = (
		type: ProductType.REGULARIZATION | ProductType.SUBSCRIPTION,
	) => {
		if (isLoadingAddToCart) return;

		const slug =
			type === ProductType.SUBSCRIPTION
				? subscriptionSlugByRegime[regimeSelected]
				: regularizationSlugByRegime[regimeSelected];

		handleAddProduct(
			getProductBySlug(slug)?.default_product_version_id,
			parseRegimes(AIParams.type),
		);
	};

	const functions: Record<
		string,
		(productSlug: string) => RecommendedProducts[]
	> = useMemo(
		() => ({
			asat: (productSlug: string): RecommendedProducts[] => {
				if (productSlug === PRODUCTS_SLUG.ASAT_SIMPLE) {
					return [
						{
							message: 'Te recomendamos:',
							product: {
								productName: 'Servicio de Trámites con el SAT Basic',
								description: 'Servicio de Trámites con el SAT Basic',
								tagLabel: 'Más vendido',
								price: getProductBySlug(productSlug)?.price,
								buttonLabel: 'Agregar al carrito y continuar',
								benefits:
									getProductBySlug(productSlug)?.benefits.slice(0, 3) ?? [],
								handleClick: () =>
									handleAddProduct(
										getProductBySlug(productSlug)?.default_product_version_id,
									),
							},
						},
					];
				}
				return [
					{
						message: 'Te recomendamos:',
						product: {
							productName: 'Servicio de Trámites con el SAT Plus',
							description: 'Servicio de Trámites con el SAT Plus',
							tagLabel: 'Más vendido',
							price: getProductBySlug(productSlug)?.price,
							buttonLabel: 'Agregar al carrito y continuar',
							benefits:
								getProductBySlug(productSlug)?.benefits.slice(0, 3) ?? [],
							handleClick: () =>
								handleAddProduct(
									getProductBySlug(productSlug)?.default_product_version_id,
								),
						},
					},
				];
			},
			annual: (): RecommendedProducts[] => {
				return [
					{
						message:
							'Para que puedas presentar tu declaración anual te recomendamos:',
						product: {
							productName: 'Declaraciones Anuales',
							options,
							description:
								'Deja tu declaración anual de impuestos en manos de los expertos. ¡Presentamos tu declaración anual hasta 5 años atrás!',
							tagLabel: 'Recomendado',
							price: getProductBySlug(slugByYearAnnual)?.price,
							buttonLabel: 'Agregar al carrito y continuar',
							benefits: [
								'Evaluamos más de 50 mil productos deducibles para favorecer tus saldos de impuestos.',
								'Hacemos el seguimiento correspondiente a tu devolución automática de saldo a favor.',
								'Resolvemos todas tus dudas sobre Declaración Anual.',
							],
							handleClick: () =>
								handleAddProduct(
									getProductBySlug(slugByYearAnnual)
										?.default_product_version_id,
									parseRegimes(AIParams.type),
									watch('value'),
								),
						},
					},
				];
			},
			subscription: (productSlug: string): RecommendedProducts[] => {
				return [
					{
						message: 'Ó nuestro paquete básico:',
						product: {
							productName: 'Contabilidad básica',
							description:
								'Tu contabilidad mensual 100% cubierta para una sola fuente de ingreso',
							price: getProductBySlug(productSlug)?.price,
							periodicity: '/mes',
							buttonLabel: 'Agregar al carrito y continuar',
							benefits: [
								'Cubre una única fuente de ingreso.',
								'Identificamos y asociamos automáticamente todos tus ingresos y gastos.',
								'Analizamos todos tus gastos con inteligencia artificial en busca de deducibles.',
							],
							beforePrice: 'Desde',
							handleClick: () => {
								setShowModal({ ...showModal, subscription: true });
								setModalType(ProductType.SUBSCRIPTION);
							},
						},
					},
				];
			},
			regularization: (productSlug: string): RecommendedProducts[] => {
				return [
					{
						message: 'Para que te pongas al día con el SAT te recomendamos:',
						product: {
							productName: 'Declaraciones atrasadas',
							description:
								'Ponte al día declarando tus periodos pendientes, evita multas, sanciones o recargos del SAT.',
							tagLabel: 'Más vendido',
							price: getProductBySlug(productSlug)?.price,
							buttonLabel: 'Agregar al carrito y continuar',
							benefits: [
								'Presentamos tu declaración pendiente en 7 días hábiles o menos para evitar recargos.',
								'Paga menos impuestos con ayuda de nuestra tecnología.',
								'Resolvemos todas tus dudas sobre tus declaraciones pendientes.',
							],
							periodicity: '/por periodo',
							handleClick: () => {
								setShowModal({ ...showModal, subscription: true });
								setModalType(ProductType.REGULARIZATION);
							},
						},
					},
				];
			},
			csf: () => {
				return [
					{
						message: 'Te recomendamos:',
						product: {
							productName: 'Servicio de Trámites con el SAT Plus',
							description: 'Servicio de Trámites con el SAT Plus',
							tagLabel: 'Más vendido',
							price: getProductBySlug(PRODUCTS_SLUG.ASSISTANCE_ASAT)?.price,
							buttonLabel: 'Agregar al carrito y continuar',
							benefits:
								getProductBySlug(PRODUCTS_SLUG.ASSISTANCE_ASAT)?.benefits.slice(
									0,
									3,
								) ?? [],
							handleClick: () =>
								handleAddProduct(
									getProductBySlug(PRODUCTS_SLUG.ASSISTANCE_ASAT)
										?.default_product_version_id,
								),
						},
					},
				];
			},
			invoicing: () => {
				return [
					{
						message: 'Para realizar tus facturas te recomendamos:',
						product: {
							productName: 'Servicio de facturación',
							description:
								'Muy útil para realizar tus facturas más fácilmente.',
							tagLabel: 'Más fácil',
							price: 0,
							buttonLabel: 'Continuar',
							benefits: [
								'Solo necesitas tu RFC y contraseña del SAT.',
								'Comienza a facturar en minutos.',
								'Trámite 100% seguro.',
							],
							periodicity: '/ GRATIS',
							handleClick: () => handleAddProduct(),
							onSkip: () => navigate(ROUTES.INVOICING),
						},
					},
				];
			},
		}),
		[watch('value')],
	);

	const parseRegimes = (activity?: string) => {
		const regimes: Record<string, string> = {
			platforms: RegimesEnum.PLATFORMS,
			resico: RegimesEnum.RESICO,
			enterprise: RegimesEnum.ENTERPRISE,
			salaried: RegimesEnum.SALARIED,
			lease: RegimesEnum.LEASE,
			multiple: RegimesEnum.MULTIPLE,
		};

		if (activity) {
			return regimes[activity];
		}
		return RegimesEnum.PLATFORMS;
	};

	const getParamsByRecommendation = () => {
		if (AIWelcomeMessage === ProductsCategory.ASAT) {
			return AIParams.type === 'basic'
				? PRODUCTS_SLUG.ASAT_SIMPLE
				: PRODUCTS_SLUG.ASSISTANCE_ASAT;
		}

		switch (AIWelcomeMessage) {
			case ProductsCategory.Subscription:
				const subscriptions: Record<string, string> = {
					platforms: PRODUCTS_SLUG.DECLARATION,
					resico: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_RESICO_PLUS,
					enterprise: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_ACT_EMP,
					lease: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_ARR_MES,
					multiple: MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_FULL,
				};
				if (subscriptions[AIParams.type]) return subscriptions[AIParams.type];
				return MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_FULL;
			case ProductsCategory.Annual:
				const annual: Record<string, string> = {
					platforms: PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
					resico: PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC,
					enterprise: PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
					salaried: PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC,
					lease: PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
				};
				if (annual[AIParams.type]) return annual[AIParams.type];
				return PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD;
			case ProductsCategory.Regularization:
				const regularization: Record<string, string> = {
					platforms: PRODUCTS_SLUG.REGULARIZATION,
					resico: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
					enterprise: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
					lease: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
				};
				if (regularization[AIParams.type]) return regularization[AIParams.type];
				return REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES;
			case ProductsCategory.csf:
				return '';
			case ProductsCategory.invoicing:
				return '';
			default:
				return '';
		}
	};

	const getRecommendedProducts = functions[AIWelcomeMessage](
		getParamsByRecommendation(),
	);

	useEffect(() => {
		setRecommendedProduct({
			id: getProductBySlug(getParamsByRecommendation())
				?.default_product_version_id!,
			regime: parseRegimes(AIParams.type),
			period: watch('value'),
		});
	}, [AIParams.type, AIWelcomeMessage]);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const getPriceBySlug = () => {
		const slug =
			modalType === ProductType.SUBSCRIPTION
				? subscriptionSlugByRegime[regimeSelected]
				: regularizationSlugByRegime[regimeSelected];
		const product = getProductBySlug(slug);
		const productPrice = product?.price ?? 0;
		return formatPrice(productPrice);
	};

	return (
		<>
			<Modal
				open={showModal.subscription}
				onClose={() => setShowModal({ ...showModal, subscription: false })}
				sx='w-[94%] !max-w-md'
			>
				<Text
					weight='bold'
					color='medium'
				>
					${getPriceBySlug()} /Mes
				</Text>
				<hr className='my-2' />
				<Text
					weight='bold'
					color='medium'
				>
					Selecciona tu régimen
				</Text>
				<div className='flex flex-col mt-3 gap-1'>
					{regimesOptions.map((item, index) => (
						<div
							key={`regime-index-${index}`}
							className='flex items-center gap-3 px-2 py-1 rounded-lg hover:bg-h-neutral-94 cursor-pointer'
							onClick={() => {
								setRegimeSelected(item.value);
							}}
						>
							<Checkbox
								checked={regimeSelected === item.value}
								className='w-2 h-2'
							/>
							<div>
								<Text
									size={isMobile ? 'caption' : 'body-3'}
									weight='medium'
								>
									{item.label}
								</Text>
								<Text
									size={isMobile ? 'mini-1' : 'caption'}
									color='medium'
								>
									{item.description}
								</Text>
							</div>
						</div>
					))}
				</div>
				<Button
					label='Continuar'
					onClick={() => handleClickSubscription(modalType)}
					sx='mt-4'
					loading={isLoadingAddToCart}
				/>
			</Modal>
			<div className='pt-8 w-fit max-w-[370px] mx-auto z-10 relative'>
				<Text
					size='m'
					color='primary'
					weight='medium'
					sx='uppercase flex gap-2 items-center mt-2'
				>
					Tenemos un producto para ti
					<Sparkle
						size={22}
						color='var(--h-primary)'
						weight='fill'
					/>
				</Text>
				{getRecommendedProducts.map((item, index) => (
					<div key={`recommendation-index-${index}`}>
						<TypingText
							text={item.message}
							duration={100}
						/>
						<div className='mt-4 flex flex-col gap-6'>
							<ProductCard
								options={item.product.options}
								productName={item.product.productName}
								description={item.product.description}
								tagLabel={item.product.tagLabel}
								price={item.product.price}
								periodicity={item.product.periodicity ?? ''}
								buttonLabel={item.product.buttonLabel}
								benefits={item.product.benefits}
								handleClick={item.product.handleClick}
								productId={item.product.productId}
								regime={item.product.regime}
								beforePrice={item.product.beforePrice}
								control={control}
								isLoadingButton={isLoadingAddToCart}
								onSkip={item.product.onSkip}
							/>
						</div>
						{index !== getRecommendedProducts.length - 1 && (
							<hr className=' w-full mt-6 mb-6' />
						)}
					</div>
				))}
			</div>
		</>
	);
};

export default Recommendations;
