import {
	Control,
	Controller,
	FieldErrors,
	UseFieldArrayRemove,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
} from 'react-hook-form';
import Text from '../../../components/atoms/Text';
import { File, FileSearch, Info, TrashSimple } from '@phosphor-icons/react';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import InvoiceStampsInfo from '../molecules/InvoiceStampsInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ProfileState } from '../../../redux/slices/profile.slice';
import { useEffect, useMemo, useState } from 'react';
import InvoiceFormField from '../molecules/InvoiceFormField';
import paymentForm from '../../../shared/constants/paymentForm';
import { InvoicingFormBody } from '../newInvoiceFlow';
import { Tooltip } from 'antd';
import { TAXES_TYPE } from '../../../shared/constants/taxesType';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import Modal from '../../../components/molecules/Modal/Modal';
import PrimaryButton from '../../../components/atoms/Button';

import { ReactElement, isValidElement } from 'react';
import { List, ListRowProps } from 'react-virtualized';
import React from 'react';

type ListboxComponentProps = React.HTMLAttributes<HTMLElement> & {
	children: React.ReactNode;
	role: string;
};

const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	ListboxComponentProps
>((props, ref) => {
	const { children, role, ...other } = props;
	const items = React.Children.toArray(children) as ReactElement[];
	const itemCount = items.length;
	const itemSize = 40;
	const listHeight = itemSize * itemCount;

	return (
		<div ref={ref}>
			<div {...other}>
				<List
					height={Math.min(listHeight, 250)}
					width={300}
					rowHeight={itemSize}
					overscanCount={5}
					rowCount={itemCount}
					rowRenderer={(listRowProps: ListRowProps) => {
						if (isValidElement(items[listRowProps.index])) {
							return React.cloneElement(items[listRowProps.index], {
								style: listRowProps.style,
							});
						}
						return null;
					}}
					role={role}
					containerStyle={{ width: '100%', maxWidth: '100%' }}
					style={{ width: '100%' }}
				/>
			</div>
		</div>
	);
});

type Props = {
	control: Control<InvoicingFormBody, any>;
	formErrors: FieldErrors<InvoicingFormBody>;
	fields: Record<'id', string>[];
	setValue: UseFormSetValue<InvoicingFormBody>;
	trigger: UseFormTrigger<InvoicingFormBody>;
	watch: UseFormWatch<InvoicingFormBody>;
	remove: UseFieldArrayRemove;
	isNewReceiver: boolean;
};

const InvoiceStep2: React.FC<Props> = ({
	control,
	formErrors,
	fields,
	setValue,
	trigger,
	watch,
	remove,
	isNewReceiver,
}) => {
	const logClevertapEvent = useCleverTapEvent();
	const { catalogs, userProducts, stampStatus } = useSelector<
		RootState,
		ProfileState
	>((state) => state.profile);
	const [showModal, setShowModal] = useState({
		deleteProduct: false,
	});
	const [productSelectedIndex, setProductSelectedIndex] = useState<number>(0);

	const productsCatalog = useMemo(() => {
		const products = Object.values(catalogs?.key_prod_serv ?? {}).map(
			(val) => ({
				value: val.code,
				label: `${val.code} - ${val.name}`,
			}),
		);

		return products;
	}, [catalogs]);

	const areRetentionsDisabled =
		stampStatus?.code?.length === 13 &&
		(isNewReceiver
			? watch('new_receiver_rfc')?.length === 13
			: watch('saved_receiver')?.length === 13);

	const onChangeNewProductSelector = async (index: number, value: string) => {
		const valueId = value?.split(' - ')[0];
		const valueName = value?.split(' - ')[1];
		const productSelectedDescription =
			Object.values(catalogs?.key_prod_serv ?? {})?.find(
				(product) => product?.code === valueId,
			)?.description || valueName;
		setValue(`products.${index}.description`, productSelectedDescription);
		setValue(`products.${index}.retentions`, 'false');
		await trigger(`products.${index}.description`);
		await trigger(`products.${index}.retentions`);
	};

	const onChangeSavedProductSelector = async (index: number) => {
		const productSelected = userProducts?.find(
			(product) =>
				product?.key_prod_serv === watch(`products.${index}.product_id`),
		);

		if (!productSelected) return;

		const {
			description,
			quantity,
			unitary_value,
			discount,
			has_retentions,
			iva,
		} = productSelected;

		const applyRetentions = areRetentionsDisabled ? false : has_retentions;

		setValue(`products.${index}.description`, description);
		setValue(`products.${index}.quantity`, quantity.toString());
		setValue(`products.${index}.price`, unitary_value.toString());
		setValue(`products.${index}.discount`, discount.toString());
		setValue(
			`products.${index}.retentions`,
			applyRetentions ? 'true' : 'false',
		);
		setValue(`products.${index}.tax`, iva.toString());

		await trigger(`products.${index}.description`);
		await trigger(`products.${index}.quantity`);
		await trigger(`products.${index}.price`);
		await trigger(`products.${index}.discount`);
		await trigger(`products.${index}.retentions`);
		await trigger(`products.${index}.tax`);
	};

	const isPartialPayment = watch('payment_method') === 'PPD';

	const onChangeVAT = async (index: number) => {
		if (['0', 'E'].includes(watch(`products.${index}.tax`)))
			setValue(`products.${index}.retentions`, 'false');
	};

	const handleRemoveProduct = () => {
		remove(productSelectedIndex);
		setShowModal({ ...showModal, deleteProduct: false });
		setProductSelectedIndex(0);

		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT, {
			event_type: EVENT_TYPES.INVOICE_CONFIRM_DELETE_PRODUCT,
		});
	};

	const handleCloseRemoveProductModal = () => {
		setShowModal({ ...showModal, deleteProduct: false });
		setProductSelectedIndex(0);

		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT, {
			event_type: EVENT_TYPES.INVOICE_BACK_DELETE_PRODUCT,
		});
	};

	const toggleProductNewOrSaved = (index: number, isNewProduct: boolean) => {
		setValue(
			`products.${index}.is_new_product`,
			isNewProduct ? 'false' : 'true',
		);
		setValue(`products.${index}.product_id`, '');
		setValue(`products.${index}.description`, '');
		setValue(`products.${index}.quantity`, '');
		setValue(`products.${index}.price`, '');
		setValue(`products.${index}.tax`, '');
	};

	useEffect(() => {
		if (isPartialPayment) {
			setValue('payment_type', '99');
		}
	}, [isPartialPayment]);

	useEffect(() => {
		watch('products').forEach((_, index) => {
			if (areRetentionsDisabled) {
				setValue(`products.${index}.retentions`, 'false');
			}
			trigger(`products.${index}.retentions`);
		});
	}, [areRetentionsDisabled]);

	return (
		<div>
			<Modal
				open={showModal.deleteProduct}
				sx='w-[94%] !max-w-md'
				onClose={handleCloseRemoveProductModal}
			>
				<Text
					sx='!text-center !block'
					weight='bold'
					size='body-3'
				>
					Eliminar Producto
				</Text>
				<Text sx='!text-center !block my-4'>
					Eliminarás toda la información que ingresaste de este producto
				</Text>
				<PrimaryButton
					label='Eliminar'
					sx='mb-2'
					onClick={handleRemoveProduct}
				/>
				<PrimaryButton
					variant={'outline'}
					label='Cancelar'
					onClick={handleCloseRemoveProductModal}
				/>
			</Modal>
			<InvoiceStampsInfo
				hideInfo
				sx='mb-5'
			/>

			<Text
				size='body-3'
				weight='medium'
			>
				¿Cómo recibiste el pago?
			</Text>
			<InvoiceFormField
				disabled
				control={control}
				label='Tipo de factura'
				fieldName='invoice_type'
				formErrors={formErrors}
				type='selector'
				options={[
					{
						label: 'Ingresos',
						value: 'income',
					},
				]}
				tooltip='La factura de ingreso se usa cuando vendes un producto o brinda un servicio.'
			/>
			<InvoiceFormField
				control={control}
				label='Método de pago'
				fieldName='payment_method'
				formErrors={formErrors}
				type='selector'
				options={Object.values(catalogs?.payment_method || {}).map((type) => ({
					label: type.name,
					value: type.code,
				}))}
				tooltip='Selecciona si tu venta fue con un pago único, o si pago diferido.'
			/>

			<div className='flex flex-col sm:flex-row sm:gap-5 w-full'>
				<div className='w-full'>
					<InvoiceFormField
						disabled={isPartialPayment}
						control={control}
						label='Forma de pago'
						fieldName='payment_type'
						formErrors={formErrors}
						type='selector'
						options={paymentForm.map((payment) => ({
							label: payment.name,
							value: payment.code,
						}))}
						tooltip='Selecciona de que forma te han pagado.'
					/>
				</div>
				<div className='w-full'>
					<InvoiceFormField
						disabled
						control={control}
						label='Moneda'
						fieldName='exchange_rate'
						formErrors={formErrors}
						type='selector'
						options={[
							{
								label: 'MXN',
								value: 'mxn',
							},
						]}
						tooltip='Selecciona la moneda en la que has recibido tu pago.'
					/>
				</div>
			</div>

			<hr className='mt-5' />
			<Text
				size='body-3'
				weight='medium'
				sx='mt-5 mb-2'
			>
				¿Qué producto quieres facturar?
			</Text>

			<div className='flex flex-col gap-2'>
				{fields.map((_, index) => {
					const isNewProduct =
						watch(`products.${index}.is_new_product`) === 'true';

					const showProductFields = !!watch(`products.${index}.description`);

					return (
						<div
							key={`field-index-${index}`}
							className={`${index !== 0 ? 'mt-2 sm:mt-5' : ''}`}
						>
							{index !== 0 && (
								<div className='w-full flex justify-end sm:-mb-8'>
									<button
										type='button'
										onClick={() => {
											setShowModal({ deleteProduct: true });
											setProductSelectedIndex(index);
										}}
										className='p-2 transition 
									bg-h-red-50/10 hover:bg-h-red-50/20 rounded-md text-h-red-50'
									>
										<TrashSimple
											weight='bold'
											width={20}
											height={20}
										/>
									</button>
								</div>
							)}
							{!!userProducts?.length && !isNewProduct ? (
								<InvoiceFormField
									control={control}
									label='Selecciona un producto o servicio guardado'
									fieldName={`products.${index}.product_id`}
									formErrors={formErrors}
									customError={formErrors?.products?.[index]?.product_id}
									type='selector'
									options={userProducts.map((product) => ({
										label: `${product?.key_prod_serv} - ${product?.description}`,
										value: product?.key_prod_serv,
									}))}
									tooltip='Elige un producto o servicio guardado o crea uno nuevo.'
									onChangeFn={() => {
										onChangeSavedProductSelector(index);
									}}
									watch={watch}
								/>
							) : (
								<div>
									<div className='flex gap-1 items-center mt-4 mb-3'>
										<Text size='caption'>Productos o servicios</Text>
										<Tooltip
											title={
												'Debes poner la clave de acuerdo al tipo de servicio o producto del catalogo del SAT.'
											}
											placement='top'
										>
											<Info />
										</Tooltip>
									</div>
									<Controller
										name={`products.${index}.product_id`}
										control={control}
										render={({ field }) => {
											const { onChange, ref } = field;
											const value = watch(`products.${index}.product_id`);

											return (
												<>
													<Autocomplete
														disableListWrap
														size='small'
														ListboxComponent={
															ListboxComponent as React.ComponentType<
																React.HTMLAttributes<HTMLElement>
															>
														}
														value={
															value
																? productsCatalog.find((option) => {
																		return value === option.value;
																	}) ?? null
																: null
														}
														getOptionLabel={(option) => option.label}
														onChange={(_, newValue) => {
															onChange(newValue ? newValue.value : null);
															onChangeNewProductSelector(
																index,
																newValue?.label ?? '',
															);
														}}
														id='controllable-states-demo'
														options={productsCatalog}
														renderInput={(params) => (
															<TextField
																{...params}
																error={
																	!!formErrors?.products?.[index]?.product_id
																}
																label='Selecciona un producto o servicio'
																inputRef={ref}
															/>
														)}
													/>
													{formErrors?.products?.[index]?.product_id && (
														<FormHelperText
															error={true}
															style={{ marginLeft: 10 }}
														>
															{
																formErrors?.products?.[index]?.product_id
																	?.message
															}
														</FormHelperText>
													)}
												</>
											);
										}}
									/>
								</div>
							)}
							{!!userProducts?.length && (
								<div
									onClick={() => toggleProductNewOrSaved(index, isNewProduct)}
									className='w-fit flex items-center gap-2 text-h-primary transition hover:bg-h-primary-90/50 py-1 px-2 rounded-md mt-2 cursor-pointer'
								>
									{isNewProduct ? <File /> : <FileSearch />}
									{isNewProduct
										? 'Usar un producto guardado'
										: 'Usar un nuevo producto'}
								</div>
							)}

							{showProductFields && (
								<>
									<InvoiceFormField
										customError={formErrors?.products?.[index]?.description}
										control={control}
										label='Describe el producto o servicio'
										fieldName={`products.${index}.description`}
										formErrors={formErrors}
										type='input-text'
										tooltip='Deja la descripción del producto o servicio que estás prestando'
										watch={watch}
									/>
									<div className='grid grid-cols-2 gap-x-2 sm:gap-x-5'>
										<InvoiceFormField
											customError={formErrors?.products?.[index]?.unit}
											control={control}
											label='Unidad de medida'
											fieldName={`products.${index}.unit`}
											formErrors={formErrors}
											type='selector'
											tooltip='Debes poner la unidad que especifica en qué presentación se ofrece el servicio o producto. Ej. Kilo, pieza, litro, servicio.'
											options={Object.values(catalogs?.key_unity ?? {}).map(
												(unit) => ({
													label: unit.name,
													value: unit.code,
												}),
											)}
											watch={watch}
										/>
										<InvoiceFormField
											customError={formErrors?.products?.[index]?.quantity}
											control={control}
											label='Cantidad'
											fieldName={`products.${index}.quantity`}
											formErrors={formErrors}
											type='input-number'
											tooltip='Cantidad del producto que has vendido de acuerdo a la medida que seleccionaste.'
											watch={watch}
										/>
										<InvoiceFormField
											customError={formErrors?.products?.[index]?.price}
											control={control}
											label='Precio'
											fieldName={`products.${index}.price`}
											formErrors={formErrors}
											type='input-number'
											tooltip='Costo del producto o servicio que hayas brindado antes de impuestos.'
											watch={watch}
										/>
										<InvoiceFormField
											customError={formErrors?.products?.[index]?.discount}
											control={control}
											label='Descuento fijo'
											fieldName={`products.${index}.discount`}
											formErrors={formErrors}
											type='input-number'
											tooltip='Captura el descuento que se aplica en el producto o servicio de la factura.'
											watch={watch}
										/>
										<InvoiceFormField
											onChangeFn={() => {
												onChangeVAT(index);
											}}
											customError={formErrors?.products?.[index]?.tax}
											control={control}
											label='Impuesto'
											fieldName={`products.${index}.tax`}
											formErrors={formErrors}
											type='selector'
											tooltip='Debes poner el % de impuesto de IVA que aplica para el producto o servicio que estás facturando.'
											options={TAXES_TYPE.map((tax) => ({
												label: tax.name,
												value: tax.iva.toString(),
											}))}
											watch={watch}
										/>
										<InvoiceFormField
											disabled={
												areRetentionsDisabled ||
												['0', 'E'].includes(watch(`products.${index}.tax`))
											}
											customError={formErrors?.products?.[index]?.retentions}
											control={control}
											label='Retenciones'
											fieldName={`products.${index}.retentions`}
											formErrors={formErrors}
											type='selector'
											tooltip='Se aplican retenciones a tu factura.'
											options={[
												{ label: 'No', value: 'false' },
												{ label: 'Sí', value: 'true' },
											]}
											watch={watch}
										/>
									</div>
								</>
							)}
							<hr className='mt-6' />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default InvoiceStep2;
