import React, { useCallback, useEffect, useState } from 'react';
import Text from '../../atoms/Text';
import InputFile from '../../atoms/InputFile';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LITERALS from '../../../shared/constants/literals';
import { SATEFirmaForm } from '.';
import Modal from '../Modal/Modal';
import { convertFiletoBase64 } from '../../../shared/utils/general.util';
import { onSendCertificates } from '../../../services/invoicing.service';
import { AlertPayload, showAlert } from '../../../redux/slices/alert.slice';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_TYPE } from '../Alert';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import { getProducts } from '../../../services/plans.service';
import { ListProducts } from '../../../@types/Products.type';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { RootState } from '../../../redux/store';

type Props = {
	show: boolean;
	onRecall: () => void;
	onHide: () => void;
	step: number;
};

const schemaEFirma = yup
	.object({
		key: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		cert: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.MIN_LENGTH_PASSWORD)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const SatLinkModal: React.FC<Props> = ({ show, onRecall, onHide, step }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [products, setProducts] = useState<ListProducts[]>([]);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logClevertapEvent = useCleverTapEvent();

	const {
		control: controlEFirma,
		handleSubmit: handleSubmitEFirma,
		formState: { errors: errorsEFirma },
	} = useForm<SATEFirmaForm>({
		resolver: yupResolver(schemaEFirma),
		defaultValues: {
			cert: undefined,
			key: undefined,
			password: '',
		},
	});

	const onSubmitEFirma: SubmitHandler<SATEFirmaForm> = async (data) => {
		try {
			setIsLoading(true);
			let cert = await convertFiletoBase64(data.cert);
			let key = await convertFiletoBase64(data.key);
			//@ts-expect-error
			const certificate = cert.split(';base64,')[1] ?? '';
			//@ts-expect-error
			const privateKey = key.split(';base64,')[1] ?? '';
			await onSendCertificates(
				{
					password: data.password,
					certificate,
					privateKey,
				},
				tokenSession,
			);
			showAlertTop({
				title: 'Éxito',
				type: ALERT_TYPE.SUCCESS,
				description:
					'Proceso exitoso. Los cerficados fueron cargados satisfactoriamente',
			});
			setIsLoading(false);
			logClevertapEvent(
				step === 1
					? ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA
					: ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT,
				{
					event_type: EVENT_TYPES.INVOICE_SAVE_STAMP,
					success: true,
				},
			);
			onRecall();
		} catch (error: any) {
			logClevertapEvent(
				step === 1
					? ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA
					: ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT,
				{
					event_type: EVENT_TYPES.INVOICE_SAVE_STAMP,
					success: false,
				},
			);
			setIsLoading(false);
			if (error?.response?.data?.message) {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description:
						error?.response?.data?.message === 'The password is invalid'
							? 'Contraseña incorrecta'
							: error?.response?.data?.message,
				});
				return;
			}
			if (typeof error?.response?.data === 'string') {
				const data = JSON.parse(error.response.data);
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: data.Message ?? 'Ha ocurrido un error',
				});
			}
		}
	};

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};
	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			setProducts(data);
		} catch (error) {}
	}, []);
	const getAsatPlus = products[3]?.products?.filter(
		(item) => item?.slug === 'asistencia_asat_2.0',
	);

	const handleNavigateASAT = () => {
		logClevertapEvent(
			step === 1
				? ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA
				: ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT,
			{
				event_type: EVENT_TYPES.INVOICE_ASAT_PRODUCT_SHOPPING_CAR,
			},
		);
		navigate(
			`${ROUTES.CHECKOUT}?product=${getAsatPlus[0]?.product_versions[0]?.id}`,
			{ replace: true },
		);
	};

	const handleSubmit = () => {
		handleSubmitEFirma(onSubmitEFirma)();
	};

	useEffect(() => {
		getAllProducts();
	}, []);
	return (
		<Modal
			open={show}
			sx='w-[94%] !max-w-md'
			onClose={() => onHide()}
		>
			<form onSubmit={handleSubmitEFirma(onSubmitEFirma)}>
				<div className='flex flex-col gap-4 pt-6'>
					<Text
						size='xl'
						sx={'font-bold'}
					>
						Sube tus sellos digitales
					</Text>
					<p>
						Para emitir facturas, es necesario que subas tus sellos digitales.
						<span className='text-h-blue-60'>
							{' '}
							Una vez que hayas subido tus sellos, podrás timbrar y emitir
							facturas de manera rápida y sencilla, teniéndolos siempre
							disponibles para su uso.
						</span>
					</p>
					<InputFile
						control={controlEFirma}
						name='cert'
						accept='.cer'
						errorMessage={errorsEFirma.cert?.message as string}
						label='Sube tu certificado (.cer)'
						sx={'border-dashed'}
					/>
					<InputFile
						control={controlEFirma}
						name='key'
						accept='.key'
						errorMessage={errorsEFirma.key?.message as string}
						label='Sube tu llave (.key)'
					/>
					<Input
						label='Contraseña clave privada'
						showTooltip
						tooltipMessage='Contraseña clave privada'
						control={controlEFirma}
						name='password'
						type='password'
						placeholder='Contraseña clave privada'
						errorMessage={errorsEFirma.password?.message}
						maxLength={50}
						rules={{ required: true }}
					/>
					<Button
						disabled={isLoading}
						label={'No tengo sellos digitales'}
						variant='outline'
						className='border-none text-h-primary'
						onClick={handleNavigateASAT}
					/>
					<Button
						onClick={handleSubmit}
						loading={isLoading}
						disabled={
							!!errorsEFirma.key ||
							!!errorsEFirma.cert ||
							!!errorsEFirma.password ||
							isLoading
						}
						label={'Subir archivos'}
						type='button'
					/>
				</div>
			</form>
		</Modal>
	);
};

export default React.memo(SatLinkModal);
