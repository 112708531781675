import React from 'react';
import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';
import PrimaryButton from '../../../components/atoms/Button';
import { Trophy } from '@phosphor-icons/react';
import { formatCurrency } from '../../../shared/utils/general.util';

type Props = {
	tagLabel?: string;
	imageUrl: string;
	productName: React.ReactNode;
	subtitle?: string;
	price: number;
	beforePrice?: string;
	afterPrice?: string;
	description: string;
	buttonVariant?: 'primary' | 'secondary';
	buttonText: string;
	onClick: () => void;
	isLoading?: boolean;
};

const ProductCard: React.FC<Props> = ({
	tagLabel,
	imageUrl,
	productName,
	subtitle,
	price,
	beforePrice,
	afterPrice,
	description,
	buttonVariant,
	buttonText,
	onClick,
	isLoading,
}) => {
	return (
		<div className='w-full shadow rounded-lg bg-white relative max-w-[400px] mx-auto fade-in overflow-hidden'>
			{tagLabel && (
				<div className='flex items-center gap-2 bg-h-primary-90 px-3 py-2 w-fit absolute top-3 left-3 rounded-full'>
					<Trophy weight='duotone' />
					<Text
						size='caption'
						weight='medium'
					>
						{tagLabel}
					</Text>
				</div>
			)}
			<div className='min-h-36 md:min-h-44 bg-h-primary-90 rounded-t-lg overflow-hidden'>
				<img
					className='h-36 md:h-44 object-cover rounded-t-lg overflow-hidden w-full'
					src={imageUrl}
					alt={subtitle}
				/>
			</div>
			<div className='p-4'>
				<div className='text-center'>
					<Heading
						sx='!block mx-[10%]'
						weight='bold'
						size='s'
					>
						{productName}
					</Heading>
					<Text
						sx='!block -mt-2'
						weight='medium'
						size='label'
					>
						{subtitle}
					</Text>
				</div>
				<div className='flex gap-3 w-fit mx-auto my-4'>
					<Text
						size='label'
						weight='medium'
					>
						{beforePrice}
					</Text>
					<div className='flex items-center gap-1'>
						<Heading size='xs'>$</Heading>
						<Heading size='m'>{formatCurrency(price, false)}</Heading>
					</div>
					<Text
						size='label'
						weight='medium'
					>
						{afterPrice}
					</Text>
				</div>
				<Text
					size='caption'
					weight='light'
					sx='h-10'
				>
					{description}
				</Text>
				<PrimaryButton
					variant={buttonVariant}
					label={buttonText}
					sx='mt-6'
					onClick={onClick}
					loading={isLoading}
				/>
			</div>
		</div>
	);
};

export default ProductCard;
