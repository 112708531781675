export const heruModalWidget = 'https://api.whatsapp.com/send/?phone=5215513751952&text=Hola,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20c%C3%B3mo%20funciona%20Heru%20y%20sus%20beneficios.&type=phone_number&app_absent=0';

export const heruAppStore = 'https://apps.apple.com/mx/app/heru/id1528061230';
export const heruPlayStore =
	'https://play.google.com/store/apps/details?id=com.heruapp&hl=es_MX&gl=MX';

export enum LINKS {
	SAT_ID_LINK = 'https://satid.sat.gob.mx/',
}

export const useSATLinkFlow = true;

export const heruWhatsappFailed = 'https://api.whatsapp.com/send?phone=5215513751952&text=Hola%2C%20tengo%20un%20problema%20con%20el%20pago%20de%20mi%20suscripci%C3%B3n%20y%20quiero%20ayuda';

export const heruWpFailedUnlockPeriod = 'https://api.whatsapp.com/send?phone=5215513751952&text=Hola%2C%20tengo%20un%20problema%20con%20el%20pago%20de%20mi%20suscripci%C3%B3n%20y%20quiero%20ayuda';