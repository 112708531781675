import { useMediaQuery } from '@mui/material';
import React from 'react';
import Container from '../../../components/atoms/Container';
import Modal from '../../../components/molecules/Modal/Modal';

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	sx?: string;
	sxContainer?: string;
	maxWidth?: string;
}

const SideToModalContainer: React.FC<Props> = ({
	isOpen,
	handleClose,
	children,
	maxWidth = '960px',
	sx,
	sxContainer,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${maxWidth})`);

	if (!isOpen) return null;

	return (
		<>
			{isMobile ? (
				<Modal
					showCloseButton={false}
					open
					onClose={handleClose}
					sx='w-[94%] !max-w-[662px] overflow-y-auto'
				>
					<div className={sx}>{children}</div>
				</Modal>
			) : (
				<Container
					sx={`w-full lg:w-1/3 min-h-[calc(100vh-200px)] ${sxContainer}`}
				>
					<div className={sx}>{children}</div>
				</Container>
			)}
		</>
	);
};

export default SideToModalContainer;
