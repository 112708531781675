import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '@phosphor-icons/react';
import Text from '../../atoms/Text';
import ROUTES from '../../../shared/constants/routes';
import SESSION_STORAGE_KEY from '../../../shared/constants/sessionStorageKey';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { useDispatch, useSelector } from 'react-redux';
import {
	addAdditionalInformation,
	addProductToCart,
	setOverwriteUtm,
	updateUTMs,
} from '../../../redux/slices/cart.slice';
import { DATES } from '../../../shared/utils/dates';
import { RootState } from '../../../redux/store';

interface CountDownDeclarationProps {
	initialValue: number;
	initialDate: Date;
	endDate: Date;
	label?: string;
	bgColor?: string;
	textColor?: string;
	bgColorValue?: string;
	bgImage?: string;
}

const BannerCount = ({
	initialDate,
	endDate,
	initialValue,
	label,
	bgColor,
	textColor,
	bgColorValue,
	bgImage,
}: CountDownDeclarationProps) => {
	const [declarationsLeft, setDeclarationsLeft] = useState(initialValue);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const calculateCurrentValue = () => {
		const now = new Date();
		const totalMilisegsInitToend = endDate.getTime() - initialDate.getTime();

		const milisegsPass = now.getTime() - initialDate.getTime();

		const timepassed = milisegsPass / totalMilisegsInitToend;

		let currentValue = Math.max(
			initialValue - Math.floor(timepassed * initialValue),
			0,
		);

		currentValue = Math.min(currentValue, initialValue);

		return currentValue;
	};

	useEffect(() => {
		setDeclarationsLeft(calculateCurrentValue());
		const interval = setInterval(() => {
			setDeclarationsLeft(calculateCurrentValue());
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	const handlerBuyProduct = () => {
		// const { utm_campaign, utm_medium, utm_source } =
		// 	PLANS_UTM_VALUES.COUNTDOWN_ANNUAL_PACK;
		if (isSessionStorageEnabled()) {
			sessionStorage.setItem(
				SESSION_STORAGE_KEY.PRODUCTS_ADDED,
				JSON.stringify([
					PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC_WITH_REGULARIZATION,
				]),
			);
			sessionStorage.setItem(
				SESSION_STORAGE_KEY.YEAR_ADDED,
				`${DATES.currentYear - 1}`,
			);

			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			// if (overwriteUtm === 'true') {
			// 	sessionStorage.setItem(campaignKey, utm_campaign);
			// 	sessionStorage.setItem(mediumKey, utm_medium);
			// 	sessionStorage.setItem(sourceKey, utm_source);
			// } else {
			// 	if (!sessionStorage.getItem(campaignKey)) {
			// 		sessionStorage.setItem(campaignKey, utm_campaign);
			// 	}
			// 	if (!sessionStorage.getItem(mediumKey)) {
			// 		sessionStorage.setItem(mediumKey, utm_medium);
			// 	}
			// 	if (!sessionStorage.getItem(sourceKey)) {
			// 		sessionStorage.setItem(sourceKey, utm_source);
			// 	}
			// }
		} else {
			dispatch(
				addProductToCart(
					JSON.stringify([
						PRODUCTS_SLUG.ANNUAL_DECLARATION_BASIC_WITH_REGULARIZATION,
					]),
				),
			);
			dispatch(addAdditionalInformation({ year: `${DATES.currentYear - 1}` }));

			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				// dispatch(updateUTMs(PLANS_UTM_VALUES.COUNTDOWN_ANNUAL_PACK));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				// const updatedUTMs = {
				// 	...currentUTMs,
				// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
				// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
				// 	utm_source: currentUTMs?.utm_source || utm_source,
				// };
				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};

				dispatch(updateUTMs(updatedUTMs));
			}
		}

		navigate(ROUTES.CHECKOUT);
	};

	return (
		<div
			className='w-full rounded-md'
			style={{ backgroundColor: bgColor ? bgColor : '#001f48' }}
		>
			<div
				className='flex flex-col lg:flex-row justify-between md:items-center py-2 md:py-4 px-4 md:px-6 bg-cover bg-no-repeat gap-2'
				style={{ backgroundImage: `url(${bgImage})` }}
			>
				<Text
					size='body-1'
					color='darkMenu'
					sx='!hidden md:!block'
				>
					¡Apurate!, Quedan{' '}
					<span
						className=' px-3 text-[23px] font-bold rounded mx-1'
						style={{
							color: textColor ? textColor : '#080D30',
							backgroundColor: bgColorValue ? bgColorValue : '#F4F9FE',
						}}
					>
						{declarationsLeft}
					</span>{' '}
					{label ? <strong>{label}</strong> : 'Declaraciones anuales a'}
				</Text>

				<div className='md:hidden flex justify-between items-center'>
					<div className='flex items-center w-1/4'>
						<div className='flex flex-col'>
							<Text
								size='mini-1'
								color='darkMenu'
								weight='regular'
								sx='!block w-[100%] !text-[16px] mr-2'
							>
								¡Apurate!, Quedan{' '}
							</Text>
						</div>
						<span
							className='bg-white px-3 text-[16px] font-bold rounded mx-1 '
							style={{ color: textColor ? textColor : '#080D30' }}
						>
							{declarationsLeft}
						</span>{' '}
					</div>

					<span className='text-h-dark-menu text-[15px] w-[44%] font-bold leading-none text-right'>
						{label ? label : 'Declaraciones anuales a'}{' '}
					</span>
				</div>

				<div
					onClick={handlerBuyProduct}
					className='hidden md:flex md:items-center cursor-pointer'
				>
					<Text
						size='body-1'
						color='darkMenu'
						weight='bold'
						sx='mr-1'
					>
						Compra ahora con
					</Text>
					<Text
						size='body-1'
						color='darkMenu'
						weight='bold'
					>
						50% OFF
					</Text>
					<ArrowRight
						color={'var(--h-blue-70)'}
						size={20}
						className='ml-2'
					/>
				</div>
				<div
					onClick={handlerBuyProduct}
					className='md:hidden flex items-center justify-center cursor-pointer'
				>
					<Text
						size='mini-1'
						color='darkMenu'
						weight='regular'
						sx='mr-1'
					>
						Compra ahora con
					</Text>
					<Text
						size='mini-1'
						color='darkMenu'
						weight='bold'
					>
						50% OFF
					</Text>
					<ArrowRight
						color={'var(--h-blue-70)'}
						size={20}
						className='ml-2'
					/>
				</div>
			</div>
		</div>
	);
};

export default BannerCount;
