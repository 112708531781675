export enum PERIODICITY_TYPES {
	MONTHLY = 'Mensual',
	ANNUAL = 'Anual',
	BIMONTHLY = 'Bimestral',
	QUARTERLY = 'Trimestral',
	BIANNUAL = 'Semestral',
}

export const HERU_TERMS_AND_CONDITIONS =
	'https://ayuda.heru-app.com/terminoscondiciones-heru';

export enum PROMO_PRICES {
	ANNUAL_BACKLOG_REGULAR_PRICE = 800,
	ANNUAL_BACKLOG_REGULARIZATION_PRICE = 800,
}

export const ANNUAL_COUNTDOWN_DECLARATION = {
	initialValue: 2000,
	initialDate: new Date('2024-04-03T00:00:00'),
	endDate: new Date('2024-04-12T23:59:59'),
	price: 800,
};

export const ANNUAL_COUNTDOWN_DECLARATION_BANNER_PACK = {
	initialValue: 1000,
	initialDate: new Date('2024-04-17T10:00:00'),
	endDate: new Date('2024-04-22T23:59:59'),
};

export const ANNUAL_PRODUCTS_VERSIONS = [
	27, 28, 29, 30, 32, 33, 34, 44, 45, 46, 47, 48, 130,
];
