import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type UTM = {
	utm_source: string;
	utm_campaign: string;
	utm_medium: string;
};
export interface CartState {
	numbersOfItems: number;
	products?: string;
	utmInfo?: UTM;
	additionalInformation?: Record<string, string>;
	couponCode?: string;
	overwriteUtm?: boolean;
}

const initialState: CartState = {
	numbersOfItems: 0,
	products: '',
	utmInfo: {
		utm_source: '',
		utm_campaign: '',
		utm_medium: '',
	},
	couponCode: '',
	overwriteUtm: false,
};

export const cartSlice = createSlice({
	name: 'Cart',
	initialState,
	reducers: {
		updateAmountOfItems: (state, action: PayloadAction<number>) => {
			state.numbersOfItems = action.payload;
		},
		updateUTMs: (state, action: PayloadAction<UTM>) => {
			state.utmInfo = action.payload;
		},
		addProductToCart: (state, action: PayloadAction<string>) => {
			state.products = action.payload;
		},
		addAdditionalInformation: (
			state,
			action: PayloadAction<Record<string, string>>,
		) => {
			state.additionalInformation = action.payload;
		},
		setCouponCode: (state, action: PayloadAction<string>) => {
			state.couponCode = action.payload;
		},
		clearCart: (state) => {
			state.products = '';
			state.additionalInformation = {};
			state.couponCode = '';
		},
		setOverwriteUtm: (state, action: PayloadAction<boolean>) => {
			state.overwriteUtm = action.payload;
		}
	},
});

export const {
	updateAmountOfItems,
	updateUTMs,
	addProductToCart,
	addAdditionalInformation,
	setCouponCode,
	clearCart,
	setOverwriteUtm,
} = cartSlice.actions;
