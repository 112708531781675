import { useEffect, useState } from 'react';
import Modal from '../../molecules/Modal/Modal';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

interface Props {
	open?: boolean;
}

const MissingProfileInfoModal = ({ open }: Props) => {
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const [isOpen, setIsOpen] = useState(open ?? false);
	const navigate = useNavigate();
	useEffect(() => {
		setIsOpen(open ?? false);
	}, [open]);
	const handleClose = () => {
		setIsOpen(false);
	};

	const goToProfile = () => {
		navigate(ROUTES.ACCOUNT_HERU);
	};

	return (
		<Modal
			showCloseButton
			open={isOpen}
			onClose={handleClose}
			sx='overflow-y-auto w-[90%] lg:w-fit-content !p-0'
		>
			<div className='flex flex-col lg:flex-row'>
				<img
					src='https://heru-static-assets.s3.us-east-2.amazonaws.com/Mask-group.webp'
					alt='hombre sonriendo'
					className='w-[300px] object-cover hidden lg:block'
				/>
				<img
					src='https://heru-static-assets.s3.us-east-2.amazonaws.com/mobile.webp'
					alt='hombre sonriendo'
					className='block lg:hidden'
				/>

				<div className='ml-10 mr-5 flex flex-col items-center gap-4 lg:gap-y-8 mt-5'>
					<Text
						sx='text-center lg:text-xl'
						size='body-3'
						color='medium'
						weight='bold'
					>
						¡Completa tu perfil!
					</Text>

					<Text sx='!block text-center'>
						Necesitamos tu {!userProfile?.first_name && <strong>nombre</strong>}
						{`${!userProfile?.first_name && !userProfile?.email ? ' y ' : ''}`}
						{!userProfile?.email && <strong>correo electrónico</strong>} para
						mejorar tu experiencia. Completa esta información ahora.
					</Text>

					<Button
						label='Completar mi perfil'
						sx='mb-5 mt-2 lg:mt-0'
						onClick={goToProfile}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default MissingProfileInfoModal;
