import React from 'react';
import {
	getTextColor,
	getTextSize,
	getTextWeight,
} from '../../../shared/utils/styles.util';

export type TTextColor =
	| 'dark'
	| 'medium'
	| 'light'
	| 'white'
	| 'primary'
	| 'ultraBlueLight'
	| 'green'
	| 'red'
	| 'gray'
	| 'blueInformation'
	| 'greenSuccess'
	| 'darkMenu'
	| 'hotSale'
	| 'blueLight';

export type TTextSize =
	| 'xxs'
	| 'xs'
	| 's'
	| 'm'
	| 'l'
	| 'xl'
	| 'subtitle'
	| 'label'
	| 'body-1'
	| 'body-2'
	| 'body-3'
	| 'caption'
	| 'mini-1'
	| 'mini-2';
interface Props {
	children: React.ReactNode;
	color?: TTextColor;
	size?: TTextSize;
	weight?: 'light' | 'regular' | 'medium' | 'bold';
	sx?: string;
	beforeIcon?: React.ReactNode;
	responsive?: boolean;
	truncate?: boolean;
}

const Text = ({
	children,
	color = 'dark',
	size = 'm',
	sx,
	weight = 'regular',
	beforeIcon,
	responsive,
	truncate,
}: Props) => {
	const getTextSizeResponsive = (textSize: string) => {
		switch (textSize) {
			case 'xl':
			case 'body-1':
			case 'subtitle':
				return 'text-lg leading-[25px]';
			case 'l':
			case 'body-2':
				return 'text-base leading-6';
			case 'm':
			case 'body-3':
			case 'label':
				return 'text-sm leading-[18px]';
			case 's':
			case 'caption':
				return 'text-xs';
			case 'xs':
			case 'mini-1':
				return 'text-[10px]';
			case 'xxs':
			case 'mini-2':
				return 'text-[10px]';
			default:
				return 'text-base leading-6';
		}
	};

	return (
		<p
			className={`flex gap-2 items-center ${getTextColor(color)} ${
				responsive ? getTextSizeResponsive(size) : getTextSize(size)
			} ${getTextWeight(weight)} ${sx} ${truncate && '!truncate !block'}`}
		>
			{beforeIcon}
			{children}
		</p>
	);
};

export default React.memo(Text);
