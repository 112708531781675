import SideToModalContainer from '../../taxesAndInvoices/molecules/SideToModalContainer';
import Text from '../../../components/atoms/Text';
import Tag from '../../../components/molecules/Tag';
import PrimaryButton from '../../../components/atoms/Button';
import {
	FileX,
	MinusCircle,
	PaperPlaneTilt,
	PlusCircle,
	XCircle,
} from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
	getInvoiceDetail,
	sendCancelInvoice,
	sendDetailEmail,
} from '../../../services/invoicing.service';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { CircularProgress } from '@mui/material';
import { formatCurrency } from '../../../shared/utils/general.util';
import Modal from '../../../components/molecules/Modal/Modal';
import { useFieldArray, useForm } from 'react-hook-form';
import Selector from '../../../components/atoms/Selector';
import ROUTES from '../../../shared/constants/routes';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import ERROR from '../../../shared/constants/errors';
import { InvoiceDetail } from '../../../@types/Invoicing.type';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ProfileState } from '../../../redux/slices/profile.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import InvoiceFormField from '../molecules/InvoiceFormField';
import { REGEX } from '../../../shared/constants/regex';

const cancelMotives = [
	{ value: 2, label: 'Comprobante emitido con errores sin relación' },
	{ value: 3, label: 'No se llevó a cabo la operación' },
	{ value: 4, label: 'Operación nominativa relacionada en la factura global' },
];

export const emailResolver = yup
	.object({
		emails: yup.array().of(
			yup.object({
				email: yup
					.string()
					.matches(REGEX.emailRegex, 'Por favor ingresa un email válido')
					.email('Por favor ingresa un email válido')
					.required('Por favor ingresa un email'),
			}),
		),
	})
	.required();

type Props = {
	getUserInvoiceIssued: () => void;
};

const IssuedInvoicePreview: React.FC<Props> = ({ getUserInvoiceIssued }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { invoice_id } = useParams();

	const [isLoading, setIsLoading] = useState({
		invoiceDetail: false,
		shareInvoice: false,
	});
	const [showModal, setShowModal] = useState({
		cancellation: false,
		shareInvoice: false,
	});
	const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetail>();
	const [invoiceIsSended, setInvoiceIsSended] = useState(false);

	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { user } = useSelector<RootState, ProfileState>(
		(state) => state.profile,
	);

	const {
		control: cancelControl,
		handleSubmit: handleCancelSubmit,
		formState: { isDirty, isValid },
	} = useForm<{ cancelMotive: number }>({
		defaultValues: {},
		mode: 'onChange',
	});

	const {
		control: controlShareInvoice,
		handleSubmit: handleShareInvoiceSubmit,
		watch: watchShareInvoice,
		reset: resetShareInvoice,
		trigger,
		formState: { errors: formErrors },
	} = useForm<{
		emails: { email: string | undefined }[];
	}>({
		mode: 'onChange',
		//@ts-ignore
		resolver: yupResolver(emailResolver),
		defaultValues: {
			emails: [{ email: '' }],
		},
	});

	const { fields, append, remove } = useFieldArray<any>({
		control: controlShareInvoice,
		name: 'emails',
	});

	const onCancelInvoice = async (body: any) => {
		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_INVOICE_DETAILS, {
			event_type: EVENT_TYPES.INVOICE_CANCEL,
		});

		setIsLoading({ ...isLoading, invoiceDetail: true });
		try {
			const response = await sendCancelInvoice(
				invoice_id!,
				body.cancelMotive,
				tokenSession,
			);

			setInvoiceDetail(response);

			await new Promise((resolve) => setTimeout(resolve, 3000));
			getUserInvoiceIssued();
			const alert = {
				type: ALERT_TYPE.SUCCESS,
				title: 'La factura fue cancelada exitosamente',
				description: '',
				duration: 2000,
			};
			dispatch(showAlert(alert));
			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_INVOICE_DETAILS, {
				event_type: EVENT_TYPES.INVOICE_CONFIRM_CANCELLATION,
				cancel_reason: body.cancelMotive,
			});
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.error_to_cancel_invoice,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, invoiceDetail: false });
			setShowModal({ ...showModal, cancellation: false });
		}
	};

	const onShareInvoice = async (values: any) => {
		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_INVOICE_DETAILS, {
			event_type: EVENT_TYPES.INVOICE_RESEND,
		});

		setIsLoading({ ...isLoading, shareInvoice: true });

		const arrayEmails = values?.emails?.map(
			(email: { email: string }) => email.email,
		);

		try {
			const body = {
				to: arrayEmails,
				user_id: user!.id,
			};
			await sendDetailEmail(invoice_id!, body, tokenSession);
			setInvoiceIsSended(true);
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, shareInvoice: false });
		}
	};

	const cantAddMoreEmails =
		watchShareInvoice('emails')?.length > 4 || !!formErrors?.emails?.length;

	const handleAddNewEmail = async () => {
		await trigger('emails');
		if (formErrors?.emails || fields.length > 4) return;
		append({ email: '' });
	};

	const taxDetails =
		invoiceDetail?.taxes?.map(
			(tax: { total: number; type: string; name: string }) => ({
				label: `${tax.type === 'withheld' ? 'Retenciones' : ''} ${tax.name}`,
				value: formatCurrency(tax?.total),
			}),
		) ?? [];

	const invoiceDetails = [
		{
			label: 'Número de factura',
			value: invoiceDetail?.complement?.tax_stamp?.uuid ?? '',
		},
		{
			label: 'Tipo de factura',
			value: 'Ingresos',
		},
		{
			label: 'Moneda de pago',
			value: 'MXN',
		},
	];

	const getDetail = useCallback(async () => {
		setIsLoading({ ...isLoading, invoiceDetail: true });
		try {
			const response = await getInvoiceDetail(invoice_id ?? '', tokenSession);
			if (!response) {
				navigate(ROUTES.INVOICING);
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: ERROR.failed_to_fetch_data,
					description: ERROR.try_again_later,
					duration: 2000,
				};
				dispatch(showAlert(alert));
			}
			setInvoiceDetail(response);
		} catch (error) {
		} finally {
			setIsLoading({ ...isLoading, invoiceDetail: false });
		}
	}, [invoice_id]);

	const handleClose = () => {
		navigate(ROUTES.INVOICING);
	};

	const handleCloseShareInvoice = () => {
		setShowModal({ ...showModal, shareInvoice: false });
		setInvoiceIsSended(false);
		resetShareInvoice();
	};

	const handleDownload = (url: string, type: 'pdf' | 'xml') => {
		window.open(url, '_blank');

		if (type === 'pdf') {
			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_INVOICE_DETAILS, {
				event_type: EVENT_TYPES.INVOICE_DOWNLOAD_PDF,
			});
		} else {
			logClevertapEvent(ANALYTICS_EVENTS.INVOICE_INVOICE_DETAILS, {
				event_type: EVENT_TYPES.INVOICE_DOWNLOAD_XML,
			});
		}
	};

	useEffect(() => {
		if (invoice_id) getDetail();
	}, [invoice_id]);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: '/invoice-detail',
		});
	}, []);

	if (!invoice_id) return null;

	const isCancelled = !!invoiceDetail?.cancellation_voucher_xml_url;

	return (
		<>
			<Modal
				open={showModal.cancellation}
				sx='w-[94%] !max-w-md'
				onClose={() => setShowModal({ ...showModal, cancellation: false })}
			>
				<div>
					<Text
						weight='medium'
						sx='mb-4'
					>
						Confirma el motivo de la cancelación
					</Text>

					<form onSubmit={handleCancelSubmit(onCancelInvoice)}>
						<Selector
							options={cancelMotives}
							control={cancelControl}
							name='cancelMotive'
							sx='w-full'
						/>
						<div className='w-full flex justify-center mt-4'>
							<PrimaryButton
								variant='danger'
								label='Cancelar Factura'
								disabled={!isDirty || !isValid}
								type='submit'
								loading={isLoading.invoiceDetail}
							/>
						</div>
					</form>
				</div>
			</Modal>

			<Modal
				open={showModal.shareInvoice}
				sx='w-[94%] !max-w-md'
				onClose={() => setShowModal({ ...showModal, shareInvoice: false })}
			>
				{invoiceIsSended ? (
					<>
						<Text
							size='body-2'
							weight='bold'
							sx='mb-2'
						>
							Factura enviada exitosamente a:
						</Text>
						{watchShareInvoice('emails')?.map(
							(
								email: {
									email: string | undefined;
								},
								index: number,
							) => (
								<Text
									key={`email-${index}`}
									sx='!block'
								>
									{email.email}
								</Text>
							),
						)}
						<PrimaryButton
							label='Cerrar'
							onClick={handleCloseShareInvoice}
							sx='mt-4'
						/>
					</>
				) : (
					<div>
						<Text
							weight='medium'
							sx='mb-4 pr-4'
						>
							Confirma el correo al que quieres que enviemos la factura
						</Text>

						<form onSubmit={handleShareInvoiceSubmit(onShareInvoice)}>
							<div className='flex flex-col gap-2'>
								{fields.map((item, index) => {
									return (
										<div
											key={item.id}
											className='flex gap-2'
										>
											<InvoiceFormField
												control={controlShareInvoice}
												label='Ingresa un email'
												showLabel={false}
												fieldName={`emails.${index}.email`}
												formErrors={formErrors}
												customError={formErrors?.emails?.[index]?.email}
												type='input-email'
												watch={watchShareInvoice}
											/>
											{index > 0 && (
												<div
													className='mt-2 cursor-pointer'
													onClick={() => remove(index)}
												>
													<MinusCircle
														size={24}
														color='var(--h-error-50)'
													/>
												</div>
											)}
										</div>
									);
								})}
							</div>
							{watchShareInvoice('emails')?.length < 4 && (
								<div
									onClick={() => {
										handleAddNewEmail();
									}}
									className={`w-fit flex items-center gap-2 text-h-primary transition hover:bg-h-primary-90/50 py-1 px-2 rounded-md mt-2 ${
										cantAddMoreEmails ? 'cursor-not-allowed' : 'cursor-pointer'
									}`}
								>
									<PlusCircle weight='bold' /> Agregar otro correo
								</div>
							)}
							<div className='w-full flex justify-center mt-4'>
								<PrimaryButton
									label='Compartir factura'
									type='submit'
									disabled={isLoading.shareInvoice}
								/>
							</div>
						</form>
					</div>
				)}
			</Modal>

			<SideToModalContainer
				isOpen
				handleClose={() => navigate(ROUTES.INVOICING)}
				sx='flex justify-center'
			>
				{isLoading.invoiceDetail ? (
					<div className='flex items-center justify-center mb-10 sm:mb-0 mt-10'>
						<CircularProgress size={25} />
					</div>
				) : (
					<div className='w-full flex flex-col gap-3 max-w-auto relative'>
						<div className='flex justify-between items-center'>
							<Text
								size='body-1'
								color='medium'
							>
								Detalle de la factura
							</Text>
							<XCircle
								onClick={handleClose}
								color='var(--h-primary)'
								weight='fill'
								width={24}
								height={24}
								className='cursor-pointer'
							/>
						</div>
						<hr />
						<div className='max-h-[calc(100dvh-180px)] sm:max-h-none md:h-auto flex flex-col gap-3 overflow-y-auto'>
							<div className='flex justify-between items-center'>
								<div>
									<Text
										size='mini-1'
										weight='light'
									>
										Fecha de emisión
									</Text>
									<Text
										size='caption'
										weight='light'
										sx='ml-2'
									>
										{`${getFormatDate(
											invoiceDetail?.date || '',
											'DD MMM YYYY',
											false,
											true,
										)}`}
									</Text>
								</div>
								<Tag
									label={isCancelled ? 'Factura cancelada' : 'Factura vigente'}
									type='filled'
									caseVariant={isCancelled ? 'error' : 'success'}
								/>
							</div>
							{isCancelled && (
								<div>
									<Text
										size='mini-1'
										weight='light'
									>
										Fecha de cancelación
									</Text>
									<Text
										size='caption'
										weight='light'
										sx='ml-2'
									>
										{`${getFormatDate(
											invoiceDetail?.cancelled_at || '',
											'DD MMM YYYY',
											false,
											true,
										)}`}
									</Text>
								</div>
							)}
							<div>
								<Text
									size='mini-1'
									weight='light'
								>
									Receptor
								</Text>
								<Text
									size='body-3'
									weight='medium'
									sx='ml-2'
								>
									{invoiceDetail?.receiver?.name}
								</Text>
								{invoiceDetail?.receiver?.rfc && (
									<Text
										size='caption'
										weight='light'
										sx='ml-2'
									>
										RFC: <b>{invoiceDetail?.receiver?.rfc}</b>
									</Text>
								)}
							</div>
							<hr />
							<div className='flex flex-col gap-1'>
								{taxDetails.map(
									(
										detail: {
											label: string;
											value: string;
										},
										index: number,
									) => (
										<div
											key={`tax-detail-${index}`}
											className='flex justify-between items-center gap-2'
										>
											<Text
												size='caption'
												weight='light'
											>
												{detail.label}
											</Text>
											<Text
												size='body-3'
												weight='medium'
												sx='!block text-right'
											>
												{detail.value}
											</Text>
										</div>
									),
								)}
							</div>
							<hr />
							<Text
								size='body-1'
								weight='medium'
								sx='flex justify-between items-center'
							>
								Total{' '}
								<b>
									{formatCurrency(Number(invoiceDetail?.total?.toFixed(2)))}
								</b>
							</Text>
							<hr />
							<div className='flex flex-col gap-1'>
								{invoiceDetails.map((detail, index) => (
									<div
										key={`tax-detail-${index}`}
										className='flex justify-between items-center gap-2'
									>
										<Text
											size='caption'
											weight='light'
										>
											{detail.label}
										</Text>
										<Text
											size='body-3'
											weight='medium'
											sx='!block text-right'
										>
											{detail.value}
										</Text>
									</div>
								))}
							</div>
							<div className='w-full flex flex-col gap-2 sm:gap-4'>
								<hr />
								{!isCancelled && (
									<PrimaryButton
										label='Descargar acuse (PDF)'
										onClick={() =>
											handleDownload(invoiceDetail?.pdf_url!, 'pdf')
										}
									/>
								)}
								<PrimaryButton
									label='Descargar acuse (XML)'
									variant='secondary'
									onClick={() =>
										handleDownload(
											isCancelled
												? invoiceDetail?.cancellation_voucher_xml_url!
												: invoiceDetail?.xml_url!,
											'xml',
										)
									}
								/>
								<div className='flex items-center gap-4 justify-center my-2'>
									{!isCancelled && (
										<button
											className='w-fit sm:w-full flex justify-center'
											onClick={() =>
												setShowModal({ ...showModal, cancellation: true })
											}
										>
											<Text
												size='caption'
												color='red'
											>
												<FileX
													width={24}
													height={24}
												/>{' '}
												Cancelar factura
											</Text>
										</button>
									)}
									<button
										className='w-fit sm:w-full flex justify-center'
										onClick={() =>
											setShowModal({
												...showModal,
												shareInvoice: true,
											})
										}
									>
										<Text
											size='caption'
											color='primary'
										>
											<PaperPlaneTilt
												width={24}
												height={24}
											/>{' '}
											Compartir
										</Text>
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</SideToModalContainer>
		</>
	);
};

export default IssuedInvoicePreview;
