import Text from '../../../components/atoms/Text';
import { formatCurrency } from '../../../shared/utils/general.util';
import Tooltip from '../../../components/atoms/Tooltip';
import { Skeleton, useMediaQuery } from '@mui/material';

interface Props {
	label: string;
	amount?: number;
	value?: string;
	tooltip?: string;
	sx?: string;
	isLoading?: boolean;
}

const ResumeProperty: React.FC<Props> = ({
	label,
	amount,
	value,
	tooltip,
	sx,
	isLoading = false,
}) => {
	const isMobile = useMediaQuery('(max-width: 640px)');

	if (isLoading) {
		return (
			<div className='flex justify-between items-center'>
				<Text
					size={isMobile ? 'caption' : 'body-3'}
					weight='light'
				>
					{label}
				</Text>
				<Skeleton width={64} />
			</div>
		);
	}

	return (
		<div className={`flex justify-between items-center ${sx}`}>
			<Text
				size={isMobile ? 'caption' : 'body-3'}
				weight='light'
			>
				{label}
				{tooltip && <Tooltip message={tooltip} />}
			</Text>
			{amount !== undefined && (
				<Text size={isMobile ? 'caption' : 'body-3'}>
					{amount > 0 ? formatCurrency(amount) : '$0'}
				</Text>
			)}
			{value !== undefined && (
				<Text size={isMobile ? 'caption' : 'body-3'} sx='!text-right'>{value}</Text>
			)}
		</div>
	);
};

export default ResumeProperty;
