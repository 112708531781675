import HERU_API from '../shared/constants/heruApi';
import { get, post, put } from '../shared/utils/FetchSSL.util';

const getOrderFailed = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_ORDER_FAILED, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const postDefaultPaymentMethod = async (
	token_id: string,
	default_method: string,
	payment_gateway: string,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_API_DEFAULT_PAYMENT,
			{
				token_id: token_id,
				default_method: default_method,
				payment_gateway: payment_gateway,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const putDefaultPaymentMethodStripeBilling = async (
	token_id: string,
	Subscription_id: number,
	tokenSession?: string,
) => {
	try {
		const response = await put(
			HERU_API.HERU_API_DEFAULT_STRIPE_BILLING(token_id, Subscription_id),
			{},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postPaymentOnDemand = async (
	Subscription_id: number,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_API_PAYMENT_ON_DEMAND(Subscription_id),
			{},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getOrderFailed,
	postDefaultPaymentMethod,
	putDefaultPaymentMethodStripeBilling,
	postPaymentOnDemand
};
