import Text from '../../../components/atoms/Text';
import { ArrowRight, XCircle } from '@phosphor-icons/react';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import Tag from '../../../components/molecules/Tag';
import { formatCurrency } from '../../../shared/utils/general.util';
import ResumeProperty from '../molecules/ResumeProperty';
import UpdateOnDemand from './UpdateOnDemand';
import PropertyDropdown from './PropertyDropdown';
import Container from '../../../components/atoms/Container';
import { Skeleton, useMediaQuery } from '@mui/material';

const emptyProperties = [
	{
		label: 'Ingresos',
		value: '-',
	},
	{
		label: 'Gastos',
		value: '-',
	},
	{
		label: 'Retenciones',
		value: '-',
	},
	{
		label: 'Gastos deducibles',
		value: '-',
	},
];

interface Props {
	title: string;
	year: number;
	month?: number;
	tagText?: string;
	description?: React.ReactNode;
	onViewDetail?: () => void;
	viewDetailCopy?: string;
	amountToPay?: number;
	incomes?: {
		total: number;
		concepts: { concept?: string; regime?: string; amount: number }[];
	};
	expenses?: number;
	retentions?: {
		total: number;
		vat: number;
		isr: number;
	};
	deductibleExpenses?: number;
	declarationType?: string;
	paymentType?: string;
	presentedBy?: string;
	presentedDate?: string;
	showRefresh?: boolean;
	banner?: React.ReactNode;
	cta?: React.ReactNode;
	emptyState?: boolean;
	onTagClick?: () => void;
}

const FiscalResume: React.FC<Props> = ({
	title,
	year,
	month,
	tagText,
	description,
	onViewDetail,
	viewDetailCopy = 'Ver detalle',
	amountToPay,
	banner,
	cta,
	incomes,
	expenses,
	retentions,
	deductibleExpenses,
	declarationType,
	// paymentType,
	presentedBy,
	presentedDate,
	showRefresh,
	emptyState,
	onTagClick,
}) => {
	const declarationProperties = [
		{
			label: 'Fecha de presentación',
			value: getFormatDate(presentedDate || '', 'DD MMM YYYY', false, true),
		},
		{
			label: 'Tipo de declaración',
			value: declarationType === 'NORMAL' ? 'Normal' : 'Complementaria',
		},
		// {
		// 	label: 'Tipo de pago',
		// 	value: paymentType === 'NORMAL' ? 'Normal' : 'Definitivo',
		// },
		{
			label: 'Presentada por',
			value: presentedBy === 'Heru' ? 'Heru' : 'Externo',
		},
	];

	const isNullOrUndefined = (value: any) =>
		value === null || value === undefined;

	const isLoading =
		isNullOrUndefined(incomes) &&
		isNullOrUndefined(expenses) &&
		isNullOrUndefined(retentions) &&
		isNullOrUndefined(deductibleExpenses) &&
		declarationType === undefined;

	const isMobile = useMediaQuery('(max-width: 1024px)');

	if (isLoading) {
		return (
			<Container sx='w-full lg:w-2/5 lg:min-h-[calc(100vh-200px)] lg:sticky lg:top-14'>
				<div className='flex gap-1'>
					<Text
						size='body-1'
						weight='bold'
						sx='flex justify-between w-full'
						responsive
					>
						<span>{title}</span>
						<span>{year}</span>
					</Text>
					{tagText && (
						<Tag
							onClick={onTagClick}
							label={tagText}
							type='filled'
							caseVariant='filter'
							sx='ml-1'
							iconRight={
								<XCircle
									color='var(--h-neutral-50)'
									weight='fill'
									width={18}
									height={18}
								/>
							}
						/>
					)}
				</div>
				<div className='mt-6'>
					{[...Array(3)].map((_, index) => (
						<div
							className='flex gap-2 justify-between mt-1'
							key={`skeleton-index-${index}`}
						>
							<Skeleton
								height={30}
								width={200}
							/>
							<Skeleton
								height={30}
								width={90}
							/>
						</div>
					))}
					<hr className='my-4' />
					{[...Array(2)].map((_, index) => (
						<div
							className='flex gap-2 justify-between mt-1'
							key={`skeleton-index-${index}`}
						>
							<Skeleton
								height={30}
								width={180}
							/>
							<Skeleton
								height={30}
								width={90}
							/>
						</div>
					))}
				</div>
			</Container>
		);
	}

	return (
		<Container sx='w-full lg:w-2/5 lg:min-h-[calc(100vh-200px)] lg:sticky lg:top-14 flex flex-col justify-between'>
			<div className='flex flex-col gap-1.5'>
				{month ? (
					<div className='flex justify-between w-full flex-col lg:flex-row gap-3'>
						<Text
							size='body-2'
							weight='bold'
							sx=''
							responsive
						>
							<span>{title}</span>
						</Text>
						<div className='flex gap-1'>
							<Text
								size='body-2'
								weight='bold'
								sx=''
								responsive
							>
								<span>{getMonthName(month, true)}</span>
							</Text>
							<Text
								size='caption'
								color='gray'
								sx='mt-1 mr-1'
								responsive
							>
								<span>{year}</span>
							</Text>
							{tagText && (
								<Tag
									onClick={onTagClick}
									label={tagText}
									type='filled'
									caseVariant='filter'
									iconRight={
										<XCircle
											color='var(--h-neutral-50)'
											weight='fill'
											width={18}
											height={18}
										/>
									}
								/>
							)}
						</div>
					</div>
				) : (
					<div className='flex gap-1'>
						<Text
							size='body-1'
							weight='bold'
							sx='flex justify-between w-full'
							responsive
						>
							<span>{title}</span>
							<span>{year}</span>
						</Text>
						{tagText && (
							<Tag
								onClick={onTagClick}
								label={tagText}
								type='filled'
								caseVariant='filter'
								sx='ml-1'
								iconRight={
									<XCircle
										color='var(--h-neutral-50)'
										weight='fill'
										width={18}
										height={18}
									/>
								}
							/>
						)}
					</div>
				)}
				<hr className='my-3' />

				{description && (
					<>
						<Text
							size='caption'
							weight='light'
							sx='!block'
						>
							{description}
						</Text>
						<hr className='my-3' />
					</>
				)}

				{emptyState ? (
					<div className='opacity-50 flex flex-col gap-2'>
						{emptyProperties.map((prop, index) => (
							<ResumeProperty
								key={`empty-property-${index}`}
								label={prop.label}
								value={prop.value}
							/>
						))}
					</div>
				) : (
					<>
						{amountToPay !== undefined && (
							<div className='flex justify-between w-full bg-h-neutral-94 p-2 rounded-lg border border-h-neutral-80/50 mb-2'>
								<Text
									size='body-3'
									weight='light'
									sx=''
									responsive
								>
									Monto a pagar
								</Text>
								<Text
									size='body-3'
									weight='bold'
									sx=''
									responsive
								>
									{formatCurrency(amountToPay)}
								</Text>
							</div>
						)}
						<PropertyDropdown
							label={'Ingresos'}
							total={incomes?.total}
							amounts={incomes?.concepts?.map((amount) => ({
								label: amount.concept ?? amount.regime ?? 'Sin concepto',
								amount: amount.amount,
							}))}
						/>
						{expenses !== undefined && expenses !== null && (
							<ResumeProperty
								isLoading={isLoading}
								label={'Gastos'}
								amount={expenses}
							/>
						)}
						<PropertyDropdown
							label={'Retenciones'}
							total={retentions?.total}
							amounts={[
								{
									label: 'IVA',
									amount: retentions?.vat,
								},
								{
									label: 'ISR',
									amount: retentions?.isr,
								},
							]}
						/>
						{deductibleExpenses !== undefined &&
							deductibleExpenses !== null && (
								<ResumeProperty
									isLoading={isLoading}
									label={'Gastos deducibles'}
									amount={deductibleExpenses}
								/>
							)}
					</>
				)}

				{declarationType && (
					<>
						{declarationProperties.map((property, index) => (
							<div key={`property-index-${index}`}>
								<ResumeProperty
									isLoading={isLoading}
									label={property.label}
									value={property.value}
								/>
							</div>
						))}
					</>
				)}
				<hr className='mt-4' />
				<div
					className={`flex flex-row-reverse items-center justify-between lg:block ${onViewDetail ? 'flex-col' : 'mx-auto lg:mx-0 '}`}
				>
					{onViewDetail && (
						<div
							onClick={onViewDetail}
							className=' w-fit cursor-pointer lg:ml-auto mb-3'
						>
							<Text
								size='body-3'
								color='primary'
								weight='medium'
								responsive
								sx='whitespace-nowrap py-1 border-b border-h-primary-60'
							>
								{viewDetailCopy} <ArrowRight />
							</Text>
						</div>
					)}
					<div className={isMobile ? 'flex flex-col items-center' : ''}>
						{showRefresh && (
							<UpdateOnDemand align={onViewDetail ? 'right' : 'center'} />
						)}
					</div>
				</div>
			</div>
			<div className={`flex flex-col gap-2 mt-2 ${isMobile && 'mt-6'}`}>
				{banner}
				{cta}
			</div>
		</Container>
	);
};

export default FiscalResume;
