import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FiscalProfileCard from '../FiscalProfileCard';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
	addTaxPayer,
	startLoader,
} from '../../../redux/slices/taxProfile.slice';
import { RootState } from '../../../redux/store';
import {
	fetchMonthlyDeclarationOverview,
	fetchTaxPayer,
	fetchYearlyDeclarationsOverview,
} from '../../../services/taxes.service';
import FiscalDocShare from '../../molecules/FiscalDocShare';

import InvoicesSumary from '../InvoicesSumary';
import {
	fetchCheckUserRappi,
	fetchFinancialsMovements,
} from '../../../services/invoicing.service';
import { IFiscalStatus, MovementDocuments } from '../../../@types/Fiscal.type';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { DATES } from '../../../shared/utils/dates';
import bannerSubscriptions from '../../../assets/img/banners/Suscribete_Small_Desktop.png';
import banner3 from '../../../assets/img/banners/Conoce_planes_Small_Desktop.png';
import ServicesStatuHome from '../ServicesStatuHome';
import { ServiceStatusElement } from '../../../@types/ServiceStatus.type';
import { settingsCarrousel } from '../SATLinking/SATLinking';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import BlockedMock from '../ServicesStatuHome/BlockedMock';

import { REGIMES_CODE } from '../../../@types/Regime.type';
import MyFiscalStatus from '../MyFiscalStatus';
import LastPendingOrder from '../../../pages/pendingReferences/components/LastPendingOrder';
import { VARIANT_STATUS } from '../../../shared/constants/serviceStatus';
interface Props {
	serviceStatus: ServiceStatusElement[];
	isLoadingServices: boolean;
}
// interface IDeductiblesViewer {
// 	data: IDeductibles | undefined | null;
// 	isLoading: boolean;
// }
type TAnnualObligations = {
	year: number;
	status: string;
};

// let loop = 0;
const UserCredentialsLinked: React.FC<Props> = ({
	serviceStatus,
	isLoadingServices,
}) => {
	const [invoices, setInvoices] = useState<MovementDocuments[]>([]);
	const [isRappiUser, setIsRappiUser] = useState({
		status: false,
		isLoading: true,
	});
	// const [deductiblesViewer, setDeductiblesViewer] =
	// 	useState<IDeductiblesViewer>({
	// 		data: null,
	// 		isLoading: true,
	// 	});
	const [hasAnnualDeclSubs, setHasAnnualDeclSubs] = useState(false);

	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { activeSuscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [fiscalStatus, setFiscalStatus] = useState<IFiscalStatus>();
	const logClevertapEvent = useCleverTapEvent();

	const activeServices = useMemo(() => {
		return serviceStatus?.filter((it) => it?.status !== 'closed');
	}, [serviceStatus]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getTaxPayer = async () => {
		try {
			if (!userProfile?.id) return;
			dispatch(startLoader(true));
			const resource = await fetchTaxPayer(userProfile?.id, tokenSession);
			dispatch(addTaxPayer(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const getIsRappiUser = useCallback(async () => {
		if (!userProfile?.id) return;
		try {
			const resource = await fetchCheckUserRappi(
				userProfile?.id!,
				tokenSession,
			);
			setIsRappiUser({
				status: resource?.length > 0,
				isLoading: false,
			});
		} catch (error) {
			setIsRappiUser({ status: false, isLoading: false });
		}
	}, [userProfile]);

	const getFinancialsMovements = async () => {
		try {
			//Todo: Check if user is Rappi User
			const extraParams = `&rappi=${isRappiUser.status}`;
			const params = `limit=${5}${extraParams}`;
			const _invoices: MovementDocuments[] = await fetchFinancialsMovements(
				params,
				tokenSession,
			);
			setInvoices(_invoices);
		} catch (error) {}
	};

	// const getDeductibles = async () => {
	// 	try {
	// 		if (!taxPayer?.code) return;
	// 		if (loop >= 2) return;
	// 		const response: IDeductiblesView = await fetchDeductions(
	// 			taxPayer?.code,
	// 			tokenSession,
	// 		);

	// 		if (response) {
	// 			const currentDeductions = response.deductions.find(
	// 				(info) => parseInt(info.year) == getCurrentDate().year - 1,
	// 			);
	// 			if (currentDeductions) {
	// 				setDeductiblesViewer({
	// 					data: currentDeductions,
	// 					isLoading: false,
	// 				});
	// 				loop = 2;
	// 			} else {
	// 				setDeductiblesViewer({
	// 					data: null,
	// 					isLoading: false,
	// 				});
	// 				loop = loop + 1;

	// 				// setTimeout(() => {
	// 				// 	getDeductibles();
	// 				// }, 45000);
	// 			}
	// 		}
	// 	} catch (error) {
	// 		setDeductiblesViewer({
	// 			data: null,
	// 			isLoading: true,
	// 		});
	// 	}
	// };

	useEffect(() => {
		if (taxPayer) return;
		getTaxPayer();
	}, [taxPayer, userProfile?.id]);

	useEffect(() => {
		getIsRappiUser();
	}, [userProfile]);

	useEffect(() => {
		if (isRappiUser.isLoading) return;
		getFinancialsMovements();
	}, [isRappiUser]);

	// useEffect(() => {
	// 	loop = 0;
	// 	getDeductibles();
	// }, [taxPayer]);

	useEffect(() => {
		const hasAnnualDecl = activeSuscriptions.find(
			(sub) =>
				sub.product_version.product?.slug ===
					PRODUCTS_SLUG.ANNUAL_DECLARATION &&
				sub?.additional_information?.year === DATES.currentYear - 1,
		);
		setHasAnnualDeclSubs(!!hasAnnualDecl);
	}, [activeSuscriptions]);

	const getAnnualsPeriod = async () => {
		if (!taxPayer) return;
		let totalYears = 5;
		if (taxPayer?.registered_at) {
			const utcDate = new Date(taxPayer?.registered_at).getUTCFullYear();
			totalYears =
				DATES.currentYear - utcDate > 5 ? 5 : DATES.currentYear - utcDate;
		}
		const years = Array.from(
			{ length: totalYears },
			(_, i) => DATES.currentYear - 1 - i,
		);

		try {
			Promise.all([
				fetchYearlyDeclarationsOverview(userProfile?.id!),
				fetchMonthlyDeclarationOverview(userProfile?.id!, DATES.currentYear),
			])
				.then((response) => {
					if (!response) return;
					if (response[0] && response[1]) {
						setFiscalStatus({
							annual: response[0].filter((o: TAnnualObligations) =>
								years.includes(o.year),
							),
							monthly: response[1].declarations
								.sort(
									(a: { month: number }, b: { month: number }) =>
										b.month - a.month,
								)
								.slice(0, 5),
						});
					} else if (response[0] && !response[1]) {
						setFiscalStatus({
							annual: response[0].filter((o: TAnnualObligations) =>
								years.includes(o.year),
							),
							monthly: [],
						});
					} else if (!response[0] && response[1]) {
						setFiscalStatus({
							annual: [],
							monthly: response[1].declarations
								.sort(
									(a: { month: number }, b: { month: number }) =>
										b.month - a.month,
								)
								.slice(0, 5),
						});
					}
				})
				.catch(() => {
					setFiscalStatus({
						annual: [],
						monthly: [],
					});
				});
		} catch (error) {
			setFiscalStatus({
				annual: [],
				monthly: [],
			});
		}
	};

	useEffect(() => {
		getAnnualsPeriod();
		// Promise.all([])
	}, [taxPayer]);

	const logBannerHomeEvent = (banner: 'planes y precios' | 'suscripcion') => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_PURCHASE_BANNER_INTERACTION,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			banner,
		});
	};

	const fiscalSituationStatus = useMemo(() => {
		if (!taxPayer?.status?.status) return 'pending';
		if (
			taxPayer?.status?.status === 'ACTIVO' ||
			taxPayer?.status?.status === 'REACTIVADO'
		)
			return 'positive';
		return 'negative';
	}, [taxPayer]);

	const complianceOpinion = useMemo(() => {
		if (!taxPayer?.compliance?.result) return 'pending';
		if (taxPayer?.compliance.result === 'positive') return 'positive';
		return 'negative';
	}, [taxPayer]);

	const goToPlans = () => {
		logBannerHomeEvent('planes y precios');
		// const { utm_campaign, utm_medium, utm_source } =
		// 	PLANS_UTM_VALUES.HOME_BANNER;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			// if (overwriteUtm === 'true') {
			// 	sessionStorage.setItem(campaignKey, utm_campaign);
			// 	sessionStorage.setItem(mediumKey, utm_medium);
			// 	sessionStorage.setItem(sourceKey, utm_source);
			// } else {
			// 	if (!sessionStorage.getItem(campaignKey)) {
			// 		sessionStorage.setItem(campaignKey, utm_campaign);
			// 	}
			// 	if (!sessionStorage.getItem(mediumKey)) {
			// 		sessionStorage.setItem(mediumKey, utm_medium);
			// 	}
			// 	if (!sessionStorage.getItem(sourceKey)) {
			// 		sessionStorage.setItem(sourceKey, utm_source);
			// 	}
			// }
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				// dispatch(updateUTMs(PLANS_UTM_VALUES.HOME_BANNER));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);
				// const updatedUTMs = {
				// 	...currentUTMs,
				// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
				// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
				// 	utm_source: currentUTMs?.utm_source || utm_source,
				// };
				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	const compliceOpinionLabel = useMemo(() => {
		if (taxPayer?.compliance?.result) {
			if (taxPayer?.compliance.result === 'negative') return 'Negativa';
			return 'Positiva';
		}
		return 'No disponible';
	}, [taxPayer]);

	// const imgClasses = useMemo(() => {
	// 	const classImg = '!:block w-auto h-full';
	// 	if (deductiblesViewer.isLoading) {
	// 		return `${classImg} lg:right-[-20px]`;
	// 	}
	// 	return `${classImg} right-[-50px]  ${
	// 		parseDeductibleAmount(deductiblesViewer.data?.amount ?? '$0.00') === 0 &&
	// 		'lg:right-[-12%] xl:right-0'
	// 	}`;
	// }, [deductiblesViewer]);

	// const goToAnnualDeclaration = () => {
	// 	// const { utm_campaign, utm_medium, utm_source } =
	// 	// 	PLANS_UTM_VALUES.HOME_BANNER_DEDUCTIBLES;
	// 	if (isSessionStorageEnabled()) {
	// 		const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
	// 		// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
	// 		// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
	// 		// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

	// 		let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

	// 		if (overwriteUtm === null) {
	// 			sessionStorage.setItem(overwriteUtmKey, 'true');
	// 			overwriteUtm = 'true';
	// 		}

	// 		// if (overwriteUtm === 'true') {
	// 		// 	sessionStorage.setItem(campaignKey, utm_campaign);
	// 		// 	sessionStorage.setItem(mediumKey, utm_medium);
	// 		// 	sessionStorage.setItem(sourceKey, utm_source);
	// 		// } else {
	// 		// 	if (!sessionStorage.getItem(campaignKey)) {
	// 		// 		sessionStorage.setItem(campaignKey, utm_campaign);
	// 		// 	}
	// 		// 	if (!sessionStorage.getItem(mediumKey)) {
	// 		// 		sessionStorage.setItem(mediumKey, utm_medium);
	// 		// 	}
	// 		// 	if (!sessionStorage.getItem(sourceKey)) {
	// 		// 		sessionStorage.setItem(sourceKey, utm_source);
	// 		// 	}
	// 		// }
	// 	} else {
	// 		const currentOverwriteUtm = useSelector(
	// 			(state: RootState) => state.cart.overwriteUtm,
	// 		);

	// 		if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
	// 			dispatch(setOverwriteUtm(true));
	// 		}

	// 		if (currentOverwriteUtm === true) {
	// 			// dispatch(updateUTMs(PLANS_UTM_VALUES.HOME_BANNER_DEDUCTIBLES));
	// 		} else {
	// 			const currentUTMs = useSelector(
	// 				(state: RootState) => state.cart.utmInfo,
	// 			);
	// 			// const updatedUTMs = {
	// 			// 	...currentUTMs,
	// 			// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
	// 			// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
	// 			// 	utm_source: currentUTMs?.utm_source || utm_source,
	// 			// };
	// 			if (!currentUTMs) return;
	// 			const updatedUTMs = {
	// 				...currentUTMs,
	// 				utm_campaign: currentUTMs?.utm_campaign,
	// 				utm_medium: currentUTMs?.utm_medium,
	// 				utm_source: currentUTMs?.utm_source,
	// 			};
	// 			dispatch(updateUTMs(updatedUTMs));
	// 		}
	// 	}

	// 	navigate(ROUTES.PLANS_AND_PRICES, {
	// 		state: { category: PYP_CATEGORY_LABELS[2] }, //? Annual Declaration category
	// 	});
	// };

	const serviceStatusSubscription = serviceStatus.find(
		(service) =>
			service.current_service_has_step.status === VARIANT_STATUS.BLOCKED &&
			service.current_service_has_step.stage.slug ===
				'stage_subscription_blocked_for_payment_declaration',
	);

	return (
		<section className='grid grid-cols-12 gap-6'>
			<div className='col-span-12 lg:col-span-6'>
				<div className='hidden lg:block mb-4'>
					{!hasAnnualDeclSubs && (
						<Slider {...settingsCarrousel}>
							{/* <DeductiblesBanner
								sx={`!min-h-fit-content ${
									deductiblesViewer.isLoading
										? "bg-[url('https://heru-static-assets.s3.us-east-2.amazonaws.com/blue-banner.webp')]"
										: '!bg-h-primary-95'
								}`}
								variant={
									deductiblesViewer.isLoading
										? 'deductibles-loading'
										: 'deductibles-linked'
								}
								labelButton='Vincúlate y ¡Calcula tus deducibles!'
								description={
									deductiblesViewer.isLoading ? (
										<LoadingDeductiblesBanner />
									) : (
										<DeductiblesBannerInfo
											name={taxPayer?.name ?? ''}
											amount={deductiblesViewer.data?.amount ?? '$0.00'}
											onAction={goToAnnualDeclaration}
										/>
									)
								}
								sxBannerImg={imgClasses}
								urlImage={
									parseDeductibleAmount(
										deductiblesViewer.data?.amount ?? '$0.00',
									) === 0
										? 'https://heru-static-assets.s3.us-east-2.amazonaws.com/no-deducibles.webp'
										: undefined
								}
							/> */}

							<img
								onClick={goToPlans}
								src={banner3}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>

							<img
								onClick={goToPlans}
								src={bannerSubscriptions}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>
						</Slider>
					)}

					{hasAnnualDeclSubs && (
						<Slider {...settingsCarrousel}>
							<img
								onClick={goToPlans}
								src={banner3}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>

							<img
								onClick={goToPlans}
								src={bannerSubscriptions}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>
						</Slider>
					)}
				</div>
				<FiscalProfileCard sx='shadow-sm' />

				<div className='flex flex-col px-6 pt-6 pb-4 bg-white rounded-lg relative my-6'>
					<FiscalDocShare
						fileName='Tu constancia de situación fiscal'
						status={fiscalSituationStatus}
						statusText={taxPayer?.status?.status ?? 'No disponible'}
						sx='mb-4'
						hrefFile={taxPayer?.status?.file?.file_url ?? ''}
					/>
					<FiscalDocShare
						status={complianceOpinion}
						fileName='Tu opinión de cumplimiento'
						hrefFile={taxPayer?.compliance?.file?.file_url ?? ''}
						statusText={compliceOpinionLabel}
					/>
				</div>

				<div className='block lg:hidden mb-6'>
					<MyFiscalStatus
						fiscalStatus={fiscalStatus}
						hideMonthlyPeriod={
							!!taxPayer?.status?.tax_regimes?.find(
								(r) => r.code !== REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
							)
						}
					/>
				</div>
				<InvoicesSumary
					title='Ultimas facturas'
					invoices={invoices}
				/>
			</div>
			<div className='col-span-12 lg:col-span-6 order-first lg:order-last'>
				<div className='block mb-4 lg:hidden'>
					{/* {!hasAnnualDeclSubs && (
						<Slider {...settingsCarrousel}>
							<DeductiblesBanner
								sx={` ${
									deductiblesViewer.isLoading
										? "bg-[url('https://heru-static-assets.s3.us-east-2.amazonaws.com/blue-banner.webp')] py-1"
										: '!bg-h-primary-95'
								}`}
								variant={
									deductiblesViewer.isLoading
										? 'deductibles-loading'
										: 'deductibles-linked'
								}
								labelButton='Vincúlate y ¡Calcula tus deducibles!'
								description={
									deductiblesViewer.isLoading ? (
										<LoadingDeductiblesBanner />
									) : (
										<DeductiblesBannerInfo
											name={taxPayer?.name ?? ''}
											amount={deductiblesViewer.data?.amount ?? '$0.00'}
											onAction={goToAnnualDeclaration}
										/>
									)
								}
								sxBannerImg={imgClasses}
								urlImage={
									parseDeductibleAmount(
										deductiblesViewer.data?.amount ?? '$0.00',
									) === 0
										? 'https://heru-static-assets.s3.us-east-2.amazonaws.com/no-deducibles.webp'
										: undefined
								}
							/>

							<img
								onClick={goToPlans}
								src={banner3}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg max-h-[200px]'
							/>

							<img
								onClick={goToPlans}
								src={bannerSubscriptions}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full max-h-[200px] object-cover rounded-lg'
							/>
						</Slider>
					)} */}
					{hasAnnualDeclSubs && (
						<Slider {...settingsCarrousel}>
							<img
								onClick={goToPlans}
								src={banner3}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg max-h-[200px]'
							/>

							<img
								onClick={goToPlans}
								src={bannerSubscriptions}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full max-h-[200px] object-cover rounded-lg'
							/>
						</Slider>
					)}
				</div>

				<LastPendingOrder />

				{serviceStatusSubscription ? (
					<BlockedMock failedStripeSub={serviceStatusSubscription} />
				) : (
					<>
						{activeServices.length > 0 && (
							<ServicesStatuHome
								serviceStatus={serviceStatus}
								isLoadingServices={isLoadingServices}
							/>
						)}
					</>
				)}

				<div className='hidden lg:block'>
					<MyFiscalStatus
						fiscalStatus={fiscalStatus}
						hideMonthlyPeriod={
							!!taxPayer?.status?.tax_regimes?.find(
								(r) => r.code !== REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
							)
						}
					/>
				</div>
			</div>
		</section>
	);
};

export default React.memo(UserCredentialsLinked);
