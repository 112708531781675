import { AlertPayload } from '../../../../../redux/slices/alert.slice';

import FileView from '../../molecules/fileView';
import { TaxProfile } from '../../../../../@types/TaxProfile';

import UploadESignature from '../../../../../components/molecules/UploadESignature';
import { CircularProgress } from '@mui/material';
import PasswordToggle from '../../molecules/passwordToggle';

type Props = {
	onCallbackSuccess: () => void;
	showAlertTop: (alert: AlertPayload) => void;
	fiscalDocuments?: TaxProfile;
	isLoading: {
		getEFirma: boolean;
	};
};

const EFirma: React.FC<Props> = ({
	onCallbackSuccess,
	fiscalDocuments,
	isLoading,
}) => {
	const documentsLinked =
		fiscalDocuments && fiscalDocuments?.links?.fiel?.length > 0;

	const handleClickOption = (filename: 'cert_url' | 'key_url') => {
		window.open(fiscalDocuments?.links?.fiel?.[0]?.[filename], '_blank');
	};

	return (
		<>
			{isLoading.getEFirma ? (
				<div className='flex justify-center m-2'>
					<CircularProgress size={30} />
				</div>
			) : documentsLinked ? (
				<div className='flex flex-col gap-3'>
					<FileView
						name='Certificado_sat.cer'
						onClick={() => handleClickOption('cert_url')}
					/>
					<FileView
						name='Clave_sat.key'
						onClick={() => handleClickOption('key_url')}
					/>
					{fiscalDocuments?.links?.fiel?.[0]?.key_pass && (
						<PasswordToggle
							label='Contraseña clave privada'
							initialPassword={fiscalDocuments?.links?.fiel?.[0]?.key_pass}
						/>
					)}
				</div>
			) : (
				<UploadESignature
					viewTitle={false}
					taxProfile={fiscalDocuments}
					onCallbackSuccess={onCallbackSuccess}
				/>
			)}
		</>
	);
};

export default EFirma;
