import { useState } from 'react';
import Text from '../../../components/atoms/Text';
import { formatCurrency } from '../../../shared/utils/general.util';
import { CaretDown, Circle } from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

interface Props {
	label: string;
	amounts?: {
		label: string;
		amount?: number;
		tooltip?: string;
	}[];
	total?: number;
}

const PropertyDropdown: React.FC<Props> = ({ label, amounts, total }) => {
	const [isOpen, setIsOpen] = useState(false);

	const isMobile = useMediaQuery('(max-width: 640px)');

	if (total === undefined || total === null) return null;

	return (
		<div>
			<div
				className='cursor-pointer flex justify-between w-full bg-h-neutral-94 px-2 py-1 rounded-t-lg border-b border-h-neutral-80'
				onClick={() => setIsOpen(!isOpen)}
			>
				<Text
					size={isMobile ? 'caption' : 'body-3'}
					weight='light'
				>
					{label}
				</Text>
				<Text size={isMobile ? 'caption' : 'body-3'}>
					{total !== undefined || total !== null ? formatCurrency(total) : '-'}
					<CaretDown
						className={`transition ${isOpen ? 'rotate-180' : 'rotate-0'}`}
					/>
				</Text>
			</div>
			<div className='max-h-[200px] overflow-auto ml-3'>
				{isOpen &&
					amounts &&
					amounts?.map((amount, index) => (
						<div
							className='flex justify-between p-2'
							key={`index-key-${index}`}
						>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='light'
							>
								<Circle
									weight='fill'
									width={5}
									height={5}
								/>
								{amount.label}
							</Text>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='light'
							>
								{amount.amount !== undefined && amount.amount !== null
									? formatCurrency(amount.amount)
									: '-'}
							</Text>
						</div>
					))}
			</div>
		</div>
	);
};

export default PropertyDropdown;
