interface Props {
	label: string;
	disabled?: boolean;
	onClick?: () => void;
	sx?: string;
	beforeIcon?: React.ReactNode;
}

const LinkButton = ({
	label,
	disabled,
	onClick,
	sx,
	beforeIcon,
	...props
}: Props) => {
	return (
		<button
			onClick={onClick}
			className={`font-medium text-h-primary disabled:cursor-not-allowed flex gap-2 items-center hover:underline rounded-full transition ${sx}`}
			disabled={disabled}
			{...props}
		>
			{beforeIcon}
			{label}
		</button>
	);
};

export default LinkButton;
