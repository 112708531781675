import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { DATES } from './dates';
import { capitalizeFirstLetter } from './general.util';
const getCurrentDate = () => {
	const date = new Date();
	return {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate(),
	};
};

const getPrefixMonth = (month: number) => {
	switch (month) {
		case 1:
			return 'Ene';
		case 2:
			return 'Feb';
		case 3:
			return 'Mar';
		case 4:
			return 'Abr';
		case 5:
			return 'May';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Ago';
		case 9:
			return 'Sep';
		case 10:
			return 'Oct';
		case 11:
			return 'Nov';
		case 12:
			return 'Dic';

		default:
			return '';
	}
};

const getCompleteMonth = (month: number) => {
	switch (month) {
		case 1:
			return 'Enero';
		case 2:
			return 'Febrero';
		case 3:
			return 'Marzo';
		case 4:
			return 'Abril';
		case 5:
			return 'Mayo';
		case 6:
			return 'Junio';
		case 7:
			return 'Julio';
		case 8:
			return 'Agosto';
		case 9:
			return 'Septiembre';
		case 10:
			return 'Octubre';
		case 11:
			return 'Noviembre';
		case 12:
			return 'Diciembre';

		default:
			return '';
	}
};

const getFormatDate = (
	date: string,
	format: string = 'DD MMM YYYY',
	custom?: boolean,
	capitalize?: boolean,
) => {
	let dateToFormat = date;
	if (date?.includes('T00:00:00.000Z')) {
		dateToFormat = date?.split('T')?.[0] + 'T10:00:00.000Z';
	}

	if (custom) {
		return `${dayjs(dateToFormat)?.locale('es')?.format('DD')} de ${dayjs(
			dateToFormat,
		)
			?.locale('es')
			?.format(
				'MMMM',
			)} de ${dayjs(dateToFormat)?.locale('es')?.format('YYYY')}`;
	}
	if (capitalize) {
		const formattedDate = dayjs(dateToFormat).locale('es');
		const day = formattedDate?.format('DD');
		const month = capitalizeFirstLetter(formattedDate?.format('MMMM'));
		const year = formattedDate?.format('YYYY');

		return `${day} ${month} ${year}`;
	}
	return dayjs(dateToFormat)?.locale('es')?.format(format);
};

const getMonthName = (month: number, capitalize?: boolean) => {
	const monthName = dayjs()
		.month(month - 1)
		.locale('es')
		.format('MMMM');

	return capitalize ? capitalizeFirstLetter(monthName) : monthName;
};

const getYearByPeriod = () => {
	const currentDate = dayjs();
	const mayFirst = dayjs(`${DATES.currentYear}-06-01`);

	if (currentDate.isBefore(mayFirst)) {
		return mayFirst.year() - 1;
	} else {
		return DATES.currentYear;
	}
};

export {
	getCurrentDate,
	getPrefixMonth,
	getFormatDate,
	getMonthName,
	getYearByPeriod,
	getCompleteMonth,
};
