import React from 'react';

interface Props {
	sx?: string;
}

const Divider: React.FC<Props> = ({ sx }) => {
	return <hr className={`border border-b-h-gray-20 mb-4 ${sx}`} />;
};

export default React.memo(Divider);
