import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import clevertap from 'clevertap-web-sdk';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm, useWatch } from 'react-hook-form';

//? Types
import { TermsAndConditions } from '../../@types/Profile.type';

//? Components
import RegisterForm from '../../components/organisms/RegisterForm';

//? Services
import {
	acceptCompliance,
	fetchTermsAndConditions,
	getUserProfile,
	patchUserProfile,
} from '../../services/profile.service';

//? Utils
import ROUTES from '../../shared/constants/routes';
import { REGEX } from '../../shared/constants/regex';
import growthbookFile from '../../shared/constants/growthbook';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';

//? Redux
import { setProfile } from '../../redux/slices/profile.slice';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import {
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import { RootState } from '../../redux/store';
import { HERU_UTM } from '../../shared/constants/utms';

type FormBody = {
	name: string;
	surname: string;
	email: string;
};

const Register: React.FC = () => {
	const [errors, setErrors] = useState({
		name: '',
		surname: '',
		email: '',
	});
	const [isLoading, setIsLoading] = useState({
		updateProfile: false,
	});
	const [termsAndConditions, setTermsAndConditions] =
		useState<TermsAndConditions | null>(null);

	const { control, handleSubmit, getValues } = useForm<FormBody>();
	const { products } = useSelector((state: RootState) => state.cart);
	const { goTo: goToURl } = useSelector((state: RootState) => state.auth);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const nameInput = useWatch({ control: control, name: 'name' });
	const surnameInput = useWatch({ control: control, name: 'surname' });
	const emailInput = useWatch({ control: control, name: 'email' });
	const isFormValid = !!nameInput && !!surnameInput && !!emailInput;
	const logClevertapEvent = useCleverTapEvent();
	let [searchParams] = useSearchParams();
	const { utmInfo } = useSelector((state: RootState) => state.cart);

	const { dataLayer } = window as any;

	const getTermsAndConditions = useCallback(async () => {
		try {
			const response = await fetchTermsAndConditions();
			setTermsAndConditions(response);
		} catch (error) {}
	}, []);

	const updateStorageProfile = async () => {
		try {
			const userInfo = await getUserProfile(tokenSession);
			if (isLocalStorageEnabled()) {
				localStorage.setItem(LOCAL_STORAGE_KEY.USER_NAME, userInfo.first_name);
			}
			dispatch(setProfile(userInfo));

			growthbookFile?.setAttributes({
				user_id: userInfo?.id,
			});

			clevertap.profile.push({
				Site: {
					Name: `${userInfo.first_name} ${userInfo.father_surname}`,
					Identity: userInfo.id,
					Email: userInfo.email,
					Phone: userInfo.cellphone,
				},
			});

			dataLayer.push({
				event: ANALYTICS_EVENTS.SIGNUP,
				user_id: userInfo?.id,
				phone: userInfo?.cellphone,
			});

			logClevertapEvent(ANALYTICS_EVENTS.SIGNUP, {
				event_type: EVENT_TYPES.SIGNUP,
				id: userInfo?.id,
				phone: userInfo?.cellphone,
			});
		} catch (error) {}
	};

	const handleGbFlag = async () => {
		try {
			await growthbookFile.loadFeatures();
			const isActive = growthbookFile.isOn('ia_onboarding');
			if (isActive) {
				navigate(ROUTES.ONBOARDING);
			} else {
				navigate(ROUTES.ROOT);
			}
		} catch (error) {}
	};

	const acceptTerms = async () => {
		try {
			await acceptCompliance('T&Cs', tokenSession);
		} catch (error) {
			throw error;
		}
	};

	const updateProfile = async () => {
		const utmCampaignParam = searchParams.get(HERU_UTM.CAMPAIGN);
		const utmSourceParam = searchParams.get(HERU_UTM.SOURCE);
		const utmMediumParam = searchParams.get(HERU_UTM.MEDIUM);

		const isSessionAvailable = isSessionStorageEnabled();
		const utmCampaign = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
			: utmInfo?.utm_campaign || null;
		const utmSource = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
			: utmInfo?.utm_source || null;
		const utmMedium = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
			: utmInfo?.utm_medium || null;

		const body = {
			first_name: getValues('name'),
			father_surname: getValues('surname'),
			email: getValues('email'),
			utm_campaign: utmCampaignParam ?? utmCampaign ?? 'organic',
			utm_medium: utmMediumParam ?? utmMedium ?? 'organic',
			utm_source: utmSourceParam ?? utmSource ?? 'organic',
		};

		setIsLoading({ ...isLoading, updateProfile: true });
		try {
			await acceptTerms();
			await patchUserProfile(body, tokenSession);
			await updateStorageProfile();
			await handleGbFlag();

			const isSessionAvailable = isSessionStorageEnabled();
			const productsFromStorage = isSessionAvailable
				? sessionStorage.getItem(SESSION_STORAGE_KEY.PRODUCTS_ADDED)
				: products;

			const goTo = isSessionAvailable
				? sessionStorage.getItem(SESSION_STORAGE_KEY.GO_TO)
				: goToURl;

			if (productsFromStorage) {
				navigate(ROUTES.CHECKOUT);
				return;
			}

			if (goTo) {
				navigate(goTo);
				return;
			}
		} catch (error) {
			//@ts-ignore
			if (error?.response?.data?.message?.includes('already taken')) {
				setErrors({ ...errors, email: 'El correo ya está en uso' });
				setTimeout(() => {
					setErrors({ ...errors, email: '' });
				}, 5000);
				return;
			}
			toast.error('Ocurrio un error, intenté de nuevo más tarde.', {
				position: 'top-right',
			});
		} finally {
			setIsLoading({ ...isLoading, updateProfile: false });
		}
	};

	const onSubmit = async () => {
		if (getValues('email').match(REGEX.emailRegex) === null) {
			setErrors({ ...errors, email: 'El correo no es válido' });
			return;
		}
		if (!isFormValid) return;
		updateProfile();
	};

	useEffect(() => {
		setErrors((prev) => ({ ...prev, email: '' }));
	}, [emailInput]);

	useEffect(() => {
		getTermsAndConditions();
	}, []);

	return (
		<div className='pb-12 relative'>
			<Toaster />
			<RegisterForm
				control={control}
				errors={errors}
				isLoading={isLoading.updateProfile}
				isFormValid={isFormValid}
				onSubmit={onSubmit}
				handleSubmit={handleSubmit}
				termsAndConditions={termsAndConditions}
			/>
		</div>
	);
};

export default Register;
