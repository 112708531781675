import React, { useState } from 'react';

import { Radio } from '@mui/material';
import {
	CaretDown,
	CaretUp,
	CheckCircle,
	ShoppingCart,
} from '@phosphor-icons/react';
import { ListProducts } from '../../../../@types/Products.type';
import Text from '../../../../components/atoms/Text';
import Heading from '../../../../components/atoms/Heading';
import {
	formatPrice,
	getPeriodicity,
} from '../../../../shared/utils/general.util';
import Button from '../../../../components/atoms/Button';
import {
	PRODUCTS_BY_SLUG,
	PRODUCTS_PERIODICITIES_ID,
} from '../../data/ecommerceData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import hotSaleImg from '../../../../assets/img/svg/hot_sale.svg';
import { APP_CONFIGURATIONS } from '../../../../shared/constants/configurations';
import { getElasticyPrice } from '../../../../shared/utils/products.utils';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../../shared/utils/growthbook.util';

interface HeruPlusProps {
	products: ListProducts[];
	isMobile: boolean;
	handleAddToCart: (id: number) => void;
	isLoadingAddProduct: boolean;
}
const HeruPlus: React.FC<HeruPlusProps> = ({
	products,
	isMobile,
	handleAddToCart,
	isLoadingAddProduct,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const getHeruPlus = products?.[0]?.products?.filter(
		(it) => it?.slug === PRODUCTS_BY_SLUG.HERU_PLUS,
	);

	const [selectedValue, setSelectedValue] = useState(30);
	const [openBenefits, setOpenBenefits] = useState(false);
	const [selectPeriodicityHeruPlus, setSelectPeriodicityHeruPlus] = useState(
		PRODUCTS_PERIODICITIES_ID.HERU_PLUS_MONTHLY,
	);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	return (
		<>
			{isMobile
				? getHeruPlus?.map((item) => (
						<div
							key={item.id}
							className='flex flex-col rounded-md border mb-56'
						>
							<div className='sticky top-0 z-10 bg-white'>
								<div
									className={`${
										isHotSale ? 'bg-h-hot-sale' : 'bg-h-primary'
									} relative p-4 rounded-t-md flex flex-col items-center`}
								>
									{isHotSale && (
										<div className='mb-12'>
											<div className='absolute left-0 -top-16 flex items-center justify-center'>
												<img
													src={hotSaleImg}
													alt='hot sale heru'
													className='w-56 h-56'
												/>
											</div>
										</div>
									)}

									{!openBenefits && (
										<>
											<Text
												size='s'
												color='white'
												sx='text-center'
											>
												{item.description}
											</Text>
											<div
												className={`border ${
													isHotSale ? 'border-white' : 'border-blue-200'
												} my-4 mx-4 w-full`}
											/>
										</>
									)}

									<Text
										size='xl'
										color='white'
										weight='bold'
									>
										{item.name}
									</Text>
								</div>

								{isHotSale ? (
									<div className='flex items-center justify-end w-full my-4'>
										<div className='flex items-center mr-4'>
											<Text
												color='gray'
												size={'s'}
												sx='max-w-10 mr-5 md:mr-8 text-center'
											>
												Precio regular
											</Text>
											<Text
												color='gray'
												size={'label'}
												weight='regular'
												sx='line-through'
											>
												$
												{item.product_versions
													.filter((it) => it.id === selectPeriodicityHeruPlus)
													.map((it) => formatPrice(it?.price))}
											</Text>
										</div>
										<div className='flex items-center ml-2'>
											<Text
												color='darkMenu'
												weight='bold'
												size={'s'}
												sx='max-w-10 text-center mr-5'
											>
												HOT HERU
											</Text>
											<Heading
												color='darkMenu'
												size={'xs'}
												weight='medium'
											>
												$
											</Heading>
											<Heading
												color='darkMenu'
												size={'m'}
												weight='medium'
											>
												{item.product_versions
													.filter((it) => it.id === selectPeriodicityHeruPlus)
													.map((it) => formatPrice(it.price * 0.7))}
											</Heading>
											<Text
												color='darkMenu'
												size={'s'}
												sx='mt-5 ml-1'
												weight='bold'
											>
												{getPeriodicity(selectedValue)}
											</Text>
										</div>
									</div>
								) : (
									<div className='flex flex-row justify-end mr-4 mt-2'>
										<Heading
											size='s'
											weight='bold'
										>
											$
											{APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
												? item.product_versions
														.filter((it) => it.id === selectPeriodicityHeruPlus)
														.map((it) =>
															getElasticyPrice(
																Math.round(it.price / 100),
																elasticityPrice.group_experiment,
															),
														)
												: item.product_versions
														.filter(
															(it) => it?.id === selectPeriodicityHeruPlus,
														)
														.map((it) => formatPrice(it?.price))}
										</Heading>
										<Text
											size='m'
											weight='bold'
											sx='mt-4 ml-2'
										>
											{' '}
											{getPeriodicity(selectedValue)}
										</Text>
									</div>
								)}
							</div>

							<div className='flex flex-col p-4'>
								<div className='border-b pb-4'>
									<Text
										size='l'
										color='dark'
										weight='bold'
									>
										Frecuencia de pago
									</Text>
									<div className='grid grid-cols-2 gap-4 mt-2'>
										{item.product_versions
											.sort((a, b) => a.periodicity - b.periodicity)
											.map((it) => (
												<div
													key={it.id}
													className={`flex flex-row ${
														isHotSale && 'items-center'
													}`}
												>
													<Radio
														checked={selectPeriodicityHeruPlus === it.id}
														onChange={() => {
															setSelectPeriodicityHeruPlus(it.id);
															setSelectedValue(it.periodicity);
														}}
														value={it.id}
														name='radio-buttons'
														inputProps={{ 'aria-label': 'A' }}
													/>
													{it?.relative_percentage_off &&
													it?.relative_percentage_off > 0 ? (
														<div className='flex flex-col'>
															<Text
																size='m'
																color='dark'
															>
																{getPeriodicity(it.periodicity)}
															</Text>
															{!isHotSale && (
																<Text
																	size='s'
																	color='primary'
																>
																	Ahorra {it.relative_percentage_off}%
																</Text>
															)}
														</div>
													) : (
														<Text
															size='m'
															sx='ml-2'
															color='dark'
														>
															{getPeriodicity(it.periodicity)}
														</Text>
													)}
												</div>
											))}
									</div>
								</div>

								<div className='flex flex-col border-b'>
									<div className='flex flex-row justify-between py-8'>
										<Text
											color='dark'
											weight='bold'
											size='xl'
										>
											Beneficios
										</Text>
										<button onClick={() => setOpenBenefits(!openBenefits)}>
											{openBenefits ? (
												<CaretUp
													size={24}
													weight='light'
												/>
											) : (
												<CaretDown
													size={24}
													weight='light'
												/>
											)}
										</button>
									</div>

									{openBenefits && (
										<div className='flex flex-col'>
											{item.benefits.map((benefit, index) => (
												<div
													key={`heruplus-benefit-${index}`}
													className='flex items-center gap-2.5 mb-4'
												>
													<CheckCircle
														size={24}
														weight='light'
														color='var(--h-primary)'
													/>
													<div className='w-full'>
														<Text
															size='s'
															color='dark'
														>
															{benefit}
														</Text>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
							<div
								className={`fixed bottom-12 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg`}
							>
								<Button
									beforeIcon={
										<ShoppingCart
											size={24}
											weight='fill'
											color={isHotSale ? 'var(--h-primary)' : 'white'}
											className='mr-2'
										/>
									}
									label='Agregar al carrito'
									loading={isLoadingAddProduct}
									onClick={() => {
										handleAddToCart(selectPeriodicityHeruPlus);
									}}
									className={`${
										isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'
									} rounded-lg ${
										isHotSale ? 'text-h-primary' : 'text-white'
									} font-medium w-full flex items-center justify-center py-3`}
								/>
							</div>
						</div>
					))
				: getHeruPlus?.map((item) => (
						<div key={item.id}>
							<div
								className={`${
									isHotSale ? 'bg-h-hot-sale' : 'bg-h-primary'
								} relative pt-9 pb-5 py-7 rounded-t-xl flex flex-col items-center`}
							>
								{isHotSale && (
									<div className='mb-6'>
										<div className='absolute left-0 -top-16 flex items-center justify-center'>
											<img
												src={hotSaleImg}
												alt='hot sale heru'
												className='w-56 h-56'
											/>
										</div>
									</div>
								)}

								<Text
									size='l'
									color='white'
									sx='text-center max-w-[370px]'
								>
									{item.description}
								</Text>
								<div
									className={`border ${
										isHotSale ? 'border-white' : 'border-blue-200'
									} my-4 mx-4 w-full`}
								/>
								<Heading
									size='m'
									color='white'
									weight='bold'
								>
									{item.name}
								</Heading>
							</div>
							{isHotSale ? (
								<div className='flex items-center justify-end w-full my-4'>
									<div className='flex items-center mr-4'>
										<Text
											color='gray'
											size={'label'}
											sx='max-w-10 mr-5 md:mr-8 text-center'
										>
											Precio regular
										</Text>
										<Text
											color='gray'
											size={'body-1'}
											weight='regular'
											sx='line-through'
										>
											$
											{item.product_versions
												.filter((it) => it.id === selectPeriodicityHeruPlus)
												.map((it) => formatPrice(it?.price))}{' '}
										</Text>
									</div>
									<div className='flex items-center ml-2'>
										<Text
											color='darkMenu'
											weight='bold'
											size={'label'}
											sx='max-w-10 text-center mr-5'
										>
											HOT HERU
										</Text>
										<Heading
											color='darkMenu'
											size={'s'}
											weight='medium'
										>
											$
										</Heading>
										<Heading
											color='darkMenu'
											size={'l'}
											weight='medium'
										>
											{item.product_versions
												.filter((it) => it.id === selectPeriodicityHeruPlus)
												.map((it) => formatPrice(it.price * 0.7))}
										</Heading>
										<Text
											color='darkMenu'
											size={'label'}
											sx='mt-5 ml-1'
											weight='bold'
										>
											{getPeriodicity(selectedValue)}
										</Text>
									</div>
								</div>
							) : (
								<div className='flex flex-row justify-end mr-4 mt-2 mb-4'>
									<Heading
										size='xs'
										weight='bold'
										sx='mt-3 mr-2'
									>
										$
									</Heading>
									<Heading
										size='l'
										weight='bold'
									>
										{APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
											? item.product_versions
													.filter((it) => it.id === selectPeriodicityHeruPlus)
													.map((it) =>
														getElasticyPrice(
															Math.round(it.price / 100),
															elasticityPrice.group_experiment,
														),
													)
											: item.product_versions
													.filter((it) => it.id === selectPeriodicityHeruPlus)
													.map((it) => formatPrice(it.price))}
									</Heading>
									<Text
										size='xl'
										weight='bold'
										sx='mt-8 ml-2'
									>
										{getPeriodicity(selectedValue)}
									</Text>
								</div>
							)}

							<div className='flex flex-col px-10 overflow-y-auto max-h-[400px]'>
								<div className='border-b pb-4'>
									<Text
										size='l'
										color='dark'
										weight='bold'
									>
										Frecuencia de pago
									</Text>
									<div className='flex flex-row gap-8 mt-8 mb-6'>
										{item.product_versions
											.sort((a, b) => a.periodicity - b.periodicity)
											.map((it) => (
												<div
													key={it.id}
													className={`flex flex-row ${
														isHotSale && 'items-center'
													}`}
												>
													<Radio
														checked={selectPeriodicityHeruPlus === it.id}
														onChange={() => {
															setSelectPeriodicityHeruPlus(it.id);
															setSelectedValue(it.periodicity);
														}}
														value={it.id}
														name='radio-buttons'
														inputProps={{ 'aria-label': 'A' }}
													/>
													{it?.relative_percentage_off &&
													it?.relative_percentage_off > 0 ? (
														<div className='flex flex-col'>
															<Text
																size='m'
																color='dark'
															>
																{getPeriodicity(it.periodicity)}
															</Text>
															{!isHotSale && (
																<Text
																	size='s'
																	color='primary'
																>
																	Ahorra {it.relative_percentage_off}%
																</Text>
															)}
														</div>
													) : (
														<Text
															size='m'
															sx='ml-2'
															color='dark'
														>
															{getPeriodicity(it.periodicity)}
														</Text>
													)}
												</div>
											))}
									</div>
								</div>
								<div className='flex flex-col border-b'>
									<div className='flex flex-row justify-between py-8'>
										<Text
											color='dark'
											weight='bold'
											size='xl'
										>
											Beneficios
										</Text>
										<button onClick={() => setOpenBenefits(!openBenefits)}>
											{openBenefits ? (
												<CaretUp
													size={24}
													weight='light'
												/>
											) : (
												<CaretDown
													size={24}
													weight='light'
												/>
											)}
										</button>
									</div>

									{openBenefits && (
										<div className='flex flex-col'>
											{item.benefits.map((benefit, index) => (
												<div
													key={`heruplus-benefit-${index}`}
													className='flex items-center gap-2.5 mb-4'
												>
													<CheckCircle
														size={24}
														weight='light'
														color='var(--h-primary)'
													/>
													<div className='w-full'>
														<Text
															size='s'
															color='dark'
														>
															{benefit}
														</Text>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
							<div className='fixed bottom-0 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg'>
								<Button
									beforeIcon={
										<ShoppingCart
											size={24}
											weight='fill'
											color={isHotSale ? 'var(--h-primary)' : 'white'}
											className='mr-2'
										/>
									}
									loading={isLoadingAddProduct}
									label='Agregar al carrito'
									onClick={() => {
										handleAddToCart(selectPeriodicityHeruPlus);
									}}
									className={`${
										isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'
									} rounded-lg ${
										isHotSale ? 'text-h-primary' : 'text-white'
									} font-medium w-full flex items-center justify-center py-3`}
								/>
							</div>
						</div>
					))}
		</>
	);
};

export default React.memo(HeruPlus);
