export interface Regime {
	name: string;
	short_name: string;
	code: string;
	issuer_supported: boolean;
	receiver_supported: boolean;
}

export enum RegimesEnum {
	PLATFORMS = 'pt',
	RESICO = 'resico',
	ENTERPRISE = 'act_emp',
	SALARIED = 'sys',
	LEASE = 'arriendos',
	MULTIPLE = 'multiple',
	RIF = 'rif',
}

export enum REGIMES_CODE {
	PLATAFORMAS_TECNOLOGICAS = '625',
	SUELDOS_Y_SALARIOS = '605',
	RESICO = '626',
	ARRENDAMIENTO = '606',
	ACTIVIDAD_EMPRESARIAL = '612',
	GENERAL_LEY_PERSONAS_MORALES = '601',
	PERSONAS_MORALES_CON_FINES_NO_LUCRATIVOS = '603',
	RESIDENTES_EN_EXTRAJERO_SIN_ESTABLECIMIENTO_EN_MEXICO = '610',
	RIF = '621',
}

export enum REGIMES_NAME {
	PLATAFORMAS_TECNOLOGICAS = 'Plataformas tecnológicas',
	SUELDOS_Y_SALARIOS = 'Sueldos y salarios',
	RESICO = 'RESICO',
	ARRENDAMIENTO = 'Arrendamiento',
	ACTIVIDAD_EMPRESARIAL = 'Actividades Empresariales',
	RIF = 'RIF',
}

export enum REGIMES_LONG_NAMES {
	ACTIVIDADES_AGRICOLAS = 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
	ARRENDAMIENTO = 'Arrendamiento',
	CONSOLIDACION = 'Consolidación',
	COORDINADOS = 'Coordinados',
	EMPRESAS_MULTINACIONALES = 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
	DEMAS_INGRESOS = 'Demás ingresos',
	ENAJENACION_ACCIONES = 'Enajenación de acciones en bolsa de valores',
	ENAJENACION_BIENES = 'Enajenación o Adquisición de Bienes',
	GENERAL_LEY_PERSONAS_MORALES = 'General de Ley Personas Morales',
	HIDROCARBUROS = 'Hidrocarburos',
	INCORPORACION_FISCAL = 'Incorporación Fiscal',
	INGRESOS_DIVIDENDOS = 'Ingresos por Dividendos',
	INGRESOS_INTERESES = 'Ingresos por intereses',
	GRUPOS_SOCIEDADES = 'Opcional para Grupos de Sociedades',
	PERSONAS_FISICAS = 'Personas Físicas con Actividades Empresariales y Profesionales',
	PERSONAS_MORALES = 'Personas Morales con Fines no Lucrativos',
	RESIDENTES_EXTRANJERO = 'Residentes en el Extranjero sin Establecimiento Permanente en México',
	REGIMEN_SIMPLIFICADO = 'Régimen Simplificado de Confianza',
	INGRESOS_PLATAFORMAS = 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
	INGRESOS_PREMIOS = 'Régimen de los ingresos por obtención de premios',
	SIN_OBLIGACIONES = 'Sin obligaciones fiscales',
	COOPERATIVAS_PRODUCCION = 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
	SUELDOS_SALARIOS = 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
}
