const INVOICE_TYPES = [
	{
		label: 'Todos',
		value: 'all',
	},
	{
		label: 'Ingresos',
		value: 'I',
	},
	{
		label: 'Egresos',
		value: 'E',
	},
	{
		label: 'Nomina',
		value: 'N',
	},
	{
		label: 'Pago',
		value: 'P',
	},
	{
		label: 'Traslado',
		value: 'T',
	},
];

const INVOICE_STATUS = [
	{
		label: 'Todos',
		value: 'all',
	},
	{
		label: 'Vigente',
		value: 'VIGENTE',
	},
	{
		label: 'Cancelada',
		value: 'CANCELADO',
	},
];

const RETENTIONS_TYPES = [
	{
		label: 'Todos',
		value: 'all',
	},
	{
		label: 'Transporte',
		value: '01',
	},
	{
		label: 'Entrega de alimentos',
		value: '02',
	},
	{
		label: 'Entrega de bienes',
		value: '03',
	},
	{
		label: 'Hospedaje',
		value: '04',
	},
	{
		label: 'Comercion de bienes',
		value: '05',
	},
	{
		label: 'Otros servicios',
		value: '06',
	},
];

const DECLARATION_TYPES = [
	{
		label: 'Todos',
		value: 'all',
	},
	{
		label: 'Normal',
		value: 'NORMAL',
	},
	{
		label: 'Complementaria',
		value: 'COMPLEMENTARIA',
	},
];

const PAYMENT_STATUS = [
	{
		label: 'Todos',
		value: 'all',
	},
	{
		label: 'Pagado',
		value: 'paid',
	},
	{
		label: 'Pendiente de pago al SAT',
		value: 'pending',
	},
];

const INVOICE_TYPES_CODES = {
	Ingresos: 'I',
	Egresos: 'E',
	Nomina: 'N',
	Pago: 'P',
	Traslado: 'T',
};

const getTypeNameByCode = (code: string) => {
	const types: Record<string, string> = {
		[INVOICE_TYPES_CODES.Ingresos]: 'Ingresos',
		[INVOICE_TYPES_CODES.Egresos]: 'Egresos',
		[INVOICE_TYPES_CODES.Nomina]: 'Nomina',
		[INVOICE_TYPES_CODES.Pago]: 'Pago',
		[INVOICE_TYPES_CODES.Traslado]: 'Traslado',
	};

	return types[code] || 'Desconocido';
};

const getStatusLabel = (status: string) => {
	const statusList: Record<string, string> = {
		VIGENTE: 'Vigente',
		CANCELADO: 'Cancelada',
		ACTIVE: 'Activa',
	};

	return statusList[status] || 'Desconocido';
};

const getRetentionTypeLabel = (type: string) => {
	const retentionTypes: Record<string, string> = {
		'01': 'Transporte',
		'02': 'Entrega de alimentos',
		'03': 'Entrega de bienes',
		'04': 'Hospedaje',
		'05': 'Comercion de bienes',
		'06': 'Otros servicios',
	};

	return retentionTypes[type] || 'Desconocido';
};

const getInvoicePaymentMethod = (method: string) => {
	const paymentMethods: Record<string, string> = {
		'01': 'Efectivo',
		'02': 'Cheque nominativo',
		'03': 'Transferencia electrónica de fondos',
		'04': 'Tarjeta de crédito',
		'05': 'Monedero electrónico',
		'06': 'Dinero electrónico',
		'08': 'Vales de despensa',
		'12': 'Dación en pago',
		'13': 'Pago por subrogación',
		'14': 'Pago por consignación',
		'15': 'Condonación',
		'17': 'Compensación',
		'23': 'Novación',
		'24': 'Confusión',
		'25': 'Remisión de deuda',
		'26': 'Prescripción o caducidad',
		'27': 'A satisfacción del acreedor',
		'28': 'Tarjeta de débito',
		'29': 'Tarjeta de servicios',
		'30': 'Aplicación de anticipos',
		'99': 'Por definir',
	};

	return paymentMethods[method] || 'Desconocido';
};

export {
	INVOICE_TYPES,
	INVOICE_STATUS,
	RETENTIONS_TYPES,
	DECLARATION_TYPES,
	PAYMENT_STATUS,
	getTypeNameByCode,
	getStatusLabel,
	getRetentionTypeLabel,
	getInvoicePaymentMethod,
	INVOICE_TYPES_CODES,
};
