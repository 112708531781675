import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DATES } from '../../../../../shared/utils/dates';
import {
	nameBimester,
	nameMonth,
	nameQuarter,
} from '../../../../../shared/utils/formatMonth';
import { CaretCircleLeft } from '@phosphor-icons/react/dist/ssr';
import { CaretCircleRight } from '@phosphor-icons/react';
import { PeriodsSelectedType } from '../../../../../@types/Calendar.type';

const styles = {
	title: {
		color: '#0A2B34',
		fontSize: '15px',
	},
	disable: {
		opacity: 0.5,
	},
};

interface Props {
	periods: (e: any[]) => void;
	value?: string;
	viewArriendos?: boolean;
	viewRif?: boolean;
	viewResico?: boolean;
	viewActivity?: boolean;
}

const CalendarOtherRegimes: React.FC<Props> = ({
	periods,
	value,
	viewArriendos,
	viewRif,
	viewResico,
	viewActivity,
}) => {
	const [year, setYear] = useState(DATES.currentYear);
	const [minimumDateSupported, setMinimumDateSupported] = useState({
		month: 6,
		year: 2020,
	});

	const [periodsSelected, setPeriodsSelected] = useState<PeriodsSelectedType[]>(
		[],
	);

	const first_periods = [1, 2, 3, 4];
	const second_periods = [5, 6, 7, 8];
	const third_periods = [9, 10, 11, 12];

	const firstQuarter = [1, 2];
	const secondQuarter = [3, 4];

	const firstBimester = [1, 2];
	const secondBimester = [3, 4];
	const thirdBimester = [5, 6];

	const months = [first_periods, second_periods, third_periods];
	const quarter = [firstQuarter, secondQuarter];
	const bimester = [firstBimester, secondBimester, thirdBimester];

	function handleUpdateYear(type: string) {
		if (type === 'decrease') {
			if (year > minimumDateSupported.year) {
				setYear(year - 1);
			}
		} else {
			if (year < DATES.currentYear) {
				setYear(year + 1);
			}
		}
	}

	const isPeriodSelected = (monthR: number, yearR: number) => {
		const found = periodsSelected.find(
			(p) => p.month === monthR && p.year === yearR,
		);
		if (found) {
			return true;
		} else {
			return false;
		}
	};

	const handleGetMonth = (month: number) => {
		const foundPeriod = periodsSelected.findIndex(
			(p) => p.month === month && p.year === year,
		);
		if (foundPeriod === -1) {
			const periodSelected = {
				month,
				year,
			};
			setPeriodsSelected([...periodsSelected, periodSelected]);
			addPeriods([...periodsSelected, periodSelected]);
		} else {
			const allPeriods = periodsSelected.filter(
				(_, index) => index !== foundPeriod,
			);
			setPeriodsSelected(allPeriods);
			addPeriods(allPeriods);
		}
	};

	const isPeriodSelectedQuarter = (quarterR: number, yearR: number) => {
		const found = periodsSelected.find(
			(p) => p.quarter === quarterR && p.year === yearR,
		);
		if (found) {
			return true;
		} else {
			return false;
		}
	};

	const handleGetQuarter = (quarter: number) => {
		const foundPeriod = periodsSelected.findIndex(
			(p) => p.quarter === quarter && p.year === year,
		);
		if (foundPeriod === -1) {
			const periodSelected = {
				quarter,
				year,
			};
			setPeriodsSelected([...periodsSelected, periodSelected]);
			addPeriods([...periodsSelected, periodSelected]);
		} else {
			const allPeriods = periodsSelected.filter(
				(_, index) => index !== foundPeriod,
			);
			setPeriodsSelected(allPeriods);
			addPeriods(allPeriods);
		}
	};

	const isPeriodSelectedBimester = (bimesterR: number, yearR: number) => {
		const found = periodsSelected.find(
			(p) => p.bimester === bimesterR && p.year === yearR,
		);
		if (found) {
			return true;
		} else {
			return false;
		}
	};

	const handleGetBimester = (bimester: number) => {
		const foundPeriod = periodsSelected.findIndex(
			(p: any) => p.bimester === bimester && p.year === year,
		);
		if (foundPeriod === -1) {
			const periodSelected = {
				bimester,
				year,
			};
			setPeriodsSelected([...periodsSelected, periodSelected]);
			addPeriods([...periodsSelected, periodSelected]);
		} else {
			const allPeriods = periodsSelected.filter(
				(_, index) => index !== foundPeriod,
			);
			setPeriodsSelected(allPeriods);
			addPeriods(allPeriods);
		}
	};

	const isPeriodSelectedActivity = (monthR: number, yearR: number) => {
		const found = periodsSelected.find(
			(p) => p.month === monthR && p.year === yearR,
		);
		if (found) {
			return true;
		} else {
			return false;
		}
	};

	//!Maybe we are going to use this function in the future
	// const handleGetMonthActivity = (month: number, year: number) => {
	// 	const selectedMonths = [];
	// 	const currentYear = DATES.currentYear;
	// 	const currentMonth = DATES.currentMonth;
	// 	const maxMonth = 12;

	// 	if (year === currentYear) {
	// 		for (let i = month; i < currentMonth; i++) {
	// 			const periodSelected = {
	// 				month: i,
	// 				year,
	// 			};
	// 			selectedMonths.push(periodSelected);
	// 		}
	// 	} else if (year < currentYear) {
	// 		for (let i = month; i <= maxMonth; i++) {
	// 			const periodSelected = {
	// 				month: i,
	// 				year,
	// 			};
	// 			selectedMonths.push(periodSelected);
	// 		}
	// 	}

	// 	for (let i = year + 1; i < currentYear; i++) {
	// 		for (let j = 1; j <= maxMonth; j++) {
	// 			const periodSelected = {
	// 				month: j,
	// 				year: i,
	// 			};
	// 			selectedMonths.push(periodSelected);
	// 		}
	// 	}

	// 	if (year !== currentYear) {
	// 		for (let i = 1; i < currentMonth; i++) {
	// 			const periodSelected = {
	// 				month: i,
	// 				year: currentYear,
	// 			};
	// 			selectedMonths.push(periodSelected);
	// 		}
	// 	}

	// 	setPeriodsSelected(selectedMonths);
	// 	addPeriods(selectedMonths);
	// };

	const isDisabledPeriod = (monthR: number, yearR: number) => {
		if (DATES.currentMonth <= monthR && DATES.currentYear === yearR) {
			return true;
		}
	};

	const isDisabledQuarter = (quarterR: number, yearR: number) => {
		const quarter = Math.floor((DATES.currentMonth - 1) / 3) + 1;

		if (DATES.currentYear === yearR && quarterR === quarter) {
			return true; // Bloquear el trimestre actual
		}

		if (DATES.currentYear === yearR && quarterR > quarter) {
			return true; // Bloquear trimestres futuros en el año actual
		}

		// En todos los demás casos, no bloquear el trimestre
		return false;
	};

	const isDisabledBimester = (bimesterR: number, yearR: number) => {
		const bimester = Math.floor((DATES.currentMonth - 1) / 2) + 1;

		if (DATES.currentYear === yearR && bimesterR === bimester) {
			return true; // Bloquear el bimestre actual
		}

		if (DATES.currentYear === yearR && bimesterR > bimester) {
			return true; // Bloquear bimestres futuros en el año actual
		}

		// En todos los demás casos, no bloquear el bimestre
		return false;
	};

	const addPeriods = (period: any[]) => {
		periods(period);
	};

	useEffect(() => {
		if (viewResico) {
			setPeriodsSelected([]);
			setMinimumDateSupported({
				month: 1,
				year: 2022,
			});
		} else if (viewArriendos || viewRif || viewActivity) {
			setPeriodsSelected([]);
			setMinimumDateSupported({
				month: 1,
				year: 2017,
			});
		}
	}, [viewResico, viewArriendos, viewRif, viewActivity]);

	useEffect(() => {
		if (value === 'GanaMás') {
			setPeriodsSelected([]);
		} else if (value === 'GanaMenos') {
			setPeriodsSelected([]);
		}
	}, [value]);

	return (
		<Box sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
			<Stack
				direction='row'
				alignItems='center'
				spacing={1}
				sx={{ justifyContent: 'center', marginBottom: '1rem' }}
			>
				<IconButton
					color='primary'
					onClick={() => handleUpdateYear('decrease')}
					sx={minimumDateSupported.year === year ? styles.disable : {}}
					disabled={minimumDateSupported.year === year}
				>
					<CaretCircleLeft
						size={20}
						weight='fill'
						color='var(--h-primary)'
					/>
				</IconButton>
				<Typography style={styles.title}>{year}</Typography>
				<IconButton
					color='primary'
					sx={
						DATES.currentYear === year
							? styles.disable
							: DATES.currentMonth === 1
							? styles.disable
							: null
					}
					disabled={DATES.currentYear === year}
					onClick={() => handleUpdateYear('increase')}
				>
					<CaretCircleRight
						size={20}
						weight='fill'
						color='var(--h-primary)'
					/>
				</IconButton>
			</Stack>
			{(viewArriendos && value === 'GanaMás') || viewResico
				? months.map((periods, index) => {
						return (
							<div
								className='flex flex-wrap justify-center'
								key={`period_${index}`}
							>
								{periods.map((month, index) => {
									return (
										<Button
											key={`month_${index}`}
											sx={{
												...Styles.button,
												width: ['50%', '107px'],
												height: ['50%', '91px'],
												color: isPeriodSelected(month, year)
													? '1.5px solid #1D8FF3'
													: 'black',
												backgroundColor: isPeriodSelected(month, year)
													? '#DAEDFE'
													: 'transparent',
												':disabled': {
													backgroundColor: 'var(--h-gray-10)',
													color: 'var(--h-gray-40)',
													border: '1.16254px solid #F1F1F1',
												},
											}}
											onClick={() => handleGetMonth(month)}
											disabled={isDisabledPeriod(month, year)}
										>
											{nameMonth(month)}
										</Button>
									);
								})}
							</div>
						);
				  })
				: viewArriendos && value === 'GanaMenos'
				? quarter.map((periods, index) => {
						return (
							<div
								className='flex flex-wrap justify-center'
								key={`period_${index}`}
							>
								{periods.map((quarter, index) => {
									return (
										<Button
											key={`month_${index}`}
											//@ts-expect-error
											sx={{
												...Styles.buttonQuarter,
												color: isPeriodSelectedQuarter(quarter, year)
													? '1.5px solid #1D8FF3'
													: 'black',
												backgroundColor:
													isPeriodSelectedQuarter(quarter, year) && '#DAEDFE',
												':disabled': {
													backgroundColor: 'var(--h-gray-10)',
													color: 'var(--h-gray-40)',
													border: '1.16254px solid #F1F1F1',
												},
											}}
											disabled={isDisabledQuarter(quarter, year)}
											onClick={() => handleGetQuarter(quarter)}
										>
											{nameQuarter(quarter)}
										</Button>
									);
								})}
							</div>
						);
				  })
				: viewRif
				? bimester.map((periods, index) => {
						return (
							<div
								className='flex flex-wrap justify-center'
								key={`period_${index}`}
							>
								{periods.map((bimester, index) => {
									return (
										<Button
											key={`month_${index}`}
											sx={{
												...Styles.buttonBimester,
												color: isPeriodSelectedBimester(bimester, year)
													? '1.5px solid #1D8FF3'
													: 'black',
												backgroundColor: isPeriodSelectedBimester(
													bimester,
													year,
												)
													? '#DAEDFE'
													: 'transparent',
												':disabled': {
													backgroundColor: 'var(--h-gray-10)',
													color: 'var(--h-gray-40)',
													border: '1.16254px solid #F1F1F1',
												},
											}}
											onClick={() => handleGetBimester(bimester)}
											disabled={isDisabledBimester(bimester, year)}
										>
											{nameBimester(bimester)}
										</Button>
									);
								})}
							</div>
						);
				  })
				: viewActivity
				? months.map((periods, index) => {
						return (
							<div
								className='flex flex-wrap justify-center'
								key={`period_${index}`}
							>
								{periods.map((month, index) => {
									return (
										<Button
											key={`month_${index}`}
											sx={{
												...Styles.button,
												width: ['50%', '107px'],
												height: ['50%', '91px'],
												color: isPeriodSelectedActivity(month, year)
													? '1.5px solid #1D8FF3'
													: 'black',
												backgroundColor: isPeriodSelectedActivity(month, year)
													? '#DAEDFE'
													: 'transparent',
												':disabled': {
													backgroundColor: 'var(--h-gray-10)',
													color: 'var(--h-gray-40)',
													border: '1.16254px solid #F1F1F1',
												},
											}}
											onClick={() => handleGetMonth(month)}
											disabled={isDisabledPeriod(month, year)}
										>
											{nameMonth(month)}
										</Button>
									);
								})}
							</div>
						);
				  })
				: null}
		</Box>
	);
};

export default React.memo(CalendarOtherRegimes);

const Styles = {
	button: {
		textTransform: 'none',
		padding: '1rem',
		border: '1.16254px solid #F1F1F1',
		position: 'relative',
	},
	buttonBimester: {
		width: '213px',
		height: '92px',
		textTransform: 'none',
		margin: '0.1rem',
		padding: '1rem 0rem 1rem 0rem',
		border: '1.16254px solid #F1F1F1',
		position: 'relative',
	},
	buttonQuarter: {
		width: '213px',
		height: '138px',
		textTransform: 'none',
		margin: '0.1rem',
		padding: '1rem',
		border: '1.16254px solid #F1F1F1',
		position: 'relative',
	},
	containerNameMonth: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
	},
};
