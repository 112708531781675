import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../atoms/Input';
import Text from '../../atoms/Text';
import { ArrowRight, Briefcase, Smiley } from '@phosphor-icons/react';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import TypingText from '../../molecules/TypingText';
import LITERALS from '../../../shared/constants/literals';

type FormBody = {
	userMessage: string;
};

interface Props {
	title: string;
	subtitle: string;
	example: string;
	buttonLabel: string;
	onSubmit: SubmitHandler<FormBody>;
	loading: boolean;
	step: number;
}

const PromptForm = ({
	title,
	subtitle,
	example,
	buttonLabel,
	onSubmit,
	loading,
	step,
}: Props) => {
	const { control, handleSubmit } = useForm<FormBody>();

	return (
		<div className='w-full max-w-[360px] mx-auto pt-8 z-10 relative'>
			{step > 2 ? (
				<Briefcase
					size={32}
					color='var(--h-primary)'
					weight='fill'
					className='mb-4'
				/>
			) : (
				<Smiley
					size={32}
					color='var(--h-primary)'
					weight='fill'
					className='mb-4'
				/>
			)}
			<Heading
				size='xs'
				color='dark'
				weight='medium'
			>
				{title}
			</Heading>
			<TypingText
				text={subtitle}
				duration={120}
			/>
			<form
				className='mt-8'
				onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					autofocus
					minRows={4}
					multiline
					rules={{ required: LITERALS.FORM_REQUIRED_ERROR }}
					control={control}
					name='userMessage'
					type='text'
					placeholder='Escribe aquí tu mensaje'
				/>
				<Text
					color='dark'
					size='s'
					sx='mt-2 opacity-80'
				>
					{example}
				</Text>
				<div className='flex gap-2'>
					<Button
						loading={loading}
						label={buttonLabel}
						variant='primary'
						type='submit'
						sx='mt-16'
						icon={<ArrowRight size={18} />}
					/>
				</div>
			</form>
		</div>
	);
};

export default PromptForm;
