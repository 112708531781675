import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { DATES } from '../../../../../shared/utils/dates';
import { FiscalDeclaration } from '../../../../../@types/Calendar.type';
import { nameMonth } from '../../../../../shared/utils/formatMonth';
import { CaretCircleLeft, CaretCircleRight } from '@phosphor-icons/react';
import Text from '../../../../../components/atoms/Text';

interface Props {
	fiscalDeclarationStatus: FiscalDeclaration | undefined;
	periods: (e: PeriodsSelectedType[]) => void;
	styles: any;
}

export type PeriodsSelectedType = {
	month: number;
	year: number;
};

const Calendar: React.FC<Props> = ({
	fiscalDeclarationStatus,
	periods,
	styles,
}) => {
	const [year, setYear] = useState(DATES.currentYear);
	const [minimumDateSupported, setMinimumDateSupported] = useState({
		month: 6,
		year: 2020,
	});
	const [periodsSelected, setPeriodsSelected] = useState<PeriodsSelectedType[]>(
		[],
	);

	const first_periods = [1, 2, 3, 4];
	const second_periods = [5, 6, 7, 8];
	const third_periods = [9, 10, 11, 12];

	const months = [first_periods, second_periods, third_periods];

	function handleUpdateYear(type: string) {
		if (type === 'decrease') {
			if (year > minimumDateSupported.year) {
				setYear(year - 1);
			}
		} else {
			if (year < DATES.currentYear) {
				setYear(year + 1);
			}
		}
	}

	const isPeriodSelected = (monthR: number, yearR: number) => {
		const found = periodsSelected.find(
			(p) => p.month === monthR && p.year === yearR,
		);
		if (found) {
			return true;
		} else {
			return false;
		}
	};

	const disabled = (monthR: number, yearR: number) => {
		if (DATES.currentMonth === monthR && yearR == DATES.currentYear) {
			return true;
		}
		if (fiscalDeclarationStatus?.periods) {
			const pending = fiscalDeclarationStatus?.periods?.filter(
				(e) => e.obligation_existed,
			);
			const found = pending?.find(
				(p) => p.month === monthR && p.year === yearR,
			);
			return !found;
		} else {
			return DATES.currentMonth <= monthR && yearR == DATES.currentYear;
		}
	};

	const handleGetMonth = (month: number) => {
		const foundPeriod = periodsSelected.findIndex(
			(p) => p.month === month && p.year === year,
		);
		if (foundPeriod === -1) {
			const periodSelected = {
				month,
				year,
			};
			setPeriodsSelected([...periodsSelected, periodSelected]);
			addPeriods([...periodsSelected, periodSelected]);
		} else {
			const allPeriods = periodsSelected.filter(
				(_, index) => index !== foundPeriod,
			);
			setPeriodsSelected(allPeriods);
			addPeriods(allPeriods);
		}
	};

	const addPeriods = (periodsReceive: PeriodsSelectedType[]) => {
		periods(periodsReceive);
	};

	const addAllPeriods = () => {
		const allRegularization = fiscalDeclarationStatus?.periods.filter(
			(e) =>
				e.obligation_existed &&
				!e.acquired &&
				!e.presented &&
				!e.approved &&
				!e.computed,
		);

		let updatedPeriodsSelected = [...periodsSelected];

		allRegularization?.forEach((element) => {
			if (
				DATES.currentMonth !== element.month ||
				element.year !== DATES.currentYear
			) {
				const foundPeriod = periodsSelected.findIndex(
					(p) => p.month === element.month && p.year === element.year,
				);
				if (foundPeriod === -1) {
					const periodSelected = {
						month: element.month,
						year: element.year,
					};
					updatedPeriodsSelected.push(periodSelected);
				}
			}
		});

		setPeriodsSelected(updatedPeriodsSelected);
		periods(updatedPeriodsSelected);
	};

	useEffect(() => {
		if (fiscalDeclarationStatus?.periods) {
			if (fiscalDeclarationStatus?.periods[0]) {
				setMinimumDateSupported({
					month: fiscalDeclarationStatus?.periods[0].month,
					year: fiscalDeclarationStatus?.periods[0].year,
				});
			}
		}
	}, [fiscalDeclarationStatus]);

	const bgStatusDeclaration = useMemo(
		() => (monthR: number, yearR: number) => {
			if (DATES.currentMonth <= monthR && yearR == DATES.currentYear) {
				return 'transparent';
			}
			if (fiscalDeclarationStatus?.periods) {
				const presented = fiscalDeclarationStatus?.periods?.filter(
					(e) => e.obligation_existed && (e.acquired || e.presented),
				);
				const found = presented?.find(
					(p) => p.month === monthR && p.year === yearR,
				);
				if (found) {
					return 'var(--h-green-40)';
				}

				const pending = fiscalDeclarationStatus?.periods?.filter(
					(e) =>
						e.obligation_existed && !e.acquired && !e.presented && !e.approved,
				);
				const foundPending = pending?.find(
					(p) => p.month === monthR && p.year === yearR,
				);
				if (foundPending) {
					return 'var(--h-red-50)';
				}
				return 'transparent';
			}
			return 'transparent';
		},
		[fiscalDeclarationStatus],
	);

	return (
		<Box sx={{ marginBottom: '2rem' }}>
			<Stack
				direction='row'
				alignItems='center'
				spacing={1}
				sx={{
					justifyContent: fiscalDeclarationStatus ? 'end' : 'center',
					marginBottom: '1rem',
				}}
			>
				<IconButton
					color='primary'
					onClick={() => handleUpdateYear('decrease')}
					sx={minimumDateSupported.year === year && styles.disable}
					disabled={minimumDateSupported.year === year}
				>
					<CaretCircleLeft
						size={20}
						weight='fill'
						color='var(--h-primary)'
					/>
				</IconButton>
				<Typography style={styles.title}>{year}</Typography>
				<IconButton
					color='primary'
					sx={DATES.currentYear === year && styles.disable}
					disabled={DATES.currentYear === year}
					onClick={() => handleUpdateYear('increase')}
				>
					<CaretCircleRight
						size={20}
						weight='fill'
						color='var(--h-primary)'
					/>
				</IconButton>
				{fiscalDeclarationStatus && (
					<div
						onClick={() => addAllPeriods()}
						className='cursor-pointer w-[146px] h-[53px] px-5 py-3.5 bg-blue-100 rounded-lg shadow justify-center items-center gap-2.5 inline-flex'
					>
						<Text
							size='xxs'
							sx='text-center'
							color='dark'
						>
							Agregar todos los meses atrasados
						</Text>
					</div>
				)}
			</Stack>
			{months.map((periods, index) => {
				return (
					<div
						className='flex flex-wrap justify-center'
						key={`period_${index}`}
					>
						{periods.map((month, index) => {
							return (
								<Button
									key={`month_${index}`}
									disabled={disabled(month, year)}
									sx={{
										...Styles.button,
										width: ['50%', '107px'],
										height: ['50%', '91px'],
										color: isPeriodSelected(month, year)
											? '1.5px solid #1D8FF3'
											: 'black',
										backgroundColor: isPeriodSelected(month, year)
											? '#DAEDFE'
											: 'white',
										':disabled': {
											backgroundColor: 'var(--h-gray-10)',
											color: 'var(--h-gray-40)',
											border: '1.16254px solid #F1F1F1',
										},
									}}
									onClick={() => {
										handleGetMonth(month);
									}}
								>
									{fiscalDeclarationStatus && (
										<Box
											sx={{
												...Styles.containerNameMonth,
												backgroundColor: bgStatusDeclaration(month, year),
												// 'var(--h-green-40)',
											}}
										></Box>
									)}
									{nameMonth(month)}
								</Button>
							);
						})}
					</div>
				);
			})}
		</Box>
	);
};

export default React.memo(Calendar);

const Styles = {
	button: {
		textTransform: 'none',
		padding: '1rem',
		border: '1.16254px solid #F1F1F1',
		position: 'relative',
	},
	containerNameMonth: {
		position: 'absolute',
		top: '5px',
		right: '5px',
		width: '10px',
		height: '10px',
		borderRadius: '50%',
	},
};
