import { useState } from 'react';
import Text from '../../../components/atoms/Text';
import { CaretDown, CaretUp, PencilSimple } from '@phosphor-icons/react';
import { SummaryInvoice } from '../../../@types/Invoicing.type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ProfileState } from '../../../redux/slices/profile.slice';
import { formatCurrency } from '../../../shared/utils/general.util';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { setInvoicingCurrentStep } from '../../../redux/slices/invoicing';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';

const DataCardInfo = ({
	label,
	value,
	align = 'left',
}: {
	label: string;
	value: string;
	align?: string;
}) => {
	const textAlign: Record<string, string> = {
		left: '!text-left',
		center: '!text-center',
		right: '!text-right',
	};

	return (
		<div className=''>
			<Text
				size='caption'
				color='medium'
				weight='medium'
				sx={`!block ${textAlign[align]}`}
			>
				{label}
			</Text>
			<Text
				size='caption'
				color='medium'
				weight='light'
				sx={`!block ml-2 ${textAlign[align]}`}
			>
				{value}
			</Text>
		</div>
	);
};

const DataCard = ({
	title,
	properties,
}: {
	title: string;
	properties: { label: string; value: string }[];
}) => {
	const dispatch = useDispatch();
	return (
		<div className='bg-h-neutral-95 rounded-md py-2 px-3 '>
			<div className='flex justify-between items-center border-b pb-2'>
				<Text>{title}</Text>
				<PencilSimple
					height={32}
					width={32}
					color='var(--h-primary)'
					onClick={() => {
						dispatch(setInvoicingCurrentStep(1));

						logClevertapEvent(ANALYTICS_EVENTS.INVOICE_THIRD_STEP_VALIDATION, {
							event_type: EVENT_TYPES.INVOICE_EDIT_RECEIVER,
						});
					}}
					className='hover:bg-h-primary-90 p-1.5 rounded-lg cursor-pointer'
				/>
			</div>
			<div className='pt-2 flex flex-col gap-2'>
				{properties.map(({ label, value }, index) => (
					<DataCardInfo
						key={`prop-index-${index}`}
						label={label}
						value={value}
					/>
				))}
			</div>
		</div>
	);
};

type ProductCardProps = {
	validatedInvoiceSummary: SummaryInvoice | undefined;
};

const ProductCard: React.FC<ProductCardProps> = ({
	validatedInvoiceSummary,
}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { catalogs } = useSelector<RootState, ProfileState>(
		(state) => state.profile,
	);

	const productsTotal = [
		{
			label: 'Subtotal',
			value: formatCurrency(validatedInvoiceSummary?.sub_total!),
		},
		{
			label: 'Descuento',
			value: formatCurrency(validatedInvoiceSummary?.discount!),
		},
		{
			label: 'IVA',
			value: formatCurrency(validatedInvoiceSummary?.total_transfer_iva!),
		},
		{
			label: 'Retenciones IVA',
			value: formatCurrency(validatedInvoiceSummary?.total_retentions_iva!),
		},
		{
			label: 'Retentciones ISR',
			value: formatCurrency(validatedInvoiceSummary?.total_retentions_isr!),
		},
	];

	return (
		<>
			<div className='mt-4 flex flex-col gap-4'>
				{validatedInvoiceSummary?.concepts?.map((product, index) => {
					const unitName =
						catalogs?.key_unity?.[product?.key_unity]?.name || '-';

					return (
						<div
							key={`card-index-${index}`}
							className='bg-h-primary-98 border-h-primary-80 border p-3 rounded-md flex flex-col gap-3'
						>
							<div className='flex justify-between'>
								<DataCardInfo
									label='Código del producto o servicio'
									value={product?.key_prod_serv || '-'}
								/>
								<PencilSimple
									color='var(--h-primary)'
									height={32}
									width={32}
									onClick={() => {
										dispatch(setInvoicingCurrentStep(2));

										logClevertapEvent(
											ANALYTICS_EVENTS.INVOICE_THIRD_STEP_VALIDATION,
											{
												event_type: EVENT_TYPES.INVOICE_EDIT_PRODUCT,
											},
										);
									}}
									className='hover:bg-h-primary-90 p-1.5 rounded-lg cursor-pointer'
								/>
							</div>
							<DataCardInfo
								label='Descripción'
								value={product?.description || '-'}
							/>
							<div className='flex justify-between'>
								<DataCardInfo
									label='Unidad de medida'
									value={unitName}
								/>
								<DataCardInfo
									label='Cantidad'
									value='1'
									align='right'
								/>
							</div>
							<div className='flex justify-between'>
								<DataCardInfo
									label='Costo unitario'
									value={formatCurrency(product?.unitary_value)}
								/>
								<DataCardInfo
									label='Descuento'
									value={formatCurrency(product?.discount)}
									align='right'
								/>
							</div>
							<hr />
							<Text
								size='caption'
								sx='justify-between'
								color='medium'
							>
								Subtotal <span>{formatCurrency(product?.subtotal)}</span>
							</Text>
						</div>
					);
				})}
			</div>
			<div className='px-2'>
				<div className='flex flex-col gap-2 mt-5'>
					{productsTotal.map(({ value, label }, index) => (
						<Text
							sx='justify-between'
							key={`total-index-${index}`}
						>
							{label} <span>{value}</span>
						</Text>
					))}
				</div>
				<hr className='my-3' />
				<Text
					sx='justify-between mb-2'
					size='body-1'
					weight='medium'
				>
					Total{' '}
					<span>{formatCurrency(validatedInvoiceSummary?.total_amount!)}</span>
				</Text>
			</div>
		</>
	);
};

type Props = {
	validatedInvoiceSummary: SummaryInvoice | undefined;
};

const InvoiceStep3: React.FC<Props> = ({ validatedInvoiceSummary }) => {
	const { regimes, catalogs } = useSelector<RootState, ProfileState>(
		(state) => state.profile,
	);
	const [open, setopen] = useState({
		data: true,
		product: true,
	});

	const issuerRegimeCode = validatedInvoiceSummary?.issuer.tax_regime;
	const issuerRegime =
		regimes?.find((regime) => regime.code === issuerRegimeCode)?.name || '-';

	const receiverRegimeCode = validatedInvoiceSummary?.receiver.tax_regime;
	const receiverRegime =
		regimes?.find((regime) => regime.code === receiverRegimeCode)?.name || '-';

	const usoCFDI =
		(validatedInvoiceSummary?.receiver.usocfdi &&
			catalogs?.usocfdi?.[validatedInvoiceSummary?.receiver?.usocfdi]?.name) ||
		'-';

	return (
		<div className='flex flex-col gap-3'>
			<div className='border border-h-neutral-80 p-3 rounded-lg flex flex-col gap-5'>
				<div
					className='flex justify-between gap-2 border-b pb-1 items-center cursor-pointer'
					onClick={() =>
						setopen({
							...open,
							data: !open.data,
						})
					}
				>
					<div className='flex gap-2 items-center'>
						<Text
							size='mini-2'
							color='white'
							sx='bg-h-text-medium rounded-full w-4 h-4 items-center justify-center'
						>
							1
						</Text>
						<Text
							size='body-1'
							color='medium'
						>
							Datos
						</Text>
					</div>
					{open.data ? <CaretUp /> : <CaretDown />}
				</div>
				{open.data && (
					<>
						<DataCard
							title='Emisor'
							properties={[
								{
									label: 'Nombre',
									value: validatedInvoiceSummary?.issuer?.name || '-',
								},
								{
									label: 'RFC',
									value:
										validatedInvoiceSummary?.issuer?.rfc?.toUpperCase() || '-',
								},
								{
									label: 'Código postal',
									value: validatedInvoiceSummary?.place_of_expedition || '-',
								},
								{
									label: 'Régimen fiscal',
									value: issuerRegime,
								},
								{
									label: 'Fecha de emisión',
									value:
										getFormatDate(
											validatedInvoiceSummary?.date || '',
											'DD MMM YYYY',
											false,
											true,
										) || '-',
								},
							]}
						/>
						<DataCard
							title='Receptor'
							properties={[
								{
									label: 'Nombre',
									value: validatedInvoiceSummary?.receiver?.name || '-',
								},
								{
									label: 'RFC',
									value:
										validatedInvoiceSummary?.receiver?.rfc?.toUpperCase() ||
										'-',
								},
								{
									label: 'Código postal:',
									value: validatedInvoiceSummary?.receiver?.postal_code || '-',
								},
								{
									label: 'Régimen fiscal:',
									value: receiverRegime,
								},
								{
									label: 'Uso del CFDI:',
									value: usoCFDI,
								},
							]}
						/>
					</>
				)}
			</div>

			<div className='bg-h-primary-95 py-2 px-3 rounded-md'>
				<div
					className='flex justify-between items-center border-b border-h-primary-80 pb-2 cursor-pointer'
					onClick={() =>
						setopen({
							...open,
							product: !open.product,
						})
					}
				>
					<div className='flex items-center gap-2'>
						<Text
							size='mini-2'
							color='white'
							sx='bg-h-text-medium rounded-full w-4 h-4 items-center justify-center'
						>
							2
						</Text>
						<Text
							size='body-1'
							color='medium'
						>
							Producto
						</Text>
					</div>
					{open.product ? (
						<CaretUp color='var(--h-primary)' />
					) : (
						<CaretDown color='var(--h-primary)' />
					)}
				</div>
				{open.product && (
					<ProductCard validatedInvoiceSummary={validatedInvoiceSummary} />
				)}
			</div>
		</div>
	);
};

export default InvoiceStep3;
