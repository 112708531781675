import { CaretLeft } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import {
	setCurrentInvoicingPage,
	setInvoicingAvaibleSteps,
	setInvoicingCurrentStep,
	setShowModalStamps,
} from '../../../redux/slices/invoicing';
import { useDispatch } from 'react-redux';

const InvoicingBreadcrumb = () => {
	const dispatch = useDispatch();

	const handleGoBack = () => {
		dispatch(setCurrentInvoicingPage('dashboard'));
		dispatch(setInvoicingAvaibleSteps(1));
		dispatch(setInvoicingCurrentStep(1));
		dispatch(setShowModalStamps(false));
	};

	return (
		<div className='flex items-center gap-1 py-2 md:py-0'>
			<button onClick={handleGoBack}>
				<Text
					color='light'
					weight='light'
					sx='items-center'
				>
					Facturación
					<CaretLeft />
				</Text>
			</button>
			<Text
				color='medium'
				weight='medium'
			>
				Nueva factura
			</Text>
		</div>
	);
};

export default InvoicingBreadcrumb;
