import React from 'react';
import Modal from '../../molecules/Modal/Modal';
import Text from '../../atoms/Text';
import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';

interface Props {
	title: string;
	show: boolean;
	setShow: (show: boolean) => void;
	onClickCta?: () => void;
}

const NegativeOpinionModal: React.FC<Props> = ({
	title,
	show,
	setShow,
	onClickCta,
}) => {
	return (
		<Modal
			open={show}
			sx='!p-0 min-w-[80%] lg:min-w-fit'
			onClose={() => setShow(false)}
		>
			<Text
				size='xl'
				weight='bold'
				sx='px-6 pt-6'
			>
				{title}
			</Text>
			<Divider sx='mt-6' />
			<Text
				size='l'
				color='medium'
				sx='pt-6 pb-4 px-6 md:w-2/3'
			>
				El SAT identificó que tienes declaraciones pendientes o adeudos que
				afectan tu situación fiscal
			</Text>
			<Divider sx='!my-6' />
			<div className='flex flex-row justify-end px-6 pb-6'>
				<Button
					label='Resolver situación fiscal'
					sx='!w-fit'
					onClick={onClickCta}
				/>
			</div>
		</Modal>
	);
};

export default React.memo(NegativeOpinionModal);
