import React, { useMemo } from 'react';

import { ListProducts } from '../../../../@types/Products.type';

import Annual from '../../ProductsLogic/Annual';
import HeruPlus from '../../ProductsLogic/HeruPlus';
import Regularization from '../../ProductsLogic/Regularization';
import Modal from '../../../../components/molecules/Modal/Modal';
import BasicAccounting from '../../ProductsLogic/BasicAccounting';
import LinkedBasicAccounting from '../../ProductsLogic/LinkedBasicAccounting';
import { PeriodsSelectedType } from '../../../../@types/Calendar.type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { REGIMES_CODE } from '../../../../shared/constants/regimes';
import { CATEGORY_SINGLE } from '../../data/ecommerceData';
interface Props {
	products: ListProducts[];
	id: string;
	isVisibleModal: boolean;
	setIsVisibleModal: (isVisibleModal: boolean) => void;
	handleAddToCart: (id: number, valuePeriods?: PeriodsSelectedType[]) => void;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}

const DesktopSelection: React.FC<Props> = ({
	products,
	id,
	isVisibleModal,
	setIsVisibleModal,
	handleAddToCart,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const sysAsUniqueRegime = useMemo(() => {
		return (
			taxPayer?.status?.tax_regimes?.length === 1 &&
			taxPayer?.status?.tax_regimes[0]?.code === REGIMES_CODE.SUELDOS_Y_SALARIOS
		);
	}, [taxPayer]);
	return (
		<div>
			<Modal
				showCloseButton={false}
				open={isVisibleModal}
				type='fullscreen'
				sx='z-40 p-[0px] w-[648px] h-[680px] md:h-[750px] px-0 py-0'
				onClose={() => setIsVisibleModal(false)}
			>
				{id === CATEGORY_SINGLE.HERU_PLUS ? (
					<HeruPlus
						products={products}
						isMobile={false}
						handleAddToCart={handleAddToCart}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				) : id === CATEGORY_SINGLE.BASIC_ACCOUNTING ? (
					<>
						{taxPayer && !sysAsUniqueRegime ? (
							<LinkedBasicAccounting
								products={products}
								isMobile={false}
								taxPayer={taxPayer}
								handleAddProduct={handleAddProduct}
								isLoadingAddProduct={isLoadingAddProduct}
							/>
						) : (
							<BasicAccounting
								products={products}
								isMobile={false}
								handleAddProduct={handleAddProduct}
								isLoadingAddProduct={isLoadingAddProduct}
							/>
						)}
					</>
				) : id === CATEGORY_SINGLE.REGULARIZATION ? (
					<Regularization
						productName='Declaraciones Atrasadas'
						description='Ponte al día declarando tus periodos pendientes, evita multas, sanciones o recargos del SAT.'
						products={products}
						isMobile={false}
						handleAddToCart={handleAddToCart}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				) : id === CATEGORY_SINGLE.ANNUAL ? (
					<Annual
						products={products}
						isMobile={false}
						handleAddProduct={handleAddProduct}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				) : null}
			</Modal>
		</div>
	);
};

export default React.memo(DesktopSelection);
