export type TPeriod = 'monthly' | 'yearly';

export enum TPeriodicity {
	MONTHLY = 'monthly',
	YEARLY = 'yearly',
}

export interface FiscalDeclaration {
	user_id: number;
	regime_covered: boolean;
	tax_regime: string;
	periods: Period[];
}

export interface Period {
	year: number;
	month: number;
	synced: boolean;
	computed: boolean;
	approved: boolean;
	presented: boolean;
	payed: boolean;
	acquired: boolean;
	owner: Owner;
	updated_at: null | string;
	obligation_existed: boolean;
}

export enum Owner {
	Heru = 'heru',
	User = 'user',
}

export type PeriodsSelectedType = {
	bimester?: number;
	quarter?: number;
	month?: number;
	year: number;
};

export type DeclarationsByMonth = {
	year: number;
	month: number;
	declarations: DeclarationsByMonthList[];
};

export type DeclarationsByMonthList = {
	[key: string]: string | number | undefined;
	dueDate?: string;
	status: string;
	fiscalResult?: number;
	regimen?: string;
	declarationDate?: string;
	declarationId?: number;
};

export enum TaxboardDeclarationStatus {
	PENDING_PAY = 'PENDIENTE DE PAGO SAT',
	PRESENTED = 'PRESENTADA',
	PENDING = 'PENDIENTE',
	IN_PROCESS = 'EN PROCESO',
	DOWNLOADING = 'DESCARGANDO INFO',
}

export enum TaxboardDeclarationStatusLabel {
	PRESENTADA = 'Presentada',
	'EN PROCESO' = 'En proceso',
	PENDIENTE = 'Pendiente',
	PENDING_PAY = 'Pago pendiente al SAT',
	DOWNLOADING = 'Descargando info',
}

export type PeriodMonth = {
	month: number;
	status: string;
	year: number;
	declarationsCount: number;
};

export interface SelectedPeriod {
	month: {
		general: any;
		detail: DeclarationsByMonth | null;
		detailByRegime: MonthlyDeclarationDetail | null;
		detailByRegimeComplete: any;
	};
	year: {
		currentYear: any;
		selectedYear: any;
		selectedDeclaration: any;
		selectedDeclarationComplete: any;
	};
}

export interface MonthlyDeclarationDetail {
	id?: number;
	year?: number;
	month?: number;
	amountToPay?: number;
	incomes?: Incomes;
	expenses?: number;
	retentions?: Retentions;
	deductibles?: number;
	declarationType?: string;
	paymentType?: string;
	presentedBy?: string;
	declarationPresentedDate?: string;
	regime?: string;
	pdfUrl?: string;
}

interface Incomes {
	total: number;
	concepts: Concept[];
}

interface Concept {
	regime?: string;
	concept?: string;
	amount: number;
}

interface Retentions {
	total: number;
	vat: number;
	isr: number;
}

export interface DeclarationYear {
	presentationDate?: string;
	paymentDate?: string;
	fiscalResult?: number;
	days_late?: number;
	declarationType?: string;
	status: string;
	declarationId?: null | number;
	year: number;
	pdfUrl?: string;
}

export type OverviewDeclarationsYear = {
	declarations: PeriodMonth[];
	deductibles: number;
	expenses: number;
	incomes: {
		concepts: {
			ammount: number;
			concept: string;
		};
	};
	retentions: {
		isr: number;
		total: number;
		vat: number;
	};
};
