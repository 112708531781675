import React, { useEffect, useState } from 'react';
import Text from '../../../components/atoms/Text';
import PlanCheckInput from '../molecules/PlanCheckInput';
import { REGIMES_CODE } from '../../../@types/Regime.type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
	Briefcase,
	CaretLeft,
	CaretRight,
	CurrencyCircleDollar,
	Info,
} from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';
import { getQueryParam } from '../../../shared/utils/general.util';
import { PlanConfig } from '..';
import { monthsArray } from '../../../shared/constants/dates';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import {
	OverviewDeclarationsYear,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';
import PrimaryButton from '../../../components/atoms/Button';
import { setPlanConfig } from '../../../redux/slices/plans.slice';

type Props = {
	planConfig: PlanConfig;
};

const regimes = [
	{
		label: 'Actividad Empresarial',
		description: 'Soy independiente o tengo un pequeño negocio',
		code: REGIMES_CODE.ACTIVIDAD_EMPRESARIAL,
	},
	{
		label: 'RESICO',
		description: 'Ingresos menores a 3.5 mdp/año y sin salario',
		code: REGIMES_CODE.RESICO,
	},
	{
		label: 'Plataformas Tecnológicas',
		description: 'Ganas dinero por aplicaciones o internet',
		code: REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
	},
	{
		label: 'Arrendamiento',
		description: 'Tus ingresos son de rentas de propiedades',
		code: REGIMES_CODE.ARRENDAMIENTO,
	},
	{
		label: 'RIF',
		description: 'Eres emprendedor',
		code: REGIMES_CODE.RIF,
	},
	{
		label: 'Sueldos y Salarios',
		description: 'Trabajas para una empresa y recibes un sueldo',
		code: REGIMES_CODE.SUELDOS_Y_SALARIOS,
	},
];

const quarterlyPeriods = [
	{
		label: '1º TRIMESTRE',
		value: 'first-quarter',
		period: 'Enero - Marzo',
	},
	{
		label: '2º TRIMESTRE',
		value: 'second-quarter',
		period: 'Abril - Junio',
	},
	{
		label: '3º TRIMESTRE',
		value: 'third-quarter',
		period: 'Julio - Septiembre',
	},
	{
		label: '4º TRIMESTRE',
		value: 'fourth-quarter',
		period: 'Octubre - Diciembre',
	},
];

const bimesterPeriods = [
	{
		label: '1º BIMESTRE',
		value: 'first-bimester',
		period: 'Enero - Febrero',
	},
	{
		label: '2º BIMESTRE',
		value: 'second-bimester',
		period: 'Marzo - Abril',
	},
	{
		label: '3º BIMESTRE',
		value: 'third-bimester',
		period: 'Mayo - Junio',
	},
	{
		label: '4º BIMESTRE',
		value: 'fourth-bimester',
		period: 'Julio - Agosto',
	},
	{
		label: '5º BIMESTRE',
		value: 'fifth-bimester',
		period: 'Septiembre - Octubre',
	},
	{
		label: '6º BIMESTRE',
		value: 'sixth-bimester',
		period: 'Noviembre - Diciembre',
	},
];

const PlanRegimeLeaseSelector: React.FC<
	Props & { confirmed: boolean; type?: string }
> = ({ planConfig, confirmed, type }) => {
	const dispatch = useDispatch();
	const leaseConfig = [
		{
			label: 'Más de $27,000 al mes',
			description: 'Debes realizar declaraciones mensuales',
			value: 'monthly',
		},
		{
			label: 'Menos de $27,000 al mes',
			description: 'Debes realizar declaraciones trimestrales',
			value: 'quarterly',
		},
	];

	const leaseRegularizationsConfig = [
		{
			label: 'Obligatorio si ganas más de $27,000 al mes',
			description: 'Debes realizar declaraciones mensuales',
			value: 'monthly',
		},
		{
			label: 'Ideal si ganas menos de $27,000 al mes',
			description: 'Debes realizar declaraciones trimestrales',
			value: 'trimestral',
		},
	];

	const options =
		type === 'regularizations' ? leaseRegularizationsConfig : leaseConfig;

	const isMobile = useMediaQuery('(max-width: 768px)');

	if (confirmed)
		return (
			<div className='flex items-center gap-3'>
				<CurrencyCircleDollar
					size={20}
					weight='duotone'
				/>
				<div>
					<Text
						size={isMobile ? 'mini-1' : 'caption'}
						weight='medium'
					>
						{
							leaseConfig.find(
								(r) => r.value === planConfig.regimeConfig?.lease,
							)?.label
						}
					</Text>
					<Text
						size={isMobile ? 'mini-1' : 'caption'}
						weight='light'
					>
						{
							leaseConfig.find(
								(r) => r.value === planConfig.regimeConfig?.lease,
							)?.description
						}
					</Text>
				</div>
			</div>
		);

	return (
		<>
			<hr />
			<Text
				size='body-3'
				weight='medium'
				sx='my-1'
				responsive
			>
				¿Cuáles son tus ingresos mensuales aproximados?
			</Text>
			<div className='flex flex-col gap-2 md:gap-4'>
				{options.map((regime, index) => {
					return (
						<PlanCheckInput
							key={`regime-${index}`}
							checked={planConfig.regimeConfig?.lease === regime.value}
							label={regime.label}
							description={regime.description}
							onClick={() => {
								dispatch(
									setPlanConfig({
										...planConfig,
										regimeConfig: {
											lease: regime.value,
										},
									}),
								);
							}}
						/>
					);
				})}
			</div>
		</>
	);
};

const PlanRegularizationRegimeSelector: React.FC<
	Props & { confirmed: boolean; showSySRegime?: boolean }
> = ({ planConfig, confirmed, showSySRegime = false }) => {
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const dispatch = useDispatch();
	const handleAddRegime = (regimeCode: string) => {
		dispatch(
			setPlanConfig({
				...planConfig,
				regimes: [regimeCode],
			}),
		);
	};

	const isMobile = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		const userRegimesWithoutSyS =
			userRegimes &&
			userRegimes.filter(
				(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
			);

		if (userRegimesWithoutSyS?.length === 1) {
			dispatch(
				setPlanConfig({
					...planConfig,
					regimes: [userRegimesWithoutSyS?.[0]?.code],
					step: 2,
				}),
			);
		} else {
			const regimes = getQueryParam('regimes');

			if (regimes) {
				const regimesToArray = regimes.split(',');

				dispatch(
					setPlanConfig({
						...planConfig,
						regimes: regimesToArray?.[0],
					}),
				);
			}
		}
	}, [userRegimes]);

	if (confirmed) {
		return (
			<div className='flex flex-col gap-3'>
				{planConfig?.regimes?.map((regime, index) => (
					<div
						className='flex items-center gap-3'
						key={`index-${index}`}
					>
						<Briefcase
							size={20}
							weight='duotone'
						/>
						<div>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='medium'
							>
								Régimen de {regimes.find((r) => r.code === regime)?.label}
							</Text>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='light'
							>
								{regimes.find((r) => r.code === regime)?.description}
							</Text>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div>
			<Text
				size='body-3'
				weight='medium'
				sx='mb-4 mt-1'
				responsive
			>
				Selecciona tu fuente de ingreso (régimen)
			</Text>
			<div className='grid grid-cols-2 gap-2 md:gap-4'>
				{regimes
					.filter((reg) =>
						!showSySRegime
							? reg.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS
							: true,
					)
					.map((regime, index) => {
						const userRegimesWithoutSyS =
							userRegimes &&
							userRegimes.filter(
								(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
							);

						const isPreselected = userRegimesWithoutSyS?.some(
							(user_regime) => user_regime.code === regime.code,
						);

						return (
							<PlanCheckInput
								bottomTagText={
									isPreselected ? 'Detectamos que tienes este régimen' : ''
								}
								key={`regime-${index}`}
								checked={planConfig.regimes?.includes(regime.code) || false}
								label={regime.label}
								description={regime.description}
								onClick={() => handleAddRegime(regime.code)}
							/>
						);
					})}
			</div>
		</div>
	);
};

const PlanRegimeSelector: React.FC<
	Props & { confirmed: boolean; label?: string; showSySRegime?: boolean }
> = ({ planConfig, confirmed, label, showSySRegime = false }) => {
	const dispatch = useDispatch();
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);
	const paramRegimes = getQueryParam('regimes');

	const handleAddRegime = (regimeCode: string) => {
		const newRegimes = planConfig.regimes?.includes(regimeCode)
			? planConfig.regimes?.filter((regime) => regime !== regimeCode)
			: [...(planConfig.regimes || []), regimeCode];

		dispatch(
			setPlanConfig({
				...planConfig,
				regimes: newRegimes,
			}),
		);
	};

	const userRegimesWithoutSyS =
		(userRegimes &&
			userRegimes.filter(
				(regime) =>
					regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS && regime.code,
			)) ||
		[];

	useEffect(() => {
		if (userRegimesWithoutSyS?.length > 0) {
			const isRESICOandOtherRegime =
				userRegimesWithoutSyS.length > 1 &&
				userRegimesWithoutSyS.some(
					(regime) => regime.code === REGIMES_CODE.RESICO,
				);

			if (isRESICOandOtherRegime) return;

			dispatch(
				setPlanConfig({
					...planConfig,
					regimes: userRegimesWithoutSyS.map((regime) => regime.code),
					step: 2,
				}),
			);
		} else {
			if (paramRegimes) {
				const regimesToArray = paramRegimes.split(',');

				dispatch(
					setPlanConfig({
						...planConfig,
						regimes: regimesToArray,
					}),
				);
			}
		}
	}, [userRegimes, paramRegimes]);

	const isMobile = useMediaQuery('(max-width: 768px)');

	if (confirmed) {
		return (
			<div className='flex flex-col gap-3'>
				{planConfig?.regimes?.map((regime) => (
					<div className='flex items-center gap-3'>
						<Briefcase
							size={20}
							weight='duotone'
						/>
						<div>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='medium'
							>
								Régimen de {regimes.find((r) => r.code === regime)?.label}
							</Text>
							<Text
								size={isMobile ? 'mini-1' : 'caption'}
								weight='light'
							>
								{regimes.find((r) => r.code === regime)?.description}
							</Text>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div>
			<Text
				size='body-3'
				weight='medium'
				sx='mb-4 mt-1'
				responsive
			>
				{label ?? 'Selecciona una o más de tus fuentes de ingreso (regímenes)'}
			</Text>
			<div className='grid grid-cols-2 gap-2 md:gap-4'>
				{regimes
					.filter((reg) =>
						!showSySRegime
							? reg.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS
							: true,
					)
					.map((regime, index) => {
						const isSelectedRESICO =
							planConfig.regimes?.length === 1 &&
							planConfig.regimes?.includes(REGIMES_CODE.RESICO);

						const isNotRESICO = regime.code !== REGIMES_CODE.RESICO;

						const isSelected = planConfig.regimes?.includes(regime.code);

						const selectedIsNotRESICO =
							planConfig?.regimes &&
							planConfig?.regimes?.some(
								(regime) => regime !== REGIMES_CODE.RESICO,
							);

						const disableRESICO =
							regime.code === REGIMES_CODE.RESICO && selectedIsNotRESICO;

						const selectedMaxRegimes =
							planConfig.regimes?.length === 3 && !isSelected;

						const isDisabled =
							(isSelectedRESICO ? isNotRESICO : false) ||
							selectedMaxRegimes ||
							disableRESICO;

						const isPreselected = userRegimes
							?.filter(
								(regime) =>
									regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS &&
									regime.code,
							)
							.some((user_regime) => user_regime.code === regime.code);

						return (
							<PlanCheckInput
								bottomTagText={
									isPreselected ? 'Detectamos que tienes este régimen' : ''
								}
								disabled={isDisabled}
								key={`regime-${index}`}
								checked={planConfig.regimes?.includes(regime.code) || false}
								label={regime.label}
								description={regime.description}
								onClick={() => handleAddRegime(regime.code)}
							/>
						);
					})}
			</div>
		</div>
	);
};

const PlanPaymentFrecuencySelector: React.FC<Props> = ({ planConfig }) => {
	const dispatch = useDispatch();
	const periodicities: {
		label: string;
		description: string;
		value: number;
	}[] = [
		{
			label: 'Mensual',
			description: 'Precio full',
			value: 30,
		},
		{
			label: 'Bimestral',
			description: 'Precio full',
			value: 60,
		},
		{
			label: 'Trimestral',
			description: 'Ahorraras un 10%',
			value: 90,
		},
		{
			label: 'Semestral',
			description: 'Ahorraras un 20%',
			value: 180,
		},
		{
			label: 'Anual',
			description: 'Ahorraras un 25%',
			value: 365,
		},
	]?.filter((periodicity) => {
		const onlyOneRegime = planConfig?.regimes?.length === 1;
		const isRIF =
			onlyOneRegime && planConfig?.regimes?.includes(REGIMES_CODE.RIF);
		const isLease =
			onlyOneRegime && planConfig?.regimes?.[0] === REGIMES_CODE.ARRENDAMIENTO;

		if (isRIF) {
			return periodicity.value !== 30 && periodicity.value !== 90;
		}

		if (isLease) {
			const isQuarterlyLease = planConfig?.regimeConfig?.lease === 'quarterly';
			return isQuarterlyLease
				? periodicity.value !== 30 && periodicity.value !== 60
				: periodicity.value !== 60;
		}

		return periodicity.value !== 60;
	});

	const handlePaymentFrecuency = (value: number) => {
		dispatch(
			setPlanConfig({
				...planConfig,
				paymentFrecuency: value,
			}),
		);
	};

	useEffect(() => {
		if (
			!periodicities.some((periodicity) => {
				return planConfig.paymentFrecuency === periodicity.value;
			})
		) {
			dispatch(
				setPlanConfig({
					...planConfig,
					paymentFrecuency: undefined,
				}),
			);
		}
	}, []);

	return (
		<div>
			<Text
				size='body-3'
				weight='medium'
				sx='mb-4 mt-1'
				responsive
			>
				Selecciona la frecuencia de pago
			</Text>
			<div className='grid grid-cols-2 gap-2 md:gap-4'>
				{periodicities.map((periodicity, index) => (
					<PlanCheckInput
						key={`periodicity-${index}`}
						checked={planConfig.paymentFrecuency === periodicity.value}
						label={periodicity.label}
						description={periodicity.description}
						onClick={() => handlePaymentFrecuency(periodicity.value)}
					/>
				))}
			</div>
		</div>
	);
};

const PlanRegularizationPeriodSelector: React.FC<
	Props & {
		declarationStatus: {
			[year: number]: OverviewDeclarationsYear;
		} | null;
	}
> = ({ planConfig, declarationStatus }) => {
	const dispatch = useDispatch();
	const [currentYear, setCurrentYear] = useState<number>(DATES.currentYear);
	const isMobile = useMediaQuery('(max-width: 768px)');

	const isSelectedPlatec = planConfig?.regimes?.includes(
		REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
	);
	const currentDeclarationStatus = (month: number) =>
		isSelectedPlatec
			? declarationStatus?.[currentYear]?.declarations?.find(
					(declaration) => declaration.month === month,
				)?.status || 'empty'
			: 'empty';

	const handleAddPeriod = (month: number) => {
		const periodExists = planConfig?.regularizationPeriods?.some(
			(period) => period.month === month && period.year === currentYear,
		);

		dispatch(
			setPlanConfig({
				...planConfig,
				regularizationPeriods: periodExists
					? planConfig?.regularizationPeriods?.filter(
							(period) =>
								!(period.month === month && period.year === currentYear),
						)
					: [
							...(planConfig.regularizationPeriods || []),
							{
								month,
								year: currentYear,
							},
						],
			}),
		);
	};

	const handleAddPeriodQuarterly = (type: string, period: string) => {
		const periodExists = planConfig?.regularizationPeriods?.some(
			(period) => period.type === type && period.year === currentYear,
		);

		dispatch(
			setPlanConfig({
				...planConfig,
				regularizationPeriods: periodExists
					? planConfig?.regularizationPeriods?.filter(
							(period) =>
								!(period.type === type && period.year === currentYear),
						)
					: [
							...(planConfig.regularizationPeriods || []),
							{
								period,
								type,
								year: currentYear,
							},
						],
			}),
		);
	};

	const handleAddAllPendingMonths = () => {
		const years = getLastYears(5, DATES.currentYear);
		let allPendingPeriods = [...(planConfig.regularizationPeriods || [])];

		for (const year of years) {
			const yearPendingMonths = declarationStatus?.[year]?.declarations?.filter(
				(declaration) =>
					declaration.status === TaxboardDeclarationStatus.PENDING,
			);

			if (yearPendingMonths) {
				yearPendingMonths.forEach((declaration) => {
					const periodExists = allPendingPeriods.some(
						(period) =>
							period.month === declaration.month && period.year === year,
					);

					if (!periodExists) {
						allPendingPeriods.push({
							month: declaration.month,
							year,
						});
					}
				});
			}
		}

		dispatch(
			setPlanConfig({
				...planConfig,
				regularizationPeriods: allPendingPeriods,
			}),
		);
	};

	const isPlatec = planConfig?.regimes?.includes(
		REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
	);

	const isLeaseQuarterly =
		planConfig?.regimeConfig?.lease === 'quarterly' &&
		planConfig?.regimes?.includes(REGIMES_CODE.ARRENDAMIENTO);

	const isRIF = planConfig?.regimes?.includes(REGIMES_CODE.RIF);

	const getButtonPendingMonths = isPlatec ? (
		<PrimaryButton
			color='primary'
			variant='tertiary'
			label='Agregar todos los meses atrasados'
			onClick={handleAddAllPendingMonths}
			sx='max-w-32 !px-2 md:max-w-[150px] bg-h-primary-90 rounded-lg py-1 !text-xs !leading-4 !border-h-primary-40'
		/>
	) : null;

	const handleNextYear = () => {
		setCurrentYear((prev) => prev + 1);
	};

	const handlePreviousYear = () => {
		setCurrentYear((prev) => prev - 1);
	};

	useEffect(() => {
		dispatch(
			setPlanConfig({
				...planConfig,
				regularizationPeriods: undefined,
			}),
		);
	}, [planConfig?.regimes]);

	return (
		<div className=''>
			<Text
				size='body-3'
				weight='medium'
				sx='mb-4 mt-1'
				responsive
			>
				Selecciona los periodos a declarar
			</Text>
			<div
				className={`max-w-sm mx-auto flex items-center mb-2 ${isMobile && isPlatec ? 'justify-between' : 'justify-center'}`}
			>
				<div
					className={`relative text-h-primary flex justify-center items-center -ml-2`}
				>
					<button
						disabled={currentYear === DATES.currentYear - 4}
						onClick={handlePreviousYear}
						className='p-3 disabled:opacity-50'
					>
						<CaretLeft
							size={20}
							weight='bold'
						/>
					</button>
					<Text
						size='body-2'
						sx='w-12 justify-center'
					>
						{currentYear}
					</Text>
					<button
						onClick={handleNextYear}
						className='p-3 disabled:opacity-50'
						disabled={currentYear === DATES.currentYear}
					>
						<CaretRight
							size={20}
							weight='bold'
						/>
					</button>
				</div>
				{isMobile && getButtonPendingMonths}
			</div>

			{!isLeaseQuarterly && !isRIF && (
				<div className='grid grid-cols-4 rounded-xl overflow-hidden border border-h-neutral-90 max-w-sm mx-auto'>
					{monthsArray.map((month, index) => {
						const currentMonth = Number(month.value);

						const isSelected = planConfig?.regularizationPeriods?.some(
							(period) =>
								period.month === currentMonth && period.year === currentYear,
						);

						const getStylesByStatus: Record<string, string> = {
							[TaxboardDeclarationStatus.PENDING]: 'bg-h-red-50',
							[TaxboardDeclarationStatus.PRESENTED]: 'bg-h-green-50',
							[TaxboardDeclarationStatus.PENDING_PAY]: 'bg-h-green-50',
							[TaxboardDeclarationStatus.IN_PROCESS]: 'bg-h-green-50',
							empty: '',
						};

						const isDisabled =
							![TaxboardDeclarationStatus.PENDING, 'empty'].includes(
								currentDeclarationStatus(currentMonth),
							) ||
							(currentMonth >= DATES.currentMonth &&
								currentYear == DATES.currentYear);

						return (
							<button
								disabled={isDisabled}
								onClick={() => handleAddPeriod(currentMonth)}
								key={`bimester-${index}`}
								type='button'
								className={`relative h-16 flex justify-center items-center border transition
								${isSelected ? 'bg-h-primary-90/80 border-h-primary-80' : 'border-h-neutral-90'}
								disabled:cursor-not-allowed disabled:opacity-50
								`}
							>
								<div
									className={` w-2 h-2 absolute left-1 top-1 rounded-full
								${getStylesByStatus[currentDeclarationStatus(currentMonth)]}
								`}
								/>
								<Text
									size='caption'
									color={isSelected ? 'primary' : 'medium'}
								>
									{isMobile ? month.label.slice(0, 3) : month.label}
								</Text>
							</button>
						);
					})}
				</div>
			)}
			{isLeaseQuarterly && (
				<div className='grid grid-cols-2 rounded-xl overflow-hidden border border-h-neutral-90 max-w-sm mx-auto'>
					{quarterlyPeriods.map((quarter, index) => {
						const isSelected = planConfig?.regularizationPeriods?.some(
							(period) =>
								period.type === quarter.value && period.year === currentYear,
						);
						const isDisabled = () => {
							const monthsByQuarter: Record<string, number[]> = {
								'first-quarter': [1, 2, 3],
								'second-quarter': [4, 5, 6],
								'third-quarter': [7, 8, 9],
								'fourth-quarter': [10, 11, 12],
							};
							const currentMonth = DATES.currentMonth;
							const months = monthsByQuarter[quarter.value];

							return (
								months.some((month) => month >= currentMonth) &&
								currentYear === DATES.currentYear
							);
						};
						return (
							<button
								disabled={isDisabled()}
								onClick={() =>
									handleAddPeriodQuarterly(quarter.value, quarter.period)
								}
								key={`quarter-${index}`}
								type='button'
								className={`relative h-20 flex flex-col justify-center items-center border transition
						${isSelected ? 'bg-h-primary-90/80 border-h-primary-80' : 'border-h-neutral-90'}
						disabled:cursor-not-allowed disabled:opacity-50`}
							>
								<div
									className={` w-2 h-2 absolute left-1 top-1 rounded-full`}
								/>
								<Text
									size='caption'
									color={isSelected ? 'dark' : 'medium'}
									weight='medium'
								>
									{quarter.label}
								</Text>
								<Text
									size='mini-1'
									color={'medium'}
								>
									{quarter.period}
								</Text>
							</button>
						);
					})}
				</div>
			)}
			{isRIF && (
				<div className='grid grid-cols-2 rounded-xl overflow-hidden border border-h-neutral-90 max-w-sm mx-auto'>
					{bimesterPeriods.map((bimester, index) => {
						const isSelected = planConfig?.regularizationPeriods?.some(
							(period) =>
								period.type === bimester.value && period.year === currentYear,
						);
						const isDisabled = () => {
							const monthsByBimester: Record<string, number[]> = {
								'first-bimester': [1, 2],
								'second-bimester': [3, 4],
								'third-bimester': [5, 6],
								'fourth-bimester': [7, 8],
								'fifth-bimester': [9, 10],
								'sixth-bimester': [11, 12],
							};
							const currentMonth = DATES.currentMonth;
							const months = monthsByBimester[bimester.value];

							return (
								months.some((month) => month >= currentMonth) &&
								currentYear === DATES.currentYear
							);
						};
						return (
							<button
								disabled={isDisabled()}
								onClick={() =>
									handleAddPeriodQuarterly(bimester.value, bimester.period)
								}
								key={`month-${index}`}
								type='button'
								className={`relative h-20 flex flex-col justify-center items-center border transition
						${isSelected ? 'bg-h-primary-90/80 border-h-primary-80' : 'border-h-neutral-90'}
						disabled:cursor-not-allowed disabled:opacity-50`}
							>
								<div
									className={` w-2 h-2 absolute left-1 top-1 rounded-full`}
								/>
								<Text
									size='caption'
									color={isSelected ? 'dark' : 'medium'}
									weight='medium'
								>
									{bimester.label}
								</Text>
								<Text
									size='mini-1'
									color={'medium'}
								>
									{bimester.period}
								</Text>
							</button>
						);
					})}
				</div>
			)}
			{isPlatec && (
				<div className='mx-auto mt-4 flex gap-4 items-center justify-center'>
					<div>
						<div className='flex items-center gap-2 mb-1'>
							<div className={`w-2 h-2 rounded-full bg-h-red-50`} />
							<Text size='mini-1'>Mes con declaración atrasada</Text>
						</div>
						<div className='flex items-center gap-2'>
							<div className={`w-2 h-2 rounded-full bg-h-green-50`} />
							<Text size='mini-1'>Mes con declaración presentada</Text>
						</div>
					</div>
					{!isMobile && getButtonPendingMonths}
				</div>
			)}

			<Text
				size='mini-1'
				color='medium'
				sx='mt-6 bg-h-neutral-90 px-3 py-2 rounded-lg flex items-center gap-4'
			>
				<Info size={64} />
				La presentación de declaraciones atrasadas debe ser en orden de enero a
				diciembre, ya que los ingresos se acumulan mes a mes. Asegúrese de
				seleccionar correctamente los meses que desea contratar.
			</Text>
		</div>
	);
};

export {
	PlanPaymentFrecuencySelector,
	PlanRegimeSelector,
	PlanRegularizationRegimeSelector,
	PlanRegimeLeaseSelector,
	PlanRegularizationPeriodSelector,
};
