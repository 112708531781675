import Text from '../../../../../components/atoms/Text';
import { CaretDown, Download, Trash } from '@phosphor-icons/react';

type Props = {
	name: string;
	onClick: () => void;
	onClickDelete?: () => void;
	viewDelete?: boolean;
};

const FileView: React.FC<Props> = ({ name, onClick, viewDelete, onClickDelete }) => {
	return (
		<div className='p-2 flex items-center justify-between border border-h-neutral-890 rounded-lg'>
			<Text weight='regular'>{name}</Text>
			<div className='flex'>
				<div
					className='relative group/file'
					onClick={onClick}
				>
					<Download
						weight='light'
						color={'var(--h-primary-20)'}
						className='text-bold group-hover/file:hidden'
						width={24}
						height={24}
					/>
					<Download
						weight='fill'
						color={'var(--h-primary-20)'}
						className='text-bold hidden group-hover/file:block'
						width={24}
						height={24}
					/>
					<div className='transition translate-y-4 group-hover/file:translate-y-0  opacity-0 group-hover/file:opacity-100 absolute bottom-5 left-[calc(100%-56px)] flex flex-col items-center'>
						<Text
							weight='regular'
							size='caption'
							color='white'
							sx='bg-h-primary-20 px-2 py-1 rounded-lg'
						>
							Descargar
						</Text>
						<CaretDown
							weight='fill'
							color='var(--h-primary-20)'
							width={24}
							height={24}
							className='mt-[-10px]'
						/>
					</div>
				</div>
				{viewDelete && (
					<div
						className='relative group/file ml-4'
						onClick={onClickDelete}
					>
						<Trash
							weight='light'
							color={'var(--h-primary-20)'}
							className='text-bold group-hover/file:hidden'
							width={24}
							height={24}
						/>
						<Trash
							weight='fill'
							color={'var(--h-primary-20)'}
							className='text-bold hidden group-hover/file:block'
							width={24}
							height={24}
						/>
						<div className='transition translate-y-4 group-hover/file:translate-y-0  opacity-0 group-hover/file:opacity-100 absolute bottom-5 left-[calc(100%-56px)] flex flex-col items-center'>
							<Text
								weight='regular'
								size='caption'
								color='white'
								sx='bg-h-primary-20 px-2 py-1 rounded-lg'
							>
								Eliminar
							</Text>
							<CaretDown
								weight='fill'
								color='var(--h-primary-20)'
								width={24}
								height={24}
								className='mt-[-10px]'
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FileView;
