import { Grid, Typography } from '@mui/material';

const nameMonth = (month: number) => {
  switch (month) {
    case 1:
      return 'Ene';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Abr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Ago';
    case 9:
      return 'Sept';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dic';
    default:
      return 'N/A';
  }
};

const nameMonthFull = (month: number) => {
  switch (month) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return 'N/A';
  }
};

const nameQuarter = (noQuarter: any) => {
  switch (noQuarter) {
    case 1:
      return (
        <Grid>
          <Typography style={styles.title}>1° TRIMESTRE</Typography>
          <Typography style={styles.subTitle}>Enero - Marzo</Typography>
        </Grid>
      );
    case 2:
      return (
        <Grid>
          <Typography style={styles.title}>2° TRIMESTRE</Typography>
          <Typography style={styles.subTitle}>Abril - Junio</Typography>
        </Grid>
      );
    case 3:
      return (
        <Grid>
          <Typography style={styles.title}>3° TRIMESTRE</Typography>
          <Typography style={styles.subTitle}>Julio - Septiembre</Typography>
        </Grid>
      );
    case 4:
      return (
        <Grid>
          <Typography style={styles.title}>4° TRIMESTRE</Typography>
          <Typography style={styles.subTitle}>Octubre - Diciembre</Typography>
        </Grid>
      );
    default:
      return null;
  }
};

const nameBimester = (noBimester: number) => {
  switch (noBimester) {
    case 1:
      return (
        <Grid>
          <Typography style={styles.title}>1° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Enero - Febrero</Typography>
        </Grid>
      );
    case 2:
      return (
        <Grid>
          <Typography style={styles.title}>2° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Marzo - Abril</Typography>
        </Grid>
      );
    case 3:
      return (
        <Grid>
          <Typography style={styles.title}>3° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Mayo - Junio</Typography>
        </Grid>
      );
    case 4:
      return (
        <Grid>
          <Typography style={styles.title}>4° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Julio - Agosto</Typography>
        </Grid>
      );
    case 5:
      return (
        <Grid>
          <Typography style={styles.title}>5° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Septiembre - Octubre</Typography>
        </Grid>
      );
    case 6:
      return (
        <Grid>
          <Typography style={styles.title}>6° BIMESTRE</Typography>
          <Typography style={styles.subTitle}>Noviembre - Diciembre</Typography>
        </Grid>
      );
    default:
      return null;
  }
};

const styles = {
  title: {
    color: '#0A2B34',
    fontSize: '11px',
  },
  subTitle: {
    color: '#9DA1AA',
    fontSize: '10px',
  },
};

export { nameMonth, nameQuarter, nameBimester, nameMonthFull };
