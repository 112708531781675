import { PencilSimple, UploadSimple } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import { useCallback, useEffect, useState } from 'react';
import SatLinkModal from '../../../components/molecules/SATLinkingForm/SatLinkModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import {
	ProfileState,
	setStampStatus,
} from '../../../redux/slices/profile.slice';
import { getUserDigitalStampStatus } from '../../../services/invoicing.service';
import {
	setInvoicingAvaibleSteps,
	setInvoicingCurrentStep,
	setIssuerName,
	setShowModalStamps,
} from '../../../redux/slices/invoicing';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import Modal from '../../../components/molecules/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../../../components/atoms/Input';
import PrimaryButton from '../../../components/atoms/Button';

export const issuerNameResolver = yup
	.object({
		issuerName: yup.string().required('Este campo es requerido'),
	})
	.required();

type Props = {
	allowEditIssuerName?: boolean;
	hideInfo?: boolean;
	sx?: string;
};

const InvoiceStampsInfo: React.FC<Props> = ({
	allowEditIssuerName = false,
	hideInfo = false,
	sx,
}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const [showModal, setShowModal] = useState({
		issuerName: false,
	});

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors: formErrors },
	} = useForm<{ issuerName: string }>({
		mode: 'onChange',
		// @ts-ignore
		resolver: yupResolver(issuerNameResolver),
		defaultValues: {
			issuerName: '',
		},
	});

	const { showModalStamps, currentStep, issuerName } = useSelector(
		(state: RootState) => state.invocing,
	);

	const { stampStatus, user } = useSelector<RootState, ProfileState>(
		(state) => state.profile,
	);
	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const getStampStatus = useCallback(async () => {
		try {
			const data = await getUserDigitalStampStatus(user!.id, tokenSession);
			dispatch(setStampStatus(data));
			dispatch(setInvoicingAvaibleSteps(1));
			dispatch(setInvoicingCurrentStep(1));
		} catch (error) {}
	}, [user?.id]);

	const onSuccessfulUpload = async () => {
		await getStampStatus();
		dispatch(setShowModalStamps(false));
	};

	const handleCloseModal = () => {
		dispatch(setShowModalStamps(false));

		logClevertapEvent(
			currentStep === 1
				? ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA
				: ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT,
			{
				event_type: EVENT_TYPES.INVOICE_GO_BACK_STAMP,
			},
		);
	};

	const handleShowModal = () => {
		dispatch(setShowModalStamps(true));
		logClevertapEvent(
			currentStep === 1
				? ANALYTICS_EVENTS.INVOICE_FISRT_STEP_DATA
				: ANALYTICS_EVENTS.INVOICE_SECOND_STEP_PRODUCT,
			{
				event_type: EVENT_TYPES.INVOICE_LINK_STAMP,
			},
		);
	};

	if ((stampStatus || status?.status) && !hideInfo) {
		const defaultIssuerName =
			stampStatus?.name || `${user?.first_name} ${user?.father_surname}`;
		const invoicingData = [
			{
				label: 'Emisor',
				value:
					allowEditIssuerName && issuerName ? issuerName : defaultIssuerName,
			},
			{
				label: 'RFC',
				value: stampStatus?.code || status?.username,
			},
			{
				label: 'Código postal',
				value: stampStatus?.postal_code,
			},
		];

		const onSubmit = async (data: { issuerName: string }) => {
			if (formErrors.issuerName) return;

			dispatch(setIssuerName(data.issuerName));
			setShowModal({ ...showModal, issuerName: false });
		};

		useEffect(() => {
			if (!allowEditIssuerName) {
				setValue('issuerName', defaultIssuerName);
				dispatch(setIssuerName(defaultIssuerName));
			}
		}, [allowEditIssuerName]);

		return (
			<div className='bg-h-primary-95 p-3 sm:p-3 rounded-lg mt-3 flex flex-col gap-1'>
				<Modal
					open={showModal.issuerName}
					sx='w-[94%] !max-w-md'
					onClose={() => setShowModal({ ...showModal, issuerName: false })}
				>
					<Text
						sx='!text-center !block'
						weight='bold'
						size='body-3'
					>
						Cambiar el nombre del emisor
					</Text>
					<div className='mt-4 flex flex-col gap-4'>
						<div>
							<Input
								showValueUpperCase
								control={control}
								name='issuerName'
								type='text'
								label='Nombre del emisor'
								errorMessage={formErrors?.issuerName?.message}
							/>
						</div>
						<PrimaryButton
							label='Guardar'
							onClick={() => handleSubmit(onSubmit)()}
						/>
					</div>
				</Modal>
				{invoicingData.map(({ label, value }, index) => {
					if (!value) return null;
					return (
						<div key={`data-index-${index}`}>
							<Text
								weight='medium'
								color='medium'
							>
								{label}
							</Text>

							{
								<div className='flex items-center gap-2'>
									<Text weight='light'>{value.toUpperCase()}</Text>
									{allowEditIssuerName && label === 'Emisor' && (
										<button type='button'>
											<PencilSimple
												height={32}
												width={32}
												color='var(--h-primary)'
												onClick={() =>
													setShowModal({ ...showModal, issuerName: true })
												}
												className='hover:bg-h-primary-90 transition p-1.5 rounded-lg cursor-pointer'
											/>
										</button>
									)}
								</div>
							}
						</div>
					);
				})}
				{!stampStatus && (
					<button
						onClick={handleShowModal}
						className='w-fit flex items-center gap-2 ml-auto text-h-primary transition hover:bg-h-primary-90/50 py-1 px-2 rounded-md mt-0 sm:-mt-5'
					>
						Cargar sellos faltantes <UploadSimple weight='bold' />
					</button>
				)}
			</div>
		);
	}

	if (!stampStatus) {
		return (
			<div className={sx}>
				<SatLinkModal
					step={1}
					show={showModalStamps}
					onRecall={onSuccessfulUpload}
					onHide={handleCloseModal}
				/>
				<div
					onClick={handleShowModal}
					className='bg-h-primary-95 border-dashed border-h-primary border rounded-md py-1 px-2 flex gap-1 items-end justify-between text-h-primary cursor-pointer'
				>
					<div>
						<Text
							color='primary'
							weight='medium'
						>
							Cargar mis sellos
						</Text>
						<Text
							color='primary'
							weight='light'
						>
							Al cargar tus sellos podrás emitir tu factura.
						</Text>
					</div>
					<UploadSimple
						width={20}
						height={20}
					/>
				</div>
			</div>
		);
	}
};

export default InvoiceStampsInfo;
