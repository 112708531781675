import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import clevertap from 'clevertap-web-sdk';
import type { RootState } from '../redux/store';
import { addToken, login } from '../redux/slices/auth.slice';
import MainLayout from '../layouts/MainLayout';
import { setProfile } from '../redux/slices/profile.slice';
import { getUserProfile } from '../services/profile.service';
import { HERU_UTM } from '../shared/constants/utms';
import LOCAL_STORAGE_KEY from '../shared/constants/localStorageKey';
import { Profile } from '../@types/Profile.type';
import {
	getBrowser,
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../shared/utils/general.util';
import growthbookFile from '../shared/constants/growthbook';
import { setOverwriteUtm, updateUTMs } from '../redux/slices/cart.slice';

type Props = {
	redirectPath: string;
};

declare global {
	interface Window {
		clarity: any;
	}
}

const ProtectedRoutes: React.FC<PropsWithChildren<Props>> = ({
	redirectPath,
}) => {
	const [searchParams] = useSearchParams();

	const isUser = useSelector((state: RootState) => state.auth.isLogged);
	const [tokenExist, setTokenExist] = useState(true);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { utmInfo } = useSelector((state: RootState) => state.cart);

	const userToken = isLocalStorageEnabled()
		? localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)
		: tokenSession;

	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getStorageProfile = async () => {
		try {
			const userInfo: Profile = await getUserProfile(tokenSession);
			dispatch(setProfile(userInfo));

			clevertap.onUserLogin.push({
				Site: {
					Name: `${userInfo.first_name} ${userInfo.father_surname}`,
					Identity: userInfo.id,
					Email: userInfo.email,
					Phone: `${userInfo.country_code}${userInfo.cellphone}`,
				},
			});
			growthbookFile?.setAttributes({
				loggedIn: true,
				id: userInfo?.id,
				email: userInfo.email,
				Phone: `${userInfo.country_code}${userInfo.cellphone}`,
				browser: getBrowser(),
			});

			if (userInfo?.id && window.clarity) {
				window.clarity('identify', userInfo.id.toString());
				// clarity.identify(userInfo.id.toString(), { userId: userInfo?.id });
			}
		} catch (error) {}
	};

	useEffect(() => {
		const tokenByUrl = searchParams.get('token');
		if (tokenByUrl) {
			if (isLocalStorageEnabled()) {
				localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, tokenByUrl);
			} else {
				dispatch(addToken(tokenByUrl));
			}
			dispatch(login());
			getStorageProfile();
			return;
		} else {
			if (!userToken) {
				setTokenExist(false);
			}
		}
	}, []);

	useEffect(() => {
		if (userToken && !userProfile) getStorageProfile();
	}, [location.pathname]);

	useEffect(() => {
		const utmCampaign = searchParams.get(HERU_UTM.CAMPAIGN);
		const utmSource = searchParams.get(HERU_UTM.SOURCE);
		const utmMedium = searchParams.get(HERU_UTM.MEDIUM);

		if (utmCampaign && utmSource && utmMedium) {
			if (isSessionStorageEnabled()) {
				sessionStorage.setItem(LOCAL_STORAGE_KEY.OVERWRITE_UTM, 'false');
				sessionStorage.setItem(LOCAL_STORAGE_KEY.CAMPAIGN, utmCampaign);
				sessionStorage.setItem(LOCAL_STORAGE_KEY.SOURCE, utmSource);
				sessionStorage.setItem(LOCAL_STORAGE_KEY.MEDIUM, utmMedium);
			} else {
				dispatch(setOverwriteUtm(false));
				dispatch(
					updateUTMs({
						utm_campaign: utmCampaign,
						utm_medium: utmMedium,
						utm_source: utmSource,
					}),
				);
			}
		}
	}, []);

	useEffect(() => {
		const isSessionAvailable = isSessionStorageEnabled();
		const utmCampaign = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
			: utmInfo?.utm_campaign || null;
		const utmSource = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
			: utmInfo?.utm_source || null;
		const utmMedium = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
			: utmInfo?.utm_medium || null;

		if (utmCampaign) {
			searchParams.set(HERU_UTM.CAMPAIGN, utmCampaign);
		}
		if (utmSource) {
			searchParams.set(HERU_UTM.SOURCE, utmSource);
		}
		if (utmMedium) {
			searchParams.set(HERU_UTM.MEDIUM, utmMedium);
		}
		searchParams.set('prycx', 'on');

		navigate(`${location.pathname}?${searchParams.toString()}`);
	}, [location.pathname]);

	if (
		isLocalStorageEnabled() &&
		((!userToken && !isUser) || !userToken) &&
		!tokenExist
	) {
		return (
			<Navigate
				to={redirectPath}
				replace
			/>
		);
	}

	if (location.pathname.includes('onboarding')) {
		return <Outlet />;
	}

	return (
		<MainLayout>
			<Outlet />
		</MainLayout>
	);
};

export default React.memo(ProtectedRoutes);
