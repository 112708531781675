import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Radio, SelectChangeEvent } from '@mui/material';
import { CreditCard } from '@phosphor-icons/react';
import { Card, PAYMENT_METHOD } from '../../../../@types/Checkout.type';
import { IPaymentInfo } from '../../../../@types/Payments.types';
import { getPaymentCards } from '../../../../services/payments.service';
import Text from '../../../../components/atoms/Text';
import PaymentMethodItem from '../../../checkout/components/molecules/PaymentMethodItem';
import { capitalizeFirstLetter } from '../../../../shared/utils/general.util';
import UnlockStripe from './UnlockStripe';
import { BlockedSub } from '../../../../@types/BlockedSubs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { getBrandIcon } from '../../../../shared/utils/payments.util';
interface SelectPaymentMethodProps {
	order: BlockedSub;
	selectPaymentMethod: (
		paymentMethod: PAYMENT_METHOD,
		paymentInfo?: IPaymentInfo,
	) => void;
	confirmPayment: boolean;
	setConfrimPayment: (value: boolean) => void;
	customerStripeId: string;
	onSuccess?: () => void;
	setTokenIdPurchased: (value: string) => void;
}
const SelectUnlockPayment: React.FC<SelectPaymentMethodProps> = ({
	order,
	selectPaymentMethod,
	confirmPayment,
	setConfrimPayment,
	customerStripeId,
	onSuccess,
	setTokenIdPurchased,
}) => {
	const [category, setCategory] = useState<PAYMENT_METHOD | string>(
		PAYMENT_METHOD.CARD,
	);
	const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);
	const [isLoadingCards, setIsLoadingCards] = useState(false);
	const [cardSelected, setCardSelected] = useState<Card>();
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const handleChange = (event: SelectChangeEvent) => {
		if (event.target.value === PAYMENT_METHOD.CARD) {
			setCategory(event.target.value as PAYMENT_METHOD);
			selectPaymentMethod(event.target.value as PAYMENT_METHOD);
		} else {
			setCategory(event.target.value as string);
			const payment = allPaymentsCards.find(
				(card) => card.id === event.target.value,
			);
			setCardSelected(payment);
			const platformPayment = {
				platform: payment?.customer?.platform?.platform_name ?? 'stripe',
				token: event.target.value,
			};
			selectPaymentMethod(PAYMENT_METHOD.CARD, platformPayment);
		}
	};

	const categoryName = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return 'Agregar tarjeta de crédito o débito';
				default:
					return 'N/A';
			}
		},
		[],
	);

	const categoryImage = useMemo(
		() => (_category: PAYMENT_METHOD) => {
			switch (_category) {
				case PAYMENT_METHOD.CARD:
					return '';
				default:
					return '';
			}
		},
		[],
	);

	const getCardList = useCallback(async () => {
		try {
			setIsLoadingCards(true);
			const resp: Card[] = await getPaymentCards(tokenSession);
			setAllPaymentsCards(resp);
			setIsLoadingCards(false);
			if (resp?.length > 0) {
				const lastCard = resp[0];
				setCategory(lastCard?.id);
				setCardSelected(lastCard);
				const platformPayment = {
					platform: lastCard?.customer?.platform?.platform_name ?? 'stripe',
					token: lastCard?.id,
				};
				selectPaymentMethod(PAYMENT_METHOD.CARD, platformPayment);
			}
		} catch (error) {
			setIsLoadingCards(false);
		}
	}, []);

	useEffect(() => {
		getCardList();
	}, []);

	useEffect(() => {
		if (!order) return;
	}, [order]);

	return (
		<div className='w-full px-4 py-3 bg-white rounded-lg shadow flex-col justify-start items-start gap-[15px] inline-flex border'>
			{isLoadingCards ? (
				<div className='flex flex-row items-center justify-center m-2 w-full'>
					<CircularProgress />
				</div>
			) : (
				<>
					<div className='w-full'>
						<div className='flex flex-col justify-between'>
							<Text
								size='body-2'
								sx='mb-4'
								color='medium'
							>
								Tus tarjetas
							</Text>
							{allPaymentsCards.map((card) => (
								<div key={`cards-${card.platform_token}`}>
									<div className='flex flex-row mb-2 items-center gap-y-4'>
										<Radio
											checked={category === card.id}
											onChange={handleChange}
											value={card.id}
											name='radio-buttons-payments'
											inputProps={{ 'aria-label': 'A' }}
										/>

										<CardInfo card={card} />
									</div>
									{!(category in PAYMENT_METHOD) &&
										cardSelected?.id === card.id && (
											<>
												<UnlockStripe
													order={order}
													onPaymentMethodSuccess={() => {
														getCardList();
														onSuccess?.();
													}}
													selectedCardId={cardSelected?.id}
													confirmPayment={confirmPayment}
													showPaymentElement={false}
													setConfrimPayment={setConfrimPayment}
													customerStripeId={customerStripeId}
													setTokenIdPurchased={setTokenIdPurchased}
												/>
											</>
										)}
								</div>
							))}
							<div className='flex flex-row mb-2 items-start'>
								<Radio
									checked={category === PAYMENT_METHOD.CARD}
									onChange={handleChange}
									value={PAYMENT_METHOD.CARD}
									name='radio-buttons-payments'
									inputProps={{ 'aria-label': 'A' }}
								/>

								<PaymentMethodItem
									label={categoryName(PAYMENT_METHOD.CARD)}
									imgSrc={categoryImage(PAYMENT_METHOD.CARD)}
									imgAlt=''
									description={''}
									showDescription={category === PAYMENT_METHOD.CARD}
									icon={
										<CreditCard
											size={28}
											weight='fill'
										/>
									}
								>
									{category === PAYMENT_METHOD.CARD && (
										<div className='md:w-[40vw] lg:w-[36vw]'>
											<Text
												size='caption'
												sx='mb-4'
												color='medium'
											>
												Paga fácil con cualquier tarjeta bancaria
											</Text>
											<div className='border px-4 py-[18px] rounded-lg'>
												<UnlockStripe
													order={order}
													onPaymentMethodSuccess={() => {
														getCardList();
													}}
													showPaymentElement={true}
													setConfrimPayment={setConfrimPayment}
													customerStripeId={customerStripeId}
													setTokenIdPurchased={setTokenIdPurchased}
												/>
											</div>
										</div>
									)}
								</PaymentMethodItem>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

const CardInfo: React.FC<{ card: Card }> = ({ card }) => {
	return (
		<div className='flex flex-row items-center justify-between gap-2 w-full'>
			<div className='inline-flex items-center gap-x-1 justify-center'>
				<img
					src={getBrandIcon(card?.details?.brand)}
					alt='brand'
					className='w-[32px] h-[32px]'
				/>
				<span className='text-sm font-bold inline-flex text-h-neutral-30'>
					<span className='hidden md:block mr-1 text-h-neutral-30'>
						{capitalizeFirstLetter(
							card.details.brand === 'amex'
								? 'American Express'
								: card.details.brand,
						)}{' '}
					</span>
					**** {card.details?.last_four || ''}
				</span>
			</div>
			<div>
				<Text
					size='m'
					sx='hidden md:block'
					color='medium'
				>
					Vence el {card.details.expires}
				</Text>
				<Text
					size='m'
					sx='block md:hidden'
					color='medium'
				>
					{card.details.expires}
				</Text>
			</div>
		</div>
	);
};

export default SelectUnlockPayment;
