import { createSlice } from '@reduxjs/toolkit';
import {
	PlanRegularizationPeriod,
	PlansProduct,
} from '../../pages/plansAndPrices';

type PlanConfig = {
	paymentFrecuency?: number;
	regimes?: string[];
	annualYears?: number[];
	regimeConfig?: {
		lease: string;
	};
	step: number;
	regularizationPeriods?: PlanRegularizationPeriod[];
};

interface PlansPricesState {
	selectedInvoiceCategory: string;
	planConfig: PlanConfig;
	selectedProduct: PlansProduct | null;
}

const initialState: PlansPricesState = {
	selectedInvoiceCategory: 'monthly_declarations',
	planConfig: {
		step: 1,
	},
	selectedProduct: null,
};

export const plansPricesSlice = createSlice({
	name: 'plans',
	initialState,
	reducers: {
		setSelectedInvoiceCategory: (state, action) => {
			state.selectedInvoiceCategory = action.payload;
		},
		setPlanConfig: (state, action) => {
			state.planConfig = action.payload;
		},
		setSelectedProduct: (state, action) => {
			state.selectedProduct = action.payload;
		},
		resetPlans: (state) => {
			state.selectedInvoiceCategory = initialState.selectedInvoiceCategory;
			state.planConfig = initialState.planConfig;
			state.selectedProduct = initialState.selectedProduct;
		},
	},
});

export const {
	setSelectedInvoiceCategory,
	setPlanConfig,
	setSelectedProduct,
	resetPlans,
} = plansPricesSlice.actions;

export default plansPricesSlice.reducer;
