import { useMediaQuery } from '@mui/material';
import Text from '../../../components/atoms/Text';
import Table, { TColumns, TRowData } from '../../../components/molecules/Table';
import SideToModalContainer from '../../taxesAndInvoices/molecules/SideToModalContainer';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { InvoicingFormBody } from '../newInvoiceFlow';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { timestampToLocalStringISOFormat } from '../../../shared/utils/times.util';
import { formatCurrency } from '../../../shared/utils/general.util';
import { RootState } from '../../../redux/store';
import { ProfileState } from '../../../redux/slices/profile.slice';
import { useSelector } from 'react-redux';
import { SummaryInvoice } from '../../../@types/Invoicing.type';
import { XCircle } from '@phosphor-icons/react';

type PropListItem = {
	label: string;
	value: string;
};

const InvoicePreviewSubProperty = ({
	properties,
	type,
	value,
}: {
	type: string;
	value: string;
	properties: PropListItem[];
}) => {
	const isMobile = useMediaQuery('(max-width: 1024px)');
	return (
		<div>
			<Text
				size='mini-1'
				color='medium'
				weight='light'
			>
				{type}
			</Text>
			<Text
				size={isMobile ? 'caption' : 'body-3'}
				color='medium'
				weight='medium'
			>
				{value}
			</Text>
			<div className='flex flex-col gap-1'>
				{properties.map(({ label, value }, index) => (
					<div
						key={`prop-index-${index}`}
						className='flex gap-1'
					>
						<Text
							size='caption'
							color='medium'
							weight='light'
							sx='!block'
						>
							{label} <span className='!font-medium'>{value}</span>
						</Text>
					</div>
				))}
			</div>
		</div>
	);
};

const InvoicePreviewProperty = ({
	label,
	value,
}: {
	label: string;
	value: string;
}) => {
	const isMobile = useMediaQuery('(max-width: 1024px)');
	return (
		<Text
			size={isMobile ? 'caption' : 'body-3'}
			color='medium'
			weight='light'
			sx='justify-between'
		>
			{label}
			<b className='font-bold text-right'>{value}</b>
		</Text>
	);
};

type Props = {
	watch: UseFormWatch<InvoicingFormBody>;
	validatedInvoiceSummary: SummaryInvoice | undefined;
	receiverInfo: {
		rfc: string;
		name: string;
		tax_regime: string;
		postal_code: string;
		usocfdi: string;
	};
	showModal: boolean;
	setShowModal: Dispatch<
		SetStateAction<{ dueCert: boolean; invoicePreviewMobile: boolean }>
	>;
};

const InvoicePreview: React.FC<Props> = ({
	watch,
	receiverInfo,
	validatedInvoiceSummary,
	showModal,
	setShowModal,
}) => {
	const { stampStatus, catalogs, regimes } = useSelector<
		RootState,
		ProfileState
	>((state) => state.profile);

	const { issuerName: issuerNameByInput } = useSelector(
		(state: RootState) => state.invocing,
	);

	const issuerRegime =
		regimes?.find((regime) => regime.code === watch('issuer_regime'))?.name ||
		'-';

	const receiverRegime =
		regimes?.find((regime) => regime.code === receiverInfo?.tax_regime)?.name ||
		'-';

	const usoCFDI = catalogs?.usocfdi?.[receiverInfo?.usocfdi]?.name || '-';

	const productsColumns: TColumns[] = [
		{
			title: 'Tipo',
			dataIndex: 'description',
		},
		{
			title: 'Cantidad',
			dataIndex: 'quantity',
			align: 'center',
		},
		{
			title: 'Subtotal',
			dataIndex: 'subtotal',
			align: 'right',
		},
	];

	const productsRows: TRowData[] = watch('products').map((product) => {
		return {
			rowStyle: '[&>td]:py-1 [&>td]:px-1',
			rowContent: {
				description: {
					content: product?.description || '-',
				},
				quantity: {
					content: product?.description !== '' ? product?.quantity || '-' : '-',
				},
				subtotal: {
					content:
						product?.description !== ''
							? formatCurrency(Number(product?.price) || 0) || '-'
							: '-',
				},
			},
		};
	});

	const totalDetails = [
		{
			label: 'Subtotal',
			value: formatCurrency(validatedInvoiceSummary?.sub_total!),
		},
		{
			label: 'IVA',
			value: formatCurrency(validatedInvoiceSummary?.total_transfer_iva!),
		},
		{
			label: 'Retenciones IVA',
			value: formatCurrency(validatedInvoiceSummary?.total_retentions_iva!),
		},
		{
			label: 'Retentciones ISR',
			value: formatCurrency(validatedInvoiceSummary?.total_retentions_isr!),
		},
	];

	const invoiceInfo = [
		{
			label: 'Método de pago',
			value:
				Object.values(catalogs?.payment_form ?? {}).find(
					(p) => p.code === validatedInvoiceSummary?.payment_form,
				)?.name ?? '-',
		},
		{
			label: 'Tipo de factura',
			value: 'Ingresos',
		},
		{
			label: 'Moneda de pago',
			value: 'Pesos mexicanos',
		},
	];

	const isMobile = useMediaQuery('(max-width: 1024px)');
	const handleClose = () => {
		setShowModal({ dueCert: false, invoicePreviewMobile: false });
	};

	const allowEditIssuerName = watch('issuer_regime') === '601';
	const issuerName =
		allowEditIssuerName && issuerNameByInput
			? issuerNameByInput
			: stampStatus?.name || '-';

	return (
		<SideToModalContainer
			isOpen={!isMobile || (showModal && isMobile)}
			handleClose={handleClose}
			sx='flex max-h-fit justify-center sm:min-h-[calc(100dvh-190px)]'
			sxContainer='sm:h-fit sm:sticky sm:top-16'
			maxWidth='1024px'
		>
			<div className='w-full flex flex-col gap-3'>
				<div className='flex justify-between items-center'>
					<Text
						size={isMobile ? 'body-3' : 'body-1'}
						weight='medium'
					>
						Previsualización de la factura
					</Text>
					<XCircle
						onClick={handleClose}
						color='var(--h-primary)'
						weight='fill'
						width={24}
						height={24}
						className='cursor-pointer lg:hidden'
					/>
				</div>

				<hr />
				<div className='max-h-[calc(100dvh-180px)] sm:max-h-none md:h-auto flex flex-col gap-3 overflow-y-auto'>
					<div>
						<Text
							size='mini-1'
							color='medium'
							weight='light'
						>
							Fecha de emisión
						</Text>
						<Text
							size='caption'
							color='medium'
							weight='light'
						>
							{getFormatDate(
								timestampToLocalStringISOFormat(new Date()) || '',
								'DD MMM YYYY',
								false,
								true,
							) || '-'}
						</Text>
					</div>
					<InvoicePreviewSubProperty
						type='Emisor'
						value={issuerName.toUpperCase()}
						properties={[
							{
								label: 'RFC:',
								value: stampStatus?.code?.toUpperCase() || '-',
							},
							{
								label: 'Régimen:',
								value: issuerRegime?.toUpperCase(),
							},
							{
								label: 'Código postal:',
								value:
									stampStatus?.postal_code || watch('issuer_zip_code') || '-',
							},
						]}
					/>
					<InvoicePreviewSubProperty
						type='Receptor'
						value={receiverInfo?.name?.toUpperCase() || '-'}
						properties={[
							{
								label: 'RFC:',
								value: receiverInfo?.rfc || '-',
							},
							{
								label: 'Régimen:',
								value: receiverRegime?.toUpperCase(),
							},
							{
								label: 'Código postal:',
								value: receiverInfo?.postal_code,
							},
							{
								label: 'Uso de CFDI:',
								value: usoCFDI,
							},
						]}
					/>
					<Table
						columns={productsColumns}
						rowsData={productsRows}
					/>
					<hr />
					<div className='flex flex-col gap-1'>
						{totalDetails.map(({ label, value }, index) => (
							<InvoicePreviewProperty
								key={`total-${index}`}
								label={label}
								value={value}
							/>
						))}
					</div>
					<hr />
					<Text
						size='body-1'
						weight='bold'
						color='medium'
						sx='justify-between'
					>
						Total{' '}
						<span>
							{formatCurrency(validatedInvoiceSummary?.total_amount!)}
						</span>
					</Text>
					<hr />
					<div className='flex flex-col gap-1'>
						{invoiceInfo.map(({ label, value }, index) => (
							<InvoicePreviewProperty
								key={`invoice-${index}`}
								label={label}
								value={value}
							/>
						))}
					</div>
				</div>
			</div>
		</SideToModalContainer>
	);
};

export default InvoicePreview;
