import { Trash } from '@phosphor-icons/react';
import Text from '../../../../components/atoms/Text';
import { capitalizeFirstLetter } from '../../../../shared/utils/general.util';
import { getBrandIcon } from '../../../../shared/utils/payments.util';

interface Props {
	brand: string;
	lastFour: string;
	expirationDate: string;
	onDelete?: () => void;
}

const OptionCard = ({ brand, lastFour, expirationDate, onDelete }: Props) => {
	return (
		<div className='flex gap-x-2 mb-2 py-2 relative w-full justify-between'>
			<div className='flex items-start'>
				<img
					src={getBrandIcon(brand)}
					alt='card'
					className='w-10 h-5 rounded-lg'
				/>
				<div>
					<Text
						size='body-3'
						weight='regular'
					>
						{capitalizeFirstLetter(
							brand === 'amex' ? 'American Express' : brand,
						)}{' '}
						**** {lastFour}
					</Text>
					<Text
						size='caption'
						weight='light'
					>
						Vence el {expirationDate}
					</Text>
				</div>
			</div>

			{onDelete && (
				<button
					type='button'
					className=' px-6 right-0'
					onClick={onDelete}
				>
					<Trash
						size={24}
						color='var(--h-primary-40)'
					/>
				</button>
			)}
		</div>
	);
};

export default OptionCard;
