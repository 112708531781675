import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//? Components
import MobileMenu from '../../components/organisms/MobileMenu';
import CollapsibleSidebar from '../../components/organisms/CollapsibleSidebar';

//? Redux
import { RootState } from '../../redux/store';
import { setIsDesktop } from '../../redux/slices/dimensions.slice';
import Alert from '../../components/molecules/Alert';
import { hideAlert } from '../../redux/slices/alert.slice';
import { getOrders } from '../../services/order.service';
import {
	addAdditionalInformation,
	addProductToCart,
	setCouponCode,
	updateAmountOfItems,
} from '../../redux/slices/cart.slice';
import ROUTES from '../../shared/constants/routes';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import { SEARCH_PARAMS } from '../../shared/constants/searchParams';
import Topbar from '../../components/organisms/Topbar';
import { setIsFromApp, setPlatformOS } from '../../redux/slices/profile.slice';
import {
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import { setGoto } from '../../redux/slices/auth.slice';
import { fetchCheckUserRappi } from '../../services/invoicing.service';
import { addRappiCertificates } from '../../redux/slices/rappi.slice';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import { useMediaQuery } from '@mui/material';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	const dispatch = useDispatch();
	const isDesktop = useSelector((state: RootState) => state.isDesktop.value);
	const alert = useSelector((state: RootState) => state.alert);
	const { numbersOfItems } = useSelector((state: RootState) => state.cart);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const showHubspotChat = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.WEBHOOK_HUBSPOT,
	);
	const [delayFeatureFlag, setDelayFeatureFlag] = useState(true);
	const [isHubspotChatOpen, setIsHubspotChatOpen] = useState(false);

	const [iframeContainer, setIframeContainer] = useState<HTMLElement | null>(
		null,
	);

	const [expanded, setExpanded] = useState(false);

	const location = useLocation();
	const [searchParams] = useSearchParams();

	const isMobile = useMediaQuery('(max-width: 768px)');

	const getUserOrders = async () => {
		try {
			const response = await getOrders('false', undefined, tokenSession);
			if (response?.length > 0) {
				dispatch(updateAmountOfItems(response[0]?.items?.length || 0));
			}
		} catch (error: any) {}
	};

	const getIsRappiUser = useCallback(async () => {
		try {
			if (!userProfile?.id) return;
			const resource = await fetchCheckUserRappi(
				userProfile?.id!,
				tokenSession,
			);
			dispatch(addRappiCertificates(resource));
		} catch (error) {}
	}, [userProfile]);

	useEffect(() => {
		const handleResize = () => {
			const newIsDesktop = window.innerWidth > 768;
			dispatch(setIsDesktop(newIsDesktop));
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	useEffect(() => {
		const isSessionStoragAvailable = isSessionStorageEnabled();
		if (isSessionStoragAvailable) {
			sessionStorage.setItem(SESSION_STORAGE_KEY.GO_TO, location.pathname);
		} else {
			dispatch(setGoto(location.pathname));
		}

		const productsAdded = searchParams.getAll('add_to_cart');
		if (productsAdded.length > 0) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEY.PRODUCTS_ADDED,
					JSON.stringify(productsAdded),
				);
			} else {
				dispatch(addProductToCart(JSON.stringify(productsAdded)));
			}
		}

		const cuponAdded = searchParams.get(SEARCH_PARAMS.CUPON_CODE);
		if (cuponAdded) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEY.CUPON_CODE_ADDED,
					cuponAdded,
				);
			} else {
				dispatch(setCouponCode(cuponAdded));
			}
		}

		const yearAdded = searchParams.get(SEARCH_PARAMS.YEAR);
		if (yearAdded) {
			if (isSessionStoragAvailable) {
				sessionStorage.setItem(SESSION_STORAGE_KEY.YEAR_ADDED, yearAdded);
			} else {
				dispatch(addAdditionalInformation({ year: yearAdded }));
			}
		}
	}, []);

	useEffect(() => {
		getIsRappiUser();
		if (
			location.pathname === ROUTES.CHECKOUT ||
			location.pathname === ROUTES.PLANS_AND_PRICES
		) {
			return;
		}
		getUserOrders();
	}, [userProfile]);

	useEffect(() => {
		sessionStorage.setItem(SESSION_STORAGE_KEY.GO_TO, location.pathname);

		const channelSource = searchParams.get('channel_source');
		const platformOS = searchParams.get('platform_os');
		if (channelSource === 'app') {
			dispatch(setIsFromApp(true));
			if (isLocalStorageEnabled()) {
				localStorage.setItem('fromApp', 'true');
			}
		}
		if (platformOS) {
			if (isLocalStorageEnabled()) {
				localStorage.setItem('platformOS', platformOS);
			}
			dispatch(setPlatformOS(platformOS));
		}
	}, [searchParams]);

	const isFromApp = isLocalStorageEnabled()
		? localStorage.getItem('fromApp')
		: false;

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDelayFeatureFlag(false);
		}, 500);

		const observer = new MutationObserver((_mutationsList, observer) => {
			const container = document.getElementById(
				'hubspot-messages-iframe-container',
			);

			if (container) {
				setIframeContainer(container);
				observer.disconnect();
			}
		});

		const config = { childList: true, subtree: true };
		observer.observe(document.body, config);

		return () => {
			clearTimeout(timeoutId);
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		const observer = new MutationObserver((_mutationsList, _observer) => {
			const html = document.querySelector('html');
			if (html?.classList.contains('hs-messages-widget-open')) {
				setIsHubspotChatOpen(true);
				localStorage.setItem('hubspotChatOpen', 'true');
			} else {
				setIsHubspotChatOpen(false);
				localStorage.setItem('hubspotChatOpen', 'false');
			}
		});

		const config = { attributes: true, childList: true, subtree: true };
		observer.observe(document?.querySelector?.('html') as Node, config);

		return () => observer.disconnect();
	}, [iframeContainer]);

	useEffect(() => {
		if (delayFeatureFlag) return;
		const html = document.querySelector('html');

		if (iframeContainer) {
			isMobile
				? iframeContainer.style.setProperty('left', '0', 'important')
				: iframeContainer.style.setProperty('left', 'auto', 'important');
			isMobile
				? iframeContainer.style.setProperty('right', 'auto', 'important')
				: iframeContainer.style.setProperty('right', '0', 'important');

			if (showHubspotChat) {
				isMobile
					? isHubspotChatOpen
						? iframeContainer.style.setProperty('bottom', '0', 'important')
						: iframeContainer.style.setProperty('bottom', '60px', 'important')
					: iframeContainer?.style.setProperty('bottom', '0', 'important');
			} else {
				iframeContainer?.style.setProperty('bottom', '-1000px', 'important');
				html?.classList.remove('hs-messages-widget-open');
			}
		}
	}, [
		iframeContainer,
		showHubspotChat,
		delayFeatureFlag,
		isHubspotChatOpen,
		isMobile,
	]);

	return (
		<div className='flex relative h-full'>
			{isDesktop && (
				<div
					className={`md:flex md:flex-col md:fixed md:inset-y-0 z-[80] h-full ${
						!isDesktop ? 'hidden md:w-0' : expanded ? 'w-64' : 'md:w-0'
					}`}
				>
					<CollapsibleSidebar
						expanded={expanded}
						setExpanded={setExpanded}
					/>
				</div>
			)}

			<div className='flex flex-col w-full'>
				{!isFromApp && <Topbar numbersOfItems={numbersOfItems} />}

				<main
					className={`flex-1 ${
						isDesktop ? (expanded ? 'ml-64' : 'ml-[60px]') : 'ml-0'
					} pb-14 md:pb-0`}
				>
					<MobileMenu />
					{alert.showAlert && (
						<Alert
							type={alert.type}
							description={alert.description}
							title={alert.title}
						/>
					)}
					{children}
				</main>
			</div>
		</div>
	);
};

export default React.memo(MainLayout);
