import { CheckCircle, XCircle } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import { UserIssuedInvoice } from '../../../@types/UserIssuedInvoice.type';
import { TablePagination } from '../../../components/molecules/Table';
import { useEffect, useState } from 'react';
import { getFormatDate } from '../../../shared/utils/dates.util';
import {
	capitalizeText,
	formatCurrency,
} from '../../../shared/utils/general.util';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';

type Props = { invoices: UserIssuedInvoice[] };

const EmmitedInvoiceList: React.FC<Props> = ({ invoices }) => {
	const logClevertapEvent = useCleverTapEvent();

	const { invoice_id } = useParams();
	const [currentPage, setCurrentPage] = useState(1);
	const [pagedInvoices, setPagedInvoices] = useState<UserIssuedInvoice[]>([]);

	useEffect(() => {
		const start = (currentPage - 1) * 5;
		const end = start + 5;
		setPagedInvoices(invoices?.slice(start, end));
	}, [currentPage, invoices]);

	const handleChangePage = (page: number) => {
		setCurrentPage(page);
	};

	const navigate = useNavigate();

	const handleShowInvoiceDetail = (invoiceId: string) => {
		navigate(`${ROUTES.INVOICING}${invoiceId}`);

		logClevertapEvent(ANALYTICS_EVENTS.INVOICE_HOME_INVOICES, {
			event_type: EVENT_TYPES.INVOICE_VIEW_DETAIL,
		});
	};

	return (
		<div className='flex flex-col mt-4 sm:mb-12'>
			{pagedInvoices.map((invoice, index) => {
				const isCanceled = invoice?.cancellation_voucher_xml_url;

				return (
					<div
						key={`item-list-index-${index}`}
						className={`cursor-pointer py-3 px-2 border-b border-h-neutral-90 ${
							invoice_id === invoice.internal_invoice_id
								? 'bg-h-neutral-95 !opacity-100'
								: ''
						}
						${invoice_id && 'opacity-50'}
						`}
						onClick={() => handleShowInvoiceDetail(invoice.internal_invoice_id)}
					>
						<div className='flex justify-between items-center max-w-'>
							<div>
								<div className='sm:flex items-center gap-2'>
									<Text
										size='caption'
										weight='light'
										color='medium'
										sx='mb-1 sm:mb-0'
									>
										{`${getFormatDate(
											invoice?.created_at || '',
											'DD MMM YYYY',
											false,
											true,
										)}`}
									</Text>
									<div className='w-1 h-1 bg-h-medium rounded-full hidden sm:block' />
									<Text
										size='caption'
										weight='light'
										sx='mb-1 sm:mb-0'
										color={isCanceled ? 'red' : 'greenSuccess'}
									>
										{isCanceled ? 'Factura cancelada' : 'Factura vigente'}
										{isCanceled ? (
											<XCircle
												weight='fill'
												color='var(--h-error-50)'
											/>
										) : (
											<CheckCircle
												weight='fill'
												color='var(--h-success-50)'
											/>
										)}
									</Text>
								</div>
								<Text
									color='medium'
									sx='!block truncate max-w-[200px] sm:max-w-max'
								>
									{capitalizeText(invoice?.receiver_name)}
								</Text>
							</div>
							<div>
								<Text
									size='caption'
									weight='light'
									color='light'
								>
									Total
								</Text>
								<Text
									color='medium'
									sx='whitespace-nowrap'
								>
									{formatCurrency(invoice?.total_amount)}
								</Text>
							</div>
						</div>
					</div>
				);
			})}
			<div className='flex justify-end mt-2 mb-2 sm:bottom-4 sm:absolute sm:right-4'>
				<TablePagination
					total={invoices?.length}
					currentPage={currentPage}
					perPage={5}
					onChangePage={handleChangePage}
				/>
			</div>
		</div>
	);
};

export default EmmitedInvoiceList;
