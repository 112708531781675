import React, { useState } from 'react';

import { Checkbox, Radio } from '@mui/material';
import {
	CaretDown,
	CaretUp,
	CheckCircle,
	ShoppingCart,
} from '@phosphor-icons/react';
import { ListProducts } from '../../../../@types/Products.type';
import Text from '../../../../components/atoms/Text';
import Heading from '../../../../components/atoms/Heading';
import {
	formatPrice,
	getPeriodicity,
} from '../../../../shared/utils/general.util';
import Button from '../../../../components/atoms/Button';
import {
	PRODUCTS_BY_SLUG,
	PRODUCTS_PERIODICITIES_ID,
	arrOptions,
	basicAccountingBenefits,
	regimesList,
} from '../../data/ecommerceData';
import { REGIMES_TYPE } from '../../../../shared/constants/regimes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import hotSaleImg from '../../../../assets/img/svg/hot_sale.svg';
import { APP_CONFIGURATIONS } from '../../../../shared/constants/configurations';
import { getElasticyPrice } from '../../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../../shared/utils/growthbook.util';
import { useFeatureValue } from '@growthbook/growthbook-react';
interface BasicAccountingProps {
	products: ListProducts[];
	isMobile: boolean;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}
interface PriceDisplayProps {
	productVersions: any[];
	selectedPeriodicity: number;
}

const BasicAccounting: React.FC<BasicAccountingProps> = ({
	products,
	isMobile,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const [selectedValue, setSelectedValue] = useState(30);
	const [openBenefits, setOpenBenefits] = useState(false);
	const [selectedRegimes, setSelectedRegimes] = useState<string[]>([]);

	const [selectPeriodicityHeruPlus, setSelectPeriodicityHeruPlus] = useState(
		PRODUCTS_PERIODICITIES_ID.HERU_PLUS_MONTHLY,
	);
	const [selectPeriodicityActEmp, setSelectPeriodicityActEmp] = useState(
		PRODUCTS_PERIODICITIES_ID.ACT_EMP_MONTHLY,
	);
	const [selectPeriodicityResico, setSelectPeriodicityResico] = useState(
		PRODUCTS_PERIODICITIES_ID.RESICO_MONTHLY,
	);
	const [selectPeriodicityPT, setSelectPeriodicityPT] = useState(
		PRODUCTS_PERIODICITIES_ID.PT_MONTHLY,
	);
	const [selectPeriodicityRIF, setSelectPeriodicityRIF] = useState(
		PRODUCTS_PERIODICITIES_ID.RIF_MONTHLY,
	);
	const [selectPeriodicityPTArr, setSelectPeriodicityPTArr] = useState(
		PRODUCTS_PERIODICITIES_ID.PT_ARR_MONTHLY,
	);
	const [selectArrType, setSelectArrType] = useState('arr-monthly');
	const [selectArrQuarterly, setSelectArrQuarterly] = useState(
		PRODUCTS_PERIODICITIES_ID.ARR_QUARTERLY,
	);
	const [selectArrMonthly, setSelectArrMonthly] = useState(
		PRODUCTS_PERIODICITIES_ID.ARR_MONTHLY,
	);

	const getProductsBySlug = (products: ListProducts[], slug: string) => {
		return products?.[0]?.products?.filter((it) => it?.slug === slug);
	};

	const getArrQuarterly = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ARR_QUARTERLY,
	);
	const getArrMonthly = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ARR_MONTHLY,
	);
	const getActEmp = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ACTIVIDAD_EMPRESARIAL,
	);
	const getResico = getProductsBySlug(products, PRODUCTS_BY_SLUG.RESICO);
	const getPt = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.PLATAFORMAS_TECNOLOGICAS,
	);
	const getRif = getProductsBySlug(products, PRODUCTS_BY_SLUG.RIF);
	const getPtArr = getProductsBySlug(products, PRODUCTS_BY_SLUG.PT_ARRIENDOS);
	const getHeruPlus = getProductsBySlug(products, PRODUCTS_BY_SLUG.HERU_PLUS);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const PriceDisplay: React.FC<PriceDisplayProps> = ({
		productVersions,
		selectedPeriodicity,
	}) => {
		const selectedProduct = productVersions.find(
			(it) => it.id === selectedPeriodicity,
		);

		return (
			<>
				{isHotSale ? (
					<div className='flex items-center justify-end mt-2 w-full'>
						<div className='flex items-center mr-4'>
							<Text
								color='gray'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 mr-5 md:mr-8 text-center'
							>
								Precio regular
							</Text>
							<Text
								color='gray'
								size={isMobile ? 'label' : 'body-1'}
								weight='regular'
								sx='line-through'
							>
								${selectedProduct ? formatPrice(selectedProduct?.price) : ''}
							</Text>
						</div>
						<div className='flex items-center ml-2'>
							<Text
								color='darkMenu'
								weight='bold'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 text-center mr-5'
							>
								HOT HERU
							</Text>
							<Heading
								color='darkMenu'
								size={isMobile ? 'xs' : 's'}
								weight='medium'
							>
								$
							</Heading>
							<Heading
								color='darkMenu'
								size={isMobile ? 'm' : 'l'}
								weight='medium'
							>
								{selectedProduct
									? formatPrice(selectedProduct?.price * 0.7)
									: ''}
							</Heading>
							<Text
								color='darkMenu'
								size={isMobile ? 's' : 'label'}
								sx='mt-5 ml-1'
								weight='bold'
							>
								{getPeriodicity(selectedValue)}
							</Text>
						</div>
					</div>
				) : (
					<div className='flex flex-row justify-end mr-4 mt-2'>
						<Heading
							size='s'
							weight='bold'
						>
							$
							{selectedProduct
								? APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
									? getElasticyPrice(
											Math.round(selectedProduct?.price / 100),
											elasticityPrice.group_experiment,
										)
									: formatPrice(selectedProduct?.price)
								: ''}
						</Heading>
						<Text
							size='m'
							weight='bold'
							sx='mt-4 ml-2'
						>
							{getPeriodicity(selectedValue)}
						</Text>
					</div>
				)}
			</>
		);
	};

	const renderPriceBySelectedRegime = () => {
		if (selectedRegimes.length === 1) {
			if (selectedRegimes.includes('act_emp')) {
				return (
					<PriceDisplay
						productVersions={getActEmp[0].product_versions}
						selectedPeriodicity={selectPeriodicityActEmp}
					/>
				);
			} else if (selectedRegimes.includes('resico')) {
				return (
					<PriceDisplay
						productVersions={getResico[0].product_versions}
						selectedPeriodicity={selectPeriodicityResico}
					/>
				);
			} else if (selectedRegimes.includes('pt')) {
				return (
					<PriceDisplay
						productVersions={getPt[0].product_versions}
						selectedPeriodicity={selectPeriodicityPT}
					/>
				);
			} else if (selectedRegimes.includes('arriendos')) {
				if (selectArrType === 'arr-monthly') {
					return (
						<PriceDisplay
							productVersions={getArrMonthly[0].product_versions}
							selectedPeriodicity={selectArrMonthly}
						/>
					);
				} else {
					return (
						<PriceDisplay
							productVersions={getArrQuarterly[0].product_versions}
							selectedPeriodicity={selectArrQuarterly}
						/>
					);
				}
			} else if (selectedRegimes.includes('rif')) {
				return (
					<PriceDisplay
						productVersions={getRif[0].product_versions}
						selectedPeriodicity={selectPeriodicityRIF}
					/>
				);
			}
		} else if (selectedRegimes.length === 2) {
			const hasPT = selectedRegimes.some((regime) => regime === 'pt');
			const hasArrendamiento = selectedRegimes.some(
				(regime) => regime === 'arriendos',
			);

			if (hasPT && hasArrendamiento) {
				return (
					<PriceDisplay
						productVersions={getPtArr[0].product_versions}
						selectedPeriodicity={selectPeriodicityPTArr}
					/>
				);
			} else {
				return (
					<PriceDisplay
						productVersions={getHeruPlus[0].product_versions}
						selectedPeriodicity={selectPeriodicityHeruPlus}
					/>
				);
			}
		} else if (selectedRegimes.length > 2) {
			return (
				<PriceDisplay
					productVersions={getHeruPlus[0].product_versions}
					selectedPeriodicity={selectPeriodicityHeruPlus}
				/>
			);
		}
	};

	const renderFrequenciesBySelectedRegime = () => {
		if (selectedRegimes.length === 1) {
			if (selectedRegimes.includes('act_emp')) {
				return (
					<>
						{getActEmp[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityActEmp === it.id}
										onChange={() => {
											setSelectPeriodicityActEmp(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>

											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			} else if (selectedRegimes.includes('resico')) {
				return (
					<>
						{getResico[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityResico === it.id}
										onChange={() => {
											setSelectPeriodicityResico(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			} else if (selectedRegimes.includes('pt')) {
				return (
					<>
						{getPt[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityPT === it.id}
										onChange={() => {
											setSelectPeriodicityPT(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			} else if (selectedRegimes.includes('arriendos')) {
				return (
					<>
						{selectArrType === 'arr-monthly' ? (
							<>
								{getArrMonthly[0].product_versions
									.sort((a, b) => a.periodicity - b.periodicity)
									.map((it) => (
										<div
											key={it.id}
											className={`flex flex-row ${isHotSale && 'items-center'}`}
										>
											<Radio
												style={{
													color:
														selectedValue === it.periodicity
															? '#1D8FF3'
															: undefined,
												}}
												checked={selectArrMonthly === it.id}
												onChange={() => {
													setSelectArrMonthly(it.id);
													setSelectedValue(it.periodicity);
												}}
												value={it.id}
												name='radio-buttons'
												inputProps={{ 'aria-label': 'A' }}
											/>
											{it?.relative_percentage_off &&
											it?.relative_percentage_off > 0 ? (
												<div className='flex flex-col'>
													<Text
														size='m'
														color='dark'
													>
														{getPeriodicity(it.periodicity)}
													</Text>
													{!isHotSale && (
														<Text
															size='s'
															color='primary'
														>
															Ahorra {it.relative_percentage_off}%
														</Text>
													)}
												</div>
											) : (
												<Text
													size='m'
													sx='ml-2'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
											)}
										</div>
									))}
							</>
						) : (
							<>
								{getArrQuarterly[0].product_versions
									.sort((a, b) => a.periodicity - b.periodicity)
									.map((it) => (
										<div
											key={it.id}
											className={`flex flex-row ${isHotSale && 'items-center'}`}
										>
											<Radio
												style={{
													color:
														selectedValue === it.periodicity
															? '#1D8FF3'
															: undefined,
												}}
												checked={selectArrQuarterly === it.id}
												onChange={() => {
													setSelectArrQuarterly(it.id);
													setSelectedValue(it.periodicity);
												}}
												value={it.id}
												name='radio-buttons'
												inputProps={{ 'aria-label': 'A' }}
											/>
											{it?.relative_percentage_off &&
											it?.relative_percentage_off > 0 ? (
												<div className='flex flex-col'>
													<Text
														size='m'
														color='dark'
													>
														{getPeriodicity(it.periodicity)}
													</Text>
													{!isHotSale && (
														<Text
															size='s'
															color='primary'
														>
															Ahorra {it.relative_percentage_off}%
														</Text>
													)}
												</div>
											) : (
												<Text
													size='m'
													sx='ml-2'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
											)}
										</div>
									))}
							</>
						)}
					</>
				);
			} else if (selectedRegimes.includes('rif')) {
				return (
					<>
						{getRif[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityRIF === it.id}
										onChange={() => {
											setSelectPeriodicityRIF(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			}
		} else if (selectedRegimes.length === 2) {
			const hasPT = selectedRegimes.some((regime) => regime === 'pt');
			const hasArrendamiento = selectedRegimes.some(
				(regime) => regime === 'arriendos',
			);

			if (hasPT && hasArrendamiento) {
				return (
					<>
						{getPtArr[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityPTArr === it.id}
										onChange={() => {
											setSelectPeriodicityPTArr(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			} else {
				return (
					<>
						{getHeruPlus[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityHeruPlus === it.id}
										onChange={() => {
											setSelectPeriodicityHeruPlus(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			}
		} else if (selectedRegimes.length > 2) {
			return (
				<>
					{getHeruPlus[0].product_versions
						.sort((a, b) => a.periodicity - b.periodicity)
						.map((it) => (
							<div
								key={it.id}
								className={`flex flex-row ${isHotSale && 'items-center'}`}
							>
								<Radio
									style={{
										color:
											selectedValue === it.periodicity ? '#1D8FF3' : undefined,
									}}
									checked={selectPeriodicityHeruPlus === it.id}
									onChange={() => {
										setSelectPeriodicityHeruPlus(it.id);
										setSelectedValue(it.periodicity);
									}}
									value={it.id}
									name='radio-buttons'
									inputProps={{ 'aria-label': 'A' }}
								/>
								{it?.relative_percentage_off &&
								it?.relative_percentage_off > 0 ? (
									<div className='flex flex-col'>
										<Text
											size='m'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
										{!isHotSale && (
											<Text
												size='s'
												color='primary'
											>
												Ahorra {it.relative_percentage_off}%
											</Text>
										)}
									</div>
								) : (
									<Text
										size='m'
										sx='ml-2'
										color='dark'
									>
										{getPeriodicity(it.periodicity)}
									</Text>
								)}
							</div>
						))}
				</>
			);
		}
	};

	const handleCheckboxChange = (regimeId: string) => {
		if (handleDisabled(regimeId)) {
			return;
		}
		const updatedSelectedRegimes = [...selectedRegimes];
		const index = updatedSelectedRegimes.indexOf(regimeId);

		if (index === -1) {
			updatedSelectedRegimes.push(regimeId);
		} else {
			updatedSelectedRegimes.splice(index, 1);
		}
		setSelectedRegimes(updatedSelectedRegimes);
	};

	const handleDisabled = (regimeId: string) => {
		if (
			['resico', 'act_emp'].includes(regimeId) &&
			selectedRegimes.includes('rif')
		) {
			return true;
		}

		if (
			['resico'].includes(regimeId) &&
			selectedRegimes.includes('arriendos')
		) {
			return true;
		}

		if (['resico'].includes(regimeId) && selectedRegimes.includes('pt')) {
			return true;
		}

		if (
			['act_emp', 'pt', 'arriendos', 'rif'].includes(regimeId) &&
			selectedRegimes.includes('resico')
		) {
			return true;
		}

		if (
			['resico', 'rif'].includes(regimeId) &&
			selectedRegimes.includes('act_emp')
		) {
			return true;
		}

		return false;
	};

	const handleGoToCheckout = () => {
		if (selectedRegimes.length === 1) {
			if (selectedRegimes.includes('act_emp')) {
				handleAddProduct(selectPeriodicityActEmp, {
					regimes: REGIMES_TYPE.ACTIVIDAD_EMPRESARIAL,
				});
			} else if (selectedRegimes.includes('resico')) {
				handleAddProduct(selectPeriodicityResico, {
					regimes: REGIMES_TYPE.RESICO,
				});
			} else if (selectedRegimes.includes('pt')) {
				handleAddProduct(selectPeriodicityPT, {
					regimes: REGIMES_TYPE.PLATAFORMAS_TECNOLOGICAS,
				});
			} else if (selectedRegimes.includes('arriendos')) {
				if (selectArrType === 'arr-monthly') {
					handleAddProduct(selectArrMonthly, {
						regimes: REGIMES_TYPE.ARRENDAMIENTO,
					});
				} else {
					handleAddProduct(selectArrQuarterly, {
						regimes: REGIMES_TYPE.ARRENDAMIENTO,
					});
				}
			} else if (selectedRegimes.includes('rif')) {
				handleAddProduct(selectPeriodicityRIF, {
					regimes: REGIMES_TYPE.RIF,
				});
			}
		} else if (selectedRegimes.length === 2) {
			const hasPT = selectedRegimes.some((regime) => regime === 'pt');
			const hasArrendamiento = selectedRegimes.some(
				(regime) => regime === 'arriendos',
			);

			if (hasPT && hasArrendamiento) {
				handleAddProduct(selectPeriodicityPTArr, {
					regimes: 'Arrendamiento y Plataformas Tecnológicas',
				});
			} else {
				handleAddProduct(selectPeriodicityHeruPlus, {
					regimes: selectedRegimes,
				});
			}
		} else if (selectedRegimes.length > 2) {
			handleAddProduct(selectPeriodicityHeruPlus, {
				regimes: selectedRegimes,
			});
		}
	};

	return (
		<>
			{isMobile ? (
				<div className='flex flex-col rounded-md border mb-56'>
					<div className='sticky top-0 z-10 bg-white'>
						<div
							className={`${
								isHotSale ? 'bg-h-hot-sale' : 'bg-h-dark'
							} p-4 rounded-t-md flex flex-col items-center`}
						>
							{isHotSale && (
								<div className='mb-12'>
									<div className='absolute left-0 -top-16 flex items-center justify-center'>
										<img
											src={hotSaleImg}
											alt='hot sale heru'
											className='w-56 h-56'
										/>
									</div>
								</div>
							)}
							{selectedRegimes.length > 0 ? (
								<>
									<Text
										size='xl'
										color='white'
										weight='bold'
									>
										Contabilidad básica
									</Text>
								</>
							) : (
								<>
									<Text
										size='s'
										color='white'
										sx='text-center'
									>
										Tu contabilidad mensual 100% cubierta para una o más fuentes
										de ingreso
									</Text>
									<div
										className={`border ${
											isHotSale ? 'border-white' : 'border-blue-200'
										} my-4 mx-4 w-full`}
									/>
									<Text
										size='xl'
										color='white'
										weight='bold'
									>
										Contabilidad básica
									</Text>
								</>
							)}
						</div>

						{selectedRegimes.length > 0 && renderPriceBySelectedRegime()}
					</div>

					<div className='flex flex-col p-4'>
						<div className='border-b pb-4'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								Seleccionar uno o más regímenes
							</Text>
							<div className='flex flex-col mt-3'>
								{regimesList.map((regime, index) => (
									<div
										className='flex flex-row items-center my-1'
										key={`annual-regime-${index}`}
									>
										<Checkbox
											style={{
												color: selectedRegimes.includes(regime.id)
													? '#1D8FF3'
													: undefined,
											}}
											checked={selectedRegimes.includes(regime.id)}
											onChange={() => handleCheckboxChange(regime.id)}
											disabled={handleDisabled(regime.id)}
										/>
										<div className='flex flex-col'>
											<Text
												size='s'
												color={handleDisabled(regime.id) ? 'light' : 'dark'}
											>
												{regime.name}
											</Text>
											<Text
												size='s'
												color={handleDisabled(regime.id) ? 'light' : 'medium'}
											>
												{regime.desc}
											</Text>
										</div>
									</div>
								))}
							</div>
						</div>

						{selectedRegimes.length === 1 &&
							selectedRegimes.includes('arriendos') && (
								<div className='border-b pb-4 mt-5'>
									<Text
										size='l'
										color='dark'
										weight='bold'
									>
										¿Qué tipo de declaración realizas?
									</Text>

									<div className='my-8'>
										{arrOptions.map((it) => (
											<div
												key={it.id}
												className='flex flex-row mb-4'
											>
												<Radio
													style={{
														color: selectArrType === it.id ? '#' : undefined,
													}}
													checked={selectArrType === it.id}
													onChange={() => {
														setSelectArrType(it.id);
													}}
													value={it.id}
													name='radio-buttons'
													inputProps={{ 'aria-label': 'A' }}
												/>
												<div className='flex flex-col'>
													<Text
														size='m'
														color='dark'
														weight={selectArrType === it.id ? 'bold' : 'medium'}
													>
														{it.name}
													</Text>
													<Text
														size='s'
														color='light'
														weight={selectArrType === it.id ? 'bold' : 'medium'}
													>
														{it.desc}
													</Text>
												</div>
											</div>
										))}
									</div>
								</div>
							)}

						{selectedRegimes.length > 0 && (
							<div className='border-b pb-4 mt-5'>
								<Text
									size='l'
									color='dark'
									weight='bold'
								>
									Frecuencia de pago
								</Text>
								<div className='grid grid-cols-2 gap-4 mt-2'>
									{renderFrequenciesBySelectedRegime()}
								</div>
							</div>
						)}

						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{basicAccountingBenefits.map((benefit, index) => (
										<div
											key={`annual-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div
						className={`fixed bottom-12 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg`}
					>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={
										selectedRegimes.length === 0
											? 'white'
											: isHotSale
												? 'var(--h-primary)'
												: 'white'
									}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							disabled={selectedRegimes.length === 0}
							onClick={handleGoToCheckout}
							className={`${
								selectedRegimes.length === 0
									? 'bg-gray-400'
									: isHotSale
										? 'bg-h-blue-20'
										: 'bg-h-primary'
							} rounded-lg ${
								selectedRegimes.length === 0
									? 'text-white'
									: isHotSale
										? 'text-h-primary'
										: 'text-white'
							} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			) : (
				<div>
					<div
						className={`${
							isHotSale ? 'bg-h-hot-sale' : 'bg-h-dark'
						} relative p-4 rounded-t-xl flex flex-col items-center`}
					>
						{isHotSale && (
							<div className='mb-12'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}
						<Text
							size='l'
							color={isHotSale ? 'white' : 'ultraBlueLight'}
							sx='text-center line-clamp-2 max-w-[370px]'
						>
							Tu contabilidad mensual 100% cubierta para una o más fuentes de
							ingreso
						</Text>
						<div
							className={`border ${
								isHotSale ? 'border-white' : 'border-blue-200'
							} my-4 mx-4 w-full`}
						/>
						<Heading
							size='m'
							color='white'
							weight='bold'
						>
							Contabilidad básica
						</Heading>
					</div>
					{selectedRegimes.length > 0 && renderPriceBySelectedRegime()}
					<div className='flex flex-col px-10 py-6 overflow-y-auto max-h-[450px]'>
						<div className='border-b pb-4'>
							<Text
								size='xl'
								color='dark'
								weight='bold'
							>
								Seleccionar uno o más regímenes
							</Text>
							<div className='flex flex-col mt-3'>
								{regimesList.map((regime, index) => (
									<div
										className='flex flex-row items-center my-1'
										key={`annual-regime-${index}`}
									>
										<Checkbox
											style={{
												color: selectedRegimes.includes(regime.id)
													? '#1D8FF3'
													: undefined,
											}}
											checked={selectedRegimes.includes(regime.id)}
											onChange={() => handleCheckboxChange(regime.id)}
											disabled={handleDisabled(regime.id)}
										/>
										<div className='flex flex-col'>
											<Text
												size='s'
												color={handleDisabled(regime.id) ? 'light' : 'dark'}
											>
												{regime.name}
											</Text>
											<Text
												size='s'
												color={handleDisabled(regime.id) ? 'light' : 'medium'}
											>
												{regime.desc}
											</Text>
										</div>
									</div>
								))}
							</div>
						</div>

						{selectedRegimes.length === 1 &&
							selectedRegimes.includes('arriendos') && (
								<div className='border-b pb-4 mt-5'>
									<Text
										size='l'
										color='dark'
										weight='bold'
									>
										¿Qué tipo de declaración realizas?
									</Text>

									<div className='my-8'>
										{arrOptions.map((it) => (
											<div
												key={it.id}
												className='flex flex-row mb-4'
											>
												<Radio
													style={{
														color: selectArrType === it.id ? '#' : undefined,
													}}
													checked={selectArrType === it.id}
													onChange={() => {
														setSelectArrType(it.id);
													}}
													value={it.id}
													name='radio-buttons'
													inputProps={{ 'aria-label': 'A' }}
												/>
												<div className='flex flex-col'>
													<Text
														size='m'
														color='dark'
														weight={selectArrType === it.id ? 'bold' : 'medium'}
													>
														{it.name}
													</Text>
													<Text
														size='s'
														color='light'
														weight={selectArrType === it.id ? 'bold' : 'medium'}
													>
														{it.desc}
													</Text>
												</div>
											</div>
										))}
									</div>
								</div>
							)}

						{selectedRegimes.length > 0 && (
							<div className='border-b pb-4 mt-5'>
								<Text
									size='l'
									color='dark'
									weight='bold'
								>
									Frecuencia de pago
								</Text>
								<div className='flex flex-row gap-8 mt-4 mb-6'>
									{renderFrequenciesBySelectedRegime()}
								</div>
							</div>
						)}

						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{basicAccountingBenefits.map((benefit, index) => (
										<div
											key={`annual-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className='fixed bottom-0 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg z-0'>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={
										selectedRegimes.length === 0
											? 'white'
											: isHotSale
												? 'var(--h-primary)'
												: 'white'
									}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							onClick={handleGoToCheckout}
							disabled={selectedRegimes.length === 0}
							className={`${
								selectedRegimes.length === 0
									? 'bg-gray-400'
									: isHotSale
										? 'bg-h-blue-20'
										: 'bg-h-primary'
							} rounded-lg ${
								selectedRegimes.length === 0
									? 'text-white'
									: isHotSale
										? 'text-h-primary'
										: 'text-white'
							} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(BasicAccounting);
