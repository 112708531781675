import { useMediaQuery } from '@mui/material';
import Text from '../../../components/atoms/Text';
import { CheckSquare, Square } from '@phosphor-icons/react';
import React from 'react';

type Props = {
	checked: boolean;
	label: React.ReactNode;
	description?: string;
	onClick: () => void;
	disabled?: boolean;
	bottomTagText?: string;
};

const PlanCheckInput: React.FC<
	Props & React.HTMLAttributes<HTMLDivElement>
> = ({
	disabled,
	checked,
	label,
	description,
	onClick,
	bottomTagText,
	...props
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div
			className='h-full'
			{...props}
		>
			<button
				disabled={disabled}
				onClick={onClick}
				className={`relative py-2 md:py-2.5 px-1.5 md:px-2 min-h-20 border w-full ${
					disabled
						? 'cursor-not-allowed border-h-neutral-80'
						: 'cursor-pointer hover:bg-h-primary-95 group'
				} ${checked ? 'border-h-primary-40' : ''} ${bottomTagText ? 'rounded-t-lg' : 'rounded-lg h-full'}`}
			>
				<div
					className={`absolute top-1 right-1 bg-white p-0.5 rounded ${
						disabled ? '' : 'text-h-primary-40'
					}`}
				>
					{checked ? (
						<CheckSquare
							width={20}
							height={20}
							weight='fill'
							className={'text-h-primary-40'}
						/>
					) : (
						<Square
							width={20}
							height={20}
							className='opacity-50 group-hover:opacity-100'
						/>
					)}
				</div>
				<Text
					color={disabled ? 'light' : 'primary'}
					size={isMobile ? 'mini-1' : 'label'}
					weight='medium'
					sx='!block !text-center px-2.5'
				>
					{label}
				</Text>
				{description && (
					<Text
						color={disabled ? 'light' : 'primary'}
						size={isMobile ? 'mini-2' : 'caption'}
						weight='light'
						sx='!block !text-center mt-1'
					>
						{description}
					</Text>
				)}
			</button>
			{bottomTagText && (
				<div
					onClick={onClick}
					className='bg-h-primary-40 rounded-b-lg w-full flex justify-center p-1'
				>
					<Text
						size={isMobile ? 'mini-2' : 'mini-1'}
						color='white'
						sx='!block !text-center !leading-3'
					>
						{bottomTagText}
					</Text>
				</div>
			)}
		</div>
	);
};

export default PlanCheckInput;
