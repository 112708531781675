import { createSlice } from '@reduxjs/toolkit';
import { UserLinkedType } from '../../@types/SATLinking';

interface SatStatusLinkState {
	status: UserLinkedType;
	isLoading: boolean;
	isRecentlyLinked: boolean | null;
}

const userStatusInitial = {
	id: 0,
	user_id: 0,
	source_id: '',
	source_user_id: null,
	status: '',
	created_at: '',
	credential_id: 0,
	username: '',
	linked: false,
};

const initialState: SatStatusLinkState = {
	status: userStatusInitial,
	isLoading: true,
	isRecentlyLinked: null,
};

export const satStatusLinkSlice = createSlice({
	name: 'satStatusLink',
	initialState,
	reducers: {
		startLoader: (state, action) => {
			state.isLoading = action.payload;
		},
		linkedAccount: (state, action) => {
			state.status = action.payload;
			state.isLoading = false;
		},
		restartAppState: (state) => {
			state.status = userStatusInitial;
			state.isLoading = false;
		},
		setIsRecentlyLinked: (state, action) => {
			state.isRecentlyLinked = action.payload;
		},
	},
});

export const {
	startLoader,
	restartAppState,
	linkedAccount,
	setIsRecentlyLinked,
} = satStatusLinkSlice.actions;

export default satStatusLinkSlice.reducer;
