import HERU_API from '../shared/constants/heruApi';
import { get, post } from '../shared/utils/FetchSSL.util';

const postAssignExperiments = async (
	experimentId: string,
	variationId: string,
	tokenSession?: string,
) => {
	try {
		const { data } = await post(
			`${HERU_API.HERU_API_GROWTHBOOK_EXPERIMENTS_ASSIGNMENTS}`,
			{
				experiment_id: experimentId,
				variation_id: variationId,
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return data;
	} catch (error) {
		throw error;
	}
};

const getErrors = async () => {
	try {
		const { data } = await get(HERU_API.HERU_API_ERRORS, {
			isNewGateway: true,
			directResponseTwo: true,
		});
		return data;
	} catch (error) {
		throw error;
	}
};

export { postAssignExperiments, getErrors };
