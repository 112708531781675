import React, { useMemo } from 'react';
import Text from '../../atoms/Text';
import { CheckCircle, Warning } from '@phosphor-icons/react';
import { getMonthName, getPrefixMonth } from '../../../shared/utils/dates.util';
import HomeCard from '../../molecules/HomeCard';
import { arrayRange } from '../../../shared/utils/general.util';
import Tag from '../../molecules/Tag';
type Props = {
	isDisabled?: boolean;
};

const periodsMonthly = arrayRange(1, 12, 1);

const DeclarationStatus: React.FC<Props> = () => {
	const labelStatus = useMemo(
		() => (month: number) => {
			if (month === 6) {
				return 'No presentada';
			}
			return 'Presentada';
		},
		[],
	);
	return (
		<HomeCard
			title='Calendario de declaraciones'
			href='#fiscalform'
			showButton
		>
			<div>
				<div className='flex flex-row gap-x-4 mb-2'>
					<Text
						color='primary'
						size='body-2'
						weight='medium'
						sx='underline'
					>
						Mensuales
					</Text>
					<Text
						color='medium'
						size='body-2'
						weight='medium'
					>
						Anuales
					</Text>
				</div>

				<div className='grid grid-cols-3 gap-3'>
					{periodsMonthly.map((month) => (
						<button
							key={`month-${month}`}
							className={`flex flex-col bg-[#F9F9F9] rounded-lg p-4 shadow-sm min-h-[77px] md:min-h-[154px] relative `}
						>
							{/*  Desktop View */}
							<div className='hidden lg:block'>
								<Text
									size='body-1'
									sx='capitalize mb-2'
									color='medium'
								>
									{getMonthName(month)}
								</Text>

								<Tag
									label={labelStatus(month)}
									type='filled'
									caseVariant={
										labelStatus(month) === 'No presentada' ? 'error' : 'success'
									}
									iconRight={
										labelStatus(month) === 'Presentada' ? (
											<CheckCircle
												size={20}
												weight='fill'
												color='var(--h-success-50)'
											/>
										) : (
											<Warning
												size={20}
												weight='fill'
												color='var(--h-error-50)'
											/>
										)
									}
								/>

								<Text
									color='gray'
									size='caption'
									sx='text-rigth absolute right-4 bottom-4'
								>
									Ver periodo
								</Text>
							</div>

							{/*  Mobile View */}
							<div className='lg:hidden flex flex-row justify-between h-full items-center gap-x-1'>
								<Text
									size='caption'
									sx='capitalize'
									color='medium'
								>
									{getPrefixMonth(month)}
								</Text>

								<div>
									{labelStatus(month) === 'Presentada' ? (
										<CheckCircle
											size={20}
											weight='fill'
											color='var(--h-success-50)'
										/>
									) : (
										<Warning
											size={20}
											weight='fill'
											color='var(--h-error-50)'
										/>
									)}
								</div>
							</div>
						</button>
					))}
				</div>
			</div>
		</HomeCard>
	);
};

export default React.memo(DeclarationStatus);
