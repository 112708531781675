import { Control, Controller, FieldError, UseFormWatch } from 'react-hook-form';
import Text from '../../../components/atoms/Text';
import { Tooltip } from 'antd';
import { Info } from '@phosphor-icons/react';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { InvoicingFormBody } from '../newInvoiceFlow';

type Props = {
	control: Control<any, any>;
	showLabel?: boolean;
	formErrors: any;
	fieldName: string;
	label: string;
	type: 'input-number' | 'input-text' | 'input-email' | 'selector';
	options?: { label: string; value: string }[];
	tooltip?: string;
	disabled?: boolean;
	customError?: FieldError | undefined;
	onChangeFn?: any;
	watch?: UseFormWatch<InvoicingFormBody> | any;
};

const InvoiceFormField: React.FC<Props> = ({
	control,
	formErrors,
	fieldName,
	tooltip,
	options,
	label,
	type,
	disabled,
	showLabel = true,
	customError = undefined,
	onChangeFn,
	watch,
}) => {
	const isUppercase =
		fieldName.includes('rfc') || fieldName.includes('new_receiver_name');
	const haveError = watch ? !!customError : !!formErrors?.[fieldName];
	const errorMessage = !!watch
		? customError?.message
		: formErrors?.[fieldName]?.message;

	const value = watch?.(fieldName) || '';

	const types: Record<string, string> = {
		'input-number': 'number',
		'input-text': 'text',
		'input-email': 'email',
	};

	return (
		<div className='w-full'>
			{showLabel && (
				<div className='flex gap-1 items-center mt-4 mb-3'>
					<Text size='caption'>{label}</Text>
					{tooltip && (
						<Tooltip
							title={tooltip}
							placement='top'
						>
							<Info />
						</Tooltip>
					)}
				</div>
			)}
			<div className='max-w-[calc(100dvw-40px)]'>
				{type !== 'selector' ? (
					<FormControl
						fullWidth
						error={haveError}
						disabled={disabled}
					>
						<Controller
							control={control}
							name={fieldName}
							rules={{ required: true, maxLength: isUppercase ? 13 : 8 }}
							render={({ field }) => (
								<>
									<InputLabel
										size='small'
										id='demo-simple-select-label'
									>
										{label}
									</InputLabel>
									<OutlinedInput
										{...field}
										type={types[type]}
										size='small'
										label={label}
										autoComplete='off'
										value={
											isUppercase
												? field.value?.toUpperCase()
												: !!watch
													? value
													: field.value
										}
									/>
									{haveError && <FormHelperText>{errorMessage}</FormHelperText>}
								</>
							)}
						/>
					</FormControl>
				) : (
					<FormControl
						fullWidth
						error={haveError}
						disabled={disabled}
					>
						<Controller
							control={control}
							name={fieldName}
							rules={{ required: true }}
							render={({ field }) => (
								<>
									<InputLabel
										size='small'
										id='demo-simple-select-label'
									>
										{label}
									</InputLabel>
									<Select
										{...field}
										size='small'
										className='w-full'
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										label={label}
										value={!!watch ? value : field.value}
										onChange={(e) => {
											field.onChange(e);
											onChangeFn?.(e);
										}}
									>
										{options?.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</Select>
									{haveError && <FormHelperText>{errorMessage}</FormHelperText>}
								</>
							)}
						/>
					</FormControl>
				)}
			</div>
		</div>
	);
};

export default InvoiceFormField;
