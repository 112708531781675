import { createSlice } from '@reduxjs/toolkit';
import { ListProducts } from '../../@types/Products.type';
import { Acquisitions } from '../../@types/Acquisitions.type';

interface ProductsState {
	products: ListProducts[];
	acquisitions: Acquisitions[];
}

const initialState: ProductsState = {
	products: [],
	acquisitions: [],
};

export const productsSlice = createSlice({
	name: 'productList',
	initialState,
	reducers: {
		addProducts: (state, action) => {
			state.products = action.payload;
		},
		addAcquisitions: (state, action) => {
			state.acquisitions = action.payload;
		},
	},
});

export const { addProducts, addAcquisitions } = productsSlice.actions;

export default productsSlice.reducer;