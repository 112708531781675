import React, { useEffect, useState } from 'react';
import Text from '../../atoms/Text';
import Modal from '../Modal/Modal';
import {
	CaretDoubleLeft,
	CaretDoubleRight,
	CaretDown,
	Trash,
} from '@phosphor-icons/react';
import { monthsArray } from '../../../shared/constants/dates';
import Button from '../../atoms/Button';
import { DATES } from '../../../shared/utils/dates';
import { getMonthName } from '../../../shared/utils/dates.util';

type Props = {
	selectedPeriod: {
		year: number;
		month: string | null;
		confirmed: boolean;
	};
	setSelectedPeriod: React.Dispatch<
		React.SetStateAction<{
			year: number;
			month: string | null;
			confirmed: boolean;
		}>
	>;
};

const DatePicker: React.FC<Props> = ({ setSelectedPeriod, selectedPeriod }) => {
	const [open, setOpen] = useState(false);

	const isSelectableMonth = (month: string) => {
		if (selectedPeriod.year === DATES.currentYear) {
			return Number(month) <= DATES.currentMonth;
		}

		return true;
	};

	const handleSelectMonth = (month: string) => {
		if (!isSelectableMonth(month)) return;

		setSelectedPeriod({
			...selectedPeriod,
			year: selectedPeriod.year,
			month,
		});
	};

	const handleSelectYear = (year: number) => {
		if (year < DATES.currentYear - 5 || year > DATES.currentYear) return;

		setSelectedPeriod({
			...selectedPeriod,
			year,
			month: null,
		});
	};

	const handleClose = () => {
		setOpen(false);

		if (selectedPeriod.confirmed) return;

		setSelectedPeriod({
			...selectedPeriod,
			month: null,
		});
	};

	const handleConfirmPeriod = () => () => {
		setOpen(false);
		setSelectedPeriod({
			...selectedPeriod,
			confirmed: true,
		});
	};

	const handleRemovePeriod = () => {
		setSelectedPeriod({
			...selectedPeriod,
			month: null,
			confirmed: false,
		});
	};

	useEffect(() => {
		if (open) {
			setSelectedPeriod({
				year: DATES.currentYear,
				month: null,
				confirmed: false,
			});
		}
	}, [open]);

	return (
		<>
			<div className='cursor-pointer'>
				<Text
					size={'caption'}
					weight='regular'
					sx='opacity-70'
				>
					Periodo
				</Text>
				{selectedPeriod.confirmed ? (
					<div
						className='border border-h-neutral-80 rounded-lg flex items-center justify-between gap-2 h-10 px-2 hover:border-h-neutral-50'
						onClick={handleRemovePeriod}
					>
						<Text
							size={'body-3'}
							weight='regular'
						>
							{`${getMonthName(Number(selectedPeriod?.month), true)} ${selectedPeriod.year}`}
						</Text>
						<Trash
							width={22}
							color={'var(--h-neutral-40)'}
						/>
					</div>
				) : (
					<div
						className='border border-h-neutral-80 rounded-lg flex items-center justify-between gap-2 h-10 px-2 hover:border-h-neutral-50'
						onClick={() => setOpen(true)}
					>
						<Text
							size={'body-3'}
							weight='regular'
						>
							Selecciona un periodo
						</Text>
						<CaretDown
							weight={'fill'}
							width={12}
							color={'var(--h-neutral-40)'}
						/>
					</div>
				)}
			</div>
			<Modal
				showCloseButton
				open={open}
				onClose={handleClose}
				sx='overflow-y-auto w-[90%] max-w-[360px]'
			>
				<Text
					size={'subtitle'}
					weight='bold'
					color='medium'
				>
					Selecciona una fecha
				</Text>
				<div className='flex justify-between items-center my-6 cursor-pointer'>
					<button
						onClick={() => handleSelectYear(selectedPeriod.year - 1)}
						className='p-2'
					>
						<CaretDoubleLeft
							width={20}
							height={20}
						/>
					</button>
					<Text
						size={'body-3'}
						weight='bold'
						color='medium'
					>
						{selectedPeriod.year}
					</Text>
					<button
						onClick={() => handleSelectYear(selectedPeriod.year + 1)}
						className='p-2'
					>
						<CaretDoubleRight
							width={20}
							height={20}
						/>
					</button>
				</div>
				<div className='grid grid-cols-3 gap-1'>
					{monthsArray.map((month, index) => (
						<button
							disabled={!isSelectableMonth(month.value)}
							key={`month-index-${index}`}
							className={`py-2 px-2 bg-h-neutral-95 border border-h-neutral-90 rounded-md 
                            flex items-center justify-center cursor-pointer transition 
                            ${selectedPeriod.month === month.value ? 'bg-h-primary-90 border-h-primary-80' : 'hover:bg-h-neutral-90 hover:border-h-neutral-80'}
							${isSelectableMonth(month.value) ? '' : 'opacity-40 cursor-not-allowed hover:bg-h-neutral-95 hover:border-h-neutral-90'}
							`}
							onClick={() => handleSelectMonth(month.value)}
						>
							<Text size={'caption'}>{month.label}</Text>
						</button>
					))}
				</div>
				<Button
					disabled={!selectedPeriod.month}
					label='Seleccionar periodo'
					sx='mt-6'
					onClick={handleConfirmPeriod()}
				/>
			</Modal>
		</>
	);
};

export default DatePicker;
