import { Controller, useForm } from 'react-hook-form';
import Text from '../../atoms/Text';
import { useState } from 'react';
import { Pencil, Upload, XCircle } from '@phosphor-icons/react';
interface Props {
	name: string;
	control?: any;
	accept?: string;
	rules?: object;
	errorMessage?: string;
	label?: string;
	sx?: string;
}

const InputFile = ({
	name,
	control,
	accept,
	rules,
	errorMessage,
	label,
	sx
}: Props) => {
	const [file, setFile] = useState<File>();
	const { control: defaultControl } = useForm();

	return (
		<Controller
			control={control || defaultControl}
			rules={rules}
			name={name}
			render={({ field: { onChange, value } }) => (
				<div>
					<label
						htmlFor={name}
						className={`cursor-pointer border-h-gray-20 px-4 py-2 border rounded flex flex-row items-center gap-2 justify-between ${
							file
								? 'border-dashed !border-h-blue-20'
								: errorMessage
								? 'border-h-red-40'
								: 'border-solid'
						}`}
					>
						<div className={`flex flex-row items-center gap-x-2 truncate ${sx}`}>
							{file ? (
								<Upload
									size={24}
									weight='fill'
									color='var(--h-primary)'
								/>
							) : (
								<Upload size={24} />
							)}
							<Text
								size='s'
								weight='bold'
								color={file ? 'primary' : 'dark'}
								sx='truncate'
							>
								{file ? file.name : label}
							</Text>
							<input
								id={name}
								className='hidden'
								value={value?.fileName}
								onChange={(event) => {
									setFile(event?.target?.files?.[0]);
									onChange(event?.target?.files?.[0]);
								}}
								type='file'
								accept={accept}
							/>
						</div>
						{file && (
							<Pencil
								size={20}
								color='var(--h-primary)'
							/>
						)}
					</label>
					<div className='flex flex-row items-center gap-x-1 mt-1'>
						{!!errorMessage && (
							<XCircle
								size={18}
								weight='fill'
								color='var(--h-red-50)'
							/>
						)}
						<Text
							size='xs'
							sx='text-h-red-40'
						>
							{errorMessage}
						</Text>
					</div>
				</div>
			)}
		/>
	);
};

export default InputFile;
