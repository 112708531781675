import React from 'react';

import { ArrowRight } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

//? Types
import {
	SubscriptionStatus,
	SubscriptionType,
} from '../../../../@types/Subscription';

//? Components
import SubCard from '../../../mySubscriptions/SubCard';
import Button from '../../../../components/atoms/Button';
import HomeCard from '../../../../components/molecules/HomeCard';

//? Utils
import ROUTES from '../../../../shared/constants/routes';
interface Props {
	activeSuscriptions: SubscriptionType[];
	subscriptions: SubscriptionType[];
	fetchUserSubscriptions: () => void;
}

const SingleTop: React.FC<Props> = ({
	activeSuscriptions,
	subscriptions,
	fetchUserSubscriptions,
}) => {
	const navigate = useNavigate();

	const getSubscriptionByStatus = () => {
		if (activeSuscriptions.length > 0) {
			const activeSubs = activeSuscriptions.filter(
				(item) => item.payment_status === SubscriptionStatus.failed,
			);
			return [activeSubs?.length ? activeSubs[0] : activeSuscriptions[0]];
		} else {
			return [subscriptions[0]];
		}
	};

	return (
		<div className='flex flex-col bg-white w-full rounded-md mt-6 md:mt-0 mb-6 md:mb-10 shadow-md'>
			<HomeCard
				title={`${subscriptions.length > 1 ? 'Suscripciones' : 'Suscripción '}`}
				showButton={false}
				headerButton={
					<>
						{subscriptions.length > 1 && (
							<Button
								onClick={() => {
									navigate(ROUTES.MY_SUBSCRIPTIONS);
								}}
								type='button'
								label={'Ver todas mis suscripciones'}
								variant='text'
								icon={<ArrowRight size={20} />}
								labelColor='primary'
							/>
						)}
					</>
				}
			>
				<div className='mt-6'>
					{getSubscriptionByStatus().map(
						(subscription: SubscriptionType, index: number) => (
							<SubCard
								fetchUserSubscriptions={fetchUserSubscriptions}
								item={subscription}
								key={index}
							/>
						),
					)}
				</div>
			</HomeCard>
		</div>
	);
};

export default React.memo(SingleTop);
