import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CaretCircleLeft, CircleNotch } from '@phosphor-icons/react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

//? Types
import { DELETE_IDS, Item, Order } from '../../@types/Order.type';
import { PAYMENT_METHOD, PLATFORM_CATALOG } from '../../@types/Checkout.type';
import { IPaymentInfo, ProcessV2Payment } from '../../@types/Payments.types';

//? Components
import Text from '../../components/atoms/Text';
import PaymentMethodPayPal from './PaymentMethodPayPal';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import Button from '../../components/atoms/Button';
import Coupon from './components/molecules/Coupon';
import Heading from '../../components/atoms/Heading';
import OrderSummary from './components/organisms/OrderSummary';
import SelectPaymentMethod from './components/organisms/SelectPaymentMethod';

//? Services
import {
	deleteAllItemCart,
	deleteItem,
	getOrders,
	postAddProducts,
	postAddProductsBulk,
	postOrders,
	putOrder,
} from '../../services/order.service';
import { postCheckoutProcessPayPal } from '../../services/checkout.service';
import {
	getUserConekta,
	postUserConekta,
} from '../../services/conekta.service';
import {
	createNewCustomer,
	getClientCustomer,
	postShoppingCartProcess,
} from '../../services/payments.service';

//? Utils
import ROUTES from '../../shared/constants/routes';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';

//? Redux
import { RootState } from '../../redux/store';
import { showAlert } from '../../redux/slices/alert.slice';
import Modal from '../../components/molecules/Modal/Modal';
import {
	addProductToCart,
	clearCart,
	updateAmountOfItems,
} from '../../redux/slices/cart.slice';
import { getProducts } from '../../services/plans.service';
import { addProducts } from '../../redux/slices/products.slice';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import {
	acceptDuplicateProductHandler,
	getProductIdBySlug,
	getProductProdByProductVersion,
	getProductProdBySlug,
	isDuplicateProductHandler,
} from '../../shared/utils/ecommerce.util';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import {
	formatPrice,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import { APP_CONFIGURATIONS } from '../../shared/constants/configurations';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../../shared/utils/products.utils';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import growthbookFile from '../../shared/constants/growthbook';
import { ProductFFT, Products, RegimeFFT } from '../../@types/Products.type';
import { PRODUCTS_SLUG } from '../../shared/constants/SlugsProducts';
import { REGIMES_CODE } from '../../@types/Regime.type';
import { PlatformOS } from '../../redux/slices/profile.slice';
interface PayloadCheckout {
	payment_method: PAYMENT_METHOD;
	source_channel: string;
	additional_information?: any;
}

const Checkout: React.FC = () => {
	const paymentsRef = useRef(null);

	const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(
		PAYMENT_METHOD.CARD,
	);
	const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>();
	const [order, setOrder] = useState<Order[]>([]);

	const [isLoadOrder, setIsLoadOrder] = useState<boolean>(true);
	const [loadSelectPaymentMethod, setLoadSelectPaymentMethod] =
		useState<boolean>(false);
	const [isLoadCoupon, setIsLoadCoupon] = useState<boolean>(false);
	const [isLoadCheckout, setIsLoadCheckout] = useState<boolean>(false);
	const [isLoadDeleteItem, setIsLoadDeleteItem] = useState<boolean>(false);
	const [customerStripeId, setCustomerStripeId] = useState('');
	const [isLoadingProducts, setIsLoadingProducts] = useState(false);
	const [viewModal, setViewModal] = useState<boolean>(false);
	const [confrimPayment, setConfrimPayment] = useState(false);
	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logClevertapEvent = useCleverTapEvent();

	const { products } = useSelector((state: RootState) => state.productList);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const {
		utmInfo,
		products: productCart,
		couponCode,
		additionalInformation,
	} = useSelector((state: RootState) => state.cart);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const platformOS = useSelector(
		(state: RootState) => state.profile.platformOS,
	);
	const queryParams = new URLSearchParams(location.search);
	const [searchParams] = useSearchParams();
	const channelApp = searchParams.get('platform_os');
	const channelSource = searchParams.get('channel_source');

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	useEffect(() => {
		if (!!userProfile?.id) {
			loadGrowthbook();
			growthbookFile?.setAttributes({
				user_id: userProfile?.id,
			});
		}
	}, [userProfile?.id]);

	const getOrder = useCallback(async () => {
		try {
			const response = await getOrders('false', paymentMethod, tokenSession);
			setOrder(response);
			setIsLoadOrder(false);
			setLoadSelectPaymentMethod(true);
			setIsLoadDeleteItem(false);
			dispatch(updateAmountOfItems(response?.[0]?.items?.length || 0));
			setIsLoadingProducts(false);
			if (response.length === 0) {
				createOrder();
			}
		} catch (error) {}
	}, [paymentMethod]);

	const createOrder = useCallback(async () => {
		try {
			let sourceChannel = 'web';
			if (
				channelSource === 'app' ||
				platformOS == PlatformOS.IOS ||
				PlatformOS.ANDROID
			) {
				sourceChannel = platformOS ?? 'app';
			}
			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);
			if (response) {
				setOrder([response]);
			}
		} catch (error) {}
	}, []);

	const putCoupon = useCallback(
		async (body: { coupon_code: string }, orderId: number) => {
			try {
				await putOrder(orderId, body, tokenSession);
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: 'Cupón aplicado',
				};
				handlePostAddedCupon();
				dispatch(showAlert(alert));
				setIsLoadCoupon(false);
				getOrder();
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_COUPON_VALIDATE,
					success: true,
					coupon: body.coupon_code,
				});
			} catch (error: any) {
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_COUPON_VALIDATE,
					success: false,
					coupon: body.coupon_code,
				});
				setIsLoadCoupon(false);
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description:
						error?.response?.data?.message ?? 'Error, intente más tarde',
				};
				setIsLoadCheckout(false);
				dispatch(showAlert(alert));
			}
		},
		[],
	);

	const delItem = useCallback(
		async (item: number, orderId: number, verifyQueryParam?: boolean) => {
			try {
				const response = await deleteItem(orderId, item, tokenSession);
				if (verifyQueryParam) {
					//eliminar el query param para evitar reagregarlo
					queryParams.delete('product');
					navigate(`?${queryParams.toString()}`, { replace: true });
				}
				if (response) {
					const alert = {
						type: ALERT_TYPE.SUCCESS,
						title: 'Aviso',
						description: 'Producto eliminado',
					};
					dispatch(showAlert(alert));
					getOrder();
				}
			} catch (error) {
				setIsLoadDeleteItem(false);
			}
		},
		[order],
	);

	const checkout = useCallback(
		async (
			orderId: number,
			payloadCheckout: PayloadCheckout,
			order?: Order,
		) => {
			try {
				let response: ProcessV2Payment;
				if (
					payloadCheckout.payment_method === PAYMENT_METHOD.CARD &&
					paymentInfo?.platform !== 'CONEKTA'
				) {
					setIsLoadCheckout(false);
					setConfrimPayment(true); //? Call confrim payment stripe widget

					return;
				} else {
					if (paymentInfo?.platform === 'CONEKTA') {
						response = await postShoppingCartProcess({
							tokenSession,
							cartId: orderId,
							tokenId: paymentInfo.token,
							renewSubscription: false,
							additionalInformation: payloadCheckout.additional_information,
							sourceChannel: payloadCheckout.source_channel,
							paymentMethod: PAYMENT_METHOD.CARD,
							platformId: PLATFORM_CATALOG.CONEKTA,
						});
					} else {
						response = await postShoppingCartProcess({
							tokenSession,
							cartId: orderId,
							renewSubscription: false,
							additionalInformation: payloadCheckout.additional_information,
							sourceChannel: payloadCheckout.source_channel,
							paymentMethod: payloadCheckout.payment_method,
							platformId: PLATFORM_CATALOG.CONEKTA,
							tokenId: '',
						});
					}
				}

				setIsLoadCheckout(false);

				const items = order?.items
					.map((item) => `description=${item.product_version.description}`)
					.join('&');
				const prices = order?.items
					.map((item) => `price=${item.total}`)
					.join('&');
				const total = order?.total || 0;
				const urlParams = new URLSearchParams();
				urlParams.append('total', total.toString());
				urlParams.append('items', items!);
				urlParams.append('prices', prices!);
				urlParams.append(
					'paymentMethod',
					total === 0 ? PAYMENT_METHOD.TRADITIONAL_CASH : paymentMethod,
				);
				urlParams.append('orderId', orderId.toString()!);
				urlParams.append('tax', order?.taxes?.toString()!);
				urlParams.append('coupon', order?.coupon?.code ?? '');

				if (response.transaction.reference) {
					urlParams.append('reference', response.transaction.reference);
				}

				const sucessUrlPage = `${
					ROUTES.SUCCESSFUL_PURCHASE
				}?${urlParams.toString()}`;

				if (
					(response?.status === 'processed' &&
						response?.transaction?.succeed) ||
					response?.status === 'pending'
				) {
					const alert = {
						type: ALERT_TYPE.SUCCESS,
						title: 'Aviso',
						description: 'Compra realizada',
					};
					dispatch(showAlert(alert));
					navigate(sucessUrlPage, {
						replace: true,
					});
				} else {
					const alert = {
						type: ALERT_TYPE.ERROR,
						title: 'Aviso',
						description:
							response.transaction?.failure_reason ??
							'Ocurrió un error con tu pago',
					};
					dispatch(showAlert(alert));
					const eventProperties = {
						event_type: EVENT_TYPES.PLANS_CHARGED,
						Amount: formatPrice(parseInt(total.toString())),
						'Charged ID': order?.id?.toString() ?? '',
						'Payment mode': payloadCheckout.payment_method,
						coupon: order?.coupon?.code ?? '',
					};
					logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
				}
			} catch (error) {
				setIsLoadCheckout(false);
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: 'Error al realizar la compra, intente más tarde',
				};
				dispatch(showAlert(alert));
				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_CHARGED,
					Amount: formatPrice(parseInt(order?.total?.toString() ?? '0')),
					'Charged ID': order?.id?.toString() ?? '',
					'Payment mode': payloadCheckout.payment_method,
					coupon: order?.coupon?.code ?? '',
				};
				logClevertapEvent(ANALYTICS_EVENTS.FAIL_CHARGED, eventProperties);
			}
		},
		[paymentInfo, paymentMethod],
	);

	const checkoutPayPal = useCallback(
		async (orderId: number, payloadCheckout: object, order?: Order) => {
			try {
				const response = await postCheckoutProcessPayPal(
					orderId,
					payloadCheckout,
					tokenSession,
				);
				if (
					response?.status === 'processed' &&
					response?.transaction?.succeed
				) {
					setIsLoadCheckout(false);
					handleCloseModal();
					const alert = {
						type: ALERT_TYPE.SUCCESS,
						title: 'Aviso',
						description: 'Compra realizada',
					};
					dispatch(showAlert(alert));
					const items = order?.items
						.map(
							(item: Item) => `description=${item.product_version.description}`,
						)
						.join('&');
					const prices = order?.items
						.map((item: Item) => `price=${item.total}`)
						.join('&');
					const total = response?.total || 0;
					const urlParams = new URLSearchParams();
					urlParams.append('total', total?.toString());
					urlParams.append('items', items!);
					urlParams.append('prices', prices!);
					urlParams.append('paymentMethod', 'PAYPAL');
					urlParams.append('orderId', orderId?.toString());
					urlParams.append(
						'tax',
						order?.taxes ? order?.taxes.toString() : '0',
					);
					urlParams.append('coupon', order?.coupon?.code ?? '');
					const url = `${ROUTES.SUCCESSFUL_PURCHASE}?${urlParams.toString()}`;
					navigate(url, {
						replace: true,
					});
				} else {
					setIsLoadCheckout(false);
					const alert = {
						type: ALERT_TYPE.ERROR,
						title: 'Aviso',
						description: response.transaction.message,
					};
					dispatch(showAlert(alert));
				}
			} catch (error) {
				setIsLoadCheckout(false);
			}
		},
		[],
	);

	const create_UserConekta = useCallback(async () => {
		try {
			await postUserConekta(
				{
					platform_id: 1,
				},
				tokenSession,
			);
		} catch (error: any) {}
	}, []);

	const get_UserConekta = useCallback(async () => {
		try {
			await getUserConekta(tokenSession);
		} catch (error: any) {
			if (error.response.status === 404) {
				create_UserConekta();
			}
		}
	}, []);

	const getCustomer = useCallback(async () => {
		try {
			const response = await getClientCustomer(tokenSession);
			if (response) {
				setCustomerStripeId(response[0].id ?? '');
			}
		} catch (error: any) {
			if (error?.response?.status === 404) {
				const response = await createNewCustomer(tokenSession);
				if (response) {
					setCustomerStripeId(response.id ?? '');
				}
				return;
			}
		}
	}, [order]);

	const postAddProduct = useCallback(async (id: number, orderId: number) => {
		try {
			const body = {
				product_version_id: id,
			};
			await postAddProducts(orderId, body, tokenSession);
			getOrder();
		} catch (error) {}
	}, []);

	const purchaseHandler = () => {
		setIsLoadCheckout(true);

		if (paymentMethod === PAYMENT_METHOD.PAYPAL && order[0]?.total > 0) {
			setViewModal(true);
			return;
		}
		const paymentMethodSelected =
			order[0]?.total === 0 ? PAYMENT_METHOD.TRADITIONAL_CASH : paymentMethod;

		const isSessionAvailable = isSessionStorageEnabled();
		const utmCampaign = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
			: utmInfo?.utm_campaign;
		const utmSource = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
			: utmInfo?.utm_source;
		const utmMedium = isSessionAvailable
			? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
			: utmInfo?.utm_medium;

		const additionalInformation = {
			utms: {
				utm_campaign: utmCampaign ?? 'organic',
				utm_source: utmSource ?? 'organic',
				utm_medium: utmMedium ?? 'organic',
			},
		};
		let sourceChannel = 'web';
		if (
			channelSource === 'app' ||
			platformOS == PlatformOS.IOS ||
			PlatformOS.ANDROID
		) {
			sourceChannel = channelApp ?? platformOS ?? 'app';
		}

		checkout(
			order[0]?.id,
			{
				payment_method: paymentMethodSelected,
				source_channel: sourceChannel,
				additional_information: additionalInformation,
			},
			order[0],
		);
	};

	const scrollToBottom = () => {
		if (paymentsRef.current) {
			// @ts-ignore
			paymentsRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		}
	};

	const isPaymentMethodReady = useMemo(() => {
		if (paymentMethod === PAYMENT_METHOD.CARD && !paymentInfo) {
			return false;
		}
		return true;
	}, [paymentMethod, paymentInfo]);

	const addCoupon = (coupon: string) => {
		setIsLoadCoupon(true);
		const body = {
			coupon_code: order[0]?.coupon?.code ? '' : coupon,
		};
		putCoupon(body, order[0]?.id);
	};

	const handleItemDelete = (itemId: number) => {
		setIsLoadDeleteItem(true);
		delItem(itemId, order[0]?.id, true);
	};

	const onDeleteCartItems = async (items: DELETE_IDS[]) => {
		try {
			setIsLoadDeleteItem(true);
			const response = await deleteAllItemCart(
				items,
				order[0]?.id,
				tokenSession,
			);
			if (response) {
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: 'Producto eliminado',
				};
				dispatch(showAlert(alert));
				getOrder();
			}
			setIsLoadDeleteItem(false);
		} catch (error) {
			setIsLoadDeleteItem(false);
		}
	};

	const selectPaymentMethod = (
		paymentMethod: PAYMENT_METHOD,
		paymentInfo?: IPaymentInfo,
	) => {
		logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
			event_type: EVENT_TYPES.CHECKOUT_SELECT_PAYMENT_METHOD,
			payment_method: paymentMethod,
		});
		setPaymentMethod(paymentMethod);
		if (!!paymentInfo) {
			setPaymentInfo(paymentInfo);
		}
	};

	const purchasePayPal = (orderData: any) => {
		let sourceChannel = 'web';
		if (
			channelSource === 'app' ||
			platformOS == PlatformOS.IOS ||
			PlatformOS.ANDROID
		) {
			sourceChannel = channelApp ?? platformOS ?? 'app';
		}
		checkoutPayPal(order[0]?.id, {
			payment_method: PAYMENT_METHOD.PAYPAL,
			payment: orderData,
			platform_id: PLATFORM_CATALOG.PAYPAL,
			source_channel: sourceChannel,
		}, order[0]);
	};

	const handleCloseModal = () => {
		setViewModal(false);
		setIsLoadCheckout(false);
	};

	useEffect(() => {
		// Obtener el ID del producto del query parameter en la URL
		const productId = queryParams.get('product');

		if (productId && order?.length > 0) {
			const existingProduct = order?.find((product) =>
				product?.items
					.map((item) => item?.product_version?.id)
					.includes(parseInt(productId, 10)),
			);
			if (!existingProduct) {
				// Lógica para agregar el producto a la orden usando product_version.id
				postAddProduct(parseInt(productId, 10), order[0]?.id);
			}
		}
	}, [order]);

	useEffect(() => {
		getOrder();
	}, [paymentMethod]);

	useEffect(() => {
		if (order?.length > 0) {
			getCustomer();
		}
	}, [order?.[0]?.user_id]);

	useEffect(() => {
		get_UserConekta();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.CHECKOUT,
		});
	}, []);

	useEffect(() => {
		if (order[0]?.coupon?.code === 'NOUSARQA') {
			setPaymentMethod(PAYMENT_METHOD.TRADITIONAL_CASH);
		} else if (order[0]?.total === 0) {
			setPaymentMethod(PAYMENT_METHOD.TRADITIONAL_CASH);
		}
	}, [paymentMethod, order[0]?.coupon?.code]);

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			dispatch(addProducts(data));
		} catch (error) {}
	}, []);

	useEffect(() => {
		if (products.length === 0) {
			getAllProducts();
		}
	}, [products]);

	const validateOrder = () => {
		if (order.length === 0 || !order?.[0]?.id || !products.length) {
			return;
		}
		onAddProductToCart();
	};

	useEffect(() => {
		validateOrder();
	}, [order, products]);

	const btnLabel = useMemo(() => {
		if (PAYMENT_METHOD.CASH === paymentMethod) {
			return 'Pagar con referencia';
		}
		if (PAYMENT_METHOD.SPEI === paymentMethod) {
			return 'Generar una referencia de pago';
		}
		return 'Pagar';
	}, [paymentMethod]);

	const cancelAddProductToCartHandler = () => {
		setIsLoadCheckout(false);
		if (isSessionStorageEnabled()) {
			sessionStorage.removeItem(SESSION_STORAGE_KEY.PRODUCTS_ADDED);
		} else {
			dispatch(addProductToCart(''));
		}
	};

	const handleGoBack = () => {
		const haveSomeParams = searchParams.toString();
		haveSomeParams ? navigate(-2) : navigate(-1);
	};

	const handlePostAddedCupon = () => {
		if (isSessionStorageEnabled()) {
			sessionStorage.removeItem(SESSION_STORAGE_KEY.CUPON_CODE_ADDED);
		} else {
			dispatch(clearCart());
		}
	};

	const onAddProductToCart = useCallback(async () => {
		const yearFromUrl = queryParams.get('year');

		try {
			if (!order?.[0]?.id) return;
			setIsLoadCheckout(true);
			let body: any[] = [];
			const isSessionAvailable = isSessionStorageEnabled();

			const productsFromStorage = isSessionAvailable
				? sessionStorage.getItem(SESSION_STORAGE_KEY.PRODUCTS_ADDED)
				: productCart ?? null;
			const cuponCodeFromStorage = isSessionAvailable
				? sessionStorage.getItem(SESSION_STORAGE_KEY.CUPON_CODE_ADDED)
				: couponCode ?? null;
			const yearFromStorage = isSessionAvailable
				? sessionStorage.getItem(SESSION_STORAGE_KEY.YEAR_ADDED)
				: additionalInformation?.year ?? '';

			if (!productsFromStorage) {
				if (cuponCodeFromStorage) {
					const body = {
						coupon_code: cuponCodeFromStorage,
					};
					putCoupon(body, order[0]?.id);
					return;
				}
				setIsLoadCheckout(false);
				return;
			}

			const productsAdded: string[] = JSON.parse(productsFromStorage);

			productsAdded?.forEach((slug) => {
				const isProductVersion = !isNaN(Number(slug));

				if (isProductVersion) {
					const _price =
						getElasticyPrice(
							getPriceFromProductVersionId(products, Number(slug)),
							elasticityPrice.group_experiment,
						) * 100;
					body.push({
						product_version_id: Number(slug),
						coupon_code: cuponCodeFromStorage || '',
						additional_information: {
							regimes: [
								Number(slug) === Products.ASATPlus
									? ProductFFT.Asistencia_ASAT
									: RegimeFFT[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS],
							],
						},
						custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
							? {
									// payload_user_id: userProfile?.id,
									group_experiment: elasticityPrice.group_experiment,
									total_paid: _price.toString(),
								}
							: undefined,
					});

					const productByUrl = getProductProdByProductVersion(
						products,
						Number(slug),
					);
					const eventProperties = {
						event_type: EVENT_TYPES.PLANS_ADD_TO_CART_COMM,
						product_version_id: Number(slug),
						product_name: productByUrl?.name ?? '',
						regime: productByUrl?.regime?.join('') ?? '',
						period: '',
					};
					logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);
					return;
				}
				//? Check if the product is already in the cart
				if (!acceptDuplicateProductHandler(products, slug)) {
					const isProductInCart = isDuplicateProductHandler(
						order[0].items,
						slug,
					);
					if (isProductInCart) return;
				}

				const productVersionId = getProductIdBySlug(products, slug);

				if (!productVersionId) return;

				const _price =
					getElasticyPrice(
						getPriceFromProductVersionId(products, productVersionId),
						elasticityPrice.group_experiment,
					) * 100;
				if (yearFromStorage || yearFromUrl) {
					body.push({
						product_version_id: productVersionId,
						coupon_code: cuponCodeFromStorage || '',
						additional_information: {
							year: yearFromStorage ?? yearFromUrl,
							regimes: [
								slug == PRODUCTS_SLUG.ASSISTANCE_ASAT
									? ProductFFT.Asistencia_ASAT
									: RegimeFFT[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS],
							],
						},
						custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
							? {
									group_experiment: elasticityPrice.group_experiment,
									total_paid: _price.toString(),
								}
							: undefined,
					});
				} else {
					body.push({
						product_version_id: productVersionId,
						coupon_code: cuponCodeFromStorage || '',
						additional_information: {
							regimes: [
								slug == PRODUCTS_SLUG.ASSISTANCE_ASAT
									? ProductFFT.Asistencia_ASAT
									: RegimeFFT[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS],
							],
						},
						custom_product: APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
							? {
									group_experiment: elasticityPrice.group_experiment,
									total_paid: _price.toString(),
								}
							: undefined,
					});
				}

				const prod = getProductProdBySlug(products, slug);

				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_ADD_TO_CART_COMM,
					product_name: prod?.name ?? '',
					regime: prod?.regime?.join('') ?? '',
					period: '',
				};
				logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);
			});

			if (body.length === 0) {
				cancelAddProductToCartHandler();
				return;
			}
			await postAddProductsBulk(order[0].id, body, tokenSession);
			setIsLoadingProducts(true);
			getOrder();

			if (isSessionStorageEnabled()) {
				sessionStorage.removeItem(SESSION_STORAGE_KEY.PRODUCTS_ADDED);
				sessionStorage.removeItem(SESSION_STORAGE_KEY.CUPON_CODE_ADDED);
				sessionStorage.removeItem(SESSION_STORAGE_KEY.YEAR_ADDED);
			} else {
				dispatch(clearCart());
			}
			setIsLoadCheckout(false);
		} catch (error: any | Error) {
			setIsLoadCheckout(false);
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Aviso',
				description: error?.response?.data?.message,
			};
			dispatch(showAlert(alert));
		}
	}, [order]);

	return (
		<>
			{isLoadOrder ? (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={30} />
				</div>
			) : (
				<div className='min-h-[calc(100svh-50px)] items-center justify-center'>
					<Modal
						sx='w-full md:w-[500px] h-[500px] overflow-y-auto'
						open={viewModal}
						onClose={handleCloseModal}
						type='fullscreen'
						children={
							<PaymentMethodPayPal
								order={order[0]}
								purchasePayPal={purchasePayPal}
							/>
						}
					/>
					<div className=' flex flex-col-reverse lg:flex-row'>
						<div className='border-r-2 flex-1 flex flex-col justify-start items-start p-4 sm:p-8 relative'>
							<Heading
								size='s'
								weight='bold'
								sx='mb-5 md:block hidden'
							>
								Tu compra
							</Heading>
							<div className='mb-7 w-full'>
								<Coupon
									order={order?.[0]}
									addCoupon={addCoupon}
									isLoadCoupon={isLoadCoupon}
								/>
							</div>
							<div className='mb-4 w-full md:pb-0'>
								{loadSelectPaymentMethod && (
									<SelectPaymentMethod
										scrollToBottom={scrollToBottom}
										order={order?.[0]}
										selectPaymentMethod={selectPaymentMethod}
										confirmPayment={confrimPayment}
										setConfrimPayment={setConfrimPayment}
										customerStripeId={customerStripeId}
									/>
								)}
							</div>
							<div ref={paymentsRef}></div>

							<div
								className={`fixed lg:absolute flex flex-col justify-end lg:bottom-0 lg:pl-7 bottom-12 md:bottom-0 right-0 bg-white shadow w-full py-4 px-4 lg:px-0`}
							>
								<div className='sm:px-[42px] mb-1.5'>
									<Button
										// loading={isLoadCheckout || confrimPayment}
										disabled={
											order?.[0]?.items?.length === 0 ||
											!isPaymentMethodReady ||
											isLoadDeleteItem ||
											isLoadCheckout ||
											confrimPayment
										}
										variant='primary'
										label={btnLabel}
										sx='h-[47px] hidden lg:block'
										onClick={purchaseHandler}
									/>
									<button
										onClick={purchaseHandler}
										disabled={
											order?.[0]?.items?.length === 0 ||
											!isPaymentMethodReady ||
											isLoadDeleteItem ||
											isLoadCheckout ||
											confrimPayment
										}
										className={`flex flex-row justify-center items-center lg:hidden bg-h-primary-40 w-full py-[14px] text-white rounded-lg ${
											isLoadCheckout || confrimPayment ? 'opacity-70' : ''
										}`}
									>
										{(isLoadCheckout || confrimPayment) && (
											<div className='animate-spin mr-2'>
												<CircleNotch
													size={22}
													color={'white'}
													weight='bold'
												/>
											</div>
										)}
										<span>{btnLabel}</span>
										<span className='bg-h-primary-10 px-6 py-[6px] ml-10 rounded-md'>{`$${formatPrice(
											order?.[0]?.total ?? 0,
										)}`}</span>
									</button>
								</div>
							</div>
						</div>
						<div className=' flex-1 flex flex-col justify-start items-start lg:items-end p-4 sm:p-8'>
							<div className='flex flex-row items-center mb-6 md:hidden'>
								<button onClick={handleGoBack}>
									<CaretCircleLeft
										size={20}
										weight='fill'
										className='mr-3'
										color='var(--h-primary)'
									/>
								</button>
								<Text
									size='l'
									color='dark'
									weight='bold'
								>
									Tu compra
								</Text>
							</div>
							<Heading
								size='s'
								weight='bold'
								sx='mb-5 md:block hidden'
							>
								Resumen de tu compra
							</Heading>
							<Text
								size='l'
								weight='bold'
								sx='mb-5 md:hidden block'
							>
								Resumen de tu compra
							</Text>
							<div className='w-full'>
								<OrderSummary
									order={order?.[0]}
									isLoad={isLoadOrder || isLoadingProducts}
									isLoadDeleteItem={isLoadDeleteItem}
									handleItemDelete={handleItemDelete}
									onDeleteCartItems={onDeleteCartItems}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Checkout;
