import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getMonthName } from '../../../shared/utils/dates.util';
import Text from '../../../components/atoms/Text';
import Tag from '../../../components/molecules/Tag';
import CALENDAR from '../../../shared/constants/calendar';
import TagIconCalendar from '../atoms/TagIconCalendar';
import Divider from '../../../components/atoms/Divider';
import Button from '../../../components/atoms/Button';
import { CaretCircleLeft, XCircle } from '@phosphor-icons/react';

import MonthlyDetail from './MonthlyDetail';
import AnnualDetail from './AnnualDetail';
import HelpDropdown from '../atoms/HelpDropdown';
import { fetchDeclarationDetailV1 } from '../../../services/taxes.service';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import { setSelectedPeriod } from '../../../redux/slices/taxCalendar.slice';
import useAddToCart from '../../../hooks/useAddToCart';
import { REGULARIZATION_REGARATION_PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { useState } from 'react';
import { TPeriodicity } from '../../../@types/Calendar.type';
import { CONFIG_WHATSAPP_SUPPORT } from '../../../shared/constants/whatsappNumbers';
import { REGIMES_CODE, REGIMES_NAME } from '../../../@types/Regime.type';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { useMediaQuery } from '@mui/material';
import Container from '../../../components/atoms/Container';
import { UserRegime } from '../../../@types/TaxProfile';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { RegimeFFT } from '../../../@types/Products.type';

interface Props {}

const PeriodDetail: React.FC<Props> = ({}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const [isLoading, setIsLoading] = useState({
		detail: false,
	});

	const isMobile = useMediaQuery('(max-width: 1024px)');

	const { handleAddProductBySlug, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const {
		month,
		year,
		period,
		annualYearSelected,
		selectedPeriod,
		statusByPriotityMonth,
	} = useSelector((state: RootState) => state.taxCalendar);
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const periodTagStatus = () => {
		if (period === TPeriodicity.MONTHLY) {
			return (
				<Tag
					label={CALENDAR.taxboardDeclarationStatusLabel[statusByPriotityMonth]}
					type='filled'
					caseVariant={CALENDAR.labelColor[statusByPriotityMonth] ?? 'filter'}
					iconRight={<TagIconCalendar status={statusByPriotityMonth} />}
					quantity={1}
				/>
			);
		}

		const annualStatus = selectedPeriod?.year?.selectedYear?.status;
		return (
			<Tag
				label={CALENDAR.taxboardDeclarationStatusLabel[annualStatus]}
				type='filled'
				caseVariant={CALENDAR.labelColor[annualStatus] ?? 'filter'}
				iconRight={<TagIconCalendar status={annualStatus} />}
				quantity={1}
			/>
		);
	};

	const handleAddToCart = async (regime?: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_ACQUIRE_PERIOD,
			source: 'declaraciones_mensuales',
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		const slugsPerRegime: Record<string, string> = {
			'Plataformas Tecnológicas':
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
			business: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
			resico: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
			lease: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
			rif: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
		};

		await handleAddProductBySlug(
			slugsPerRegime[regime || 'Plataformas Tecnológicas'] ||
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
			{
				year: year,
				month: month,
				regimes: [RegimeFFT[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]],
			},
		);
	};

	const handleCloseDetail = () => {
		dispatch(
			setSelectedPeriod({
				year: {
					...selectedPeriod.year,
					selectedYear: null,
					selectedDeclaration: null,
				},
				month: {
					...selectedPeriod.month,
					detail: null,
					detailByRegime: null,
				},
			}),
		);
	};

	const getDeclarationDetailByID = async (declaration_id: number) => {
		if (!declaration_id || isLoading.detail) return null;

		try {
			setIsLoading({ ...isLoading, detail: true });
			const response = await fetchDeclarationDetailV1(declaration_id);
			return response;
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Error al obtener la información',
				description: 'Vuelve a intentarlo más tarde',
				duration: 2000,
			};
			dispatch(showAlert(alert));
			return null;
		} finally {
			setIsLoading({ ...isLoading, detail: false });
		}
	};

	const handleViewDetail = async (
		declaration_id: number,
		declarationPresentedDate?: string,
	) => {
		const detail = await getDeclarationDetailByID(declaration_id);

		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_PERIOD_DETAIL_VIEWED,
			tax_detail: `${detail?.month}/${detail?.year}`,
		});

		if (period === TPeriodicity.MONTHLY) {
			dispatch(
				setSelectedPeriod({
					...selectedPeriod,
					month: {
						...selectedPeriod.month,
						detailByRegime: {
							...detail,
							declarationPresentedDate,
						},
					},
				}),
			);
		} else {
			dispatch(
				setSelectedPeriod({
					...selectedPeriod,
					year: {
						...selectedPeriod.year,
						selectedDeclaration: detail,
					},
				}),
			);
		}

		if (isMobile) {
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: 'smooth',
			});
		}
	};

	const handleClickWhatsapp = () => {
		const whatsappUrl = `${CONFIG_WHATSAPP_SUPPORT}`;
		window.open(whatsappUrl, '_blank');
	};

	const getOtherRegimes = () => {
		const regimesCodeToName: Record<string, string> = {
			[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]:
				REGIMES_NAME.PLATAFORMAS_TECNOLOGICAS,
			[REGIMES_CODE.SUELDOS_Y_SALARIOS]: REGIMES_NAME.SUELDOS_Y_SALARIOS,
			[REGIMES_CODE.RESICO]: REGIMES_NAME.RESICO,
			[REGIMES_CODE.ARRENDAMIENTO]: REGIMES_NAME.ARRENDAMIENTO,
			[REGIMES_CODE.ACTIVIDAD_EMPRESARIAL]: REGIMES_NAME.ACTIVIDAD_EMPRESARIAL,
			[REGIMES_CODE.RIF]: REGIMES_NAME.RIF,
		};

		const otherRegimes = userRegimes
			?.map((regime) => {
				return {
					name: regimesCodeToName?.[regime?.code],
					code: regime?.code,
				};
			})
			?.filter(
				(regime) =>
					regime?.code !== REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS &&
					regime?.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
			);

		const uniqueRegimes = otherRegimes?.filter(
			(regime, index, self) =>
				index ===
				self?.findIndex((selfElement) => selfElement?.code === regime?.code),
		);

		return uniqueRegimes;
	};

	return (
		<>
			{isMobile && (
				<button
					onClick={handleCloseDetail}
					className='flex items-center gap-1'
				>
					<CaretCircleLeft
						size={20}
						weight='fill'
						color='var(--h-primary)'
					/>
					<Text
						size='caption'
						color='medium'
						weight='medium'
					>
						Volver al calendario
					</Text>
				</button>
			)}
			<Container sx={`flex-1 !p-0 lg:!p-4`}>
				<section className='bg-h-neutral-95 p-4 rounded-lg shadow-md h-full flex flex-col justify-between'>
					<div>
						<div className='flex flex-row justify-between mb-4 items-center gap-2'>
							<div className='inline-flex gap-2'>
								{period === TPeriodicity.MONTHLY && (
									<Text
										size='body-1'
										color='medium'
										weight='bold'
										sx='capitalize'
									>
										{getMonthName(month)}
									</Text>
								)}
								<Text
									size={period === TPeriodicity.MONTHLY ? 'caption' : 'body-1'}
									color={period === TPeriodicity.MONTHLY ? 'light' : 'medium'}
									weight={period === TPeriodicity.MONTHLY ? 'medium' : 'bold'}
									sx='mr-4'
								>
									{period === TPeriodicity.YEARLY ? annualYearSelected : year}
								</Text>

								{periodTagStatus()}
							</div>
							{!isMobile && (
								<Button
									onClick={handleCloseDetail}
									label=''
									variant='text'
									labelColor='gray'
									labelSize='caption'
									beforeIcon={
										<XCircle
											size={26}
											weight='fill'
											color='var(--h-gray-40)'
										/>
									}
								></Button>
							)}
						</div>
						<Divider />

						{period === TPeriodicity.MONTHLY ? (
							<>
								<MonthlyDetail
									handleViewDetail={handleViewDetail}
									handleAddToCart={handleAddToCart}
									isLoadingAddToCart={isLoadingAddToCart.products}
									isLoadingDetail={isLoading.detail}
								/>
								{getOtherRegimes()?.length! >= 1 && (
									<>
										<Text
											size='body-3'
											color='medium'
											weight='medium'
											sx='p-2 mt-5 bg-h-neutral-90 rounded-t-lg'
										>
											Otras declaraciones
										</Text>
										{getOtherRegimes()?.map((regime, index) => (
											<div
												className='flex justify-between flex-col sm:flex-row gap-4 sm:items-center mt-2 p-2'
												key={`regime-index-${index}`}
											>
												<div>
													<Text
														size='caption'
														color='medium'
													>
														{regime?.name}
													</Text>
													<Text
														size='mini-1'
														color='medium'
														weight='light'
													>
														Si necesitas presentar las declaraciones de este
														régimen, ponte en contacto con nosotros.
													</Text>
												</div>
												<Button
													label='Obtener ayuda personalizada'
													variant='secondary'
													onClick={handleClickWhatsapp}
													sx='!w-fit whitespace-nowrap'
												/>
											</div>
										))}
									</>
								)}
							</>
						) : (
							<AnnualDetail handleViewDetail={handleViewDetail} />
						)}
					</div>
					<HelpDropdown period={period} />
				</section>
			</Container>
		</>
	);
};

export default PeriodDetail;
