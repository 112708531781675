import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExpandedState {
	expanded: boolean;
}

const initialState: ExpandedState = {
	expanded: false,
};

export const expandedSlice = createSlice({
	name: 'expanded',
	initialState,
	reducers: {
		setExpandedSidebar: (state, action: PayloadAction<boolean>) => {
			state.expanded = action.payload;
		},
	},
});

export const { setExpandedSidebar } = expandedSlice.actions;
