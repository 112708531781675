import { AnimationType } from '../../@types/Animation.type';

const getTextColor = (textColor: string) => {
	const colors: Record<string, string> = {
		white: 'text-h-primary-100',
		dark: 'text-h-text-dark',
		medium: 'text-h-text-medium',
		light: 'text-h-text-light',
		primary: 'text-h-primary-40',
		ultraBlueLight: 'text-h-blue-20',
		red: 'text-h-red-50',
		green: 'text-h-green-50',
		gray: 'text-h-gray-50',
		greenSuccess: 'text-h-success-50',
		blueInformation: 'text-h-information-50',
		darkMenu: 'text-h-dark-menu',
		hotSale: 'text-h-hot-sale',
		blueLight: 'text-h-primary-40',
	};

	return colors[textColor] || 'text-title';
};

const getTextSize = (textSize: string) => {
	switch (textSize) {
		case 'xl':
		case 'body-1':
		case 'subtitle':
			return 'text-xl leading-7';
		case 'l':
		case 'body-2':
			return 'text-lg leading-[25px]';
		case 'm':
		case 'body-3':
		case 'label':
			return 'text-base leading-6';
		case 's':
		case 'caption':
			return 'text-sm leading-[18px]';
		case 'xs':
		case 'mini-1':
			return 'text-xs';
		case 'xxs':
		case 'mini-2':
			return 'text-[10px]';
		default:
			return 'text-base leading-6';
	}
};

const getTextWeight = (weight: string) => {
	switch (weight) {
		case 'light':
			return 'font-light';
		case 'regular':
			return 'font-regular';
		case 'medium':
			return 'font-medium';
		case 'bold':
			return 'font-bold';
		default:
			return 'font-regular';
	}
};

const getAnimation = (animation: AnimationType) => {
	if (animation === 'ease-in-out') {
		return 'transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300';
	}
	return '';
};

const getBgColorRow = (index: number) => {
	if (index % 2 === 0) {
		return 'bg-white';
	} else {
		return 'bg-[#FAFAFA]';
	}
};
export {
	getTextColor,
	getTextSize,
	getTextWeight,
	getAnimation,
	getBgColorRow,
};
