import React, { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { CreditCard } from '@phosphor-icons/react';
import { RootState } from '../../../../redux/store';
import {
	getCreateIntent,
	sendStripeToken,
} from '../../../../services/payments.service';
import { AlertPayload, showAlert } from '../../../../redux/slices/alert.slice';
import ROUTES from '../../../../shared/constants/routes';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../../shared/constants/events';
import Button from '../../../../components/atoms/Button';
import useCleverTapEvent from '../../../../hooks/useClevertapEvent';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY as string);
interface CreateCardProps {
	customerStripeId: string;
	onPaymentMethodSuccess?: () => void;
}
const CreateCard: React.FC<CreateCardProps> = ({
	customerStripeId,
	onPaymentMethodSuccess,
}) => {
	return (
		<>
			<Elements
				stripe={stripePromise}
				options={{
					mode: 'setup',
					currency: 'mxn',
				}}
			>
				<WidgetForm
					customerStripeId={customerStripeId}
					onPaymentMethodSuccess={onPaymentMethodSuccess}
				/>
			</Elements>
		</>
	);
};

const WidgetForm: React.FC<CreateCardProps> = ({ customerStripeId, onPaymentMethodSuccess }) => {
	const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);

	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const get_ClientIntent = useCallback(async (userId: number) => {
		try {
			const response = await getCreateIntent(userId, tokenSession);
			return response;
		} catch (error) {}
	}, []);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const handlerSaveCard = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();

			if (!stripe) return;

			setIsLoadingPaymentMethod(true);

			const { error: submitError } = await elements!.submit();

			if (submitError) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						submitError.message ??
						'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
				});
				setIsLoadingPaymentMethod(false);
				return;
			}

			const res = await get_ClientIntent(userProfile?.id!);
			const clientSecret = res?.Body?.responseCall?.data?.client_secret;

			if (!clientSecret) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						'Ocurrió un error al intentar guardar la tarjeta, intenta de nuevo',
				});
			}

			const { error, setupIntent } = await stripe!.confirmSetup({
				elements: elements!,
				redirect: 'if_required',
				clientSecret: clientSecret!,
				confirmParams: {
					return_url: location.href + ROUTES.CHECKOUT,
				},
			});
			setIsLoadingPaymentMethod(false);

			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: false,
				});
			} else {
				await sendStripeToken(
					customerStripeId ?? '',
					(setupIntent.payment_method as string) ?? '',
					tokenSession,
				);
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: true,
				});
				showAlertTop({
					title: 'Tarjeta guardada',
					type: 'success',
					description: 'Tarjeta guardada exitosamente',
				});
				onPaymentMethodSuccess?.();
			}
		} catch (error) {
			setIsLoadingPaymentMethod(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
			});
		}
	};

	return (
		<form onSubmit={handlerSaveCard}>
			<PaymentElement />
			<Button
				beforeIcon={<CreditCard size={24} />}
				loading={isLoadingPaymentMethod}
				disabled={!stripe}
				variant='secondary'
				label='Agregar'
				sx='h-[47px] ml-2 mt-4'
				type='submit'
			/>
		</form>
	);
};

export default CreateCard;
