export enum FiscalDocumentType {
	INVOICE = 'invoice',
	INVOICE_RECEIVED = 'invoice_received',
	RETENTION = 'retention',
	RECEIPT = 'receipt',
}

export interface FiscalDocumentInvoices {
	elementsCount: number;
	pagesCount: number;
	pageRange: number;
	detail: string;
	data: FiscalDocumentInvoicesData[];
}

export interface FiscalDocumentInvoicesData {
	date: string;
	invoice_type: InvoiceType;
	status: Status;
	total_amount: number;
	name_issuer: string;
	code_issuer: string;
	deductible_amount: number;
	invoice_id: string;
	description: string;
	issuerData: {
		responseIssuerData: FiscalDocumentIssuerDatum[];
	};
	receiverData: {
		responseReceiverData: FiscalDocumentIssuerDatum[];
	};
}

export interface FiscalDocumentIssuerDatum {
	rfc: string;
	issuer_id: number;
	name: string;
}

// * E - Egreso
// * I - Ingreso
// * N - Nomina
// * P - Pago
// * T - Traslado

export enum InvoiceType {
	I = 'I',
	E = 'E',
	N = 'N',
	P = 'P',
	T = 'T',
}

enum Status {
	Vigente = 'VIGENTE',
	Active = 'ACTIVE',
	Cancelado = 'CANCELADO',
}

export interface FiscalDocumentRetentions {
	elementsCount: number;
	pagesCount: number;
	pageRange: number;
	detail: string;
	data: FiscalDocumentRetentionsData[];
}

export interface FiscalDocumentRetentionsData {
	retention_id: string;
	date: string;
	retention_type: string;
	status: string;
	total_amount: number;
	name_issuer: string;
	code_issuer: string;
	deductible_amount: number;
	xml_url?: string;
	issuerData: {
		responseIssuerData: FiscalDocumentIssuerDatum[];
	};
	receiverData: {
		responseReceiverData: FiscalDocumentIssuerDatum[];
	};
}

export interface FiscalDocumentReceipts {
	elementsCount: number;
	pagesCount: number;
	pageRange: number;
	detail: string;
	data: FiscalDocumentReceiptsData[];
}

export interface FiscalDocumentReceiptsData {
	id: string;
	user_id: number;
	rfc: string;
	month: number;
	year: number;
	declaration_url: string;
	amount: number;
	type: DeclarationReceiptType;
	capture_line: string;
	presented_by: PresentedBy;
	presented_at: string;
	declaration_detail_document: string;
	paid_id: string | string;
	payment_date: string | string;
}

export enum PresentedBy {
	Heru = 'Heru',
}

export enum DeclarationReceiptType {
	Complementaria = 'COMPLEMENTARIA',
	Normal = 'NORMAL',
}

export interface FiscalDocumentInvoiceDetail {
	invoice: FiscalDocumentInvoiceDetailGeneral;
	items: FiscalDocumentInvoiceDetailItem[];
	detail: string;
	issuerData: ErData;
	receiverData: ErData;
}

export interface FiscalDocumentInvoiceDetailGeneral {
	id: number;
	source_id: string;
	uuid: string;
	version: number;
	type: string;
	usage: string;
	payment_type: string;
	payment_method: string;
	issuer_id: number;
	receiver_id: number;
	currency: string;
	discount: number;
	tax: number;
	subtotal: number;
	total: number;
	exchange_rate: string;
	paid_amount: string;
	due_amount: string;
	fully_paid_at: string;
	status: string;
	pac: string;
	issued_at: string;
	certified_at: string;
	cancellation_status: string;
	cancellation_process_status: string;
	cancelled_at: string;
	internal_identifier: string;
	reference: string;
	xml: string;
	issued_by: string;
	regime: number;
	faulty: boolean;
	faulty_reason: string;
	issuer_partner: string;
	xml_url: string;
	pdf_url: string;
	xml_file_uuid: string;
	pdf_file_uuid: string;
	paid_days: number;
	total_deductions: number;
	total_taxed: number;
	total_exempt: number;
	payroll_type: string;
	payroll_version: number;
	total_perceptions: string;
	start_date: string;
	finish_date: string;
	related_uuid: string;
	payment_partiality_number: string;
	payment_pending_amount: string;
	payment_past_amount: string;
	payment_method_related_document: string;
	related_code: string;
	payment_amount: number;
	payment_date: string;
	created_at: string;
	updated_at: string;
}

export interface FiscalDocumentInvoiceDetailItem {
	id: number;
	identification_number: string;
	product_identification: string;
	description: string;
	unit_amount: number;
	unit_code: string;
	quantity: number;
	discount_amount: number;
	tax_type: string;
	tax_rate: string;
	tax_amount: number;
	total_amount: number;
	invoice_id: number;
	tax_factor_type: string;
	created_at: string;
	updated_at: string;
}

export interface FiscalDocumentRetentionDetail {
	retention: FiscalDocumentRetentionDetailGeneral;
	items: FiscalDocumentRetentionDetailItem[];
	detail: string;
	issuerData: ErData;
	receiverData: ErData;
}

export interface ErData {
	name: string;
	code: string;
}

export interface FiscalDocumentRetentionDetailItem {
	id: number;
	base_amount: number;
	tax_type: string;
	retained_amount: number;
	payment_type: string;
	retention_id: number;
	created_at: string;
	updated_at: string;
}

export interface FiscalDocumentRetentionDetailGeneral {
	id: number;
	source_id: string;
	uuid: string;
	version: number;
	code: string;
	description: string;
	issuer_id: number;
	receiver_id: number;
	total_operation_amount: number;
	total_taxable_amount: number;
	total_exempt_amount: number;
	total_retained_amount: number;
	period_from: string;
	period_to: string;
	pac: string;
	internal_identifier: string;
	issued_at: string;
	certified_at: string;
	cancelled_at: string;
	xml: string;
	service_type: string;
	faulty: boolean;
	faulty_reason: string;
	xml_url: string;
	pdf_url: string;
	xml_file_uuid: string;
	pdf_file_uuid: string;
	governmental_contribution: string;
	total_platform_use: string;
	created_at: string;
	updated_at: string;
}

export interface FiscalDocumentReceiptDetail {
	month: number;
	year: number;
	id: string;
	user_id: number;
	declaration_detail_document: string;
	declaration_url: string;
	amount: number;
	declaration_type: string;
	capture_line: null;
	presented_by: string;
	presented_at: string;
	created_at: string;
	created_manually: boolean;
	status: string;
	vat_detail_amount_to_pay: null;
	payed: boolean;
}

export interface RappiCertificate {
	uuid: string;
	partner_name: string;
	total_amount: number;
	emitted_at: string;
	state: string;
	pdf_url: string;
	xml_url: string;
}
