import React, { useMemo } from 'react';
import {
	Check,
	CheckCircle,
	CurrencyDollar,
	HourglassMedium,
	Timer,
	Warning,
} from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import { TaxboardDeclarationStatus } from '../../../@types/Calendar.type';

interface TagIconCalendarProps {
	status: string;
	isMobile?: boolean;
	isAnnual?: boolean;
}

const TagIconCalendar: React.FC<TagIconCalendarProps> = ({
	status,
	isMobile,
	isAnnual,
}) => {
	const tagIcon = useMemo(() => {
		switch (status) {
			case TaxboardDeclarationStatus.PRESENTED:
				return <Check size={20} />;

			case TaxboardDeclarationStatus.IN_PROCESS:
				return <HourglassMedium size={20} />;

			case TaxboardDeclarationStatus.PENDING:
				return <Warning size={20} />;

			case TaxboardDeclarationStatus.PENDING_PAY:
				return <CurrencyDollar size={20} />;

			case TaxboardDeclarationStatus.DOWNLOADING:
				return <Timer size={20} />;
			default:
				return <div />;
		}
	}, [status]);

	const tagIconMobile = useMemo(() => {
		switch (status) {
			case TaxboardDeclarationStatus.PRESENTED:
				return (
					<>
						{isAnnual ? (
							<div className='bg-h-success-90 px-3 p-1 rounded-full'>
								<Check size={20} />
							</div>
						) : (
							<CheckCircle
								size={20}
								weight='fill'
								color='var(--h-success-50)'
								className='min-w-[20px]'
							/>
						)}
					</>
				);

			case TaxboardDeclarationStatus.IN_PROCESS:
				return (
					<>
						{isAnnual ? (
							<div className='bg-h-neutral-90 px-3 p-1 rounded-full'>
								<HourglassMedium size={20} />
							</div>
						) : (
							<HourglassMedium
								size={20}
								weight='fill'
								className='min-w-[20px]'
							/>
						)}
					</>
				);

			case TaxboardDeclarationStatus.PENDING:
				return (
					<>
						{isAnnual ? (
							<div className='bg-h-error-90 px-3 p-1 rounded-full'>
								<Warning
									size={20}
									color='var(--h-error-50)'
								/>
							</div>
						) : (
							<Warning
								size={20}
								weight='fill'
								color='var(--h-error-50)'
								className='min-w-[20px]'
							/>
						)}
					</>
				);

			case TaxboardDeclarationStatus.PENDING_PAY:
				return (
					<>
						{isAnnual ? (
							<div className='bg-h-warning-90 px-3 p-1 rounded-full'>
								<Text
									size='mini-1'
									sx='text-h-warning-30'
								>
									SAT
								</Text>
							</div>
						) : (
							<CurrencyDollar
								size={20}
								color='var(--h-warning-30)'
								className='min-w-[20px]'
							/>
						)}
					</>
				);

			case TaxboardDeclarationStatus.DOWNLOADING:
				return (
					<>
						{isAnnual ? (
							<div className='bg-h-information-90 px-3 p-1 rounded-full'>
								<Timer
									size={20}
									weight='fill'
									color='var(--h-information-50)'
								/>
							</div>
						) : (
							<Timer
								size={20}
								weight='fill'
								color='var(--h-information-50)'
								className='min-w-[20px]'
							/>
						)}
					</>
				);
			default:
				return <div />;
		}
	}, [isAnnual, status, isMobile]);
	return <>{isMobile ? tagIconMobile : tagIcon}</>;
};

export default React.memo(TagIconCalendar);
