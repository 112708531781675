import React, { useMemo } from 'react';
import Text from '../../../components/atoms/Text';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

type TAlign = 'left' | 'center' | 'right';

export type TColumns = {
	title: string;
	dataIndex: string;
	columnStyle?: string;
	headColumnStyle?: string;
	hiddeInMobile?: boolean;
	titleInMobile?: string;
	labelStyle?: string;
	align?: TAlign;
};

export type TRowData = {
	rowStyle?: string;
	onClick?: () => void;
	rowContent: TRowDataContent;
};

type TRowDataContent = {
	[key: TColumns['dataIndex']]: {
		content: string | JSX.Element;
		textStyle?: string;
	};
};

type PeriodInfoTableProps = {
	columns: TColumns[];
	rowsData: TRowData[];
	sx?: string;
	responsive?: boolean;
};

const Table: React.FC<PeriodInfoTableProps> = ({
	columns,
	rowsData,
	sx = '',
	responsive,
}) => {
	const columnsTable = useMemo(() => {
		if (responsive) {
			return columns.filter((column) => !column.hiddeInMobile);
		}
		return columns;
	}, [columns, responsive]);

	const alignColumnClass = useMemo(
		() => (alignment: TAlign) => {
			if (alignment === 'center') return 'text-center';
			if (alignment === 'right') return 'text-right';
			return 'text-left';
		},
		[columnsTable],
	);

	return (
		<table className={`text-left w-full ${sx}`}>
			<thead className='table-header-group'>
				<tr>
					{columnsTable?.map((column, key) => (
						<th
							className={`border-b border-h-gray-30 p-1 py-2 ${column.headColumnStyle}`}
							key={`column_${key}`}
							scope='col'
						>
							<Text
								weight='medium'
								size={responsive ? 'mini-1' : 'caption'}
								color='medium'
								sx={`!block ${alignColumnClass(column.align ?? 'left')} ${
									column.labelStyle ?? ''
								}`}
							>
								{responsive && column.titleInMobile
									? column.titleInMobile
									: column.title}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rowsData?.map((row, key) => (
					<tr
						key={`row_${key}`}
						className={row.rowStyle}
						onClick={row?.onClick}
					>
						{columnsTable?.map(({ dataIndex, columnStyle, align }) => (
							<td
								key={`column_${dataIndex}`}
								className={`p-2 py-2 ${columnStyle ?? ''}`}
							>
								{typeof row.rowContent[dataIndex]?.content !== 'string' ? (
									row.rowContent[dataIndex]?.content
								) : (
									<Text
										weight='light'
										size='caption'
										color='medium'
										sx={`
										!block 
										${alignColumnClass(align ?? 'left')} 
										${row.rowContent[dataIndex].textStyle}
										`}
									>
										{row.rowContent[dataIndex].content}
									</Text>
								)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default Table;

type TablePaginationProps = {
	total: number;
	currentPage: number;
	perPage: number;
	onChangePage: (page: number) => void;
	disabled?: boolean;
};

const TablePagination: React.FC<TablePaginationProps> = ({
	total,
	currentPage,
	perPage,
	onChangePage,
	disabled,
}) => {
	const totalPages = Math.ceil(total / perPage);
	const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
	const lastPages =
		currentPage > 3
			? pages.slice(currentPage - 2, currentPage + 1)
			: pages.slice(0, 4);

	const handleChangePage = (page: number) => {
		if (page < 1 || page > totalPages) return;
		onChangePage(page);
	};

	if (totalPages <= 1 || total === 0) return null;

	return (
		<div
			className={`flex justify-center gap-2 items-center cursor-pointer ${disabled && 'cursor-not-allowed'}`}
		>
			{currentPage > 1 && (
				<button
					onClick={() => handleChangePage(currentPage - 1)}
					className='p-1 hover:bg-h-primary-95 rounded-full'
				>
					<CaretLeft
						weight='bold'
						width={16}
						height={16}
						color='var(--h-primary)'
						className='mb-0.5'
					/>
				</button>
			)}
			{currentPage !== pages[0] && currentPage > 3 && (
				<button
					onClick={() => handleChangePage(pages[0])}
					className='px-1 hover:bg-h-primary-95 rounded-full'
				>
					<Text
						weight='regular'
						color='primary'
					>
						{pages[0]} ...
					</Text>
				</button>
			)}

			<div className='flex'>
				{lastPages.map((page, index) => (
					<button
						onClick={() => handleChangePage(page)}
						className='px-3 hover:bg-h-primary-95 rounded-full'
						key={`page-index-${index}`}
					>
						<Text
							weight={currentPage === page ? 'bold' : 'regular'}
							color='primary'
							sx={`underline ${page !== currentPage && '!opacity-40 !no-underline'}`}
						>
							{page}
						</Text>
					</button>
				))}
			</div>

			{currentPage !== pages[pages.length - 1] &&
				currentPage !== pages[pages.length - 1] - 1 &&
				totalPages > 3 && (
					<button
						onClick={() => handleChangePage(pages[pages.length - 1])}
						className='px-1 hover:bg-h-primary-95 rounded-full'
					>
						<Text
							weight='regular'
							color='primary'
						>
							... {pages[pages.length - 1]}
						</Text>
					</button>
				)}
			{currentPage < totalPages && (
				<button
					onClick={() => handleChangePage(currentPage + 1)}
					className='p-1 hover:bg-h-primary-95 rounded-full'
				>
					<CaretRight
						weight='bold'
						width={16}
						height={16}
						color='var(--h-primary)'
						className='mb-0.5'
					/>
				</button>
			)}
		</div>
	);
};

export { TablePagination };
