const enum MONTHLY_DECLARATION_PRODUCTS_SLUG {
	DECL_PT_REPARTIDOR = 'decl_pt_rep_1',
	DECL_PT_CONDUCTOR = 'decl_pt_cond_1',
	DECL_PT_SOCIO_FLOTILLERO = 'decl_pt_flot_1',
	DECL_PT_SOCIO_ANFITRION = 'decl_pt_host_1',
	DECL_PT_SOCIO_VENDEDOR_LINEA = 'decl_pt_sell_1',
	DECL_PT_TODAS_ACTIVIDADES = 'decl_pt_full_1',
	DECL_RESICO_PLUS = 'decl_resico_plus_1',
	DECL_RESICO_EXPRESS = 'decl_resico_exp_1',
	DECL_ARR_MES = 'decl_arr_mes_1',
	DECL_ARR_TRIMESTRAL = 'decl_arr_tri_3',
	DECL_RIF = 'decl_rif_2',
	DECL_PT_ARR = 'decl_pt_arr_1',
	DECL_ACT_EMP = 'decl_act_emp_1',
	DECL_FULL = 'decl_full_1',
}

const enum ONE_OFF_DECLARATION_PRODUCTS_SLUG {
	ONE_OFF_DECL_PT_REPARTIDOR = 'oneoff_pt_rep',
	ONE_OFF_DECL_PT_CONDUCTOR = 'oneoff_pt_cond',
	ONE_OFF_DECL_PT_SOCIO_FLOTILLERO = 'oneoff_pt_flot',
	ONE_OFF_DECL_PT_SOCIO_ANFITRION = 'oneoff_pt_host',
	ONE_OFF_DECL_PT_SOCIO_VENDEDOR_LINEA = 'oneoff_pt_sell',
	ONE_OFF_DECL_PT_TODAS_ACTIVIDADES = 'oneoff_pt_full',
}

const enum PRODUCT_CATEGORY {
	MONTH_DECLARATION = 'Declaración Mensual',
	ANNUAL_DECLARATION = 'Declaración Anual',
	ASAT = 'Asistencia SAT',
	REGULARIZATION_DECLARATION = 'Declaración Atrasada',
	ALL_PRODUCTS = 'Todos',
}

const enum PRODUCTS_SLUG {
	ANNUAL_DECLARATION_SALARY = 'declaracion_anual_sueldos_salarios',
	ANNUAL_DECLARATION_WITH_REGULARIZATION_2020 = 'declaracion_anual_con_regularizacion_2020',
	ANNUAL_DECLARATION_WITH_REGULARIZATION = 'declaracion_anual_con_regularizacion',
	ANNUAL_DECLARATION_WITHOUT_REGULARIZATION_2020 = 'declaracion_anual_sin_regularizacion_2020',
	ANNUAL_DECLARATION_WITHOUT_REGULARIZATION = 'declaracion_anual_sin_regularizacion',
	DECLARATION = 'declaration',
	MONTH_DECLARATION = 'declaracion_mes',
	REGULARIZATION = 'regularization',
	ANNUAL_GENERIC_DECLARATION_WITH_REGULARIZATION = 'declaracion_anual_generica_con_regularizacion',
	ANNUAL_GENERIC_DECLARATION_WITHOUT_REGULARIZATION = 'declaracion_anual_generica_sin_regularizacion',
	ASAT_SIMPLE = 'asat_simple',
	ASSISTANCE_ASAT = 'asistencia_asat_2.0',
	ANNUAL_DECLARATION_BASIC = 'declaración_anual_basica',
	ANNUAL_DECLARATION_HARD = 'declaración_anual_compleja',
	ANNUAL_DECLARATION = 'suscripcion_declaracion_anual',
	ANNUAL_DECLARATION_BASIC_WITH_REGULARIZATION = 'declaración_anualbasica_regubasica',
	ANNUAL_DECLARATION_HARD_WITH_REGULARIZATION_COMPLEJA = 'decla_anualcomp_regucomp',
	ANNUAL_DECLARATION_HARD_WITH_REGULARIZATION = 'decla_anualcomp_regubasica',
}

const enum REGULARIZATION_REGARATION_PRODUCTS_SLUG {
	REG_PT_REPARTIDOR = 'reg_pt_rep',
	REG_PT_CONDUCTOR = 'reg_pt_cond',
	REG_PT_SOCIO_FLOTILLERO = 'reg_pt_flot',
	REG_PT_SOCIO_ANFITRION = 'reg_pt_host',
	REG_PT_SOCIO_VENDEDOR_LINEA = 'reg_pt_sell',
	REG_PT_TODAS_ACTIVIDADES = 'reg_pt_full',
	REG_RESICO_PLUS = 'reg_resico_plus',
	REG_RESICO_EXPRESS = 'reg_resico_exp',
	REG_ARR_MES = 'reg_arr_mes',
	REG_ARR_TRIMESTRAL = 'reg_arr_tri',
	REG_RIF = 'reg_rif',
	REG_ACT_EMP = 'reg_act_emp',
}

export const allRegularizationProductsSlug = [
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_REPARTIDOR,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_CONDUCTOR,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_FLOTILLERO,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_ANFITRION,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_VENDEDOR_LINEA,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_EXPRESS,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_TRIMESTRAL,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
];

export const RegularizationProductTypes = {
	getRegDeliverProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_REPARTIDOR}`,
	getRegDriverProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_CONDUCTOR}`,
	getRegHostProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_ANFITRION}`,
	getRegSellerProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_VENDEDOR_LINEA}`,
	getRegFleetPartnerProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_FLOTILLERO}`,
	getRegAllActivitiesProd: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES}`,
	getRegResicoPlus: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS}`,
	getRegResicoExpress: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_EXPRESS}`,
	getRegArriendosMonth: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES}`,
	getRegArriendosTrimester: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_TRIMESTRAL}`,
	getRegRIF: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF}`,
	getRegActEmp: `[regularization] ${REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP}`,
};

export const regularizationsPT = [
	//? PLATAFORMA TECNOLOGICA
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_REPARTIDOR,
		type: RegularizationProductTypes.getRegDeliverProd,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_CONDUCTOR,
		type: RegularizationProductTypes.getRegDriverProd,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_ANFITRION,
		type: RegularizationProductTypes.getRegHostProd,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_VENDEDOR_LINEA,
		type: RegularizationProductTypes.getRegSellerProd,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_SOCIO_FLOTILLERO,
		type: RegularizationProductTypes.getRegFleetPartnerProd,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
		type: RegularizationProductTypes.getRegAllActivitiesProd,
	},
	//? Resico
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
		type: RegularizationProductTypes.getRegResicoPlus,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_EXPRESS,
		type: RegularizationProductTypes.getRegResicoExpress,
	},
	//? Arriendos
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
		type: RegularizationProductTypes.getRegArriendosMonth,
	},
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_TRIMESTRAL,
		type: RegularizationProductTypes.getRegArriendosTrimester,
	},
	//? RIF
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
		type: RegularizationProductTypes.getRegRIF,
	},
	//? ACTIVIDAD EMPRESARIAL
	{
		slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
		type: RegularizationProductTypes.getRegActEmp,
	},
];

export {
	MONTHLY_DECLARATION_PRODUCTS_SLUG,
	ONE_OFF_DECLARATION_PRODUCTS_SLUG,
	PRODUCT_CATEGORY,
	PRODUCTS_SLUG,
	REGULARIZATION_REGARATION_PRODUCTS_SLUG,
};
