import React, { useCallback, useState, SetStateAction, Dispatch } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	useStripe,
	useElements,
	Elements,
	PaymentElement,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import {
	getCreateIntent,
	getStripeClientSecret,
	sendStripeToken,
} from '../../../services/payments.service';
import { Order } from '../../../@types/Order.type';
import Button from '../../atoms/Button';
import { AlertPayload, showAlert } from '../../../redux/slices/alert.slice';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import {
	postDefaultPaymentMethod,
	postPaymentOnDemand,
	putDefaultPaymentMethodStripeBilling,
} from '../../../services/failed.service';
import { FAILURE_REASONS } from '../../../shared/constants/failed';
import ROUTES from '../../../shared/constants/routes';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { RootState } from '../../../redux/store';
import { PAYMENT_METHOD } from '../../../@types/Checkout.type';
import { CircularProgress } from '@mui/material';
import { SUB_TREATMENT } from '../../../shared/constants/shopping';
import { CreditCard } from '@phosphor-icons/react';
import { SubscriptionStatus } from '../../../@types/Subscription';
import { setUserSubscriptions } from '../../../redux/slices/profile.slice';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY as string);
interface FailedWidgetProps {
	order: Order;
	idUser?: number;
	onPaymentMethodSuccess?: () => void;
	showPaymentElement: boolean;
	paymentMethodId?: string;
	activeSubscription?: any;
	selectedCard?: any;
	setStep: Dispatch<SetStateAction<any>>;
	setShowModal: Dispatch<SetStateAction<any>>;
	customerStripeId: string;
	fetchUserSuscriptions: () => void;
	onCancel?: () => void;
}

const PurchasesWidget: React.FC<FailedWidgetProps> = ({
	order,
	onPaymentMethodSuccess,
	paymentMethodId,
	showPaymentElement,
	activeSubscription,
	selectedCard,
	setStep,
	setShowModal,
	customerStripeId,
	fetchUserSuscriptions,
	onCancel,
	idUser,
}) => {
	return (
		<>
			<Elements
				stripe={stripePromise}
				options={{
					mode: 'setup',
					currency: 'mxn',
				}}
			>
				<CheckoutForm
					order={order}
					onPaymentMethodSuccess={onPaymentMethodSuccess}
					paymentMethodId={paymentMethodId}
					showPaymentElement={showPaymentElement}
					activeSubscription={activeSubscription}
					selectedCard={selectedCard}
					setStep={setStep}
					setShowModal={setShowModal}
					customerStripeId={customerStripeId}
					fetchUserSuscriptions={fetchUserSuscriptions}
					onCancel={onCancel}
					idUser={idUser}
				/>
			</Elements>
		</>
	);
};

const CheckoutForm: React.FC<FailedWidgetProps> = ({
	order,
	onPaymentMethodSuccess,
	paymentMethodId,
	showPaymentElement,
	activeSubscription,
	selectedCard,
	setStep,
	setShowModal,
	customerStripeId,
	fetchUserSuscriptions,
	onCancel,
	idUser,
}) => {
	const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false);

	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const [isLoadingChangePaymentMethod, setIsLoadingChangePaymentMethod] =
		useState(false);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const { subscriptions } = useSelector((state: RootState) => state.profile);
	const [isLoadingProcess, setIsLoadingProcess] = useState(false);
	const [isLoadingRetryPayment, setIsLoadingRetryPayment] = useState(false);

	const get_ClientIntent = useCallback(async (userId: number) => {
		try {
			const response = await getCreateIntent(userId, tokenSession);
			return response;
		} catch (error: any) {}
	}, []);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const handlerSaveCard = async (event: React.FormEvent<HTMLFormElement>) => {
		try {
			event.preventDefault();

			if (!stripe) return;

			setIsLoadingPaymentMethod(true);

			const { error: submitError } = await elements!.submit();

			if (submitError) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						submitError.message ??
						'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
				});
				setIsLoadingPaymentMethod(false);
				return;
			}

			const user_id = order?.user_id ?? idUser ?? 0;
			const res = await get_ClientIntent(user_id);
			const clientSecret = res?.Body?.responseCall?.data?.client_secret;

			if (!clientSecret) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description:
						'Ocurrió un error al intentar guardar la tarjeta, intenta de nuevo',
				});
			}

			const { error, setupIntent } = await stripe!.confirmSetup({
				elements: elements!,
				redirect: 'if_required',
				clientSecret: clientSecret!,
				confirmParams: {
					return_url: location.href + ROUTES.CHECKOUT,
				},
			});
			setIsLoadingPaymentMethod(false);

			if (error) {
				showAlertTop({
					title: 'Error',
					type: 'error',
					description: error.message ?? '',
				});
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: false,
				});
			} else {
				await sendStripeToken(
					customerStripeId ?? '',
					(setupIntent.payment_method as string) ?? '',
					tokenSession,
				);
				logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
					event_type: EVENT_TYPES.CHECKOUT_ADD_PAYMENT_CARD,
					success: true,
				});
				showAlertTop({
					title: 'Tarjeta guardada',
					type: 'success',
					description: 'Tarjeta guardada exitosamente',
				});
				onPaymentMethodSuccess?.();
			}
		} catch (error) {
			setIsLoadingPaymentMethod(false);
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al guardar la tarjeta, intenta de nuevo',
			});
		}
	};

	const onChangeDefaultPaymentMethod = async () => {
		try {
			setIsLoadingChangePaymentMethod(true);
			if (!paymentMethodId) return;

			if (
				activeSubscription.subscription_treatment ===
				SUB_TREATMENT.HCP_STRIPE_PLATFORM
			) {
				const resp = await putDefaultPaymentMethodStripeBilling(
					selectedCard.platform_token,
					activeSubscription.id,
					tokenSession,
				);

				if (!resp) {
					showAlertTop({
						title: 'Ocurrío un error',
						type: 'error',
						description: 'Error al cambiar el método de pago',
					});
					return;
				}
				fetchUserSuscriptions();
				showAlertTop({
					title: resp?.data?.resource?.message,
					type: 'success',
					description: 'Método de pago cambiado exitosamente',
				});
			} else {
				await postDefaultPaymentMethod(
					paymentMethodId,
					PAYMENT_METHOD.CARD,
					'',
					tokenSession,
				);
				fetchUserSuscriptions();
				showAlertTop({
					title: 'Método de pago cambiado',
					type: 'success',
					description: 'Método de pago cambiado exitosamente',
				});
				setShowModal(false);
			}
		} catch (error) {
			showAlertTop({
				title: 'Error',
				type: 'error',
				description:
					'No es posible cambiar el método de pago, intenta de nuevo',
			});
		} finally {
			setIsLoadingChangePaymentMethod(false);
		}
	};

	const handleChangeMethodAndPay = async () => {
		try {
			setIsLoadingProcess(true);
			if (!paymentMethodId || !stripe) return;
			if (
				activeSubscription.subscription_treatment ===
				SUB_TREATMENT.HCP_STRIPE_PLATFORM
			) {
				//? BILLING
				await putDefaultPaymentMethodStripeBilling(
					selectedCard.platform_token,
					activeSubscription.id,
					tokenSession,
				);

				const subsUpdated = await postPaymentOnDemand(
					activeSubscription.id,
					tokenSession,
				);
				setIsLoadingProcess(false);

				if (
					!subsUpdated ||
					subsUpdated.length === 0 ||
					subsUpdated?.statusCode === 400
				) {
					let msgError =
						'Ocurrió un error al procesar el pago, intenta de nuevo';
					if (subsUpdated.message === 'Your card was declined.') {
						msgError = 'Tu tarjeta fue declinada.';
					}
					showAlertTop({
						title: 'Error en el pago',
						type: 'error',
						description: msgError,
					});
					return;
				}

				const newSubscriptions = subscriptions.filter(
					(subs) => subs.id !== subsUpdated[0].id,
				);

				dispatch(setUserSubscriptions([...newSubscriptions, subsUpdated[0]]));

				setStep(2);
				setTimeout(() => {
					setShowModal(false);
					setStep(0);
				}, 4000);
			} else {
				//? LEGACY
				await postDefaultPaymentMethod(
					paymentMethodId,
					PAYMENT_METHOD.CARD,
					'',
					tokenSession,
				);

				const res = await getStripeClientSecret(
					tokenSession,
					order.id,
					order?.user_id!,
					paymentMethodId,
					'',
					true,
				);

				const { error } = await stripe.confirmPayment({
					clientSecret: res?.platform_transaction_id,
					confirmParams: {
						return_url: location.origin,
					},
					redirect: 'if_required',
				});

				if (error) {
					setStep(3);
					setIsLoadingProcess(false);
				} else {
					setIsLoadingProcess(false);
					setStep(2);
					setTimeout(() => {
						setShowModal(false);
						fetchUserSuscriptions();
					}, 4000);
				}
			}
		} catch (error) {
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al procesar el pago, intenta de nuevo',
			});
			setIsLoadingProcess(false);
		}
	};

	const handleRetryPayment = async () => {
		try {
			setIsLoadingRetryPayment(true);
			if (!paymentMethodId || !stripe) return;

			if (
				activeSubscription.subscription_treatment ===
				SUB_TREATMENT.HCP_STRIPE_PLATFORM
			) {
				//! BILLING
				const subsUpdated = await postPaymentOnDemand(
					activeSubscription.id,
					tokenSession,
				);

				if (!subsUpdated || subsUpdated.length === 0) {
					return;
				}
				if (subsUpdated.statusCode === 400) {
					let msgError =
						'Ocurrió un error al procesar el pago, intenta de nuevo';
					if (subsUpdated.message === 'Your card was declined.') {
						msgError = 'Tu tarjeta fue declinada.';
					}
					showAlertTop({
						title: 'Error en el pago',
						type: 'error',
						description: msgError,
					});
					setIsLoadingRetryPayment(false);
					return;
				}
				const newSubscriptions = subscriptions.filter(
					(subs) => subs.id !== subsUpdated[0].id,
				);
				setStep(1);
				dispatch(setUserSubscriptions([...newSubscriptions, subsUpdated[0]]));
				setIsLoadingRetryPayment(false);
			} else {
				//! LEGACY
				const res = await getStripeClientSecret(
					tokenSession,
					order.id,
					order?.user_id!,
					paymentMethodId,
					'',
					true,
				);

				const { error } = await stripe.confirmPayment({
					clientSecret: res?.platform_transaction_id,
					confirmParams: {
						return_url: location.origin,
					},
					redirect: 'if_required',
				});

				if (error) {
					setStep(3);
					setIsLoadingRetryPayment(false);
					setTimeout(() => {
						setStep(0);
					}, 1000);
				} else {
					setStep(1);
					fetchUserSuscriptions();
					setIsLoadingRetryPayment(false);
				}
			}
		} catch (error) {
			showAlertTop({
				title: 'Error',
				type: 'error',
				description: 'Ocurrió un error al procesar el pago, intenta de nuevo',
			});
			setIsLoadingRetryPayment(false);
		}
	};

	return (
		<>
			{showPaymentElement ? (
				<form onSubmit={handlerSaveCard}>
					{elements && <PaymentElement />}
					<div
						className={`flex flex-col-reverse md:flex-row ${onCancel ? 'justify-between' : 'justify-end'} gap-x-2 gap-y-4 mt-4 `}
					>
						{onCancel && (
							<Button
								variant='tertiary'
								label='Cancelar'
								sx='text-center !h-[46px]'
								onClick={onCancel}
							/>
						)}
						<Button
							beforeIcon={<CreditCard size={20} />}
							loading={isLoadingPaymentMethod}
							disabled={!stripe}
							variant='secondary'
							label='Agregar método de pago'
							sx='!text-[14px] !h-[46px] !text-h-primary-30 '
							type='submit'
						/>
					</div>
				</form>
			) : (
				<>
					{activeSubscription.payment_status === SubscriptionStatus.success ? (
						<>
							{isLoadingChangePaymentMethod ? (
								<div className='mt-5 flex items-center justify-center'>
									<CircularProgress size={25} />
								</div>
							) : (
								<Button
									onClick={onChangeDefaultPaymentMethod}
									disabled={
										(selectedCard.id ===
											activeSubscription?.last_transaction.token_id &&
											!activeSubscription?.subscription_treatment) ||
										(selectedCard.id ===
											activeSubscription.subscription_platform?.payment_details
												?.id &&
											activeSubscription?.subscription_treatment ===
												SUB_TREATMENT.HCP_STRIPE_PLATFORM)
									}
									variant='primary'
									label='Cambiar método de pago y guardar'
									sx='h-[47px] mt-2'
									type='submit'
								/>
							)}
						</>
					) : (
						<>
							{activeSubscription.failure_reason ===
							FAILURE_REASONS.INSUFFICIENT_FUNDS ? (
								<>
									{isLoadingProcess || isLoadingRetryPayment ? (
										<div className='mt-5 flex items-center justify-center'>
											<CircularProgress size={25} />
										</div>
									) : (
										<div className='flex items-center gap-2'>
											<Button
												onClick={handleRetryPayment}
												disabled={
													selectedCard.id !==
													activeSubscription.last_transaction.token_id
												}
												variant='primary'
												label='Reintentar pago'
												sx='h-[47px] mt-4'
												type='submit'
												loading={isLoadingRetryPayment}
											/>
											<Button
												onClick={handleChangeMethodAndPay}
												disabled={
													(selectedCard.id ===
														activeSubscription?.last_transaction.token_id &&
														!activeSubscription?.subscription_treatment) ||
													(selectedCard.id ===
														activeSubscription.subscription_platform
															?.payment_details?.id &&
														activeSubscription?.subscription_treatment ===
															SUB_TREATMENT.HCP_STRIPE_PLATFORM)
												}
												variant='primary'
												label='Cambiar método de pago y reintentar pago'
												sx='h-[47px] mt-4'
												type='submit'
											/>
										</div>
									)}
								</>
							) : (
								<>
									{isLoadingProcess ? (
										<div className='mt-5 flex items-center justify-center'>
											<CircularProgress size={25} />
										</div>
									) : (
										<Button
											onClick={
												selectedCard.id ===
												activeSubscription.last_transaction.token_id
													? handleRetryPayment
													: handleChangeMethodAndPay
											}
											// disabled={
											// 	selectedCard.id ===
											// 	activeSubscription.last_transaction.token_id
											// }
											variant='primary'
											label={
												selectedCard.id ===
												activeSubscription.last_transaction.token_id
													? 'Reintentar pago'
													: 'Cambiar método de pago y hacer compra'
											}
											sx='h-[47px] mt-4'
											type='submit'
											loading={isLoadingRetryPayment}
										/>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default PurchasesWidget;
