import bgAirbnbDesktop from '../../assets/img/png/airbnb.png';
import bgAirbnbMobile from '../../assets/img/png/airbnbMobile.png';
import bgUberDesktop from '../../assets/img/png/uber.png';
import bgUberMobile from '../../assets/img/png/uberMobile.png';
import bgUberEatsDesktop from '../../assets/img/png/uberEats.png';
import bgUberEatsMobile from '../../assets/img/png/uberEatsMobile.png';
import bgRappiDesktop from '../../assets/img/png/rappi.png';
import bgRappiMobile from '../../assets/img/png/rappiMobile.png';
import bgCornershopDesktop from '../../assets/img/png/cornershop.png';
import bgCornershopMobile from '../../assets/img/png/cornershopMobile.png';
import bgDidiDesktop from '../../assets/img/png/didi.png';
import bgDidiMobile from '../../assets/img/png/didiMobile.png';
import bgMercadoEnviosDesktop from '../../assets/img/png/mercadoEnvios.png';
import bgMercadoEnviosMobile from '../../assets/img/png/mercadoMobile.png';
import flagMx from '../../assets/img/svg/flags/mx.svg';
import flagUs from '../../assets/img/svg/flags/us.svg';
import flagCo from '../../assets/img/svg/flags/co.svg';
import flagPe from '../../assets/img/svg/flags/pe.svg';
import flagGt from '../../assets/img/svg/flags/gt.svg';
import flagAr from '../../assets/img/svg/flags/ar.svg';
import flagUy from '../../assets/img/svg/flags/uy.svg';
import flagCl from '../../assets/img/svg/flags/cl.svg';
import flagGb from '../../assets/img/svg/flags/gb.svg';
import flagEs from '../../assets/img/svg/flags/es.svg';
import amexBrand from '../../assets/img/svg/brands/amex_brand.svg';
import masterBrand from '../../assets/img/svg/brands/master_brand.svg';
import visaBrand from '../../assets/img/svg/brands/visa_brand.svg';
import masterBg from '../../assets/img/svg/brands/master_bg.svg';
import visa2Brand from '../../assets/img/svg/brands/visa2_brand.svg';
import visaBgBrand from '../../assets/img/svg/brands/visabg_brand.svg';
import amexBgBrand from '../../assets/img/svg/brands/amexbg_brand.svg';
import masterBgBrand from '../../assets/img/svg/brands/masterbg_brand.svg';
import chip from '../../assets/img/svg/brands/chip.svg';
import visaWhite from '../../assets/img/svg/brands/visa_white.svg';
import master2Brand from '../../assets/img/svg/brands/master2_brand.svg';

export default {
  bgAirbnbDesktop,
  bgAirbnbMobile,
  bgUberDesktop,
  bgUberMobile,
  bgUberEatsDesktop,
  bgUberEatsMobile,
  bgRappiDesktop,
  bgRappiMobile,
  bgCornershopDesktop,
  bgCornershopMobile,
  bgDidiDesktop,
  bgDidiMobile,
  bgMercadoEnviosDesktop,
  bgMercadoEnviosMobile,
  flagMx,
  flagUs,
  flagCo,
  flagPe,
  flagGt,
  flagAr,
  flagUy,
  flagCl,
  flagGb,
  flagEs,
  amexBrand,
  masterBrand,
  visaBrand,
  masterBg,
  visa2Brand,
  visaBgBrand,
  amexBgBrand,
  masterBgBrand,
  chip,
  visaWhite,
  master2Brand,
};
