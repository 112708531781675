import axios from 'axios';
import LOCAL_STORAGE_KEY from '../constants/localStorageKey';
import ROUTES from '../constants/routes';
import { isLocalStorageEnabled } from './general.util';

type options = {
	token?: boolean;
	headers?: any;
	authCode?: boolean;
	contentType?: string;
	isNewGateway?: boolean;
	skipAccountError?: boolean;
	directResponse?: boolean;
	directResponseTwo?: boolean;
	tokenSession?: string;
};

const onAccountError = (error: any) => {
	if (error?.response?.status === 401) {
		if (isLocalStorageEnabled()) {
			localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
			localStorage.setItem(LOCAL_STORAGE_KEY.SESSION_EXPIRED, 'true');
		}
		location.href = ROUTES.LOGIN;
		return;
	}
	if (error?.response?.data?.http_status === 404) {
		if (error?.response?.config?.url?.includes('/taxpayers')) {
			return error?.response?.data;
		}
	}
	if (error?.response?.status === 409) {
		return error?.response?.data;
	}
	throw error;
};

const getUserToken = (tokenSession?: string) => {
	if (isLocalStorageEnabled()) {
		return (
			localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) ||
			encodeURI(
				new URLSearchParams(window.location.search).get(
					LOCAL_STORAGE_KEY.TOKEN,
				) || '',
			)
		);
	} else {
		return (
			tokenSession ||
			encodeURI(
				new URLSearchParams(window.location.search).get(
					LOCAL_STORAGE_KEY.TOKEN,
				) || '',
			)
		);
	}
};

const get = async <T = any>(url: string, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, authCode, isNewGateway, directResponse, directResponseTwo } =
		options;
	let authorization = '';
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;

	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}
		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}
		const response = await axios.get(newUrl + url, {
			headers: { Authorization: authorization },
		});
		if (directResponse) {
			return response.data;
		}
		if (directResponseTwo) {
			return response;
		}

		return response.data.resource as T;
	} catch (error: any) {
		onAccountError(error);
		throw error;
	}
};

const getCustom = async <T = any>(url: string, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, authCode, isNewGateway, headers } = options;
	let authorization = '';
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;

	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}
		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}
		const response = await axios.get(newUrl + url, {
			headers: { Authorization: authorization, ...headers },
		});
		return response.data as T;
	} catch (error: any) {
		onAccountError(error);
	}
};

const post = async <T = any>(url: string, body: object, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, contentType, authCode, isNewGateway } = options;
	let authorization;
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;
	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}

		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}

		const { data } = await axios.post(newUrl + url, body, {
			headers: {
				'Content-Type': contentType || 'application/json',
				Authorization: authorization || '',
			},
		});
		return (data.resource as T) ?? (data as T);
	} catch (error) {
		if (options.skipAccountError) {
			throw error;
		}
		return onAccountError(error);
	}
};

const postForm = async <T = any>(url: string, body: object, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, authCode, isNewGateway } = options;
	let authorization;
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;
	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}

		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}

		const { data } = await axios.post(newUrl + url, body, {
			headers: {
				//'Content-Type': contentType || 'application/json',
				Authorization: authorization || '',
				...options.headers,
			},
		});
		return (data.resource as T) ?? (data as T);
	} catch (error) {
		if (options.skipAccountError) {
			throw error;
		}
		return onAccountError(error);
	}
};

const patch = async (url: string, body: object, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, contentType, authCode, isNewGateway } = options;
	let authorization;
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;
	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}

		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}

		const response = await axios.patch(newUrl + url, body, {
			headers: {
				'Content-Type': contentType || 'application/json',
				Authorization: authorization || '',
			},
		});
		return response;
	} catch (error: any) {
		onAccountError(error);
	}
};

const del = async (url: string, options: options, body?: object) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, authCode, isNewGateway } = options;
	let authorization = '';
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;
	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}
		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}
		const response = await axios.delete(newUrl + url, {
			headers: { Authorization: authorization },
			data: body,
		});
		return response;
	} catch (error: any) {
		onAccountError(error);
	}
};

const put = async (url: string, body: object, options: options) => {
	const userToken = getUserToken(options?.tokenSession);
	const { token, contentType, authCode, isNewGateway } = options;
	let authorization;
	const newUrl = isNewGateway
		? import.meta.env.VITE_API_NEW_GATEWAY
		: import.meta.env.VITE_API_HERU;
	try {
		if (token) {
			if (userToken) {
				authorization = `Bearer ${userToken}`;
			}
		}
		if (authCode) {
			authorization = import.meta.env.VITE_SECRET;
		}
		const response = await axios.put(newUrl + url, body, {
			headers: {
				'Content-Type': contentType || 'application/json',
				Authorization: authorization || '',
			},
		});
		return response;
	} catch (error) {
		onAccountError(error);
	}
};

export { get, post, patch, del, put, getCustom, postForm };
