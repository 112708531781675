import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import Text from '../../../components/atoms/Text';
import { SelectChangeEvent } from '@mui/material';
import {
	PYP_CATEGORY_LABELS_MOBILE,
	annualProductsStatic,
	asatDefaultProducts,
	regularizationProductsDefaults,
	subscriptionsProductsDefaults,
} from '../data/ecommerceData';
import CategorySelector from '../components/organisms/CategorySelector';

import { ListProducts } from '../../../@types/Products.type';
import MobileSelection from './MobileSelection';
import AsatCard from '../components/organisms/AsatCard';
import { PeriodsSelectedType } from '../../../@types/Calendar.type';
import DefaultCard from '../components/organisms/DefaultCard';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { getYearByPeriod } from '../../../shared/utils/dates.util';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
interface MobilePlansProps {
	products: ListProducts[];
	handleAddToCart: (id: number, valuePeriods?: PeriodsSelectedType[]) => void;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}

const MobilePlans: React.FC<MobilePlansProps> = ({
	products,
	handleAddToCart,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const [category, setCategory] = useState(PYP_CATEGORY_LABELS_MOBILE[4]);
	const [step, setStep] = useState(0);

	const location = useLocation();
	const logClevertapEvent = useCleverTapEvent();

	const [searchParams] = useSearchParams();

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const handleChange = (event: SelectChangeEvent<string>) => {
		setCategory(event.target.value as string);
	};

	const getAsatPlus = products[3]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT,
	);
	const getAsatBasic = products[3]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ASAT_SIMPLE,
	);
	const getAnnual = products[2]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const handleAddProductAnnual = (id: string) => {
		if (id === 'annual') {
			handleAddProduct(getAnnual[0]?.product_versions[0]?.id, {
				year: getYearByPeriod(),
				regimes: [],
			});
		} else {
			setStep(3);
		}
	};

	useEffect(() => {
		if (location.state && location.state.category) {
			setCategory(location.state.category);
		}
	}, []);

	useEffect(() => {
		const categoryByParams = searchParams.get('category');
		if (!!categoryByParams) {
			setCategory(categoryByParams);
		}
	}, []);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_TAB,
			tab: category,
		});
	}, [category]);

	return (
		<div className='flex flex-col w-full p-4 h-full'>
			{step === 0 && (
				<div className='flex flex-col'>
					<Text
						children='Planes y precios'
						weight='bold'
						size='l'
					/>
					<Text
						children='Elige entre estas soluciones para ayudarte con tus impuestos.'
						size='m'
						sx='mt-2 mb-6'
						color='dark'
					/>
					<div>
						<CategorySelector
							isMobile
							categories={PYP_CATEGORY_LABELS_MOBILE}
							categoryMobile={category}
							handleChangeMobile={handleChange}
						/>

						{category !== 'Todos los planes' && (
							<Text
								size='l'
								weight='bold'
								sx='my-6'
							>
								{category}
							</Text>
						)}

						{category === 'Planes de contabilidad' && (
							<div className='flex flex-col items-center mb-16'>
								{subscriptionsProductsDefaults.map((item) => (
									<DefaultCard
										id={item.id}
										key={item.id}
										description={item.desc}
										title={item.name}
										benefits={item.principalBenefits}
										allBenefits={item.allBenefits}
										beforePriceText={item.beforePriceText}
										afterPriceText={item.afterPriceText}
										price={
											APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
												? getElasticyPrice(
														Math.round(item.price),
														elasticityPrice.group_experiment,
													)
												: item.price
										}
										showTag={item.showTag}
										textButton='Quiero este plan'
										sx='mb-6'
										onPress={() => {
											if (item.id === 'heru-plus') {
												setStep(1);
											} else {
												setStep(2);
											}
										}}
									/>
								))}
							</div>
						)}

						{category === 'Declaraciones Atrasadas' && (
							<div className='flex flex-col items-center mb-16'>
								{regularizationProductsDefaults.map((item) => (
									<DefaultCard
										id={item.id}
										key={item.id}
										description={item.desc}
										title={item.name}
										benefits={item.principalBenefits}
										allBenefits={item.allBenefits}
										beforePriceText={item.beforePriceText}
										afterPriceText={item.afterPriceText}
										price={
											APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
												? getElasticyPrice(
														Math.round(item.price),
														elasticityPrice.group_experiment,
													)
												: item.price
										}
										textButton='Quiero este plan'
										sx='mb-6'
										onPress={() => {
											setStep(4);
										}}
									/>
								))}
							</div>
						)}

						{category === 'Declaraciones Anuales' && (
							<div className='flex flex-col items-center mb-16'>
								{annualProductsStatic.map((item) => (
									<DefaultCard
										id={item.id}
										key={item.id}
										description={item.desc}
										title={item.name}
										benefits={item.principalBenefits}
										allBenefits={item.allBenefits}
										beforePriceText={item.beforePriceText}
										afterPriceText={item.afterPriceText}
										price={
											APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
												? getElasticyPrice(
														Math.round(item.price),
														elasticityPrice.group_experiment,
													)
												: item.price
										}
										textButton='Quiero este plan'
										sx='mb-6'
										onPress={() => handleAddProductAnnual(item.id)}
									/>
								))}
							</div>
						)}

						{category === 'Tramites con el SAT' && (
							<div className='flex flex-col items-center mb-16'>
								{asatDefaultProducts.map((item) => (
									<AsatCard
										id={item.id}
										key={item.id}
										description={item.desc}
										title={item.name}
										benefits={item.allBenefits}
										price={
											APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
												? getElasticyPrice(
														Math.round(item.price),
														elasticityPrice.group_experiment,
													)
												: item.price
										}
										textButton='Agregar al carrito'
										onPress={() => {
											if (item.id === 'asat-plus') {
												handleAddToCart(31);
											} else {
												handleAddToCart(36);
											}
										}}
										sx='mb-6'
										loading={isLoadingAddProduct}
									/>
								))}
							</div>
						)}

						{category === 'Todos los planes' && (
							<>
								<Text
									size='l'
									weight='bold'
									sx='my-6'
								>
									Declaraciones Mensuales
								</Text>
								<div className='flex flex-col items-center'>
									{subscriptionsProductsDefaults.map((item) => (
										<DefaultCard
											id={item.id}
											key={item.id}
											description={item.desc}
											title={item.name}
											benefits={item.principalBenefits}
											allBenefits={item.allBenefits}
											beforePriceText={item.beforePriceText}
											afterPriceText={item.afterPriceText}
											price={
												APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
													? getElasticyPrice(
															Math.round(item.price),
															elasticityPrice.group_experiment,
														)
													: item.price
											}
											showTag={item.showTag}
											textButton='Quiero este plan'
											sx='mb-6'
											onPress={() => {
												if (item.id === 'heru-plus') {
													setStep(1);
												} else {
													setStep(2);
												}
											}}
										/>
									))}
								</div>

								<Text
									size='l'
									weight='bold'
									sx='mb-6 mt-2'
								>
									Declaraciones Atrasadas
								</Text>

								<div className='flex flex-col items-center'>
									{regularizationProductsDefaults.map((item) => (
										<DefaultCard
											id={item.id}
											key={item.id}
											description={item.desc}
											title={item.name}
											benefits={item.principalBenefits}
											allBenefits={item.allBenefits}
											beforePriceText={item.beforePriceText}
											afterPriceText={item.afterPriceText}
											price={
												APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
													? getElasticyPrice(
															Math.round(item.price),
															elasticityPrice.group_experiment,
														)
													: item.price
											}
											textButton='Quiero este plan'
											sx='mb-6'
											onPress={() => {
												setStep(4);
											}}
										/>
									))}
								</div>

								<Text
									size='l'
									weight='bold'
									sx='mb-6 mt-2'
								>
									Declaraciones Anuales
								</Text>
								<div className='flex flex-col items-center'>
									{annualProductsStatic.map((item) => (
										<DefaultCard
											id={item.id}
											key={item.id}
											description={item.desc}
											title={item.name}
											benefits={item.principalBenefits}
											allBenefits={item.allBenefits}
											beforePriceText={item.beforePriceText}
											afterPriceText={item.afterPriceText}
											price={
												APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
													? getElasticyPrice(
															Math.round(item.price),
															elasticityPrice.group_experiment,
														)
													: item.price
											}
											textButton='Quiero este plan'
											sx='mb-6'
											onPress={() => handleAddProductAnnual(item.id)}
											loading={isLoadingAddProduct}
										/>
									))}
								</div>

								<Text
									size='l'
									weight='bold'
									sx='mb-6 mt-2'
								>
									Trámites con el SAT
								</Text>
								<div className='flex flex-col items-center mb-16'>
									{asatDefaultProducts.map((item) => (
										<AsatCard
											id={item.id}
											key={item.id}
											description={item.desc}
											title={item.name}
											benefits={item.allBenefits}
											price={
												APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
													? getElasticyPrice(
															Math.round(item.price),
															elasticityPrice.group_experiment,
														)
													: item.price
											}
											textButton='Agregar al carrito'
											onPress={() => {
												if (item.id === 'asat-plus') {
													handleAddToCart(
														getAsatPlus[0]?.product_versions[0]?.id,
													);
												} else {
													handleAddToCart(
														getAsatBasic[0]?.product_versions[0]?.id,
													);
												}
											}}
											sx='mb-6'
											loading={isLoadingAddProduct}
										/>
									))}
								</div>
							</>
						)}
					</div>
				</div>
			)}

			{step === 1 && (
				<MobileSelection
					setStep={setStep}
					products={products}
					id='heru-plus'
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			)}

			{step === 2 && (
				<MobileSelection
					setStep={setStep}
					products={products}
					id='basic-accounting'
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			)}

			{step === 3 && (
				<MobileSelection
					setStep={setStep}
					products={products}
					id='annual'
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			)}

			{step === 4 && (
				<MobileSelection
					setStep={setStep}
					products={products}
					id='regularization'
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			)}
		</div>
	);
};

export default React.memo(MobilePlans);
