import { Item } from '../../@types/Order.type';
import { CategoryName, ListProducts } from '../../@types/Products.type';
import {
	REGULARIZATION_REGARATION_PRODUCTS_SLUG,
	allRegularizationProductsSlug,
} from '../constants/SlugsProducts';

const getProductIdBySlug = (products: ListProducts[], slug: string) => {
	const product = products
		.find((cat) => cat.products.find((prod) => prod.slug === slug))
		?.products.find((prod) => prod.slug === slug);

	return product?.default_product_version_id || 0;
};

const acceptDuplicateProductHandler = (
	products: ListProducts[],
	slug: string,
) => {
	const category = products.find((cat) =>
		cat.products.find((prod) => prod.slug === slug),
	)?.name;

	if (category === CategoryName.DeclaraciónAtrasada) {
		return true;
	}
	return false;
};

const isDuplicateProductHandler = (itemsInCart: Item[] = [], slug: string) => {
	if (itemsInCart.length === 0) return false;

	return itemsInCart.find((item) => item.product_version.product.slug === slug);
};

const isRegularizationProduct = (category: string, slug: string = '') => {
	if (category) {
		return category === CategoryName.DeclaraciónAtrasada;
	} else {
		return allRegularizationProductsSlug.includes(
			slug as REGULARIZATION_REGARATION_PRODUCTS_SLUG,
		);
	}
};

const getProductProdBySlug = (products: ListProducts[], slug: string) => {
	const product = products
		.find((cat) => cat.products.find((prod) => prod.slug === slug))
		?.products.find((prod) => prod.slug === slug);

	return product;
};

const getProductProdByProductVersion = (
	products: ListProducts[],
	id: number,
) => {
	const product = products
		.find((cat) =>
			cat.products.find((prod) =>
				prod.product_versions.find((prodVer) => prodVer.id === id),
			),
		)
		?.products.find((prod) =>
			prod.product_versions.find((prodVer) => prodVer.id === id),
		);

	return product;
};

export {
	getProductIdBySlug,
	acceptDuplicateProductHandler,
	isDuplicateProductHandler,
	isRegularizationProduct,
	getProductProdBySlug,
	getProductProdByProductVersion,
};
