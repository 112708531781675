import images from "../constants/images";

const getBrandIcon = (brand: string) => {
	switch (brand) {
		case 'visa':
			return images.visaBrand;
		case 'mastercard':
			return images.masterBrand;
		case 'amex':
			return images.amexBrand;
		default:
			return images.visaBrand;
	}
};

export {
  getBrandIcon,
};
