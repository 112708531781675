import { createSlice } from '@reduxjs/toolkit';
import { TaxPayer, TaxProfile, UserRegime } from '../../@types/TaxProfile';

interface TaxProfileState {
	links: TaxProfile | null;
	taxPayer: TaxPayer | null;
	userRegimes: UserRegime[] | null;
	isLoading: boolean;
	userHasRetentions: {
		dateFirstRetention: string;
		dateOldRetention: string;
		hasRetentions: boolean;
	} | null;
}

const initialState: TaxProfileState = {
	links: null,
	taxPayer: null,
	userRegimes: null,
	isLoading: false,
	userHasRetentions: null,
};

export const taxProfileSlice = createSlice({
	name: 'taxProfile',
	initialState,
	reducers: {
		startLoader: (state, action) => {
			state.isLoading = action.payload;
		},
		addTaxPayer: (state, action) => {
			state.taxPayer = action.payload;
			state.isLoading = false;
		},
		restartTaxPayerData: (state) => {
			state.taxPayer = null;
			state.isLoading = false;
		},
		addUserRegimes: (state, action) => {
			state.userRegimes = action.payload;
		},
		addUserHasRetentions: (state, action) => {
			state.userHasRetentions = action.payload;
		},
		addLinks: (state, action) => {
			state.links = action.payload;
		},
		restartLinks: (state) => {
			state.links = null;
		},
	},
});

export const {
	startLoader,
	addTaxPayer,
	restartTaxPayerData,
	addUserRegimes,
	addLinks,
	restartLinks,
	addUserHasRetentions,
} = taxProfileSlice.actions;

export default taxProfileSlice.reducer;
