import Modal from '../../../components/molecules/Modal/Modal';
import Text from '../../../components/atoms/Text';
import { Checkbox, CircularProgress, useMediaQuery } from '@mui/material';
import Button from '../../../components/atoms/Button';
import Table, { TRowData } from '../../../components/molecules/Table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setShowModal } from '../../../redux/slices/taxCalendar.slice';
import { useEffect, useState } from 'react';
import { fetchMonthlyDeclarationOverview } from '../../../services/taxes.service';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import ERROR from '../../../shared/constants/errors';
import { showAlert } from '../../../redux/slices/alert.slice';
import {
	PeriodMonth,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';
import CALENDAR from '../../../shared/constants/calendar';
import { getMonthName } from '../../../shared/utils/dates.util';
import useAddToCart from '../../../hooks/useAddToCart';
import { REGULARIZATION_REGARATION_PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';

type PeriodMonthWithSelected = PeriodMonth & {
	isSelected: boolean;
	index: number;
};

const ModalAnnualPendingMonths = () => {
	const dispatch = useDispatch();
	const { handleAddProductBySlugBulk, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { showModal, annualYearSelected } = useSelector(
		(state: RootState) => state.taxCalendar,
	);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const [isLoading, setIsLoading] = useState({
		monthly: false,
	});

	const [pendingMonthlyDeclarations, setPendingMonthlyDeclarations] = useState<
		PeriodMonthWithSelected[] | []
	>([]);

	const toggleSelectedMonthlyDeclaration = (index: number) => {
		const updatedMonthlyDeclarations = pendingMonthlyDeclarations.map(
			(declaration, i) =>
				i === index
					? { ...declaration, isSelected: !declaration.isSelected }
					: declaration,
		);
		setPendingMonthlyDeclarations(updatedMonthlyDeclarations);
	};

	const selectAllMonthlyDeclarations = () => {
		const updatedMonthlyDeclarations = pendingMonthlyDeclarations.map(
			(declaration) => ({
				...declaration,
				isSelected: !pendingMonthlyDeclarations.every(
					(declaration) => declaration.isSelected,
				),
			}),
		);
		setPendingMonthlyDeclarations(updatedMonthlyDeclarations);
	};

	const getMonthlyDeclarationOverview = async () => {
		setPendingMonthlyDeclarations([]);
		try {
			setIsLoading((prev) => ({ ...prev, monthly: true }));
			const response = await fetchMonthlyDeclarationOverview(
				userProfile?.id!,
				annualYearSelected,
			);
			const filteredResponse = response?.declarations?.filter(
				(declaration: PeriodMonth) =>
					declaration?.month &&
					declaration?.status === TaxboardDeclarationStatus.PENDING,
			);

			const declarationsWithSelected = filteredResponse.map(
				(declaration: PeriodMonth, index: number) => ({
					...declaration,
					isSelected: false,
					index,
				}),
			);

			setPendingMonthlyDeclarations(declarationsWithSelected);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, monthly: false }));
		}
	};

	const handleAddProducts = async () => {
		const selectedDeclarations = pendingMonthlyDeclarations.filter(
			(declaration) => declaration.isSelected,
		);

		const productsToAdd = selectedDeclarations.map((declaration) => ({
			slug: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
			additional_information: {
				year: annualYearSelected,
				month: declaration.month,
			},
		}));

		try {
			await handleAddProductBySlugBulk(productsToAdd);
			logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
				event_type: EVENT_TYPES.TAXBOARD_GROUP_TAX_SELECTED,
				tax_list: productsToAdd,
			});
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.error_to_add_product,
				description: ERROR.failed_to_fetch,
			};
			dispatch(showAlert(alert));
		}
	};

	const RowDataMonthlyPending: TRowData[] = pendingMonthlyDeclarations?.map(
		(declaration) => ({
			onClick: () => toggleSelectedMonthlyDeclaration(declaration.index),
			rowStyle: 'cursor-pointer',
			rowContent: {
				checkbox: {
					content: (
						<div className='w-fit h-5 flex items-center'>
							<Checkbox
								checked={declaration.isSelected}
								onClick={() =>
									toggleSelectedMonthlyDeclaration(declaration.index)
								}
							/>
						</div>
					),
				},
				month: {
					content: getMonthName(declaration.month, true),
				},
				regime: {
					content: 'Plataformas Tecnológicas',
				},
				delay_days: {
					content: `${CALENDAR.calculateDelayDays(annualYearSelected, declaration?.month)} días`,
				},
			},
		}),
	);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const checkIsAllSelected = pendingMonthlyDeclarations.every(
		(declaration) => declaration.isSelected,
	);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			getMonthlyDeclarationOverview();
		}

		return () => {
			isMounted = false;
		};
	}, [annualYearSelected]);

	useEffect(() => {
		getMonthlyDeclarationOverview();
	}, []);

	return (
		<Modal
			open={showModal.annualPendingMonths}
			onClose={() =>
				dispatch(
					setShowModal({ modalName: 'annualPendingMonths', show: false }),
				)
			}
			sx='w-[94%] !max-w-[600px]'
		>
			<Text
				size='subtitle'
				weight='bold'
			>
				Declaraciones pendientes {annualYearSelected}
			</Text>
			<hr className='mt-4' />
			<Text
				size='caption'
				weight='light'
				sx='mt-4 pb-3 lg:mb-0'
			>
				Pon al día todas las declaraciones mensuales y podrás presentar tu
				declaración anual
			</Text>
			<hr />
			<Text
				size='body-3'
				weight='regular'
				sx='mt-4 mb-2 lg:mb-0 hidden md:block'
			>
				Selecciona las declaraciones mensuales que quieres poner al día.
			</Text>
			<div
				className='flex gap-2 items-center cursor-pointer'
				onClick={selectAllMonthlyDeclarations}
			>
				<Checkbox
					className='!ml-[-10px]'
					checked={checkIsAllSelected}
				/>
				<Text
					size='body-3'
					weight='light'
					sx='ml-[-10px]'
				>
					Seleccionar todas
				</Text>
			</div>
			<div className='mt-1 max-h-[calc(50dvh)] sm:max-h-[calc(30dvh)] overflow-auto'>
				{isLoading.monthly ? (
					<>
						<div className=' w-full h-12 bg-white/50 z-10 flex justify-center items-center'>
							<CircularProgress size={32} />
						</div>
					</>
				) : (
					<Table
						columns={[
							{
								title: '',
								dataIndex: 'checkbox',
								headColumnStyle: 'w-10',
							},
							{
								title: 'Mes',
								dataIndex: 'month',
							},
							{
								title: 'Régimen',
								dataIndex: 'regime',
							},
							{
								title: 'Días de atraso',
								dataIndex: 'delay_days',
								align: 'center',
							},
						]}
						rowsData={RowDataMonthlyPending}
						sx='mt-2'
						responsive={isMobile}
					/>
				)}
			</div>
			<hr className='mt-4' />
			<Button
				loading={isLoadingAddToCart.products}
				disabled={
					!pendingMonthlyDeclarations?.some(
						(declaration) => declaration.isSelected,
					)
				}
				label={'Presentar declaraciones pendientes'}
				sx='!font-light leading-4 '
				onClick={handleAddProducts}
			/>
		</Modal>
	);
};

export default ModalAnnualPendingMonths;
