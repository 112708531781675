const HERU_UTM = {
	SOURCE: 'utm_source',
	MEDIUM: 'utm_medium',
	CAMPAIGN: 'utm_campaign',
};

const HERU_UTM_VALUES = {
	DIRECT_PAGE_SEARCH: 'direct_page_search',
	SIGNUP_LANDING_PAGE: 'signup_landing_page',
	ORGANIC_SEARCH: 'organic_search',
};
const PLANS_UTM_VALUES = {
	HOME_TAB: {
		utm_source: 'heru',
		utm_campaign: 'feature_home',
		utm_medium: 'pyp_navbar',
	},
	HOME_BANNER: {
		utm_source: 'heru',
		utm_campaign: 'feature_home',
		utm_medium: 'banner_suscribirme',
	},
	HOME_BANNER_DEDUCTIBLES: {
		utm_source: 'heru',
		utm_campaign: 'feature_home',
		utm_medium: 'banner_deducciones',
	},
	SERVICES_STATUS: {
		utm_source: 'heru',
		utm_campaign: 'feature_estado_de_servicio',
		utm_medium: 'banner_suscribirme',
	},
	SERVICES_STATUS_DEDUCTIBLES: {
		utm_source: 'heru',
		utm_campaign: 'feature_estado_de_servicio',
		utm_medium: 'banner_deducciones',
	},
	TAXES_TABLE: {
		utm_source: 'heru',
		utm_campaign: 'feature_tablero',
		utm_medium: 'banner_suscribirme',
	},
	ONBOARDING: {
		utm_source: 'heru',
		utm_campaign: 'feature_onboarding',
		utm_medium: 'omitir_vinculacion',
	},
	ASAT_RECOMMENDATION: {
		utm_source: 'heru',
		utm_campaign: 'feature_onboarding',
		utm_medium: 'ayuda_vinculacion',
	},
	LINK_SUCCESS: {
		utm_source: 'heru',
		utm_campaign: 'feature_onboarding',
		utm_medium: 'vinculacion_exitosa',
	},
	COUNTDOWN_ANNUAL: {
		utm_source: 'heru',
		utm_campaign: 'countdownanuales',
		utm_medium: 'home',
	},
	COUNTDOWN_ANNUAL_PACK: {
		utm_source: 'heru',
		utm_campaign: 'anual50OFF',
		utm_medium: 'banner_countdown_home',
	},
};

export { HERU_UTM, HERU_UTM_VALUES, PLANS_UTM_VALUES };
