import Text from '../../../components/atoms/Text';
import ManSmilingBg from '../../../assets/img/png/man-smiling.png';
import EndRoundedPath from '../../../assets/img/svg/end-rounded.svg';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useAddToCart from '../../../hooks/useAddToCart';
import { DATES } from '../../../shared/utils/dates';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import { CircleNotch } from '@phosphor-icons/react';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { RegimeFFT } from '../../../@types/Products.type';
import { REGIMES_CODE } from '../../../@types/Regime.type';

const FiscalBanner = () => {
	const navigate = useNavigate();
	const logClevertapEvent = useCleverTapEvent();

	const showBanner = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_TAXBOARD_BANNER,
	);

	const { handleAddProductBySlug, isLoading } = useAddToCart();

	const { activeSuscriptions, activeAnnualSubscription } = useSelector(
		(state: RootState) => state.profile,
	);

	const recommendSubscription =
		activeAnnualSubscription && activeSuscriptions?.length === 1;

	const handleOnClick = async () => {
		if (recommendSubscription) {
			logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
				event_type: EVENT_TYPES.TAXBOARD_BANNER_SELECTED,
				banner_type: 'suscripcion',
			});
			navigate(ROUTES.PLANS_AND_PRICES);
		} else {
			logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
				event_type: EVENT_TYPES.TAXBOARD_BANNER_SELECTED,
				banner_type: 'anuales',
			});
			await handleAddProductBySlug(PRODUCTS_SLUG.ANNUAL_DECLARATION, {
				year: DATES.currentYear - 1,
				regimes: [RegimeFFT[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]],
			});
		}
	};

	if (!showBanner || (!recommendSubscription && activeAnnualSubscription))
		return null;

	return (
		<div
			onClick={handleOnClick}
			className='hidden sm:flex absolute top-20 lg:top-20 right-12 max-w-[50%] lg:max-w-[500px] bg-h-primary-90 h-[88px] overflow-hidden rounded-lg cursor-pointer'
		>
			<div className='flex-1 px-4 flex'>
				<Text
					size='body-1'
					weight='medium'
				>
					¡Suscríbete y deja tus impuestos en nuestras manos!
				</Text>
			</div>
			<img
				src={EndRoundedPath}
				alt='End rounded'
				className='z-10'
			/>
			<img
				src={ManSmilingBg}
				alt='Man smiling'
				className='w-1/3 object-cover object-bottom -ml-[13px]'
			/>
			{isLoading.products && (
				<CircleNotch
					className='absolute top-2 right-2 animate-spin text-h-primary'
					weight='bold'
					width={20}
					height={20}
				/>
			)}
		</div>
	);
};

export default FiscalBanner;
