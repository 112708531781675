interface Props {
	children: React.ReactNode;
	sx?: string;
}

const Container: React.FC<Props> = ({ children, sx }) => {
	return (
		<div className={`bg-white shadow-md rounded-lg p-2 sm:p-5 ${sx}`}>
			{children}
		</div>
	);
};

export default Container;
