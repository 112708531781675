import { BlockedBulkList } from '../pages/serviceStatus/components/organisms/ActiveServices';
import HERU_API from '../shared/constants/heruApi';
import { del, get, patch, post } from '../shared/utils/FetchSSL.util';

const getAllServiceStatus = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_SERVICE_STATUS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

const getListBlockedCart = async (tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SERVICES_STATUS_LIST_BLOCKED_CART,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postCreateBlockedCart = async (tokenSession?: string) => {
	try {
		const response = await post(
			HERU_API.HERU_API_SERVICES_STATUS_CREATE_BLOCKED_CART,
			{
				status: 'open',
				shopping_cart_treatment: 'ACQUISITION_FOR_RENEWAL_FAILED',
			},
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getPriceBlockedCart = async (subsId: number, tokenSession?: string) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SERVICES_STATUS_GET_PRICE_BLOCKED_CART(subsId),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postAddItemToBlockedCart = async (
	orderId: number,
	blockedList: BlockedBulkList[],
	tokenSession?: string,
) => {
	try {
		const response = await post(
			HERU_API.HERU_API_SERVICES_STATUS_ADD_ITEM_TO_BLOCKED_CART(orderId),
			blockedList,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteItemBlockedCart = async (
	orderId: number,
	itemId: number,
	tokenSession?: string,
) => {
	try {
		const response = await del(
			HERU_API.HERU_API_SERVICES_STATUS_DELETE_ITEM_BLOCKED_CART(
				orderId,
				itemId,
			),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getAccountants = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_SERVICE_STATUS_ACCOUNTANTS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const updateFormStep = async (
	serviceId: number,
	serviceStepId: number,
	request: any,
	tokenSession?: string,
) => {
	try {
		const response = await patch(
			HERU_API.HERU_API_SERVICE_STATUS_COMPLETE_STEP(serviceId, serviceStepId),
			request,
			{ token: true, isNewGateway: true, tokenSession },
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getDeclarationDocument = async (
	year: number,
	month?: number,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SERVICES_STATUS_DECLARATIONS_DOCUMENTS(year, month),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const getAnnualDeclarationDocument = async (
	year: number,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			HERU_API.HERU_API_SERVICES_STATUS_ANNUAL_DECLARATIONS_DOCUMENTS(year),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	getAllServiceStatus,
	getListBlockedCart,
	postCreateBlockedCart,
	getPriceBlockedCart,
	postAddItemToBlockedCart,
	deleteItemBlockedCart,
	getAccountants,
	updateFormStep,
	getDeclarationDocument,
	getAnnualDeclarationDocument,
};
