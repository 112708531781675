import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Trash } from '@phosphor-icons/react';
import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react';

//? Types
import { Order } from '../../../../../@types/Order.type';

//? Components
import Text from '../../../../../components/atoms/Text';
import Button from '../../../../../components/atoms/Button';
import Divider from '../../../../../components/atoms/Divider';
import ModalOrganizations from '../../organisms/ModalOrganizations';

//? Utils
import { ORGANIZATIONS } from '../../../../../shared/constants/organizations';
import GROWTHBOOK_FEATURE_FLAGS from '../../../../../shared/utils/growthbook.util';

//? Redux
import { RootState } from '../../../../../redux/store';
interface CouponProps {
	order: Order;
	addCoupon: (code: string) => void;
	isLoadCoupon: boolean;
}

const Coupon: React.FC<CouponProps> = ({ order, addCoupon, isLoadCoupon }) => {
	const gb = useGrowthBook();

	const [code, setCode] = useState<string>('');
	const [showOrgsModal, setShowOrgsModal] = useState(false);

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const isOrgsAvailable = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_ORGANIZATIONS,
	);

	const loadGrowthbook = async () => {
		await gb?.loadFeatures();
	};

	const handleDeleteCoupon = () => {
		setCode('');
		addCoupon('');
	};

	const activeOrgs = userProfile?.organizations?.filter((org) =>
		[
			ORGANIZATIONS.RAPPI,
			ORGANIZATIONS.DIDI,
			ORGANIZATIONS.AIRBNB,
			ORGANIZATIONS.UBER,
			ORGANIZATIONS.CORNERSHOP,
			ORGANIZATIONS.MERCADO_ENVIOS,
			ORGANIZATIONS.UBER_EATS,
		]?.some((keyword) => org?.name?.includes(keyword)),
	);

	useEffect(() => {
		loadGrowthbook();
	}, []);

	return (
		<div className='w-full p-6 bg-white rounded-lg shadow flex-col justify-start items-start gap-4 inline-flex'>
			<ModalOrganizations
				showModal={showOrgsModal}
				setShowModal={setShowOrgsModal}
			/>
			<Text
				size='xl'
				weight='bold'
				sx='hidden lg:block'
			>
				Cupones y descuentos
			</Text>
			<Text
				size='l'
				weight='bold'
				sx='lg:hidden block'
			>
				Cupones y descuentos
			</Text>
			<div className='w-full'>
				<Divider />
			</div>
			<div className='flex items-center w-full'>
				<input
					type='text'
					className={`border border-gray-300 rounded-md w-full px-4 py-2 focus:outline-none ${
						order?.coupon?.code ? 'bg-h-gray-20' : 'bg-white'
					} ${order?.coupon?.code && 'text-gray-500'} flex-1`}
					placeholder={order?.coupon?.code ?? 'Código de descuento'}
					id='codeInput'
					value={code}
					disabled={!!order?.coupon?.code}
					onChange={(e) => setCode(e.target.value)}
				/>

				{order?.coupon?.code ? (
					<Button
						variant='primary'
						loading={isLoadCoupon}
						icon={
							<Trash
								color='var(--h-gray-10)'
								size={25}
								weight='light'
							/>
						}
						label=''
						sx='!border-transparent justify-center items-center !w-[70px] h-[41px] lg:!w-[115px] lg:h-[42px] ml-3'
						onClick={handleDeleteCoupon}
					/>
				) : (
					<Button
						disabled={!code.trim()}
						loading={isLoadCoupon}
						variant='primary'
						label={'Validar'}
						sx='!w-[80px] h-[41px] lg:!w-[115px] lg:h-[42px] ml-2'
						onClick={() => addCoupon(code)}
					/>
				)}
			</div>

			{isOrgsAvailable && (
				<>
					{/* Desktop */}
					<div className='hidden lg:flex lg:flex-col mb-2'>
						<div className='flex flex-col'>
							{activeOrgs?.map((org) => (
								<div
									className='flex items-center mb-2'
									key={org.id}
								>
									<img
										src={org?.extra_fields?.icon_url_png}
										alt={org.name}
										width={30}
										height={30}
									/>
									<Text
										size='caption'
										weight='regular'
										color='medium'
										sx='ml-2.5'
									>{`Tu cuenta de ${org.name} ya está conectada`}</Text>
								</div>
							))}
						</div>

						<div className='flex flex-row items-center'>
							<Text
								size='caption'
								weight='regular'
								color='medium'
								sx='!block'
							>
								Descubre si cuentas con descuentos por alianza y
								<button onClick={() => setShowOrgsModal(true)}>
									<Text
										size='caption'
										weight='regular'
										color='primary'
										sx='underline ml-1'
									>
										canjéalos aquí
									</Text>
								</button>
							</Text>
						</div>
						<Text
							size='mini-1'
							weight='light'
							color='medium'
							sx='mt-3'
						>
							*Los descuentos no son acumulables.
						</Text>
					</div>

					{/* Mobile */}
					<div className='flex flex-col lg:hidden mt-1'>
						<div className='flex flex-col'>
							{activeOrgs?.map((org) => (
								<div
									className='flex items-center mb-2'
									key={org.id}
								>
									<img
										src={org?.extra_fields?.icon_url_png}
										alt={org.name}
										width={30}
										height={30}
									/>
									<Text
										size='caption'
										weight='regular'
										color='medium'
										sx='ml-2.5'
									>{`Tu cuenta de ${org.name} ya está conectada`}</Text>
								</div>
							))}
						</div>

						<div className='flex flex-row mt-2'>
							<Text
								size='caption'
								weight='regular'
								color='medium'
								sx='!block'
							>
								Descubre si cuentas con descuentos por alianza
								<button onClick={() => setShowOrgsModal(true)}>
									<Text
										size='caption'
										weight='regular'
										color='primary'
										sx='underline ml-1'
									>
										y canjéalos aquí.
									</Text>
								</button>
							</Text>
						</div>
						<Text
							size='mini-1'
							weight='light'
							color='medium'
							sx='mt-4'
						>
							*Los descuentos no son acumulables.
						</Text>
					</div>
				</>
			)}
		</div>
	);
};

export default Coupon;
