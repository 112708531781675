import { useMediaQuery } from '@mui/material';
import Text from '../../../components/atoms/Text';

type InvoicingStepperProps = {
	currentStep: number;
	avaibleSteps: number;
	handleChangeStep: (step: number) => void;
};

const InvoicingStepper: React.FC<InvoicingStepperProps> = ({
	currentStep = 1,
	avaibleSteps = 1,
	handleChangeStep,
}) => {
	const steps = [
		{
			label: 'Datos',
		},
		{
			label: 'Productos',
		},
		{
			label: 'Verificación',
		},
		{
			label: 'Emisión',
		},
	];

	const isMobile = useMediaQuery('(max-width: 768px)');

	const onChangeStep = (step: number) => {
		if (step <= avaibleSteps) {
			handleChangeStep(step);
		}
	};

	return (
		<div className='w-full flex items-center sm:gap-4 md:px-8'>
			{steps.map(({ label }, index) => {
				const isLast = index !== steps.length - 1;
				const isAvaible = index + 1 <= avaibleSteps;
				const isEnabled = index + 1 <= currentStep || isAvaible;

				return (
					<>
						<button
							type='button'
							onClick={() => onChangeStep(index + 1)}
							key={`step-index-${index}`}
							className={`flex flex-col items-center justify-center gap-1  transition p-1 rounded-lg 
							${index + 1 !== currentStep && isAvaible ? 'opacity-60' : ''}
							${isAvaible ? 'cursor-pointer hover:bg-h-primary-95' : 'cursor-not-allowed'}
							`}
						>
							<Text
								size='caption'
								color='white'
								sx={`p-1 rounded-full h-6 w-6 justify-center items-center ${
									isEnabled ? 'bg-h-primary' : 'bg-h-text-light'
								}`}
							>
								{index + 1}
							</Text>
							<Text
								size={isMobile ? 'mini-1' : 'body-3'}
								color={isEnabled ? 'primary' : 'light'}
								weight={isMobile ? 'regular' : 'light'}
								sx='text-center'
							>
								{label}
							</Text>
						</button>
						{isLast && (
							<hr
								key={`hr-index-${index}`}
								className={`w-full h-0.5 min-w-2 
								${index + 2 <= avaibleSteps ? 'bg-h-primary-90' : 'bg-h-neutral-90'}
							`}
							/>
						)}
					</>
				);
			})}
		</div>
	);
};

export default InvoicingStepper;
