import { FAILURE_REASONS } from '../constants/failed';
import { PERIODICITY_TYPES } from '../constants/general';
import images from '../constants/images';
import { ORGANIZATIONS } from '../constants/organizations';

const generateUUID = () => {
	const randomUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
		/[xy]/g,
		function (c) {
			const r = (Math.random() * 16) | 0;
			const v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		},
	);

	return randomUUID;
};

const convertFiletoBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

const getFirstLetter = (name: string) => {
	if (name.length > 0) {
		return name.charAt(0).toUpperCase();
	} else {
		return '';
	}
};

const formatPrice = (value: number) => {
	const rounded = Math.round(value / 100);
	return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getPeriodicity = (days: number) => {
	const months = Math.trunc(days / 30);

	switch (months) {
		case 1:
			return PERIODICITY_TYPES.MONTHLY;
		case 2:
			return PERIODICITY_TYPES.BIMONTHLY;
		case 3:
			return PERIODICITY_TYPES.QUARTERLY;
		case 6:
			return PERIODICITY_TYPES.BIANNUAL;
		case 12:
			return PERIODICITY_TYPES.ANNUAL;
		default:
			return '';
	}
};

const formatCurrency = (value: number | null, showCurrency: boolean = true) => {
	if (typeof value !== 'number' || !value) return '-';

	return new Intl.NumberFormat('es-MX', {
		style: showCurrency ? 'currency' : 'decimal',
		currency: 'MXN',
	}).format(value);
};

const getRecurrency = (value: number) => {
	switch (value) {
		case 30: {
			return 'Mensual';
		}
		case 60: {
			return 'Bimestral';
		}
		case 90: {
			return 'Trimestral';
		}
		case 180: {
			return 'Semestral';
		}
		case 365: {
			return 'Anual';
		}
		default: {
			return '';
		}
	}
};
const capitalizeFirstLetter = (string: string) =>
	string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();

const capitalizeText = (text: string) => {
	return text
		?.split(' ')
		?.map((word) => capitalizeFirstLetter(word))
		?.join(' ');
};

const getFailuresText = (failure: string) => {
	if (failure === FAILURE_REASONS.INSUFFICIENT_FUNDS) {
		return 'Tu suscripción se encuentra en pausa debido a que tienes fondos insuficientes, ';
	} else {
		return 'Tu suscripción se encuentra en pausa debido a que no pudimos realizar la renovación, ';
	}
};

const getBrowser = () => {
	if (
		(navigator.userAgent.indexOf('Opera') ||
			navigator.userAgent.indexOf('OPR')) != -1
	) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Edg') != -1) {
		return 'Edge';
	} else if (navigator.userAgent.indexOf('Chrome') != -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') != -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') != -1) {
		return 'Firefox';
	} else if (
		navigator.userAgent.indexOf('MSIE') != -1 ||
		!!document?.DOCUMENT_NODE == true
	) {
		//IF IE > 10
		return 'IE';
	} else {
		return 'unknown';
	}
};

const getBgMobileByOrg = (org: string) => {
	if (org === ORGANIZATIONS.UBER_EATS) {
		return images.bgUberEatsMobile;
	} else if (org === ORGANIZATIONS.UBER) {
		return images.bgUberMobile;
	} else if (org === ORGANIZATIONS.AIRBNB) {
		return images.bgAirbnbMobile;
	} else if (org === ORGANIZATIONS.CORNERSHOP) {
		return images.bgCornershopMobile;
	} else if (org === ORGANIZATIONS.DIDI) {
		return images.bgDidiMobile;
	} else if (org === ORGANIZATIONS.RAPPI) {
		return images.bgRappiMobile;
	} else if (org === ORGANIZATIONS.MERCADO_ENVIOS) {
		return images.bgMercadoEnviosMobile;
	} else {
		return '';
	}
};

const getBgDesktopByOrg = (org: string) => {
	if (org === ORGANIZATIONS.UBER_EATS) {
		return images.bgUberEatsDesktop;
	} else if (org === ORGANIZATIONS.UBER) {
		return images.bgUberDesktop;
	} else if (org === ORGANIZATIONS.AIRBNB) {
		return images.bgAirbnbDesktop;
	} else if (org === ORGANIZATIONS.CORNERSHOP) {
		return images.bgCornershopDesktop;
	} else if (org === ORGANIZATIONS.DIDI) {
		return images.bgDidiDesktop;
	} else if (org === ORGANIZATIONS.RAPPI) {
		return images.bgRappiDesktop;
	} else if (org === ORGANIZATIONS.MERCADO_ENVIOS) {
		return images.bgMercadoEnviosDesktop;
	} else {
		return '';
	}
};

const getBorderColorByOrg = (org: string) => {
	switch (org) {
		case ORGANIZATIONS.RAPPI:
			return 'border-h-error-70';
		case ORGANIZATIONS.DIDI:
			return 'border-orange-300';
		case ORGANIZATIONS.AIRBNB:
			return 'border-red-300';
		case ORGANIZATIONS.UBER:
			return 'border-h-gray-100';
		case ORGANIZATIONS.CORNERSHOP:
			return 'border-orange-600';
		case ORGANIZATIONS.MERCADO_ENVIOS:
			return 'border-lime-400';
		default:
			return 'border-h-green-40';
	}
};

const parseDeductibleAmount = (amount: string) =>
	amount.includes('$') && amount.includes(',')
		? Math.floor(parseInt(amount.split('$')[1].replace(/,/g, ''), 10))
		: amount.includes('$')
			? Math.floor(parseInt(amount.split('$')[1], 10) ?? 0)
			: Math.floor(parseInt(amount, 10) ?? 0);

const arrayRange = (start: number, stop: number, step: number) =>
	Array.from(
		{ length: (stop - start) / step + 1 },
		(_, index) => start + index * step,
	);

const getCountryFlag = (shortName: string) => {
	switch (shortName) {
		case 'MX':
			return images.flagMx;
		case 'US':
			return images.flagUs;
		case 'CO':
			return images.flagCo;
		case 'PE':
			return images.flagPe;
		case 'GT':
			return images.flagGt;
		case 'AR':
			return images.flagAr;
		case 'UY':
			return images.flagUy;
		case 'CL':
			return images.flagCl;
		case 'GB':
			return images.flagGb;
		case 'ES':
			return images.flagEs;
		default:
			return '';
	}
};

const isLocalStorageEnabled = () => {
	try {
		const key = `__storage__test`;
		window.localStorage.setItem(key, 'null');
		window.localStorage.removeItem(key);
		return true;
	} catch (e) {
		return false;
	}
};

const isSessionStorageEnabled = () => {
	try {
		const key = `__storage__test`;
		window.sessionStorage.setItem(key, 'null');
		window.sessionStorage.removeItem(key);
		return true;
	} catch (e) {
		return false;
	}
};

const getQueryParam = (param: string) => {
	const params = new URLSearchParams(location.search);
	return params.get(param);
};

export {
	generateUUID,
	convertFiletoBase64,
	getFirstLetter,
	formatCurrency,
	formatPrice,
	getRecurrency,
	getPeriodicity,
	capitalizeFirstLetter,
	getFailuresText,
	getBrowser,
	getBgMobileByOrg,
	getBgDesktopByOrg,
	getBorderColorByOrg,
	parseDeductibleAmount,
	arrayRange,
	capitalizeText,
	getCountryFlag,
	isLocalStorageEnabled,
	isSessionStorageEnabled,
	getQueryParam,
};
