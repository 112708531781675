import { Item } from '../../@types/Order.type';
import { RegularizationProductTypes } from '../../shared/constants/SlugsProducts';

type StateRegularizationRegimes = {
	regularizationsPTDeliver: Item[];
	regularizationsPTDriver: Item[];
	regularizationsPTHost: Item[];
	regularizationsPTSeller: Item[];
	regularizationsPTFleetPartner: Item[];
	regularizationsPTFull: Item[];
	regularizationsRESICOPlus: Item[];
	regularizationsRESICOExpress: Item[];
	regularizationsArriendosMonth: Item[];
	regularizationsArriendosTrimester: Item[];
	regularizationsRIF: Item[];
	regularizationsActEmp: Item[];
};

type ActionRegularizationProd = {
	type: string;
	payload: Item[];
};

export const initialRegularizationRegimesProd: StateRegularizationRegimes = {
	regularizationsPTDeliver: [],
	regularizationsPTDriver: [],
	regularizationsPTHost: [],
	regularizationsPTSeller: [],
	regularizationsPTFleetPartner: [],
	regularizationsPTFull: [],
	regularizationsRESICOPlus: [],
	regularizationsRESICOExpress: [],
	regularizationsArriendosMonth: [],
	regularizationsArriendosTrimester: [],
	regularizationsRIF: [],
	regularizationsActEmp: [],
};

export const regularizationRegimesProdsReducer = (
	state: StateRegularizationRegimes,
	action: ActionRegularizationProd,
) => {
	switch (action.type) {
		//? PLATAFORMA TECNOLOGICA
		case RegularizationProductTypes.getRegDeliverProd:
			return {
				...state,
				regularizationsPTDeliver: action.payload,
			};

		case RegularizationProductTypes.getRegDriverProd:
			return {
				...state,
				regularizationsPTDriver: action.payload,
			};

		case RegularizationProductTypes.getRegHostProd:
			return {
				...state,
				regularizationsPTHost: action.payload,
			};
		case RegularizationProductTypes.getRegSellerProd:
			return {
				...state,
				regularizationsPTSeller: action.payload,
			};
		case RegularizationProductTypes.getRegFleetPartnerProd:
			return {
				...state,
				regularizationsPTFleetPartner: action.payload,
			};
		case RegularizationProductTypes.getRegAllActivitiesProd:
			return {
				...state,
				regularizationsPTFull: action.payload,
			};

		//? RESICO
		case RegularizationProductTypes.getRegResicoPlus:
			return {
				...state,
				regularizationsRESICOPlus: action.payload,
			};

		case RegularizationProductTypes.getRegResicoExpress:
			return {
				...state,
				regularizationsRESICOExpress: action.payload,
			};

		//? ARRIENDOS
		case RegularizationProductTypes.getRegArriendosMonth:
			return {
				...state,
				regularizationsArriendosMonth: action.payload,
			};

		case RegularizationProductTypes.getRegArriendosTrimester:
			return {
				...state,
				regularizationsArriendosTrimester: action.payload,
			};

		//? RIF
		case RegularizationProductTypes.getRegRIF:
			return {
				...state,
				regularizationsRIF: action.payload,
			};

		//? ACTIVIDAD EMPRESARIAL
		case RegularizationProductTypes.getRegActEmp:
			return {
				...state,
				regularizationsActEmp: action.payload,
			};

		default:
			return state;
	}
};
