import HERU_API from '../shared/constants/heruApi';
import { post } from '../shared/utils/FetchSSL.util';

const sendUserFeedback = async (
	userId: number,
	message: string,
	tokenSession?: string,
) => {
	try {
		const body = {
			user_id: userId,
			survey_form_id: 78,
			survey_responses: [
				{
					questionary_id: 160,
					response: [
						'Web app 2 - Churn flow',
						JSON.stringify({ feedback: message }),
					],
				},
			],
		};
		const response = await post(HERU_API.HERU_API_USER_INTERACTION, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export { sendUserFeedback };
