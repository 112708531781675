import { useEffect, useMemo, useState } from 'react';
import Text from '../../../components/atoms/Text';
import Tabs from '../../../components/molecules/Tabs';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import { useForm } from 'react-hook-form';
import Selector from '../../../components/atoms/Selector';
import { formatCurrency } from '../../../shared/utils/general.util';
import { getMonthName } from '../../../shared/utils/dates.util';
import Tag from '../../../components/molecules/Tag';
import { Funnel, Info } from '@phosphor-icons/react';
import CALENDAR from '../../../shared/constants/calendar';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateYear,
	updatePeriod,
	updateMonth,
	setSelectedPeriod,
	updatedAnnualYearSelected,
	setFiscalResume,
} from '../../../redux/slices/taxCalendar.slice';
import Button from '../../../components/atoms/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import ModalFilterPeriod from '../molecules/ModalFilterPeriod';
import TagIconCalendar from '../atoms/TagIconCalendar';
import { RootState } from '../../../redux/store';
import { CircularProgress, Tooltip, useMediaQuery } from '@mui/material';
import {
	fetchDeclarationsByMonth,
	fetchDeclarationsByYear,
	fetchMonthlyDeclarationOverview,
	fetchYearlyDeclarationsOverview,
} from '../../../services/taxes.service';
import { monthsNumberArray } from '../../../shared/constants/dates';
import { showAlert } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import ERROR from '../../../shared/constants/errors';
import {
	PeriodMonth,
	TPeriod,
	TPeriodicity,
	TaxboardDeclarationStatus,
} from '../../../@types/Calendar.type';
import Container from '../../../components/atoms/Container';
import AnnualTemporalyDetail from './AnnualTemporalyDetail';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { UserRegime } from '../../../@types/TaxProfile';

export const itemsPeriods = [
	{
		label: 'Mensuales',
		slug: 'monthly' as TPeriod,
	},
	{
		label: 'Anuales',
		slug: 'yearly' as TPeriod,
	},
];

interface Props {}

interface FormBody {
	year: number;
}

type PeriodYear = {
	year: number;
	status: string;
};

type PeriodOverview = {
	monthly: PeriodMonth[] | null;
	yearly: PeriodYear[] | null;
};

const yearsOptions = getLastYears(
	CALENDAR.supportedNumberOfYears.monthly,
	DATES.currentYear,
).map((year) => ({
	value: year,
	label: year,
}));

const monthsEmpty = monthsNumberArray.map((month) => ({
	month,
	status: '',
	year: DATES.currentYear,
	declarationsCount: 1,
}));

const yearsEmpty = getLastYears(
	CALENDAR.supportedNumberOfYears.monthly,
	DATES.currentYear,
).map((year) => ({
	year,
	status: '',
}));

const Calendar: React.FC<Props> = ({}) => {
	const logClevertapEvent = useCleverTapEvent();

	const {
		year: yearCalendar,
		period,
		selectedPeriod,
		month: selectedMonth,
		annualYearSelected,
		fiscalResume,
	} = useSelector((state: RootState) => state.taxCalendar);
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { isRecentlyLinked } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);

	const [periodOverview, setPeriodOverview] = useState<PeriodOverview>({
		monthly: monthsEmpty,
		yearly: null,
	});
	const [isLoading, setIsLoading] = useState({
		monthly: false,
		yearly: false,
		selected: false,
	});
	const [activeTab, setActiveTab] = useState<TPeriod>(period);
	const [showModal, setShowModal] = useState(false);
	const { control, watch, setValue } = useForm<FormBody>({
		defaultValues: { year: yearCalendar },
	});
	const dispatch = useDispatch();

	const yearSelected = watch('year');

	const updateYearState = () => {
		dispatch(updateYear(yearSelected));

		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_SELECT_FILTER,
			source: activeTab,
			regime: userRegimes?.map((regime: UserRegime) => regime?.name) || '[]',
		});
	};

	const disablePeriod = useMemo(
		() => (period: PeriodMonth) => {
			if (
				(DATES.currentYear === yearSelected &&
					period.month > DATES.currentMonth) ||
				period.status === ''
			) {
				return true;
			}
		},
		[yearSelected],
	);

	const monthClass = useMemo(
		() => (period: PeriodMonth) => {
			if (
				yearSelected === DATES.currentYear &&
				period.month === DATES.currentMonth - 1
			) {
				return;
			}
			if (disablePeriod(period)) {
				return 'opacity-30';
			}
			return 'cursor-pointer';
		},
		[yearSelected],
	);

	const getTagByStatus = useMemo(
		() => (status: string, quantity: number) => {
			if (status === '') return null;

			return (
				<Tag
					label={CALENDAR.taxboardDeclarationStatusLabel[status]}
					type='filled'
					caseVariant={CALENDAR.labelColor[status] ?? 'filter'}
					iconRight={<TagIconCalendar status={status} />}
					quantity={
						status === TaxboardDeclarationStatus.DOWNLOADING
							? undefined
							: quantity
					}
				/>
			);
		},
		[],
	);

	const getMonthlyDeclarationOverview = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, monthly: true }));
			const response = await fetchMonthlyDeclarationOverview(
				userProfile?.id!,
				yearSelected,
			);

			dispatch(
				setFiscalResume({
					...fiscalResume,
					year: response,
				}),
			);

			if (isRecentlyLinked) {
				const downloadingPeriods = monthsNumberArray.map((month) => ({
					month,
					status:
						month >= DATES.currentMonth && yearSelected == DATES.currentYear
							? ''
							: TaxboardDeclarationStatus.DOWNLOADING,
					year: yearSelected,
					declarationsCount: 1,
				}));

				setPeriodOverview((prev) => ({
					...prev,
					monthly: downloadingPeriods,
				}));
				return;
			}

			const fillMissingMonths = monthsNumberArray.map((month) => {
				const monthFound = response.declarations?.find(
					(item: PeriodMonth) => item.month === month,
				);
				if (!monthFound) {
					return {
						month,
						status: '',
						years: yearSelected,
						declarationsCount: 1,
					};
				}
				return monthFound;
			});

			setPeriodOverview((prev) => ({
				...prev,
				monthly: fillMissingMonths,
			}));
		} catch (error) {
			setPeriodOverview((prev) => ({
				...prev,
				monthly: monthsEmpty,
			}));
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading((prev) => ({ ...prev, monthly: false }));
		}
	};

	const getDeclarationsByMonth = async (month: number) => {
		if (isLoading.selected) return;
		try {
			setIsLoading((prev) => ({ ...prev, selected: true }));
			const response = await fetchDeclarationsByMonth(
				month,
				yearSelected,
				userProfile?.id!,
			);

			dispatch(
				setFiscalResume({
					...fiscalResume,
					month: response,
				}),
			);

			return response?.declarations;
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
			return null;
		} finally {
			setIsLoading((prev) => ({ ...prev, selected: false }));
		}
	};

	const getYearlyDeclarationsOverview = async () => {
		try {
			const response = await fetchYearlyDeclarationsOverview(userProfile?.id!);
			const yearsFiltered = response
				.filter((item: PeriodYear) => item.year > DATES.currentYear - 6)
				.sort((a: PeriodYear, b: PeriodYear) => b.year - a.year);

			if (isRecentlyLinked) {
				setPeriodOverview((prev) => ({
					...prev,
					yearly: yearsFiltered.map((year: PeriodYear) => ({
						...year,
						status:
							year.status === TaxboardDeclarationStatus.PENDING
								? TaxboardDeclarationStatus.DOWNLOADING
								: year.status,
					})),
				}));
				return;
			}

			setPeriodOverview((prev) => ({
				...prev,
				yearly: yearsFiltered,
			}));
		} catch (error) {
			setPeriodOverview((prev) => ({
				...prev,
				yearly: yearsEmpty,
			}));
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		}
	};

	const getDeclarationsByYear = async (year: number) => {
		if (isLoading.selected) return;
		try {
			setIsLoading((prev) => ({ ...prev, selected: true }));
			const response = await fetchDeclarationsByYear(year, userProfile?.id!);
			return response;
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
			return null;
		} finally {
			setIsLoading((prev) => ({ ...prev, selected: false }));
		}
	};

	const onClickMonthlyPeriod = async (period: PeriodMonth) => {
		if (
			disablePeriod(period) ||
			period.status === TaxboardDeclarationStatus.DOWNLOADING
		) {
			return;
		}
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_PERIOD_DETAIL_VIEWED,
			source: 'declaraciones_mensuales',
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		dispatch(updateMonth(period.month));
		dispatch(
			setSelectedPeriod({
				...selectedPeriod,
				month: {
					...selectedPeriod.month,
					detail: await getDeclarationsByMonth(period.month),
				},
			}),
		);
	};

	const onClickAnnualPeriod = async (year: number, status: string) => {
		if (status === TaxboardDeclarationStatus.DOWNLOADING) {
			return;
		}

		dispatch(updatedAnnualYearSelected(year));
		dispatch(
			setSelectedPeriod({
				...selectedPeriod,
				year: {
					...selectedPeriod.year,
					selectedYear: await getDeclarationsByYear(year),
				},
			}),
		);
	};

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_SELECT_FILTER,
			source: activeTab,
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});
	}, [yearCalendar]);

	useEffect(() => {
		getMonthlyDeclarationOverview();
		getYearlyDeclarationsOverview();
	}, [yearSelected, userProfile]);

	useEffect(() => {
		updateYearState();
	}, [yearSelected, userProfile]);

	useEffect(() => {
		dispatch(updatePeriod(activeTab));
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_SELECT_PERIODICITY,
			source: activeTab,
			regime: userRegimes?.map((regime: UserRegime) => regime?.name) || '[]',
		});
	}, [activeTab]);

	useEffect(() => {
		setActiveTab(TPeriodicity.MONTHLY);
	}, []);

	const isMobile = useMediaQuery('(max-width: 640px)');

	const handleChangePeriodicity = (val: string) => {
		logClevertapEvent(ANALYTICS_EVENTS.TAX_DASHBOARD, {
			event_type: EVENT_TYPES.TAXBOARD_SELECT_PERIODICITY,
			source: activeTab,
			regime:
				userRegimes?.map((regime: UserRegime) => regime?.name).join(' - ') ||
				'[]',
		});

		setActiveTab(val as TPeriod);
	};

	return (
		<Container sx='flex-1'>
			<section>
				<Modal
					open={showModal}
					onClose={() => setShowModal(false)}
					sx='w-[94%] !max-w-md !p-0 !p-0'
					showCloseButton={false}
				>
					<ModalFilterPeriod
						setActiveTab={setActiveTab}
						setShowModal={setShowModal}
						setValue={setValue}
						activeTab={activeTab}
					/>
				</Modal>
				<div className='flex flex-row justify-between items-center'>
					<div>
						<Text
							size={isMobile ? 'mini-1' : 'body-3'}
							color='gray'
						>
							{isMobile
								? period === 'monthly'
									? 'Declaraciones Mensuales'
									: 'Declaraciones Anuales'
								: 'Declaraciones'}
						</Text>
						{!isMobile && (
							<Tabs
								items={itemsPeriods}
								onClickTab={(val) => handleChangePeriodicity(val)}
								sx='!rounded-full !shadow-none border-h-neutral-90 border-[0.5px] ml-0 !px-2'
								sizes='small'
								activeTabDefault={activeTab}
							/>
						)}
					</div>

					{period === 'monthly' && !isMobile && (
						<Selector
							control={control}
							name='year'
							options={yearsOptions}
							label='Año'
						/>
					)}
					{isMobile && (
						<div className='px-2.5 py-1.5 bg-h-neutral-90/40 rounded-full flex items-center justify-center'>
							<Button
								onClick={() => setShowModal(true)}
								label='Filtrar'
								variant='text'
								labelColor='medium'
								labelSize='caption'
								beforeIcon={<Funnel size={18} />}
							></Button>
						</div>
					)}
				</div>

				<div className='lg:hidden bg-h-neutral-95  px-2 pt-0.5 pb-1.5 rounded border-h-neutral-90 border-[0.5px] mt-4'>
					<Text
						size='mini-1'
						color='gray'
					>
						{yearSelected}
					</Text>
					<Tooltip
						title='Son gastos realizados y que son indispensables para que seguir
							generando ingresos.'
					>
						<div className='flex flex-row justify-between'>
							<Text
								size='caption'
								color='medium'
							>
								Gastos deducibles
								<Info
									size={20}
									color='var(--h-gray-40)'
								/>
							</Text>

							<Text
								size='caption'
								color='medium'
							>
								{formatCurrency(fiscalResume?.year?.deductibles ?? 0)}
							</Text>
						</div>
					</Tooltip>
				</div>

				<div className='my-4 relative'>
					{isLoading.monthly && period === TPeriodicity.MONTHLY && (
						<div className='absolute top-0 left-0 w-full h-full bg-white/50 z-10 flex justify-center items-center'>
							<CircularProgress size={32} />
						</div>
					)}
					{activeTab === TPeriodicity.MONTHLY && (
						<div className='grid grid-cols-3 gap-3 h-full'>
							{periodOverview?.monthly?.map((period, index: number) => (
								<button
									onClick={() => onClickMonthlyPeriod(period)}
									disabled={disablePeriod(period)}
									key={`month-index-${index}`}
									className={`bg-h-neutral-95 min-h-[64px] sm:min-h-[124px] rounded-lg p-2 sm:p-4 shadow-sm relative sm:flex sm:flex-col justify-between ${monthClass(
										period,
									)}`}
								>
									{isLoading.selected && selectedMonth === period.month && (
										<div className='absolute top-0 left-0 w-full h-full bg-white/50 z-10 flex justify-center items-center'>
											<CircularProgress size={32} />
										</div>
									)}
									<div className='flex sm:block justify-between'>
										<Text
											size={isMobile ? 'caption' : 'body-1'}
											sx={`capitalize mb-1 ${isMobile ? '!block !truncate' : ''}`}
											color='medium'
										>
											{getMonthName(period.month)}
										</Text>
										{isMobile ? (
											<TagIconCalendar
												status={period.status}
												isMobile
											/>
										) : (
											getTagByStatus(period.status, period.declarationsCount)
										)}
									</div>
									{!isMobile &&
										period.status !== TaxboardDeclarationStatus.DOWNLOADING && (
											<Text
												color='gray'
												size='caption'
												sx='text-right mt-5 justify-end w-full'
											>
												Ver periodo
											</Text>
										)}
								</button>
							))}
						</div>
					)}

					{activeTab === TPeriodicity.YEARLY && false && (
						<div className='grid grid-cols-3 gap-3'>
							{periodOverview?.yearly?.map((period, index) => (
								<button
									key={`year-index-${index}`}
									className={`bg-h-neutral-95 rounded-lg p-4 shadow-sm sm:min-h-[270px] flex flex-col justify-between w-full items-center sm:items-start relative`}
									onClick={() =>
										onClickAnnualPeriod(period.year, period.status)
									}
								>
									{isLoading.selected && annualYearSelected === period.year && (
										<div className='absolute top-0 left-0 w-full h-full bg-white/50 z-10 flex justify-center items-center'>
											<CircularProgress size={32} />
										</div>
									)}
									<div>
										<Text
											size={isMobile ? 'caption' : 'body-1'}
											sx={`!block capitalize mb-2 ${
												isMobile ? 'text-center' : 'text-left'
											}`}
											color='medium'
										>
											{period.year}
										</Text>
										{isMobile ? (
											<TagIconCalendar
												status={period.status}
												isMobile
												isAnnual
											/>
										) : (
											getTagByStatus(period.status, 1)
										)}
									</div>
									{!isMobile &&
										period.status !== TaxboardDeclarationStatus.DOWNLOADING && (
											<Text
												color='gray'
												size='caption'
												sx='w-full justify-end'
											>
												Ver periodo
											</Text>
										)}
								</button>
							))}
						</div>
					)}

					{activeTab === TPeriodicity.YEARLY && <AnnualTemporalyDetail />}
				</div>
				<div className='flex gap-2 items-center'>
					<Info
						size={20}
						color='var(--h-primary)'
						className='min-w-[20px]'
					/>
					<Text
						size={isMobile ? 'mini-1' : 'caption'}
						weight='light'
						color='medium'
						sx={isMobile ? '!leading-4' : ''}
					>
						La información en el tablero de impuestos es referente únicamente a
						Plataformas Tecnológicas. Para los demás regímenes, obten ayuda
						personalizada de un contador
					</Text>
				</div>
			</section>
		</Container>
	);
};

export default Calendar;
