import React, { useEffect, useMemo, useState } from 'react';
import HomeCard from '../../molecules/HomeCard';
import Text from '../../atoms/Text';
import {
	Warning,
	HourglassMedium,
	CurrencyDollar,
	Timer,
	SealCheck,
} from '@phosphor-icons/react';
import { periodSections } from '../../../shared/constants/data';
import { getBgColorRow } from '../../../shared/utils/styles.util';
import {
	IFiscalStatus,
	IObligations,
	TFiscalMonthStatus,
	TFiscalYearStatus,
} from '../../../@types/Fiscal.type';
import { getMonthName } from '../../../shared/utils/dates.util';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import noFileImg from '../../../assets/img/svg/noFile.svg';
import { DATES } from '../../../shared/utils/dates';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../shared/constants/routes';
import { PYP_CATEGORY_LABELS } from '../../../pages/plan/data/ecommerceData';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { TaxboardDeclarationStatus } from '../../../@types/Calendar.type';
interface Props {
	fiscalStatus?: IFiscalStatus;
	hideMonthlyPeriod?: boolean;
}

const MyFiscalStatus: React.FC<Props> = ({
	fiscalStatus,
	hideMonthlyPeriod,
}) => {
	const [activeSection, setActiveSection] = useState<keyof IObligations>(
		hideMonthlyPeriod ? 'annual' : 'monthly',
	);

	const [currentObligation, setCurrentObligation] = useState<
		TFiscalMonthStatus[] | TFiscalYearStatus[]
	>([]);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const navigate = useNavigate();
	const logClevertapEvent = useCleverTapEvent();

	useEffect(() => {
		if (fiscalStatus) {
			if (activeSection === 'annual') {
				setCurrentObligation(
					fiscalStatus[activeSection].filter(
						(i) => i.year !== DATES.currentYear,
					),
				);
			} else {
				setCurrentObligation(fiscalStatus[activeSection]);
			}
		}
	}, [fiscalStatus, activeSection]);

	useEffect(() => {
		if (hideMonthlyPeriod) {
			setActiveSection('annual');
		}
	}, [hideMonthlyPeriod]);

	const getLabelColor = (status: TaxboardDeclarationStatus) => {
		switch (status) {
			case TaxboardDeclarationStatus.PRESENTED:
				return '!text-h-green-50';
			case TaxboardDeclarationStatus.PENDING:
				return '!text-h-red-50';

			case TaxboardDeclarationStatus.DOWNLOADING:
				return '!text-h-blue-50';

			default:
				return '!text-h-yellow-50';
		}
	};

	const getIcon = (status: TaxboardDeclarationStatus) => {
		switch (status) {
			case TaxboardDeclarationStatus.PRESENTED:
				return (
					<SealCheck
						size={20}
						weight='fill'
						color='var(--h-green-50)'
					/>
				);

			case TaxboardDeclarationStatus.IN_PROCESS:
				return (
					<HourglassMedium
						size={20}
						weight='fill'
						color='var(--h-yellow-50)'
					/>
				);

			case TaxboardDeclarationStatus.PENDING:
				return (
					<Warning
						size={20}
						weight='fill'
						color='var(--h-red-50)'
					/>
				);

			case TaxboardDeclarationStatus.PENDING_PAY:
				return (
					<CurrencyDollar
						size={20}
						weight='fill'
						color='var(--h-yellow-50)'
					/>
				);

			case TaxboardDeclarationStatus.DOWNLOADING:
				return (
					<Timer
						size={20}
						weight='fill'
						color='var(--h-blue-50)'
					/>
				);
		}
	};

	const periodName = useMemo(
		() => (year: number, month: number) => {
			if (month) {
				return `${getMonthName(month)} ${year}`;
			}
			return `${year}`;
		},
		[fiscalStatus],
	);

	const logObligationFilter = () => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_FISCAL_OBLIGATIONS_FILTER,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
		});
	};

	const obligationFilterHandler = (slug: keyof IObligations) => {
		logObligationFilter();
		setActiveSection(slug);
	};

	const handlerObligation = () => {
		const index = activeSection === 'monthly' ? 1 : 2;
		navigate(ROUTES.PLANS_AND_PRICES, {
			state: { category: PYP_CATEGORY_LABELS[index] }, //? ASAT category
		});
	};

	return (
		<HomeCard
			title='Mis obligaciones'
			sx='mt-8'
		>
			<>
				<div className='flex flex-row gap-x-4'>
					{periodSections
						.filter((_, index) => (hideMonthlyPeriod ? index >= 1 : true))
						.map(({ slug, label }) => (
							<button
								key={slug}
								onClick={() => obligationFilterHandler(slug)}
							>
								<Text
									size='l'
									weight={activeSection === slug ? 'bold' : 'medium'}
									color={activeSection === slug ? 'primary' : 'dark'}
									sx={activeSection === slug ? 'underline' : ''}
								>
									{label}
								</Text>
							</button>
						))}
				</div>

				<div className='flex flex-col'>
					{currentObligation.length === 0 && (
						<div className='flex flex-col justify-center items-center'>
							<img
								src={noFileImg}
								alt='Icono de factura bloqueada'
							/>
							<Text
								size='m'
								weight='medium'
							>
								Por el momento no tienes ninguna obligación fiscal
							</Text>
						</div>
					)}
					{currentObligation.map((item, key) => (
						<button
							onClick={handlerObligation}
							disabled={item.status === TaxboardDeclarationStatus.PRESENTED}
							key={`obligation-${key}`}
							className={`flex flex-row py-4 items-center ${getBgColorRow(
								key,
							)}`}
						>
							{getIcon(item.status)}
							<Text
								size='s'
								sx={`ml-2 ${getLabelColor(item.status)} capitalize`}
								weight='medium'
							>
								{item.status.toLowerCase()}
							</Text>
							<div className='ml-6'>
								<Text
									size='s'
									weight='medium'
									sx='capitalize'
								>
									{periodName(item.year, (item as TFiscalMonthStatus).month)}
								</Text>
								<Text
									size='s'
									sx='capitalize'
								>
									{item.status.toLowerCase()}
								</Text>
							</div>
						</button>
					))}
				</div>
			</>
		</HomeCard>
	);
};

export default React.memo(MyFiscalStatus);
