import React, { useMemo, useState } from 'react';

import { Radio } from '@mui/material';
import {
	CaretDown,
	CaretUp,
	CheckCircle,
	ShoppingCart,
} from '@phosphor-icons/react';

import { ListProducts } from '../../../../@types/Products.type';
import Text from '../../../../components/atoms/Text';
import Heading from '../../../../components/atoms/Heading';
import Button from '../../../../components/atoms/Button';
import { annualBenefits } from '../../data/ecommerceData';
import { PRODUCTS_SLUG } from '../../../../shared/constants/SlugsProducts';
import { DATES } from '../../../../shared/utils/dates';
import { PROMO_PRICES } from '../../../../shared/constants/general';
import useRemoteConfig from '../../../../hooks/useRemoteConfig.hook';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import hotSaleImg from '../../../../assets/img/svg/hot_sale.svg';
interface AnnualProps {
	products: ListProducts[];
	isMobile: boolean;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}

const Annual: React.FC<AnnualProps> = ({
	products,
	isMobile,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const [selectedYear, setSelectedYear] = useState('2022');
	const [openBenefits, setOpenBenefits] = useState(false);
	const { remoteProducts } = useRemoteConfig(['product_prices']);

	const renderYearsBySelectedRegime = () => {
		const years: string[] = [];
		const maxYearsBefore = 5;
		const startIndex = DATES.currentMonth < 12 ? 1 : 0;

		for (let index = startIndex; index < maxYearsBefore; index++) {
			const yearString = (DATES.currentYear - index).toString();
			years.push(yearString);
		}

		return (
			<>
				{years.map((year, index) => (
					<div
						key={index}
						className='flex flex-row'
					>
						<Radio
							style={{
								color: selectedYear === year ? '#1D8FF3' : undefined,
							}}
							checked={selectedYear === year}
							onChange={() => {
								setSelectedYear(year);
							}}
							value={year}
							name='radio-buttons'
							inputProps={{ 'aria-label': 'A' }}
						/>
						<Text
							size='m'
							sx='ml-2'
							color='dark'
						>
							{year}
						</Text>
					</div>
				))}
			</>
		);
	};

	const getAnnual = products[2]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION_HARD,
	);

	const handleAddCheckout = () => {
		handleAddProduct(getAnnual[0]?.product_versions[0]?.id, {
			regime: [],
			year: selectedYear,
		});
	};

	const price = useMemo(() => {
		return (
			// remoteProducts?.[0]?.['annual-declarations-default'] ??
			PROMO_PRICES.ANNUAL_BACKLOG_REGULARIZATION_PRICE
		);
	}, [remoteProducts]);

	return (
		<>
			{isMobile ? (
				<div className='flex flex-col rounded-md border mb-56'>
					<div className='sticky top-0 z-10 bg-white'>
						<div
							className={`${isHotSale ? 'bg-h-hot-sale' : 'bg-h-blue-60'} relative p-4 rounded-t-md flex flex-col items-center`}
						>
							<>
								{isHotSale && (
									<div className='mb-12'>
										<div className='absolute left-0 -top-16 flex items-center justify-center'>
											<img
												src={hotSaleImg}
												alt='hot sale heru'
												className='w-56 h-56'
											/>
										</div>
									</div>
								)}
								<Text
									size='s'
									color='white'
									sx='text-center'
									weight='regular'
								>
									Ponte al día declarando tus periodos pendientes, evita multas,
									sanciones o recargos del SAT.
								</Text>

								<div
									className={`border ${isHotSale ? 'border-white' : 'border-blue-200'} my-4 mx-4 w-full`}
								/>
								<Text
									size='xl'
									color='white'
									weight='bold'
								>
									Declaraciones Anuales
								</Text>
							</>
						</div>

						{isHotSale ? (
							<div className='flex items-center justify-end w-full my-4'>
								<div className='flex items-center mr-4'>
									<Text
										color='gray'
										size={'s'}
										sx='max-w-10 mr-5 md:mr-8 text-center'
									>
										Precio regular
									</Text>
									<Text
										color='gray'
										size={'label'}
										weight='regular'
										sx='line-through'
									>
										$800
									</Text>
								</div>
								<div className='flex items-center ml-2'>
									<Text
										color='darkMenu'
										weight='bold'
										size={'s'}
										sx='max-w-10 text-center mr-5'
									>
										HOT HERU
									</Text>
									<Heading
										color='darkMenu'
										size={'xs'}
										weight='medium'
									>
										$
									</Heading>
									<Heading
										color='darkMenu'
										size={'m'}
										weight='medium'
									>
										400
									</Heading>
								</div>
							</div>
						) : (
							<div className='flex flex-row justify-end mr-4 mt-2'>
								{!!remoteProducts && (
									<Heading
										size='s'
										weight='bold'
									>
										${price}
									</Heading>
								)}
							</div>
						)}
					</div>

					<div className='flex flex-col px-4'>
						<div className='border-b pb-4 mt-5'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								Selecciona los años que deseas declarar
							</Text>
							<div className='grid grid-cols-2 gap-4 mt-2'>
								{renderYearsBySelectedRegime()}
							</div>
						</div>

						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{annualBenefits.map((benefit, index) => (
										<div
											key={`annual-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div
						className={`fixed bottom-12 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg`}
					>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							onClick={handleAddCheckout}
							disabled={!selectedYear}
							className={`${
								!selectedYear
									? 'bg-gray-400'
									: isHotSale
										? 'bg-h-blue-20'
										: 'bg-h-primary'
							} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			) : (
				<div>
					<div
						className={`${isHotSale ? 'bg-h-hot-sale' : 'bg-h-blue-60'} relative p-4 rounded-t-xl flex flex-col items-center`}
					>
						{isHotSale && (
							<div className='mb-12'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}

						<Text
							size='label'
							color='white'
							sx='text-center'
							weight='regular'
						>
							Ponte al día declarando tus periodos pendientes, evita multas,
							sanciones o recargos del SAT.
						</Text>

						<div
							className={`border ${isHotSale ? 'border-white' : 'border-blue-200'} my-4 mx-4 w-full`}
						/>
						<Heading
							size='m'
							color='white'
							weight='bold'
						>
							Declaraciones Anuales
						</Heading>
					</div>

					{isHotSale ? (
						<div className='flex items-center justify-end w-full mt-3'>
							<div className='flex items-center mr-4'>
								<Text
									color='gray'
									size={'label'}
									sx='max-w-10 mr-5 md:mr-8 text-center'
								>
									Precio regular
								</Text>
								<Text
									color='gray'
									size={'body-1'}
									weight='regular'
									sx='line-through'
								>
									$800
								</Text>
							</div>
							<div className='flex items-center ml-2'>
								<Text
									color='darkMenu'
									weight='bold'
									size={'label'}
									sx='max-w-10 text-center mr-5'
								>
									HOT HERU
								</Text>
								<Heading
									color='darkMenu'
									size={'s'}
									weight='medium'
								>
									$
								</Heading>
								<Heading
									color='darkMenu'
									size={'l'}
									weight='medium'
								>
									400
								</Heading>
							</div>
						</div>
					) : (
						<div className='flex flex-row justify-end mr-4 mt-2'>
							{!!remoteProducts && (
								<Heading
									size='s'
									weight='bold'
								>
									${price}
								</Heading>
							)}
						</div>
					)}

					<div className='flex flex-col px-4 py-1 overflow-y-auto max-h-[450px]'>
						<div className='border-b pb-4 mt-5'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								Selecciona los años que deseas declarar
							</Text>
							<div className='flex flex-row justify-between mt-2'>
								{renderYearsBySelectedRegime()}
							</div>
						</div>

						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{annualBenefits.map((benefit, index) => (
										<div
											key={`annual-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className='fixed bottom-0 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg'>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							onClick={handleAddCheckout}
							disabled={!selectedYear}
							className={`${
								!selectedYear
									? 'bg-gray-400'
									: isHotSale
										? 'bg-h-blue-20'
										: 'bg-h-primary'
							} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(Annual);
