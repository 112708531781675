import React, { useCallback, useEffect, useState } from 'react';

import DesktopPlans from './DesktopPlans';
import MobilePlans from './MobilePlans';
import { ListProducts } from '../../@types/Products.type';
import { getProducts } from '../../services/plans.service';
import {
	getOrders,
	postAddProductsBulk,
	postOrders,
} from '../../services/order.service';
import { Order } from '../../@types/Order.type';
import { PeriodsSelectedType } from '../../@types/Calendar.type';
import {
	getBimester,
	getBimesterType,
	getQuarter,
	getQuarterType,
} from '../../shared/utils/dates';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../redux/slices/alert.slice';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import { Item } from '../../@types/Ecommerce';
import { RootState } from '../../redux/store';
import ROUTES from '../../shared/constants/routes';
import { updateAmountOfItems } from '../../redux/slices/cart.slice';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import ERROR from '../../shared/constants/errors';
import { useFeatureValue } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import {
	getElasticyPrice,
	getPriceFromProductVersionId,
} from '../../shared/utils/products.utils';
import { APP_CONFIGURATIONS } from '../../shared/constants/configurations';
import { getProductIdBySlug } from '../../shared/utils/ecommerce.util';
import {
	MONTHLY_DECLARATION_PRODUCTS_SLUG,
	PRODUCTS_SLUG,
} from '../../shared/constants/SlugsProducts';
import clevertap from 'clevertap-web-sdk';
// import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
// import { isLocalStorageEnabled } from '../../shared/utils/general.util';
import growthbookFile from '../../shared/constants/growthbook';

interface Props {}

const Plan: React.FC<Props> = ({}) => {
	const [products, setProducts] = useState<ListProducts[]>([]);
	const [order, setOrder] = useState<Order[]>([]);
	const [isLoadingAddProduct, setIsLoadingAddProduct] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { dataLayer } = window as any;
	const logClevertapEvent = useCleverTapEvent();

	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [searchParams] = useSearchParams();

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const getAllProducts = useCallback(async () => {
		try {
			const data = await getProducts(tokenSession);
			if (data?.length > 0) {
				setProducts(data);
				const slugs = [
					PRODUCTS_SLUG.ANNUAL_DECLARATION,
					MONTHLY_DECLARATION_PRODUCTS_SLUG.DECL_FULL,
					PRODUCTS_SLUG.ASSISTANCE_ASAT,
				].map((slug) => ({
					[slug]: getElasticyPrice(
						getPriceFromProductVersionId(data, getProductIdBySlug(data, slug)),
						elasticityPrice.group_experiment,
					),
				}));
				console.log('profile push event', {
					Site: {
						pricing_experiment: elasticityPrice.group_experiment,
						...slugs[0],
						...slugs[1],
						...slugs[2],
					},
				});
				clevertap.profile.push({
					Site: {
						pricing_experiment: elasticityPrice.group_experiment,
						...slugs[0],
						...slugs[1],
						...slugs[2],
					},
				});
			}
		} catch (error) {}
	}, []);

	const getOrder = useCallback(async () => {
		try {
			const response = await getOrders('false', undefined, tokenSession);

			setOrder(response);
			if (response.length === 0) {
				createOrder();
			}
			dispatch(updateAmountOfItems(response?.[0]?.items?.length || 0));
		} catch (error) {}
	}, []);

	const createOrder = useCallback(async () => {
		try {
			const channelSource = searchParams.get('channel_source');
			let sourceChannel = 'web';
			if (channelSource === 'app') {
				sourceChannel = 'app';
			}
			const body = {
				status: 'open',
				source_channel: sourceChannel,
			};
			const response = await postOrders(body, tokenSession);
			if (response) {
				setOrder([response]);
			}
		} catch (error) {}
	}, []);

	const postAddProduct = useCallback(
		async (body: object, orderId: number) => {
			try {
				setIsLoadingAddProduct(true);

				const response = await postAddProductsBulk(orderId, body, tokenSession);

				dataLayer.push({ ecommerce: null });
				dataLayer.push({
					event: ANALYTICS_EVENTS.ADD_TO_CART,
					phone: userProfile?.cellphone,
					rfc: taxPayer?.code ?? '',
					ecommerce: {
						items: response?.map((item: Item) => ({
							price: item?.base_price / 100,
							item_id: item?.product_version?.product_id,
							item_name: item?.product_version?.product?.name,
							category: item?.product_version?.product?.regime?.toString(),
							item_category1: item?.product_version?.product?.category,
							item_category2: item?.purchase_type,
						})),
					},
				});

				const product = response?.[0]?.product_version?.product;

				const period =
					product?.regime?.join('') == 'RIF'
						? response
								?.map(
									(item: Item) =>
										`${item?.additional_information?.period}/${item?.additional_information?.year}`,
								)
								?.join(', ')
						: response
								?.map(
									(item: Item) =>
										`${item?.additional_information?.month}/${item?.additional_information?.year}`,
								)
								?.join(', ');

				const eventProperties = {
					event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
					product_name: product?.name,
					regime: product?.regime?.join(''),
					period,
				};
				logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

				setIsLoadingAddProduct(false);
				navigate(ROUTES.CHECKOUT);
			} catch (error: any | Error) {
				setIsLoadingAddProduct(false);
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: error?.response?.data?.message
						? 'Aviso'
						: ERROR.error_to_add_product,
					description: error?.response?.data?.message ?? ERROR.failed_to_fetch,
				};
				dispatch(showAlert(alert));
			}
		},
		[order, userProfile, taxPayer, dataLayer],
	);

	const handleAddToCart = (
		productId: number,
		valuePeriods: PeriodsSelectedType[] = [],
	) => {
		try {
			const body = [];
			if (valuePeriods && valuePeriods.length > 0) {
				valuePeriods.map((product: any) => {
					const _price =
						getElasticyPrice(
							getPriceFromProductVersionId(products, productId),
							elasticityPrice.group_experiment,
						) * 100;
					if (product.month) {
						body.push({
							product_version_id: productId,
							additional_information: {
								month: product.month,
								year: product.year,
							},
							custom_product: {
								payload_user_id: userProfile?.id,
								group_experiment: elasticityPrice.group_experiment,
								total_paid: _price.toString(),
							},
						});
					} else if (product.quarter) {
						body.push({
							product_version_id: productId,
							additional_information: {
								type: getQuarterType(product.quarter),
								period: getQuarter(product.quarter),
								year: product.year,
							},
							custom_product: {
								payload_user_id: userProfile?.id,
								group_experiment: elasticityPrice.group_experiment,
								total_paid: _price.toString(),
							},
						});
					} else if (product.bimester) {
						body.push({
							product_version_id: productId,
							additional_information: {
								type: getBimesterType(product.bimester),
								period: getBimester(product.bimester),
								year: product.year,
							},
							custom_product: {
								payload_user_id: userProfile?.id,
								group_experiment: elasticityPrice.group_experiment,
								total_paid: _price.toString(),
							},
						});
					}
				});
			} else {
				const filterProduct = products
					.filter((cat) =>
						cat.products.find((p) =>
							p.product_versions.find((pv) => pv.id === productId),
						),
					)?.[0]
					?.products?.find((p) =>
						p.product_versions.find((pv) => pv.id === productId),
					);

				if (filterProduct && APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
					const priceByProductVersion =
						filterProduct.product_versions.find((pv) => pv.id === productId)
							?.price || 0;

					const price =
						getElasticyPrice(
							Math.round(priceByProductVersion / 100),
							elasticityPrice.group_experiment,
						) * 100;
					body.push({
						product_version_id: productId,
						custom_product: {
							group_experiment: elasticityPrice.group_experiment,
							total_paid: price.toString(),
						},
					});
				} else {
					body.push({
						product_version_id: productId,
					});
				}
			}

			postAddProduct(body, order[0]?.id);
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: 'Ocurrió un error al agregar el producto al carrito',
				}),
			);
		}
	};

	const handleAddProduct = (
		productId: number,
		additional_information?: Object,
	) => {
		let body: any = {
			product_version_id: productId,
			additional_information,
		};

		if (APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY) {
			const _price =
				getElasticyPrice(
					getPriceFromProductVersionId(products, productId),
					elasticityPrice.group_experiment,
				) * 100;
			body = {
				...body,
				custom_product: {
					payload_user_id: userProfile?.id,
					group_experiment: elasticityPrice.group_experiment,
					total_paid: _price.toString(),
				},
			};
		}
		postAddProduct([body], order[0]?.id);
	};

	useEffect(() => {
		getOrder();
		getAllProducts();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.PLANS_AND_PRICES,
		});
	}, []);

	const loadGrowthbook = async () => {
		await growthbookFile.loadFeatures();
	};

	useEffect(() => {
		if (!!userProfile?.id) {
			loadGrowthbook();
			growthbookFile?.setAttributes({
				user_id: userProfile?.id,
			});
		}
	}, [userProfile?.id]);

	return (
		<div>
			<div className='hidden md:flex'>
				<DesktopPlans
					products={products}
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			</div>

			<div className='flex md:hidden'>
				<MobilePlans
					products={products}
					handleAddToCart={handleAddToCart}
					handleAddProduct={handleAddProduct}
					isLoadingAddProduct={isLoadingAddProduct}
				/>
			</div>
		</div>
	);
};

export default React.memo(Plan);
