import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import LITERALS from '../../../shared/constants/literals';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { showAlert, AlertPayload } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../Alert';
import { msgToolTipPassword } from '../../../shared/constants/SatLinkMessages';
import { convertFiletoBase64 } from '../../../shared/utils/general.util';
import InputFileNew from '../../atoms/InputFileNew';
import { onSendCertificates } from '../../../services/invoicing.service';
import { CaretCircleLeft } from '@phosphor-icons/react';
import { LINKS } from '../../../shared/constants/links';

interface Props {
	onCallbackSuccess?: (value: any) => void;
	onCallbackError?: (error: any) => void;
	viewTitle?: boolean;
	isModal?: boolean;
}

type SATDigitalSealForm = {
	cert_digitalSeal: any;
	key_digitalSeal: any;
	password: string;
};

const schemaDigitalSeals = yup
	.object({
		key_digitalSeal: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		cert_digitalSeal: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.MIN_LENGTH_PASSWORD)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const UploadDigitalSeals = ({
	onCallbackSuccess,
	onCallbackError,
	viewTitle = true,
	isModal = false,
}: Props) => {
	const {
		control: controlDigitalSeals,
		handleSubmit: handleSubmitDigitalSeals,
		formState: { errors: errorsEFirma },
	} = useForm<SATDigitalSealForm>({
		resolver: yupResolver(schemaDigitalSeals),
		defaultValues: {
			cert_digitalSeal: undefined,
			key_digitalSeal: undefined,
			password: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const [step, setStep] = useState(0);

	const onSubmitDigitalSeals: SubmitHandler<SATDigitalSealForm> = async (
		data,
	) => {
		if (!userProfile?.id || isLoading) return;
		if (!data.cert_digitalSeal || !data.key_digitalSeal || !data.password) {
			showAlertTop({
				title: 'Error',
				type: ALERT_TYPE.ERROR,
				description: LITERALS.REQUIRED_FIELDS,
			});
			return;
		}
		let cert = await convertFiletoBase64(data.cert_digitalSeal)
			.then((res) => res as string)
			.catch((err) => err);
		let key = await convertFiletoBase64(data.key_digitalSeal)
			.then((res) => res as string)
			.catch((err) => err);
		try {
			setIsLoading(true);
			cert.includes('data:') && (cert = cert.split(',')[1]);
			key.includes('data:') && (key = key.split(',')[1]);

			const body = {
				certificate: cert,
				privateKey: key,
				password: data.password,
			};

			const resp = await onSendCertificates(body, tokenSession);

			setIsLoading(false);

			if (!resp) {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.UPLOAD_DIGITAL_SEALS_ERROR,
				});
				onCallbackError?.(resp);
				return;
			}

			onCallbackSuccess?.(resp);
			showAlertTop({
				title: 'Éxito',
				type: ALERT_TYPE.SUCCESS,
				description: LITERALS.UPLOAD_DIGITAL_SEALS_SUCCESS,
			});
		} catch (error: Error | any) {
			setIsLoading(false);
			showAlertTop({
				title: 'Error',
				type: ALERT_TYPE.ERROR,
				description:
					error.response.data.msg ||
					error.response.data.message ||
					LITERALS.UPLOAD_DIGITAL_SEALS_ERROR,
			});
			onCallbackError?.(error);
		}
	};

	return (
		<div>
			{step === 0 && (
				<div className='p-2'>
					{viewTitle && (
						<Text
							weight='bold'
							color='medium'
							size='body-1'
							sx='mb-5'
						>
							Carga tus sellos digitales
						</Text>
					)}
					<form onSubmit={handleSubmitDigitalSeals(onSubmitDigitalSeals)}>
						<div className='flex flex-col gap-4'>
							{viewTitle && (
								<Text size='m'>
									Es un documento electrónico mediante el cual el SAT garantiza
									la vinculación entre la identidad de un sujeto o entidad y su
									certificado.
								</Text>
							)}

							<InputFileNew
								control={controlDigitalSeals}
								name='cert_digitalSeal'
								accept='.cer'
								errorMessage={errorsEFirma.cert_digitalSeal?.message as string}
								label='Sello digital (.cer)'
							/>

							<InputFileNew
								control={controlDigitalSeals}
								name='key_digitalSeal'
								accept='.key'
								errorMessage={errorsEFirma.key_digitalSeal?.message as string}
								label='Sello digital (.key)'
							/>
							<Input
								label='Contraseña clave privada'
								showTooltip
								tooltipMessage={msgToolTipPassword}
								control={controlDigitalSeals}
								name='password'
								type='password'
								placeholder='Contraseña clave privada'
								errorMessage={errorsEFirma.password?.message}
								maxLength={50}
								rules={{
									required: true,
									// minLength: 8,
									// pattern: REGEX.nameRegex,
								}}
							/>

							<div className='flex flex-col-reverse justify-end lg:flex-row items-center w-full'>
								<Button
									variant='outline'
									sx='w-full lg:w-60 mt-2 lg:mt-0 border-0 !shadow-none'
									onClick={() => setStep(1)}
									label='No tengo sellos digitales'
								/>

								<Button
									sx='w-full lg:w-60'
									loading={isLoading}
									disabled={
										!!errorsEFirma.key_digitalSeal ||
										!!errorsEFirma.cert_digitalSeal ||
										!!errorsEFirma.password ||
										isLoading
									}
									label='Cargar mis sellos'
									type='submit'
								/>
							</div>
						</div>
					</form>
				</div>
			)}
			{step === 1 && (
				<div>
					{isModal && (
						<div className='absolute left-2 top-2'>
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={24}
								height={24}
								className='cursor-pointer'
								onClick={() => setStep(0)}
							/>
						</div>
					)}

					<div className='flex flex-col w-full h-[600px] md:h-[780px] mt-4'>
						{!isModal && (
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={24}
								height={24}
								className='cursor-pointer mb-2'
								onClick={() => setStep(0)}
							/>
						)}
						<Text
							size='s'
							sx='text-center bg-h-gray-10 flex justify-center py-2 z-10'
							color='medium'
						>
							Aviso: SATID es una página ajena a Heru.
						</Text>
						<iframe
							title='Embedded Page'
							width='100%'
							height='100%'
							src={LINKS.SAT_ID_LINK}
							allowFullScreen
						></iframe>
					</div>
				</div>
			)}
		</div>
	);
};

export default UploadDigitalSeals;
