import React, { useEffect, useState } from 'react';
import Modal from '../../molecules/Modal/Modal';
import { heruModalWidget } from '../../../shared/constants/links';
import { X } from '@phosphor-icons/react';
import CustomIcon from '../../atoms/CustomIcon';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const WhatsappChat: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ ...props }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openChat = () => {
		setIsModalOpen(!isModalOpen);
	};
	const handleTapWhatsapp = () => {
		window.open(`${heruModalWidget}`, '_blank');
	};

	const [showWelcome, setShowWelcome] = useState(false);
	const [showThanks, setShowThanks] = useState(false);
	const [showHelp, setShowHelp] = useState(false);

	const userProfile = useSelector((state: RootState) => state.profile.user);

	useEffect(() => {
		if (isModalOpen) {
			const timeout1 = setTimeout(() => {
				setShowWelcome(true);
			}, 100);

			const timeout2 = setTimeout(() => {
				setShowThanks(true);
			}, 1000);

			const timeout3 = setTimeout(() => {
				setShowHelp(true);
			}, 2000);

			return () => {
				clearTimeout(timeout1);
				clearTimeout(timeout2);
				clearTimeout(timeout3);
			};
		}
	}, [isModalOpen]);

	return (
		<>
			<Modal
				type='dialog'
				open={isModalOpen}
				sx='w-[280px] h-[250px] md:mr-0 right-8 bottom-32 md:bottom-24 shadow-xl rounded-b-xl bg-white'
			>
				<>
					<div className='bg-h-primary-50 flex flex-row justify-around p-2.5 rounded-t-xl'>
						<CustomIcon
							name='heru-logo'
							width={43}
							color='#FFF'
						/>
						<Text
							size='s'
							color='white'
							sx='leading-loose'
						>
							Soporte Heru
						</Text>
						<button onClick={() => setIsModalOpen(false)}>
							<X
								size={20}
								color='white'
							/>
						</button>
					</div>
					<div className='rounded-b-xl flex flex-col items-center'>
						<div className='bg-sky-100 mt-6 mb-4 rounded-xl p-3 flex flex-col items-center'>
							<Text
								size='s'
								color='dark'
								weight='medium'
								sx={`text-center mb-2 transition-opacity duration-300 ${
									showWelcome ? 'opacity-100' : 'opacity-0'
								}`}
							>
								¡Bienvenido {userProfile?.first_name}!
							</Text>
							<Text
								size='s'
								color='medium'
								weight='regular'
								sx={`text-center mb-2 ${showThanks ? '' : 'hidden'}`}
							>
								Gracias por contactarte.
							</Text>
							<Text
								size='s'
								color='medium'
								weight='regular'
								sx={`text-center mb-2 ${showHelp ? '' : 'hidden'}`}
							>
								¿Cómo podemos ayudarte?
							</Text>
						</div>
						<div className='flex flex-row justify-center'>
							<Button
								label='¡Hablemos!'
								onClick={handleTapWhatsapp}
								className='bg-green-500 rounded-lg py-2 px-4 text-white font-medium text-sm transition-opacity duration-500 hover:opacity-80'
							/>
						</div>
					</div>
				</>
			</Modal>
			<div className='hidden md:flex'>
				<button
					{...props}
					className='fixed right-3 bottom-10 z-10'
					onClick={openChat}
				>
					<CustomIcon
						name='whatsapp'
						width={52}
					/>
				</button>
			</div>

			<div className='flex md:hidden'>
				<button
					{...props}
					className='fixed left-3 bottom-16 z-10'
					onClick={openChat}
				>
					<CustomIcon
						name='whatsapp'
						width={52}
					/>
				</button>
			</div>
		</>
	);
};

export default React.memo(WhatsappChat);