import React, { useMemo } from 'react';
import Text from '../../atoms/Text';
import {
	SealCheck,
	Warning,
	WarningCircle,
	XCircle,
} from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

export enum ALERT_TYPE {
	WARNING = 'warning',
	ERROR = 'error',
	SUCCESS = 'success',
	INFO = 'info',
}

export type AlertType = 'warning' | 'error' | 'success' | 'info';
type Props = {
	type: AlertType;
	description: string;
	title?: string;
	sx?: string;
};

const Alert: React.FC<Props> = ({ type, description, title, sx = '' }) => {
	const getIcon = useMemo(() => {
		switch (type) {
			case ALERT_TYPE.WARNING:
				return (
					<Warning
						size={32}
						weight='fill'
						color='var(--h-yellow-50)'
					/>
				);
			case ALERT_TYPE.ERROR:
				return (
					<XCircle
						size={32}
						weight='fill'
						color='var(--h-red-50)'
					/>
				);

			case ALERT_TYPE.INFO:
				return (
					<WarningCircle
						size={32}
						weight='fill'
						color='var(--h-blue-50)'
					/>
				);

			case ALERT_TYPE.SUCCESS:
				return (
					<SealCheck
						size={32}
						weight='fill'
						color='var(--h-green-50)'
					/>
				);
			default:
				<></>;
		}
	}, [type]);

	const getBackgroundColor = useMemo(() => {
		switch (type) {
			case ALERT_TYPE.WARNING:
				return 'bg-[var(--h-yellow-30)] border-[var(--h-yellow-50)]';
			case ALERT_TYPE.ERROR:
				return 'bg-[var(--h-red-30)] border-[var(--h-red-50)]';

			case ALERT_TYPE.INFO:
				return 'bg-[var(--h-blue-20)] border-[var(--h-blue-50)]';

			case ALERT_TYPE.SUCCESS:
				return 'bg-[var(--h-green-30)] border-[var(--h-green-50)]';

			default:
				'';
		}
	}, [type]);

	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div
			className={`animate-slice fixed top-14 left-0 right-0 max-w-fit  m-auto z-1600 flex flex-row  ${getBackgroundColor} justify-center items-center border-t-4 p-4 gap-x-2.5 transition-transform delay-500 rounded-lg ${sx}`}
		>
			{getIcon}
			<div>
				<Text
					size={isMobile ? 'm' : 'xl'}
					weight='bold'
				>
					{title}
				</Text>
				<Text
					size={isMobile ? 's' : 'l'}
					sx='mt-1'
				>
					{description}
				</Text>
			</div>
		</div>
	);
};

export default React.memo(Alert);
