import React, { useCallback, useEffect, useState } from 'react';

import { CaretCircleLeft } from '@phosphor-icons/react';

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { SubscriptionType } from '../../@types/Subscription';
import { getUserSuscriptions } from '../../services/suscription.service';
import Heading from '../../components/atoms/Heading';
import { CircularProgress } from '@mui/material';
import SubCard from './SubCard';
import ROUTES from '../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';

interface Props {}

const MySubscriptions: React.FC<Props> = ({}) => {
	const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
	const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

	const fetchUserSuscriptions = useCallback(async () => {
		try {
			setIsLoadingSubscriptions(true);
			const subs = await getUserSuscriptions(tokenSession);
			setSubscriptions(subs);
		} catch (error) {
		} finally {
			setIsLoadingSubscriptions(false);
		}
	}, []);

	useEffect(() => {
		fetchUserSuscriptions();
	}, []);

	const navigate = useNavigate();

	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const activeSubs = subscriptions.filter((item) => item?.active);
	const inactiveSubs = subscriptions.filter((item) => !item?.active);

	return (
		<div className='p-4 lg:px-12 lg:py-6 w-full h-full'>
			<div className='flex flex-row items-center mb-6 lg:mb-6'>
				<button
					onClick={() => {
						navigate(ROUTES.SHOPPING);
					}}
				>
					<CaretCircleLeft
						size={20}
						weight='fill'
						className='mr-3'
						color='var(--h-primary)'
					/>
				</button>
				<Heading
					size='xs'
					color='dark'
					weight='bold'
				>
					Mis suscripciones
				</Heading>
			</div>

			{isLoadingSubscriptions ? (
				<div className='flex items-center justify-center'>
					<CircularProgress size={22} />
				</div>
			) : (
				<div className='flex flex-col items-center'>
					{activeSubs.map((item) => (
						<div className='mb-2 md:mb-4 w-full'>
							<SubCard
								item={item}
								fetchUserSubscriptions={fetchUserSuscriptions}
							/>
						</div>
					))}
					{inactiveSubs.map((item) => (
						<div className='mb-2 md:mb-4 w-full'>
							<SubCard
								item={item}
								fetchUserSubscriptions={fetchUserSuscriptions}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default MySubscriptions;
