import { IObligations } from '../../@types/Fiscal.type';

export const periodSections = [
	{
		label: 'Mensuales',
		slug: 'monthly' as keyof IObligations,
	},
	{
		label: 'Anuales',
		slug: 'annual' as keyof IObligations,
	},
];

export enum REMOTE_CONFIG_PRODUCTS {
	DECLARATION_PRODUCTS = 'declaration_products',
	SUBSCRIPTION_DECLARATION_PRODUCTS = 'subscription_declaration_products',
	REGULARIZATION_PRODUCTS = 'regularization_products',
	ASAT_PRODUCTS = 'asat_products',
}
