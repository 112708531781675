import { useRef, useEffect, useState } from 'react';

type Properties = {
	value: number;
	unit: string;
	stop: boolean;
	play: boolean;
	getTime: (time: number) => void;
};

const Counter: React.FC<Properties> = ({
	value,
	getTime,
	stop,
	play,
	unit,
}) => {
	const [num, setNum] = useState(value);
	let intervalRef = useRef<NodeJS.Timeout>();

	const decreaseNum = () => setNum((prev) => prev - 1);

	useEffect(() => {
		intervalRef.current = setInterval(
			decreaseNum,
			1000,
		) as unknown as NodeJS.Timeout;
		return () => clearInterval(intervalRef.current!);
	}, []);

	useEffect(() => {
		if (num === 0) {
			getTime(num);
		}
	}, [num]);

	useEffect(() => {
		if (stop) {
			clearInterval(intervalRef.current!);
		}
	}, [stop]);

	useEffect(() => {
		if (play) {
			setNum(value);
			intervalRef.current = setInterval(
				decreaseNum,
				1000,
			) as unknown as NodeJS.Timeout;
			return () => clearInterval(intervalRef.current!);
		}
	}, [play]);

	return (
		<span className='text-white'>
			{num}
			{unit}
		</span>
	);
};
export default Counter;
