import { useEffect, useState } from 'react';
import Text from '../../atoms/Text';

interface Props {
	text: string;
	duration: number;
	center?: boolean;
	sx?: string;
}

const TypingText = ({ text, duration, center = false, sx }: Props) => {
	const [currentPosition, setCurrentPosition] = useState(0);
	const [textToShow, setTextToShow] = useState<string[]>([]);
	const [hideCircle, setHideCircle] = useState(false);
	const items = text?.split(' ');

	useEffect(() => {
		setCurrentPosition(0);
	}, [text]);

	useEffect(() => {
		if (currentPosition >= items?.length) {
			setHideCircle(true);
			return;
		}

		const intervalId = setInterval(() => {
			setCurrentPosition((prevPosition) => prevPosition + 1);
		}, duration);

		return () => {
			clearInterval(intervalId);
		};
	}, [currentPosition, items, duration]);

	useEffect(() => {
		setTextToShow([...textToShow, items[currentPosition]]);
	}, [currentPosition]);

	return (
		<Text
			sx={`flex gap-[3px] items-center flex-wrap mt-2 ${
				center && 'justify-center'
			} ${sx}`}
		>
			{textToShow?.map((word, index) => (
				<span key={`word-key-${index}`}>{word}</span>
			))}
			<span
				className={`ml-1 h-3 w-3 rounded-full inline-block bg-h-dark duration-300 ${
					hideCircle ? `scale-0 opacity-0 ${center && 'hidden'}` : ''
				}`}
			/>
		</Text>
	);
};

export default TypingText;
