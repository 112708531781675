import {
	ArrowsClockwise,
	CaretCircleLeft,
	CaretDown,
	Trash,
	Warning,
} from '@phosphor-icons/react';
import Heading from '../../components/atoms/Heading';
import ROUTES from '../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Profile } from '../../@types/Profile.type';
import { getUserProfile } from '../../services/profile.service';
import { useDispatch, useSelector } from 'react-redux';
import {
	AlertPayload,
	hideAlert,
	showAlert,
} from '../../redux/slices/alert.slice';
import Alert, { ALERT_TYPE } from '../../components/molecules/Alert';
import { RootState } from '../../redux/store';

import Text from '../../components/atoms/Text';

import FiscalProfileCard from '../../components/organisms/FiscalProfileCard';
import FiscalDocShare from '../../components/molecules/FiscalDocShare';
import Divider from '../../components/atoms/Divider';
import LITERALS from '../../shared/constants/literals';

import Button from '../../components/atoms/Button';
import {
	SATUnlinkedByUser,
	fetchSATLinkedById,
} from '../../services/satLinking.service';
import Modal from '../../components/molecules/Modal/Modal';
import DigitalSeals from './components/organisms/digitalSeals';
import EFirma from './components/organisms/eFirma';
import {
	fetchFiscalCredentials,
	fetchTaxPayer,
	postFiscalDocuments,
	removeLinks,
} from '../../services/taxes.service';
import { DATES } from '../../shared/utils/dates';
import SATLink from '../../components/molecules/SATLink';
import {
	addLinks,
	addTaxPayer,
	startLoader,
} from '../../redux/slices/taxProfile.slice';
import { TaxPayer, TaxProfile } from '../../@types/TaxProfile';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { isLocalStorageEnabled } from '../../shared/utils/general.util';
import { linkedAccount } from '../../redux/slices/sat.slice';
import { UserLinkedType } from '../../@types/SATLinking';

const TaxProfilePage = () => {
	const alert = useSelector((state: RootState) => state.alert);
	const [linkedStatus, setLinkedStatus] = useState<UserLinkedType>();
	const [taxPayer, setTaxpayer] = useState<TaxPayer>();

	const [userProfile, setUserProfile] = useState<null | Profile>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logClevertapEvent = useCleverTapEvent();

	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [deletedTaxPayer, setDeletedTaxPayer] = useState(false);
	const [showUpdateButton, setShowUpdateButton] = useState(true);

	const [openModal, setOpenModal] = useState(false);
	const [fielFlow, setFielFlow] = useState(false);
	const [isLoading, setIsLoading] = useState({
		getEFirma: false,
	});

	const { isFromApp } = useSelector((state: RootState) => state.profile);
	const [fiscalDocuments, setFiscalDocuments] = useState<TaxProfile>();
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const saveUserProfile = useCallback(async () => {
		try {
			const userInfo = await getUserProfile(tokenSession);
			setUserProfile(userInfo);
		} catch (error) {}
	}, []);

	const updateDocuments = useCallback(async () => {
		try {
			const body = {
				month: DATES.currentMonth,
				user_id: userProfile?.id,
				year: DATES.currentYear,
			};
			await postFiscalDocuments(
				{
					...body,
					type: 'on-demand-compliance',
				},
				tokenSession,
			);
			await postFiscalDocuments(
				{
					...body,
					type: 'on-demand-csf',
				},
				tokenSession,
			);
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'Aviso',
					description: 'Tus documentos se actualizaran pronto',
				}),
			);
			const currentTime = new Date().getTime();
			if (isLocalStorageEnabled()) {
				localStorage.setItem('lastUpdateTime', currentTime.toString());
			}
			setShowUpdateButton(false);
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description: 'No se pudieron actualizar tus documentos fiscales',
				}),
			);
		} finally {
			setIsVisibleModal(false);
		}
	}, [dispatch, userProfile]);

	const deleteTaxInformation = useCallback(async () => {
		try {
			await SATUnlinkedByUser(userProfile?.id!, tokenSession);
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'Desvinculación exitosa',
					description: 'Tus datos fiscales han sido desvinculados',
				}),
			);
			getFiscalCredentials();
			getTaxPayer();
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description: 'No se pudieron desvincular tus datos fiscales',
				}),
			);
		} finally {
			setIsVisibleModal(false);
		}
	}, [dispatch, userProfile]);

	const deleteEsignature = useCallback(async () => {
		try {
			await removeLinks(userProfile?.id!, 'fiel', tokenSession);
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'e.Firma eliminada',
					description: 'Tu e.firma ha sido eliminada correctamente',
				}),
			);
			onCallbackSuccess();
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description:
						'No se pudo eliminar tu e.firma, por favor intente nuevamente',
				}),
			);
		} finally {
			setOpenModal(false);
			setFielFlow(false);
		}
	}, [userProfile]);

	const deleteCiec = useCallback(async () => {
		try {
			await removeLinks(userProfile?.id!, 'ciec', tokenSession);
			dispatch(
				showAlert({
					type: ALERT_TYPE.SUCCESS,
					title: 'CIEC eliminada',
					description: 'Tus credenciales han sido eliminadas correctamente',
				}),
			);
			onCallbackSuccess();
		} catch (error) {
			dispatch(
				showAlert({
					type: ALERT_TYPE.ERROR,
					title: 'Error',
					description:
						'No se pudo eliminar tus credenciales, por favor intente nuevamente',
				}),
			);
		} finally {
			setOpenModal(false);
			setFielFlow(false);
		}
	}, [userProfile]);

	const fiscalSituationStatus = useMemo(() => {
		if (!taxPayer?.status?.status) return 'pending';
		if (
			taxPayer?.status?.status === 'ACTIVO' ||
			taxPayer?.status?.status === 'REACTIVADO'
		)
			return 'positive';
		return 'negative';
	}, [taxPayer]);

	const complianceOpinion = useMemo(() => {
		if (!taxPayer?.compliance?.result) return 'pending';
		if (taxPayer?.compliance.result === 'positive') return 'positive';
		return 'negative';
	}, [taxPayer]);

	const compliceOpinionLabel = useMemo(() => {
		if (taxPayer?.compliance?.result) {
			if (taxPayer?.compliance.result === 'negative') return 'Negativa';
			return 'Positiva';
		}
		return 'No disponible';
	}, [taxPayer]);

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const getTaxPayer = useCallback(async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchTaxPayer(userProfile?.id!, tokenSession);
			setTaxpayer(resource);
			dispatch(addTaxPayer(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	}, [userProfile]);

	const onCallbackSuccess = () => {
		fetchSatLinkedAcount();
	};

	//? LINKS
	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
			setLinkedStatus(resource);
		} catch (error: Error | any) {
			dispatch(startLoader(false));
		}
	};

	const getFiscalCredentials = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, getLinks: true }));
			const response = await fetchFiscalCredentials(tokenSession);
			dispatch(addLinks(response));
			setFiscalDocuments(response);
		} catch (error) {
		} finally {
			setIsLoading((prev) => ({ ...prev, getLinks: false }));
		}
	};

	const closeModalFiel = () => {
		setOpenModal(false);
		setFielFlow(false);
	};

	const openModalFiel = () => {
		setFielFlow(true);
		setOpenModal(true);
	};

	const openModalCiec = () => {
		setFielFlow(false);
		setOpenModal(true);
	};

	useEffect(() => {
		if (linkedStatus?.status === 'ACTIVE' && userProfile?.id) {
			getFiscalCredentials();
			getTaxPayer();
		}
	}, [linkedStatus, userProfile]);

	useEffect(() => {
		const lastUpdateTime = isLocalStorageEnabled()
			? localStorage.getItem('lastUpdateTime')
			: null;
		if (lastUpdateTime) {
			const currentTime = new Date().getTime();
			const elapsedTime = currentTime - parseInt(lastUpdateTime);
			if (elapsedTime < 3 * 60 * 60 * 1000) {
				setShowUpdateButton(false);
			}
		}
	}, []);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	useEffect(() => {
		fetchSatLinkedAcount();
		saveUserProfile();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.TAX_PROFILE,
		});
	}, []);

	return (
		<div className='p-5 md:p-10'>
			{alert.showAlert && (
				<Alert
					type={alert.type}
					description={alert.description}
					title={alert.title}
				/>
			)}
			<div className='flex items-center gap-3 mb-6'>
				{!isFromApp && (
					<CaretCircleLeft
						weight='fill'
						color='var(--h-primary)'
						width={24}
						height={24}
						className='cursor-pointer block lg:hidden'
						onClick={() => navigate(ROUTES.USER_PROFILE)}
					/>
				)}
				<CaretCircleLeft
					weight='fill'
					color='var(--h-primary)'
					width={32}
					height={32}
					className='cursor-pointer hidden lg:block'
					onClick={() => navigate(ROUTES.USER_PROFILE)}
				/>

				<Heading
					sx='hidden lg:block'
					size='s'
					weight='bold'
				>
					Perfil fiscal
				</Heading>
				<Text
					sx='block lg:hidden'
					size='body-2'
					weight='bold'
				>
					Perfil fiscal
				</Text>
			</div>
			<div className='flex flex-col lg:flex-row lg:gap-6'>
				<div className='lg:w-1/2'>
					<div
						className={`order-first bg-white rounded-lg shadow mb-6 ${
							fiscalDocuments?.links?.ciec?.[0]?.rfc ? 'p-0' : 'p-6'
						}`}
					>
						{fiscalDocuments?.links?.ciec?.[0]?.rfc || fiscalDocuments?.links?.fiel?.[0]?.key_url ? (
							<FiscalProfileCard
								openModal={openModalCiec}
								sx='border-0'
								fiscalProfile={true}
								eliminateCiec={
									fiscalDocuments?.links?.fiel?.[0]?.key_pass &&
									fiscalDocuments?.links?.ciec?.[0]?.password
										? true
										: false
								}
							/>
						) : (
							<SATLink onCallbackSuccess={onCallbackSuccess} />
						)}
					</div>
					<div className='order-2 md:order-3 bg-white rounded-lg shadow p-6 mb-6'>
						<Text
							size='body-2'
							color='dark'
							weight='bold'
							sx='mb-4 hidden lg:block'
						>
							Documentos
						</Text>
						<Text
							size='body-3'
							color='dark'
							weight='bold'
							sx='mb-4 block lg:hidden'
						>
							Documentos
						</Text>
						<Divider />
						<FiscalDocShare
							fileName='Tu constancia de situación fiscal'
							status={fiscalSituationStatus}
							statusText={taxPayer?.status?.status ?? 'No disponible'}
							sx='mb-4'
							hrefFile={taxPayer?.status?.file?.file_url ?? ''}
						/>
						<FiscalDocShare
							status={complianceOpinion}
							fileName='Tu opinión de cumplimiento'
							hrefFile={taxPayer?.compliance?.file?.file_url ?? ''}
							statusText={compliceOpinionLabel}
						/>
						{taxPayer?.status?.file?.file_url &&
							taxPayer?.compliance?.file?.file_url &&
							showUpdateButton && (
								<Button
									onClick={() => setIsVisibleModal(true)}
									variant='outline'
									sx='text-xs lg:text-base mt-5 border-0 !shadow-none'
									label='Actualizar mis documentos fiscales'
									beforeIcon={
										<>
											<ArrowsClockwise
												className='hidden lg:block'
												size={24}
												weight='light'
											/>
											<ArrowsClockwise
												className='block lg:hidden'
												size={16}
												weight='light'
											/>
										</>
									}
								/>
							)}
					</div>
					<div className='order-3 md:order-last bg-white rounded-lg shadow p-6 mb-6'>
						<Text
							size='body-2'
							color='dark'
							weight='bold'
							sx='mb-4 hidden lg:block'
						>
							Regimenes asociados
						</Text>
						<Text
							size='body-3'
							color='dark'
							weight='bold'
							sx='mb-4 block lg:hidden'
						>
							Regimenes asociados
						</Text>
						<Divider />
						<div>
							{(!taxPayer?.status?.tax_regimes ||
								taxPayer?.status?.tax_regimes?.length === 0) && (
								<>
									<Text
										size='body-3'
										weight='light'
										sx='hidden lg:block'
									>
										{LITERALS.NO_AVAILABLE_DATA}
									</Text>
									<Text
										size='caption'
										weight='light'
										sx='block lg:hidden'
									>
										{LITERALS.NO_AVAILABLE_DATA}
									</Text>
								</>
							)}

							<ul className='list-disc ml-6'>
								{taxPayer?.status?.tax_regimes?.map((regime) => (
									<li key={`regime-${regime.code}`}>
										<Text
											size='body-3'
											weight='light'
											sx='hidden lg:block'
										>
											{regime.name}
										</Text>
										<Text
											size='caption'
											weight='light'
											sx='block lg:hidden'
										>
											{regime.name}
										</Text>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className='lg:w-1/2'>
					<div className='order-4 md:order-2 bg-white rounded-lg shadow p-6 mb-6'>
						<div className=' flex justify-between'>
							<Text
								size='body-2'
								color='dark'
								weight='bold'
								sx='mb-4 hidden lg:block'
							>
								{fiscalDocuments?.links?.fiel?.[0]?.key_pass
									? 'e.Firma'
									: fiscalDocuments?.links?.rfc
										? 'Cargar mi e.Firma'
										: 'Vincular mi e.Firma'}
							</Text>
							<Text
								size='body-3'
								color='dark'
								weight='bold'
								sx='mb-4 block lg:hidden'
							>
								{fiscalDocuments?.links?.fiel?.[0]?.key_pass
									? 'e.Firma'
									: fiscalDocuments?.links?.rfc
										? 'Cargar mi e.Firma'
										: 'Vincular mi e.Firma'}
							</Text>
							{fiscalDocuments?.links?.fiel?.[0]?.key_pass &&
								fiscalDocuments?.links?.ciec?.[0]?.password && (
									<div
										className='relative group/file ml-4'
										onClick={openModalFiel}
									>
										<Trash
											weight='light'
											color={'var(--h-primary-20)'}
											className='text-bold group-hover/file:hidden'
											width={24}
											height={24}
										/>
										<Trash
											weight='fill'
											color={'var(--h-primary-20)'}
											className='text-bold hidden group-hover/file:block'
											width={24}
											height={24}
										/>
										<div className='transition translate-y-4 group-hover/file:translate-y-0  opacity-0 group-hover/file:opacity-100 absolute bottom-5 left-[calc(100%-56px)] flex flex-col items-center'>
											<Text
												weight='regular'
												size='caption'
												color='white'
												sx='bg-h-primary-20 px-2 py-1 rounded-lg'
											>
												Eliminar
											</Text>
											<CaretDown
												weight='fill'
												color='var(--h-primary-20)'
												width={24}
												height={24}
												className='mt-[-10px]'
											/>
										</div>
									</div>
								)}
						</div>
						<Divider />
						<EFirma
							isLoading={isLoading}
							onCallbackSuccess={onCallbackSuccess}
							fiscalDocuments={fiscalDocuments}
							showAlertTop={showAlertTop}
						/>
					</div>
					<div className='order-last md:order-4 bg-white rounded-lg shadow p-6 mb-6'>
						<DigitalSeals
							userProfile={userProfile}
							showAlertTop={showAlertTop}
						/>
					</div>
					{(fiscalDocuments?.links?.ciec?.[0]?.rfc ||
						fiscalDocuments?.links?.fiel?.[0]?.key_url) && (
						<Button
							onClick={() => {
								setDeletedTaxPayer(true);
								setIsVisibleModal(true);
							}}
							variant='tertiary'
							sx='text-xs lg:text-base mt-5 border-0 !shadow-none !bg-transparent'
							label='Desvincular mis datos fiscales'
						/>
					)}
				</div>
			</div>
			<Modal
				open={openModal}
				onClose={closeModalFiel}
				children={
					<div className='flex flex-col p-4'>
						<Heading
							sx='block lg:hidden mb-4'
							size='xs'
							weight='bold'
						>
							{fielFlow ? 'Eliminar e.Firma' : 'Eliminar mis credenciales'}
						</Heading>
						<Heading
							sx='hidden lg:block mb-4'
							size='s'
							weight='bold'
						>
							{fielFlow ? 'Eliminar e.Firma' : 'Eliminar mis credenciales'}
						</Heading>
						<Text
							size='label'
							weight='light'
							sx='block lg:hidden'
						>
							{fielFlow
								? '¿Estás seguro de eliminar tu e.firma?, al hacer esto podrías retrasar servicios que tengas en curso.'
								: '¿Estás seguro de eliminar tus credenciales por RFC?, al hacer esto podrías retrasar servicios que tengas en curso.'}
						</Text>
						<Text
							size='label'
							weight='regular'
							sx='hidden lg:block'
						>
							{fielFlow
								? '¿Estás seguro de eliminar tu e.firma?, al hacer esto podrías retrasar servicios que tengas en curso.'
								: '¿Estás seguro de eliminar tus credenciales por RFC?, al hacer esto podrías retrasar servicios que tengas en curso.'}
						</Text>
						<div className='flex justify-between mt-6'>
							<Warning size={24} color='var(--h-primary)' className='mr-2'/>
							<Text
								size='label'
								weight='light'
								sx='block lg:hidden'
							>
								{fielFlow
									? 'Si solo deseas actualizar tus archivos continua y vuelve a subir tus credenciales.'
									: 'Si solo deseas actualizar tu contraseña continua y vuelve a subir tus credenciales.'}
							</Text>
							<Text
								size='label'
								weight='regular'
								sx='hidden lg:block'
							>
								{fielFlow
									? 'Si solo deseas actualizar tus archivos continua y vuelve a subir tus credenciales.'
									: 'Si solo deseas actualizar tu contraseña continua y vuelve a subir tus credenciales.'}
							</Text>
						</div>
						<div className='flex flex-col-reverse lg:flex-row justify-end gap-x-4 mt-8'>
							<Button
								sx='w-full lg:w-24 text-xs lg:text-base'
								variant='outline'
								label='Regresar'
								onClick={closeModalFiel}
							/>
							<Button
								variant={'primary'}
								sx='w-full lg:w-auto text-xs lg:text-base mb-4 lg:mb-0'
								label={
									fielFlow ? 'Eliminar mi e.firma' : 'Eliminar mis credenciales'
								}
								onClick={() => {
									fielFlow ? deleteEsignature() : deleteCiec();
								}}
							/>
						</div>
					</div>
				}
			/>
			<Modal
				sx='w-[90%] lg:w-[40%]'
				open={isVisibleModal}
				onClose={() => setIsVisibleModal(false)}
				children={
					<div className='flex flex-col p-4'>
						<Heading
							sx='block lg:hidden mb-4'
							size='xs'
							weight='bold'
						>
							{deletedTaxPayer
								? 'Desvincular mis datos fiscales'
								: 'Actualización de información fiscal'}
						</Heading>
						<Heading
							sx='hidden lg:block mb-4'
							size='s'
							weight='bold'
						>
							{deletedTaxPayer
								? 'Desvincular mis datos fiscales'
								: 'Actualización de información fiscal'}
						</Heading>
						<Text
							size='label'
							weight='light'
							sx='block lg:hidden'
						>
							{deletedTaxPayer
								? 'Al continuar ya no podrás ver tu información fiscal y Heru ya no podrá presentar tus declaraciones.'
								: 'Si aun no ves todos tus movimientos o no están completos, puedes actualizar toda tu información para mantenerlo al día'}
						</Text>
						<Text
							size='label'
							weight='regular'
							sx='hidden lg:block'
						>
							{deletedTaxPayer
								? 'Al continuar ya no podrás ver tu información fiscal y Heru ya no podrá presentar tus declaraciones.'
								: 'Si aun no ves todos tus movimientos o no están completos, puedes actualizar toda tu información para mantenerlo al día'}
						</Text>
						<div className='flex flex-col-reverse lg:flex-row justify-end gap-x-4 mt-8'>
							<Button
								sx='w-full lg:w-24 text-xs lg:text-base'
								variant='outline'
								label='Regresar'
								onClick={() => setIsVisibleModal(false)}
							/>
							<Button
								variant={deletedTaxPayer ? 'danger-secondary' : 'primary'}
								sx='w-full lg:w-auto text-xs lg:text-base mb-4 lg:mb-0'
								label={
									deletedTaxPayer
										? 'Confirmar mi desvinculación'
										: 'Continuar con la actualización'
								}
								onClick={() => {
									deletedTaxPayer ? deleteTaxInformation() : updateDocuments();
								}}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default TaxProfilePage;
