import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { CircularProgress, Radio, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SealCheck, XCircle, CreditCard } from '@phosphor-icons/react';

//? Types
import { Order } from '../../../../@types/Order.type';
import { Card, PAYMENT_METHOD } from '../../../../@types/Checkout.type';

//? Components
import Text from '../../../../components/atoms/Text';
import Modal from '../../../../components/molecules/Modal/Modal';
import Heading from '../../../../components/atoms/Heading';
import PurchasesWidget from '../../../../components/organisms/PurchasesWidget';

//? Redux
import { RootState } from '../../../../redux/store';
import { hideAlert } from '../../../../redux/slices/alert.slice';

//? Services
import { getPaymentCards } from '../../../../services/payments.service';
import OptionCard from '../molecules/OptionCard';
import Button from '../../../../components/atoms/Button';
interface Props {
	showModal: any;
	setShowModal: Dispatch<SetStateAction<any>>;
	order: Order;
	activeSubscription: any;
	customerStripeId: string;
	fetchUserSuscriptions: () => void;
}

const ModalSelectCard: React.FC<Props> = ({
	showModal,
	setShowModal,
	order,
	activeSubscription,
	customerStripeId,
	fetchUserSuscriptions,
}) => {
	const alert = useSelector((state: RootState) => state.alert);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const dispatch = useDispatch();

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);
	const [isLoadingCards, setIsLoadingCards] = useState(false);
	const [cardSelected, setCardSelected] = useState<Card>();
	const [cate, setCate] = useState('');

	const handleChange = (event: SelectChangeEvent) => {
		if (event.target.value === 'CARD') {
			setCate('CARD');
		} else {
			const payment = allPaymentsCards.find(
				(card) => card.id === event.target.value,
			);
			setCate(event.target.value);
			setCardSelected(payment);
		}
	};

	const onPaymentMethodSuccess = useCallback(async () => {
		await getCardList(true);
	}, []);

	const getCardList = useCallback(async (isNewAdd?: boolean) => {
		try {
			setIsLoadingCards(true);
			const resp: Card[] = await getPaymentCards(tokenSession);
			setAllPaymentsCards(resp);
			setIsLoadingCards(false);
			if (resp?.length > 0) {
				const cardUsed = resp.find(
					(card) =>
						card.platform_token ===
						activeSubscription?.subscription_platform?.payment_details
							?.platform_token,
				);

				if (cardUsed && !isNewAdd) {
					setCate(cardUsed.id);
					setCardSelected(cardUsed);
					return;
				}
				const lastCard = resp[0];
				setCate(lastCard?.id);
				setCardSelected(lastCard);
			}
		} catch (error) {
			setIsLoadingCards(false);
		}
	}, []);

	useEffect(() => {
		getCardList();
	}, []);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	const [step, setStep] = useState(0);

	return (
		<Modal
			open={showModal}
			onClose={handleCloseModal}
			sx='w-[94%] !max-w-[662px]'
		>
			{step === 0 && (
				<>
					<div className='flex items-center justify-center'>
						<Heading
							color='dark'
							size='xs'
							sx='text-center'
							weight='bold'
						>
							Cambiar métodos de pago
						</Heading>
					</div>

					<div className='flex flex-col max-h-[450px] overflow-auto p-1'>
						<div className='w-full p-2 md:p-6 bg-white flex-col inline-flex'>
							{isLoadingCards ? (
								<div className='flex flex-row items-center justify-center m-2 w-full'>
									<CircularProgress />
								</div>
							) : (
								<>
									<div className='w-full'>
										{allPaymentsCards.map((card) => (
											<div className='flex items-start'>
												<Radio
													value={card.id}
													checked={cate === card.id}
													onChange={handleChange}
												/>
												<OptionCard
													brand={card.details?.brand ?? ''}
													lastFour={card.details?.last_four ?? ''}
													expirationDate={card.details?.expires ?? ''}
													// onDelete={} //TODO: Implement delete card
												/>
											</div>
										))}

										{cate !== PAYMENT_METHOD.CARD && (
											<Button
												beforeIcon={<CreditCard size={24} />}
												variant='secondary'
												label='Agregar tarjeta de crédito o débito'
												sx='!text-h-primary-30 mt-4'
												onClick={() => setCate(PAYMENT_METHOD.CARD)}
											/>
										)}
									</div>
									<div className='w-full'>
										{cate === PAYMENT_METHOD.CARD && (
											<div className='w-full'>
												<div className='mt-2 border border-h-neutral-90 p-4 rounded-lg'>
													<PurchasesWidget
														order={order}
														onPaymentMethodSuccess={onPaymentMethodSuccess}
														showPaymentElement={true}
														activeSubscription={activeSubscription}
														selectedCard={cardSelected}
														setStep={setStep}
														setShowModal={setShowModal}
														customerStripeId={customerStripeId}
														fetchUserSuscriptions={fetchUserSuscriptions}
														onCancel={
															cate === PAYMENT_METHOD.CARD
																? () => setCate(cardSelected?.id ?? '')
																: undefined
														}
														idUser={userProfile?.id}
													/>
												</div>
											</div>
										)}

										{cate !== PAYMENT_METHOD.CARD && (
											<>
												{cardSelected && (
													<div className='w-full flex flex-col gap-y-6'>
														{/* <div className='flex flex-col md:flex-row justify-between items-center gap-x-10 mt-4'>
															<div className='w-full md:w-1/2'>
																<Text size='m'>Número de tarjeta</Text>
																<div className='flex flex-row justify-between border px-4 py-0.5 border-h-gray-20 rounded-[4px] mt-2'>
																	<Text size='m'>
																		**** {cardSelected?.details?.last_four}
																	</Text>
																	<img
																		src={getBrandIcon(
																			cardSelected?.details?.brand,
																		)}
																		alt='brand'
																		className='w-8 h-8 ml-2'
																	/>
																</div>
															</div>
															<div className='w-full md:w-1/2'>
																<Text size='m'>Fecha de caducidad</Text>
																<div className='border px-4 py-2 border-h-gray-20 rounded-[4px] mt-2'>
																	<Text size='m'>
																		{cardSelected.details.expires}
																	</Text>
																</div>
															</div>
														</div> */}
														<PurchasesWidget
															showPaymentElement={false}
															order={order}
															onPaymentMethodSuccess={onPaymentMethodSuccess}
															paymentMethodId={cardSelected?.id}
															activeSubscription={activeSubscription}
															selectedCard={cardSelected}
															setStep={setStep}
															setShowModal={setShowModal}
															customerStripeId={customerStripeId}
															fetchUserSuscriptions={fetchUserSuscriptions}
														/>
													</div>
												)}
											</>
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}

			{step === 1 && (
				<div className='flex flex-col justify-center items-center'>
					<SealCheck
						size={40}
						weight='fill'
						className='mb-4'
						color='var(--h-green-50)'
					/>
					<Heading
						size='xs'
						weight='bold'
						sx='mb-4'
					>
						¡Felicidades!
					</Heading>
					<Text
						size='l'
						sx='text-center'
					>
						Tu tarjeta ha sido aceptada y permanecerá como el método de pago
						principal para tu suscripción.
					</Text>
				</div>
			)}

			{step === 2 && (
				<div className='flex flex-col justify-center items-center'>
					<SealCheck
						size={40}
						weight='fill'
						className='mb-4'
						color='var(--h-green-50)'
					/>
					<Heading
						size='xs'
						weight='bold'
						sx='mb-4'
					>
						¡Felicidades!
					</Heading>
					<Text
						size='l'
						sx='text-center'
					>
						La renovación de tu suscripción ha sido exitosa tras el cambio de
						tarjeta.
					</Text>
				</div>
			)}

			{step === 3 && (
				<div className='flex flex-col justify-center items-center'>
					<XCircle
						size={40}
						weight='fill'
						className='mb-4'
						color='var(--h-red-50)'
					/>
					<Heading
						size='xs'
						weight='bold'
						sx='mb-4'
					>
						¡Error con el pago!
					</Heading>
					<Text
						size='l'
						sx='text-center'
					>
						Lamentablemente, tu tarjeta no fue aceptada. Te recomendamos cambiar
						el método de pago principal para tu suscripción.
					</Text>
				</div>
			)}
		</Modal>
	);
};

// const CardInfo: React.FC<{ card: Card }> = ({ card }) => {
// 	return (
// 		<div className='flex flex-row items-center justify-between gap-2 w-full'>
// 			<div className='inline-flex items-center gap-x-1 justify-center'>
// 				<img
// 					src={getBrandIcon(card.details.brand)}
// 					alt='brand'
// 					className='w-[32px] h-[32px]'
// 				/>
// 				<span className='text-sm font-bold inline-flex'>
// 					<span className='hidden md:block mr-1'>
// 						{capitalizeFirstLetter(
// 							card.details.brand === 'amex'
// 								? 'American Express'
// 								: card.details.brand,
// 						)}{' '}
// 					</span>
// 					**** {card.details?.last_four || ''}
// 				</span>
// 			</div>
// 			<div>
// 				<Text
// 					size='m'
// 					sx='hidden md:block'
// 				>
// 					Vence el {card.details.expires}
// 				</Text>
// 				<Text
// 					size='m'
// 					sx='block md:hidden'
// 				>
// 					{card.details.expires}
// 				</Text>
// 			</div>
// 		</div>
// 	);
// };

export default ModalSelectCard;
