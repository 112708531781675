import React, { useState } from 'react';

import { Radio } from '@mui/material';
import {
	CaretDown,
	CaretUp,
	CheckCircle,
	ShoppingCart,
} from '@phosphor-icons/react';
import { ListProducts } from '../../../../@types/Products.type';
import Text from '../../../../components/atoms/Text';
import Heading from '../../../../components/atoms/Heading';
import {
	formatPrice,
	getPeriodicity,
} from '../../../../shared/utils/general.util';
import Button from '../../../../components/atoms/Button';
import {
	PRODUCTS_BY_SLUG,
	PRODUCTS_PERIODICITIES_ID,
	arrOptions,
	basicAccountingBenefits,
} from '../../data/ecommerceData';
import { TaxPayer } from '../../../../@types/TaxProfile';
import { REGIMES_CODE } from '../../../../shared/constants/regimes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import hotSaleImg from '../../../../assets/img/svg/hot_sale.svg';
interface LinkedBasicAccountingProps {
	products: ListProducts[];
	isMobile: boolean;
	taxPayer: TaxPayer;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}
interface PriceDisplayProps {
	productVersions: any[];
	selectedPeriodicity: number;
	selectedValue: number;
}

const LinkedBasicAccounting: React.FC<LinkedBasicAccountingProps> = ({
	products,
	isMobile,
	taxPayer,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const [selectedValue, setSelectedValue] = useState(30);
	const [openBenefits, setOpenBenefits] = useState(false);

	const [selectPeriodicityHeruPlus, setSelectPeriodicityHeruPlus] = useState(
		PRODUCTS_PERIODICITIES_ID.HERU_PLUS_MONTHLY,
	);
	const [selectPeriodicityActEmp, setSelectPeriodicityActEmp] = useState(
		PRODUCTS_PERIODICITIES_ID.ACT_EMP_MONTHLY,
	);
	const [selectPeriodicityResico, setSelectPeriodicityResico] = useState(
		PRODUCTS_PERIODICITIES_ID.RESICO_MONTHLY,
	);
	const [selectPeriodicityPT, setSelectPeriodicityPT] = useState(
		PRODUCTS_PERIODICITIES_ID.PT_MONTHLY,
	);
	const [selectPeriodicityRIF, setSelectPeriodicityRIF] = useState(
		PRODUCTS_PERIODICITIES_ID.RIF_MONTHLY,
	);
	const [selectPeriodicityPTArr, setSelectPeriodicityPTArr] = useState(
		PRODUCTS_PERIODICITIES_ID.PT_ARR_MONTHLY,
	);
	const [selectArrType, setSelectArrType] = useState('arr-monthly');
	const [selectArrQuarterly, setSelectArrQuarterly] = useState(
		PRODUCTS_PERIODICITIES_ID.ARR_QUARTERLY,
	);
	const [selectArrMonthly, setSelectArrMonthly] = useState(
		PRODUCTS_PERIODICITIES_ID.ARR_MONTHLY,
	);

	const getProductsBySlug = (products: ListProducts[], slug: string) => {
		return products?.[0]?.products?.filter((it) => it?.slug === slug);
	};

	const getArrQuarterly = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ARR_QUARTERLY,
	);
	const getArrMonthly = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ARR_MONTHLY,
	);
	const getActEmp = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.ACTIVIDAD_EMPRESARIAL,
	);
	const getResico = getProductsBySlug(products, PRODUCTS_BY_SLUG.RESICO);
	const getPt = getProductsBySlug(
		products,
		PRODUCTS_BY_SLUG.PLATAFORMAS_TECNOLOGICAS,
	);
	const getRif = getProductsBySlug(products, PRODUCTS_BY_SLUG.RIF);
	const getPtArr = getProductsBySlug(products, PRODUCTS_BY_SLUG.PT_ARRIENDOS);
	const getHeruPlus = getProductsBySlug(products, PRODUCTS_BY_SLUG.HERU_PLUS);

	const PriceDisplay: React.FC<PriceDisplayProps> = ({
		productVersions,
		selectedPeriodicity,
		selectedValue,
	}) => {
		const selectedProduct = productVersions.find(
			(it) => it.id === selectedPeriodicity,
		);
		return (
			<>
				{isHotSale ? (
					<div className='flex items-center justify-end mb-2 w-full'>
						<div className='flex items-center mr-4'>
							<Text
								color='gray'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 mr-5 md:mr-8 text-center'
							>
								Precio regular
							</Text>
							<Text
								color='gray'
								size={isMobile ? 'label' : 'body-1'}
								weight='regular'
								sx='line-through'
							>
								${selectedProduct ? formatPrice(selectedProduct?.price) : ''}
							</Text>
						</div>
						<div className='flex items-center ml-2'>
							<Text
								color='darkMenu'
								weight='bold'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 text-center mr-5'
							>
								HOT HERU
							</Text>
							<Heading
								color='darkMenu'
								size={isMobile ? 'xs' : 's'}
								weight='medium'
							>
								$
							</Heading>
							<Heading
								color='darkMenu'
								size={isMobile ? 'm' : 'l'}
								weight='medium'
							>
								{selectedProduct
									? formatPrice(selectedProduct?.price * 0.7)
									: ''}
							</Heading>
							<Text
								color='darkMenu'
								size={isMobile ? 's' : 'label'}
								sx='mt-5 ml-1'
								weight='bold'
							>
								{getPeriodicity(selectedValue)}
							</Text>
						</div>
					</div>
				) : (
					<div className='flex flex-row justify-end mr-4 mt-2'>
						<Heading
							size='s'
							weight='bold'
						>
							${selectedProduct ? formatPrice(selectedProduct?.price) : ''}
						</Heading>
						<Text
							size='m'
							weight='bold'
							sx='mt-4 ml-2'
						>
							{getPeriodicity(selectedValue)}
						</Text>
					</div>
				)}
			</>
		);
	};

	const renderLinkedNameByRegime = () => {
		const { status } = taxPayer || {};
		const regimes = status?.tax_regimes || [];

		const regimesWithoutSys = regimes.filter(
			(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
		);

		if (regimesWithoutSys.length === 1) {
			const regimeCode = regimesWithoutSys[0].code;

			switch (regimeCode) {
				case REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS:
					return (
						<div className='flex flex-col mt-2 md:mt-8'>
							{taxPayer.status.tax_regimes.map((regime, index) => (
								<Text
									size='m'
									sx='mb-2'
									key={`regime-${index}`}
								>
									{regime.name}
								</Text>
							))}
							<Text
								size='s'
								color='medium'
								sx='mt-1'
							>
								Ganas dinero por aplicaciones o internet
							</Text>
						</div>
					);
				case REGIMES_CODE.RESICO:
					return (
						<div className='flex flex-col mt-2 md:mt-8'>
							{taxPayer.status.tax_regimes.map((regime, index) => (
								<Text
									size='m'
									sx='mb-2'
									key={`regime-${index}`}
								>
									{regime.name}
								</Text>
							))}
							<Text
								size='s'
								color='medium'
								sx='mt-1'
							>
								Ingresos menores a 3.5 mdp/año y sin salario
							</Text>
						</div>
					);
				case REGIMES_CODE.RIF:
					return (
						<div className='flex flex-col mt-2 md:mt-8'>
							{taxPayer.status.tax_regimes.map((regime, index) => (
								<Text
									size='m'
									sx='mb-2'
									key={`regime-${index}`}
								>
									{regime.name}
								</Text>
							))}
							<Text
								size='s'
								color='medium'
								sx='mt-1'
							>
								Eres emprendedor
							</Text>
						</div>
					);
				case REGIMES_CODE.ARRENDAMIENTO:
					return (
						<div className='flex flex-col mt-2 md:mt-8'>
							{taxPayer.status.tax_regimes.map((regime, index) => (
								<Text
									size='m'
									sx='mb-2'
									key={`regime-${index}`}
								>
									{regime.name}
								</Text>
							))}
							<Text
								size='s'
								color='medium'
								sx='mt-1'
							>
								Tus ingresos son de rentas de propiedades
							</Text>
						</div>
					);
				case REGIMES_CODE.ACTIVIDAD_EMPRESARIAL:
					return (
						<div className='flex flex-col mt-2 md:mt-8'>
							{taxPayer.status.tax_regimes.map((regime, index) => (
								<Text
									size='m'
									sx='mb-2'
									key={`regime-${index}`}
								>
									{regime.name}
								</Text>
							))}
							<Text
								size='s'
								color='medium'
								sx='mt-1'
							>
								Soy independiente o tengo un pequeño negocio
							</Text>
						</div>
					);
				default:
					'No cuentas con un régimen fiscal';
			}
		} else if (regimesWithoutSys.length === 2) {
			const hasPT = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
			);
			const hasArrendamiento = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.ARRENDAMIENTO,
			);

			if (hasPT && hasArrendamiento) {
				return (
					<div className='flex flex-col mt-2 md:mt-8'>
						{taxPayer.status.tax_regimes.map((regime, index) => (
							<Text
								size='m'
								sx='mb-2'
								key={`regime-${index}`}
							>
								{regime.name}
							</Text>
						))}
						<Text
							size='s'
							color='medium'
							sx='mt-1'
						>
							Tus ingresos son de rentas de propiedades y ganas dinero por
							aplicaciones o internet
						</Text>
					</div>
				);
			} else {
				return (
					<div className='flex flex-col mt-2 md:mt-8'>
						{taxPayer.status.tax_regimes.map((regime, index) => (
							<Text
								size='m'
								sx='mb-2'
								key={`regime-${index}`}
							>
								{regime.name}
							</Text>
						))}
					</div>
				);
			}
		} else if (regimesWithoutSys.length > 2) {
			<div className='flex flex-col mt-2 md:mt-8'>
				{taxPayer.status.tax_regimes.map((regime, index) => (
					<Text
						size='m'
						sx='mb-2'
						key={`regime-${index}`}
					>
						{regime.name}
					</Text>
				))}
			</div>;
		}
	};

	const renderPeriodicitiesByLinkedRegime = () => {
		const { status } = taxPayer || {};
		const regimes = status?.tax_regimes || [];

		const regimesWithoutSys = regimes.filter(
			(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
		);

		if (regimesWithoutSys.length === 1) {
			const regimeCode = regimesWithoutSys[0].code;

			switch (regimeCode) {
				case REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS:
					return (
						<>
							{getPt[0].product_versions
								.sort((a, b) => a.periodicity - b.periodicity)
								.map((it) => (
									<div
										key={it.id}
										className={`flex flex-row ${isHotSale && 'items-center'}`}
									>
										<Radio
											style={{
												color:
													selectedValue === it.periodicity
														? '#1D8FF3'
														: undefined,
											}}
											checked={selectPeriodicityPT === it.id}
											onChange={() => {
												setSelectPeriodicityPT(it.id);
												setSelectedValue(it.periodicity);
											}}
											value={it.id}
											name='radio-buttons'
											inputProps={{ 'aria-label': 'A' }}
										/>
										{it?.relative_percentage_off &&
										it?.relative_percentage_off > 0 ? (
											<div className='flex flex-col'>
												<Text
													size='m'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
												{!isHotSale && (
													<Text
														size='s'
														color='primary'
													>
														Ahorra {it.relative_percentage_off}%
													</Text>
												)}
											</div>
										) : (
											<Text
												size='m'
												sx='ml-2'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
										)}
									</div>
								))}
						</>
					);
				case REGIMES_CODE.RESICO:
					return (
						<>
							{getResico[0].product_versions
								.sort((a, b) => a.periodicity - b.periodicity)
								.map((it) => (
									<div
										key={it.id}
										className={`flex flex-row ${isHotSale && 'items-center'}`}
									>
										<Radio
											style={{
												color:
													selectedValue === it.periodicity
														? '#1D8FF3'
														: undefined,
											}}
											checked={selectPeriodicityResico === it.id}
											onChange={() => {
												setSelectPeriodicityResico(it.id);
												setSelectedValue(it.periodicity);
											}}
											value={it.id}
											name='radio-buttons'
											inputProps={{ 'aria-label': 'A' }}
										/>
										{it?.relative_percentage_off &&
										it?.relative_percentage_off > 0 ? (
											<div className='flex flex-col'>
												<Text
													size='m'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
												{!isHotSale && (
													<Text
														size='s'
														color='primary'
													>
														Ahorra {it.relative_percentage_off}%
													</Text>
												)}
											</div>
										) : (
											<Text
												size='m'
												sx='ml-2'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
										)}
									</div>
								))}
						</>
					);
				case REGIMES_CODE.RIF:
					return (
						<>
							{getRif[0].product_versions
								.sort((a, b) => a.periodicity - b.periodicity)
								.map((it) => (
									<div
										key={it.id}
										className={`flex flex-row ${isHotSale && 'items-center'}`}
									>
										<Radio
											style={{
												color:
													selectedValue === it.periodicity
														? '#1D8FF3'
														: undefined,
											}}
											checked={selectPeriodicityRIF === it.id}
											onChange={() => {
												setSelectPeriodicityRIF(it.id);
												setSelectedValue(it.periodicity);
											}}
											value={it.id}
											name='radio-buttons'
											inputProps={{ 'aria-label': 'A' }}
										/>
										{it?.relative_percentage_off &&
										it?.relative_percentage_off > 0 ? (
											<div className='flex flex-col'>
												<Text
													size='m'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
												{!isHotSale && (
													<Text
														size='s'
														color='primary'
													>
														Ahorra {it.relative_percentage_off}%
													</Text>
												)}
											</div>
										) : (
											<Text
												size='m'
												sx='ml-2'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
										)}
									</div>
								))}
						</>
					);
				case REGIMES_CODE.ARRENDAMIENTO:
					return (
						<>
							<div className='border-b pb-4 mt-5'>
								<Text
									size='l'
									color='dark'
									weight='bold'
								>
									¿Qué tipo de declaración realizas?
								</Text>

								<div className='my-8'>
									{arrOptions.map((it) => (
										<div
											key={it.id}
											className='flex flex-row mb-4'
										>
											<Radio
												style={{
													color: selectArrType === it.id ? '#' : undefined,
												}}
												checked={selectArrType === it.id}
												onChange={() => {
													setSelectArrType(it.id);
												}}
												value={it.id}
												name='radio-buttons'
												inputProps={{ 'aria-label': 'A' }}
											/>
											<div className='flex flex-col'>
												<Text
													size='m'
													color='dark'
													weight={selectArrType === it.id ? 'bold' : 'medium'}
												>
													{it.name}
												</Text>
												<Text
													size='s'
													color='light'
													weight={selectArrType === it.id ? 'bold' : 'medium'}
												>
													{it.desc}
												</Text>
											</div>
										</div>
									))}
								</div>
							</div>
							{selectArrType === 'arr-monthly' ? (
								<>
									{getArrMonthly[0].product_versions
										.sort((a, b) => a.periodicity - b.periodicity)
										.map((it) => (
											<div
												key={it.id}
												className={`flex flex-row ${isHotSale && 'items-center'}`}
											>
												<Radio
													style={{
														color:
															selectedValue === it.periodicity
																? '#1D8FF3'
																: undefined,
													}}
													checked={selectArrMonthly === it.id}
													onChange={() => {
														setSelectArrMonthly(it.id);
														setSelectedValue(it.periodicity);
													}}
													value={it.id}
													name='radio-buttons'
													inputProps={{ 'aria-label': 'A' }}
												/>
												{it?.relative_percentage_off &&
												it?.relative_percentage_off > 0 ? (
													<div className='flex flex-col'>
														<Text
															size='m'
															color='dark'
														>
															{getPeriodicity(it.periodicity)}
														</Text>
														{!isHotSale && (
															<Text
																size='s'
																color='primary'
															>
																Ahorra {it.relative_percentage_off}%
															</Text>
														)}
													</div>
												) : (
													<Text
														size='m'
														sx='ml-2'
														color='dark'
													>
														{getPeriodicity(it.periodicity)}
													</Text>
												)}
											</div>
										))}
								</>
							) : (
								<>
									{getArrQuarterly[0].product_versions
										.sort((a, b) => a.periodicity - b.periodicity)
										.map((it) => (
											<div
												key={it.id}
												className={`flex flex-row ${isHotSale && 'items-center'}`}
											>
												<Radio
													style={{
														color:
															selectedValue === it.periodicity
																? '#1D8FF3'
																: undefined,
													}}
													checked={selectArrQuarterly === it.id}
													onChange={() => {
														setSelectArrQuarterly(it.id);
														setSelectedValue(it.periodicity);
													}}
													value={it.id}
													name='radio-buttons'
													inputProps={{ 'aria-label': 'A' }}
												/>
												{it?.relative_percentage_off &&
												it?.relative_percentage_off > 0 ? (
													<div className='flex flex-col'>
														<Text
															size='m'
															color='dark'
														>
															{getPeriodicity(it.periodicity)}
														</Text>
														{!isHotSale && (
															<Text
																size='s'
																color='primary'
															>
																Ahorra {it.relative_percentage_off}%
															</Text>
														)}
													</div>
												) : (
													<Text
														size='m'
														sx='ml-2'
														color='dark'
													>
														{getPeriodicity(it.periodicity)}
													</Text>
												)}
											</div>
										))}
								</>
							)}
						</>
					);
				case REGIMES_CODE.ACTIVIDAD_EMPRESARIAL:
					return (
						<>
							{getActEmp[0].product_versions
								.sort((a, b) => a.periodicity - b.periodicity)
								.map((it) => (
									<div
										key={it.id}
										className={`flex flex-row ${isHotSale && 'items-center'}`}
									>
										<Radio
											style={{
												color:
													selectedValue === it.periodicity
														? '#1D8FF3'
														: undefined,
											}}
											checked={selectPeriodicityActEmp === it.id}
											onChange={() => {
												setSelectPeriodicityActEmp(it.id);
												setSelectedValue(it.periodicity);
											}}
											value={it.id}
											name='radio-buttons'
											inputProps={{ 'aria-label': 'A' }}
										/>
										{it?.relative_percentage_off &&
										it?.relative_percentage_off > 0 ? (
											<div className='flex flex-col'>
												<Text
													size='m'
													color='dark'
												>
													{getPeriodicity(it.periodicity)}
												</Text>
												{!isHotSale && (
													<Text
														size='s'
														color='primary'
													>
														Ahorra {it.relative_percentage_off}%
													</Text>
												)}
											</div>
										) : (
											<Text
												size='m'
												sx='ml-2'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
										)}
									</div>
								))}
						</>
					);
				default:
					<></>;
			}
		} else if (regimesWithoutSys.length === 2) {
			const hasPT = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
			);
			const hasArrendamiento = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.ARRENDAMIENTO,
			);

			if (hasPT && hasArrendamiento) {
				return (
					<>
						{getPtArr[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityPTArr === it.id}
										onChange={() => {
											setSelectPeriodicityPTArr(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			} else {
				return (
					<>
						{getHeruPlus[0].product_versions
							.sort((a, b) => a.periodicity - b.periodicity)
							.map((it) => (
								<div
									key={it.id}
									className={`flex flex-row ${isHotSale && 'items-center'}`}
								>
									<Radio
										style={{
											color:
												selectedValue === it.periodicity
													? '#1D8FF3'
													: undefined,
										}}
										checked={selectPeriodicityHeruPlus === it.id}
										onChange={() => {
											setSelectPeriodicityHeruPlus(it.id);
											setSelectedValue(it.periodicity);
										}}
										value={it.id}
										name='radio-buttons'
										inputProps={{ 'aria-label': 'A' }}
									/>
									{it?.relative_percentage_off &&
									it?.relative_percentage_off > 0 ? (
										<div className='flex flex-col'>
											<Text
												size='m'
												color='dark'
											>
												{getPeriodicity(it.periodicity)}
											</Text>
											{!isHotSale && (
												<Text
													size='s'
													color='primary'
												>
													Ahorra {it.relative_percentage_off}%
												</Text>
											)}
										</div>
									) : (
										<Text
											size='m'
											sx='ml-2'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
									)}
								</div>
							))}
					</>
				);
			}
		} else if (regimesWithoutSys.length > 2) {
			return (
				<>
					{getHeruPlus[0].product_versions
						.sort((a, b) => a.periodicity - b.periodicity)
						.map((it) => (
							<div
								key={it.id}
								className={`flex flex-row ${isHotSale && 'items-center'}`}
							>
								<Radio
									style={{
										color:
											selectedValue === it.periodicity ? '#1D8FF3' : undefined,
									}}
									checked={selectPeriodicityHeruPlus === it.id}
									onChange={() => {
										setSelectPeriodicityHeruPlus(it.id);
										setSelectedValue(it.periodicity);
									}}
									value={it.id}
									name='radio-buttons'
									inputProps={{ 'aria-label': 'A' }}
								/>
								{it?.relative_percentage_off &&
								it?.relative_percentage_off > 0 ? (
									<div className='flex flex-col'>
										<Text
											size='m'
											color='dark'
										>
											{getPeriodicity(it.periodicity)}
										</Text>
										{!isHotSale && (
											<Text
												size='s'
												color='primary'
											>
												Ahorra {it.relative_percentage_off}%
											</Text>
										)}
									</div>
								) : (
									<Text
										size='m'
										sx='ml-2'
										color='dark'
									>
										{getPeriodicity(it.periodicity)}
									</Text>
								)}
							</div>
						))}
				</>
			);
		}
	};

	const renderPriceBySelectedRegime = () => {
		const { status } = taxPayer || {};
		const regimes = status?.tax_regimes || [];

		const regimesWithoutSys = regimes.filter(
			(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
		);

		if (regimesWithoutSys.length === 1) {
			const regimeCode = regimesWithoutSys[0].code;

			switch (regimeCode) {
				case REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS:
					return (
						<PriceDisplay
							productVersions={getPt[0]?.product_versions}
							selectedPeriodicity={selectPeriodicityPT}
							selectedValue={selectedValue}
						/>
					);
				case REGIMES_CODE.RESICO:
					return (
						<PriceDisplay
							productVersions={getResico[0]?.product_versions}
							selectedPeriodicity={selectPeriodicityResico}
							selectedValue={selectedValue}
						/>
					);
				case REGIMES_CODE.RIF:
					return (
						<PriceDisplay
							productVersions={getRif[0]?.product_versions}
							selectedPeriodicity={selectPeriodicityRIF}
							selectedValue={selectedValue}
						/>
					);
				case REGIMES_CODE.ARRENDAMIENTO:
					return (
						<>
							{selectArrType === 'arr-monthly' ? (
								<PriceDisplay
									productVersions={getArrMonthly[0]?.product_versions}
									selectedPeriodicity={selectArrMonthly}
									selectedValue={selectedValue}
								/>
							) : (
								<PriceDisplay
									productVersions={getArrQuarterly[0]?.product_versions}
									selectedPeriodicity={selectArrQuarterly}
									selectedValue={selectedValue}
								/>
							)}
						</>
					);
				case REGIMES_CODE.ACTIVIDAD_EMPRESARIAL:
					return (
						<PriceDisplay
							productVersions={getActEmp[0]?.product_versions}
							selectedPeriodicity={selectPeriodicityActEmp}
							selectedValue={selectedValue}
						/>
					);
				default:
					<></>;
			}
		} else if (regimesWithoutSys.length === 2) {
			const hasPT = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
			);
			const hasArrendamiento = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.ARRENDAMIENTO,
			);

			if (hasPT && hasArrendamiento) {
				return (
					<PriceDisplay
						productVersions={getPtArr[0]?.product_versions}
						selectedPeriodicity={selectPeriodicityPTArr}
						selectedValue={selectedValue}
					/>
				);
			} else {
				return (
					<PriceDisplay
						productVersions={getHeruPlus[0]?.product_versions}
						selectedPeriodicity={selectPeriodicityHeruPlus}
						selectedValue={selectedValue}
					/>
				);
			}
		} else if (regimesWithoutSys.length > 2) {
			return (
				<PriceDisplay
					productVersions={getHeruPlus[0]?.product_versions}
					selectedPeriodicity={selectPeriodicityHeruPlus}
					selectedValue={selectedValue}
				/>
			);
		}
	};

	const handleGoToCheckout = () => {
		const { status } = taxPayer || {};
		const regimes = status?.tax_regimes || [];

		const regimesWithoutSys = regimes.filter(
			(regime) => regime.code !== REGIMES_CODE.SUELDOS_Y_SALARIOS,
		);

		if (regimesWithoutSys.length === 0) {
			return;
		}

		if (regimesWithoutSys.length === 1) {
			const regimeCode = regimesWithoutSys[0]?.code;

			if (regimeCode === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS) {
				handleAddProduct(selectPeriodicityPT, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			} else if (regimeCode === REGIMES_CODE.RESICO) {
				handleAddProduct(selectPeriodicityResico, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			} else if (regimeCode === REGIMES_CODE.RIF) {
				handleAddProduct(selectPeriodicityRIF, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			} else if (regimeCode === REGIMES_CODE.ARRENDAMIENTO) {
				if (selectArrType === 'arr-monthly') {
					handleAddProduct(selectArrMonthly, {
						regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
					});
				} else {
					handleAddProduct(selectArrQuarterly, {
						regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
					});
				}
			} else if (regimeCode === REGIMES_CODE.ACTIVIDAD_EMPRESARIAL) {
				handleAddProduct(selectPeriodicityActEmp, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			}
		} else if (regimesWithoutSys.length === 2) {
			const hasPT = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS,
			);
			const hasArrendamiento = regimesWithoutSys.some(
				(regime) => regime.code === REGIMES_CODE.ARRENDAMIENTO,
			);

			if (hasPT && hasArrendamiento) {
				handleAddProduct(selectPeriodicityPTArr, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			} else {
				handleAddProduct(selectPeriodicityHeruPlus, {
					regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
				});
			}
		} else if (regimesWithoutSys.length > 2) {
			handleAddProduct(selectPeriodicityHeruPlus, {
				regimes: taxPayer.status.tax_regimes.map((regime) => regime.name),
			});
		}
	};

	return (
		<>
			{isMobile ? (
				<div className='flex flex-col rounded-md border mb-52'>
					<div className='sticky top-0 z-10 bg-white'>
						<div
							className={`${isHotSale ? 'bg-h-hot-sale' : 'bg-h-blue-60'} relative p-4 rounded-t-md flex flex-col items-center`}
						>
							{isHotSale && (
								<div className='mb-12'>
									<div className='absolute left-0 -top-16 flex items-center justify-center'>
										<img
											src={hotSaleImg}
											alt='hot sale heru'
											className='w-56 h-56'
										/>
									</div>
								</div>
							)}
							<Text
								size='s'
								color='white'
								sx='text-center text-h-blue-20'
							>
								Tu contabilidad mensual 100% cubierta para una o más fuentes de
								ingreso
							</Text>
							<div
								className={`border ${isHotSale ? 'border-white' : 'border-blue-200'} my-4 mx-4 w-full`}
							/>
							<Text
								size='xl'
								color='white'
								weight='bold'
							>
								Contabilidad básica
							</Text>
						</div>
						{renderPriceBySelectedRegime()}
					</div>

					<div className='flex flex-col p-4'>
						<Text
							size='l'
							weight='bold'
						>
							Según tu información fiscal tu régimen es
						</Text>
						{renderLinkedNameByRegime()}

						<div className='border-b border-t pb-4 mt-5'>
							<Text
								size='l'
								color='dark'
								weight='bold'
								sx='mt-6'
							>
								Frecuencia de pago
							</Text>
							<div className='grid grid-cols-2 gap-4 mt-2'>
								{renderPeriodicitiesByLinkedRegime()}
							</div>
						</div>
						<div className='flex flex-col border-b'>
							<div className='flex flex-row justify-between py-8'>
								<Text
									color='dark'
									weight='bold'
									size='xl'
								>
									Beneficios
								</Text>
								<button onClick={() => setOpenBenefits(!openBenefits)}>
									{openBenefits ? (
										<CaretUp
											size={24}
											weight='light'
										/>
									) : (
										<CaretDown
											size={24}
											weight='light'
										/>
									)}
								</button>
							</div>

							{openBenefits && (
								<div className='flex flex-col'>
									{basicAccountingBenefits.map((benefit, index) => (
										<div
											key={`basic-benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='light'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div
						className={`fixed bottom-12 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg`}
					>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							onClick={() => handleGoToCheckout()}
							className={`${isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			) : (
				<div>
					<div
						className={`${isHotSale ? 'bg-h-hot-sale' : 'bg-h-blue-60'} relative p-8 rounded-t-xl flex flex-col items-center`}
					>
						{isHotSale && (
							<div className='mb-7'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}
						<Text
							size='l'
							color='white'
							sx='text-center max-w-[380px] text-h-blue-20'
						>
							Tu contabilidad mensual 100% cubierta para una o más fuentes de
							ingreso
						</Text>
						<div
							className={`border ${isHotSale ? 'border-white' : 'border-blue-200'} my-4 mx-4 w-full`}
						/>
						<Heading
							size='m'
							color='white'
							weight='bold'
						>
							Contabilidad básica
						</Heading>
					</div>

					<div className='flex flex-col p-6'>
						<div className='flex flex-col justify-between'>
							{renderPriceBySelectedRegime()}

							<Text
								size='xl'
								weight='bold'
								sx='mt-3'
							>
								Según tu información fiscal tu régimen es
							</Text>
						</div>
						{renderLinkedNameByRegime()}

						<div className='border-t mt-6'>
							<Text
								size='l'
								color='dark'
								weight='bold'
								sx='mt-10'
							>
								Frecuencia de pago
							</Text>
							<div className='flex flex-row gap-8 mt-2'>
								{renderPeriodicitiesByLinkedRegime()}
							</div>
						</div>
					</div>

					<div className='fixed bottom-0 right-0 bg-white shadow w-full px-10 py-6 rounded-bl-lg rounded-br-lg'>
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={isLoadingAddProduct}
							label='Agregar al carrito'
							onClick={() => handleGoToCheckout()}
							className={`${isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(LinkedBasicAccounting);
