import { createSlice } from '@reduxjs/toolkit';
import { DATES } from '../../shared/utils/dates';
import {
	DeclarationsByMonthList,
	SelectedPeriod,
	TPeriod,
	TPeriodicity,
	TaxboardDeclarationStatus,
} from '../../@types/Calendar.type';

interface TaxCalendarState {
	month: number;
	year: number;
	isSelectedPeriod: boolean;
	period: TPeriod;
	annualYearSelected: number;
	showModal: {
		[key: string]: boolean;
		annualPendingMonths: boolean;
	};
	selectedPeriod: SelectedPeriod;
	statusByPriotityMonth: TaxboardDeclarationStatus;
	fiscalResume: {
		month: any | null;
		year: any | null;
	};
}

const initialState: TaxCalendarState = {
	month: DATES.currentMonth,
	year: DATES.currentYear,
	isSelectedPeriod: false,
	period: TPeriodicity.MONTHLY,
	showModal: {
		annualPendingMonths: false,
	},
	annualYearSelected: DATES.currentYear,
	selectedPeriod: {
		month: {
			general: null,
			detail: null,
			detailByRegime: null,
			detailByRegimeComplete: null,
		},
		year: {
			currentYear: null,
			selectedYear: null,
			selectedDeclaration: null,
			selectedDeclarationComplete: null,
		},
	},
	statusByPriotityMonth: TaxboardDeclarationStatus.IN_PROCESS,
	fiscalResume: {
		month: null,
		year: null,
	},
};

export const taxCalendarSlice = createSlice({
	name: 'taxCalendar',
	initialState,
	reducers: {
		updateYear: (state, action) => {
			state.year = action.payload;
		},
		updateMonth: (state, action) => {
			state.month = action.payload;
		},
		selectPeriod: (state) => {
			state.isSelectedPeriod = true;
		},
		unSelectPeriod: (state) => {
			state.isSelectedPeriod = false;
		},
		updatePeriod: (state, action) => {
			state.period = action.payload;
		},
		updatedAnnualYearSelected: (state, action) => {
			state.annualYearSelected = action.payload;
			state.isSelectedPeriod = true;
		},
		restartPeriod: (state) => {
			state.year = initialState.year;
			state.month = initialState.month;
			state.isSelectedPeriod = false;
		},
		setShowModal: (state, action) => {
			state.showModal[action.payload.modalName] = action.payload.show;
		},
		setSelectedPeriod: (state, action) => {
			state.selectedPeriod = action.payload;
			state.statusByPriotityMonth =
				action.payload.month.detail?.declarations?.find(
					(item: DeclarationsByMonthList) => {
						switch (item.status) {
							case TaxboardDeclarationStatus.PENDING:
								return item;
							case TaxboardDeclarationStatus.PENDING_PAY:
								return item;
							case TaxboardDeclarationStatus.IN_PROCESS:
								return item;
							case TaxboardDeclarationStatus.PRESENTED:
								return item;
							default:
								return TaxboardDeclarationStatus.IN_PROCESS;
						}
					},
				)?.status || TaxboardDeclarationStatus.IN_PROCESS;
		},
		setFiscalResume: (state, action) => {
			state.fiscalResume = action.payload;
		},
	},
});

export const {
	updateYear,
	updateMonth,
	restartPeriod,
	selectPeriod,
	unSelectPeriod,
	updatePeriod,
	updatedAnnualYearSelected,
	setShowModal,
	setSelectedPeriod,
	setFiscalResume,
} = taxCalendarSlice.actions;

export default taxCalendarSlice.reducer;
