const COUNTRY_CODES = [
	{
		value: '52',
		label: '+ 52',
    shortName: 'MX',
	},
  {
    value: '57',
    label: '+ 57',
    shortName: 'CO',
  },
  {
    value: '1',
    label: '+ 1',
    shortName: 'US',
  },
  {
    value: '51',
    label: '+ 51',
    shortName: 'PE',
  },
  {
    value: '502',
    label: '+ 502',
    shortName: 'GT',
  },
  {
    value: '54',
    label: '+ 54',
    shortName: 'AR',
  },
  {
    value: '598',
    label: '+ 598',
    shortName: 'UY',
  },
  {
    value: '56',
    label: '+ 56',
    shortName: 'CL',
  },
  {
    value: '44',
    label: '+ 44',
    shortName: 'GB',
  },
  {
    value: '34',
    label: '+ 34',
    shortName: 'ES',
  }
];

export default COUNTRY_CODES;
