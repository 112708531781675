import React from 'react';
import Text from '../../atoms/Text/index';
import { CaretRight } from '@phosphor-icons/react';
interface Props {
	title?: string;
	description?: string | React.ReactNode;
	onHandlerLink?: () => void;
	sxBannerImg?: string;
	sx?: string;
	sxBannerContent?: string;
	labelButton?: string;
}

const BannerHome: React.FC<Props> = ({
	title,
	description,
	sxBannerImg,
	sx,
	onHandlerLink,
	sxBannerContent,
	labelButton,
}) => (
	<div
		className={`flex flex-row justify-between relative rounded-xl sm:rounded-r-none overflow-hidden  ${sx}`}
	>
		<div className={`bg-h-blue-40 p-4  w-full md:w-3/4 ${sxBannerContent}`}>
			<Text
				size='label'
				weight='medium'
				sx='lg:!text-xl'
			>
				{title}
			</Text>
			{typeof description === 'string' ? (
				<>
					<Text
						size='caption'
						sx='mt-4 lg:!text-lg'
					>
						{description}
					</Text>
				</>
			) : (
				<>{description}</>
			)}

			<button
				type='button'
				className='inline-flex items-center justify-center mt-3 '
				onClick={onHandlerLink}
			>
				<Text
					size='mini-1'
					weight='regular'
					sx='py-0.5 lg:!text-base'
				>
					{labelButton}
				</Text>
				<CaretRight size={12} />
			</button>
		</div>
		<div
			className={`rounded-r-lg w-[40%] sm:w-[40%] bg-cover bg-no-repeat bg-right lg:bg-center bg-[url('https://heru-static-assets.s3.us-east-2.amazonaws.com/banner_1.webp')] ${sxBannerImg}`}
		/>
	</div>
);

export default React.memo(BannerHome);
