import React from 'react';
import { CopySimple } from '@phosphor-icons/react';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../redux/slices/alert.slice';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import { RootState } from '../../../redux/store';
import { formatPrice } from '../../../shared/utils/general.util';
import { getFormatDate } from '../../../shared/utils/dates.util';
import { useMediaQuery } from '@mui/material';
import { ALERT_TYPE } from '../../../components/molecules/Alert';
import Text from '../../../components/atoms/Text';
import Button from '../../../components/atoms/Button';

interface PendingCardProps {
	accountNumber: string;
	dateLimit: string;
	total?: number;
	items: any[];
	paymentMethodName: string;
	isSingle?: boolean;
	onPress?: () => void;
	isAllOrderList?: boolean;
}

const PendingCard: React.FC<PendingCardProps> = ({
	isSingle = false,
	accountNumber,
	dateLimit,
	total,
	items,
	paymentMethodName,
	onPress,
	isAllOrderList = false,
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const logReferencePaymentEvent = (
		action: 'copy_payment_number' | 'finalize_purchase',
	) => {
		if (userProfile) {
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_PENDING_PURCHASE_INTERACTION,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
				action,
			});
		}
	};

	const copyToClipboard = () => {
		navigator.clipboard.writeText(accountNumber);
		const alert = {
			type: ALERT_TYPE.SUCCESS,
			title: 'Aviso',
			description: 'Se ha copiado el número de cuenta',
		};
		dispatch(showAlert(alert));
		logReferencePaymentEvent('copy_payment_number');
	};

	return (
		<>
			{isSingle ? (
				<>
					{isMobile ? (
						<div className='flex flex-col bg-h-neutral-94 shadow-md rounded-md p-6'>
							<div className='flex flex-col'>
								<div className='flex items-center justify-center'>
									<Text
										sx='text-center mb-4'
										size='l'
										weight='bold'
									>
										{isAllOrderList
											? `Referencia de pago por ${
													paymentMethodName === 'Cash'
														? 'efectivo Oxxo'
														: 'transferencia bancaria'
												}`
											: '¡A solo un clic de estar al día con el SAT!'}
									</Text>
								</div>

								{items?.map((item, index) => (
									<div key={index}>
										<span className='font-bold text-h-blue-60'>
											{item?.product_version?.product?.name ?? ''}
										</span>
									</div>
								))}

								<Text
									size='s'
									color='dark'
									sx='mt-3 mb-2 !block'
								>
									{`Completa la compra de tu servicio, copia la referencia adjunta y transfiere a través de ${
										paymentMethodName === 'Cash' ? 'OXXO' : 'SPEI'
									} antes del`}
									<span className='font-bold ml-1 text-h-blue-60'>
										{getFormatDate(dateLimit, 'DD MMMM YYYY', true)}
									</span>
								</Text>

								<div className='flex items-center justify-between mt-2'>
									<Text
										size='s'
										color='dark'
									>
										Valor a pagar
									</Text>
									<Text
										size='s'
										color='dark'
										weight='bold'
									>
										${formatPrice(total!)}
									</Text>
								</div>

								<div className='flex items-center justify-between mt-1'>
									<button
										className='flex flex-row gap-x-2 items-center py-3.5'
										onClick={copyToClipboard}
									>
										<CopySimple
											size={18}
											color='var(--h-primary)'
											weight='fill'
										/>
										<div>
											<Text
												color='primary'
												size='xs'
											>
												{`${
													paymentMethodName === 'Cash'
														? 'Referencia de pago'
														: 'CLABE Bancaria'
												}`}
											</Text>
											<Text
												color='primary'
												size='s'
												weight='bold'
											>
												{accountNumber}
											</Text>
										</div>
									</button>
								</div>

								<button
									onClick={onPress}
									className={`flex items-center ${isAllOrderList ? 'bg-h-blue-20' : 'bg-h-primary '} py-3.5 px-8 rounded-md justify-center mt-2`}
								>
									<Text
										size='m'
										color={isAllOrderList ? 'primary' : 'white'}
									>
										Ver instrucciones de pago
									</Text>
								</button>
							</div>
						</div>
					) : (
						<div className='flex flex-col bg-h-neutral-94 shadow-md rounded-md p-6'>
							<div className='flex flex-col items-start mb-6'>
								<Text
									sx='text-center'
									size='xl'
									weight='bold'
								>
									{isAllOrderList
										? `Referencia de pago por ${
												paymentMethodName === 'Cash'
													? 'efectivo Oxxo'
													: 'transferencia bancaria'
											}`
										: '¡A solo un clic de estar al día con el SAT!'}
								</Text>
							</div>

							<div className='flex items-center justify-between '>
								<div className='flex flex-col w-5/12'>
									<div className='flex items-center justify-between'>
										<div className='flex flex-col'>
											<Text
												size='s'
												color='dark'
											>
												Valor a pagar
											</Text>
											<Text
												size='s'
												color='dark'
												weight='bold'
											>
												${formatPrice(total!!)}
											</Text>
										</div>

										<div className='flex items-center justify-between mb-3'>
											<button
												className='flex flex-row gap-x-2 items-center py-3.5 px-2'
												onClick={copyToClipboard}
											>
												<CopySimple
													size={18}
													color='var(--h-primary)'
													weight='fill'
												/>
												<div>
													<Text
														color='primary'
														size='s'
													>
														{`${
															paymentMethodName === 'Cash'
																? 'Referencia de pago'
																: 'CLABE Bancaria'
														}`}
													</Text>
													<Text
														color='primary'
														size='m'
														weight='bold'
													>
														{accountNumber}
													</Text>
												</div>
											</button>
										</div>
									</div>

									<button
										onClick={onPress}
										className={`flex items-center ${isAllOrderList ? 'bg-h-blue-20' : 'bg-h-primary'}  py-3.5 px-8 rounded-md justify-center`}
									>
										<Text
											size='m'
											color={isAllOrderList ? 'primary' : 'white'}
											sx='ml-2.5'
										>
											Ver instrucciones de pago
										</Text>
									</button>
								</div>
								<div className='flex flex-col border-l border-h-primary w-3/6'>
									<div className='ml-8'>
										{items?.map((item, index) => (
											<div key={index}>
												<span className='font-bold text-h-blue-60'>
													{item?.product_version?.product?.name ?? ''}
												</span>
											</div>
										))}

										<Text
											size='m'
											color='dark'
											sx='mt-3 mb-2 !block'
										>
											{`Completa la compra de tu servicio, copia la referencia adjunta y transfiere a través de ${
												paymentMethodName === 'Cash' ? 'OXXO' : 'SPEI'
											} antes del`}
											<span className='font-bold ml-1 text-h-blue-60'>
												{getFormatDate(dateLimit, 'DD MMMM YYYY', true)}
											</span>
										</Text>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<div className='flex flex-col bg-h-neutral-94 p-5 rounded-lg shadow-md'>
					<div className='flex flex-col items-center'>
						<Text
							sx='text-center'
							size='xl'
							weight='bold'
						>
							¡A solo un clic de estar al día con el SAT!
						</Text>
					</div>
					<div className='mt-3'>
						{items?.map((item, index) => (
							<div key={index}>
								<Text
									color='darkMenu'
									weight='medium'
								>
									{item?.product_version?.product?.name ?? ''}
								</Text>
							</div>
						))}

						<Text
							size='m'
							color='dark'
							sx='mt-3 mb-2 !block'
						>
							{`Completa la compra de tu servicio, copia la referencia adjunta y transfiere a través de ${
								paymentMethodName === 'Cash' ? 'OXXO' : 'SPEI'
							} antes del`}
							<span className='font-bold ml-1 text-h-blue-60'>
								{getFormatDate(dateLimit, 'DD MMMM YYYY', true)}
							</span>
						</Text>
					</div>
					<div className='flex flex-col sm:flex-row sm:items-center sm:justify-between mb-3'>
						<button
							className='flex flex-row gap-x-2 items-center py-3.5 px-2'
							onClick={copyToClipboard}
						>
							<CopySimple
								size={18}
								color='var(--h-primary)'
								weight='fill'
							/>
							<div>
								<Text
									color='primary'
									size='s'
								>
									{`${
										paymentMethodName === 'Cash'
											? 'Referencia de pago'
											: 'CLABE Bancaria'
									}`}
								</Text>
								<Text
									color='primary'
									size='m'
									weight='bold'
								>
									{accountNumber}
								</Text>
							</div>
						</button>

						<div className='flex justify-between sm:flex-col items-center sm:items-end gap-x-2'>
							<Text
								size='s'
								color='dark'
							>
								Valor a pagar
							</Text>
							<Text
								size='m'
								weight='bold'
								color='dark'
							>
								${formatPrice(total!)}
							</Text>
						</div>
					</div>

					<Button
						color='primary'
						label='Ver instrucciones de pago'
						onClick={onPress}
					/>
				</div>
			)}
		</>
	);
};

export default React.memo(PendingCard);
