import React, { useEffect, useState } from 'react';
import { CaretCircleLeft } from '@phosphor-icons/react';
import Heading from '../../components/atoms/Heading';
import { PendingProduct } from '../../@types/Ecommerce';
import {
	fetchPendingOrders,
	getPendingOrderDetail,
} from '../../services/orders.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CircularProgress } from '@mui/material';
import ModalReference from './components/ModalReference';
import PendingCard from './components/PendingCard';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';

interface Props {}

const PendingReferences: React.FC<Props> = ({}) => {
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [orderDetails, setOrderDetails] = useState<PendingProduct[]>([]);
	const [isLoadingPendingOrders, setIsLoadingPendingOrders] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState<PendingProduct | null>(
		null,
	);
	const [showModalPaymentSteps, setShowModalPaymentSteps] = useState(false);

	const navigate = useNavigate();

	const getPendingOrder = async () => {
		try {
			setIsLoadingPendingOrders(true);
			const orders = await fetchPendingOrders(tokenSession);

			const detailsPromises = orders.map(async (order: any) => {
				const details = await getPendingOrderDetail(order?.id, tokenSession);
				return { ...details, items: order?.items };
			});

			const details = await Promise.all(detailsPromises);
			setOrderDetails(details);
		} catch (error: any) {
		} finally {
			setIsLoadingPendingOrders(false);
		}
	};

	useEffect(() => {
		getPendingOrder();
	}, []);

	const onOpenInstructions = (order: PendingProduct) => {
		setSelectedOrder(order);
		setShowModalPaymentSteps(true);
	};

	return (
		<>
			<ModalReference
				showModal={showModalPaymentSteps}
				setShowModal={setShowModalPaymentSteps}
				paymentMethod={selectedOrder?.payment_method_name ?? ''}
				reference={selectedOrder?.payment_reference_code ?? ''}
				total={selectedOrder?.total ?? 0}
			/>
			<div className='p-4 lg:px-12 lg:py-8 w-full h-full'>
				<div className='flex flex-row items-center mb-6 lg:mb-4'>
					<button
						onClick={() => {
							navigate(ROUTES.ROOT);
						}}
					>
						<CaretCircleLeft
							size={20}
							weight='fill'
							className='mr-3'
							color='var(--h-primary)'
						/>
					</button>
					<Heading
						size='xs'
						color='dark'
						weight='bold'
					>
						Mis referencias pendientes{' '}
						{!isLoadingPendingOrders && `(${orderDetails?.length})`}
					</Heading>
				</div>
				<div>
					{isLoadingPendingOrders ? (
						<div className='flex items-center justify-center'>
							<CircularProgress size={25} />
						</div>
					) : (
						<>
							{orderDetails.map((order, index) => (
								<div
									className='mb-8'
									key={index}
								>
									<PendingCard
										accountNumber={order?.payment_reference_code ?? ''}
										paymentMethodName={order?.payment_method_name ?? ''}
										dateLimit={order?.expires_at ?? ''}
										total={order?.total ?? 0}
										items={order?.items ?? []}
										isSingle
										isAllOrderList
										onPress={() => onOpenInstructions(order)}
									/>
								</div>
							))}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default PendingReferences;
