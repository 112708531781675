import {
	Checkbox,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	createTheme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Info, XCircle } from '@phosphor-icons/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Text from '../Text';
import Tooltip from '../Tooltip';

interface Props {
	name: string;
	type: string;
	placeholder?: string;
	sx?: string;
	label?: string;
	errorMessage?: string;
	autofocus?: boolean;
	rules?: object;
	multiline?: boolean;
	minRows?: number;
	maxLength?: number;
	showValueUpperCase?: boolean;
	control?: any;
	isDarkTheme?: boolean;
	showTooltip?: boolean;
	tooltipMessage?: string;
	size?: 'small' | 'medium';
}

const Input = ({
	name,
	type,
	placeholder = 'Escribe aquí',
	sx = '',
	label = '',
	errorMessage,
	autofocus,
	control,
	rules,
	multiline = false,
	minRows = 0,
	maxLength,
	showValueUpperCase = false,
	isDarkTheme = false,
	showTooltip,
	tooltipMessage,
	size = 'medium',
}: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const { control: defaultControl } = useForm();

	const darkTheme = createTheme({
		palette: {
			mode: isDarkTheme ? 'dark' : 'light',
		},
	});

	return (
		<ThemeProvider theme={darkTheme}>
			<div className={`${isDarkTheme && 'customInput'} relative w-full ${sx}`}>
				<Controller
					rules={rules}
					control={control || defaultControl}
					name={name}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<>
							<FormControl
								variant='outlined'
								fullWidth
							>
								<InputLabel
									error={!!error || !!errorMessage}
									sx={isDarkTheme ? { color: 'white !important' } : {}}
								>
									{label}
								</InputLabel>

								<OutlinedInput
									size={size}
									multiline={multiline}
									minRows={minRows}
									className={
										multiline ? 'multiline' : isDarkTheme ? 'darktheme' : ''
									}
									autoFocus={autofocus}
									error={!!error || !!errorMessage}
									onChange={onChange}
									value={showValueUpperCase ? value.toUpperCase() : value}
									fullWidth
									placeholder={placeholder}
									label={label}
									inputProps={{ maxLength }}
									type={
										type === 'password'
											? showPassword
												? 'text'
												: 'password'
											: type
									}
									endAdornment={
										showTooltip && (
											<InputAdornment position='end'>
												<Tooltip message={tooltipMessage || ''}>
													<IconButton
														aria-label='tooltip label'
														edge='end'
													>
														<Info size={18} />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										)
									}
								/>
								<div className='flex flex-row items-center'>
									{(!!error || !!errorMessage) && (
										<XCircle
											size={18}
											weight='fill'
											color='var(--h-red-50)'
										/>
									)}
									<FormHelperText
										error={!!error || !!errorMessage}
										sx={{ marginLeft: '5px' }}
									>
										{errorMessage ? errorMessage : error ? error.message : null}
									</FormHelperText>
								</div>
							</FormControl>
							{type === 'password' && (
								<div
									className='mt-[-10px] ml-[-10px]'
									onClick={() => setShowPassword(!showPassword)}
								>
									<Text
										size='m'
										sx='mt-1.5'
										color={isDarkTheme ? 'white' : 'medium'}
									>
										<Checkbox
											checked={showPassword}
											sx={{
												color: isDarkTheme ? 'white' : 'var(--h-primary)',
												'&.Mui-checked': {
													color: isDarkTheme ? 'white' : 'var(--h-primary)',
												},
											}}
										/>
										<span className='ml-[-10px]'>
											{showPassword
												? 'Ocultar contraseña'
												: 'Mostrar contraseña'}
										</span>
									</Text>
								</div>
							)}
						</>
					)}
				/>
			</div>
		</ThemeProvider>
	);
};

export default Input;
