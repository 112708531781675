import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PlanRegularizationPeriod, PropsPlanFlow } from '..';
import {
	PlanRegimeLeaseSelector,
	PlanRegularizationPeriodSelector,
	PlanRegularizationRegimeSelector,
} from '../organisms/PlanSelectors';
import PrimaryButton from '../../../components/atoms/Button';
import { ArrowLeft } from '@phosphor-icons/react';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import useAddToCart from '../../../hooks/useAddToCart';
import { REGIMES_CODE } from '../../../@types/Regime.type';
import { REGULARIZATION_REGARATION_PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { useEffect, useState } from 'react';
import { DATES, getLastYears } from '../../../shared/utils/dates';
import { fetchMonthlyDeclarationOverview } from '../../../services/taxes.service';
import { OverviewDeclarationsYear } from '../../../@types/Calendar.type';
import { setPlanConfig } from '../../../redux/slices/plans.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { Item } from '../../../@types/Ecommerce';
import { RegimeFFT } from '../../../@types/Products.type';

const RegularizationsFlow: React.FC<PropsPlanFlow> = ({
	planConfig,
	product,
	startingPrice,
}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);

	const { step } = planConfig;
	const [declarationStatus, setDeclarationStatus] = useState<{
		[year: number]: OverviewDeclarationsYear;
	} | null>(null);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;
	const { products } = useSelector((state: RootState) => state.productList);

	const productsByRegimeCode: Record<string, string> = {
		[REGIMES_CODE.ACTIVIDAD_EMPRESARIAL]:
			REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ACT_EMP,
		[REGIMES_CODE.RESICO]:
			REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RESICO_PLUS,
		[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]:
			REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
		[REGIMES_CODE.ARRENDAMIENTO]:
			planConfig?.regimeConfig?.lease === 'quarterly'
				? REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_TRIMESTRAL
				: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_ARR_MES,
		[REGIMES_CODE.RIF]: REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_RIF,
	};

	const isRIF = planConfig?.regimes?.[0] === REGIMES_CODE.RIF;
	const isLease = planConfig?.regimes?.includes(REGIMES_CODE.ARRENDAMIENTO);
	const isLeaseQuarterly = planConfig?.regimeConfig?.lease === 'quarterly';

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const regularizationProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.slug ===
				productsByRegimeCode[
					planConfig?.regimes?.[0] ||
						REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES
				],
		);

	const regularizationProductBenefits = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.slug ===
				REGULARIZATION_REGARATION_PRODUCTS_SLUG.REG_PT_TODAS_ACTIVIDADES,
		)?.benefits;

	const regProductPrice =
		regularizationProduct &&
		getPriceWithElasticity(regularizationProduct?.price / 100);

	const handleNextStep = () => {
		if (step === 3 || (step === 2 && !isLease)) {
			handleAddProduct();
			return;
		}
		dispatch(
			setPlanConfig({
				...planConfig,
				step: planConfig.step + 1,
			}),
		);
	};
	const handlePreviousStep = () => {
		dispatch(
			setPlanConfig({
				...planConfig,
				regularizationPeriods: undefined,
				step: planConfig.step - 1,
			}),
		);
	};

	const disableButton: Record<number, boolean> = {
		1: !planConfig.regimes?.length,
		2: isLease
			? !planConfig.regimeConfig?.lease
			: !planConfig.regimes?.length ||
				!planConfig.regularizationPeriods?.length,
		3: !planConfig.regularizationPeriods?.length,
	};

	const buttonText: Record<number, string> = {
		1: isLease ? 'Continuar 1 de 3' : 'Continuar 1 de 2',
		2: isLease ? 'Continuar 2 de 3' : product.buttonText,
		3: product.buttonText,
	};

	const confirmedRegime = step > 1;
	const confirmedRegimeConfig = step > 2;

	const numberOfPeriodsSelected = planConfig?.regularizationPeriods?.length;

	const propsTopBanner = {
		productName,
		subtitle,
		price: regProductPrice
			? numberOfPeriodsSelected
				? regProductPrice * numberOfPeriodsSelected
				: regProductPrice
			: startingPrice,
		afterPrice: numberOfPeriodsSelected
			? `Por ${numberOfPeriodsSelected} periodos`
			: 'Por periodo',
		beforePrice: !numberOfPeriodsSelected ? 'Desde' : '',
	};

	const handleAddProduct = async () => {
		if (
			!planConfig?.regularizationPeriods ||
			!planConfig?.regimes?.[0] ||
			!regProductPrice
		)
			return;

		const regimes = planConfig?.regimes?.map((regime) => RegimeFFT[regime]);

		const getAdditionalInfoByPeriod = (period: PlanRegularizationPeriod) => {
			if (isLeaseQuarterly || isRIF) {
				return {
					type: period?.type,
					period: period?.period,
					year: period?.year,
					regimes,
				};
			}
			return {
				month: period?.month,
				year: period?.year,
				regimes,
			};
		};

		const payload = planConfig?.regularizationPeriods?.map((period) => ({
			product_version_id: regularizationProduct?.default_product_version_id,
			custom_product: {
				group_experiment: elasticityPrice.group_experiment,
				total_paid: Number(regProductPrice * 100),
				payload_user_id: userProfile?.id,
			},
			additional_information: getAdditionalInfoByPeriod(period),
		}));

		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: regularizationProduct?.name || 'regularization',
			regime: regularizationProduct?.regime?.join('') || '',
			periods: planConfig?.regularizationPeriods,
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

		const response = await handleAddCustomProduct(payload);
		dataLayer.push({ ecommerce: null });
		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_TO_CART,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				items: response?.map((item: Item) => ({
					price: item?.base_price / 100,
					item_id: item?.product_version?.product_id,
					item_name: item?.product_version?.product?.name,
					category: item?.product_version?.product?.regime?.toString(),
					item_category1: item?.product_version?.product?.category,
					item_category2: item?.purchase_type,
				})),
			},
		});
	};

	const getDeclarationsStatus = async () => {
		try {
			const years = getLastYears(5, DATES.currentYear);

			for (const year of years) {
				const response = await fetchMonthlyDeclarationOverview(
					userProfile?.id!,
					year,
				);

				setDeclarationStatus((prevState) => ({
					...prevState,
					[year]: response,
				}));
			}
		} catch (error) {}
	};

	useEffect(() => {
		getDeclarationsStatus();
	}, [userRegimes]);

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					<div className='flex flex-col gap-3'>
						{step > 1 && (
							<PrimaryButton
								label='Volver'
								variant='text'
								labelColor='primary'
								onClick={handlePreviousStep}
								labelSize='caption'
								beforeIcon={<ArrowLeft weight='bold' />}
								sx='flex items-center gap-2 mb-4 px-2'
							/>
						)}
						<PlanRegularizationRegimeSelector
							planConfig={planConfig}
							confirmed={confirmedRegime}
						/>
						{isLease && confirmedRegime && (
							<PlanRegimeLeaseSelector
								planConfig={planConfig}
								confirmed={confirmedRegimeConfig}
							/>
						)}
						{((isLease && step > 2) || (!isLease && step > 1)) && (
							<>
								<hr />
								<PlanRegularizationPeriodSelector
									planConfig={planConfig}
									declarationStatus={declarationStatus}
								/>
							</>
						)}
						<BenefitsDropdown benefits={regularizationProductBenefits} />
					</div>
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={disableButton[step]}
					buttonLabel={buttonText[step]}
					onClickButton={handleNextStep}
					isLoading={isLoadingAddToCart.products}
				/>
			</div>
		</>
	);
};

export default RegularizationsFlow;
