import { createSlice } from '@reduxjs/toolkit';
import { UserIssuedInvoice } from '../../@types/UserIssuedInvoice.type';

type InvoicingInitialState = {
	currentPage: string;
	showModalStamps: boolean;
	avaibleSteps: number;
	currentStep: number;
	issuedInvoices: UserIssuedInvoice[];
	issuerName: string | null;
};

const initialState: InvoicingInitialState = {
	currentPage: 'dashboard',
	showModalStamps: false,
	avaibleSteps: 1,
	currentStep: 1,
	issuedInvoices: [],
	issuerName: null,
};

export const invocingSlice = createSlice({
	name: 'invocing',
	initialState,
	reducers: {
		setCurrentInvoicingPage: (state, action) => {
			state.currentPage = action.payload;
		},
		setShowModalStamps: (state, action) => {
			state.showModalStamps = action.payload;
		},
		setInvoicingAvaibleSteps: (state, action) => {
			state.avaibleSteps = action.payload;
		},
		setInvoicingCurrentStep: (state, action) => {
			state.currentStep = action.payload;
		},
		setUserIssuedInvoices: (state, action) => {
			state.issuedInvoices = action.payload;
		},
		setIssuerName: (state, action) => {
			state.issuerName = action.payload;
		},
	},
});

export const {
	setCurrentInvoicingPage,
	setShowModalStamps,
	setInvoicingAvaibleSteps,
	setInvoicingCurrentStep,
	setUserIssuedInvoices,
	setIssuerName,
} = invocingSlice.actions;

export default invocingSlice.reducer;
