import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { getCountryFlag } from '../../../shared/utils/general.util';
import { XCircle } from '@phosphor-icons/react';
interface Props {
	name: string;
	options: {
		value: string | number;
		label: string | number;
		shortName?: string;
	}[];
	label?: string;
	sx?: string;
	control: any;
	hasFlag?: boolean;
	disabled?: boolean;
	error?: any;
	errorMessage?: string;
}

const Selector = ({
	name,
	options,
	control,
	label,
	sx,
	error,
	errorMessage,
	hasFlag = false,
	disabled = false,
}: Props) => {
	const { control: defaultControl } = useForm({
		defaultValues: { [name]: options?.[0]?.value },
	});

	return (
		<Controller
			control={control || defaultControl}
			name={name}
			render={({ field }) => (
				<div className={hasFlag ? 'w-56 md:w-40' : ''}>
					{label && <InputLabel className='!text-sm'>{label}</InputLabel>}
					{hasFlag ? (
						<Select
							size='small'
							{...field}
							className={`bg-white border-none !rounded-lg p-0 ${sx}`}
						>
							{options.map(({ value, label, shortName }, index) => (
								<MenuItem
									value={value}
									key={`${value}-${label}-${index}`}
								>
									<div className='flex items-center'>
										<img
											src={getCountryFlag(shortName ?? '')}
											className='w-4 h-4 md:w-6 md:h-6 mr-2'
										/>

										{label}
									</div>
								</MenuItem>
							))}
						</Select>
					) : (
						<>
							<Select
								disabled={disabled}
								size='small'
								placeholder='Selecciona una opción'
								error={!!error || !!errorMessage}
								{...field}
								className={`bg-white border-none !rounded-lg p-0 ${sx}`}
							>
								{options.map(({ value, label }, idx) => (
									<MenuItem
										value={value}
										key={`${value}-${label}-${idx}`}
									>
										{label}
									</MenuItem>
								))}
							</Select>
							<div className='flex flex-row items-center'>
								{(!!error || !!errorMessage) && (
									<XCircle
										size={18}
										weight='fill'
										color='var(--h-red-50)'
									/>
								)}
								<FormHelperText
									error={!!error || !!errorMessage}
									sx={{ marginLeft: '5px' }}
								>
									{errorMessage ? errorMessage : error ? error.message : null}
								</FormHelperText>
							</div>
						</>
					)}
				</div>
			)}
		/>
	);
};

export default Selector;
