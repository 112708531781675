import React from 'react';

import { ArrowRight } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

//? Types
import { SubscriptionType } from '../../../../@types/Subscription';

//? Components
import SubCard from '../../../mySubscriptions/SubCard';
import Button from '../../../../components/atoms/Button';
import HomeCard from '../../../../components/molecules/HomeCard';
//? Utils
import ROUTES from '../../../../shared/constants/routes';
import LastPendingOrder from '../../../pendingReferences/components/LastPendingOrder';
interface Props {
	activeSuscriptions: SubscriptionType[];
	subscriptions: SubscriptionType[];
	fetchUserSubscriptions: () => void;
}

const MultipleTop: React.FC<Props> = ({
	activeSuscriptions,
	subscriptions,
	fetchUserSubscriptions,
}) => {
	const navigate = useNavigate();

	const getSubscriptionByStatus = () => {
		if (activeSuscriptions.length > 0) {
			return [activeSuscriptions[0]];
		} else {
			return [subscriptions[0]];
		}
	};

	return (
		<div className='flex flex-col md:flex-row w-full justify-between mb-6 md:mb-10'>
			<div className='w-full flex'>
				<LastPendingOrder />
			</div>
			<div className='flex flex-col bg-white w-full md:ml-5 rounded-md mt-6 md:mt-0'>
				<HomeCard
					title={`${subscriptions.length > 1 ? 'Suscripciones' : 'Suscripción'}`}
					showButton={false}
					headerButton={
						<>
							{subscriptions.length > 1 && (
								<Button
									onClick={() => {
										navigate(ROUTES.MY_SUBSCRIPTIONS);
									}}
									type='button'
									label={'Ver todas mis suscripciones'}
									variant='text'
									icon={<ArrowRight size={20} />}
									labelColor='primary'
								/>
							)}
						</>
					}
				>
					<div className='mt-6'>
						{getSubscriptionByStatus().map(
							(subscription: SubscriptionType, index: number) => (
								<SubCard
									fetchUserSubscriptions={fetchUserSubscriptions}
									item={subscription}
									key={index}
								/>
							),
						)}
					</div>
				</HomeCard>
			</div>
		</div>
	);
};

export default React.memo(MultipleTop);
