import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import Modal from '../../../../components/molecules/Modal/Modal';
import {
	deleteItemBlockedCart,
	getListBlockedCart,
} from '../../../../services/serviceStatus.service';
import { BlockedSub } from '../../../../@types/BlockedSubs';
import Heading from '../../../../components/atoms/Heading';
import Text from '../../../../components/atoms/Text';
import { CaretLeft, Trash } from '@phosphor-icons/react';
import { formatCurrency } from '../../../../shared/utils/general.util';
import { CircularProgress, useMediaQuery } from '@mui/material';
import Divider from '../../../../components/atoms/Divider';
import { getCompleteMonth } from '../../../../shared/utils/dates.util';
import Button from '../../../../components/atoms/Button';
import { Card, PAYMENT_METHOD } from '../../../../@types/Checkout.type';
import { IPaymentInfo } from '../../../../@types/Payments.types';
import {
	createNewCustomer,
	getClientCustomer,
	getPaymentCards,
} from '../../../../services/payments.service';
import SelectUnlockPayment from './SelectUnlockPayment';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getBrandIcon } from '../../../../shared/utils/payments.util';
interface Props {
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<any>>;
	activeUnlockModal?: boolean;
	onCallbackSuccess?: (value: any) => void;
	onCallbackError?: (error: any) => void;
	isSingle?: boolean;
	fetchServiceStatus: () => void;
}

const steps = [
	{
		id: 1,
		name: 'Productos',
	},
	{
		id: 2,
		name: 'Método de pago',
	},
	{
		id: 3,
		name: 'Confirmar',
	},
];

const ModalUnlockStatements = ({
	showModal,
	setShowModal,
	activeUnlockModal,
	isSingle,
	fetchServiceStatus,
}: Props) => {
	const [order, setOrder] = useState<BlockedSub[]>([]);
	const [isLoadingCart, setIsLoadingCart] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const getOrder = async () => {
		try {
			setIsLoadingCart(true);
			const data: BlockedSub[] = await getListBlockedCart(tokenSession);
			if (data && data.length > 0) {
				setOrder(data);
			}
		} catch (error) {
		} finally {
			setIsLoadingCart(false);
		}
	};

	const handleDelete = async (itemId: number) => {
		try {
			await deleteItemBlockedCart(order?.[0]?.id, itemId, tokenSession);
			getOrder();
		} catch (error) {}
	};

	useEffect(() => {
		getOrder();
	}, [activeUnlockModal]);

	const [step, setStep] = useState(1);

	//@ts-ignore
	const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(
		PAYMENT_METHOD.CARD,
	);

	const [confrimPayment, setConfrimPayment] = useState(false);

	const [customerStripeId, setCustomerStripeId] = useState('');

	const [tokenIdPurchased, setTokenIdPurchased] = useState('');
	//@ts-ignore
	const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>();
	const [allPaymentsCards, setAllPaymentsCards] = useState<Card[]>([]);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const updatedSteps = steps.map((stepItem) => ({
		...stepItem,
		active: stepItem.id <= step,
		opacity: stepItem.id < step,
	}));

	const selectPaymentMethod = (
		paymentMethod: PAYMENT_METHOD,
		paymentInfo?: IPaymentInfo,
	) => {
		setPaymentMethod(paymentMethod);
		if (!!paymentInfo) {
			setPaymentInfo(paymentInfo);
		}
	};

	const getCustomer = useCallback(async () => {
		try {
			const response = await getClientCustomer(tokenSession);
			if (response) {
				setCustomerStripeId(response[0].id ?? '');
			}
		} catch (error: any) {
			if (error?.response?.status === 404) {
				const response = await createNewCustomer(tokenSession);
				if (response) {
					setCustomerStripeId(response.id ?? '');
				}
				return;
			}
		}
	}, [order]);

	const getCardList = useCallback(async () => {
		try {
			const resp: Card[] = await getPaymentCards(tokenSession);
			setAllPaymentsCards(resp);
		} catch (error) {}
	}, []);

	useEffect(() => {
		if (tokenIdPurchased !== '') {
			getCardList();
		}
	}, [tokenIdPurchased]);

	const getPurchasedCard = allPaymentsCards.find(
		(card) => card.id === tokenIdPurchased,
	);

	useEffect(() => {
		if (order?.length > 0) {
			getCustomer();
		}
	}, [order?.[0]?.user_id]);

	const handleUnlockFinish = () => {
		setShowModal(false);
		setStep(1);
		fetchServiceStatus();
	};

	const getNameCard = (brand: string) => {
		if (brand === 'visa') {
			return 'Visa';
		} else if (brand === 'mastercard') {
			return 'Mastercard';
		} else {
			return 'American Express';
		}
	};

	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
			sx='w-[80%] max-h-[750px]'
		>
			{isLoadingCart ? (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={30} />
				</div>
			) : (
				<div className='p-0 lg:p-2 flex flex-col'>
					<Heading
						size={isMobile ? 'xs' : 's'}
						weight='bold'
						color='medium'
					>
						Declaraciones bloqueadas
					</Heading>

					{step === 2 && (
						<button
							onClick={() => setStep(1)}
							className='flex items-center mt-6'
						>
							<CaretLeft
								size={20}
								color='var(--h-gray-50)'
							/>
							<Text
								size='body-3'
								weight='regular'
								color='light'
								sx='ml-2'
							>
								Volver
							</Text>
						</button>
					)}

					<div className='flex items-center justify-between mt-6 mb-8 w-full'>
						{updatedSteps.map((item, index) => (
							<>
								<div
									key={`step-${index}`}
									className={`hidden lg:flex items-center ${
										index !== updatedSteps.length - 1 ? 'mr-2' : ''
									} ${index === 0 ? 'ml-2' : ''}`}
								>
									<div
										className={`w-6 h-6 rounded-full mr-2 ${
											item.active ? 'bg-h-primary-50' : 'bg-h-neutral-70'
										} ${
											item.opacity ? 'opacity-50' : ''
										} flex items-center justify-center`}
									>
										<Text
											size='s'
											weight='regular'
											color='white'
										>
											{item.id}
										</Text>
									</div>

									{item.active ? (
										<Text
											size='body-3'
											weight='regular'
											color={item.opacity ? 'ultraBlueLight' : 'primary'}
										>
											{item.name}
										</Text>
									) : (
										<Text
											size='body-3'
											weight='light'
											color='light'
										>
											{item.name}
										</Text>
									)}

									{index !== updatedSteps.length - 1 && (
										<div
											className={`h-0.5 w-36 md:w-44 ml-2 ${
												item.active ? 'bg-h-primary-50' : 'bg-h-gray-20'
											} ${item.opacity ? 'opacity-50' : ''} rounded-lg`}
										/>
									)}
								</div>

								<div className='flex lg:hidden items-center'>
									<div
										className={`flex flex-col  items-center ${
											index !== updatedSteps.length - 1 ? 'mb-2' : ''
										} ${index === 0 ? 'mb-2' : ''}`}
									>
										<div
											className={`w-6 h-6 rounded-full mb-2 ${
												item.active ? 'bg-h-primary-50' : 'bg-h-neutral-70'
											} ${
												item.opacity ? 'opacity-50' : ''
											} flex items-center justify-center`}
										>
											<Text
												size='s'
												weight='regular'
												color='white'
											>
												{item.id}
											</Text>
										</div>

										{item.active ? (
											<Text
												size='caption'
												weight='regular'
												color={item.opacity ? 'ultraBlueLight' : 'primary'}
												sx='text-center'
											>
												{item.name}
											</Text>
										) : (
											<Text
												size='caption'
												weight='light'
												color='light'
												sx='text-center'
											>
												{item.name}
											</Text>
										)}
									</div>
									{index !== updatedSteps.length - 1 && (
										<div
											className={`h-0.5 w-4 md:ml-20 sm:ml-5 ml-2 ${
												item.active ? 'bg-h-primary-50' : 'bg-h-gray-20'
											} ${item.opacity ? 'opacity-50' : ''} rounded-lg`}
										/>
									)}
								</div>
							</>
						))}
					</div>

					{step === 1 && (
						<>
							<div className='flex flex-col'>
								{!isSingle && (
									<div className='flex flex-col'>
										<Text
											size='body-2'
											weight='bold'
											color='medium'
										>
											Selecciona los productos
										</Text>
										<Text
											size='body-3'
											weight='light'
											color='medium'
										>
											Confirma las declaraciones que quieres desbloquear.
										</Text>
									</div>
								)}

								<div className='mt-4 w-full'>
									<div className='max-h-96 overflow-y-auto overflow-scrolling-touch hide-scrollbar'>
										<div className='mt-1'>
											{order?.[0]?.items?.map((item, index) => (
												<div
													key={`item-${index}`}
													className='flex items-center justify-between border border-h-gray-20 p-4 mb-2 rounded-lg shadow-sm'
												>
													<div className='flex items-center'>
														<div className='flex flex-col'>
															<Text
																size='body-3'
																weight='bold'
																color='medium'
															>
																Declaración{' '}
																{getCompleteMonth(
																	item?.additional_information?.month,
																)}{' '}
																{item?.additional_information?.year}
															</Text>
															<Text
																size='caption'
																weight='light'
																color='medium'
															>
																Pago único por renovación fallida
															</Text>
														</div>

														{!isSingle && (
															<button
																onClick={() => handleDelete(item?.id)}
																className='ml-5'
															>
																<Trash
																	size={24}
																	weight='light'
																/>
															</button>
														)}
													</div>

													<div>
														<Text
															size='body-3'
															weight='bold'
															color='medium'
														>
															{formatCurrency(item?.total / 100)}
														</Text>
													</div>
												</div>
											))}
										</div>
										<div className='mt-4 mb-1'>
											<Divider />
										</div>

										<div className='border bg-h-primary-95 flex flex-col p-4 rounded-lg shadow-sm'>
											<div className='flex items-center justify-between'>
												<Text
													size='caption'
													weight='light'
													color='medium'
												>
													Subtotal
												</Text>
												<Text
													size='body-3'
													weight='light'
													color='medium'
												>
													{formatCurrency(order?.[0]?.total / 100)}
												</Text>
											</div>

											<div className='mt-4 mb-1'>
												<Divider />
											</div>

											<div className='flex items-center justify-between'>
												<Text
													size='body-3'
													weight='bold'
													color='medium'
												>
													Total
												</Text>
												<Text
													size='body-3'
													weight='bold'
													color='medium'
												>
													{formatCurrency(order?.[0]?.total / 100)}
												</Text>
											</div>
										</div>
									</div>
								</div>

								<div className='flex items-end justify-end mt-8'>
									<div className='w-full lg:w-64 h-12'>
										<Button
											disabled={order?.[0]?.items?.length === 0}
											onClick={() => setStep(2)}
											label={`Desbloquear ${
												isSingle ? `declaración` : `declaraciones`
											}`}
										/>
									</div>
								</div>
							</div>
						</>
					)}

					{step === 2 && (
						<>
							<div className='flex flex-col'>
								<div className='flex flex-col'>
									<Text
										size='body-2'
										weight='bold'
										color='medium'
									>
										Métodos de pago
									</Text>
									<Text
										size='body-3'
										weight='light'
										color='medium'
									>
										Selecciona el método de pago
									</Text>
								</div>

								<div className='mt-4 w-full'>
									<div className='max-h-96 overflow-y-auto hide-scrollbar'>
										<SelectUnlockPayment
											order={order?.[0]}
											selectPaymentMethod={selectPaymentMethod}
											confirmPayment={confrimPayment}
											setConfrimPayment={setConfrimPayment}
											customerStripeId={customerStripeId}
											onSuccess={() => setStep(3)}
											setTokenIdPurchased={setTokenIdPurchased}
										/>
									</div>
								</div>

								<div className='flex items-end justify-end mt-8'>
									<div className='w-full lg:w-64 h-12'>
										<Button
											loading={confrimPayment}
											onClick={() => setConfrimPayment(true)}
											label={`Realizar pago`}
										/>
									</div>
								</div>
							</div>
						</>
					)}

					{step === 3 && (
						<>
							<div className='flex flex-col'>
								<div className='flex flex-col'>
									<Text
										size='body-1'
										weight='bold'
										color='medium'
									>
										¡Gracias por tu compra!
									</Text>
									<Text
										size='body-3'
										weight='light'
										color='medium'
										sx='mt-2'
									>
										Estamos tramitando tu orden.
									</Text>

									<div className='flex flex-col lg:flex-row'>
										<Text
											size='body-3'
											weight='light'
											color='medium'
										>
											Recibirás un correo de confirmación a
										</Text>
										<Text
											size='body-3'
											weight='regular'
											color='medium'
											sx='underline lg:ml-1'
										>
											{userProfile?.email}
										</Text>
									</div>
								</div>

								<div className='mt-8 w-full'>
									<div className='max-h-96 overflow-y-auto hide-scrollbar'>
										{order?.[0]?.items?.map((item) => (
											<div
												key={item.id}
												className='flex items-center justify-between mb-4'
											>
												<div className='flex flex-col'>
													<Text
														size='body-3'
														weight='bold'
														color='medium'
													>
														Declaración{' '}
														{getCompleteMonth(
															item?.additional_information?.month,
														)}{' '}
														{item?.additional_information?.year}
													</Text>
													<Text
														size='caption'
														weight='light'
														color='medium'
													>
														Pago único por renovación fallida
													</Text>
												</div>
												<div>
													<Text
														size='body-3'
														weight='bold'
														color='medium'
													>
														{formatCurrency(item?.total / 100)}
													</Text>
												</div>
											</div>
										))}

										<div className='flex items-center justify-between mt-6'>
											<Text
												size='body-2'
												weight='bold'
												color='medium'
											>
												Total pagado
											</Text>
											<Text
												size='body-2'
												weight='bold'
												color='medium'
											>
												{order?.[0]?.total
													? formatCurrency(order?.[0]?.total / 100)
													: '-'}
											</Text>
										</div>
									</div>
								</div>

								<div className=' bg-h-blue-20 flex items-center justify-between rounded-lg p-4 mt-4'>
									<div className='flex items-center'>
										<img
											src={getBrandIcon(getPurchasedCard?.details?.brand ?? '')}
											className='w-12 h-12'
											alt='brand'
										/>
										<div className='flex flex-col ml-4'>
											<Text
												size={isMobile ? 'caption' : 'body-3'}
												weight='regular'
												color='medium'
											>
												{getNameCard(getPurchasedCard?.details?.brand ?? '')}
											</Text>

											<Text
												size={isMobile ? 'caption' : 'body-3'}
												weight='regular'
												color='medium'
												sx={`${isMobile ? 'mt-2' : ''}`}
											>
												{getPurchasedCard?.details?.expires}
											</Text>
										</div>
									</div>
									<div>
										<Text
											size={isMobile ? 'mini-1' : 'body-3'}
											weight='regular'
											color='medium'
											sx='ml-2'
										>
											xxxx xxxx xxxx {getPurchasedCard?.details?.last_four}
										</Text>
									</div>
								</div>

								<div className='flex items-end justify-end mt-8'>
									<div className='w-full lg:w-72 h-12'>
										<Button
											onClick={handleUnlockFinish}
											label={
												isSingle
													? `Volver al estado de mi servicio`
													: `Volver al estado de mis servicios`
											}
										/>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</Modal>
	);
};

export default ModalUnlockStatements;
