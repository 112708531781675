import {
	CaretDown,
	CaretUp,
	CheckCircle,
	ShootingStar,
	ShoppingCart,
} from '@phosphor-icons/react';
import React, { useState } from 'react';
import Text from '../../../../../components/atoms/Text';
import Heading from '../../../../../components/atoms/Heading';
import Button from '../../../../../components/atoms/Button';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../../../shared/constants/events';
import PromoPrice from './PromoPrice';
import useCleverTapEvent from '../../../../../hooks/useClevertapEvent';
import hotSaleImg from '../../../../../assets/img/svg/hot_sale.svg';
import hot50Img from '../../../../../assets/img/svg/hot50.svg';
import hot30Img from '../../../../../assets/img/svg/hot30.svg';
import hot40Img from '../../../../../assets/img/svg/hot40.svg';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

export interface ProductCardProps {
	description: string;
	title: string;
	benefits: string[];
	allBenefits: string[];
	price: number | string;
	periodicity?: boolean;
	textButton: string;
	sx?: string;
	onPress?: () => void;
	id: string;
	beforePriceText?: string;
	afterPriceText?: string;
	showTag?: boolean;
	isOpacity?: boolean;
	loading?: boolean;
	beforePrice?: string | number;
	newPriceText?: string;
}

const DefaultCard: React.FC<ProductCardProps> = ({
	description,
	title,
	benefits,
	allBenefits,
	price,
	textButton,
	sx,
	onPress,
	id,
	beforePriceText,
	afterPriceText,
	showTag = false,
	isOpacity = false,
	loading,
	beforePrice,
	newPriceText,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const logClevertapEvent = useCleverTapEvent();

	const [showAllBenefits, setShowAllBenefits] = useState(false);

	const handleSeeMore = () => {
		setShowAllBenefits(!showAllBenefits);
	};

	const handleClick = () => {
		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_SELECT,
			product_name: title,
		});
		onPress?.();
	};

	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<>
			{id === 'heru-plus' ? (
				<div
					className={`w-full md:w-[450px] sm:w-[490px] md:min-h-[620px] bg-white rounded-lg border border-blue-100 flex flex-col ${sx}`}
				>
					<div
						className={`relative w-full ${
							isHotSale ? 'bg-h-hot-sale' : 'bg-h-primary'
						} rounded-tl-lg rounded-tr-lg flex flex-col items-center justify-center p-6 pb-4`}
					>
						{isHotSale && (
							<div className='mb-10'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}
						<Heading
							size='m'
							weight='medium'
							color='white'
							sx='text-center'
						>
							{title}
						</Heading>
						<div className='flex flex-row items-center justify-between w-full h-0 mt-4 mb-2'>
							<div className='w-full border border-white' />
							{!isHotSale && showTag && (
								<div className='flex flex-row items-center bg-h-green-40 rounded-[4px] ml-4 md:ml-2 w-52 sm:w-44 py-1 px-2 whitespace-nowrap'>
									<ShootingStar
										size={16}
										weight='fill'
										color='var(--h-gray-10)'
									/>
									<Text
										size='s'
										color='white'
										sx='ml-1 '
									>
										Más vendido
									</Text>
								</div>
							)}
						</div>
						<Text
							size='m'
							color='white'
							sx='text-center max-w-[320px] mt-2'
						>
							{description}
						</Text>
					</div>
					<div
						className={`relative flex flex-col items-center justify-start flex-grow p-4 sm:p-8 bg-white`}
					>
						{isHotSale && (
							<div className='mb-8'>
								<div className='absolute right-0 -top-14 flex items-center justify-center'>
									<img
										src={hot30Img}
										alt='hot30off'
										className='w-32 h-32'
									/>
								</div>
							</div>
						)}
						{showAllBenefits ? (
							<div className='flex flex-col'>
								{allBenefits.map((benefit, index) => (
									<div
										key={`benefit-${index}`}
										className='flex items-center gap-2.5 mb-4'
									>
										<CheckCircle
											size={24}
											weight='light'
											color='var(--h-primary)'
										/>
										<div className='w-full'>
											<Text
												size='s'
												color='dark'
											>
												{benefit}
											</Text>
										</div>
									</div>
								))}
							</div>
						) : (
							<div className='flex flex-col'>
								{benefits.map((benefit, index) => (
									<div
										key={`benefit-${index}`}
										className='flex items-center gap-2.5 mb-4'
									>
										<CheckCircle
											size={24}
											weight='light'
											color='var(--h-primary)'
										/>
										<div className='w-full'>
											<Text
												size='s'
												color='dark'
											>
												{benefit}
											</Text>
										</div>
									</div>
								))}
							</div>
						)}
					</div>

					<div className='w-full bg-white rounded-b-lg shadow border-t border-blue-100 flex flex-col justify-end items-center py-5 px-5 relative'>
						<div className='absolute -top-2 right-0 mt-3 mr-3'>
							<button onClick={handleSeeMore}>
								<div className='flex flex-row items-center'>
									<Text
										size='s'
										color='primary'
										sx='text-center mr-1'
									>
										{showAllBenefits ? 'Ver menos' : 'Ver más'}
									</Text>
									{showAllBenefits ? (
										<CaretUp
											color={'var(--h-primary)'}
											size={22}
											weight='light'
										/>
									) : (
										<CaretDown
											color={'var(--h-primary)'}
											size={22}
											weight='light'
										/>
									)}
								</div>
							</button>
						</div>
						{isHotSale ? (
							<div className='flex items-center justify-between mt-2 w-full'>
								<div className='flex items-center'>
									<Text
										color='gray'
										size={isMobile ? 's' : 'label'}
										sx='max-w-10 mr-5 md:mr-8 text-center'
									>
										Precio regular
									</Text>
									<Text
										color='gray'
										size={isMobile ? 'label' : 'body-1'}
										sx='line-through'
									>
										$499
									</Text>
								</div>
								<div className='flex items-center ml-2'>
									<Text
										color='darkMenu'
										weight='bold'
										size={isMobile ? 's' : 'label'}
										sx='max-w-10 mr-2 md:mr-4 text-center'
									>
										HOT HERU
									</Text>
									<Heading
										color='darkMenu'
										size={isMobile ? 'm' : 'l'}
									>
										$349
									</Heading>
									<Text
										color='darkMenu'
										size={isMobile ? 's' : 'label'}
										sx='mt-5'
									>
										mensual
									</Text>
								</div>
							</div>
						) : (
							<>
								{beforePrice ? (
									<PromoPrice
										beforePrice={beforePrice}
										price={price}
										newPriceText={newPriceText}
									/>
								) : (
									<div className='w-[219px] h-[60px] flex justify-start items-end gap-2 md:justify-center'>
										{beforePriceText && (
											<Text
												size='xl'
												weight='medium'
												color='light'
												sx='mb-2'
											>
												{beforePriceText}
											</Text>
										)}

										<div className='justify-center items-center flex'>
											<Heading
												size='xs'
												weight='medium'
											>
												{'$'}
											</Heading>
											<Heading
												size='xl'
												weight='medium'
											>
												{price}
											</Heading>
										</div>

										{afterPriceText && (
											<Text
												size='xl'
												weight='medium'
												sx='text-h-blue-60 mb-2'
											>
												{afterPriceText}
											</Text>
										)}
									</div>
								)}
							</>
						)}

						<Button
							label={textButton}
							onClick={handleClick}
							className={`${
								isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'
							} rounded-lg ${
								isHotSale ? 'text-h-primary' : 'text-white'
							} font-medium w-full flex items-center justify-center py-3 mt-3 md:mt-2`}
						/>
					</div>
				</div>
			) : (
				<div
					className={`w-full md:w-[450px] sm:w-[490px] md:min-h-[620px] ${
						id === 'annual'
							? isHotSale
								? 'bg-white'
								: 'bg-h-blue-20'
							: 'bg-white'
					} rounded-lg border border-blue-100 flex flex-col justify-between ${sx} ${
						isOpacity ? 'opacity-50' : ''
					}`}
				>
					<div
						className={`relative ${
							isHotSale
								? 'bg-h-hot-sale'
								: id === 'annual'
									? 'bg-h-primary'
									: 'bg-h-blue-60'
						} rounded-t-lg flex flex-col items-center justify-center p-6 pb-4`}
					>
						{isHotSale && (
							<div className='mb-10'>
								<div className='absolute left-0 -top-16 flex items-center justify-center'>
									<img
										src={hotSaleImg}
										alt='hot sale heru'
										className='w-56 h-56'
									/>
								</div>
							</div>
						)}

						<Heading
							size='m'
							weight='medium'
							color={
								isHotSale
									? 'white'
									: id === 'annual'
										? 'white'
										: 'ultraBlueLight'
							}
							sx={`text-center relative z-10`}
						>
							{title}
						</Heading>
						<div className='flex flex-row items-center justify-between w-full bg-red-300 h-0 mt-4 mb-2 relative z-10'>
							<div
								className={`w-full border ${
									isHotSale ? 'border-white' : 'border-blue-200'
								}  `}
							/>
							{showTag && (
								<div className='flex flex-row items-center bg-h-green-40 rounded-[4px] ml-4 md:ml-2 w-52 sm:w-44 py-1 px-2 relative z-10'>
									<ShootingStar
										size={16}
										weight='fill'
										color='var(--h-gray-10)'
									/>
									<Text
										size='s'
										color='white'
										sx='ml-1 '
									>
										Más vendido
									</Text>
								</div>
							)}
						</div>
						<Text
							size='m'
							color={
								isHotSale
									? 'white'
									: id === 'annual'
										? 'white'
										: 'ultraBlueLight'
							}
							sx='text-center max-w-[380px] relative z-10 mt-2'
						>
							{description}
						</Text>
					</div>

					{id === 'annual' ? (
						<div
							className={`relative flex flex-col items-center justify-start flex-grow p-4 sm:p-8 shadow`}
						>
							{isHotSale && (
								<div className='mb-8'>
									<div className='absolute right-0 -top-14 flex items-center justify-center'>
										<img
											src={hot50Img}
											alt='hot sale heru'
											className='w-32 h-32'
										/>
									</div>
								</div>
							)}
							{showAllBenefits ? (
								<div className='flex flex-col'>
									{allBenefits.map((benefit, index) => (
										<div
											key={`benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='fill'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							) : (
								<div className='flex flex-col'>
									{benefits.map((benefit, index) => (
										<div
											key={`benefit-${index}`}
											className='flex items-center gap-2.5 mb-4'
										>
											<CheckCircle
												size={24}
												weight='fill'
												color='var(--h-primary)'
											/>
											<div className='w-full'>
												<Text
													size='s'
													color='dark'
												>
													{benefit}
												</Text>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					) : (
						<div
							className={`relative flex flex-col px-6 py-8 
							 'md:-mt-14'
						`}
						>
							{isHotSale && (
								<div className='mb-8'>
									<div className='absolute right-0 -top-14 flex items-center justify-center'>
										<img
											src={
												id === 'basic-accounting'
													? hot30Img
													: id === 'annual-declarations-default'
														? hot50Img
														: hot40Img
											}
											alt='hot sale heru'
											className='w-32 h-32'
										/>
									</div>
								</div>
							)}
							{benefits.map((benefit, index) => (
								<div
									key={`benefit-${index}`}
									className='flex gap-2.5 mb-4'
								>
									<CheckCircle
										size={24}
										weight='light'
										color='var(--h-primary)'
									/>

									<div className='w-full'>
										<Text
											size='s'
											color='dark'
										>
											{benefit}
										</Text>
									</div>
								</div>
							))}
						</div>
					)}

					<div className='mb-1 px-6 pb-4 relative'>
						{id === 'annual' || id === 'annual-declarations-default' ? (
							<>
								{id === 'annual' && (
									<div className='absolute top-[2px] right-0 mr-3'>
										<button onClick={handleSeeMore}>
											<div className='flex flex-row items-center'>
												<Text
													size='s'
													color='primary'
													sx='text-center mr-1'
												>
													{showAllBenefits ? 'Ver menos' : 'Ver más'}
												</Text>
												{showAllBenefits ? (
													<CaretUp
														color={'var(--h-primary)'}
														size={22}
														weight='light'
													/>
												) : (
													<CaretDown
														color={'var(--h-primary)'}
														size={22}
														weight='light'
													/>
												)}
											</div>
										</button>
									</div>
								)}
								<div className='flex flex-col md:flex-row justify-center mt-5 md:mt-6'>
									{isHotSale ? (
										<div className='flex items-center justify-between mt-2 mb-4 w-full'>
											<div className='flex items-center'>
												<Text
													color='gray'
													size={isMobile ? 's' : 'label'}
													sx='max-w-10 mr-5 md:mr-8 text-center'
												>
													Precio regular
												</Text>
												<Text
													color='gray'
													size={isMobile ? 'label' : 'body-1'}
													sx='line-through'
												>
													${id === 'annual' ? '1200' : '800'}
												</Text>
											</div>
											<div className='flex items-center'>
												<Text
													color='darkMenu'
													weight='bold'
													size={isMobile ? 's' : 'label'}
													sx='max-w-10 mr-4 md:mr-8 text-center'
												>
													HOT HERU
												</Text>
												<Heading
													color='darkMenu'
													size={isMobile ? 'm' : 'l'}
												>
													${id === 'annual' ? '600' : '400'}
												</Heading>
											</div>
										</div>
									) : (
										<>
											{beforePrice ? (
												<PromoPrice
													beforePrice={beforePrice}
													price={price}
													newPriceText={newPriceText}
												/>
											) : (
												<div className='flex flex-row items-center justify-center'>
													<Heading
														size='xs'
														weight='bold'
														sx='mb-6'
													>
														{'$'}
													</Heading>
													<Heading
														size='xl'
														weight='bold'
														sx='mb-4'
													>
														{price}
													</Heading>
												</div>
											)}
										</>
									)}
								</div>
							</>
						) : (
							<>
								{isHotSale ? (
									<div className='flex items-center justify-between mt-2 w-full'>
										{id === 'basic-accounting' && (
											<>
												<div className='flex items-center justify-between mt-2 w-full'>
													<div className='flex items-center'>
														<Text
															color='gray'
															size={isMobile ? 's' : 'label'}
															sx='max-w-10 mr-5 md:mr-8 text-center'
														>
															Precio regular
														</Text>
														<Text
															color='gray'
															size={isMobile ? 'label' : 'body-1'}
															sx='line-through'
														>
															$199
														</Text>
													</div>
													<div className='flex items-center ml-2'>
														<Text
															color='darkMenu'
															weight='bold'
															size={isMobile ? 's' : 'label'}
															sx='max-w-10 mr-2 md:mr-4 text-center'
														>
															HOT HERU
														</Text>
														<Heading
															color='darkMenu'
															size={isMobile ? 'm' : 'l'}
														>
															$139
														</Heading>
														<Text
															color='darkMenu'
															size={isMobile ? 's' : 'label'}
															sx='mt-5'
														>
															mensual
														</Text>
													</div>
												</div>
											</>
										)}

										{id === 'regularizations-default' && (
											<>
												<div className='flex items-center'>
													<Text
														color='gray'
														size={isMobile ? 's' : 'label'}
														sx='max-w-10 mr-4 md:mr-8 text-center'
													>
														Desde
													</Text>
													<Text
														color='gray'
														size={isMobile ? 'label' : 'body-1'}
														sx='line-through'
													>
														$299
													</Text>
												</div>
												<div className='flex items-center'>
													<div className='flex flex-col items-center justify-center mr-3'>
														<Text
															color='darkMenu'
															weight='bold'
															size={isMobile ? 's' : 'label'}
															sx='text-center'
														>
															HOT HERU
														</Text>
														<Text
															color='darkMenu'
															weight='regular'
															size={isMobile ? 's' : 'label'}
															sx='text-center'
														>
															Desde
														</Text>
													</div>

													<Heading
														color='darkMenu'
														size={isMobile ? 'm' : 'l'}
													>
														$179
													</Heading>
												</div>
											</>
										)}
									</div>
								) : (
									<>
										{beforePrice ? (
											<PromoPrice
												beforePrice={beforePrice}
												price={price}
												newPriceText={newPriceText}
											/>
										) : (
											<div className='flex flex-row items-center justify-center'>
												{beforePriceText && (
													<Text
														size='xl'
														weight='medium'
														color='light'
														sx='mr-2'
													>
														{beforePriceText}
													</Text>
												)}
												<Heading
													size='xs'
													weight='medium'
													sx='mb-6'
												>
													{'$'}
												</Heading>
												<Heading
													size='xl'
													weight='medium'
													sx='mb-6'
												>
													{price}
												</Heading>
												{afterPriceText && (
													<Text
														size='xl'
														weight='medium'
														sx='text-h-blue-60 ml-2'
													>
														{afterPriceText}
													</Text>
												)}
											</div>
										)}
									</>
								)}
							</>
						)}

						<div className={`w-full ${id === 'annual' ? 'mt-0' : 'mt-5'}`}>
							{id === 'annual' ? (
								<Button
									beforeIcon={
										<ShoppingCart
											size={18}
											weight='fill'
											color={isHotSale ? 'var(--h-primary)' : 'white'}
											className='mr-2'
										/>
									}
									label='Contratar declaración anual'
									onClick={handleClick}
									loading={loading}
									className={`${
										isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'
									} rounded-lg ${
										isHotSale ? 'text-h-primary' : 'text-white'
									} font-medium w-full flex items-center justify-center py-3`}
								/>
							) : (
								<Button
									label={textButton}
									onClick={handleClick}
									className={`${
										isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'
									} rounded-lg ${
										isHotSale ? 'text-h-primary' : 'text-white'
									} font-medium w-full flex items-center justify-center py-3`}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DefaultCard;
