import { Controller, useForm } from 'react-hook-form';
import Text from '../../atoms/Text';
import { useState } from 'react';
import { FileText, Trash, Upload, XCircle } from '@phosphor-icons/react';
import { CircularProgress } from '@mui/material';
interface Props {
	name: string;
	control?: any;
	accept?: string;
	rules?: object;
	errorMessage?: string;
	label?: string;
	sx?: string;
}

const InputFileNew = ({
	name,
	control,
	accept,
	rules,
	errorMessage,
	label,
	sx,
}: Props) => {
	const [file, setFile] = useState<File>();
	const { control: defaultControl } = useForm();
	const [isUploading, setIsUploading] = useState(false);

	const handleDeleteFile = () => {
		setFile(undefined);
	};
	const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFile(event?.target?.files?.[0]);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsUploading(true); // Set uploading state to true when a file is selected
		setFile(event?.target?.files?.[0]);

		setTimeout(() => {
			setIsUploading(false);
		}, 2000);
	};

	return (
		<Controller
			control={control || defaultControl}
			rules={rules}
			name={name}
			render={({ field: { onChange, value } }) => (
				<>
					{file ? (
						<div>
							<div className='flex items-center border border-h-gray-20 rounded-lg cursor-pointer px-4 py-2'>
								<div className='w-[95%]'>
									<label
										htmlFor={name}
										className={`flex flex-col w-full ${
											errorMessage ? 'border-h-red-40' : 'border-solid'
										}`}
									>
										<div className='flex items-center justify-between'>
											<div className='flex items-center'>
												<FileText
													size={24}
													weight='fill'
													color='var(--h-neutral-60)'
												/>
												<div className='flex flex-col ml-2'>
													<Text
														size='mini-1'
														weight='regular'
														color={'medium'}
													>
														{file.name}
													</Text>
													<Text
														size='mini-1'
														weight='regular'
														color={'light'}
													>
														{file.size} KB
													</Text>
												</div>
											</div>

											<input
												id={name}
												className='hidden'
												value={value?.fileName}
												onChange={(event) => {
													onChange(event?.target?.files?.[0]);
													handleFile(event);
												}}
												type='file'
												accept={accept}
											/>
										</div>

										<div className='flex items-center justify-between'>
											{isUploading ? (
												<div className='h-[6px] bg-h-gray-20 w-[95%] rounded-lg'>
													<div className='h-[6px] bg-h-primary-50 w-[70%] rounded-lg mr-2' />
												</div>
											) : (
												<div className='h-[6px] bg-h-success-50 w-[95%] rounded-lg mr-2' />
											)}

											{isUploading ? (
												<CircularProgress
													size={20}
													className='ml-2'
												/>
											) : (
												<Text
													size='mini-1'
													weight='regular'
													color={'light'}
												>
													100%
												</Text>
											)}
										</div>
									</label>
								</div>
								<div className='w-[5%] flex justify-center items-center'>
									<Trash
										size={20}
										color='var(--h-neutral-30)'
										onClick={handleDeleteFile}
									/>
								</div>
							</div>

							<div className='flex flex-row items-center gap-x-1 mt-1'>
								{!!errorMessage && (
									<XCircle
										size={18}
										weight='fill'
										color='var(--h-red-50)'
									/>
								)}
								<Text
									size='xs'
									sx='text-h-red-40'
								>
									{errorMessage}
								</Text>
							</div>
						</div>
					) : (
						<div>
							<label
								htmlFor={name}
								className={`cursor-pointer border-h-gray-20 px-4 py-2 border rounded-lg flex flex-row items-center gap-2 justify-between border-solid`}
							>
								<div
									className={`flex flex-row items-center gap-x-2 truncate justify-between w-full ${sx}`}
								>
									<Text
										size='caption'
										weight='regular'
										color={'medium'}
										sx='truncate'
									>
										{label}
									</Text>

									<Upload
										size={20}
										weight='light'
										color=''
									/>

									<input
										id={name}
										className='hidden'
										value={value?.fileName}
										onChange={(event) => {
											onChange(event?.target?.files?.[0]);
											handleFileChange(event);
										}}
										type='file'
										accept={accept}
									/>
								</div>
							</label>
						</div>
					)}
				</>
			)}
		/>
	);
};

export default InputFileNew;
