import React from 'react';
import Text from '../../atoms/Text';

export type TCaseVariant =
	| 'success'
	| 'error'
	| 'pending'
	| 'inactive'
	| 'loading'
	| 'filter';
interface Props {
	label: string;
	onClick?: () => void;
	type: 'filled' | 'contrast' | 'text';
	caseVariant: TCaseVariant;
	iconLeft?: React.ReactNode;
	iconRight?: React.ReactNode;
	quantity?: number;
	sx?: string;
}

const Tag: React.FC<Props> = ({
	label,
	onClick,
	type,
	caseVariant,
	iconLeft,
	iconRight,
	quantity,
	sx,
}) => {
	const bgColor: Record<
		string,
		{
			success: string;
			error: string;
			pending: string;
			inactive: string;
			loading: string;
			filter: string;
		}
	> = {
		filled: {
			success: 'bg-h-success-90',
			error: 'bg-h-error-90',
			pending: 'bg-h-warning-90',
			inactive: 'bg-h-neutral-90',
			loading: 'bg-h-information-90',
			filter: 'bg-h-neutral-94 shadow',
		},
		contrast: {
			success: 'bg-h-success-50',
			error: 'bg-h-error-50',
			pending: 'bg-h-warning-50',
			inactive: 'bg-h-neutral-60',
			loading: 'bg-h-information-50',
			filter: 'bg-h-neutral-94',
		},
		text: {
			success: 'bg-transparent',
			error: 'bg-transparent',
			pending: 'bg-transparent',
			inactive: 'bg-transparent',
			loading: 'bg-transparent',
			filter: 'bg-transparent',
		},
	};

	const textColor: Record<
		string,
		{
			success: string;
			error: string;
			pending: string;
			inactive: string;
			loading: string;
			filter: string;
		}
	> = {
		filled: {
			success: 'text-h-success-50',
			error: 'text-h-error-50',
			pending: 'text-h-warning-30',
			inactive: 'text-h-neutral-20',
			loading: 'text-h-information-50',
			filter: 'text-h-neutral-20',
		},
		contrast: {
			success: 'text-white',
			error: 'text-white',
			pending: 'text-white',
			inactive: 'text-white',
			loading: 'text-white',
			filter: 'text-neutral-60',
		},
		text: {
			success: 'text-h-success-50',
			error: 'text-h-error-50',
			pending: 'text-h-warning-30',
			inactive: 'text-h-neutral-20',
			loading: 'text-h-information-50',
			filter: 'text-h-filter-50',
		},
	};

	const iconColor: Record<
		string,
		{
			success: string;
			error: string;
			pending: string;
			inactive: string;
			loading: string;
			filter: string;
		}
	> = {
		filled: {
			success: 'text-h-success-50',
			error: 'text-h-error-50',
			pending: 'text-h-warning-30',
			inactive: 'text-h-neutral-20',
			loading: '!text-h-information-50',
			filter: 'text-h-neutral-20',
		},
		contrast: {
			success: 'text-white',
			error: 'text-white',
			pending: 'text-white',
			inactive: 'text-white',
			loading: 'text-white',
			filter: 'text-neutral-60',
		},
		text: {
			success: 'text-h-success-50',
			error: 'text-h-error-50',
			pending: 'text-h-warning-50',
			inactive: 'text-h-neutral-20',
			loading: '!text-h-information-50',
			filter: 'text-h-filter-50',
		},
	};

	return (
		<div
			onClick={onClick}
			className={`flex items-center ${
				type !== 'text' && 'gap-1'
			} ${onClick && 'cursor-pointer'}`}
		>
			<Text
				size='mini-1'
				weight='regular'
				sx={`flex items-center px-3 py-1 rounded-full min-h-6
                !${textColor[type][caseVariant]}  
                ${bgColor[type][caseVariant]} ${sx}`}
			>
				<span
					className={`mr-[-3px] 
                ${iconColor[type][caseVariant]}`}
				>
					{iconLeft}
				</span>
				{label && (
					<span className={`leading-3 ${textColor[type][caseVariant]}`}>
						{label}
					</span>
				)}

				<span
					className={`ml-[-3px] 
                ${iconColor[type][caseVariant]}`}
				>
					{iconRight}
				</span>
			</Text>
			{quantity && (
				<Text
					size='mini-1'
					weight='regular'
					sx={`
                ${textColor[type][caseVariant]}`}
				>
					<span className={`${textColor[type][caseVariant]}`}>{quantity}</span>
				</Text>
			)}
		</div>
	);
};

export default Tag;
