import { DELETE_IDS, Order } from '../@types/Order.type';
import HERU_API from '../shared/constants/heruApi';
import { del, get, post, put } from '../shared/utils/FetchSSL.util';

const getOrders = async (
	is_renewal: string,
	payment_method?: string,
	tokenSession?: string,
): Promise<Order[]> => {
	try {
		const response = await get(
			`${
				HERU_API.HERU_API_CREATE_CART
			}?status=open&status=failed&is_renewal=${is_renewal}${
				payment_method ? `&payment_method=${payment_method}` : ''
			}`,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postOrders = async (body: object, tokenSession?: string) => {
	try {
		const response = await post(`${HERU_API.HERU_API_CREATE_CART}`, body, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const postAddProducts = async (
	order_id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			`${HERU_API.HERU_API_CREATE_CART}/${order_id}/items`,
			body,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postAddProductsBulk = async (
	order_id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post(
			`${HERU_API.HERU_API_CREATE_CART}/${order_id}/items/bulk`,
			body,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteItem = async (
	order_id: number,
	item_id: number,
	tokenSession?: string,
): Promise<boolean> => {
	try {
		await del(`${HERU_API.HERU_API_CREATE_CART}/${order_id}/items/${item_id}`, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return true;
	} catch (error) {
		throw error;
	}
};

const putOrder = async (
	order_id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await put(
			`${HERU_API.HERU_API_CREATE_CART}/${order_id}`,
			body,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteAllItemCart = async (
	items: DELETE_IDS[],
	cartId: number,
	tokenSession?: string,
) => {
	try {
		await del(
			`${HERU_API.HERU_API_CREATE_CART}/${cartId}/items/bulk`,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
			items,
		);
		return true;
	} catch (error) {
		throw error;
	}
};

export {
	getOrders,
	postOrders,
	postAddProducts,
	postAddProductsBulk,
	deleteItem,
	putOrder,
	deleteAllItemCart,
};
