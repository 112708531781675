import { TColumns } from '../../components/molecules/Table';

const defaultColumn: TColumns[] = [
	{
		title: 'Fecha presentación',
		dataIndex: 'presentation_date',
		hiddeInMobile: true,
	},
	{
		title: 'Régimen',
		dataIndex: 'regime',
	},
];

const actionColumn: TColumns = {
	title: '',
	dataIndex: 'actions',
};

const presentedData: TColumns[] = [
	...defaultColumn,
	{
		title: 'Resultado fiscal',
		dataIndex: 'fiscal_result',
		titleInMobile: 'Resultado',
		align: 'center',
	},
	{
		title: 'Tipo',
		dataIndex: 'declaration_type',
		titleInMobile: 'Tipo',
		align: 'center',
	},
	actionColumn,
];

const inProcessData: TColumns[] = [
	...defaultColumn,
	{
		title: 'Resultado fiscal',
		dataIndex: 'fiscal_result',
		titleInMobile: 'Resultado',
		align: 'center',
	},
];

const pendingData: TColumns[] = [
	...defaultColumn,
	{
		title: 'Días de atraso',
		dataIndex: 'delay_days',
		align: 'center',
		headColumnStyle: 'whitespace-nowrap',
	},
	actionColumn,
];

const pendingPaymentData: TColumns[] = [
	...defaultColumn,
	{
		title: 'Resultado fiscal',
		dataIndex: 'fiscal_result',
		titleInMobile: 'Resultado',
		align: 'center',
		hiddeInMobile: true,
		headColumnStyle: 'whitespace-nowrap',
	},
	{
		title: 'Fecha de pago',
		dataIndex: 'date_payment',
		headColumnStyle: 'whitespace-nowrap',
	},
	actionColumn,
];

export const TaxboardDeclarationsColumns = {
	PRESENTED: presentedData,
	IN_PROCESS: inProcessData,
	PENDING: pendingData,
	PENDING_PAYMENT: pendingPaymentData,
};

//======= FISCAL DOCUMENTS =======//
const invoice: TColumns[] = [
	{
		title: 'Fecha de generación',
		dataIndex: 'creation_date',
	},
	{
		title: 'Tipo de movimiento',
		dataIndex: 'invoice_type',
		hiddeInMobile: true,
	},
	{
		title: 'Concepto',
		dataIndex: 'description',
	},
	{
		title: 'Emitida / Recibida',
		dataIndex: 'emission_type',
	},
	{
		title: 'Emisor / Receptor',
		dataIndex: 'type',
	},

	{
		title: 'Estado',
		dataIndex: 'status',
		align: 'center',
	},
	{
		title: 'Total',
		dataIndex: 'total',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

const retention: TColumns[] = [
	{
		title: 'Fecha de generación',
		dataIndex: 'creation_date',
	},
	{
		title: 'Tipo de certificado',
		dataIndex: 'certificate_type',
	},
	{
		title: 'Emisor',
		dataIndex: 'issuer',
	},
	{
		title: 'RFC',
		dataIndex: 'rfc',
	},
	{
		title: 'Total',
		dataIndex: 'total',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

const receipt: TColumns[] = [
	{
		title: 'Periodo',
		dataIndex: 'period_date',
	},
	{
		title: 'Fecha de presentación',
		dataIndex: 'creation_date',
	},
	{
		title: 'Tipo de acuse',
		dataIndex: 'receipt_type',
	},
	{
		title: 'Resultado fiscal',
		dataIndex: 'fiscal_result',
		align: 'center',
	},
	{
		title: 'Estado declaración',
		dataIndex: 'payment_status',
	},
	{
		title: 'Fecha de pago',
		dataIndex: 'payment_date',
	},
	{
		title: 'Resultado declaración',
		dataIndex: 'download',
		align: 'center',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

export const FiscalDocumentsColumns = {
	invoice,
	receipt,
	retention,
};

// ============= ANUAL DECLARATIONS ============= //

const presentedColumns: TColumns[] = [
	{
		title: 'Fecha presentación',
		dataIndex: 'presentation_date',
	},
	{
		title: 'Fecha de pago',
		dataIndex: 'payment_date',
		hiddeInMobile: true,
	},
	{
		title: 'Resultado fiscal',
		titleInMobile: 'Resultado',
		dataIndex: 'fiscal_result',
		align: 'center',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

const pendingColumns: TColumns[] = [
	{
		title: 'Fecha presentación',
		dataIndex: 'presentation_date',
	},
	{
		title: 'Días de atraso',
		titleInMobile: 'Atraso',
		dataIndex: 'delay_days',
		align: 'center',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

const pendingColumnsMonthly: TColumns[] = [
	{
		title: 'Periodo',
		dataIndex: 'period',
	},
	{
		title: 'Régimen',
		dataIndex: 'regime',
	},
	{
		title: 'Días de atraso',
		dataIndex: 'delay_days',
	},
	{
		title: '',
		dataIndex: 'actions',
	},
];

export const AnnualDeclarationsColumns = {
	PENDING: pendingColumns,
	PRESENTED: presentedColumns,
	PENDING_MONTHLY: pendingColumnsMonthly,
};
