import HERU_API from '../shared/constants/heruApi';
import { post } from '../shared/utils/FetchSSL.util';

const getOnboardingWelcome = async (message: string, tokenSession?: string) => {
	try {
		const body = {
			message,
		};
		const response = await post(HERU_API.ONBOARDING_WELCOME, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

type OnboardingRecommendationBody = {
	recommendation: string;
	userContext: string;
};

const getOnboardingRecommendation = async (
	body: OnboardingRecommendationBody,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.ONBOARDING_RECOMMENDATION, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export { getOnboardingWelcome, getOnboardingRecommendation };
