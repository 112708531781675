import HERU_API from '../shared/constants/heruApi';
import { del, get, post } from '../shared/utils/FetchSSL.util';
const fetchTaxPayer = async (userId: number, tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_FINANCIAL_TAX_PAYERS(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

const fetchFiscalObligations = async (
	userId: number,
	tokenSession?: string,
) => {
	try {
		const response = await get(HERU_API.HERU_API_FISCAL_OBLIGATIONS(userId), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeductions = async (rfc: string, tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_VISOR_DEDUCTIONS(rfc), {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {}
};

const uploadEFirma = async (
	body: {
		user_id: number;
		cert: string;
		key: string;
		password: string;
	},
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.HERU_API_FISCAL_UPLOAD_EFIRMA, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {}
};

const postESignatures = async (
	id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post(HERU_API.HERU_API_FISCAL_SIGNATURES(id), body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchUserFiscalDocuments = async (
	param: 'private_key' | 'certificate' | 'password',
	documentType: 'e-signatures' | 'certificates',
	tokenSession?: string,
) => {
	try {
		const response = await get(
			HERU_API.HERU_FISCAL_DOWNLOAD_DOCUMENTS(param, documentType),
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const deleteFiscalDocuments = async (
	documentType: 'e-signatures' | 'certificates',
	tokenSession?: string,
) => {
	try {
		const response = await del(
			HERU_API.HERU_API_FISCAL_DELETE_DOCUMENTS(documentType),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
			{},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const removeLinks = async (id: number, type: string, tokenSession?: string) => {
	try {
		const response = await del(
			HERU_API.REMOVE_LINKS(id, type),
			{ token: true, isNewGateway: true, tokenSession },
			{},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchFiscalCredentials = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_FISCAL_CREDENTIALS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeclarations = async (
	userId: number,
	year?: number | string,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			year
				? `${HERU_API.HERU_API_FISCAL_DECLARATIONS(userId)}&year=${year}`
				: `${HERU_API.HERU_API_FISCAL_DECLARATIONS(userId)}`,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeclarationDetail = async (
	declarationId: number,
	regime: string,
	tokenSession?: string,
) => {
	try {
		const response = await get(
			`${HERU_API.HERU_API_FISCAL_DECLARATION_DETAIL(declarationId, regime)}`,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postFiscalDocuments = async (body: object, tokenSession?: string) => {
	try {
		const resp = await post(HERU_API.HERU_API_FISCAL_DOCUMENTS, body, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});
		return resp;
	} catch (error) {
		throw error;
	}
};

//? TAXES V1
const fetchMonthlyDeclarationOverview = async (
	userId: number,
	year: number,
) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_MONTHLY_DECLARATION_OVERVIEW}/${userId}/${year}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchYearlyDeclarationsOverview = async (userId: number) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_YEARLY_DECLARATIONS_OVERVIEW}/${userId}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchFiscalOverview = async (
	user_id: number,
	year: number,
	month?: number,
) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_ANNUAL_FISCAL_OVERVIEW}/${user_id}/${year}${month ? `/${month}` : ''}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeclarationsByMonth = async (
	month: number,
	year: number,
	user_id: number,
) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_MONTHLY_DECLARATION_OVERVIEW}/${user_id}/${year}/${month}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeclarationsByYear = async (year: number, user_id: number) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_YEARLY_DECLARATIONS_OVERVIEW}/${user_id}/${year}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const fetchDeclarationDetailV1 = async (declaration_id: number) => {
	try {
		const response = await get(
			`${HERU_API.DASHBOARD_DECLARATION_DETAIL}/${declaration_id}`,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postExtractionsOnDemand = async (
	userId: number,
	type: string,
	year: number,
	month: number,
) => {
	try {
		const body = {
			userId,
			type,
			year,
			month,
		};
		const response = await post(
			HERU_API.DASHBOARD_EXTRACTIONS_ON_DEMAND,
			body,
			{
				token: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export {
	fetchTaxPayer,
	fetchFiscalObligations,
	uploadEFirma,
	fetchFiscalCredentials,
	fetchDeclarations,
	fetchDeductions,
	postFiscalDocuments,
	fetchDeclarationDetail,
	postESignatures,
	deleteFiscalDocuments,
	fetchUserFiscalDocuments,
	fetchMonthlyDeclarationOverview,
	fetchYearlyDeclarationsOverview,
	fetchDeclarationDetailV1,
	fetchFiscalOverview,
	fetchDeclarationsByMonth,
	fetchDeclarationsByYear,
	postExtractionsOnDemand,
	removeLinks,
};
