import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import LITERALS from '../../../shared/constants/literals';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { showAlert, AlertPayload } from '../../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../Alert';
import { msgToolTipPassword } from '../../../shared/constants/SatLinkMessages';
import { SATEFirmaForm } from '../SATLinkingForm';
import { convertFiletoBase64 } from '../../../shared/utils/general.util';
import InputFileNew from '../../atoms/InputFileNew';
import { uploadEFirma } from '../../../services/taxes.service';
import { CaretCircleLeft } from '@phosphor-icons/react';
import { LINKS } from '../../../shared/constants/links';
import { TaxProfile } from '../../../@types/TaxProfile';
import { linkSATAccountEFirmaHeru } from '../../../services/satLinking.service';

interface Props {
	onCallbackSuccess?: (value: any) => void;
	onCallbackError?: (error: any) => void;
	viewTitle?: boolean;
	isModal?: boolean;
	taxProfile?: TaxProfile | null;
}

const schemaEFirma = yup
	.object({
		key: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		cert: yup.mixed().required(LITERALS.REQUIRED_FIELD),
		password: yup.string().required(LITERALS.REQUIRED_FIELD),
		// .matches(REGEX.nameRegex, LITERALS.MIN_LENGTH_PASSWORD)
		// .min(8, LITERALS.MIN_LENGTH_PASSWORD),
	})
	.required(LITERALS.REQUIRED_FIELD);

const UploadESignature = ({
	onCallbackSuccess,
	onCallbackError,
	viewTitle = true,
	isModal = false,
	taxProfile,
}: Props) => {
	const {
		control: controlEFirma,
		handleSubmit: handleSubmitEFirma,
		formState: { errors: errorsEFirma },
	} = useForm<SATEFirmaForm>({
		resolver: yupResolver(schemaEFirma),
		defaultValues: {
			cert: undefined,
			key: undefined,
			password: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const fiscalDocuments = useSelector(
		(state: RootState) => state.taxProfile.links,
	);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();

	const showAlertTop = ({ title, type, description }: AlertPayload) => {
		const alert = {
			type,
			title,
			description,
		};
		dispatch(showAlert(alert));
	};

	const [step, setStep] = useState(0);

	const onSubmitEFirma: SubmitHandler<SATEFirmaForm> = async (data) => {
		if (!userProfile?.id || isLoading) return;
		if (!data.cert || !data.key || !data.password) {
			showAlertTop({
				title: 'Error',
				type: ALERT_TYPE.ERROR,
				description: LITERALS.REQUIRED_FIELDS,
			});
			return;
		}
		let cert = await convertFiletoBase64(data.cert)
			.then((res) => res as string)
			.catch((err) => err);
		let key = await convertFiletoBase64(data.key)
			.then((res) => res as string)
			.catch((err) => err);
		try {
			setIsLoading(true);
			cert.includes('data:') && (cert = cert.split(',')[1]);
			key.includes('data:') && (key = key.split(',')[1]);
			if (fiscalDocuments?.links?.ciec?.[0]?.password) {
				const body = {
					user_id: userProfile?.id,
					cert: cert,
					key: key,
					password: data.password,
				};
				const resp = await uploadEFirma(body, tokenSession);
				if (!resp.detail.isValid) {
					showAlertTop({
						title: 'Error',
						type: ALERT_TYPE.ERROR,
						description: resp.detail.msg,
					});
					onCallbackError?.(resp);
					return;
				} else {
					onCallbackSuccess?.(resp);
					showAlertTop({
						title: 'Éxito',
						type: ALERT_TYPE.SUCCESS,
						description: resp.detail.linkResult.msg,
					});
				}
			} else {
				const body = {
					userId: userProfile?.id,
					cert: cert,
					key: key,
					password: data.password,
					source: LITERALS.PLATFORM,
				};
				const resp = await linkSATAccountEFirmaHeru(body, tokenSession);
				if (
					resp.detail.linkResult?.internalCode === 5000 ||
					!resp.detail.isValid
				) {
					showAlertTop({
						title: 'Error',
						type: ALERT_TYPE.ERROR,
						description: resp.detail.msg ?? resp.detail.linkResult.msg,
					});
					onCallbackError?.(resp);
					return;
				} else if (resp.detail.state) {
					onCallbackSuccess?.(resp);
					showAlertTop({
						title: 'Éxito',
						type: ALERT_TYPE.SUCCESS,
						description: resp.detail.msg,
					});
				}
			}
		} catch (error) {
			// @ts-ignore
			if (error?.response?.data?.detail.msg) {
				// @ts-ignore
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					// @ts-ignore
					description: error.response.data.detail.msg,
				});
			} else {
				showAlertTop({
					title: 'Error',
					type: ALERT_TYPE.ERROR,
					description: LITERALS.UPLOAD_E_SIGNATURE_ERROR,
				});
			}

			onCallbackError?.(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			{step === 0 && (
				<div className='p-2'>
					{viewTitle && (
						<Text
							weight='bold'
							color='medium'
							size='body-1'
							sx='mb-5'
						>
							Vincula tu e.Firma
						</Text>
					)}
					<form onSubmit={handleSubmitEFirma(onSubmitEFirma)}>
						<div className='flex flex-col gap-4'>
							<Text size='m'>Certificado</Text>
							<InputFileNew
								control={controlEFirma}
								name='cert'
								accept='.cer'
								errorMessage={errorsEFirma.cert?.message as string}
								label='Sube tu certificado (.cer)'
							/>
							<Text size='m'>Clave privada</Text>
							<InputFileNew
								control={controlEFirma}
								name='key'
								accept='.key'
								errorMessage={errorsEFirma.key?.message as string}
								label='Sube tu clave privada (.key)'
							/>
							<Input
								label='Contraseña clave privada'
								showTooltip
								tooltipMessage={msgToolTipPassword}
								control={controlEFirma}
								name='password'
								type='password'
								placeholder='Contraseña clave privada'
								errorMessage={errorsEFirma.password?.message}
								maxLength={50}
								rules={{
									required: true,
								}}
							/>

							<div className='flex flex-col-reverse justify-end lg:flex-row items-center w-full'>
								<Button
									variant='outline'
									sx='w-full lg:w-60 mt-2 lg:mt-0 border-0 !shadow-none hover:!bg-transparent'
									onClick={() => setStep(1)}
									label='No tengo mi e.Firma'
								/>

								<Button
									sx='w-full lg:w-60'
									loading={isLoading}
									disabled={
										!!errorsEFirma.key ||
										!!errorsEFirma.cert ||
										!!errorsEFirma.password ||
										isLoading
									}
									label={
										taxProfile?.statusCode === 404
											? 'Vincular mi e.Firma'
											: 'Cargar mi e.Firma'
									}
									type='submit'
								/>
							</div>
						</div>
					</form>
				</div>
			)}
			{step === 1 && (
				<div>
					{isModal && (
						<div className='absolute left-2 top-2'>
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={24}
								height={24}
								className='cursor-pointer'
								onClick={() => setStep(0)}
							/>
						</div>
					)}

					<div className='flex flex-col w-full h-[600px] md:h-[780px] mt-4'>
						{!isModal && (
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={24}
								height={24}
								className='cursor-pointer mb-2'
								onClick={() => setStep(0)}
							/>
						)}
						<Text
							size='s'
							sx='text-center bg-h-gray-10 flex justify-center py-2 z-10'
							color='medium'
						>
							Aviso: SATID es una página ajena a Heru.
						</Text>
						<iframe
							title='Embedded Page'
							width='100%'
							height='100%'
							src={LINKS.SAT_ID_LINK}
							allowFullScreen
						></iframe>
					</div>
				</div>
			)}
		</div>
	);
};

export default UploadESignature;
