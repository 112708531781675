import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import SATLinkingForm from '../../molecules/SATLinkingForm';
import Tabs from '../../molecules/Tabs';
import Text from '../../atoms/Text';
import FiscalProfileCard from '../FiscalProfileCard';
import DeclarationStatus from '../DeclarationStatus';
import InvoicesSumary from '../InvoicesSumary';
import { linkedAccount, startLoader } from '../../../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../../../services/satLinking.service';
import ROUTES from '../../../shared/constants/routes';
import { RootState } from '../../../redux/store';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import bannerSubscriptions from '../../../assets/img/banners/Suscribete_Small_Desktop.png';
import banner3 from '../../../assets/img/banners/Conoce_planes_Small_Desktop.png';
import { ServiceStatusElement } from '../../../@types/ServiceStatus.type';
import ServicesStatuHome from '../ServicesStatuHome';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import { isSessionStorageEnabled } from '../../../shared/utils/general.util';
import { setOverwriteUtm, updateUTMs } from '../../../redux/slices/cart.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import BlockedMock from '../ServicesStatuHome/BlockedMock';

import LastPendingOrder from '../../../pages/pendingReferences/components/LastPendingOrder';
import { VARIANT_STATUS } from '../../../shared/constants/serviceStatus';

type TFlow = 'taxes' | 'home';

type Props = {
	flow?: TFlow;
	sxLinkingSAT?: string;
	serviceStatus: ServiceStatusElement[];
	isLoadingServices: boolean;
	sx?: string;
};

const items = [
	{
		label: 'Vincular con e.firma',
		slug: 'efirma',
	},
	{
		label: 'Vincular con RFC y contraseña',
		slug: 'password',
	},
];

export const settingsCarrousel = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	nextArrow: <></>,
	prevArrow: <></>,
	appendDots: (dots: any) => (
		<div style={{ position: 'absolute', bottom: 3 }}>
			<ul style={{ margin: '0px' }}> {dots} </ul>
		</div>
	),
};

const SATLinking: React.FC<Props> = ({
	flow = 'home',
	sxLinkingSAT,
	serviceStatus,
	isLoadingServices,
	sx,
}) => {
	const [activeTab, setActiveTab] = useState(items[0].slug);
	const [hideBlinkEffect, setHideBlinkEffect] = useState(false);
	const [showLinkInfo, setShowLinkInfo] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const logClevertapEvent = useCleverTapEvent();

	const activeServices = useMemo(() => {
		return serviceStatus?.filter((it) => it?.status !== 'closed');
	}, [serviceStatus]);

	useEffect(() => {
		if (location.hash === '#fiscalform' && hideBlinkEffect) {
			setHideBlinkEffect(false);
		}
	}, [location, hideBlinkEffect]);

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			await dispatch(linkedAccount(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const successSATLinkHandler = async () => {
		fetchSatLinkedAcount();
	};

	const logBannerHomeEvent = (banner: 'planes y precios' | 'suscripcion') => {
		logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
			event_type: EVENT_TYPES.HOME_PURCHASE_BANNER_INTERACTION,
			id: userProfile?.id!,
			phone: userProfile?.cellphone!,
			banner,
		});
	};

	const goToPlans = () => {
		logBannerHomeEvent('planes y precios');
		// const { utm_campaign, utm_medium, utm_source } =
		// 	PLANS_UTM_VALUES.HOME_BANNER;
		if (isSessionStorageEnabled()) {
			const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
			// const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
			// const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
			// const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

			let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

			if (overwriteUtm === null) {
				sessionStorage.setItem(overwriteUtmKey, 'true');
				overwriteUtm = 'true';
			}

			// if (overwriteUtm === 'true') {
			// 	sessionStorage.setItem(campaignKey, utm_campaign);
			// 	sessionStorage.setItem(mediumKey, utm_medium);
			// 	sessionStorage.setItem(sourceKey, utm_source);
			// } else {
			// 	if (!sessionStorage.getItem(campaignKey)) {
			// 		sessionStorage.setItem(campaignKey, utm_campaign);
			// 	}
			// 	if (!sessionStorage.getItem(mediumKey)) {
			// 		sessionStorage.setItem(mediumKey, utm_medium);
			// 	}
			// 	if (!sessionStorage.getItem(sourceKey)) {
			// 		sessionStorage.setItem(sourceKey, utm_source);
			// 	}
			// }
		} else {
			const currentOverwriteUtm = useSelector(
				(state: RootState) => state.cart.overwriteUtm,
			);

			if (currentOverwriteUtm === undefined || currentOverwriteUtm === null) {
				dispatch(setOverwriteUtm(true));
			}

			if (currentOverwriteUtm === true) {
				// dispatch(updateUTMs(PLANS_UTM_VALUES.HOME_BANNER));
			} else {
				const currentUTMs = useSelector(
					(state: RootState) => state.cart.utmInfo,
				);

				// const updatedUTMs = {
				// 	...currentUTMs,
				// 	utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
				// 	utm_medium: currentUTMs?.utm_medium || utm_medium,
				// 	utm_source: currentUTMs?.utm_source || utm_source,
				// };
				if (!currentUTMs) return;
				const updatedUTMs = {
					...currentUTMs,
					utm_campaign: currentUTMs?.utm_campaign,
					utm_medium: currentUTMs?.utm_medium,
					utm_source: currentUTMs?.utm_source,
				};
				dispatch(updateUTMs(updatedUTMs));
			}
		}
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	const serviceStatusSubscription = serviceStatus.find(
		(service) =>
			service.current_service_has_step.status === VARIANT_STATUS.BLOCKED &&
			service.current_service_has_step.stage.slug ===
				'stage_subscription_blocked_for_payment_declaration',
	);

	return (
		<section className='my-3 md:my-5'>
			<div
				id='fiscalform'
				className='grid grid-cols-12 gap-4 lg:gap-6 place-content-center '
			>
				<div className={`col-span-12 md:col-span-6 xl:col-span-6 ${sx}`}>
					<div className='block'>
						<Slider {...settingsCarrousel}>
							{/* <img
								src={banner4}
								onClick={() => setShowLinkInfo(true)}
								alt='Banner de calculo de deducibles'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/> */}

							<img
								onClick={goToPlans}
								src={banner3}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>

							<img
								onClick={goToPlans}
								src={bannerSubscriptions}
								alt='Banner de compra suscripcion'
								className='relative h-fit-content w-full object-cover rounded-lg'
							/>
						</Slider>
					</div>

					<div
						onClick={() => setShowLinkInfo(false)}
						className={`mt-6 rounded-lg border-4  border-h-primary shadow-md ${
							hideBlinkEffect ? 'animate-none' : 'animate-blink'
						} ${sxLinkingSAT}`}
					>
						{showLinkInfo ? (
							<Text
								size='s'
								sx='mb-8 lg:text-xl bg-h-primary text-white p-4'
								weight='medium'
							>
								Vincula aquí tu RFC para calcular tus deducibles e iniciar con
								tu declaración anual
							</Text>
						) : (
							<Text
								size='m'
								sx='justify-center mt-6 mb-8 mx-4 lg:text-xl'
								weight='medium'
							>
								Vincula tu RFC y simplifica tus impuestos con Heru
							</Text>
						)}

						<Tabs
							items={items}
							onClickTab={(val) => setActiveTab(val)}
						/>
						<SATLinkingForm
							onCallbackSuccess={successSATLinkHandler}
							onCallbackError={() => {
								setHideBlinkEffect(true);
								navigate(location.pathname, {});
							}}
							showEFirmaForm={activeTab !== 'password'}
							sx='p-6'
							buttonLabel='Vincular mi RFC'
						/>
					</div>
				</div>
				{flow === 'home' && (
					<div className='col-span-12 md:col-span-6 xl:col-span-6  '>
						<div className='flex flex-col gap-y-4'>
							<LastPendingOrder />

							{serviceStatusSubscription ? (
								<BlockedMock failedStripeSub={serviceStatusSubscription} />
							) : (
								<>
									{activeServices.length > 0 && (
										<ServicesStatuHome
											serviceStatus={serviceStatus}
											isLoadingServices={isLoadingServices}
										/>
									)}
								</>
							)}

							<FiscalProfileCard
								isDisabled
								sx='pr-0'
							/>
							<DeclarationStatus />
							<InvoicesSumary
								title='Facturas del próximo mes'
								showButton
							/>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default SATLinking;
