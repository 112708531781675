import BenefitsDropdown from '../molecules/BenefitsDropdown';
import PlanFlowButtons from '../molecules/PlanFlowButtons';
import { PropsPlanFlow } from '..';
import {
	PlanPaymentFrecuencySelector,
	PlanRegimeLeaseSelector,
	PlanRegimeSelector,
} from '../organisms/PlanSelectors';
import PrimaryButton from '../../../components/atoms/Button';
import { ArrowLeft } from '@phosphor-icons/react';
import {
	PlanFlowContentWrapper,
	TopBanner,
} from '../organisms/LayoutCustomPlan';
import { formatCurrency } from '../../../shared/utils/general.util';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import useAddToCart from '../../../hooks/useAddToCart';
import { REGIMES_CODE } from '../../../@types/Regime.type';
import { Regime, RegimeFFT } from '../../../@types/Products.type';
import { setPlanConfig } from '../../../redux/slices/plans.slice';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { Item } from '../../../@types/Ecommerce';

const HeruProFlow: React.FC<PropsPlanFlow> = ({
	planConfig,
	product,
	startingPrice,
}) => {
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();
	const { dataLayer } = window as any;
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);

	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { step } = planConfig;

	const { handleAddCustomProduct, isLoading: isLoadingAddToCart } =
		useAddToCart();

	const { productName, subtitle } = product;

	const { products } = useSelector((state: RootState) => state.productList);

	const productsByRegimeCode: Record<string, number> = {
		[REGIMES_CODE.ACTIVIDAD_EMPRESARIAL]: 124,
		[REGIMES_CODE.RESICO]: 78,
		[REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS]: 16,
		[REGIMES_CODE.ARRENDAMIENTO]:
			planConfig?.regimeConfig?.lease === 'quarterly' ? 86 : 82,
		[REGIMES_CODE.RIF]: 89,
	};

	const isOnlyRIF =
		planConfig?.regimes?.length === 1 &&
		planConfig?.regimes[0] === REGIMES_CODE.RIF;

	const isLease =
		planConfig?.regimes?.includes('606') && planConfig?.regimes?.length === 1;

	const isLeaseQuarterly = planConfig?.regimeConfig?.lease === 'quarterly';

	const paymentFrecuency = planConfig?.paymentFrecuency
		? planConfig.paymentFrecuency
		: isOnlyRIF
			? 60
			: isLeaseQuarterly && isLease
				? 90
				: 30;

	const getPriceWithElasticity = (price: number) =>
		APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
			? getElasticyPrice(price, elasticityPrice.group_experiment)
			: price;

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const currentProduct = products
		?.flatMap((item) => item?.products)
		?.find(
			(productInfoItem) =>
				productInfoItem?.default_product_version_id ===
				(planConfig?.regimes?.length && planConfig?.regimes?.length >= 2
					? 92
					: productsByRegimeCode[planConfig?.regimes?.[0]!]),
		);

	const currentProductVersion = currentProduct?.product_versions?.find(
		(version) => version.periodicity === paymentFrecuency,
	);

	const currentProductVersionPrice =
		currentProductVersion &&
		getPriceWithElasticity(currentProductVersion?.price / 100);

	const getProductTotalPrice = () => {
		const amountsDivider: Record<number, number> = {
			30: 1,
			60: 2,
			90: 3,
			180: 6,
			365: 12,
		};

		if (currentProductVersionPrice) {
			return Math.round(
				currentProductVersionPrice /
					amountsDivider[planConfig.paymentFrecuency ?? 30],
			);
		}
		if (planConfig?.paymentFrecuency && step > 1) {
			return Math.round(
				startingPrice / amountsDivider[planConfig.paymentFrecuency ?? 30],
			);
		}
		return Math.round(startingPrice);
	};

	const handleNextStep = () => {
		if (step === 3 || (step === 2 && !isLease)) {
			handleAddProduct();
			return;
		}
		dispatch(
			setPlanConfig({
				...planConfig,
				step: planConfig.step + 1,
			}),
		);
	};
	const handlePreviousStep = () => {
		dispatch(
			setPlanConfig({
				...planConfig,
				paymentFrecuency: undefined,
				step: planConfig.step - 1,
			}),
		);
	};

	const disableButton: Record<number, boolean> = {
		1: !planConfig.regimes?.length,
		2: isLease
			? !planConfig.regimeConfig?.lease
			: !planConfig.regimes?.length || !planConfig.paymentFrecuency,
		3: !planConfig.paymentFrecuency,
	};

	const buttonText: Record<number, string> = {
		1: isLease ? 'Continuar 1 de 3' : 'Continuar 1 de 2',
		2: isLease ? 'Continuar 2 de 3' : product.buttonText,
		3: product.buttonText,
	};

	const confirmedRegime = step > 1;
	const confirmedRegimeConfig = step > 2;

	const priceDescription =
		planConfig?.paymentFrecuency && currentProductVersionPrice
			? `$${formatCurrency(currentProductVersionPrice, false)} Total`
			: '';

	const propsTopBanner = {
		productName,
		subtitle,
		price: getProductTotalPrice(),
		afterPrice: '/mes',
		beforePrice:
			currentProductVersionPrice && planConfig?.paymentFrecuency ? '' : 'Desde',
		priceDescription,
	};

	const handleAddProduct = async () => {
		if (
			!currentProductVersionPrice ||
			!planConfig?.regimes?.length ||
			!userProfile
		)
			return;

		const isLeaseAndPlatec =
			planConfig?.regimes?.length === 2 &&
			planConfig.regimes.includes(REGIMES_CODE.ARRENDAMIENTO) &&
			planConfig.regimes.includes(REGIMES_CODE.PLATAFORMAS_TECNOLOGICAS);

		const regimes =
			planConfig?.regimes?.length > 1
				? isLeaseAndPlatec
					? [Regime.Arrendamiento, Regime.PlataformasTecnológicas]
					: planConfig?.regimes?.map((regime) => RegimeFFT[regime])
				: [RegimeFFT?.[planConfig?.regimes?.[0]]];

		const payload = [
			{
				product_version_id: currentProductVersion?.id,
				custom_product: {
					group_experiment: elasticityPrice.group_experiment,
					total_paid: Number(currentProductVersionPrice * 100),
					payload_user_id: userProfile?.id,
				},
				additional_information: {
					regimes,
				},
			},
		];

		const eventProperties = {
			event_type: EVENT_TYPES.PLANS_ADD_TO_CART,
			product_name: currentProduct?.name || 'heru_pro',
			regime: currentProduct?.regime?.join('') || '',
		};
		logClevertapEvent(ANALYTICS_EVENTS.ADD_TO_CART, eventProperties);

		const response = await handleAddCustomProduct(payload);
		dataLayer.push({ ecommerce: null });
		dataLayer.push({
			event: ANALYTICS_EVENTS.ADD_TO_CART,
			phone: userProfile?.cellphone,
			rfc: taxPayer?.code ?? '',
			ecommerce: {
				items: response?.map((item: Item) => ({
					price: item?.base_price / 100,
					item_id: item?.product_version?.product_id,
					item_name: item?.product_version?.product?.name,
					category: item?.product_version?.product?.regime?.toString(),
					item_category1: item?.product_version?.product?.category,
					item_category2: item?.purchase_type,
				})),
			},
		});
	};

	return (
		<>
			<TopBanner {...propsTopBanner} />
			<div className='flex flex-col justify-between h-full'>
				<PlanFlowContentWrapper>
					<div className='flex flex-col gap-3'>
						{step > 1 && (
							<PrimaryButton
								label='Volver'
								variant='text'
								labelColor='primary'
								onClick={handlePreviousStep}
								labelSize='caption'
								beforeIcon={<ArrowLeft weight='bold' />}
								sx='flex items-center gap-2 mb-4 px-2'
							/>
						)}
						<PlanRegimeSelector
							planConfig={planConfig}
							confirmed={confirmedRegime}
						/>
						{isLease && confirmedRegime && (
							<PlanRegimeLeaseSelector
								planConfig={planConfig}
								confirmed={confirmedRegimeConfig}
							/>
						)}
						{((isLease && step > 2) || (!isLease && step > 1)) && (
							<>
								<hr />
								<PlanPaymentFrecuencySelector planConfig={planConfig} />
							</>
						)}
						<BenefitsDropdown benefits={currentProduct?.benefits} />
					</div>
				</PlanFlowContentWrapper>
				<PlanFlowButtons
					disableButton={disableButton[step]}
					buttonLabel={buttonText[step]}
					onClickButton={handleNextStep}
					isLoading={isLoadingAddToCart.products}
				/>
			</div>
		</>
	);
};

export default HeruProFlow;
