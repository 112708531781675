import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { sendOTP } from '../../services/auth.service';
import Verify from './Verify';
import { ArrowRight, LockSimpleOpen } from '@phosphor-icons/react';
import Register from './Register';
import Text from '../../components/atoms/Text';
import { REGEX } from '../../shared/constants/regex';
import { useSearchParams } from 'react-router-dom';
import Input from '../../components/atoms/Input';
import Button from '../../components/atoms/Button';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import ROUTES from '../../shared/constants/routes';
import Heading from '../../components/atoms/Heading';
import CustomIcon from '../../components/atoms/CustomIcon';
import { hideAlert, showAlert } from '../../redux/slices/alert.slice';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Alert, { ALERT_TYPE } from '../../components/molecules/Alert';
import COUNTRY_CODES from '../../shared/constants/countryCodes';
import Selector from '../../components/atoms/Selector';
import { isLocalStorageEnabled } from '../../shared/utils/general.util';
import { removeToken } from '../../redux/slices/auth.slice';

type FormBody = {
	countryCode: string;
	phone: string;
};

const defaultImage =
	'https://heru-static-assets.s3.us-east-2.amazonaws.com/man-background/man-background.webp';

const bgImage =
	'https://heru-static-assets.s3.us-east-2.amazonaws.com/heru-signup.webp';
const showDefaultImage = false;

const Login: React.FC = () => {
	const [step, setStep] = useState(1);
	const [inputErrors, setInputErrors] = useState({
		phone: '',
	});
	const [isLoading, setIsLoading] = useState({
		sendOTP: false,
		verifyOTP: false,
	});

	const { control, handleSubmit, getValues } = useForm<FormBody>({
		defaultValues: { countryCode: COUNTRY_CODES[0].value, phone: '' },
	});

	const dispatch = useDispatch();
	const alert = useSelector((state: RootState) => state.alert);
	const { errors } = useSelector((state: RootState) => state.profile);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	let [searchParams] = useSearchParams();
	const isSignup = searchParams.get('flow') === 'signup';

	const input = useWatch({ control: control, name: 'phone' });

	const onSubmit = async (data: FormBody) => {
		if (inputErrors.phone.length > 0) return;
		const internalCode = 66;
		try {
			setIsLoading({ ...isLoading, sendOTP: true });
			const response = await sendOTP(data.phone, data.countryCode);
			if (response.internal_code === internalCode) {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Error al enviar',
					description:
						errors[internalCode] ||
						'Hay un problema con tu cuenta, comúnicate al número de whatsapp +525513751952',
				};
				dispatch(showAlert(alert));
				return;
			}
			setStep(2);
		} catch (error: any | Error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Error al enviar',
				description:
					'Hay un problema con tu cuenta, comúnicate al número de whatsapp +525513751952',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, sendOTP: false });
		}
	};

	useEffect(() => {
		if (!input.match(REGEX.onlyNumberRegex) && input.length > 0) {
			setInputErrors({ ...inputErrors, phone: '*Solo se permiten números' });
			return;
		}
		setInputErrors({ ...inputErrors, phone: '' });
	}, [input]);

	useEffect(() => {
		const sessionExpired = isLocalStorageEnabled()
			? localStorage.getItem(LOCAL_STORAGE_KEY.SESSION_EXPIRED)
			: false;

		const token = isLocalStorageEnabled()
			? localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)
			: tokenSession;

		if (token) {
			window.location.href = ROUTES.ROOT;
		}

		setTimeout(() => {
			if (sessionExpired) {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Tu sesión expiro',
					description: 'Vuelve a ingresar tu número para poder continuar.',
				};
				dispatch(showAlert(alert));
				if (isLocalStorageEnabled()) {
					localStorage.removeItem('session_expired');
				} else {
					dispatch(removeToken());
				}
			}
		}, 1000);
	}, []);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	return (
		<section
			style={{
				backgroundImage: isSignup
					? `url(${bgImage})`
					: `url(${
							showDefaultImage
								? defaultImage
								: 'https://heru-static-assets.s3.us-east-2.amazonaws.com/heru-signin.webp'
						})`,
			}}
			className={`sm:flex justify-end h-screen bg-cover bg-no-repeat`}
		>
			{!showDefaultImage && (
				<img
					src={
						'https://heru-static-assets.s3.us-east-2.amazonaws.com/banner-info.png'
					}
					alt='login-promo-copy'
					className='hidden sm:block absolute bottom-10 left-0 w-1/2'
				/>
			)}
			{alert.showAlert && (
				<Alert
					type={alert.type}
					description={alert.description}
					title={alert.title}
				/>
			)}
			<div className='px-4 bg-h-dark/90 backdrop-blur-lg min-h-screen w-full sm:w-2/5'>
				<div className='max-w-[430px] mx-auto pt-8 lg:mt-12 2xl:mt-16 flex flex-col gap-8 xl:gap-6 2xl:gap-12'>
					<CustomIcon
						color='#fff'
						name='heru-logo'
						width={100}
					/>
					{isSignup ? (
						<Heading
							color='white'
							size='xs'
							sx='lg:text-[32px] lg:leading-[45px]'
						>
							Completa tu registro <br /> y disfruta de <br />
							<b className='text-h-primary'>tu contabilidad</b>
							<br />
							fácil y rápido
						</Heading>
					) : (
						<Heading
							color='white'
							size='xs'
							sx='lg:text-[32px] lg:leading-[45px]'
						>
							Haz las pases con <br />
							<b className='text-h-primary'>tus impuestos.</b>
							<br />
							¡Comienza ahora!
						</Heading>
					)}

					{step === 1 && (
						<div>
							<Text
								color='white'
								size='l'
							>
								{isSignup
									? 'Tu cuenta solo con tu número de celular'
									: 'Ingresa tu número de celular para comenzar'}
							</Text>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className='flex mb-9 gap-0 md:gap-2 mt-3 items-center'>
									<Selector
										hasFlag
										control={control}
										name='countryCode'
										options={COUNTRY_CODES}
									/>
									<Input
										control={control}
										name='phone'
										type='tel'
										label='Número de celular'
										errorMessage={inputErrors.phone}
										isDarkTheme
									/>
								</div>
								<Button
									variant='primary'
									loading={isLoading.sendOTP}
									disabled={input.length < 6 || inputErrors.phone.length > 0}
									label='Continuar'
									type='submit'
									icon={<ArrowRight size={18} />}
								/>
							</form>
						</div>
					)}
					{step === 2 && (
						<Verify
							setStep={setStep}
							countryCode={getValues('countryCode')}
							phone={getValues('phone')}
						/>
					)}
					{[1, 2].includes(step) && (
						<div className='pb-8 mt-4 2xl:mt-0'>
							<span className='flex gap-2 items-center'>
								<LockSimpleOpen
									size={18}
									color={'var(--h-primary)'}
									weight='bold'
								/>
								<Text
									color='primary'
									weight='medium'
								>
									Tu seguridad es nuestra máxima prioridad
								</Text>
							</span>
							<Text
								color='white'
								size='s'
								sx='opacity-80 mt-2'
							>
								En Heru, la seguridad de tu cuenta es nuestra máxima prioridad.
								Solicitamos números de teléfono móvil para el registro y el
								inicio de sesión porque nos permite enviar un código de un solo
								uso (OTP, por sus siglas en inglés) para agregar una capa
								adicional de protección. Este método de autenticación de dos
								factores es la forma más segura de resguardar tu cuenta e
								información sensible.
							</Text>
						</div>
					)}
					{step === 3 && <Register />}
				</div>
			</div>
		</section>
	);
};

export default React.memo(Login);
