import { CircularProgress, useMediaQuery } from '@mui/material';
import Table, { TablePagination } from '../../../components/molecules/Table';
import React, { useEffect, useState, useRef } from 'react';
import Selector from '../../../components/atoms/Selector';
import { useForm } from 'react-hook-form';
import { FiscalDocumentsColumns } from '../../../shared/constants/tables';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import DatePicker from '../../../components/molecules/DatePicker';
import {
	FiscalDocumentInvoices,
	FiscalDocumentReceipts,
	FiscalDocumentRetentions,
	FiscalDocumentType,
} from '../../../@types/FiscalDocuments.type';
import { DATES } from '../../../shared/utils/dates';
import {
	DECLARATION_TYPES,
	INVOICE_STATUS,
	INVOICE_TYPES,
	PAYMENT_STATUS,
	RETENTIONS_TYPES,
} from '../../../shared/constants/fiscalDocuments';
import FiscalDocumentsCardsMobile from './FiscalDocumentsCardsMobile';
import FiscalTableEmptyState from '../molecules/FiscalTableEmptyState';
import Modal from '../../../components/molecules/Modal/Modal';
import Text from '../../../components/atoms/Text';
import Button from '../../../components/atoms/Button';
import ROUTES from '../../../shared/constants/routes';
import { useLocation } from 'react-router-dom';
import { SelectedFiscalDocument } from './FiscalDocumentsSection';
import GetRowsDataFiscalDocument from '../molecules/RowDataFiscalDocument';
import useHandleFiscalServices from './useHandleFiscalServices';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { FileText, Upload, Trash } from '@phosphor-icons/react';
import LinearProgressWithLabel from './LinearProgressbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface FormBody {
	invoiceType: string;
	invoiceStatus: string;
	retentionType: string;
	declarationType: string;
	paymentStatus: string;
	receiptType: string;
}

interface Props {
	isHalfPage: boolean;
	documentType:
	| FiscalDocumentType.INVOICE
	| FiscalDocumentType.RECEIPT
	| FiscalDocumentType.RETENTION;
	selectedDocument: SelectedFiscalDocument;
	setSelectedDocument: React.Dispatch<
		React.SetStateAction<SelectedFiscalDocument>
	>;
	filterByPeriod: {
		year: number;
		month: string | null;
		confirmed: boolean;
	};
	setFilterByPeriod: React.Dispatch<
		React.SetStateAction<{
			year: number;
			month: string | null;
			confirmed: boolean;
		}>
	>;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	startUpload: boolean;
	setStartUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

export type FiscalDocumentsLists = {
	invoice: FiscalDocumentInvoices | null;
	retention: FiscalDocumentRetentions | null;
	receipt: FiscalDocumentReceipts | null;
};

const TableFiscalDocuments: React.FC<Props> = ({
	isHalfPage,
	documentType,
	selectedDocument,
	setSelectedDocument,
	filterByPeriod,
	setFilterByPeriod,
	currentPage,
	setCurrentPage,
	startUpload,
	setStartUpload,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [uploadProgress, setUploadProgress] = useState(0);
	const location = useLocation();
	const logClevertapEvent = useCleverTapEvent();
	const [uploadStep, setUploadStep] = useState(1);

	const isViewedFromTaxboard = location.pathname === ROUTES.TAXES;

	const [showModal, setShowModal] = useState({
		filters: false,
	});
	const [isLoading, setIsLoading] = useState({
		invoice: false,
		retention: false,
		receipt: false,
		detail: false,
	});
	const [selectedDocumentId, setSelectedDocumentId] = useState<{
		selected: number | null;
		loadingSelected: number | null;
	}>({ selected: null, loadingSelected: null });

	const [fiscalDocuments, setFiscalDocuments] =
		React.useState<FiscalDocumentsLists>({
			invoice: null,
			retention: null,
			receipt: null,
		});

	const userProfile = useSelector((state: RootState) => state.profile.user);

	const { year: taxboardYear, month: taxboardMonth } = useSelector(
		(state: RootState) => state.taxCalendar,
	);

	const { control, watch, setValue } = useForm<FormBody>({
		defaultValues: {
			invoiceStatus: INVOICE_STATUS[0].value,
			invoiceType: INVOICE_TYPES[0].value,
			retentionType: RETENTIONS_TYPES[0].value,
			declarationType: DECLARATION_TYPES[0].value,
			paymentStatus: PAYMENT_STATUS[0].value,
			receiptType: DECLARATION_TYPES[0].value,
		},
	});

	const invoiceType = watch('invoiceType');
	const invoiceStatus = watch('invoiceStatus');
	const retentionType = watch('retentionType');
	const declarationType = watch('declarationType');
	const paymentStatus = watch('paymentStatus');

	const isMobile = useMediaQuery('(max-width: 1200px)');

	const {
		handleGetInvoices,
		handleGetRetentions,
		handleGetReceipts,
		handleGetFiscalDocument,
		handleUploadFiscalDocument,
	} = useHandleFiscalServices(
		setFiscalDocuments,
		fiscalDocuments,
		setIsLoading,
		isLoading,
		setSelectedDocumentId,
		selectedDocumentId,
		documentType,
	);

	const handleShowDetail = async (document_id: number | string) => {
		setSelectedDocument({
			...selectedDocument,
			[documentType]: await handleGetFiscalDocument(Number(document_id)),
		});
	};

	const handleRemoveAllFilters = () => {
		setValue('invoiceType', INVOICE_TYPES[0].value);
		setValue('invoiceStatus', INVOICE_STATUS[0].value);
		setValue('retentionType', RETENTIONS_TYPES[0].value);
		setValue('declarationType', DECLARATION_TYPES[0].value);
		setValue('paymentStatus', PAYMENT_STATUS[0].value);

		if (isViewedFromTaxboard) {
			return;
		}

		setFilterByPeriod({
			year: DATES.currentYear,
			month: null,
			confirmed: false,
		});
	};

	const filteredInvoices = fiscalDocuments?.invoice?.data || [];
	const filteredRetentions = fiscalDocuments?.retention?.data || [];
	const filteredReceipts = fiscalDocuments?.receipt?.data || [];

	const filteredColumns = () => {
		if (selectedDocument[documentType]) {
			return FiscalDocumentsColumns[documentType].filter(
				(column) =>
					![
						'rfc',
						'invoice_type',
						'status',
						'deductible_amount',
						'payment_status',
						'payment_date',
						'certificate_type',
					].includes(column.dataIndex),
			);
		}
		return FiscalDocumentsColumns[documentType];
	};

	const getFiltersPerCategory = () => {
		switch (documentType) {
			case FiscalDocumentType.INVOICE:
				return (
					<>
						<Selector
							sx='w-full'
							control={control}
							name='invoiceStatus'
							options={INVOICE_STATUS}
							label='Estado'
						/>
					</>
				);
			case FiscalDocumentType.RETENTION:
				return (
					<>
						<Selector
							control={control}
							name='retentionType'
							options={RETENTIONS_TYPES}
							label='Tipo de retención'
							sx='w-full'
						/>
					</>
				);
			case FiscalDocumentType.RECEIPT:
				return (
					<>
						<Selector
							control={control}
							name='declarationType'
							options={DECLARATION_TYPES}
							label='Tipo de acuse'
							sx='w-full'
						/>
					</>
				);
			default:
				return null;
		}
	};

	const handleChangePage = (page: number) => {
		setCurrentPage(page);
	};

	const getAmountsByType = () => {
		const amounts: Record<string, number> = {
			invoice: fiscalDocuments?.invoice?.elementsCount || 0,
			retention: fiscalDocuments?.retention?.elementsCount || 0,
			receipt: fiscalDocuments?.receipt?.elementsCount || 0,
		};

		const totalPerPage: Record<string, number> = {
			invoice: fiscalDocuments?.invoice?.pageRange || 0,
			retention: fiscalDocuments?.retention?.pageRange || 0,
			receipt: fiscalDocuments?.receipt?.pageRange || 0,
		};

		return {
			totalDocuments: amounts[documentType] || 0,
			totalPerPage: totalPerPage[documentType] || 0,
		};
	};

	const numberOfFiltersApplied =
		Object.values({
			declarationType,
			retentionType,
			invoiceType,
			invoiceStatus,
			paymentStatus,
		}).filter((value) => value !== 'all').length +
		(filterByPeriod.confirmed ? (isHalfPage ? 0 : 1) : 0);

	useEffect(() => {
		if (!userProfile) return;

		if (isViewedFromTaxboard) {
			if (
				documentType === FiscalDocumentType.INVOICE &&
				filterByPeriod.confirmed
			) {
				handleGetInvoices(
					userProfile?.id!,
					currentPage,
					invoiceType,
					invoiceStatus,
					filterByPeriod.confirmed ? filterByPeriod.year : undefined,
					filterByPeriod.month || undefined,
				);
			}
			if (
				documentType === FiscalDocumentType.RETENTION &&
				filterByPeriod.confirmed
			) {
				handleGetRetentions(
					userProfile?.id!,
					currentPage,
					retentionType,
					filterByPeriod.confirmed ? filterByPeriod.year : undefined,
					filterByPeriod.month || undefined,
				);
			}
			if (
				documentType === FiscalDocumentType.RECEIPT &&
				filterByPeriod.confirmed
			) {
				handleGetReceipts(
					userProfile?.id!,
					currentPage,
					declarationType,
					filterByPeriod.confirmed ? filterByPeriod.year : undefined,
					filterByPeriod.month || undefined,
				);
			}

			return;
		}

		if (documentType === FiscalDocumentType.INVOICE) {
			handleGetInvoices(
				userProfile?.id!,
				currentPage,
				invoiceType,
				invoiceStatus,
				filterByPeriod.confirmed ? filterByPeriod.year : undefined,
				filterByPeriod.month || undefined,
			);
		}
		if (documentType === FiscalDocumentType.RETENTION) {
			handleGetRetentions(
				userProfile?.id!,
				currentPage,
				retentionType,
				filterByPeriod.confirmed ? filterByPeriod.year : undefined,
				filterByPeriod.month || undefined,
			);
		}
		if (documentType === FiscalDocumentType.RECEIPT) {
			handleGetReceipts(
				userProfile?.id!,
				currentPage,
				declarationType,
				filterByPeriod.confirmed ? filterByPeriod.year : undefined,
				filterByPeriod.month || undefined,
			);
		}
		setSelectedDocumentId({
			selected: null,
			loadingSelected: null,
		});
	}, [
		userProfile,
		documentType,
		invoiceType,
		retentionType,
		currentPage,
		invoiceStatus,
		paymentStatus,
		declarationType,
		filterByPeriod.confirmed,
	]);

	useEffect(() => {
		if (taxboardYear && taxboardMonth && isViewedFromTaxboard) {
			setFilterByPeriod({
				year: taxboardYear,
				month: `${taxboardMonth}`,
				confirmed: true,
			});
		} else {
			setFilterByPeriod({
				year: DATES.currentYear,
				month: null,
				confirmed: false,
			});
			handleRemoveAllFilters();
		}
	}, [documentType, taxboardMonth]);

	useEffect(() => {
		if (selectedDocument[documentType] === null) {
			setSelectedDocumentId({
				selected: null,
				loadingSelected: null,
			});
		}
	}, [selectedDocument]);

	useEffect(() => {
		if (numberOfFiltersApplied > 0) {
			const filters = [
				{ type: 'invoiceType', value: invoiceType },
				{ type: 'retentionType', value: retentionType },
				{ type: 'invoiceStatus', value: invoiceStatus },
				{ type: 'paymentStatus', value: paymentStatus },
				{ type: 'declarationType', value: declarationType },
			];

			for (const filter of filters) {
				if (filter.value !== 'all') {
					logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
						event_type: EVENT_TYPES.FISCAL_DOCUMENTS_SELECT_FILTER,
						filter_type: filter.type,
						filter_value: filter.value,
						source: documentType,
					});
					return;
				}
			}

			if (filterByPeriod.confirmed) {
				logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
					event_type: EVENT_TYPES.FISCAL_DOCUMENTS_SELECT_FILTER,
					filter_type: 'period',
					filter_value: `${filterByPeriod.year}-${filterByPeriod.month}`,
					source: documentType,
				});
			}
		}
	}, [
		documentType,
		invoiceType,
		retentionType,
		currentPage,
		invoiceStatus,
		paymentStatus,
		declarationType,
		filterByPeriod.confirmed,
	]);

	const dataRows = GetRowsDataFiscalDocument(
		handleShowDetail,
		fiscalDocuments,
		isLoading,
		selectedDocumentId,
		isMobile,
	);

	const handleChange = (event: any) => {
		setUploadStep(2);
		setFile(event.target.files[0]);

		setUploadProgress(30);


		setTimeout(() => {
			setUploadProgress(60);
		}, 2000);

		setTimeout(() => {
			setUploadProgress(90);
		}, 1000);

		setTimeout(() => {
			setUploadStep(3);
		}, 1000);
		setTimeout(() => {
			setUploadStep(4);
		}, 2000);


	};

	const prepareCloseUpload = () => {
		setStartUpload(false);
		resetUploadFile();
	};

	const resetUploadFile = () => {
		setUploadStep(1);
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const triggerUploadInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const finishUploadFlow = () => {
		setStartUpload(false);
		handleUploadFiscalDocument(file, userProfile?.id!);
		resetUploadFile();
	};

	const getFileSizeKB = () => {
		if (!file) return '0';
		return (file?.size / 1024).toFixed(2);
	};

	if (isMobile) {
		return (
			<>
				<Modal
					showCloseButton
					open={showModal.filters}
					onClose={() => setShowModal({ ...showModal, filters: false })}
					sx='overflow-y-auto w-[100%] max-w-[90vw]'
				>


					<Text
						size={'body-1'}
						weight='bold'
						sx='mb-4'
						color='medium'
					>
						Filtrar por
					</Text>
					<div className='flex flex-col gap-3'>
						{!isHalfPage && (
							<DatePicker
								selectedPeriod={filterByPeriod}
								setSelectedPeriod={setFilterByPeriod}
							/>
						)}
						{getFiltersPerCategory()}
					</div>
					{numberOfFiltersApplied > 0 && (
						<Button
							label='Quitar todos los filtros'
							variant='secondary'
							sx='mt-6'
							onClick={handleRemoveAllFilters}
						/>
					)}
					<Button
						label='Cerrar filtros'
						sx={numberOfFiltersApplied > 0 ? 'mt-2' : 'mt-6'}
						onClick={() => setShowModal({ ...showModal, filters: false })}
					/>
				</Modal>
				<input
					type='file'
					id='file'
					ref={fileInputRef}
					onChange={handleChange}
					accept='.xml'
					style={{ display: 'none' }}
				/>


				<Modal
					showCloseButton={false}
					open={startUpload}
					onClose={prepareCloseUpload}
					sx='w-[327px] md:h-auto'
				>
					<div className='flex flex-col items-center justify-center gap-4 px-1 py-4'>
						<Upload size={36} />
						<Text size='label' weight='medium' >Carga de documentos</Text>

						<Text size='caption' weight='light' sx='text-center'>
							Por favor, asegúrate de que el archivo que estás subiendo es tu certificado de retención o factura en formato XML, ya que se tomará como base para generar tu declaración fiscal del  mes correspondiente.
						</Text>


						{uploadStep === 1 && (
							<>
								<Button
									label='Cargar documento'
									variant='primary'
									onClick={triggerUploadInput}
								/>
								<Button label='Cerrar' variant='outline' onClick={prepareCloseUpload} />
							</>
						)}

						{uploadStep === 2 && (
							<>
								<div className='w-full p-4' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<div className='flex md:flex-row flex-col items-center justify-between gap-2'>
										<div className='flex md:flex-row gap-2 w-[100%]'>
											<div className='flex w-[10%]'>
												<FileText size={24} weight='fill' color='gray' />
											</div>
											<div className='flex flex-col justify-between w-[90%]'>
												<div className='flex flex-row items-center justify-between w-[100%]'>
													<Text size='mini-1' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
													<Button label='' variant='text' beforeIcon={<Trash size={24} />} onClick={resetUploadFile} />
												</div>

												<Text size='mini-1' weight='regular' color='gray'>{getFileSizeKB()} KB</Text>
											</div>
										</div>


									</div>
									<div>
										<LinearProgressWithLabel value={uploadProgress} />
									</div>
								</div>
							</>
						)}

						{uploadStep === 3 && (
							<>
								<div className='w-full p-4' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<div className='flex  items-center justify-between gap-2'>
										<div className='flex  gap-2 w-[100%]'>
											<div className='flex w-[10%]'>
												<FileText size={24} weight='fill' color='gray' />
											</div>
											<div className='flex flex-col justify-between w-[90%]'>
												<div className='flex flex-row items-center justify-between w-[100%]'>
													<Text size='xs' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
													<CheckCircleIcon color='success' sx={{ fontSize: '24px' }} />
												</div>

												<Text size='mini-1' weight='regular' color='gray'>{getFileSizeKB()} KB</Text>
											</div>
										</div>


									</div>
									<div>
										<LinearProgressWithLabel value={100} color='success' />
									</div>
								</div>
							</>
						)}

						{uploadStep === 4 && (
							<>
								<div className='flex  items-center justify-between gap-2 w-full h-[40px] py-2 px-2' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<Text size='body-3' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
									<Button label='' variant='text' beforeIcon={<Trash size={24} />} onClick={resetUploadFile} />
								</div>
								<Button
									label='Finalizar'
									variant='primary'
									onClick={finishUploadFlow}

								/>
								<Button label='Cancelar' variant='outline' onClick={prepareCloseUpload} />
							</>
						)}
					</div>
				</Modal>
				<FiscalDocumentsCardsMobile
					setShowModal={setShowModal}
					isHalfPage={isHalfPage}
					handleShowDetail={handleShowDetail}
					invoice={filteredInvoices}
					retention={filteredRetentions}
					receipt={filteredReceipts}
					documentType={documentType}
					isLoading={isLoading[documentType]}
					isLoadingDetail={isLoading.detail}
					filtersApplied={numberOfFiltersApplied}
					removeAllFilters={handleRemoveAllFilters}
					selectedDocumentId={selectedDocumentId}
				/>
				<FiscalTableEmptyState
					isLoading={isLoading}
					documentType={documentType}
					fiscalDocuments={fiscalDocuments}
				/>
				<div className='flex justify-end mt-5'>
					<TablePagination
						disabled={isLoading[documentType]}
						total={getAmountsByType().totalDocuments}
						currentPage={currentPage}
						perPage={getAmountsByType().totalPerPage}
						onChangePage={handleChangePage}
					/>
				</div>
			</>
		);
	}



	return (
		<div>
			<div className='flex gap-1 ml-auto w-fit -mt-14'>
				<input
					type='file'
					id='file'
					ref={fileInputRef}
					onChange={handleChange}
					accept='.xml'
					style={{ display: 'none' }}
				/>


				<Modal
					showCloseButton
					open={startUpload}
					onClose={prepareCloseUpload}
					sx='md:w-[465px] md:h-auto'
				>
					<div className='flex flex-col items-center justify-center gap-4 px-5 py-4'>
						<Upload size={36} />
						<Text size='subtitle' weight='medium'>Carga de documentos</Text>

						<Text size='label' weight='light'>
							Por favor, asegúrate de que el archivo que estás subiendo es tu certificado de retención o factura en formato XML, ya que se tomará como base para generar tu declaración fiscal del  mes correspondiente.
						</Text>


						{uploadStep === 1 && (
							<>
								<Button
									label='Cargar documento'
									variant='primary'
									onClick={triggerUploadInput}
								/>
								<Button label='Cerrar' variant='outline' onClick={prepareCloseUpload} />
							</>
						)}

						{uploadStep === 2 && (
							<>
								<div className='w-full p-4' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<div className='flex md:flex-row flex-col items-center justify-between gap-2'>
										<div className='flex md:flex-row gap-2 w-[100%]'>
											<FileText size={24} weight='fill' color='gray' />
											<div className='flex flex-col justify-between w-[100%]'>
												<div className='flex flex-row items-center justify-between w-[100%]'>
													<Text size='mini-1' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
													<Button label='' variant='text' beforeIcon={<Trash size={24} />} onClick={resetUploadFile} />
												</div>

												<Text size='mini-1' weight='regular' color='gray'>{getFileSizeKB()} KB</Text>
											</div>
										</div>


									</div>
									<div>
										<LinearProgressWithLabel value={uploadProgress} />
									</div>
								</div>
							</>
						)}

						{uploadStep === 3 && (
							<>
								<div className='w-full p-4' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<div className='flex md:flex-row flex-col items-center justify-between gap-2'>
										<div className='flex md:flex-row gap-2 w-[100%]'>
											<FileText size={24} weight='fill' color='gray' />
											<div className='flex flex-col justify-between w-[100%]'>
												<div className='flex flex-row items-center justify-between w-[100%]'>
													<Text size='xs' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
													<CheckCircleIcon color='success' sx={{ fontSize: '24px' }} />
												</div>

												<Text size='mini-1' weight='regular' color='gray'>{getFileSizeKB()} KB</Text>
											</div>
										</div>


									</div>
									<div>
										<LinearProgressWithLabel value={100} color='success' />
									</div>
								</div>
							</>
						)}

						{uploadStep === 4 && (
							<>
								<div className='flex md:flex-row flex-col items-center justify-between gap-2 md:w-full md:h-[40px] p-2' style={{ border: '1px solid #E0E0E0', borderRadius: '8px' }}>
									<Text size='body-3' weight='regular' sx='w-[85%]' truncate>{file?.name}</Text>
									<Button label='' variant='text' beforeIcon={<Trash size={24} />} onClick={resetUploadFile} />
								</div>
								<Button
									label='Finalizar'
									variant='primary'
									onClick={finishUploadFlow}
								/>
								<Button label='Cancelar' variant='outline' onClick={prepareCloseUpload} />
							</>
						)}
					</div>
				</Modal>
			</div>
			<br />
			<div className='flex gap-2 ml-auto w-fit -mt-7'>
				{!isHalfPage && (
					<DatePicker
						selectedPeriod={filterByPeriod}
						setSelectedPeriod={setFilterByPeriod}
					/>
				)}
				{getFiltersPerCategory()}
			</div>
			<div
				className={`flex flex-col justify-between ${isHalfPage ? '' : 'min-h-[calc(100vh-260px)]'}`}
			>
				{isLoading[documentType] ? (
					<div className='flex items-center justify-center mt-10'>
						<CircularProgress size={25} />
					</div>
				) : (
					<div>
						<Table
							columns={filteredColumns()}
							rowsData={dataRows[documentType]}
							responsive={isMobile}
							sx='mt-6'
						/>
						<FiscalTableEmptyState
							isLoading={isLoading}
							documentType={documentType}
							fiscalDocuments={fiscalDocuments}
						/>
					</div>
				)}
				<div className={`flex justify-end ${isHalfPage ? 'mt-4' : ''}`}>
					<TablePagination
						disabled={isLoading[documentType]}
						total={getAmountsByType().totalDocuments}
						currentPage={currentPage}
						perPage={getAmountsByType().totalPerPage}
						onChangePage={handleChangePage}
					/>
				</div>
			</div>
		</div>
	);
};

export default TableFiscalDocuments;
