import { useMediaQuery } from '@mui/material';
import Heading from '../../../components/atoms/Heading';
import Text from '../../../components/atoms/Text';
import Modal from '../../../components/molecules/Modal/Modal';
import { CaretCircleLeft } from '@phosphor-icons/react';
import { formatCurrency } from '../../../shared/utils/general.util';

export const PlanFlowContentWrapper = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return (
		<div className='p-3 md:p-5 md:max-h-[calc(100dvh-350px)] overflow-y-auto'>
			{children}
		</div>
	);
};

type TopBannerProps = {
	productName: React.ReactNode;
	price: number | null;
	subtitle?: string;
	afterPrice?: string;
	priceDescription?: string;
	beforePrice?: string;
};

export const TopBanner: React.FC<TopBannerProps> = ({
	productName,
	subtitle,
	price,
	afterPrice,
	priceDescription,
	beforePrice,
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div className='bg-h-primary-30 flex items-center gap-2 justify-between py-3 md:py-4 px-3 rounded-t-lg'>
			<div className='flex flex-col items-center w-full'>
				{isMobile ? (
					<Text
						size='body-2'
						weight='bold'
						color='white'
						sx='flex !text-center justify-center'
						responsive
					>
						{productName}
					</Text>
				) : (
					<Heading
						size='xs'
						weight='bold'
						color='white'
						sx='max-w-[200px] flex !text-center justify-center'
						responsive
					>
						{productName}
					</Heading>
				)}
				{
					<Text
						size={isMobile ? 'mini-1' : 'label'}
						color='white'
						weight='light'
						responsive
						sx='!block !text-center'
					>
						{subtitle}
					</Text>
				}
			</div>
			<div className='h-14 w-0.5 bg-white' />
			<div className='flex flex-col items-center w-full px-2'>
				<div className='flex gap-2 w-fit items-center'>
					<div className='flex items-center gap-2'>
						<Text
							size={isMobile ? 'mini-1' : 'label'}
							weight='medium'
							color='white'
							responsive
						>
							{beforePrice}
						</Text>
						<Heading
							size='xs'
							color='white'
							responsive
						>
							$
						</Heading>
						<Heading
							size={isMobile ? 'xs' : 's'}
							color='white'
						>
							{price ? formatCurrency(price, false) : '-'}
						</Heading>
					</div>
					<Text
						size={isMobile ? 'mini-1' : 'label'}
						weight='medium'
						color='white'
						responsive
						sx='!block !text-center'
					>
						{afterPrice}
					</Text>
				</div>
				<Text
					size={isMobile ? 'caption' : 'label'}
					weight='light'
					color='white'
					responsive
				>
					{priceDescription}
				</Text>
			</div>
		</div>
	);
};

type LayoutCustomPlanProps = {
	closeModal: () => void;
	planFlow: React.ReactNode;
};

const LayoutCustomPlan: React.FC<LayoutCustomPlanProps> = ({
	closeModal,
	planFlow,
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	if (!planFlow) return null;

	if (isMobile)
		return (
			<div className='p-3 pb-20'>
				<div
					className='flex items-center gap-2 mb-4'
					onClick={closeModal}
				>
					<CaretCircleLeft
						width={24}
						height={24}
						weight='fill'
						color='var(--h-primary)'
					/>
					<Text weight='bold'>Planes y precios</Text>
				</div>
				<div className='shadow rounded-lg overflow-hidden'>{planFlow}</div>
			</div>
		);
	return (
		<Modal
			open
			showCloseButton={false}
			sx='w-[94%] !max-w-xl !p-0 h-[calc(100dvh-128px)] overflow-hidden !rounded-lg flex flex-col'
			onClose={closeModal}
		>
			{planFlow}
		</Modal>
	);
};

export default LayoutCustomPlan;
