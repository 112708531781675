import {
	TPeriodicity,
	TaxboardDeclarationStatus,
} from '../../@types/Calendar.type';
import { TCaseVariant } from '../../components/molecules/Tag';

const labelColor: Record<string, TCaseVariant> = {
	[TaxboardDeclarationStatus.PRESENTED]: 'success',
	[TaxboardDeclarationStatus.PENDING]: 'error',
	[TaxboardDeclarationStatus.PENDING_PAY]: 'pending',
	[TaxboardDeclarationStatus.IN_PROCESS]: 'inactive',
	[TaxboardDeclarationStatus.DOWNLOADING]: 'loading',
};

const taxboardDeclarationStatusLabel: Record<string, string> = {
	PRESENTADA: 'Presentada',
	'EN PROCESO': 'En proceso',
	PENDIENTE: 'Pendiente',
	'PENDIENTE DE PAGO SAT': 'Pago pendiente al SAT',
	'DESCARGANDO INFO': 'Descargando info',
};

const calculateDelayDays = (year: number, month: number) => {
	const today = new Date();

	const monthToCheck = month === 12 ? 1 : month + 1;
	const yearToCheck = month === 12 ? year + 1 : year;

	const presentationDate = new Date(`${yearToCheck}-${monthToCheck}-17`);
	const difference = today.getTime() - presentationDate.getTime();
	const days = Math.ceil(difference / (1000 * 3600 * 24));

	if (typeof days !== 'number' || isNaN(days) || days < 0) {
		return 0;
	}

	return days <= 0 ? 0 : days;
};

const supportedNumberOfYears = {
	[TPeriodicity.MONTHLY]: 5,
	[TPeriodicity.YEARLY]: 4,
};

const CALENDAR = {
	labelColor,
	taxboardDeclarationStatusLabel,
	calculateDelayDays,
	supportedNumberOfYears,
};

export default CALENDAR;
