import HERU_API from '../shared/constants/heruApi';
import { get } from '../shared/utils/FetchSSL.util';

const fetchAcquisitions = async (tokenSession?: string) => {
	try {
		const response = await get(HERU_API.HERU_API_ACQUISITIONS, {
			token: true,
			isNewGateway: true,
			tokenSession,
		});

		return response;
	} catch (error) {
		throw error;
	}
};

export { fetchAcquisitions };
