import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
	isLogged: boolean;
	tokenSession?: string;
	goTo?: string;
}

const initialState: AuthState = {
	isLogged: false,
	tokenSession: undefined,
	goTo: undefined,
};

export const authSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {
		login: (state) => {
			state.isLogged = true;
		},
		logout: (state) => {
			state.isLogged = false;
		},
		addToken: (state, action) => {
			state.tokenSession = action.payload;
		},
		removeToken: (state) => {
			state.tokenSession = undefined;
		},
		setGoto: (state, action) => {
			state.goTo = action.payload;
		},
	},
});

export const { login, logout, addToken, removeToken, setGoto } =
	authSlice.actions;
