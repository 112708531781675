import React, { useEffect, useState } from 'react';
import Heading from '../../../components/atoms/Heading';
import Text from '../../../components/atoms/Text';
import CategorySelector from '../components/organisms/CategorySelector';
import {
	PYP_CATEGORY_LABELS,
	CATEGORY_SINGLE,
	annualProductsStatic,
	asatDefaultProducts,
	regularizationProductsDefaults,
	subscriptionsProductsDefaults,
} from '../data/ecommerceData';

import { ListProducts } from '../../../@types/Products.type';
import DesktopSelection from './DesktopSelection';
import AsatCard from '../components/organisms/AsatCard';
import DefaultCard from '../components/organisms/DefaultCard';
import { PeriodsSelectedType } from '../../../@types/Calendar.type';
import { useLocation } from 'react-router-dom';
import { CaretCircleLeft, CaretCircleRight } from '@phosphor-icons/react';

import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { PRODUCTS_SLUG } from '../../../shared/constants/SlugsProducts';
import { getYearByPeriod } from '../../../shared/utils/dates.util';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import { APP_CONFIGURATIONS } from '../../../shared/constants/configurations';
import { getElasticyPrice } from '../../../shared/utils/products.utils';
import GROWTHBOOK_FEATURE_FLAGS from '../../../shared/utils/growthbook.util';
import { useFeatureValue } from '@growthbook/growthbook-react';

export type ProductType = {
	id: string;
	name: string;
	desc: string;
	principalBenefits: string[];
	allBenefits: string[];
	beforePriceText: string;
	afterPriceText: string;
	price: number;
	showTag: boolean;
	beforePrice?: string | number;
	newPriceText?: string;
};
interface DesktopPlansProps {
	products: ListProducts[];
	handleAddToCart: (id: number, valuePeriods?: PeriodsSelectedType[]) => void;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}

const DesktopPlans: React.FC<DesktopPlansProps> = ({
	products,
	handleAddToCart,
	handleAddProduct,
	isLoadingAddProduct,
}) => {
	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [id, setId] = useState<string>(CATEGORY_SINGLE.HERU_PLUS);
	const [isLoadingFeature, setIsLoadingFeature] = useState(true);

	const [selectedCategory, setSelectedCategory] = useState(
		PYP_CATEGORY_LABELS[0],
	);
	const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);

	const location = useLocation();
	const logClevertapEvent = useCleverTapEvent();

	const handleChangeDektop = (item: string) => {
		setSelectedCategory(item);
	};

	const getAsatPlus = products[3]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ASSISTANCE_ASAT,
	);
	const getAsatBasic = products[3]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ASAT_SIMPLE,
	);
	const getAnnual = products[2]?.products?.filter(
		(item) => item?.slug === PRODUCTS_SLUG.ANNUAL_DECLARATION,
	);

	const elasticityPrice = useFeatureValue(
		GROWTHBOOK_FEATURE_FLAGS.GET_PRICE_ELASTICITY,
		{ group_experiment: 'A' },
	);

	const prevSlide = () => {
		setCurrentCardIndex((prevIndex) =>
			prevIndex === 0 ? annualProductsStatic.length - 1 : prevIndex - 1,
		);
	};

	const nextSlide = () => {
		setCurrentCardIndex((prevIndex) =>
			prevIndex === annualProductsStatic.length - 1 ? 0 : prevIndex + 1,
		);
	};

	useEffect(() => {
		if (location.state && location.state.category) {
			setSelectedCategory(location.state.category);
		}
	}, []);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_TAB,
			tab: selectedCategory,
		});
	}, [selectedCategory]);

	useEffect(() => {
		setTimeout(() => {
			setIsLoadingFeature(false);
		}, 2000);
	}, []);

	return (
		<div className='flex flex-col items-center w-full pt-8'>
			<Heading
				children='Planes y precios'
				weight='medium'
				size='m'
			/>
			<Text
				children='Elige entre estas soluciones para ayudarte con tus impuestos.'
				weight='medium'
				size='l'
				sx='mt-2'
				color='dark'
			/>
			<div>
				<CategorySelector
					categories={PYP_CATEGORY_LABELS}
					isMobile={false}
					categoryDesktop={selectedCategory}
					handleChangeDesktop={handleChangeDektop}
				/>
			</div>

			<div>
				{/* //* Planes de contabilidad   */}
				{selectedCategory === PYP_CATEGORY_LABELS[0] && (
					<div className='flex flex-row items-start pb-8'>
						{subscriptionsProductsDefaults.map((item) => (
							<DefaultCard
								id={item.id}
								key={item.id}
								description={item.desc}
								title={item.name}
								benefits={item.principalBenefits}
								allBenefits={item.allBenefits}
								beforePriceText={item.beforePriceText}
								afterPriceText={item.afterPriceText}
								price={
									APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
										? isLoadingFeature
											? '-'
											: getElasticyPrice(
													Math.round(item.price),
													elasticityPrice.group_experiment,
												)
										: item.price
								}
								showTag={item.showTag}
								textButton='Quiero este plan'
								sx='mr-4'
								onPress={() => {
									if (item.id === 'heru-plus') {
										setIsVisibleModal(true);
										setId('heru-plus');
									} else {
										setIsVisibleModal(true);
										setId('basic-accounting');
									}
								}}
								beforePrice={item.beforePrice}
								newPriceText={item.newPriceText}
							/>
						))}
					</div>
				)}

				{/* //* Declaraciones Atrasadas  */}
				{selectedCategory === PYP_CATEGORY_LABELS[1] && (
					<div className='flex flex-row items-center'>
						{regularizationProductsDefaults.map((item) => (
							<DefaultCard
								id={item.id}
								key={item.id}
								description={item.desc}
								title={item.name}
								benefits={item.principalBenefits}
								allBenefits={item.allBenefits}
								beforePriceText={item.beforePriceText}
								afterPriceText={item.afterPriceText}
								price={
									APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
										? getElasticyPrice(
												Math.round(item.price),
												elasticityPrice.group_experiment,
											)
										: item.price
								}
								textButton='Quiero este plan'
								sx='mr-4'
								onPress={() => {
									setIsVisibleModal(true);
									setId('regularization');
								}}
								beforePrice={item.beforePrice}
								newPriceText={item.newPriceText}
							/>
						))}
					</div>
				)}

				{/* //* Declaraciones Anuales  */}
				{selectedCategory === PYP_CATEGORY_LABELS[2] && (
					<div className='relative mx-auto w-full flex justify-center'>
						<div className='overflow-hidden rounded-lg'>
							<div
								className='flex'
								style={{
									transform: `translateX(-${
										currentCardIndex * (100 / annualProductsStatic.length)
									}%)`,
									transition: 'transform 0.5s ease',
									width: `${annualProductsStatic.length * 100}%`,
								}}
							>
								{annualProductsStatic.map((item, index) => (
									<div
										key={index}
										style={{
											width: `calc(100% / ${annualProductsStatic.length})`,
											marginLeft: currentCardIndex === 0 ? `12%` : `30%`,
											marginRight: currentCardIndex === 1 ? `-45%` : `-30%`,
										}}
									>
										<DefaultCard
											id={item.id}
											key={index}
											description={item.desc}
											title={item.name}
											benefits={item.principalBenefits}
											allBenefits={item.allBenefits}
											beforePriceText={item.beforePriceText}
											afterPriceText={item.afterPriceText}
											price={
												APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
													? getElasticyPrice(
															Math.round(item.price),
															elasticityPrice.group_experiment,
														)
													: item.price
											}
											textButton='Quiero este plan'
											sx={`mb-6`}
											isOpacity={currentCardIndex !== index}
											onPress={() => {
												if (item.id === 'annual') {
													handleAddProduct(
														getAnnual[0]?.product_versions[0]?.id,
														{
															year: getYearByPeriod(),
															regimes: [],
														},
													);
												} else {
													setIsVisibleModal(true);
													setId('annual');
												}
											}}
											loading={isLoadingAddProduct}
											beforePrice={item.beforePrice}
											newPriceText={item.newPriceText}
										/>
									</div>
								))}
							</div>
						</div>
						<div className='absolute top-1/2 -translate-y-1/2 flex justify-between w-full'>
							<CaretCircleLeft
								size={40}
								weight='fill'
								onClick={prevSlide}
								className={`absolute top-1/2 -translate-y-1/2 left-[-50px] ${
									currentCardIndex === 0 ? 'text-transparent' : 'text-h-blue-50'
								}`}
							/>

							<CaretCircleRight
								size={40}
								weight='fill'
								onClick={nextSlide}
								className={`absolute top-1/2 -translate-y-1/2 right-[-50px] ${
									currentCardIndex === 1 ? 'text-transparent' : 'text-h-blue-50'
								}`}
							/>
						</div>
					</div>
				)}

				{/* //* Tramites con el SAT  */}
				{selectedCategory === PYP_CATEGORY_LABELS[3] && (
					<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
						{asatDefaultProducts.map((item) => (
							<AsatCard
								id={item.id}
								key={item.id}
								description={item.desc}
								title={item.name}
								benefits={item.allBenefits}
								price={
									APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
										? getElasticyPrice(
												Math.round(item.price),
												elasticityPrice.group_experiment,
											)
										: item.price
								}
								textButton='Agregar al carrito'
								onPress={() => {
									if (item.id === 'asat-plus') {
										handleAddToCart(getAsatPlus[0]?.product_versions[0]?.id);
									} else {
										handleAddToCart(getAsatBasic[0]?.product_versions[0]?.id);
									}
								}}
								sx='mb-6'
								loading={isLoadingAddProduct}
								beforePrice={item.beforePrice}
								newPriceText={item.newPriceText}
							/>
						))}
					</div>
				)}
			</div>
			<DesktopSelection
				products={products}
				id={id}
				isVisibleModal={isVisibleModal}
				setIsVisibleModal={setIsVisibleModal}
				handleAddToCart={handleAddToCart}
				handleAddProduct={handleAddProduct}
				isLoadingAddProduct={isLoadingAddProduct}
			/>
		</div>
	);
};

export default React.memo(DesktopPlans);
