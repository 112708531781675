import { Profile } from '../../../../../@types/Profile.type';

import { useCallback, useEffect, useState } from 'react';

import {
	deleteFiscalDocuments,
	fetchUserFiscalDocuments,
} from '../../../../../services/taxes.service';

import { AlertPayload } from '../../../../../redux/slices/alert.slice';

import FileView from '../../molecules/fileView';

import UploadDigitalSeals from '../../../../../components/molecules/UploadDigitalSeals';
import Text from '../../../../../components/atoms/Text';
import Divider from '../../../../../components/atoms/Divider';
import { CaretDown, Trash } from '@phosphor-icons/react';

import PasswordToggle from '../../molecules/passwordToggle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface DataDigitalSea {
	certificate: string;
	private_key: string;
	password: string;
}

type Props = {
	userProfile: Profile | null;
	showAlertTop: (alert: AlertPayload) => void;
};

const DigitalSeals: React.FC<Props> = ({ userProfile, showAlertTop }) => {
	const [pdfCerDigitalSea, setPdfCerDigitalSea] = useState<DataDigitalSea>();
	const [pdfKeyDigitalSea, setPdfKeyDigitalSea] = useState<DataDigitalSea>();
	const [passwordDigitalSea, setPasswordDigitalSea] =
		useState<DataDigitalSea>();
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const getDigitalSeaCer = useCallback(async () => {
		try {
			const response = await fetchUserFiscalDocuments(
				'certificate',
				'certificates',
				tokenSession,
			);
			setPdfCerDigitalSea(response);
		} catch (error) {}
	}, [userProfile]);

	const getDigitalSeaKey = useCallback(async () => {
		try {
			const response = await fetchUserFiscalDocuments(
				'private_key',
				'certificates',
				tokenSession,
			);
			setPdfKeyDigitalSea(response);
		} catch (error) {}
	}, [userProfile]);

	const getDigitasSeaPassword = useCallback(async () => {
		try {
			const response = await fetchUserFiscalDocuments(
				'password',
				'certificates',
				tokenSession,
			);
			setPasswordDigitalSea(response);
		} catch (error) {}
	}, [userProfile]);

	const handleDelete = useCallback(async () => {
		try {
			await deleteFiscalDocuments('certificates', tokenSession);
			getDigitalSeaCer();
			getDigitalSeaKey();
			getDigitasSeaPassword();
			showAlertTop({
				type: 'success',
				description: 'Documentos eliminados correctamente',
			});
		} catch (error) {}
	}, []);

	const documentsLinked = pdfCerDigitalSea && pdfKeyDigitalSea;

	const handleClickOption = (filename: 'cert_url' | 'key_url') => {
		if (filename === 'cert_url') {
			window.open(pdfCerDigitalSea?.certificate, '_blank');
		} else if (filename === 'key_url') {
			window.open(pdfKeyDigitalSea?.private_key, '_blank');
		}
	};

	useEffect(() => {
		if (userProfile) {
			getDigitalSeaCer();
			getDigitalSeaKey();
			getDigitasSeaPassword();
		}
	}, [userProfile]);

	return (
		<div>
			<div className='flex justify-between'>
				<Text
					size='body-2'
					color='dark'
					weight='bold'
					sx='mb-4 hidden lg:block'
				>
					Sellos digitales
				</Text>
				<Text
					size='body-3'
					color='dark'
					weight='bold'
					sx='mb-4 block lg:hidden'
				>
					Sellos digitales
				</Text>
				{pdfCerDigitalSea?.certificate && pdfKeyDigitalSea?.private_key && (
					<div
						className='relative group/file ml-4 cursor-pointer'
						onClick={handleDelete}
					>
						<Trash
							weight='light'
							color={'var(--h-primary-20)'}
							className='text-bold group-hover/file:hidden'
							width={24}
							height={24}
						/>
						<Trash
							weight='fill'
							color={'var(--h-primary-20)'}
							className='text-bold hidden group-hover/file:block'
							width={24}
							height={24}
						/>
						<div className='transition translate-y-5 group-hover/file:translate-y-0  opacity-0 group-hover/file:opacity-100 absolute bottom-10 left-[calc(100%-56px)] flex flex-col items-center cursor-pointer'>
							<Text
								weight='regular'
								size='caption'
								color='white'
								sx='bg-h-primary-20 px-2 py-1 rounded-lg'
							>
								Eliminar
							</Text>
							<CaretDown
								weight='fill'
								color='var(--h-primary-20)'
								width={24}
								height={24}
								className='mt-[-10px]'
							/>
						</div>
					</div>
				)}
			</div>

			<Divider />
			{documentsLinked ? (
				<div className='flex flex-col gap-3'>
					<FileView
						name='Certificado_sat.cer'
						onClick={() => handleClickOption('cert_url')}
						//TODO: handleDelete
						// onClickDelete={handleDelete}
						// viewDelete={true}
					/>
					<FileView
						name='Clave_sat.key'
						onClick={() => handleClickOption('key_url')}
						// onClickDelete={handleDelete}
						// viewDelete={true}
					/>
					{passwordDigitalSea?.password && (
						<PasswordToggle
							label='Contraseña clave privada'
							initialPassword={passwordDigitalSea?.password}
						/>
					)}
				</div>
			) : (
				<UploadDigitalSeals viewTitle={false} />
			)}
		</div>
	);
};

export default DigitalSeals;
