import { Check, XCircle } from '@phosphor-icons/react';
import Container from '../../../components/atoms/Container';
import Text from '../../../components/atoms/Text';
import { useMediaQuery } from '@mui/material';
import React from 'react';

type TableRow = { text: string; check: boolean } | string;

type Props = {
	tableColumns: {
		title: React.ReactNode;
		subtitle?: string;
	}[];
	tableRows: TableRow[][];
};

const PlanBenefits: React.FC<Props> = ({ tableColumns, tableRows }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	if (isMobile && !tableRows?.[0]?.[2])
		return (
			<Container sx='p-3'>
				<Text
					size='body-3'
					weight='bold'
					sx='mt-2'
				>
					Beneficios
				</Text>
				<hr className='my-3' />
				<div className='flex flex-col mt-2'>
					{tableRows?.map((row, index) => (
						<div
							key={`row-${index}`}
							className={`py-3 flex items-center gap-3 ${
								index === tableRows.length - 1
									? ''
									: 'border-b border-b-h-neutral-90'
							}`}
						>
							<Check
								color='var(--h-primary)'
								weight='bold'
								width={12}
								height={12}
								className='mt-1 min-w-3'
							/>
							{typeof row?.[0] === 'string' && (
								<Text
									size='mini-1'
									weight='light'
								>
									{row?.[0]}
								</Text>
							)}
						</div>
					))}
				</div>
			</Container>
		);

	if (isMobile)
		return (
			<Container sx='p-3'>
				<Text
					size='body-3'
					weight='bold'
					sx='mt-2'
				>
					Beneficios
				</Text>
				<hr className='my-2' />
				<div className='flex flex-col gap-2'>
					{tableRows?.map((row, index) => (
						<div
							key={`row-${index}`}
							className={`py-2 ${
								index === tableRows.length - 1
									? ''
									: 'border-b border-b-h-neutral-90'
							}`}
						>
							{typeof row?.[0] === 'string' && (
								<Text size='caption'>{row?.[0]}</Text>
							)}
							<div className='grid grid-cols-2 mt-2'>
								{typeof row?.[1] !== 'string' && (
									<div className='flex gap-2'>
										<Check
											color='var(--h-primary)'
											weight='bold'
											width={12}
											height={12}
											className='mt-1'
										/>
										<div>
											<Text
												size='caption'
												weight='bold'
											>
												{tableColumns?.[1]?.title}
											</Text>
											<Text
												weight='light'
												size='mini-1'
											>
												{row?.[1]?.text}
											</Text>
										</div>
									</div>
								)}
								{typeof row?.[2] !== 'string' && (
									<div className='flex gap-2'>
										<Check
											color='var(--h-primary)'
											weight='bold'
											width={12}
											height={12}
											className='mt-1'
										/>
										<div>
											<Text
												size='caption'
												weight='bold'
											>
												{tableColumns?.[2]?.title}
											</Text>
											<Text
												weight='light'
												size='mini-1'
											>
												{row?.[2]?.text}
											</Text>
										</div>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</Container>
		);

	return (
		<Container sx='flex'>
			<table width={'100%'}>
				<thead>
					<tr className=''>
						{tableColumns.map((column, index) => (
							<th
								className={`py-4 px-3 border-b border-b-h-neutral-90 text-nowrap ${column?.title === 'Beneficios' ? 'text-left' : ''}`}
								key={`column-${index}`}
							>
								<Text
									size='body-2'
									weight='bold'
									sx='!block'
								>
									{column.title}
								</Text>
								<Text
									size='caption'
									sx='!block !font-light'
								>
									{column.subtitle}
								</Text>
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					{tableRows?.map((row, index) => (
						<tr
							key={`row-${index}`}
							className={
								index === tableRows.length - 1
									? ''
									: 'border-b border-b-h-neutral-90'
							}
						>
							{row?.map((cell, index) => (
								<td
									className='py-4'
									key={`cell-${index}`}
								>
									{typeof cell === 'string' ? (
										<Text
											size='caption'
											weight='light'
											sx='!block'
										>
											{cell}
										</Text>
									) : (
										<div className='flex flex-col items-center gap-1'>
											{cell?.check && (
												<Check
													color='var(--h-primary)'
													weight='bold'
													width={cell?.text ? 18 : 24}
													height={cell?.text ? 18 : 24}
												/>
											)}
											{!cell?.check && (
												<XCircle
													color='var(--h-primary)'
													weight='bold'
													width={cell?.text ? 20 : 24}
													height={cell?.text ? 20 : 24}
												/>
											)}
											{cell?.text && (
												<Text
													size='mini-1'
													weight='light'
													sx='!block text-center'
												>
													{cell?.text}
												</Text>
											)}
										</div>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</Container>
	);
};

export default PlanBenefits;
