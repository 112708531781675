import {
	CaretDown,
	CheckCircle,
	CurrencyDollarSimple,
	HourglassMedium,
	Warning,
} from '@phosphor-icons/react';
import Text, { TTextColor } from '../../../../components/atoms/Text';
import { useState } from 'react';

type TStatus = 'warning' | 'alert' | 'success' | 'pending' | 'processing';

interface Props {
	title: string | number;
	subtitle: string;
	status: TStatus;
	quantity: number;
	children: React.ReactNode;
	isOpenByDefault?: boolean;
	className?: string;
}

const DropdownDeclarations: React.FC<Props> = ({
	title,
	subtitle,
	status,
	quantity,
	children,
	isOpenByDefault = true,
	className,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(isOpenByDefault);

	const handleOpenDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const quantityTextStyle: Record<string, TTextColor> = {
		warning: 'white',
		success: 'green',
		alert: 'red',
	};

	return (
		<div className={className}>
			<div
				className={`bg-h-gray-20/50 p-2 rounded-tr-lg rounded-tl-lg flex gap-2 cursor-pointer border-b border-h-gray-30 items-center ${
					status === 'warning' ? 'bg-h-red-50 ' : 'border-green'
				}`}
				onClick={handleOpenDropdown}
			>
				<Text
					size='body-3'
					weight='bold'
					color='medium'
					sx={`whitespace-nowrap w-full lg:w-fit ${
						status === 'warning' ? 'text-white' : ''
					}`}
				>
					{title}
				</Text>
				<Text
					size='mini-1'
					weight='regular'
					color='light'
					sx={`w-full hidden lg:block ${
						status === 'warning' ? 'text-white' : ''
					}`}
				>
					{subtitle}
				</Text>
				<div className='flex items-center gap-1'>
					{(status === 'warning' || status === 'alert') && (
						<Warning
							weight='fill'
							color={status === 'warning' ? 'white' : 'var(--h-red-50)'}
							size={18}
							className='min-w-[18px]'
						/>
					)}
					{status === 'success' && (
						<CheckCircle
							weight='fill'
							color='var(--h-green-50)'
							size={18}
							className='min-w-[18px]'
						/>
					)}
					{status === 'pending' && (
						<CurrencyDollarSimple
							weight='bold'
							color='var(--h-neutral-20)'
							size={18}
							className='min-w-[18px]'
						/>
					)}
					{status === 'processing' && (
						<HourglassMedium
							weight='fill'
							color='var(--h-neutral-20)'
							size={18}
							className='min-w-[18px]'
						/>
					)}
					<Text
						size='mini-2'
						weight='regular'
						color={quantityTextStyle[status] || 'dark'}
						sx='w-full'
					>
						({quantity})
					</Text>
				</div>
				<CaretDown
					size={18}
					weight='bold'
					color={status === 'warning' ? 'white' : 'var(--h-gray-50)'}
					className={`transition-all duration-300 transform ${
						isDropdownOpen ? 'rotate-180' : ''
					}`}
				/>
			</div>
			<div
				className={`overflow-hidden h-0 transition-all duration-300 ${
					isDropdownOpen ? 'h-fit' : ''
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default DropdownDeclarations;
