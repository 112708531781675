import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomIcon from '../../atoms/CustomIcon';
import ROUTES from '../../../shared/constants/routes';
import { Bell, ShoppingCart } from '@phosphor-icons/react';

interface TopbarProps {
	numbersOfItems: number;
}

const Topbar: React.FC<TopbarProps> = ({ numbersOfItems }) => {
	const navigate = useNavigate();

	return (
		<section className='sticky -top-1 z-50 bg-white w-full flex border-b items-center py-1'>
			<CustomIcon
				sx='ml-4 md:hidden'
				color={'var(--h-primary)'}
				name={'heru-logo'}
				width={60}
				onClick={() => navigate(ROUTES.ROOT)}
			/>
			<div className='flex w-full justify-end py-2.5'>
				<button onClick={() => navigate(ROUTES.CHECKOUT)}>
					{numbersOfItems > 0 && (
						<div className='bg-h-red-50 w-[18px] h-[18px] rounded-full absolute top-1'>
							<span className='text-[10px] absolute top-[2px] left-[6px] text-white'>
								{numbersOfItems}
							</span>
						</div>
					)}
					<ShoppingCart
						size={26}
						color={'var(--h-blue-70)'}
						weight='light'
						className='mr-3'
					/>
				</button>
				<button>
					<Bell
						size={26}
						color={'var(--h-blue-70)'}
						weight='light'
						className='mr-4'
					/>
				</button>
			</div>
		</section>
	);
};

export default Topbar;
