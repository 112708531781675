import Text from '../../../../../components/atoms/Text';

interface Props {
	beforePrice: string | number;
	price: number | string;
	newPriceText?: string;
}

const PromoPrice = ({ beforePrice, price, newPriceText }: Props) => {
	return (
		<div className='flex justify-between w-full py-4 my-2'>
			<div className='inline-flex gap-x-2'>
				<div>
					<Text
						size='label'
						color='gray'
						sx='!leading-none'
						weight='medium'
					>
						Precio
					</Text>
					<Text
						size='label'
						color='gray'
						weight='medium'
					>
						regular
					</Text>
				</div>
				<Text
					size='subtitle'
					color='gray'
					weight='bold'
					sx='line-through !text-[24px]'
				>
					{beforePrice}
				</Text>
			</div>

			<div className='inline-flex gap-x-2'>
				<Text
					size='label'
					weight='medium'
					sx='!text-h-blue-60 w-24 text-center !leading-5'
				>
					{newPriceText ?? 'Por tiempo limitado'}
				</Text>
				<Text
					sx='!text-[48px] !text-h-blue-60 !gap-0 '
					weight='medium'
				>
					<span className='text-[24px]'>$</span>
					{price}
				</Text>
			</div>
		</div>
	);
};

export default PromoPrice;
