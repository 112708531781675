import Lottie from 'lottie-react';
import CustomIcon from '../../../components/atoms/CustomIcon';
import Text from '../../../components/atoms/Text';
import Heading from '../../../components/atoms/Heading';
import { ArrowRight } from '@phosphor-icons/react';
import Button from '../../../components/atoms/Button';
import React from 'react';
import successAnim from '../../../assets/animations/success.anim.json';
import Slider from 'react-slick';
import OnboardingCarouselImg1 from '../../../assets/img/svg/onboarding-carousel-1.svg';
import OnboardingCarouselImg2 from '../../../assets/img/svg/onboarding-carousel-2.svg';
import OnboardingCarouselImg3 from '../../../assets/img/svg/onboarding-carousel-3.svg';
import OnboardingCarouselImg4 from '../../../assets/img/svg/onboarding-carousel-4.svg';

type Props = {
	handleNextStep: () => void;
};

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
};

const carouselSlides = [
	{
		text: 'Con Heru puedes presentar tus declaraciones mensuales con tu suscripción',
		img: OnboardingCarouselImg1,
	},
	{
		text: 'Presentamos tus declaraciones anuales ¡aunque estén atrasadas!',
		img: OnboardingCarouselImg2,
	},
	{
		text: 'Armamos tu perfil fiscal para que consultes y compartas tus datos',
		img: OnboardingCarouselImg3,
	},
	{
		text: 'Emite tus facturas fácilmente y en menos de 5 minutos ¡totalmente GRATIS!',
		img: OnboardingCarouselImg4,
	},
];

const OnboardingCarousel: React.FC<Props> = ({ handleNextStep }) => {
	return (
		<div className='h-full flex justify-center items-center z-10 relative'>
			<div className='flex flex-col items-center justify-center w-fit h-fit'>
				<CustomIcon
					name='heru-logo'
					color='var(--h-primary)'
					sx='mx-auto w-fit mb-6 mt-1'
				/>
				<div className='flex items-center gap-2 w-fit mx-auto text-center max-w-[380px] mb-2'>
					<Heading
						size='xs'
						weight='medium'
						responsive
					>
						¡Completaste tu registro!
					</Heading>
					<div className='w-6 h-6 flex justify-center items-center overflow-hidden rounded-full mx-auto'>
						<Lottie
							animationData={successAnim}
							loop={false}
							className='min-w-[32px] min-h-[32px] mx-auto'
						/>
					</div>
				</div>
				<div className='w-[300px]'>
					<Slider {...settings}>
						{carouselSlides.map((slide, index) => (
							<div key={`slide-index-${index}`}>
								<Text
									size='caption'
									weight='light'
									sx='text-center mb-5'
								>
									{slide.text}
								</Text>
								<img
									src={slide.img}
									alt='OnboardingCarouselImg1'
									className='w-full h-auto mx-auto mb-2'
								/>
							</div>
						))}
					</Slider>
				</div>
				<div className='px-2 sm:bottom-6 w-full '>
					<Button
						label='Comenzar'
						onClick={() => handleNextStep()}
						sx=' w-[320px] mx-auto mt-12 z-10 relative'
						icon={<ArrowRight size={18} />}
					/>
				</div>
			</div>
		</div>
	);
};

export default OnboardingCarousel;
