import React from 'react';
import { CheckCircle, ShoppingCart } from '@phosphor-icons/react';
import Text from '../../../../../components/atoms/Text';
import Heading from '../../../../../components/atoms/Heading';
import Button from '../../../../../components/atoms/Button';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../../../shared/constants/events';
import PromoPrice from '../DefaultCard/PromoPrice';
import useCleverTapEvent from '../../../../../hooks/useClevertapEvent';
import hotSaleImg from '../../../../../assets/img/svg/hot_sale.svg';
import hot50Img from '../../../../../assets/img/svg/hot50.svg';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface ProductCardProps {
	id: string;
	description: string;
	title: string;
	benefits: string[];
	price: number;
	periodicity?: boolean;
	textButton: string;
	sx?: string;
	onPress?: () => void;
	loading: boolean;
	beforePrice?: string | number;
	newPriceText?: string;
}

const AsatCard: React.FC<ProductCardProps> = ({
	id,
	description,
	title,
	benefits,
	price,
	textButton,
	sx,
	onPress,
	loading,
	beforePrice,
	newPriceText,
}) => {
	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const logClevertapEvent = useCleverTapEvent();

	const handleClick = () => {
		onPress?.();
		logClevertapEvent(ANALYTICS_EVENTS.PLANS, {
			event_type: EVENT_TYPES.PLANS_SELECT,
			product_name: `${description} ${title}`,
		});
	};

	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<div
			className={`w-full md:w-[430px] sm:w-[490px] bg-white rounded-lg border border-blue-100 flex flex-col justify-between ${sx}`}
		>
			<div
				className={`relative w-full ${
					isHotSale
						? 'bg-h-hot-sale'
						: id === 'asat-plus'
							? 'bg-h-primary'
							: 'bg-h-blue-60'
				} rounded-t-lg flex flex-col items-center justify-center p-6 pb-4`}
			>
				{isHotSale && (
					<div className='mb-10'>
						<div className='absolute left-0 -top-16 flex items-center justify-center'>
							<img
								src={hotSaleImg}
								alt='hot sale heru'
								className='w-56 h-56'
							/>
						</div>
					</div>
				)}
				<Heading
					size='s'
					color={
						isHotSale
							? 'white'
							: id === 'asat-plus'
								? 'white'
								: 'ultraBlueLight'
					}
					sx={`text-center max-w-[320px]`}
				>
					{description}
				</Heading>
				<div className='md:w-full sm:w-[400px] w-full h-[0px] border border-blue-200 mt-4 mb-2'></div>
				<Heading
					size='m'
					weight='bold'
					color={
						isHotSale
							? 'white'
							: id === 'asat-plus'
								? 'white'
								: 'ultraBlueLight'
					}
					sx='text-center'
				>
					{title}
				</Heading>
			</div>

			{id === 'asat-plus' ? (
				<div className='relative flex flex-col px-6 py-8'>
					{isHotSale && (
						<div className='mb-8'>
							<div className='absolute right-0 -top-14 flex items-center justify-center'>
								<img
									src={hot50Img}
									alt='hot sale heru'
									className='w-32 h-32'
								/>
							</div>
						</div>
					)}
					{benefits.map((benefit, index) => (
						<div
							key={`benefit-${index}`}
							className='flex gap-2.5 mb-4'
						>
							<CheckCircle
								size={24}
								weight='light'
								color='var(--h-primary)'
							/>
							<div className='w-full'>
								<Text
									size='s'
									color='dark'
								>
									{benefit}
								</Text>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className='flex flex-col px-6 py-8 md:-mt-28'>
					{benefits.map((benefit, index) => (
						<div
							key={`benefit-${index}`}
							className='flex gap-2.5 mb-4'
						>
							<CheckCircle
								size={24}
								weight='light'
								color='var(--h-primary)'
							/>
							<div className='w-full'>
								<Text
									size='s'
									color='dark'
								>
									{benefit}
								</Text>
							</div>
						</div>
					))}
				</div>
			)}

			<div className='mb-1 px-6 pb-5'>
				{isHotSale ? (
					<div className='flex items-center justify-between'>
						<div className='flex items-center'>
							<Text
								color='gray'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 mr-8 text-center'
							>
								Precio regular
							</Text>
							<Text
								color='gray'
								size={isMobile ? 'label' : 'body-1'}
								sx='line-through'
							>
								$500
							</Text>
						</div>
						<div className='flex items-center'>
							<Text
								color='darkMenu'
								weight='bold'
								size={isMobile ? 's' : 'label'}
								sx='max-w-10 mr-4 md:mr-8 text-center'
							>
								HOT HERU
							</Text>
							<Heading
								color='darkMenu'
								size={isMobile ? 'm' : 'l'}
							>
								$350
							</Heading>
						</div>
					</div>
				) : (
					<>
						{beforePrice ? (
							<PromoPrice
								beforePrice={beforePrice}
								price={price}
								newPriceText={newPriceText}
							/>
						) : (
							<div className='flex flex-row items-center justify-center'>
								<Heading
									size='xs'
									weight='bold'
								>
									{'$'}
								</Heading>
								<Heading
									size='xl'
									weight='bold'
								>
									{price}
								</Heading>
							</div>
						)}
					</>
				)}

				<div className='w-full mt-5'>
					{id === 'asat-plus' ? (
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={loading}
							label={textButton}
							onClick={handleClick}
							className={`${isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					) : (
						<Button
							beforeIcon={
								<ShoppingCart
									size={24}
									weight='fill'
									color={isHotSale ? 'var(--h-primary)' : 'white'}
									className='mr-2'
								/>
							}
							loading={loading}
							label={textButton}
							onClick={handleClick}
							className={`${isHotSale ? 'bg-h-blue-20' : 'bg-h-primary'} rounded-lg ${isHotSale ? 'text-h-primary' : 'text-white'} font-medium w-full flex items-center justify-center py-3`}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default AsatCard;
