import React, { useState } from 'react';
import Text from '../../atoms/Text';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import clevertap from 'clevertap-web-sdk';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
interface Props {
	title?: string;
	children?: React.ReactNode;
	href?: string;
	showButton?: boolean;
	sx?: string;
	headerButton?: React.ReactNode;
}

const HomeCard: React.FC<Props> = ({
	title,
	children,
	showButton,
	href,
	sx,
	headerButton,
}) => {
	const [isHover, setIsHover] = useState(false);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const logClevertapEvent = useCleverTapEvent();

	const logCTEvent = () => {
		if (!title) return;
		const source = title.toLowerCase().replaceAll(' ', '_');
		if (userProfile) {
			clevertap.event.push(ANALYTICS_EVENTS.HOME_LINK);
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_LINK_INTERACTION,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
				source,
			});
		}
	};
	return (
		<section
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			className={`flex flex-col px-4 pt-4 md:px-6 md:pt-6 pb-4 bg-white rounded-lg relative ${sx}`}
		>
			<div
				className={`${isHover && showButton ? 'opacity-25' : 'opacity-100'}`}
			>
				<div className='flex flex-row justify-between items-center mb-4'>
					{title && (
						<>
							<Text
								size='body-2'
								weight='bold'
								sx='hidden lg:block'
							>
								{title}
							</Text>
							<Text
								size='body-3'
								weight='bold'
								sx='block lg:hidden'
							>
								{title}
							</Text>
						</>
					)}
					<>{headerButton}</>
				</div>
				<div className='border border-b-h-gray-20 mb-4' />

				<>{children}</>
			</div>
			{showButton && isHover && (
				<a
					onClick={logCTEvent}
					href={href}
					className='bg-h-primary hover:bg-h-blue-50-hover rounded-lg border text-white text-center w-[284px] px-8 py-6 h-max left-1/2 top-1/2 translate-y-[-50%] translate-x-[-50%] absolute m-0'
				>
					Debes vincularte para descargar o compartir
				</a>
			)}
		</section>
	);
};

export default React.memo(HomeCard);
