import { GrowthBook } from '@growthbook/growthbook-react';
import { postAssignExperiments } from '../../services/app.service';
import { isLocalStorageEnabled } from '../utils/general.util';

const growthbookFile = new GrowthBook({
	apiHost: 'https://cdn.growthbook.io',
	clientKey: import.meta.env.VITE_GROWTHBOOK_KEY,
	subscribeToChanges: true,
	enableDevMode: true,
	trackingCallback: (experiment, result) => {
		const assign = async () => {
			try {
				await postAssignExperiments(experiment.key, result.key);
			} catch (err) {}
		};
		if (isLocalStorageEnabled()) {
			assign();
		}
	},
});

export default growthbookFile;
