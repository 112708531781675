import HERU_API from '../shared/constants/heruApi';
import { get, post } from '../shared/utils/FetchSSL.util';

const getUserConekta = async (tokenSession?: string) => {
	try {
		const response = await get(`${HERU_API.CREATE_CUSTOMER}?platform_id=1`, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const postUserConekta = async (body: object, tokenSession?: string) => {
	try {
		const response = await post(`${HERU_API.CREATE_CUSTOMER}`, body, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export { getUserConekta, postUserConekta };
