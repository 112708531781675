import React, { useMemo } from 'react';
import { CaretCircleLeft } from '@phosphor-icons/react';

import { ListProducts } from '../../../../@types/Products.type';
import Annual from '../../ProductsLogic/Annual';
import HeruPlus from '../../ProductsLogic/HeruPlus';
import Text from '../../../../components/atoms/Text';
import Regularization from '../../ProductsLogic/Regularization';
import BasicAccounting from '../../ProductsLogic/BasicAccounting';
import { PeriodsSelectedType } from '../../../../@types/Calendar.type';
import LinkedBasicAccounting from '../../ProductsLogic/LinkedBasicAccounting';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { REGIMES_CODE } from '../../../../shared/constants/regimes';
import { CATEGORY_SINGLE } from '../../data/ecommerceData';
interface MobileSelectionProps {
	setStep: React.Dispatch<React.SetStateAction<number>>;
	products: ListProducts[];
	id: string;
	handleAddToCart?: (id: number, valuePeriods?: PeriodsSelectedType[]) => void;
	handleAddProduct: (id: number, additionalInformation: Object) => void;
	isLoadingAddProduct: boolean;
}

const MobileSelection: React.FC<MobileSelectionProps> = ({
	setStep,
	products,
	id,
	handleAddToCart,
	handleAddProduct,
	isLoadingAddProduct
}) => {
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const sysAsUniqueRegime = useMemo(() => {
		return (
			taxPayer?.status?.tax_regimes?.length === 1 &&
			taxPayer?.status?.tax_regimes?.[0].code === REGIMES_CODE.SUELDOS_Y_SALARIOS
		);
	}, [taxPayer]);

	return (
		<div className='w-full h-screen flex flex-col'>
			<div className='flex flex-row items-center mb-6'>
				<button onClick={() => setStep(0)}>
					<CaretCircleLeft
						size={20}
						weight='fill'
						className='mr-3'
						color='var(--h-primary)'
					/>
				</button>
				<Text
					size='l'
					color='dark'
					weight='bold'
				>
					Planes y precios
				</Text>
			</div>

			<div className='flex-grow overflow-y-auto'>
				{id === CATEGORY_SINGLE.HERU_PLUS && (
					<HeruPlus
						products={products}
						isMobile
						handleAddToCart={handleAddToCart!}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				)}

				{id === CATEGORY_SINGLE.BASIC_ACCOUNTING && (
					<>
						{taxPayer && !sysAsUniqueRegime ? (
							<LinkedBasicAccounting
								products={products}
								isMobile
								taxPayer={taxPayer}
								handleAddProduct={handleAddProduct}
								isLoadingAddProduct={isLoadingAddProduct}
							/>
						) : (
							<BasicAccounting
								products={products}
								isMobile
								handleAddProduct={handleAddProduct}
								isLoadingAddProduct={isLoadingAddProduct}
							/>
						)}
					</>
				)}

				{id === CATEGORY_SINGLE.ANNUAL && (
					<Annual
						products={products}
						isMobile
						handleAddProduct={handleAddProduct}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				)}

				{id === CATEGORY_SINGLE.REGULARIZATION && (
					<Regularization
						productName='Declaraciones Atrasadas'
						description='Ponte al día declarando tus periodos pendientes, evita multas, sanciones o recargos del SAT.'
						products={products}
						isMobile
						handleAddToCart={handleAddToCart!}
						isLoadingAddProduct={isLoadingAddProduct}
					/>
				)}
			</div>
		</div>
	);
};

export default React.memo(MobileSelection);
