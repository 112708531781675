interface Props {
	label: string;
	sx?: string;
}

const Tag = ({ label, sx }: Props) => {
	return (
		<span
			className={
				 `bg-h-blue-20/60 rounded-lg px-3 py-1 text-h-primary font-normal ${sx}`
			}
		>
			{label}
		</span>
	);
};

export default Tag;
