import {
	Control,
	FieldErrors,
	UseFieldArrayAppend,
	UseFieldArrayRemove,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
} from 'react-hook-form';
import Text from '../../../components/atoms/Text';
import { CheckCircle, MinusCircle, PlusCircle } from '@phosphor-icons/react';
import { InvoicingFormBody } from '../newInvoiceFlow';
import InvoiceFormField from '../molecules/InvoiceFormField';

type Props = {
	control: Control<InvoicingFormBody, any>;
	formErrors: FieldErrors<InvoicingFormBody>;
	fields: Record<'id', string>[];
	setValue: UseFormSetValue<InvoicingFormBody>;
	trigger: UseFormTrigger<InvoicingFormBody>;
	watch: UseFormWatch<InvoicingFormBody>;
	remove: UseFieldArrayRemove;
	append: UseFieldArrayAppend<InvoicingFormBody, 'emails'>;
};

const InvoiceStep4: React.FC<Props> = ({
	control,
	fields,
	append,
	watch,
	remove,
	formErrors,
	trigger,
}) => {
	const handleAddEmail = async () => {
		await trigger('emails');
		if (formErrors?.emails || fields.length > 4) return;
		append({ email: '' });
	};

	return (
		<div className='mt-6 sm:mt-14 flex flex-col items-center justify-center pb-6'>
			<CheckCircle
				size={64}
				color='var(--h-primary)'
				className='mb-4'
			/>
			<Text
				size='body-3'
				weight='bold'
			>
				¡Estás a un paso de finalizar!
			</Text>
			<Text sx='mt-1 !block text-center'>
				Ingresa el correo a donde quieres que enviemos la factura
			</Text>
			<div className='mt-6 w-full max-w-96 flex flex-col gap-4'>
				{fields.map((field, index) => (
					<div
						key={`field-${field.id}`}
						className='flex items-start gap-2 w-full '
					>
						<InvoiceFormField
							control={control}
							label='Ingresa un email'
							showLabel={false}
							fieldName={`emails.${index}.email`}
							formErrors={formErrors}
							customError={formErrors?.emails?.[index]?.email}
							type='input-email'
							watch={watch}
						/>
						{index > 0 && (
							<div
								className='cursor-pointer hover:bg-h-error-90 p-2 rounded-lg'
								onClick={() => remove(index)}
							>
								<MinusCircle
									size={24}
									color='var(--h-error-50)'
								/>
							</div>
						)}
					</div>
				))}
			</div>
			<button
				type='button'
				onClick={handleAddEmail}
				className='w-fit flex items-center gap-2 text-h-primary transition hover:bg-h-primary-90/50 py-1 px-2 rounded-md mt-2'
			>
				<PlusCircle weight='bold' /> Agregar otro correo
			</button>
		</div>
	);
};

export default InvoiceStep4;
