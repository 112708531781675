import React, { useEffect, useMemo, useReducer } from 'react';
import { DELETE_IDS, Order } from '../../../../../@types/Order.type';
import { formatPrice } from '../../../../../shared/utils/general.util';
import { ShoppingCart } from '@phosphor-icons/react';
import Text from '../../../../../components/atoms/Text';
import { CircularProgress } from '@mui/material';
import Button from '../../../../../components/atoms/Button';
import { regularizationsPT } from '../../../../../shared/constants/SlugsProducts';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../../../shared/constants/events';
import ROUTES from '../../../../../shared/constants/routes';
import { logClevertapEvent } from '../../../../../shared/analytics/analytics';
import { useNavigate } from 'react-router-dom';
import Divider from '../../../../../components/atoms/Divider';
import ProductItem from '../../molecules/ProductItem';
import { CategoryName } from '../../../../../@types/Products.type';
import {
	initialRegularizationRegimesProd,
	regularizationRegimesProdsReducer,
} from '../../../../../reducers/regularizationRegimes/regularizationRegimesReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface OrderSummaryProps {
	order: Order;
	isLoad: boolean;
	isLoadDeleteItem: boolean;
	handleItemDelete: (itemId: number) => void; // Función para manejar la eliminación del ítem
	onDeleteCartItems: (items: DELETE_IDS[]) => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
	order,
	isLoad,
	isLoadDeleteItem,
	handleItemDelete,
	onDeleteCartItems,
}) => {

	const { isHotSale } = useSelector((state: RootState) => state.profile);

	const navigate = useNavigate();

	const productsWithoutRegs = useMemo(() => {
		if (order?.items) {
			return order?.items.filter(
				(item) =>
					item.product_version.product.category !==
					CategoryName.DeclaraciónAtrasada,
			);
		}
		return [];
	}, [order]);

	const [regularizationProds, dispatchRegu] = useReducer(
		regularizationRegimesProdsReducer,
		initialRegularizationRegimesProd,
	);
	const allRegularizationProducts = useMemo(
		() => [
			regularizationProds.regularizationsPTDeliver,
			regularizationProds.regularizationsPTDriver,
			regularizationProds.regularizationsPTHost,
			regularizationProds.regularizationsPTSeller,
			regularizationProds.regularizationsPTFleetPartner,
			regularizationProds.regularizationsPTFull,
			regularizationProds.regularizationsRESICOPlus,
			regularizationProds.regularizationsRESICOExpress,
			regularizationProds.regularizationsArriendosMonth,
			regularizationProds.regularizationsArriendosTrimester,
			regularizationProds.regularizationsRIF,
			regularizationProds.regularizationsActEmp,
		],
		[regularizationProds],
	);

	const getRegularizationRegimesProducts = () => {
		regularizationsPT.forEach((regu) => {
			const regProds =
				order?.items?.filter(
					(product) => product?.product_version?.product.slug === regu.slug,
				) ?? [];

			if (regProds.length > 0) {
				dispatchRegu({ type: regu.type, payload: regProds });
			} else {
				dispatchRegu({ type: regu.type, payload: [] });
			}
		});
	};

	useEffect(() => {
		getRegularizationRegimesProducts();
	}, [order?.items]);

	const handleAddOtherProduct = () => {
		navigate(ROUTES.PLANS_AND_PRICES);
		logClevertapEvent(ANALYTICS_EVENTS.CHECKOUT, {
			event_type: EVENT_TYPES.CHECKOUT_ADD_EXTRA_PRODUCT,
		});
	};

	return (
		<>
			{isLoad ? (
				<div className='flex justify-center m-2'>
					<CircularProgress size={30} />
				</div>
			) : (
				<div className='w-full'>
					<div
						className={`sm:h-full md:overflow-auto ${
							order?.items?.length > 3
								? 'lg:max-h-[464px]'
								: order?.items?.length === 0
								? 'hidden'
								: 'h-auto'
						}`}
					>
						<ul>
							{productsWithoutRegs.map((item, index) => (
								<li
									key={`${item.id}_${index}`}
									className='mb-6'
								>
									<ProductItem
										id={item.id}
										description={item?.product_version?.description}
										basePrice={item?.base_price}
										discount={item?.discount}
										category={item?.product_version?.product?.category ?? ''}
										periodicity={item?.product_version?.periodicity}
										isLoadDeleteItem={isLoadDeleteItem}
										handleItemDelete={handleItemDelete}
										additionalInformation={item?.additional_information}
										slug={item?.product_version?.product?.slug}
									/>
								</li>
							))}
							{!!allRegularizationProducts.length &&
								allRegularizationProducts.map((reguRegimen, index) => {
									if (reguRegimen.length === 0) {
										return <div key={`view-${index}`} />;
									}
									return (
										<li key={`cart-item-${reguRegimen[0].id}`}>
											<ProductItem
												id={reguRegimen[0].id}
												description={
													reguRegimen[0]?.product_version?.description
												}
												basePrice={reguRegimen[0]?.base_price}
												discount={reguRegimen[0]?.discount}
												category={
													reguRegimen[0]?.product_version?.product?.category ??
													''
												}
												isLoadDeleteItem={isLoadDeleteItem}
												handleItemDelete={handleItemDelete}
												additionalInformation={
													reguRegimen[0]?.additional_information
												}
												slug={reguRegimen[0]?.product_version?.product?.slug}
												regularizationProducts={reguRegimen}
												onDeleteCartItems={onDeleteCartItems}
											/>
										</li>
									);
								})}
						</ul>
						<div className='flex justify-end items-center my-6'>
							<Button
								label='Agregar otro plan'
								beforeIcon={
									<ShoppingCart
										size={18}
										weight='light'
									/>
								}
								variant='outline'
								sx='lg:!w-[217px]'
								onClick={handleAddOtherProduct}
							/>
						</div>
					</div>
					<div className='w-full p-6 bg-white rounded-lg shadow flex-col gap-4 inline-flex'>
						<div className='flex items-center justify-between'>
							<Text
								size='l'
								color='gray'
							>
								Subtotal
							</Text>

							<Text
								size='l'
								color='gray'
							>
								${formatPrice(order?.base_price ?? 0)}
							</Text>
						</div>

						<div className='flex items-center justify-between'>
							<Text
								size='l'
								color={isHotSale ? 'hotSale' : 'gray'}
								weight={isHotSale ? 'medium' : 'regular'}
							>
								{isHotSale ? 'HOT HERU 🔥' :'Descuento'}
							</Text>

							<Text
								size='l'
								color='gray'
							>
								- ${formatPrice(order?.discount ?? 0)}
							</Text>
						</div>

						<Divider />

						<div className='flex items-center justify-between'>
							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								Total
							</Text>

							<Text
								size='l'
								color='dark'
								weight='bold'
							>
								${formatPrice(order?.total ?? 0)}
							</Text>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default OrderSummary;
