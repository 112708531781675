import React from 'react';
import PrimaryButton from '../../../components/atoms/Button';

type Props = {
	buttonLabel: string;
	onClickButton: () => void;
	disableButton: boolean;
	isLoading?: boolean;
};

const PlanFlowButtons: React.FC<Props> = ({
	buttonLabel,
	onClickButton,
	disableButton,
	isLoading,
}) => {
	return (
		<div className='p-3 md:p-4 fixed md:relative bottom-14 md:bottom-auto left-0 md:left-auto w-full bg-white border-t md:border-none border-t-h-neutral-90'>
			<PrimaryButton
				disabled={disableButton}
				label={buttonLabel}
				onClick={onClickButton}
				loading={isLoading}
			/>
			{/* ==== TEMPORALY REMOVED === */}
			{/* <button
				type='button'
				className='w-full flex items-center gap-2 justify-center py-2 mt-1'
			>
				<Text color='primary'>
					<WhatsappLogo
						width={24}
						height={24}
					/>{' '}
					Requiero asesoría
				</Text>
			</button> */}
		</div>
	);
};

export default PlanFlowButtons;
