import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';


interface PasswordToggleProps {
	initialPassword: string;
	label: string;
}

const PasswordToggle: React.FC<PasswordToggleProps> = ({
	initialPassword,
	label,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowPassword(event.target.checked);
	};

	const password = initialPassword;

	return (
		<div className='flex flex-col'>
			<TextField 
				size='small'
				disabled
				id='outlined-disabled'
				label={label}
				value={showPassword ? password : '********'}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={showPassword}
						onChange={handleCheckboxChange}
					/>
				}
				label='Mostrar contraseña'
			/>
		</div>
	);
};

export default PasswordToggle;
