import images from "../constants/images";

const getBrandIcon = (brand: string) => {
	switch (brand) {
		case 'visa':
			return images.visaBrand;
		case 'mastercard':
			return images.masterBrand;
		case 'amex':
			return images.amexBrand;
		default:
			return images.visaBrand;
	}
};

const getBrandBg = (brand: string) => {
	switch (brand) {
		case 'visa':
			return images.visaBgBrand;
		case 'mastercard':
			return images.masterBgBrand;
		case 'amex':
			return images.amexBgBrand;
		default:
			return images.visa2Brand;
	}
};

const getCardType = (tyoe: string) => {
	switch (tyoe) {
		case 'debit':
			return 'débito';
		case 'credit':
			return 'crédito';
		default:
			return 'Crédito';
	}
}

const getBrandMockCards = (brand: string) => {
	switch (brand) {
		case 'visa':
			return images.visaWhite;
		case 'mastercard':
			return images.master2Brand;
		case 'amex':
			return images.amexBrand;
		default:
			return images.visaWhite;
	}
};

export {
  getBrandIcon,
	getBrandBg,
	getCardType,
	getBrandMockCards,
};
