export enum REGIMES_TYPE {
	PLATAFORMAS_TECNOLOGICAS = 'Plataformas Tecnológicas',
	SUELDOS_Y_SALARIOS = 'Sueldos y Salarios',
	RESICO = 'RESICO',
	ARRENDAMIENTO = 'Arrendamiento',
	ACTIVIDAD_EMPRESARIAL = 'Actividades Empresariales y Profesionales',
	RIF = 'RIF',
	OTRO = 'Otro',
}

export enum REGIMES_LONG_TYPE {
	PLATAFORMAS_TECNOLOGICAS = 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
	SUELDOS_Y_SALARIOS = 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
	RESICO = 'Régimen Simplificado de Confianza',
	ARRENDAMIENTO = 'Arrendamiento',
	ACTIVIDAD_EMPRESARIAL = 'Personas Físicas con Actividades Empresariales y Profesionales',
	RIF = 'Incorporación Fiscal',
}

export enum REGIMES_CODE {
	PLATAFORMAS_TECNOLOGICAS = '625',
	SUELDOS_Y_SALARIOS = '605',
	RESICO = '626',
	ARRENDAMIENTO = '606',
	ACTIVIDAD_EMPRESARIAL = '612',
	GENERAL_LEY_PERSONAS_MORALES = '601',
	PERSONAS_MORALES_CON_FINES_NO_LUCRATIVOS = '603',
	RESIDENTES_EN_EXTRAJERO_SIN_ESTABLECIMIENTO_EN_MEXICO = '610',
	RIF = '621',
}

export enum REGIMES_SHORT_NAME {
	PLATEC = 'platec',
	RESICO = 'resico',
	LEASE = 'lease',
	RIF = 'rif',
	BUSINESS = 'business',
}
