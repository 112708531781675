import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { Skeleton, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

//? Types
import {
	SubscriptionEvent,
	SubscriptionEventTypes,
	SubscriptionType,
} from '../../../../@types/Subscription';

//? Components
import Text from '../../../../components/atoms/Text';
import Modal from '../../../../components/molecules/Modal/Modal';
import CustomIcon from '../../../../components/atoms/CustomIcon';
import { ALERT_TYPE } from '../../../../components/molecules/Alert';

//? Services
import { getSubscriptionsEvents } from '../../../../services/suscription.service';

//? Redux
import { RootState } from '../../../../redux/store';
import { hideAlert, showAlert } from '../../../../redux/slices/alert.slice';

//? Utils
import { formatCurrency } from '../../../../shared/utils/general.util';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import dayjs from 'dayjs';
interface Props {
	showModal: {
		churn: boolean;
		history: boolean;
	};
	setShowModal: Dispatch<SetStateAction<{ churn: boolean; history: boolean }>>;
	activeSubscription: SubscriptionType;
}

const ModalHistory: React.FC<Props> = ({
	showModal,
	setShowModal,
	activeSubscription,
}) => {
	const [subscriptionEvents, setSubscriptionEvents] = useState<
		SubscriptionEvent[]
	>([]);
	const [isLoading, setIsLoading] = useState(false);

	const alert = useSelector((state: RootState) => state.alert);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const dispatch = useDispatch();

	const callSubscriptionEvents = useCallback(async () => {
		try {
			setIsLoading(true);
			const userInfo = await getSubscriptionsEvents(
				activeSubscription?.id,
				tokenSession,
			);
			setSubscriptionEvents(userInfo);
		} catch (error) {
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: 'Ocurrio un error obteniendo el historial de renovaciones',
				description: 'Intente de nuevo más tarde.',
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading(false);
		}
	}, [activeSubscription]);

	const handleCloseModal = () => {
		setShowModal({ ...showModal, history: false });
	};

	const getPaymentBrandIcon = (item: SubscriptionEvent) => {
		if (item.payment.payment_method === 'Card') {
			if (item.payment.payment_details.brand === 'visa') {
				return 'visa';
			} else if (item.payment.payment_details.brand === 'mastercard') {
				return 'mastercard';
			} else {
				return 'visa';
			}
		} else if (item.payment.payment_method === 'Cash') {
			return 'oxxo';
		} else if (item.payment.payment_method === 'SPEI') {
			return 'spei';
		}
	};

	useEffect(() => {
		if (activeSubscription) {
			callSubscriptionEvents();
		}
	}, [activeSubscription]);

	useEffect(() => {
		if (alert.showAlert) {
			setTimeout(() => {
				dispatch(hideAlert());
			}, alert.duration);
		}
	}, [alert]);

	const successRenovation = subscriptionEvents?.filter(
		(item) =>
			item?.successful &&
			(item?.event === SubscriptionEventTypes.SubscriptionRenewed ||
				item?.event === SubscriptionEventTypes.SubscriptionRenewAttempt ||
				item?.event === SubscriptionEventTypes.SubscriptionCreated),
	);

	const failedRenovation = subscriptionEvents?.filter(
		(item) =>
			!item?.successful &&
			(item?.event === SubscriptionEventTypes.SubscriptionRenewed ||
				item?.event === SubscriptionEventTypes.SubscriptionRenewAttempt),
	);

	const isMobile = useMediaQuery('(max-width: 768px)');

	const categories = ['Cobros exitosos', 'Cobros fallidos'];

	const [selectedCategory, setSelectedCategory] = useState(categories[0]);

	const handleChangeDektop = (item: string) => {
		setSelectedCategory(item);
	};

	return (
		<Modal
			open={showModal.history}
			onClose={handleCloseModal}
			sx='w-[94%] !max-w-[580px]'
		>
			<Text
				color='darkMenu'
				weight='medium'
				size='l'
			>
				Histórico de cobros
			</Text>
			<div className='mt-3 flex flex-col max-h-[370px] overflow-auto p-1'>
				{isLoading ? (
					[1, 2, 3].map(() => (
						<div className='shadow p-3 rounded-md mb-2'>
							<div className='flex gap-5 w-full'>
								<Skeleton className='w-full' />
								<Skeleton className='w-32' />
							</div>
							<div className='flex gap-5 w-full justify-between mt-1'>
								<Skeleton className='w-32' />
								<Skeleton className='w-16' />
							</div>
						</div>
					))
				) : (
					<>
						<div className='flex flex-row mb-6'>
							{categories?.map((item, index) => (
								<div
									key={`category-${index}`}
									className='mr-6'
								>
									<button onClick={() => handleChangeDektop?.(item)}>
										<Text
											size='m'
											color={selectedCategory === item ? 'primary' : 'dark'}
											weight={selectedCategory === item ? 'medium' : 'regular'}
										>
											{item}
										</Text>
									</button>
									{selectedCategory === item && (
										<div className='h-0.5 bg-h-primary w-full' />
									)}
								</div>
							))}
						</div>
						{selectedCategory === categories[0] && (
							<>
								{successRenovation?.map((item, index) => {
									const lastFour = item?.payment?.payment_details?.last_four;
									const total = item?.subscription?.shopping_item?.total ?? 0;
									const paymentMethod = item?.payment?.payment_method;
									const parsedDatetime = dayjs(item.datetime);
									const formattedDatetime = parsedDatetime.format('DD/MM/YYYY');
									const formattedTime = parsedDatetime.format('HH:mm:ss');

									return (
										<div
											className='shadow p-3 rounded-md mb-2'
											key={`subscription-key-${index}`}
										>
											<Text
												sx='flex justify-between'
												weight='bold'
												size={isMobile ? 's' : 'm'}
											>
												<span>
													{item?.event ===
													SubscriptionEventTypes.SubscriptionCreated
														? 'Compra de la suscripción'
														: 'Renovación'}
												</span>
												<span>{formatCurrency(total / 100)}</span>
											</Text>
											<div className='mt-1 flex justify-between'>
												<div className='flex items-center'>
													<Text
														size={isMobile ? 'mini-1' : 's'}
														color='gray'
														sx='!font-light mr-2'
													>
														{`Fecha: ${formattedDatetime} - Hora: ${formattedTime}`}
													</Text>
													{item?.successful ? (
														<CheckCircle
															size={18}
															weight='fill'
															color='var(--h-success-50)'
														/>
													) : (
														<XCircle
															size={18}
															weight='fill'
															color='var(--h-error-50)'
														/>
													)}
												</div>

												<div className='flex gap-2 items-center'>
													{paymentMethod && (
														<CustomIcon
															color={'var(--h-primary)'}
															name={getPaymentBrandIcon(item) ?? 'visa'}
															width={isMobile ? 22 : 32}
														/>
													)}
													{lastFour && (
														<Text
															color='gray'
															size={isMobile ? 'mini-1' : 's'}
															sx='!font-light'
														>
															•••• {lastFour}
														</Text>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</>
						)}
						{selectedCategory === categories[1] && (
							<>
								{failedRenovation?.length === 0 && (
									<Text
										size='label'
										color='gray'
									>
										No cuentas con cobros fallidos
									</Text>
								)}
								{failedRenovation?.map((item, index) => {
									const lastFour = item?.payment?.payment_details?.last_four;
									const total = item?.subscription?.shopping_item?.total ?? 0;
									const paymentMethod = item?.payment?.payment_method;
									const parsedDatetime = dayjs(item.datetime);
									const formattedDatetime = parsedDatetime.format('DD/MM/YYYY');
									const formattedTime = parsedDatetime.format('HH:mm:ss');

									return (
										<div
											className='shadow p-3 rounded-md mb-2'
											key={`subscription-key-${index}`}
										>
											<Text
												sx='flex justify-between'
												weight='bold'
												size={isMobile ? 's' : 'm'}
											>
												<span>Intento de renovación</span>
												<span>{formatCurrency(total / 100)}</span>
											</Text>
											<div className='mt-1 flex justify-between'>
												<div className='flex items-center'>
													<Text
														size={isMobile ? 'mini-1' : 's'}
														color='gray'
														sx='!font-light mr-2'
													>
														{`Fecha: ${formattedDatetime} - Hora: ${formattedTime}`}
													</Text>
													{item?.successful ? (
														<CheckCircle
															size={18}
															weight='fill'
															color='var(--h-success-50)'
														/>
													) : (
														<XCircle
															size={18}
															weight='fill'
															color='var(--h-error-50)'
														/>
													)}
												</div>

												<div className='flex gap-2 items-center'>
													{paymentMethod && (
														<CustomIcon
															color={'var(--h-primary)'}
															name={getPaymentBrandIcon(item) ?? 'visa'}
															width={isMobile ? 22 : 32}
														/>
													)}
													{lastFour && (
														<Text
															color='gray'
															size={isMobile ? 'mini-1' : 's'}
															sx='!font-light'
														>
															•••• {lastFour}
														</Text>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</>
						)}
					</>
				)}
			</div>
		</Modal>
	);
};

export default ModalHistory;
