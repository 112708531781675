import React, { useEffect, useState } from 'react';
import { ArrowRight } from '@phosphor-icons/react';
import { PendingProduct } from '../../../@types/Ecommerce';
import { useSelector } from 'react-redux';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import { logClevertapEvent } from '../../../shared/analytics/analytics';
import { RootState } from '../../../redux/store';
import {
	fetchPendingOrders,
	getPendingOrderDetail,
} from '../../../services/orders.service';
import { CircularProgress } from '@mui/material';
import ROUTES from '../../../shared/constants/routes';
import { useNavigate } from 'react-router-dom';
import HomeCard from '../../../components/molecules/HomeCard';
import Button from '../../../components/atoms/Button';
import ModalReference from './ModalReference';
import PendingCard from './PendingCard';

interface LastPendingOrderProps {
	isSingle?: boolean;
}

const LastPendingOrder: React.FC<LastPendingOrderProps> = ({
	isSingle = false,
}) => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const [lastPendingOrder, setLastPendingOrder] = useState<PendingProduct>();
	const [allPendingOrders, setAllPendingOrders] = useState(
		[] as PendingProduct[],
	);
	const [isLoadingPendingOrders, setIsLoadingPendingOrders] = useState(false);

	const getPendingOrder = async () => {
		try {
			setIsLoadingPendingOrders(true);
			const orders = await fetchPendingOrders(tokenSession);
			setAllPendingOrders(orders);
			if (orders?.length > 0) {
				const order = await getPendingOrderDetail(orders[0]?.id, tokenSession);
				setLastPendingOrder(order);
			}
		} catch (error: any) {
		} finally {
			setIsLoadingPendingOrders(false);
		}
	};

	useEffect(() => {
		getPendingOrder();
	}, []);

	const logReferencePaymentEvent = (
		action: 'copy_payment_number' | 'finalize_purchase',
	) => {
		if (userProfile) {
			logClevertapEvent(ANALYTICS_EVENTS.HOME_LINK, {
				event_type: EVENT_TYPES.HOME_PENDING_PURCHASE_INTERACTION,
				id: userProfile?.id,
				phone: userProfile?.cellphone,
				action,
			});
		}
	};

	const onOpenInstructions = () => {
		logReferencePaymentEvent('finalize_purchase');
		setShowModalPaymentSteps(true);
	};

	const [showModalPaymentSteps, setShowModalPaymentSteps] = useState(false);

	const navigate = useNavigate();

	return (
		<>
			{allPendingOrders.length > 0 && (
				<>
					<ModalReference
						paymentMethod={lastPendingOrder?.payment_method_name ?? ''}
						showModal={showModalPaymentSteps}
						setShowModal={setShowModalPaymentSteps}
						reference={lastPendingOrder?.payment_reference_code ?? ''}
						total={lastPendingOrder?.total ?? 0}
					/>
					<HomeCard
						title={`${allPendingOrders.length > 1 ? 'Pagos pendientes' : 'Pago pendiente'}`}
						showButton={false}
						headerButton={
							<>
								{allPendingOrders.length > 1 && (
									<Button
										onClick={() => {
											navigate(ROUTES.PENDING_REFERENCES);
										}}
										type='button'
										label={'Ver todas mis referencias'}
										variant='text'
										icon={<ArrowRight size={20} />}
										labelColor='primary'
									/>
								)}
							</>
						}
					>
						{isLoadingPendingOrders ? (
							<div className='flex items-center justify-center'>
								<CircularProgress size={25} />
							</div>
						) : (
							<>
								<PendingCard
									accountNumber={lastPendingOrder?.payment_reference_code ?? ''}
									paymentMethodName={
										lastPendingOrder?.payment_method_name ?? ''
									}
									dateLimit={lastPendingOrder?.expires_at ?? ''}
									total={lastPendingOrder?.total ?? 0}
									items={allPendingOrders[0]?.items ?? []}
									onPress={onOpenInstructions}
									isSingle={isSingle}
								/>
							</>
						)}
					</HomeCard>
				</>
			)}
		</>
	);
};

export default React.memo(LastPendingOrder);
