import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '../../shared/constants/routes';
import {
	getUberLoginConnect,
	patchUber,
	postOrganizationCredentials,
} from '../../services/organizations.service';
import { showAlert } from '../../redux/slices/alert.slice';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import { isLocalStorageEnabled } from '../../shared/utils/general.util';
import { RootState } from '../../redux/store';

const UberFlow = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const logClevertapEvent = useCleverTapEvent();

	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState(false);

	const selecOrgUber = isLocalStorageEnabled()
		? localStorage.getItem('selectedOrg')
		: '';

	const codeUber = searchParams.get('code');
	const stateUber = searchParams.get('state');

	const alertSuccess = {
		type: ALERT_TYPE.SUCCESS,
		title: 'Aviso',
		description: `Uber vinculado correctamente.`,
	};

	const alertError = {
		type: ALERT_TYPE.ERROR,
		title: 'Aviso',
		description: `Error al vincular Uber, intenta de nuevo.`,
	};

	const fetchUrlUber = async () => {
		try {
			setIsLoading(true);
			const res = await getUberLoginConnect(codeUber ?? '', stateUber ?? '');

			if (res) {
				const body = {
					access_token: res.access_token,
					refresh_token: res.refresh_token,
					expires_in_seconds: res.expires_in_seconds,
				};
				await postOrganizationCredentials(body, tokenSession);
				await patchUber(selecOrgUber === 'Uber' ? 5 : 2, tokenSession);
				dispatch(showAlert(alertSuccess));
			} else {
				dispatch(showAlert(alertError));
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			dispatch(showAlert(alertError));
		} finally {
			navigate(ROUTES.CHECKOUT);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		 fetchUrlUber();
		 logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: '/uber-connect',
		});
	}, []);

	return (
		<>
			{isLoading && (
				<div className='flex items-center justify-center mt-5'>
					<CircularProgress size={30} />
				</div>
			)}
		</>
	);
};

export default UberFlow;
