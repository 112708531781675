import { Dispatch, SetStateAction, useState } from 'react';

import { DotsThreeVertical } from '@phosphor-icons/react';

//? Types
import { SubscriptionType } from '../../../../@types/Subscription';

//? Components
import Button from '../../../../components/atoms/Button';
import ModalHistory from '../organisms/ModalHistory';
import ModalChurn from '../organisms/ModalChurn';
interface Props {
	showModal: {
		churn: boolean;
		history: boolean;
	};
	setShowModal: Dispatch<SetStateAction<{ churn: boolean; history: boolean }>>;
	subscription: SubscriptionType;
	fetchUserSuscriptions: () => void;
}

const ModalServiceOptions: React.FC<Props> = ({
	showModal,
	setShowModal,
	subscription,
	fetchUserSuscriptions
}) => {
	const handleShowModal = (modal: string) => {
		if (modal === 'history') {
			setShowModal({ ...showModal, history: true });
		} else {
			setShowModal({ ...showModal, churn: true });
		}
	};
	const [showOptions, setShowOptions] = useState(false);
	const [activeHistoricalModal, setActiveHistoricalModal] = useState(false);

	return (
		<>
			{activeHistoricalModal && (
				<ModalHistory
					showModal={showModal}
					setShowModal={setShowModal}
					activeSubscription={subscription}
				/>
			)}

			<ModalChurn
				showModal={showModal}
				setShowModal={setShowModal}
				activeSubscription={subscription}
				fetchUserSubscriptions={fetchUserSuscriptions}
			/>
			<div className='absolute top-2 right-2'>
				<button
					onClick={() => setShowOptions(!showOptions)}
					className={`rounded w-6 h-6 flex justify-center items-center transition ${
						showOptions ? 'bg-h-blue-20' : 'bg-white'
					}`}
				>
					<DotsThreeVertical
						size={18}
						weight='bold'
						color={showOptions ? 'var(--h-primary)' : 'var(--h-dark)'}
					/>
				</button>
				{showOptions && (
					<div className='absolute top-6 right-0 bg-white p-2 rounded-md shadow-lg '>
						<Button
							sx='hover:bg-h-gray-10 !text-h-dark transition h-fit !w-[250px] !text-right !block !shadow-none !py-1 !px-2 rounded-md cursor-pointer border-none'
							onClick={() => {
								setActiveHistoricalModal(true);
								handleShowModal('history');
							}}
							label='Histórico de cobros'
							variant='outline'
						/>
						{subscription?.renew_at_period_end && subscription.active && (
							<>
								<hr className='my-1' />
								<Button
									sx='!text-h-red-50 hover:bg-h-red-40/10 transition h-fit !w-[250px] !text-right !block !shadow-none !py-1 !px-2 rounded-md cursor-pointer border-none'
									onClick={() => handleShowModal('churn')}
									label='Cancelar suscripción'
									variant='outline'
								/>
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default ModalServiceOptions;
