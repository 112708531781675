import HERU_API from '../shared/constants/heruApi';
import { post } from '../shared/utils/FetchSSL.util';

const postCheckout = async (
	order_id: number,
	body: object,
	query_params?: string,
	tokenSession?: string,
) => {
	try {
		const response = await post<any>(
			query_params
				? `${HERU_API.HERU_API_PAYMENT__ORDERS_PROCESS(
						order_id,
					)}?${query_params}`
				: HERU_API.HERU_API_PAYMENT__ORDERS_PROCESS(order_id),
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postCheckoutPayPal = async (
	order_id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post<any>(
			`${HERU_API.HERU_API_PAYMENT__ORDERS_PROCESS(order_id)}/paypal`,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const postCheckoutProcessPayPal = async (
	order_id: number,
	body: object,
	tokenSession?: string,
) => {
	try {
		const response = await post<any>(
			`${HERU_API.HERU_CORE_PAYMENTS_SHOPPING_PROCESSV2_PAYPAL(order_id)}`,
			body,
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};
export { postCheckout, postCheckoutPayPal, postCheckoutProcessPayPal };
