import { FiscalDocumentType } from '../../../@types/FiscalDocuments.type';
import Text from '../../../components/atoms/Text';
import { FiscalDocumentsLists } from '../organisms/TableFiscalDocuments';

type Props = {
	isLoading: Record<string, boolean>;
	documentType:
		| FiscalDocumentType.INVOICE
		| FiscalDocumentType.RECEIPT
		| FiscalDocumentType.RETENTION;
	fiscalDocuments: FiscalDocumentsLists;
};

const FiscalTableEmptyState: React.FC<Props> = ({
	isLoading,
	documentType,
	fiscalDocuments,
}) => {
	if (
		!isLoading[documentType] &&
		(fiscalDocuments[documentType] === null ||
			!fiscalDocuments[documentType]?.data)
	) {
		return (
			<div className='flex justify-center items-center mt-4'>
				<Text
					size='caption'
					color='light'
				>
					No se encontraron documentos
				</Text>
			</div>
		);
	}

	return null;
};

export default FiscalTableEmptyState;
