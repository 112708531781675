import { useMediaQuery } from '@mui/material';
import Text from '../../atoms/Text';

interface Props {
	options: {
		label: string;
		labelMobile?: string;
		slug: string;
	}[];
	handleCategory: (slug: string) => void;
	currentOption: string;
}

const TabSelector: React.FC<Props> = ({
	options,
	handleCategory,
	currentOption,
}) => {
	const isMobile = useMediaQuery('(max-width: 1024px)');

	return (
		<div className='flex items-end'>
			{options.map((option, index) => {
				const isActive = currentOption === option.slug;
				return (
					<button
						key={`category-${index}`}
						onClick={() => handleCategory(option.slug)}
						className={`transition px-2 py-1 border-b border-b-h-neutral-90 rounded-t-lg group ${
							isActive
								? '!border-h-primary !border-b-2 !px-3 hover:bg-h-primary-95'
								: 'hover:bg-h-neutral-95'
						}`}
					>
						<Text
							weight={'medium'}
							color={isActive ? 'primary' : 'medium'}
							sx={`!cursor-pointer !text-[13px] lg:!text-base !leading-5 !lg:leading-6 whitespace-nowrap transition
								${isActive ? '' : 'opacity-60 group-hover:opacity-80'}
							`}
						>
							{isMobile ? option.labelMobile || option.label : option.label}
						</Text>
					</button>
				);
			})}
			<div className='w-full h-[1px] bg-h-neutral-90'></div>
		</div>
	);
};

export default TabSelector;
