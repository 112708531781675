import { useState } from 'react';
import Text from '../../../components/atoms/Text';
import { CaretDown, CheckCircle } from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

type Props = {
	benefits?: string[];
};

const BenefitsDropdown: React.FC<Props> = ({ benefits }) => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMediaQuery('(max-width: 768px)');

	if (isMobile || !benefits?.length) return null;

	return (
		<div className=''>
			<hr className='my-5' />
			<button
				onClick={() => setIsOpen(!isOpen)}
				className='flex justify-between w-full items-center cursor-pointer mb-4 mt-3'
			>
				<Text
					size='body-3'
					weight='medium'
				>
					Beneficios
				</Text>
				<CaretDown
					width={20}
					height={20}
				/>
			</button>
			{isOpen && (
				<div className='flex flex-col gap-4'>
					{benefits?.map((benefit, index) => (
						<div
							className='flex items-center gap-2'
							key={index}
						>
							<CheckCircle
								width={20}
								height={20}
								weight='duotone'
								color='var(--h-primary)'
							/>
							<Text
								size='caption'
								weight='light'
							>
								{benefit}
							</Text>
						</div>
					))}
				</div>
			)}
			<hr className='my-5' />
		</div>
	);
};

export default BenefitsDropdown;
