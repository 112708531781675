import React from 'react';
import HomeCard from '../../molecules/HomeCard';
import Text from '../../atoms/Text';
import {
	getCurrentDate,
	getFormatDate,
	getPrefixMonth,
} from '../../../shared/utils/dates.util';
import { MovementDocuments } from '../../../@types/Fiscal.type';
import { formatCurrency } from '../../../shared/utils/general.util';
import { getBgColorRow } from '../../../shared/utils/styles.util';
import noFileImg from '../../../assets/img/svg/noFile.svg';

interface Props {
	title?: string;
	invoices?: MovementDocuments[];
	showButton?: boolean;
}

const demoInvoices = [
	{
		date: `07 ${getPrefixMonth(getCurrentDate().month)} ${
			getCurrentDate().year
		}`,
		name: 'Sistemas informáticos para la gestión empresarial',
		price: '$ 100.00',
	},
	{
		date: `10 ${getPrefixMonth(getCurrentDate().month)} ${
			getCurrentDate().year
		}`,
		name: 'Program de diseño de patrones',
		price: '$ 300.00',
	},
	{
		date: `11 ${getPrefixMonth(getCurrentDate().month)} ${
			getCurrentDate().year
		}`,
		name: 'Servicios administrativos',
		price: '$ 500.00',
	},
	{
		date: `17 ${getPrefixMonth(getCurrentDate().month)} ${
			getCurrentDate().year
		}`,
		name: 'Automóviles',
		price: '$ 200.00',
	},
];

const InvoicesSumary: React.FC<Props> = ({ title, invoices, showButton }) => {
	return (
		<HomeCard
			title={title}
			href='#fiscalform'
			showButton={showButton}
		>
			<div className='flex flex-col'>
				{!invoices &&
					demoInvoices.map((invoice, key) => (
						<div
							className='flex flex-row py-2 justify-between'
							key={`demo-invoice-${key}`}
						>
							<div className='flex flex-row gap-x-2 lg:gap-x-4'>
								<Text
									size='xs'
									color='medium'
									sx='min-w-[64px]'
								>
									{invoice.date}
								</Text>
								<Text
									size='xs'
									color='medium'
									sx='truncate !inline-block w-[130px] sm:w-[300px] md:w-[130px] lg:w-[250px] xl:w-[300px]'
								>
									{invoice.name}
								</Text>
							</div>
							<div>
								<Text
									size='xs'
									weight='medium'
									color='medium'
								>
									{invoice.price}
								</Text>
							</div>
						</div>
					))}

				{!invoices ||
					(invoices?.length === 0 && (
						<div className='flex flex-col justify-center items-center'>
							<img
								src={noFileImg}
								alt='Icono de factura bloqueada'
							/>
							<Text
								size='m'
								weight='medium'
							>
								Por el momento no tienes facturas
							</Text>
						</div>
					))}

				{invoices &&
					invoices.map((invoice, key) => (
						<div
							className={`flex flex-row py-2 justify-between ${getBgColorRow(
								key,
							)}`}
							key={`invoice-${key}`}
						>
							<div className='flex flex-row gap-x-2 lg:gap-x-4'>
								<Text
									size='xs'
									color='medium'
									sx='min-w-[64px]'
								>
									{getFormatDate(invoice.issued_at)}
								</Text>
								<Text
									size='xs'
									color='medium'
									sx='truncate !inline-block w-[120px] sm:w-[300px] md:w-[130px] lg:w-[170px] xl:w-[300px]'
								>
									{invoice.items.find((t) => t?.concept)?.concept}
								</Text>
							</div>
							<div>
								<Text
									size='xs'
									weight='medium'
									color='medium'
									sx='text-right w-full'
								>
									{formatCurrency(invoice.amount)}
								</Text>
							</div>
						</div>
					))}
			</div>
		</HomeCard>
	);
};

export default React.memo(InvoicesSumary);
