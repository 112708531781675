import Text from '../../atoms/Text';
import { CheckCircle } from '@phosphor-icons/react';
import Tag from '../../atoms/Tag';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Selector from '../../atoms/Selector';
import { ProductCardProps } from '../../../@types/Onboarding.type';
import { Skeleton } from '@mui/material';
import { formatPrice } from '../../../shared/utils/general.util';

const ProductCard: React.FC<ProductCardProps> = ({
	productName,
	description,
	tagLabel,
	price,
	periodicity,
	buttonLabel,
	benefits,
	handleClick,
	options = [],
	beforePrice,
	isLoading,
	isLoadingButton,
	showPrice = true,
	control,
	onSkip,
}) => {
	if (isLoading) {
		return (
			<div className='bg-white p-3 rounded-xl relative max-w-sm shadow'>
				<div className='flex gap-2 justify-between'>
					<Heading
						size='xs'
						color='dark'
						weight='medium'
						sx='mt-2'
					>
						<Skeleton width={200} />
					</Heading>
					{tagLabel && (
						<Tag
							label={tagLabel}
							sx='whitespace-nowrap h-fit'
						/>
					)}
				</div>
				<Text
					color='medium'
					sx='mt-2 flex flex-col  w-full'
				>
					<Skeleton
						width={'100%'}
						height={30}
					/>
					<Skeleton
						width={'100%'}
						height={30}
					/>
				</Text>
				<div className='flex flex-col gap-2 mt-5 p-3 bg-h-blue-20/60 rounded-lg font-medium '>
					{benefits?.map((_, index) => (
						<div key={`benefit-index-${index}`}>
							<Text
								beforeIcon={
									<CheckCircle
										size={18}
										color='var(--h-primary)'
										weight='fill'
										className='min-w-[18px]'
									/>
								}
								size='s'
							>
								<Skeleton
									width={'90%'}
									height={30}
								/>
							</Text>
						</div>
					))}
				</div>
				{options.length > 0 && (
					<Selector
						control={control}
						name='value'
						options={options}
						sx='mt-2 w-full'
					/>
				)}
				<Button
					label={buttonLabel}
					sx='mt-4'
				/>
			</div>
		);
	}

	return (
		<div className='bg-white p-3 rounded-xl relative max-w-sm shadow'>
			<div className='flex gap-2 justify-between'>
				<Text
					size='xl'
					color='dark'
					weight='bold'
					sx='mt-2 sm:text-2xl'
				>
					{productName}
				</Text>
				{tagLabel && (
					<Tag
						label={tagLabel}
						sx='whitespace-nowrap h-fit'
					/>
				)}
			</div>
			<Text
				sx='mt-3'
				color='medium'
			>
				{description}
			</Text>
			<div className='flex flex-col gap-2 mt-5 p-3 bg-h-blue-20/60 rounded-lg font-medium '>
				{benefits?.map((benefit: string, index) => (
					<div key={`benefit-index-${index}`}>
						<Text
							beforeIcon={
								<CheckCircle
									size={18}
									color='var(--h-primary)'
									weight='fill'
									className='min-w-[18px]'
								/>
							}
							size='s'
						>
							{benefit}
						</Text>
					</div>
				))}
			</div>
			{showPrice && price !== undefined && (
				<Heading
					size='xs'
					weight='medium'
					sx='mt-4'
				>
					{!!beforePrice && (
						<span className='text-lg font-normal text-h-medium mr-1'>
							{beforePrice}{' '}
						</span>
					)}
					${formatPrice(price)}{' '}
					{periodicity && (
						<span className='text-lg font-normal text-h-medium'>
							{
								<span className='text-lg font-normal text-h-medium'>
									{periodicity}
								</span>
							}
						</span>
					)}
				</Heading>
			)}
			{options.length > 0 && (
				<Selector
					control={control}
					name='value'
					options={options}
					sx='mt-2 w-full'
				/>
			)}
			<Button
				onClick={handleClick}
				label={buttonLabel}
				sx='mt-4'
				loading={isLoadingButton}
			/>
			{onSkip && (
				<Button
					variant='secondary'
					label='Ver después'
					sx='mt-2'
					onClick={onSkip}
				/>
			)}
		</div>
	);
};

export default ProductCard;
