import React from 'react';
import { Info } from '@phosphor-icons/react';
import Tooltip from '@mui/material/Tooltip';

type Props = {
	message: string;
	beforeIcon?: React.ReactNode;
	sx?: string;
	size?: number;
};

const HeruTooltip: React.FC<
	Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ message, size = 18 }) => {
	return (
		<Tooltip
			title={message}
			arrow
			enterTouchDelay={0}
			leaveTouchDelay={10000}
		>
			<Info size={size} />
		</Tooltip>
	);
};

export default React.memo(HeruTooltip);
