import { ListProducts } from '../@types/Products.type';
import HERU_API from '../shared/constants/heruApi';
import { get } from '../shared/utils/FetchSSL.util';

const getProducts = async (tokenSession?: string): Promise<ListProducts[]> => {
	try {
		const response = await get(
			`${HERU_API.HERU_API_PRODUCTS}/map?all=true&channel_source=web`,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export { getProducts };
