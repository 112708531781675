import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CaretCircleLeft } from '@phosphor-icons/react';

//? Types
import { Acquisitions } from '../../@types/Acquisitions.type';
import { ServiceStatusElement } from '../../@types/ServiceStatus.type';

//? Components
import Text from '../../components/atoms/Text';
import Divider from '../../components/atoms/Divider';
import Heading from '../../components/atoms/Heading';
import PurchaseRow from './components/molecules/PurchaseRow';
import BannerHome from '../../components/molecules/BannerHome';
import MultipleTop from './components/organisms/MultipleTop';
import SingleTop from './components/organisms/SingleTop';

//? Utils
import ROUTES from '../../shared/constants/routes';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import EmptyCartIcon from '../../assets/img/svg/empty_cart.svg';

//? Services
import { fetchPendingOrders } from '../../services/orders.service';
import { getUserSuscriptions } from '../../services/suscription.service';
import { fetchAcquisitions } from '../../services/shopping.service';
import { getAllServiceStatus } from '../../services/serviceStatus.service';

//? Redux
import { RootState } from '../../redux/store';
import { setUserSubscriptions } from '../../redux/slices/profile.slice';

//? Hooks
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import LastPendingOrder from '../pendingReferences/components/LastPendingOrder';

interface ShoppingProps {}

const headersTable = [
	{
		id: 1,
		name: 'Servicio',
	},
	{
		id: 2,
		name: 'Estado',
	},
	{
		id: 3,
		name: 'Fecha de presentación',
	},
	{
		id: 4,
		name: 'Método de pago',
	},
	{
		id: 5,
		name: 'Valor pagado',
	},
];

const Shopping: React.FC<ShoppingProps> = ({}) => {
	const queryParams = new URLSearchParams(location.search);
	const profile = queryParams.get('profile');

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const { activeSuscriptions, subscriptions } = useSelector(
		(state: RootState) => state.profile,
	);
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const [serviceStatus, setServiceStatus] = useState<ServiceStatusElement[]>(
		[],
	);
	const [acquisitions, setAcquisitions] = useState<Acquisitions[]>([]);
	const [hasPendingOrders, setHasPendingOrders] = useState(false);

	const [isLoading, setIsLoading] = useState({
		pendingOrders: false,
		acquisitions: false,
		subscriptions: false,
	});

	const getPendingOrder = async () => {
		try {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				pendingOrders: true,
			}));
			const orders = await fetchPendingOrders(tokenSession);
			if (orders?.length > 0) {
				setHasPendingOrders(true);
			} else {
				setHasPendingOrders(false);
			}
		} catch (error: any) {
		} finally {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				pendingOrders: false,
			}));
		}
	};

	const fetchUserSuscriptions = useCallback(async () => {
		try {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				subscriptions: true,
			}));
			const subs = await getUserSuscriptions(tokenSession);
			dispatch(setUserSubscriptions(subs));
		} catch (error) {
		} finally {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				subscriptions: false,
			}));
		}
	}, []);

	const getAcquisitions = async () => {
		try {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				acquisitions: true,
			}));
			const resource = await fetchAcquisitions(tokenSession);
			setAcquisitions(resource);
		} catch (error: any) {
		} finally {
			setIsLoading((prevLoadingState) => ({
				...prevLoadingState,
				acquisitions: false,
			}));
		}
	};

	const getServiceStatus = async () => {
		try {
			const resource = await getAllServiceStatus(tokenSession);
			if (Array.isArray(resource) && resource.length > 0) {
				setServiceStatus(resource);
			}
		} catch (error) {}
	};

	const findServiceStatus = (acquisitionId: number) => {
		return serviceStatus.find(
			(service) => service.product.acquisition_id === acquisitionId,
		);
	};

	const getHeaderWidth = (id: number) => {
		if (id === 1) {
			return 'w-[480px]';
		} else if (id === 2) {
			return 'w-[304px]';
		} else if (id === 3) {
			return 'w-[350px]';
		} else if (id === 4) {
			return 'w-[300px]';
		} else if (id === 5) {
			return 'w-[270px]';
		}
	};

	const renderTopSection = () => {
		if (hasPendingOrders && activeSuscriptions.length > 0) {
			return (
				<>
					{isLoading.pendingOrders && isLoading.subscriptions ? (
						<div className='flex items-center justify-center mt-12'>
							<CircularProgress size={25} />
						</div>
					) : (
						<MultipleTop
							activeSuscriptions={activeSuscriptions}
							subscriptions={subscriptions}
							fetchUserSubscriptions={fetchUserSuscriptions}
						/>
					)}
				</>
			);
		} else if (activeSuscriptions.length > 0 && !hasPendingOrders) {
			return (
				<>
					{isLoading.pendingOrders && isLoading.subscriptions ? (
						<div className='flex items-center justify-center mt-12'>
							<CircularProgress size={25} />
						</div>
					) : (
						<SingleTop
							activeSuscriptions={activeSuscriptions}
							subscriptions={subscriptions}
							fetchUserSubscriptions={fetchUserSuscriptions}
						/>
					)}
				</>
			);
		} else if (activeSuscriptions.length === 0 && hasPendingOrders) {
			return (
				<>
					{isLoading.pendingOrders && isLoading.subscriptions ? (
						<div className='flex items-center justify-center mt-12'>
							<CircularProgress size={25} />
						</div>
					) : (
						<div className='flex flex-col w-full mb-8'>
							<LastPendingOrder isSingle />
						</div>
					)}
				</>
			);
		} else if (activeSuscriptions?.length === 0 && !hasPendingOrders) {
			return (
				<>
					{isLoading.pendingOrders && isLoading.subscriptions ? (
						<div className='flex items-center justify-center mt-12'>
							<CircularProgress size={25} />
						</div>
					) : (
						<div className='mb-5'>
							<BannerHome
								title={'¡Suscríbete y deja tus impuestos en nuestras manos!'}
								sxBannerImg='bg-center'
								onHandlerLink={goToPlans}
								sxBannerContent='lg:pr-32'
								labelButton='Conocer los planes'
							/>
						</div>
					)}
				</>
			);
		}
	};

	const renderBottomSection = () => {
		return (
			<>
				{isLoading.acquisitions ? (
					<div className='flex items-center justify-center mt-32'>
						<CircularProgress size={25} />
					</div>
				) : (
					<>
						{acquisitions?.length === 0 && activeSuscriptions?.length === 0 && (
							<div className='flex flex-col items-center my-12'>
								<img
									src={EmptyCartIcon}
									alt='empty-cart'
									className='mb-6'
								/>
								<Heading
									size='m'
									weight='bold'
									sx='mb-6 lg:flex hidden'
								>
									Aún no has realizado ninguna compra.
								</Heading>

								<Text
									size='xl'
									weight='bold'
									sx='mb-6 lg:hidden'
								>
									Aún no has realizado ninguna compra.
								</Text>

								<Heading
									size='xs'
									sx='lg:flex hidden'
								>
									Descubre todos nuestros productos en la sección de
								</Heading>

								<Text
									size='m'
									sx='lg:hidden'
								>
									Descubre todos nuestros productos en la sección de
								</Text>

								<button onClick={goToPlans}>
									<Heading
										size='xs'
										weight='bold'
										sx='lg:flex hidden'
									>
										planes y precios.
									</Heading>
									<Text
										size='m'
										weight='bold'
										sx='lg:hidden'
									>
										planes y precios.
									</Text>
								</button>
							</div>
						)}
						{acquisitions?.length > 0 && (
							<div className='flex flex-col bg-white p-6 w-full rounded-lg shadow-md'>
								<Text
									size='xl'
									color='dark'
									weight='bold'
									sx='mb-6'
								>
									Lista de compras
								</Text>
								<Divider />

								<div className='hidden lg:flex border-b border-h-blue-40 x'>
									{headersTable.map((item) => (
										<div
											key={item.id}
											className={`py-4 flex justify-start items-center ${getHeaderWidth(
												item.id,
											)} bg-white`}
										>
											<Text
												size='m'
												color='dark'
												weight='bold'
											>
												{item.name}
											</Text>
										</div>
									))}
								</div>

								<div className='mt-2'>
									{acquisitions?.map((item, index) => {
										const statusObject = findServiceStatus(item.id);
										return (
											<PurchaseRow
												key={`acq-${index}`}
												index={index}
												item={item}
												statusObject={statusObject!}
											/>
										);
									})}
								</div>
							</div>
						)}
					</>
				)}
			</>
		);
	};

	const goToPlans = () => {
		navigate(ROUTES.PLANS_AND_PRICES);
	};

	useEffect(() => {
		getPendingOrder();
		getAcquisitions();
		getServiceStatus();
		fetchUserSuscriptions();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.SHOPPING,
		});
	}, []);

	return (
		<>
			<div className='p-4 lg:px-12 lg:py-8 w-full h-full'>
				<div
					className={`flex items-center ${
						profile ? 'gap-3 mb-6' : 'flex-row mb-6 lg:mb-6'
					}`}
				>
					{profile && (
						<>
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={32}
								height={32}
								className='cursor-pointer hidden lg:block'
								onClick={() => navigate(ROUTES.USER_PROFILE)}
							/>
							<CaretCircleLeft
								weight='fill'
								color='var(--h-primary)'
								width={24}
								height={24}
								className='cursor-pointer block lg:hidden'
								onClick={() => navigate(ROUTES.USER_PROFILE)}
							/>
						</>
					)}
					<Heading
						sx='hidden lg:block'
						size='s'
						weight='bold'
					>
						Mis compras
					</Heading>
					<Text
						sx='block lg:hidden'
						size='body-2'
						weight='bold'
					>
						Mis compras
					</Text>
				</div>

				{renderTopSection()}

				{renderBottomSection()}
			</div>
		</>
	);
};

export default React.memo(Shopping);
