import { ListProducts } from '../../@types/Products.type';
import { APP_CONFIGURATIONS } from '../constants/configurations';
import { PRODUCTS } from '../constants/prices';

const getElasticyPriceByDb = (
	groupExperiment: string,
	slug: string,
	periodicy: number = 30,
) => {
	return PRODUCTS.MONTHLY_DECLARATION[groupExperiment][slug][periodicy];
};

type TPorcent = 'discount' | 'increase';

const getOfferPrice = (
	price: number,
	porcent: number,
	type: TPorcent = 'discount',
) => {
	let finalPrice = price;
	if (type === 'discount') {
		finalPrice = price * ((100 - porcent) / 100);
	} else {
		finalPrice = price * ((100 + porcent) / 100);
	}
	return Math.round(finalPrice);
};

const getPorcentByGroupExperiment = (groupExperiment: string) => {
	switch (groupExperiment) {
		case 'A':
			return {
				porcent: 0,
				type: 'discount',
			};

		case 'B':
			return {
				porcent: 20,
				type: 'increase',
			};

		case 'C':
			return {
				porcent: 20,
				type: 'discount',
			};

		default:
			return {
				porcent: 0,
				type: 'discount',
			};
	}
};

const getElasticyPrice = (price: number, groupExperiment: string) => {
	const { porcent, type } = getPorcentByGroupExperiment(groupExperiment);

	return getOfferPrice(price, porcent, type as TPorcent);
};

const getPriceWithElasticity = (
	price: number,
	elasticityPrice: { group_experiment: string },
) =>
	APP_CONFIGURATIONS.SHOW_PRICE_ELASTICY
		? getElasticyPrice(price, elasticityPrice.group_experiment)
		: price;

const getPriceFromProductVersionId = (
	products: ListProducts[],
	productId: number,
) => {
	const filterProduct =
		products
			.filter((cat) =>
				cat.products.find((p) =>
					p.product_versions.find((pv) => pv.id === productId),
				),
			)?.[0]
			?.products?.find((p) =>
				p.product_versions.find((pv) => pv.id === productId),
			)
			?.product_versions.find((pv) => pv.id === productId)?.price || 0;

	return Math.round(filterProduct / 100);
};

export {
	getElasticyPrice,
	getPorcentByGroupExperiment,
	getOfferPrice,
	getElasticyPriceByDb,
	getPriceFromProductVersionId,
	getPriceWithElasticity,
};
