import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/atoms/Heading';
import FiscalDocumentsSection from './organisms/FiscalDocumentsSection';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import { fetchSATLinkedById } from '../../services/satLinking.service';
import { linkedAccount } from '../../redux/slices/sat.slice';
import Text from '../../components/atoms/Text';
import Tabs from '../../components/molecules/Tabs';
import SATLinkingForm from '../../components/molecules/SATLinkingForm';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import ERROR from '../../shared/constants/errors';
import { showAlert } from '../../redux/slices/alert.slice';
import { CircularProgress } from '@mui/material';
import Container from '../../components/atoms/Container';
import Button from '../../components/atoms/Button';
import { Upload, Warning } from '@phosphor-icons/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AlertBanner from '../../components/molecules/AlertBanner';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
const items = [
	{
		label: 'Contraseña',
		slug: 'password',
	},
	{
		label: 'e.Firma',
		slug: 'efirma',
	},
];

const FiscalDocuments = () => {
	const [startUpload, setStartUpload] = useState(false);
	const [activeTab, setActiveTab] = useState(items[0]?.slug);
	const [isLoading, setIsLoading] = useState({
		linkedAccount: false,
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { status } = useSelector((state: RootState) => state.satLinkStatus);
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const userLinked = !isLoading.linkedAccount && status?.status && userProfile;
	const { tokenSession } = useSelector((state: RootState) => state.auth);
	const showErrorSatAlert = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.SHOW_ERROR_SAT_ALERT,
	);
	const fetchSatLinkedAcount = async () => {
		setIsLoading({ ...isLoading, linkedAccount: true });
		try {
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: Error | any) {
			if (error?.response?.status === 404) return;
			const alert = {
				type: ALERT_TYPE.ERROR,
				title: ERROR.failed_to_fetch_data,
				description: ERROR.try_again_later,
				duration: 2000,
			};
			dispatch(showAlert(alert));
		} finally {
			setIsLoading({ ...isLoading, linkedAccount: false });
		}
	};

	const isMobile = useMediaQuery('(max-width: 1000px)');

	const successSATLinkHandler = async () => {
		fetchSatLinkedAcount();
	};

	useEffect(() => {
		fetchSatLinkedAcount();
	}, []);

	if (isLoading.linkedAccount) {
		return (
			<div className='flex items-center justify-center mt-10'>
				<CircularProgress size={25} />
			</div>
		);
	}

	if (!userLinked) {
		return (
			<Container sx='w-full max-w-[600px] mx-auto sm:mt-10'>
				<div className='rounded-lg border  border-h-primary'>
					<Text
						size='m'
						sx='text-center mt-6 mb-8 mx-4 lg:text-xl'
						weight='medium'
					>
						Vincula tu RFC para acceder a tus documentos fiscales
					</Text>
					<Tabs
						items={items}
						onClickTab={(val) => setActiveTab(val)}
					/>
					<SATLinkingForm
						onCallbackSuccess={successSATLinkHandler}
						onCallbackError={() => {
							navigate(location.pathname, {});
						}}
						showEFirmaForm={activeTab !== 'password'}
						sx='p-6'
						buttonLabel='Vincular mi RFC'
					/>
				</div>
			</Container>
		);
	}

	if (isMobile) {
		return (
			<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
				<div className='flex flex-col md:flex-row justify-between items-center gap-4'>
					<Heading
						size='s'
						weight='bold'
						responsive
					>
						Documentos fiscales
					</Heading>
					<Button
						label='Carga tus facturas o retenciones'
						variant='primary'
						onClick={() => setStartUpload(true)}
						sx='w-full md:w-[233px] h-[44px]'
						icon={<Upload size={24} />}
					/>
				</div>

				<FiscalDocumentsSection
					startUpload={startUpload}
					setStartUpload={setStartUpload}
				/>
			</section>
		);
	}
	return (
		<section className='p-3 lg:py-6 lg:px-12 flex flex-col gap-4 bg-h-neutral-94 min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-46px)]'>
			{showErrorSatAlert && (
				<div className='flex justify-center  items-center mb-2 md:mb-4'>
					<AlertBanner
						variant='error'
						title='Inconveniente temporal en la descarga de retenciones'
						description={
							<div>
								El SAT presenta intermitencias. Carga tus facturas o
								certificados desde el botón{' '}
								<a
									href='/fiscal-documents/?prycx=on'
									className='font-bold '
								>
									"Cargar documentos"
								</a>{' '}
								para generar tu declaración. También puedes consultar nuestro{' '}
								<a
									target='_blank'
									href='https://heru.frontkb.com/en/articles/3578625'
									className='font-bold'
								>
									blog
								</a>{' '}
								para aprender cómo subir los documentos fácilmente.
							</div>
						}
						icon={
							<Warning
								size={36}
								weight='fill'
								color='var(--h-hot-sale) '
								className='flex-2'
							/>
						}
						sx='w-full lg:w-[600px]'
						sizeTitle='body-2'
						sizeDescription='body-2'
					/>
				</div>
			)}
			<div className='flex flex-col md:flex-row justify-between items-center gap-4'>
				<Heading
					size='s'
					weight='bold'
					responsive
				>
					Documentos fiscales
				</Heading>
				<Button
					label='Carga tus facturas o retenciones'
					variant='primary'
					onClick={() => setStartUpload(true)}
					sx='w-full md:w-[233px] h-[44px]'
				/>
			</div>

			<FiscalDocumentsSection
				startUpload={startUpload}
				setStartUpload={setStartUpload}
			/>
		</section>
	);
};

export default FiscalDocuments;
