import clevertap from 'clevertap-web-sdk';

const logClevertapEvent = (
	eventName: string,
	properties: Record<string, string | number | boolean | any[]> = {},
) => {
	clevertap.event.push(eventName, {
		...properties,
		timestamp: new Date(),
	});
};

export { logClevertapEvent };
