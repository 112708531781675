import { createSlice } from '@reduxjs/toolkit';
import { RappiCertificate } from '../../@types/FiscalDocuments.type';

export interface AuthState {
	certificates: RappiCertificate[];
}

const initialState: AuthState = {
	certificates: [],
};

export const rappiSlice = createSlice({
	name: 'Rappi',
	initialState,
	reducers: {
		addRappiCertificates: (state, action) => {
			state.certificates = action.payload;
		},
	},
});

export const { addRappiCertificates } = rappiSlice.actions;
