import { CircleNotch } from '@phosphor-icons/react';
import React, { useCallback } from 'react';
import { TTextColor, TTextSize } from '../Text';
import Text from '../Text';
import { getTextColor } from '../../../shared/utils/styles.util';

type Props = {
	label: string;
	beforeIcon?: React.ReactNode;
	icon?: React.ReactNode;
	variant?:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'outline'
		| 'text'
		| 'danger'
		| 'danger-secondary';
	sx?: string;
	loading?: boolean;
	labelColor?: TTextColor;
	labelSize?: TTextSize;
};

const PrimaryButton: React.FC<
	Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	label,
	variant = 'primary',
	icon,
	beforeIcon,
	disabled,
	sx,
	loading = false,
	type = 'button',
	labelColor = 'dark',
	labelSize = 'm',
	...props
}) => {
	const getButtonStyle = useCallback(() => {
		switch (variant) {
			case 'primary':
				return 'bg-h-primary-40 text-white h-[48px] hover:bg-h-blue-50-hover border-none';
			case 'secondary':
				return 'bg-h-blue-20 h-[48px] text-h-primary border-none';
			case 'outline':
				return ' text-h-primary h-[48px] hover:bg-h-primary/10 border-h-primary';
			case 'tertiary':
				return 'bg-white text-primary border-slate-200 text-h-dark h-10 hover:bg-h-neutral-94';
			case 'danger':
				return 'bg-h-red-50 text-white h-[48px] hover: border-none';
			case 'danger-secondary':
				return 'bg-h-red-30 text-h-red-50 h-[48px] border-none';
			case 'text':
				return 'bg-transparent h-fit border-none inline-flex gap-x-1';
			default:
				return 'bg-h-primary text-white h-[48px]';
		}
	}, [variant]);

	const getIconStyle = useCallback(() => {
		switch (variant) {
			case 'primary':
			case 'danger':
				return 'white';
			case 'secondary':
			case 'outline':
				return 'var(--h-primary)';
			case 'tertiary':
				return 'var(--h-dark)';
			case 'danger-secondary':
				return 'var(--h-red-50)';
			default:
				return 'white';
		}
	}, [variant]);

	if (loading) {
		return (
			<button
				disabled
				className={`
		rounded-lg border flex gap-2 items-center justify-center px-3 shadow-sm font-medium text-sm w-full cursor-not-allowed transition
		 ${getButtonStyle()} 
		 ${sx}
		 `}
			>
				<div className='animate-spin w-fit mx-auto'>
					<CircleNotch
						size={22}
						color={getIconStyle()}
						weight='bold'
					/>
				</div>
			</button>
		);
	}

	if (variant === 'text') {
		return (
			<button
				type={type}
				disabled={disabled}
				className={`${getButtonStyle()} ${getTextColor(labelColor)} ${sx}`}
				{...props}
			>
				{beforeIcon}
				<Text
					color={labelColor}
					size={labelSize}
					sx='!block'
				>
					{label}
				</Text>
				{icon}
			</button>
		);
	}

	return (
		<button
			type={type}
			disabled={disabled}
			className={`
			rounded-lg border flex gap-2 items-center justify-center px-3 shadow-sm font-medium transition active:translate-y-[2px] text-sm md:text-md
			disabled:text-h-light disabled:bg-h-gray-20 disabled:border-none disabled:cursor-not-allowed w-full
			 ${getButtonStyle()} 
			 ${sx}
			 `}
			{...props}
		>
			{beforeIcon}
			{label}
			{icon}
		</button>
	);
};

export default PrimaryButton;
