import { IFiscalDeclarationStatus } from '../@types/Fiscal.type';
import HERU_API from '../shared/constants/heruApi';
import { get } from '../shared/utils/FetchSSL.util';

const getUserFiscalStatus = async (tokenSession?: string) => {
	try {
		const response: IFiscalDeclarationStatus | undefined = await get(
			HERU_API.HERU_API_FISCAL_DECLARATIONS_STATUS,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export { getUserFiscalStatus };
