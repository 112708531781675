import HERU_API from '../shared/constants/heruApi';
import { post } from '../shared/utils/FetchSSL.util';

const sendOTP = async (
	cellphone: string,
	countryCode: string,
	send_by: string = 'sms',
	platform: string = 'android',
) => {
	try {
		const disableSentOtp = import.meta.env.VITE_DISABLE_SENT_OTP;
		console.log('disableSentOtp', disableSentOtp);
		const body = {
			send_by,
			cellphone,
			country_code: `+${countryCode}`,
			heru_pal: disableSentOtp === 'true',
		};
		const response = await post(
			HERU_API.SEND_OTP_CODE + `?platform=${platform}`,
			body,
			{
				authCode: true,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const verifyOTP = async (
	cellphone: string,
	code: string,
	country_code: string,
	skipAccountError: boolean = false,
) => {
	try {
		const body = {
			code,
			cellphone,
			country_code: `+${country_code}`,
			acquisition_channel_id: 5,
		};
		const response = await post(HERU_API.VERIFY_OTP_CODE, body, {
			authCode: true,
			skipAccountError: skipAccountError,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const generateOtp = async (body: object) => {
	try {
		const response = await post(HERU_API.GENERATE_OTP, body, {
			token: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

export { sendOTP, verifyOTP, generateOtp };
