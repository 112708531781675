const currentDate = new Date();

const currentDay = currentDate.getDate();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();

const getQuarter = (month: number) => {
	switch (month) {
		case 1:
			return 'Enero - Marzo';
		case 2:
			return 'Abril - Junio';
		case 3:
			return 'Julio - Septiembre';
		case 4:
			return 'Octubre - Diciembre';
		default:
			return '';
	}
};

const getQuarterType = (quarter: number) => {
	switch (quarter) {
		case 1:
			return 'first-quarter';
		case 2:
			return 'second-quarter';
		case 3:
			return 'third-quarter';
		case 4:
			return 'fourth-quarter';
		default:
			return '';
	}
};

const getBimester = (month: number) => {
	switch (month) {
		case 1:
			return 'Enero - Febrero';
		case 2:
			return 'Marzo - Abril';
		case 3:
			return 'Mayo - Junio';
		case 4:
			return 'Julio - Agosto';
		case 5:
			return 'Septiembre - Octubre';
		case 6:
			return 'Noviembre - Diciembre';
		default:
			return '';
	}
};

const getBimesterType = (bimester: number) => {
	switch (bimester) {
		case 1:
			return 'first-bimester';
		case 2:
			return 'second-bimester';
		case 3:
			return 'third-bimester';
		case 4:
			return 'fourth-bimester';
		case 5:
			return 'fifth-bimester';
		case 6:
			return 'sixth-bimester';
		default:
			return '';
	}
};

function getLastYears(numberOfYears: number, startFrom?: number) {
	const currentYear = new Date().getFullYear();
	const lastFiveYears = [];

	const start = startFrom || currentYear;

	for (let i = 0; i < numberOfYears; i++) {
		lastFiveYears.push(start - i);
	}

	return lastFiveYears;
}

export const DATES = {
	currentMonth,
	currentYear,
	currentDay,
};

const getLastRegularizationMonth = () => {
	const currentDate = new Date();

	let lastMonth = currentDate.getMonth();
	let lastYear = currentDate.getFullYear();

	if (lastMonth === 0) {
		lastMonth = 12;
		lastYear--;
	}

	const regularizationMonth = lastMonth - 1 === 0 ? 12 : lastMonth;
	
	return {
		month: regularizationMonth,
		year: lastYear,
	};
};
const getLastRegularizationQuarter = () => {
	const currentDate = new Date();

	let lastMonth = currentDate.getMonth();
	let lastYear = currentDate.getFullYear();

	const quarters = {
		1: {
			year: lastYear,
			period: 'Enero - Abril',
			type: 'first-quarter',
		},
		2: {
			year: lastYear,
			period: 'Mayo - Agosto',
			type: 'second-quarter',
		},
		3: {
			year: lastYear,
			period: 'Septiembre - Diciembre',
			type: 'third-quarter',
		},
	};

	if (lastMonth >= 1 && lastMonth <= 4) {
		return quarters[3];
	} else if (lastMonth >= 5 && lastMonth <= 8) {
		return quarters[1];
	} else if (lastMonth >= 9 && lastMonth <= 12) {
		return quarters[2];
	}

	return quarters[1];
};

const getCurrentYear = () => {
	const currentDate = new Date();
	return currentDate.getFullYear();
}

export {
	getQuarter,
	getQuarterType,
	getBimester,
	getBimesterType,
	getLastYears,
	getLastRegularizationMonth,
	getLastRegularizationQuarter,
	getCurrentYear,
};
