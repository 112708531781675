import React from 'react';

const NotFound: React.FC = () => {
	return (
		<div>
			<h1>Error Page Not Found</h1>
		</div>
	);
};

export default React.memo(NotFound);
