import { useSelector } from 'react-redux';
import { TRowData } from '../../../components/molecules/Table';
import {
	INVOICE_TYPES_CODES,
	getRetentionTypeLabel,
	getStatusLabel,
	getTypeNameByCode,
} from '../../../shared/constants/fiscalDocuments';
import { getFormatDate, getMonthName } from '../../../shared/utils/dates.util';
import { RootState } from '../../../redux/store';
import {
	capitalizeText,
	formatCurrency,
} from '../../../shared/utils/general.util';
import { CircularProgress } from '@mui/material';
import { DownloadSimple, Eye } from '@phosphor-icons/react';
import { FiscalDocumentsLists } from '../organisms/TableFiscalDocuments';
import { FiscalDocumentInvoicesData } from '../../../@types/FiscalDocuments.type';
import useCleverTapEvent from '../../../hooks/useClevertapEvent';
import {
	ANALYTICS_EVENTS,
	EVENT_TYPES,
} from '../../../shared/constants/events';
import ROUTES from '../../../shared/constants/routes';

const GetRowsDataFiscalDocument = (
	handleShowDetail: (id: string) => void,
	fiscalDocuments: FiscalDocumentsLists,
	isLoading: {
		detail: boolean;
	},
	selectedDocumentId: {
		loadingSelected: number | null;
		selected: number | null;
	},
	isMobile: boolean,
) => {
	const logClevertapEvent = useCleverTapEvent();
	const taxPayer = useSelector((state: RootState) => state.taxProfile.taxPayer);
	const isViewedFromTaxboard = location.pathname === ROUTES.TAXES;

	const filteredInvoices = fiscalDocuments?.invoice?.data || [];
	const filteredRetentions = fiscalDocuments?.retention?.data || [];
	const filteredReceipts = fiscalDocuments?.receipt?.data || [];

	const checkRowIsSelected = (id: string) => {
		if (isLoading.detail && Number(id) === selectedDocumentId.loadingSelected) {
			return Number(id) === selectedDocumentId.loadingSelected;
		}

		return Number(id) === selectedDocumentId.selected;
	};

	const invoiceIsReceived = (receiver_id: number) => {
		return receiver_id === taxPayer?.id;
	};

	const handleDownload = (
		url: string,
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		logClevertapEvent(ANALYTICS_EVENTS.FISCAL_DOCUMENTS, {
			event_type: isViewedFromTaxboard
				? EVENT_TYPES.TAXBOARD_DOCUMENT_DOWNLOADED
				: EVENT_TYPES.FISCAL_DOCUMENTS_DETAIL_ACTION,
			source: 'receipt',
		});

		e.preventDefault();
		e.stopPropagation();

		window.open(url, '_blank', 'noreferrer');
	};

	const getTypeOfMovement = (invoice: FiscalDocumentInvoicesData) => {
		if (invoice?.invoice_type == INVOICE_TYPES_CODES.Ingresos) {
			return invoiceIsReceived(
				invoice?.receiverData?.responseReceiverData?.[0]?.issuer_id,
			)
				? 'Gastos'
				: 'Ingresos';
		}
		return getTypeNameByCode(invoice?.invoice_type);
	};

	const RowDataInvoices: {
		invoice: TRowData[];
		retention: TRowData[];
		receipt: TRowData[];
	} = {
		invoice: filteredInvoices?.map((invoice) => ({
			onClick: () => handleShowDetail(invoice?.invoice_id),
			rowStyle: `hover:bg-h-neutral-94 transition cursor-pointer ${
				checkRowIsSelected(invoice?.invoice_id) && 'bg-h-neutral-94'
			}`,
			rowContent: {
				creation_date: {
					content: `${getFormatDate(
						invoice?.date || '',
						'DD MMM YYYY',
						false,
						true,
					)}`,
					textStyle: 'py-1',
				},
				invoice_type: {
					content: getTypeOfMovement(invoice),
				},
				emission_type: {
					content: invoiceIsReceived(
						invoice?.receiverData?.responseReceiverData?.[0]?.issuer_id,
					)
						? 'Recibida'
						: 'Emitida',
				},
				description: {
					content: capitalizeText(invoice?.description || '-'),
					textStyle: 'max-w-[250px] truncate',
				},
				type: {
					content: capitalizeText(
						invoiceIsReceived(
							invoice?.receiverData?.responseReceiverData?.[0]?.issuer_id,
						)
							? invoice?.issuerData?.responseIssuerData?.[0]?.name || '-'
							: invoice?.receiverData?.responseReceiverData?.[0]?.name || '-',
					),
				},
				status: {
					content: getStatusLabel(invoice?.status),
				},
				total: {
					content: formatCurrency(invoice?.total_amount || 0),
				},
				deductible_amount: {
					content: formatCurrency(invoice?.deductible_amount || 0),
				},
				actions: {
					content:
						isLoading.detail &&
						Number(invoice?.invoice_id) ===
							selectedDocumentId.loadingSelected ? (
							<CircularProgress size={20} />
						) : (
							<Eye
								color='var(--h-primary)'
								width={20}
								height={20}
								weight={
									checkRowIsSelected(invoice?.invoice_id) ? 'fill' : 'regular'
								}
								className='ml-2'
							/>
						),
				},
			},
		})),
		retention: filteredRetentions?.map((retention) => ({
			onClick: () => handleShowDetail(retention?.retention_id),
			rowStyle: `hover:bg-h-neutral-94 transition cursor-pointer ${
				Number(retention?.retention_id) === selectedDocumentId.selected &&
				'bg-h-neutral-94'
			}`,
			rowContent: {
				creation_date: {
					content: `${getFormatDate(
						retention?.date || '',
						'DD MMM YYYY',
						false,
						true,
					)}`,
					textStyle: 'py-1',
				},
				certificate_type: {
					content: getRetentionTypeLabel(retention?.retention_type),
				},
				emission_type: {
					content: invoiceIsReceived(
						retention?.receiverData?.responseReceiverData?.[0]?.issuer_id,
					)
						? 'Recibida'
						: 'Emitida',
				},
				issuer: {
					content: capitalizeText(
						retention?.issuerData?.responseIssuerData?.[0]?.name,
					),
				},
				rfc: {
					content: retention?.code_issuer,
				},
				total: { content: formatCurrency(retention?.total_amount || 0) },
				deductible_amount: {
					content: formatCurrency(retention?.deductible_amount || 0),
				},
				actions: {
					content:
						isLoading.detail &&
						Number(retention?.retention_id) ===
							selectedDocumentId.loadingSelected ? (
							<CircularProgress size={20} />
						) : (
							<Eye
								color='var(--h-primary)'
								width={20}
								height={20}
								className='ml-2'
								weight={
									Number(retention?.retention_id) ===
									selectedDocumentId.selected
										? 'fill'
										: 'regular'
								}
							/>
						),
				},
			},
		})),
		receipt: filteredReceipts?.map((receipt) => ({
			onClick: () => {
				if (receipt?.declaration_detail_document) {
					handleShowDetail(receipt?.id);
				}
			},
			rowStyle: `hover:bg-h-neutral-94 transition cursor-pointer 
			${
				Number(receipt?.id) === selectedDocumentId.selected &&
				!isMobile &&
				'bg-h-neutral-94'
			} 
			${receipt?.declaration_detail_document ? '' : 'hover:bg-white cursor-default'}`,
			rowContent: {
				period_date: {
					content: `${getMonthName(receipt?.month, true)} ${receipt?.year}`,
				},
				creation_date: {
					content: `${getFormatDate(
						receipt?.presented_at || '',
						'DD MMM YYYY',
						false,
						true,
					)}`,
					textStyle: 'py-1',
				},
				receipt_type: {
					content: receipt?.type === 'NORMAL' ? 'Normal' : 'Complementaria',
				},
				fiscal_result: {
					content:
						receipt?.amount > 0 ? formatCurrency(receipt?.amount) : '$0.00',
				},
				payment_status: {
					content:
						receipt?.amount === 0
							? 'Presentada'
							: receipt?.paid_id && receipt?.payment_date
								? 'Pagado'
								: 'Pendiente de pago al SAT',
				},
				payment_date: {
					content: receipt?.payment_date
						? `${getFormatDate(
								receipt?.payment_date || '',
								'DD MMM YYYY',
								false,
								true,
							)}`
						: '-',
				},
				download: {
					content: receipt?.declaration_url ? (
						<div
							onClick={(e) => handleDownload(receipt?.declaration_url, e)}
							className='flex items-center gap-2 text-h-medium text-sm justify-center w-fit mx-auto opacity-70 cursor-pointer hover:opacity-100'
						>
							Descargar
							<DownloadSimple
								weight='bold'
								width={16}
								height={16}
							/>
						</div>
					) : (
						'-'
					),
				},
				actions: {
					content: receipt?.declaration_detail_document ? (
						isLoading.detail &&
						Number(receipt?.id) === selectedDocumentId.loadingSelected ? (
							<CircularProgress size={20} />
						) : (
							<Eye
								color='var(--h-primary)'
								width={20}
								height={20}
								className='ml-2'
								weight={
									Number(receipt?.id) === selectedDocumentId.selected
										? 'fill'
										: 'regular'
								}
							/>
						)
					) : (
						''
					),
				},
			},
		})),
	};

	return RowDataInvoices;
};

export default GetRowsDataFiscalDocument;
