export interface SubscriptionType {
	id: number;
	user_id: number;
	created_at: string;
	current_period_start: string;
	current_period_end: string;
	product_version: ProductVersion;
	plan: { [key: string]: null };
	additional_information: ResourceAdditionalInformation;
	renew_at_period_end: boolean;
	active: boolean;
	coupon: ResourceCoupon;
	retries_left: number;
	cancellation_candidate: boolean;
	shopping_item?: ShoppingItem;
	payment_status?: string;
	payment_type?: string;
	last_transaction: LastTransaction;
	failure_reason: string;
	payment_details: PaymentDetails;
	subscription_treatment: string;
}

export interface ResourceAdditionalInformation {
	work_type?: string;
	work_vehicle?: string;
	year?: number;
	month?: number;
	regimes?: string | any;
}

export interface ResourceCoupon {
	id: number;
	name: string;
	code: string;
	duration: string;
	duration_in_periods: null;
	percent_off: number;
	extra_fields: ExtraFields | null;
	weight: number | null;
	active_subscription: boolean;
	redem_by: null;
	redeem_from_date: null;
	max_redemptions: null;
	max_concurrent_redemptions: null;
	product_version: ProductVersion[];
	product: Product[];
	times_redeemed: number;
	created_at: string;
	updated_at: string;
}

export interface ExtraFields {
	image_url: string;
	footer_text: string;
	header_text: string;
}

export interface ProductVersion {
	id: number;
	product_id: number;
	currency_id: number;
	price: number;
	periodicity: number;
	description: string;
	created_at: string;
	product?: Product;
}

export interface Product {
	id: number;
	name: string;
	default_product_version_id: number;
	description: string;
	additional_fields: any[];
	fiscal_concept: string;
	fiscal_code: string;
	icon_url: string;
	color: Color;
	is_purchasable: boolean;
	long_description: LongDescription;
	tags: Tag[];
	product_versions?: ProductVersion[];
	hidden?: boolean;
	slug: string;
	required_fields?: null;
	coupon?: ProductCoupon;
	benefits: string[];
	regime: string[];
}

export enum Color {
	The0091Ff = '#0091FF',
}

export interface ProductCoupon {}

export interface LongDescription {
	more_details: MoreDetail[];
	product_title: string;
	cancel_message: string;
	short_description: string;
	button_action_label: string;
	product_description_top: string;
	product_description_bottom: string;
}

export interface MoreDetail {
	terms: string;
	icon_url: string;
	icon_width: string;
	description: string;
	icon_height: string;
	icon_details: string;
	certificate_head: string;
	notice_of_privacy: string;
	icon_at_background: boolean | string;
}

export interface Tag {
	name: string;
	title: string;
}

export interface LastTransaction {
	token_id: null;
	succeed: boolean;
	additional_information: LastTransactionAdditionalInformation;
	disputed: null;
	shopping_cart_id: number;
	amount: number;
	created_at: string;
	id: number;
	customer_id: number;
	is_finished: boolean;
	platform_transaction_id: string;
	type: number;
	reference: string;
	updated_at: string;
}

export interface LastTransactionAdditionalInformation {
	amount: number;
	order_id: string;
	description: string;
	payment_reference: null;
}

export interface ShoppingItem {
	id: number;
	product_version_id: number;
	created_at: string;
	updated_at: string;
	purchase_type: string;
	coupon: ShoppingItemCoupon;
	base_price: number;
	discount: number;
	subtotal: number;
	taxes: number;
	total: number;
	additional_information: ShoppingItemAdditionalInformation;
	shopping_cart: ShoppingCart;
}

export interface ShoppingItemAdditionalInformation {
	year: number;
	month: number;
}

export interface ShoppingItemCoupon {
	id: number;
	name: string;
	code: string;
	duration: string;
	percent_off: number;
	active_subscription: boolean;
}

export interface ShoppingCart {
	id: number;
	user_id: number;
	status: string;
	base_price: number;
	discount: number;
	subtotal: number;
	taxes: number;
	total: number;
	transaction_id: number;
	created_at: string;
	updated_at: string;
}

export interface SubscriptionEvent {
	id: number;
	subscription: SubscriptionType;
	event: string;
	payment: Payment;
	successful: boolean;
	has_invoice: boolean;
	datetime: string;
}

export interface Payment {
	id: number;
	user_id: number;
	worker_id: number;
	total: number;
	concept: string;
	breakdown: Breakdown;
	payment_method: string;
	successful: boolean;
	failure_reason: string;
	error_code: string;
	payment_intent_uuid: string;
	created_at: string;
	subtotal: number;
	discount: number;
	tax: number;
	card_id: string;
	payment_details: PaymentDetails;
	token_id: string;
}

export interface Breakdown {}

export interface PaymentDetails {
	brand: string;
	origin: string;
	expires: string;
	card_type: string;
	last_four: string;
}

export enum SubscriptionEventTypes {
	SubscriptionCreated = 'SUBSCRIPTION_CREATED',
	SubscriptionRenewed = 'SUBSCRIPTION_RENEWED',
	GracePeriodRenewal = 'GRACE_PERIOD_RENEWAL',
	SubscriptionCancelationVoidedHpal = 'SUBSCRIPTION_CANCELATION_VOIDED_HPAL',
	SubscriptionCancelationVoidedUser = 'SUBSCRIPTION_CANCELATION_VOIDED_USER',
	SubscriptionCancelAtPeriodEnd = 'SUBSCRIPTION_CANCEL_AT_PERIOD_END',
	SubscriptionCanceled = 'SUBSCRIPTION_CANCELED',
	SubcriptionCanceledByGracePeriodMigration = 'SUBSCRIPTION_CANCELED_BY_GRACE_PERIOD_MIGRATION',
	SubscriptionCouponUpdated = 'SUBSCRIPTION_COUPON_UPDATED',
	SubscriptionCreatedByGracePeriodMigration = 'SUBSCRIPTION_CREATED_BY_GRACE_PERIOD_MIGRATION',
	SubscriptionDeactivated = 'subscription_deactivated',
	SubscriptionRenewAttempt = 'SUBSCRIPTION_RENEW_ATTEMPT',
	SubscriptionRenewAttemptOnDemand = 'SUBSCRIPTION_RENEW_ATTEMPT_ON_DEMAND',
	SubscriptionRenewLastAttempt = 'SUBSCRIPTION_RENEW_LAST_ATTEMPT',
	SubscriptionWaitLastAttempt = 'SUBSCRIPTION_WAIT_LAST_ATTEMPT',
}

export enum SubscriptionStatus {
	failed = 'failed',
	success = 'success',
}
