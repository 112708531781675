import React, { useState, useCallback } from 'react';
import OTPInput from 'react-otp-input';
import { sendOTP, verifyOTP } from '../../services/auth.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToken, login } from '../../redux/slices/auth.slice';
import Text from '../../components/atoms/Text';
import { PencilSimpleLine, CircleNotch } from '@phosphor-icons/react';
import Counter from '../../components/atoms/Counter';
import { setProfile } from '../../redux/slices/profile.slice';
import { HERU_UTM, HERU_UTM_VALUES } from '../../shared/constants/utms';
import toast, { Toaster } from 'react-hot-toast';
import { VerifyOTP } from '../../@types/Auth.type';
import {
	getUserProfile,
	patchUserProfile,
} from '../../services/profile.service';
import LOCAL_STORAGE_KEY from '../../shared/constants/localStorageKey';
import LinkButton from '../../components/atoms/LinkButton';
import ROUTES from '../../shared/constants/routes';
import { ANALYTICS_EVENTS, EVENT_TYPES } from '../../shared/constants/events';
import SESSION_STORAGE_KEY from '../../shared/constants/sessionStorageKey';
import useCleverTapEvent from '../../hooks/useClevertapEvent';
import {
	isLocalStorageEnabled,
	isSessionStorageEnabled,
} from '../../shared/utils/general.util';
import { setOverwriteUtm, updateUTMs } from '../../redux/slices/cart.slice';
import { RootState } from '../../redux/store';
import clevertap from 'clevertap-web-sdk';

interface Props {
	phone: string;
	countryCode: string;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Verify: React.FC<Props> = ({ phone, countryCode, setStep }) => {
	const [code, setCode] = useState('');
	const [time, setTime] = useState(15);
	const [stop, setStop] = useState(false);
	const [play, setplay] = useState(false);
	const [counter, setCounter] = useState(0);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [errors, setErrors] = useState({
		code: false,
	});

	const [isLoading, setIsLoading] = useState({
		resend: false,
		verifyOTP: false,
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	let [searchParams] = useSearchParams();
	const { products } = useSelector((state: RootState) => state.cart);
	const { goTo: goToURl } = useSelector((state: RootState) => state.auth);
	const { utmInfo } = useSelector((state: RootState) => state.cart);

	const utm_source = searchParams.get(HERU_UTM.SOURCE);
	const utm_medium = searchParams.get(HERU_UTM.MEDIUM);
	const utm_campaign = searchParams.get(HERU_UTM.CAMPAIGN);
	const logClevertapEvent = useCleverTapEvent();

	const { dataLayer } = window as any;

	const notify = (message: string) =>
		toast.error(message, {
			position: 'top-right',
		});

	const onVerifyOTP = useCallback(
		async (code: string) => {
			try {
				const isSessionStoreAvailable = isSessionStorageEnabled();

				const productsFromStorage = isSessionStoreAvailable
					? sessionStorage.getItem(SESSION_STORAGE_KEY.PRODUCTS_ADDED)
					: products;

				const goTo = isSessionStoreAvailable
					? sessionStorage.getItem(SESSION_STORAGE_KEY.GO_TO)
					: goToURl;

				setIsLoading({ ...isLoading, verifyOTP: true });
				const response = (await verifyOTP(
					phone,
					code,
					countryCode,
					true,
				)) as VerifyOTP;

				logClevertapEvent(ANALYTICS_EVENTS.LOGIN, {
					event_type: EVENT_TYPES.LOGIN,
					id: response?.id,
					phone: phone,
				});
				dataLayer.push({
					event: ANALYTICS_EVENTS.LOGIN,
					phone: phone,
					user_id: response?.id,
				});

				const isLocalStorageAvailable = isLocalStorageEnabled();
				if (isLocalStorageAvailable) {
					localStorage.setItem(
						LOCAL_STORAGE_KEY.TOKEN,
						response.access_token.access_token,
					);
					localStorage.setItem(
						LOCAL_STORAGE_KEY.REFRESH,
						response.refresh_token.refresh_token,
					);
				}

				dispatch(login());
				dispatch(addToken(response.access_token.access_token));

				const userInfo = await getUserProfile(
					response.access_token.access_token,
				);
				if (isLocalStorageAvailable) {
					localStorage.setItem(
						LOCAL_STORAGE_KEY.USER_NAME,
						userInfo?.first_name,
					);
				}
				clevertap.onUserLogin.push({
					Site: {
						Name: `${userInfo?.first_name} ${userInfo?.father_surname}`,
						Identity: userInfo?.id,
						Email: userInfo?.email,
						Phone: `${userInfo.country_code}${userInfo.cellphone}`,
					},
				});
				dispatch(setProfile(userInfo));

				if (userInfo?.email) {
					if (isLocalStorageAvailable) {
						localStorage.setItem(
							LOCAL_STORAGE_KEY.USER_NAME,
							userInfo.first_name,
						);
					}
					if (productsFromStorage) {
						navigate(ROUTES.CHECKOUT);
						return;
					}

					if (goTo) {
						navigate(goTo);
						return;
					}
					navigate(ROUTES.ROOT);
					return;
				}

				const isSessionAvailable = isSessionStorageEnabled();

				const utmCampaign = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.CAMPAIGN)
					: utmInfo?.utm_campaign || null;

				const utmSource = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.SOURCE)
					: utmInfo?.utm_source || null;

				const utmMedium = isSessionAvailable
					? sessionStorage.getItem(LOCAL_STORAGE_KEY.MEDIUM)
					: utmInfo?.utm_medium || null;

				const body = {
					acquisition_channel_id: 5,
					utm_source:
						utm_source ?? utmSource ?? HERU_UTM_VALUES.DIRECT_PAGE_SEARCH,
					utm_medium:
						utm_medium ?? utmMedium ?? HERU_UTM_VALUES.SIGNUP_LANDING_PAGE,
					utm_campaign:
						utm_campaign ?? utmCampaign ?? HERU_UTM_VALUES.ORGANIC_SEARCH,
				};

				if (utm_source && utm_medium && utm_campaign) {
					if (isSessionStoreAvailable) {
						const overwriteUtmKey = LOCAL_STORAGE_KEY.OVERWRITE_UTM;
						const campaignKey = LOCAL_STORAGE_KEY.CAMPAIGN;
						const mediumKey = LOCAL_STORAGE_KEY.MEDIUM;
						const sourceKey = LOCAL_STORAGE_KEY.SOURCE;

						let overwriteUtm = sessionStorage.getItem(overwriteUtmKey);

						if (overwriteUtm === null) {
							sessionStorage.setItem(overwriteUtmKey, 'true');
							overwriteUtm = 'true';
						}

						if (overwriteUtm === 'true') {
							sessionStorage.setItem(campaignKey, utm_campaign);
							sessionStorage.setItem(mediumKey, utm_medium);
							sessionStorage.setItem(sourceKey, utm_source);
						} else {
							if (!sessionStorage.getItem(campaignKey)) {
								sessionStorage.setItem(campaignKey, utm_campaign);
							}
							if (!sessionStorage.getItem(mediumKey)) {
								sessionStorage.setItem(mediumKey, utm_medium);
							}
							if (!sessionStorage.getItem(sourceKey)) {
								sessionStorage.setItem(sourceKey, utm_source);
							}
						}
					} else {
						const currentOverwriteUtm = useSelector(
							(state: RootState) => state.cart.overwriteUtm,
						);

						if (
							currentOverwriteUtm === undefined ||
							currentOverwriteUtm === null
						) {
							dispatch(setOverwriteUtm(true));
						}

						if (currentOverwriteUtm === true) {
							dispatch(
								updateUTMs({
									utm_campaign,
									utm_medium,
									utm_source,
								}),
							);
						} else {
							const currentUTMs = useSelector(
								(state: RootState) => state.cart.utmInfo,
							);
							const updatedUTMs = {
								...currentUTMs,
								utm_campaign: currentUTMs?.utm_campaign || utm_campaign,
								utm_medium: currentUTMs?.utm_medium || utm_medium,
								utm_source: currentUTMs?.utm_source || utm_source,
							};
							dispatch(updateUTMs(updatedUTMs));
						}
					}
				}

				await patchUserProfile(body, response.access_token.access_token);

				setStep(3);
			} catch (error) {
				setErrors({ ...errors, code: true });
				setCode('');
				notify('El código que ingresaste es incorrecto.');
				setIsLoading({ ...isLoading, verifyOTP: false });
			}
		},
		[code],
	);

	const handleChangeOtp = (otp: string) => {
		setErrors({ ...errors, code: false });
		setCode(otp);
		if (otp.length < 6) return;
		onVerifyOTP(otp);
	};

	const handleGetTime = (time: number) => {
		setTime(time);
		setDisabledBtn(false);
		setStop(true);
		setplay(false);
	};

	const onResend = async () => {
		setCode('');
		setIsLoading({ ...isLoading, resend: true });
		try {
			const count = counter + 1;
			const whatsapp = count === 2;

			await sendOTP(phone, countryCode, whatsapp ? 'whatsapp' : 'sms');
			setIsLoading({ ...isLoading, resend: false });
			setTime(15);
			setplay(true);
			setDisabledBtn(true);
			setCounter(count);
		} catch (error) {
			setIsLoading({ ...isLoading, resend: false });
			notify('Ocurrió un error al enviar el código.');
		}
	};

	return (
		<div>
			<Toaster />
			<Text
				size='l'
				weight='medium'
				color='white'
			>
				Ingresa el código de verificación
			</Text>
			<Text
				color='white'
				sx='opacity-70 flex gap-2 items-center'
			>
				Enviado al +{countryCode} {phone}
				<button onClick={() => setStep(1)}>
					<PencilSimpleLine
						size={18}
						weight='regular'
					/>
				</button>
			</Text>
			{isLoading.verifyOTP ? (
				<div className='h-[3.5rem] rounded-lg border-2 border-white/20 grid place-items-center my-4'>
					<span className='animate-spin'>
						<CircleNotch
							size={26}
							weight='bold'
							color='white'
						/>
					</span>
				</div>
			) : (
				<OTPInput
					value={code}
					onChange={handleChangeOtp}
					numInputs={6}
					containerStyle={{ margin: '1rem 0' }}
					inputStyle={
						'h-12 !w-10 text-white border-[1px] border-h-blue-100 bg-transparent rounded-lg text-center outline-none focus:border-white focus:border-2 ' +
						(errors.code
							? 'border-h-red-40 !text-h-red-40 focus:border-h-red-40'
							: '')
					}
					inputType='tel'
					renderSeparator={<span style={{ width: '0.6rem' }} />}
					renderInput={(props) => <input {...props} />}
					shouldAutoFocus
				/>
			)}
			<div className=' mb-2 text-white text-sm flex items-center gap-2 flex-wrap'>
				<Text
					color='white'
					size='s'
				>
					¿No recibiste el código? —
				</Text>
				{time !== 0 ? (
					<Counter
						value={time}
						unit={'s'}
						stop={stop}
						getTime={handleGetTime}
						play={play}
					/>
				) : (
					<LinkButton
						label={
							isLoading.resend
								? 'Enviando código...'
								: counter === 1
									? 'Reenviar código por WhatsApp'
									: 'Reenviar código'
						}
						onClick={onResend}
						disabled={disabledBtn || isLoading.resend}
					/>
				)}
			</div>
		</div>
	);
};
export default React.memo(Verify);
