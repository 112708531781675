import Button from '../../../components/atoms/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { postExtractionsOnDemand } from '../../../services/taxes.service';
import { ArrowsClockwise } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import LOCAL_STORAGE_KEY from '../../../shared/constants/localStorageKey';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { FiscalDocumentType } from '../../../@types/FiscalDocuments.type';
import { isLocalStorageEnabled } from '../../../shared/utils/general.util';

interface Props {
	align?: 'right' | 'center';
}

const UpdateOnDemand: React.FC<Props> = ({ align }) => {
	const [periodLastExtractionDate, setPeriodLastExtractionDate] =
		useState<string>('');
	const { isRecentlyLinked } = useSelector(
		(state: RootState) => state.satLinkStatus,
	);
	const { year, month } = useSelector((state: RootState) => state.taxCalendar);
	const userProfile = useSelector((state: RootState) => state.profile.user);

	const checkIsRecentlyUpdatedExtraction = (date: string) => {
		const lastExtractionDate = new Date(date);
		const currentDate = new Date();
		const isRecentlyUpdated =
			currentDate.getTime() - lastExtractionDate.getTime() < 900000;
		return isRecentlyUpdated;
	};

	const sendExtractionsOnDemand = async () => {
		if (
			isRecentlyLinked ||
			checkIsRecentlyUpdatedExtraction(periodLastExtractionDate)
		)
			return;

		try {
			const platecDocumentTypes = [
				FiscalDocumentType.INVOICE,
				FiscalDocumentType.RETENTION,
			];
			platecDocumentTypes.forEach(async (type) => {
				await postExtractionsOnDemand(userProfile?.id!, type, year, month);
			});

			if (!isLocalStorageEnabled()) return;

			const lastExtractionDate = new Date();

			const lastExtractionsDates = JSON.parse(
				localStorage.getItem(LOCAL_STORAGE_KEY.LAST_EXTRACTION_DATES) || '[]',
			);

			const newLastExtractionsDates = {
				...lastExtractionsDates,
				[`${month}_${year}`]: lastExtractionDate.toString(),
			};

			localStorage.setItem(
				LOCAL_STORAGE_KEY.LAST_EXTRACTION_DATES,
				JSON.stringify(newLastExtractionsDates),
			);

			setPeriodLastExtractionDate(lastExtractionDate.toString());
		} catch (error) {}
	};

	const getlastExtractionDateFormatted = () => {
		const formatedDate = dayjs(periodLastExtractionDate).format(
			'hh:mm A [de] DD/MM/YYYY',
		);

		return checkIsRecentlyUpdatedExtraction(periodLastExtractionDate)
			? 'Regresa más tarde para ver tu información actualizada.'
			: `Información actualizada ${formatedDate}`;
	};

	useEffect(() => {
		if (!isLocalStorageEnabled()) return;

		const lastExtractionsDates = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_KEY.LAST_EXTRACTION_DATES) || '[]',
		);
		const getLastExtractionDate = lastExtractionsDates[`${month}_${year}`];

		setPeriodLastExtractionDate(getLastExtractionDate || '');
	}, []);

	const isMobile = useMediaQuery('(max-width: 640px)');

	if (isRecentlyLinked) return null;

	return (
		<>
			<Button
				disabled={checkIsRecentlyUpdatedExtraction(periodLastExtractionDate)}
				onClick={sendExtractionsOnDemand}
				variant='tertiary'
				label={
					checkIsRecentlyUpdatedExtraction(periodLastExtractionDate)
						? 'Actualizando información'
						: 'Actualizar información'
				}
				icon={<ArrowsClockwise />}
				sx={`
				!border-none !shadow-none mb-2 ml-none lg:ml-auto !py-1 !px-2 !h-auto !text-h-neutral-40 !font-light !text-sm 
				${align === 'right' ? '!w-fit' : '!w-full lg:!w-fit'} 
				${
					isRecentlyLinked ||
					(checkIsRecentlyUpdatedExtraction(periodLastExtractionDate) &&
						'opacity-60 active:!translate-y-[0px] !bg-transparent hover:!bg-transparent !cursor-not-allowed')
				}`}
			/>

			{periodLastExtractionDate !== '' && (
				<Text
					size={isMobile ? 'mini-1' : 'caption'}
					weight='light'
					color='light'
					sx={`
					justify-end -mt-2 mx-2 mb-4 lg:text-right sm:max-w-[100%] text-center 
					${align === 'right' ? 'max-w-[70%]' : ' max-w-[100%]'}
					`}
				>
					{getlastExtractionDateFormatted()}
				</Text>
			)}
		</>
	);
};

export default UpdateOnDemand;
