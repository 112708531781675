import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CaretLeft, CaretRight, XCircle } from '@phosphor-icons/react';

import Heading from '../../../components/atoms/Heading';
import Button from '../../../components/atoms/Button';
import Text from '../../../components/atoms/Text';
import Divider from '../../../components/atoms/Divider';
import Tabs from '../../../components/molecules/Tabs';
import { itemsPeriods } from '../organism/Calendar';
import { RootState } from '../../../redux/store';
import { DATES } from '../../../shared/utils/dates';
import { TPeriod } from '../../../@types/Calendar.type';

interface Props {
	setShowModal: (val: boolean) => void;
	setActiveTab: (val: TPeriod) => void;
	setValue: (name: 'year', value: number) => void;
	activeTab: TPeriod;
}

const minYear = DATES.currentYear - 4;

const ModalFilterPeriod: React.FC<Props> = ({
	setShowModal,
	setActiveTab,
	setValue,
	activeTab,
}) => {
	const { year } = useSelector((state: RootState) => state.taxCalendar);
	const [currentYear, setCurrentYear] = useState(year);
	const [currentPeriod, setCurrentPeriod] = useState<TPeriod>(activeTab);

	const handlerApplyFilters = () => {
		setValue('year', currentYear);
		setActiveTab(currentPeriod);
		setShowModal(false);
	};

	return (
		<div className=''>
			<div className='px-4 py-3 flex flex-row justify-between'>
				<Heading
					size='xs'
					weight='bold'
				>
					Mis declaraciones
				</Heading>
				<Button
					onClick={() => setShowModal(false)}
					label=''
					variant='text'
					labelColor='dark'
					labelSize='caption'
					beforeIcon={
						<XCircle
							size={26}
							weight='fill'
							color='var(--h-gray-20)'
						/>
					}
				/>
			</div>
			<Divider />
			<div className='px-4 mt-6 pb-4'>
				<Text
					size='mini-1'
					color='gray'
				>
					Tipo
				</Text>
				<Tabs
					items={itemsPeriods}
					activeTabDefault={currentPeriod}
					onClickTab={(val) => setCurrentPeriod(val as TPeriod)}
					sx='!rounded-full !shadow-none border-[#E6E6E6] border-[0.5px] ml-0 !px-2'
					sizes='small'
				/>
				{currentPeriod === 'monthly' && (
					<>
						<Text
							size='mini-1'
							color='gray'
						>
							Año
						</Text>

						<div className='flex flex-row justify-between bg-h-neutral-98 border-h-neutral-90 border rounded-full py-1 px-2'>
							<Button
								sx={currentYear === minYear ? '!opacity-20' : ''}
								disabled={currentYear === minYear}
								onClick={() => setCurrentYear(currentYear - 1)}
								label=''
								variant='text'
								labelColor='gray'
								labelSize='caption'
								beforeIcon={
									<CaretLeft
										size={22}
										weight='bold'
										color='var(--h-gray-40)'
									/>
								}
							/>
							<Text
								size='caption'
								color='medium'
							>
								{currentYear}
							</Text>
							<Button
								disabled={currentYear === DATES.currentYear}
								onClick={() => setCurrentYear(currentYear + 1)}
								label=''
								variant='text'
								labelColor='gray'
								labelSize='caption'
								beforeIcon={
									<CaretRight
										size={22}
										weight='bold'
										color='var(--h-gray-40)'
									/>
								}
								sx={year === DATES.currentYear ? '!opacity-20' : ''}
							/>
						</div>
					</>
				)}
			</div>
			<Divider />
			<div className='p-4 pt-0'>
				<Button
					onClick={handlerApplyFilters}
					label='Aplicar filtros'
					sx='w-full'
				/>
			</div>
		</div>
	);
};

export default ModalFilterPeriod;
