import { useMediaQuery } from '@mui/material';
import Heading from '../../../components/atoms/Heading';
import Text from '../../../components/atoms/Text';
import Tabs from '../../../components/molecules/Tabs';
import Selector from '../../../components/atoms/Selector';
import { useForm } from 'react-hook-form';
import Button from '../../../components/atoms/Button';
import { Funnel, Info } from '@phosphor-icons/react';
import { getMonthName } from '../../../shared/utils/dates.util';
import { arrayRange } from '../../../shared/utils/general.util';
import { useDispatch, useSelector } from 'react-redux';
import { linkedAccount, startLoader } from '../../../redux/slices/sat.slice';
import { fetchSATLinkedById } from '../../../services/satLinking.service';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SATLinkingForm from '../../../components/molecules/SATLinkingForm';
import { TPeriod } from '../../../@types/Calendar.type';
import TaxesHelpQR from '../../../assets/img/png/taxesHelpQR.png';
import Container from '../../../components/atoms/Container';
import { CONFIG_WHATSAPP_SUPPORT } from '../../../shared/constants/whatsappNumbers';
import { RootState } from '../../../redux/store';

interface FormBody {
	year: number;
}

const items = [
	{
		label: 'Contraseña',
		slug: 'password',
	},
	{
		label: 'e.Firma',
		slug: 'efirma',
	},
];

const periodsMonthly = arrayRange(1, 12, 1);

interface Props {
	linked: boolean;
}

const EmptyCalendar: React.FC<Props> = ({ linked }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [activeTab, setActiveTab] = useState(items[0]?.slug);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const { control } = useForm<FormBody>({
		defaultValues: { year: 2024 },
	});

	const isMobile = useMediaQuery('(max-width: 640px)');

	const fetchSatLinkedAcount = async () => {
		try {
			dispatch(startLoader(true));
			const resource = await fetchSATLinkedById(tokenSession);
			dispatch(linkedAccount(resource));
		} catch (error: any) {
			dispatch(startLoader(false));
		}
	};

	const successSATLinkHandler = async () => {
		fetchSatLinkedAcount();
	};

	const handleClickWhatsapp = () => {
		const whatsappUrl = `${CONFIG_WHATSAPP_SUPPORT}`;
		window.open(whatsappUrl, '_blank');
	};

	return (
		<section className='p-3 lg:p-12 flex flex-col gap-4 bg-h-neutral-94 lg:min-h-[calc(100vh-46px)]'>
			<Heading
				size='s'
				weight='bold'
				responsive
			>
				Tablero de impuestos
			</Heading>
			<div>
				<div className='flex flex-col-reverse lg:flex-row gap-4'>
					<Container sx='flex-1'>
						<section>
							<div className='flex flex-row justify-between'>
								<div>
									<Text
										size={isMobile ? 'mini-1' : 'body-3'}
										color='gray'
									>
										{isMobile ? 'Declaraciones Mensuales' : 'Declaraciones'}
									</Text>
									{!isMobile && (
										<Tabs
											disabled
											items={[
												{
													label: 'Mensuales',
													slug: 'monthly' as TPeriod,
												},
												{
													label: 'Anuales',
													slug: 'yearly' as TPeriod,
												},
											]}
											onClickTab={() => {}}
											sx='!rounded-full !shadow-none border-h-neutral-90 border-[0.5px] ml-0 !px-2'
											sizes='small'
											activeTabDefault={'monthly'}
										/>
									)}
								</div>

								{!isMobile && (
									<Selector
										control={control}
										name='year'
										options={[{ label: '2024', value: 2024 }]}
										label='Año'
									/>
								)}
								{isMobile && (
									<Button
										label='Filtrar'
										variant='text'
										labelColor='gray'
										labelSize='caption'
										beforeIcon={<Funnel size={18} />}
									></Button>
								)}
							</div>

							<div className='lg:hidden bg-h-neutral-95  px-2 pt-0.5 pb-1.5 rounded border-h-neutral-90 border-[0.5px] mt-4'>
								<Text
									size='mini-1'
									color='gray'
								>
									2024
								</Text>
								<div className='flex flex-row justify-between'>
									<Text
										size='caption'
										color='medium'
									>
										Gastos deducibles
										<Info
											size={20}
											color='var(--h-gray-40)'
										/>
									</Text>
									<Text
										size='caption'
										color='medium'
									>
										$ 0.00
									</Text>
								</div>
							</div>

							<div className='my-4'>
								<div className='grid grid-cols-3 gap-3 h-full'>
									{periodsMonthly.map((month) => (
										<div
											key={`month-${month}`}
											className={`bg-h-neutral-95 min-h-[64px] sm:min-h-[124px] rounded-lg p-2 sm:p-4 shadow-sm relative  flex md:block items-center justify-center`}
										>
											<Text
												size={isMobile ? 'caption' : 'body-1'}
												sx='capitalize mb-1'
												color='medium'
											>
												{getMonthName(month)}
											</Text>
										</div>
									))}
								</div>
							</div>
						</section>
					</Container>
					{linked ? (
						<Container sx='w-full lg:w-2/6 flex flex-col justify-center py-6'>
							<Text
								size='subtitle'
								sx='text-center mx-4 justify-center'
								weight='medium'
							>
								¡Obten ayuda personalizada de un contador!
							</Text>
							<Text
								size='body-3'
								sx='text-center my-6 mx-4'
								weight='light'
							>
								Estamos trabajando en mostrar la informacion de tu regimen.
								Escanea el código QR y obten la ayuda personalizada de un
								contador.
							</Text>

							{!isMobile && (
								<div className='p-2 shadow-md w-fit mx-auto rounded-lg'>
									<img
										src={TaxesHelpQR}
										alt='Taxes Help QR'
										className='mx-auto w-[200px]'
									/>
								</div>
							)}
							<Button
								label='Obtener ayuda personalizada'
								variant='secondary'
								onClick={handleClickWhatsapp}
								sx='mt-6'
							/>
						</Container>
					) : (
						<Container sx='w-full lg:w-2/5'>
							<div className='rounded-lg border  border-h-primary'>
								<Text
									size='m'
									sx='text-center mt-6 mb-8 mx-4 lg:text-xl'
									weight='medium'
								>
									Vincúlate y simplifica tus impuestos
								</Text>
								<Tabs
									items={items}
									onClickTab={(val) => setActiveTab(val)}
								/>
								<SATLinkingForm
									onCallbackSuccess={successSATLinkHandler}
									onCallbackError={() => {
										navigate(location.pathname, {});
									}}
									showEFirmaForm={activeTab !== 'password'}
									sx='p-6'
									buttonLabel='Vincular mi RFC'
								/>
							</div>
						</Container>
					)}
				</div>
			</div>
		</section>
	);
};

export default EmptyCalendar;
