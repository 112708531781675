const LITERALS = {
	PLATFORM: 'web',
	REQUIRED_FIELD: 'Campo requerido',
	INVALID_FIELD: 'Campo inválido',
	REG_EXP_RFC: 'El RFC ingresado es invalido, verificalo y vuelve a intentarlo',
	INCOMPLETE_FIELD: 'Campo incompleto',
	REQUIRED_FIELDS: 'Todos los campos son requeridos',
	FILES: 'Subir archivos',
	FILESUPLOADED: 'Archivos subidos',
	SUCCESS_SAT_LINK:
		'¡Tu cuenta ha sido vincula exitosamente! Desde ya estamos trabajando en tu tablero de impuestos.',
	MAX_LENGTH_PASSWORD:
		'La contraseña del SAT contiene 8 caracteres. Verifica tu información.',
	MIN_LENGTH_PASSWORD:
		'La contraseña debe contener 8 caracteres como mínimo, verificalo e intentalo de nuevo',
	PASSWORD_INVALID:
		'La contraseña sólo contiene letras y números, sin espacios. Verifica tu información',
	REG_EXP_CURP: 'El CURP es inválido. Verifica tu información.',
	RFC_LINKED_SUCCESSFULLY: 'Tu RFC fue vinculado exitosamente.',
	RFC_LINKED_SUCCESSFULLY_EXTRA:
		'La sincronización de tus datos puede tardar hasta 24 horas. Si esto no pasa, escríbenos.',
	FORM_REQUIRED_ERROR: 'Este campo es requerido',
	FISCAL_LINK_ERROR:
		'Lo sentimos, no pudimos vincular tu cuenta, intenta más tarde',
	FISCAL_EFIRMA_ERROR:
		'Lo sentimos, no pudimos vincular tu e.Firma, intenta más tarde',
	ERROR_WEB_SHARE_MESSAGE:
		'Para compartir tu información fiscal, activa la opción "Web Share" en tu navegador',
	NO_AVAILABLE_DATA: 'No disponible',
	WHATSAPP_MESSAGE_PROBLEM: 'Hola equipo Heru. Necesito ayuda',
	UPLOAD_E_SIGNATURE_ERROR:
		'Lo sentimos, no pudimos cargar tu e.Firma, intenta más tarde.',
	UPLOAD_E_SIGNATURE_SUCCESS: '¡Tu e.Firma ha sido cargada correctamente!',
	UPLOAD_DIGITAL_SEALS_ERROR:
		'Lo sentimos, no pudimos cargar tus sellos digitales, intenta más tarde.',
	UPLOAD_DIGITAL_SEALS_SUCCESS:
		'¡Tus sellos digitales han sido cargados correctamente!',
	WHATSSAP_REGULARIZATIONS:
		'Hola equipo Heru, necesito comprar las regularizaciones pendientes para mi declaración Anual.',
};

export default LITERALS;
