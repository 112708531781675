import React, { useEffect, useRef } from 'react';
import { Order } from '../../../@types/Order.type';

interface PaymentMethodPayPalProps {
	order: Order;
	purchasePayPal: (orderData: any) => void;
}

const PaymentMethodPayPal: React.FC<PaymentMethodPayPalProps> = ({
	purchasePayPal,
	order,
}) => {
	const payPalCard = useRef<any>();

	useEffect(() => {
		const initializePayPal = () => {
			// @ts-ignore
			payPalCard.current = paypal
				.Buttons({
					createOrder: (_data: any, actions: any) => {
						return actions.order.create({
							purchase_units: [
								{
									amount: {
										value: order?.total / 100,
									},
								},
							],
						});
					},
					onApprove: (_data: any, actions: any) => {
						return actions.order
							.capture()
							.then((orderData: any) => {
								purchasePayPal(orderData);
							})
							.catch((_: any) => {});
					},
					style: {
						layout: 'vertical', // Puedes ajustar a 'vertical' u otros valores según tu preferencia
						color: 'gold', // Puedes cambiar el color si es necesario
						shape: 'rect', // Puedes usar 'pill' u otros valores según tu preferencia
						size: 'responsive', // Puedes ajustar el tamaño aquí ('small', 'medium', 'large', 'responsive')
					},
				})
				.render('#paypal-button-container');
		};

		initializePayPal();
	}, [order, purchasePayPal]);

	return (
		<div
			id='paypal-button-container'
			className='mt-10'
		></div>
	);
};

export default PaymentMethodPayPal;
