import { SubscriptionType } from '../@types/Subscription';
import HERU_API from '../shared/constants/heruApi';
import { del, get, post } from '../shared/utils/FetchSSL.util';

const getUserSuscriptions = async (tokenSession?: string) => {
	try {
		const data: SubscriptionType[] = await get(
			HERU_API.HERU_API_ALL_SUBSCRIPTION.concat('?all=true'),
			{
				token: true,
				isNewGateway: true,
				tokenSession,
			},
		);
		return data;
	} catch (error) {
		throw error;
	}
};

const getSubscriptionsEvents = async (id: number, tokenSession?: string) => {
	try {
		const response = await get(
			`${HERU_API.HERU_API_SUBSCRIPTION_NEW}/${id}/event`,
			{
				token: true,
				tokenSession,
				isNewGateway: true,
			},
		);
		return response;
	} catch (error) {
		throw error;
	}
};

const cancelSubscription = async (id: number, tokenSession?: string) => {
	try {
		const response = await del(`${HERU_API.HERU_API_ALL_SUBSCRIPTION}/${id}`, {
			token: true,
			tokenSession,
			isNewGateway: true,
		});
		return response;
	} catch (error) {
		throw error;
	}
};

const postRenewSubscription = async (subsId: any, tokenSession?: string) => {
	try {
		const data = await post(
			HERU_API.HERU_API_ALL_SUBSCRIPTION.concat('/', subsId, '/reactivate'),
			{ renew_at_period_end: true },
			{ token: true, isNewGateway: true, tokenSession },
		);
		return data;
	} catch (error) {
		throw error;
	}
};

export {
	getUserSuscriptions,
	postRenewSubscription,
	getSubscriptionsEvents,
	cancelSubscription,
};
