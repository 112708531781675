export enum PAYMENT_METHOD {
	CARD = 'CARD',
	CASH = 'CASH',
	SPEI = 'SPEI',
	PAYPAL = 'PAYPAL',
	TRADITIONAL_CASH = 'TRADITIONAL_CASH',
}

export enum PLATFORM_CATALOG {
	CONEKTA = 1,
	STRIPE = 2,
	PAYPAL = 3,
	DLOCAL = 4,
}

export interface Card {
	id: string;
	created_at: string;
	updated_at: string;
	details: Details;
	platform_token: string;
	customer_id: number;
	token_status: string;
	is_default: boolean;
	platform_id: number;
	user_id: number | null;
	customer: Customer;
}

export interface Customer {
	id: number;
	user_id: number;
	name: string;
	email: string;
	phone: string;
	official_id: string;
	platform_id: number;
	platform_customer_id: string;
	created_at: string;
	updated_at: string;
	platform: Platform;
}

export interface Platform {
	id: number;
	platform_name: string;
	platform_slug: string;
}

export interface Details {
	last_four: string;
	expires: string;
	card_type: string;
	brand: string;
}
