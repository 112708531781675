import React, { useEffect, useState } from 'react';
import Heading from '../../components/atoms/Heading';
import Text from '../../components/atoms/Text';
import Divider from '../../components/atoms/Divider';
import { PaperPlaneTilt, SealCheck, WhatsappLogo } from '@phosphor-icons/react';

import copy from '../../assets/img/svg/copy.svg';
import Input from '../../components/atoms/Input';
import COUNTRY_CODES from '../../shared/constants/countryCodes';
import { useForm, useWatch } from 'react-hook-form';
import { REGEX } from '../../shared/constants/regex';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import GROWTHBOOK_FEATURE_FLAGS from '../../shared/utils/growthbook.util';
import growthbookFile from '../../shared/constants/growthbook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ALERT_TYPE } from '../../components/molecules/Alert';
import { showAlert } from '../../redux/slices/alert.slice';
import { ANALYTICS_EVENTS } from '../../shared/constants/events';
import ROUTES from '../../shared/constants/routes';
import useCleverTapEvent from '../../hooks/useClevertapEvent';

type FormBody = {
	countryCode: string;
	phone: string;
};

const Referrals: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const dispatch = useDispatch();
	const logClevertapEvent = useCleverTapEvent();

	const isReferralPrice = useFeatureIsOn(
		GROWTHBOOK_FEATURE_FLAGS.REFERRAL_PRICE,
	);
	const listBenefits = [
		<Text
			size='label'
			weight='light'
			color='medium'
			sx='!block'
		>
			Obtén{' '}
			<span className='font-bold'>${isReferralPrice ? '150' : '100'}</span> por
			la primera compra* de un usuario con tu link.
		</Text>,
		<Text
			size='label'
			weight='light'
			color='medium'
			sx='!block'
		>
			Obtén{' '}
			<span className='font-bold'>${isReferralPrice ? '600' : '500'}</span> por
			la primera compra* de 5 usuarios con tu link.
		</Text>,
		<Text
			size='label'
			weight='light'
			color='medium'
			sx='!block'
		>
			Obtén{' '}
			<span className='font-bold'>${isReferralPrice ? '1,500' : '1,000'}</span>{' '}
			y una asesoría contable por la primera compra* de 10 o más usuarios con tu
			link.
		</Text>,
	];
	const [errors, setErrors] = useState({
		phone: '',
	});
	const { control, handleSubmit } = useForm<FormBody>({
		defaultValues: { countryCode: COUNTRY_CODES[0].value, phone: '' },
	});
	const input = useWatch({ control: control, name: 'phone' });

	const onSubmit = (data: FormBody) => {
		const message = encodeURIComponent(
			`Hola, ¿Necesitas ayuda con tus impuestos? Yo uso Heru para mis declaraciones anuales, ahí puedes ver si tienes obligaciones pendientes. Compra ahora con mi URL: https://web.heru.app/checkout/?add_to_cart=suscripcion_declaracion_anual&cupon_code=REFERIDOS150&utm_campaign=${userProfile?.referral_code}&utm_medium=referidos&utm_source=heru. Espero verte pronto en Heru!`,
		);
		const phoneNumber = `${data.countryCode}${data.phone}`;
		const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
		window.open(whatsappUrl, '_blank');
	};

	const copyLink = () => {
		navigator.clipboard
			.writeText(
				`https://web.heru.app/checkout/?add_to_cart=suscripcion_declaracion_anual&cupon_code=REFERIDOS150&utm_campaign=${userProfile?.referral_code}&utm_medium=referidos&utm_source=heru`,
			)
			.then(() => {
				const alert = {
					type: ALERT_TYPE.SUCCESS,
					title: 'El link fue copiado exitosamente',
					description: 'Ahora puedes enviarlo a tus contactos',
				};
				dispatch(showAlert(alert));
			})
			.catch(() => {
				const alert = {
					type: ALERT_TYPE.ERROR,
					title: 'Aviso',
					description: 'Error al copiar el link intenté nuevamente',
				};
				dispatch(showAlert(alert));
			});
	};

	const inviteByEmail = () => {
		const subject = encodeURIComponent('Te regalo 150 para tus impuestos');
		const body = encodeURIComponent(
				
				`Hola,\n\n¿Necesitas ayuda con tus impuestos? Yo uso Heru para mis declaraciones anuales, ahí puedes ver si tienes obligaciones pendientes. Compra ahora con mi URL:\n\n${`https://web.heru.app/checkout/?add_to_cart=suscripcion_declaracion_anual&cupon_code=REFERIDOS150&utm_campaign=${userProfile?.referral_code}&utm_medium=referidos&utm_source=heru`}\n\nEspero verte pronto en Heru!\n\nSaludos, ${userProfile?.first_name}`,
		);
		window.location.href = `mailto:?subject=${subject}&body=${body}`;
	};

	useEffect(() => {
		if (!input.match(REGEX.onlyNumberRegex) && input.length > 0) {
			setErrors({ ...errors, phone: '*Solo se permiten números' });
			return;
		}
		setErrors({ ...errors, phone: '' });
	}, [input]);

	useEffect(() => {
		growthbookFile.loadFeatures();
	}, []);

	useEffect(() => {
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.REFERRALS,
		});
	}, [])
	
	return (
		<div className='p-4 md:p-10'>
			<div>
				<Heading
					sx='hidden lg:block'
					size='s'
					color='dark'
					weight='bold'
				>
					Referidos
				</Heading>
				<Text
					sx='block lg:hidden'
					size='body-2'
					weight='bold'
				>
					Referidos
				</Text>
			</div>
			<div className='flex flex-col justify-center items-center mt-8 lg:px-5'>
				<div className='flex flex-col p-4 mb-6 rounded-md shadow-sm w-full lg:w-2/4 bg-white'>
					<Text
						sx='hidden lg:block'
						size='body-2'
						weight='bold'
					>
						Recomienda Heru y obtén beneficios
					</Text>
					<Text
						sx='block lg:hidden'
						size='body-3'
						weight='bold'
					>
						Recomienda Heru y obtén beneficios
					</Text>
					<Divider sx='mt-6 mb-6' />
					<Text
						sx='hidden lg:block mb-4'
						size='body-3'
						weight='light'
					>
						Comparte tu link con todos tus contactos, entre más usuarios compren
						con tu link, tendrás mayores beneficios.
					</Text>
					<Text
						sx='block lg:hidden mb-4'
						size='caption'
						weight='light'
					>
						Comparte tu link con todos tus contactos, entre más usuarios compren
						con tu link, tendrás mayores beneficios.
					</Text>
					{listBenefits.map((benefit, index) => (
						<div
							className='flex items-center mb-4'
							key={`key-${index}`}
						>
							<div>
								<SealCheck
									size={24}
									className='text-h-primary-40 mr-2'
								/>
							</div>

							{benefit}
						</div>
					))}
					<Text
						sx='mb-4'
						size='mini-1'
						weight='light'
					>
						*Debe ser la primera compra de tu referido
					</Text>
					<Divider sx='mt-2 mb-2' />
					<Text
						sx='mb-4'
						size='body-3'
						weight='bold'
					>
						Beneficio para tus referidos
					</Text>
					<Text
						size='label'
						weight='light'
						color='medium'
						sx='!block'
					>
						Los nuevos usuarios que compren con tu link obtendrán{' '}
						<span className='font-bold'>$150</span> para comprar cualquier
						servicio de Heru.
					</Text>
				</div>
				<div className='flex flex-col p-4 mb-6 rounded-md shadow-sm w-full lg:w-2/4 bg-white'>
					<Text
						sx='hidden lg:block'
						size='body-2'
						weight='bold'
					>
						Link para compartir
					</Text>
					<Text
						sx='block lg:hidden'
						size='body-3'
						weight='bold'
					>
						Link para compartir
					</Text>
					<Divider sx='mt-6 mb-6' />
					<div className='flex items-center justify-between'>
						<Text
							sx='hidden lg:block'
							size='body-3'
							weight='light'
						>
							Invitar por link
						</Text>
						<Text
							sx='block lg:hidden'
							size='caption'
							weight='light'
						>
							Invitar por link
						</Text>
						<div
							className='flex cursor-pointer'
							onClick={copyLink}
						>
							<Text
								sx='mr-4 text-h-primary-40'
								size='caption'
								weight='light'
							>
								Copiar link
							</Text>
							<img
								src={copy}
								width='16'
								height='16'
							/>
						</div>
					</div>
					<Divider sx='mt-6 mb-6' />
					<div className='flex items-center justify-between'>
						<Text
							sx='hidden lg:block'
							size='body-3'
							weight='light'
						>
							Invitar por email
						</Text>
						<Text
							sx='block lg:hidden'
							size='caption'
							weight='light'
						>
							Invitar por email
						</Text>
						<div
							className='flex cursor-pointer'
							onClick={inviteByEmail}
						>
							<Text
								sx='mr-4 text-h-primary-40'
								size='caption'
								weight='light'
							>
								Invitar
							</Text>
							<PaperPlaneTilt
								size={16}
								weight='fill'
								className='text-h-primary-40'
							/>
						</div>
					</div>
					<Divider sx='mt-6 mb-6' />
					<div>
						<div className='mb-4'>
							<Text
								sx='hidden lg:block'
								size='body-3'
								weight='light'
							>
								Invitar por Whatsapp
							</Text>
							<Text
								sx='block lg:hidden'
								size='caption'
								weight='light'
							>
								Invitar por Whatsapp
							</Text>
						</div>
					</div>
					<form
						className='flex flex-col lg:flex-row items-center lg:justify-between'
						onSubmit={handleSubmit(onSubmit)}
					>
						<Input
							sx='mr-4 w-full lg:w-[437px] mb-2 lg:mb-0'
							control={control}
							name='phone'
							type='tel'
							label='Número de celular'
							errorMessage={errors.phone}
						/>

						<button
							className='flex w-full lg:w-[115px] items-center justify-end'
							type='submit'
							disabled={input.length < 6 || errors.phone.length > 0}
						>
							<Text
								sx={`mr-4  ${
									input.length < 6 || errors.phone.length > 0
										? 'text-h-neutral-70'
										: 'text-h-primary-40'
								}`}
								size='caption'
								weight='light'
							>
								Invitar
							</Text>
							<WhatsappLogo
								size={16}
								weight='light'
								className={`${
									input.length < 6 || errors.phone.length > 0
										? 'text-h-neutral-70'
										: 'text-h-primary-40'
								}`}
							/>
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default React.memo(Referrals);
