import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { RootState } from '../../../../redux/store';
import Text from '../../../../components/atoms/Text';
import ROUTES from '../../../../shared/constants/routes';
import Heading from '../../../../components/atoms/Heading';
import { ServiceStatusElement } from '../../../../@types/ServiceStatus.type';
import {
	getAllServiceStatus,
	updateFormStep,
} from '../../../../services/serviceStatus.service';
import { ACTION_CURRENT_SLUG, VARIANT_STATUS } from '../../../../shared/constants/serviceStatus';
import { ANALYTICS_EVENTS } from '../../../../shared/constants/events';
import useCleverTapEvent from '../../../../hooks/useClevertapEvent';

const AsatFormSuccess: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.profile.user);
	const { tokenSession } = useSelector((state: RootState) => state.auth);

	const navigate = useNavigate();
	const logClevertapEvent = useCleverTapEvent();

	const getServiceWithPendingForm = async () => {
		const currentUrl = window.location.href;

		const response: ServiceStatusElement[] =
			await getAllServiceStatus(tokenSession);

		if (response) {
			if (currentUrl.includes('form-asat-success')) {
				const result = response.find(
					(item) =>
						item?.service_has_step.find(
							(item) =>
								item?.stage?.slug ===
								ACTION_CURRENT_SLUG.STAGE_REQUESTED_IDENTIFICATION_FORM,
						),
				);

				const body = {
					fields_status: {
						complete_form_identification: VARIANT_STATUS.COMPLETED,
					},
				};

				try {
					if (result?.id && result?.current_service_has_step_id) {
						await updateFormStep(
							result?.id,
							result?.current_service_has_step_id,
							body,
							tokenSession,
						);
						setTimeout(() => {
							navigate(ROUTES.SERVICE_STATUS);
						}, 3500);
					}
				} catch (error) {}
			}
		}
	};

	useEffect(() => {
		getServiceWithPendingForm();
		logClevertapEvent(ANALYTICS_EVENTS.PAGE_VIEWED, {
			name: ROUTES.ASAT_SUCCESS,
		});
	}, []);

	return (
		<div className='bg-white min-h-full'>
			<div
				className={`bg-[url('https://heru-static-assets.s3.us-east-2.amazonaws.com/Group-1000003242.webp')] justify-center min-h-screen flex flex-col items-center 2xl:mt-[-46px] p-4 lg:p-10 opacity-90`}
			>
				<Heading
					size='m'
					weight='bold'
					sx='text-center'
				>
					¡Gracias por tu respuesta {userProfile?.first_name}{' '}
					{userProfile?.father_surname}!
				</Heading>

				<Text
					size='subtitle'
					weight='bold'
					sx='mt-6 text-center'
				>
					En un momento serás reedirigido a tus servicios.
				</Text>

				<div className='mt-5'>
					<CircularProgress size={25} />
				</div>
			</div>
		</div>
	);
};

export default AsatFormSuccess;
