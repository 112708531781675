const ERROR = {
	failed_to_fetch: 'Ocurrió un error, intente nuevamente más tarde.',
	invalid_prompt: 'Mensaje inválido, intente nuevamente.',
	failed_to_fetch_data: 'Ocurrio un error al obtener la información.',
	try_again_later: 'Intente nuevamente más tarde.',
	//? ADD TO CART
	already_on_cart: 'Este producto no se puede agregar más de una vez al carrito',
	error_to_add_product: 'Error al agregar el producto',
	//? INVOICING
	error_to_cancel_invoice: 'Error al cancelar la factura',
	
};

export default ERROR;
