import { PaperPlaneTilt } from '@phosphor-icons/react';
import Text from '../../../components/atoms/Text';
import PrimaryButton from '../../../components/atoms/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface Props {
	emails: string[];
}

const InvoiceStep5: React.FC<Props> = ({ emails }) => {
	const { issuedInvoices } = useSelector((state: RootState) => state.invocing);

	const handleGoBack = () => {
		window.location.reload();
	};
	return (
		<div className='flex justify-center items-center py-8 min-h-[calc(100dvh-200px)]'>
			<div className='flex flex-col items-center max-w-[400px]'>
				<PaperPlaneTilt
					weight='fill'
					width={64}
					height={64}
					color='var(--h-primary)'
				/>
				<Text
					size='body-1'
					weight='bold'
					sx='mt-4'
				>
					¡Tu factura ha sido enviada!
				</Text>
				<Text
					weight='light'
					sx='mt-4 justify-center text-center'
				>
					Hemos enviado tu factura a {emails.join(', ')}. <br /> Puedes verla en
					la sección de facturación
				</Text>
				<PrimaryButton
					onClick={handleGoBack}
					label={
						issuedInvoices.length
							? 'Volver a la pantalla principal'
							: 'Generar otra factura'
					}
					sx='mt-8 !w-fit px-6'
				/>
			</div>
		</div>
	);
};

export default InvoiceStep5;
