import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import Text from '../../../components/atoms/Text';
import { PlusCircle, XCircle } from '@phosphor-icons/react';
import InvoiceStampsInfo from '../molecules/InvoiceStampsInfo';
import { RootState } from '../../../redux/store';
import { ProfileState } from '../../../redux/slices/profile.slice';
import { useSelector } from 'react-redux';
import { capitalizeText } from '../../../shared/utils/general.util';
import InvoiceFormField from '../molecules/InvoiceFormField';
import { Checkbox } from '@mui/material';
import { UserRegime } from '../../../@types/TaxProfile';
import { InvoicingFormBody } from '../newInvoiceFlow';

type Props = {
	control: Control<InvoicingFormBody, any>;
	watch: UseFormWatch<InvoicingFormBody>;
	formErrors: FieldErrors<InvoicingFormBody>;
	isNewReceiver: boolean;
	setIsNewReceiver: React.Dispatch<React.SetStateAction<boolean>>;
	saveNewReceiver: boolean;
	setSaveNewReceiver: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceStep1: React.FC<Props> = ({
	control,
	watch,
	formErrors,
	isNewReceiver,
	setIsNewReceiver,
	saveNewReceiver,
	setSaveNewReceiver,
}) => {
	const { userRegimes } = useSelector((state: RootState) => state.taxProfile);

	const { regimes, stampStatus, receivers, catalogs } = useSelector<
		RootState,
		ProfileState
	>((state) => state.profile);

	const issuerRegimes = regimes
		?.filter((regime) => {
			const notSupportedRegimes = ['603'];
			if (notSupportedRegimes.includes(regime.code)) return false;

			if (userRegimes?.length === 0) return true;

			return userRegimes?.some(
				(userRegime: UserRegime) => userRegime.code === regime.code,
			);
		})
		?.map((regime) => ({
			label: regime.name,
			value: regime.code,
		}));

	const usesCFDI = Object.values(catalogs?.usocfdi || {}).map((payment) => ({
		label: payment.name,
		value: payment.code,
	}));

	const allowEditIssuerName = watch('issuer_regime') === '601';

	return (
		<div className=''>
			<Text
				size='body-3'
				weight='medium'
				color='medium'
			>
				Mis datos de facturación
			</Text>
			<Text
				size='body-3'
				weight='light'
				color='light'
				sx='mb-4'
			>
				Carga tu información de facturación para crear una nueva factura
			</Text>
			<InvoiceStampsInfo allowEditIssuerName={allowEditIssuerName} />
			<hr className='mt-6' />

			<InvoiceFormField
				control={control}
				label='Selecciona tu régimen para esta factura'
				fieldName={'issuer_regime'}
				formErrors={formErrors}
				options={issuerRegimes}
				type='selector'
			/>
			{!stampStatus?.postal_code && (
				<InvoiceFormField
					control={control}
					label='Código postal'
					fieldName='issuer_zip_code'
					formErrors={formErrors}
					type='input-number'
				/>
			)}
			<hr className='mt-6' />
			<Text
				size='caption'
				sx='mt-4 mb-4'
			>
				¿A quién se emitirá esta factura?
			</Text>
			{!isNewReceiver && (
				<InvoiceFormField
					control={control}
					showLabel={false}
					label='Selecciona un receptor'
					fieldName={'saved_receiver'}
					formErrors={formErrors}
					options={receivers?.map((receiver) => ({
						label: capitalizeText(receiver.name),
						value: receiver.rfc,
					}))}
					type='selector'
				/>
			)}

			{/* ====== NEW RECEIVER ====== */}
			<div
				onClick={() => setIsNewReceiver(!isNewReceiver)}
				className='w-fit flex items-center gap-2 text-h-primary transition hover:bg-h-primary-90/50 py-1 px-2 rounded-md mt-2 cursor-pointer'
			>
				{isNewReceiver ? (
					<XCircle weight='bold' />
				) : (
					<PlusCircle weight='bold' />
				)}{' '}
				{isNewReceiver ? 'Cancelar' : 'Agregar nuevo receptor'}
			</div>

			{isNewReceiver && (
				<>
					<InvoiceFormField
						control={control}
						label='Nombre del receptor'
						fieldName='new_receiver_name'
						formErrors={formErrors}
						type='input-text'
					/>
					<InvoiceFormField
						control={control}
						label='RFC del nuevo receptor'
						fieldName='new_receiver_rfc'
						formErrors={formErrors}
						type='input-text'
						tooltip='Clave de 12 o 13 caracteres que debe proporcionarte la persona a quien facturarás'
					/>
					<InvoiceFormField
						control={control}
						label='Código postal'
						fieldName='new_receiver_zip_code'
						formErrors={formErrors}
						type='input-number'
						tooltip='Debe corresponder al domicilio fiscal de quien le vayas a facturar'
					/>
					<InvoiceFormField
						control={control}
						label='Régimen fiscal del receptor'
						fieldName='new_receiver_regime'
						formErrors={formErrors}
						options={regimes?.map((regime) => ({
							label: regime.name,
							value: regime.code,
						}))}
						type='selector'
						tooltip='Régimen al que pertenece la persona a quien facturarás'
					/>
					<InvoiceFormField
						control={control}
						label='Uso del CFDI'
						fieldName='new_receiver_cfdi_use'
						formErrors={formErrors}
						options={usesCFDI}
						type='selector'
						tooltip='Corresponde al uso que le dará tu cliente a la factura. En caso de no tenerlo claro, te recomendamos (Gastos Generales).'
					/>
					<div
						className='flex  items-center ml-[-12px] mt-2 cursor-pointer'
						onClick={() => setSaveNewReceiver(!saveNewReceiver)}
					>
						<Checkbox
							checked={saveNewReceiver}
							sx={{
								'&.Mui-checked': {
									color: 'var(--h-primary)',
								},
							}}
						/>
						<Text>Guardar datos del receptor</Text>
					</div>
				</>
			)}
		</div>
	);
};

export default InvoiceStep1;
